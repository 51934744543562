import React, { useEffect, useState } from "react";
import HourTimeInput from "../inputs/HourTimeInput";
import PersonnelAndEquipmentSelector from "../inputs/PersonnelAndEquipmentSelector";
import Selector from "../inputs/Selector";
import generateFileUrl from "../../utils/generateFileUrl";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../inputs/Input";
import Button from "../buttons/Button";
import { equipmentService } from "../../services/equipment.service";
import { personalService } from "../../services/personal.service";

import DateTimeInput from "../inputs/DateTimeInput";
import { junctionService } from "../../services/junction.service";
import { Toast } from "../../utils/toastify/toast";
import { flexJunctionService } from "../../services/flex-junction.service";

const AddJunctionManual = ({ operation, orderData, refetch, setIsOpenJunction, operations, productType }) => {
  const { t } = useTranslation();

  const [equipments, setEquipments] = useState([]);
  const [personels, setPersonels] = useState([]);
  const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"));
  const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"));
  const [operationId, setOperationId] = useState("");
  const [headerId, setHeaderId] = useState("");

  const schema = yup.object({
    station: yup.string().required(),
    personnel: yup.string().required(),
    type: yup.string().required(),
    madeWorkCount: yup
      .number()
      .required()
      .typeError(`${t("validation:number")}`),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      rejectedPiece: 0,
    },
  });

  const junctionTypes = [
    { label: t("product:work"), value: "work" },
    { label: t("product:setup"), value: "setup" },
  ];

  const onSubmit = async (value) => {
    if (moment(startDate).isAfter(moment()) || moment(endDate).isAfter(moment())) {
      Toast("error", "Başlangıç veya bitiş saati şimdi ki saatten ileri olamazz!!!");
    } else {
      const send = {
        startDate: moment(startDate).utc().format(),
        endDate: moment(endDate).utc().format(),
        station: value?.station,
        user: value?.personnel,
        type: value?.type,
        madeWorkCount: value?.madeWorkCount,
        internalOperation: operation?.processId,
        job: orderData?.product?.id,
        header: headerId,
      };

      if (productType == "flex") {
        await flexJunctionService.addJunctionManual(send).then((res) => {
          if (res?.data?.code === 1) {
            Toast("error", res?.data?.message);
            setIsOpenJunction(false);
          } else {
            Toast("success", res?.data?.message);
            setIsOpenJunction(false);
            refetch();
          }
        });
      } else {
        await junctionService.addJunctionManual(send).then((res) => {
          if (res?.data?.code === 1) {
            Toast("error", res?.data?.message);
            setIsOpenJunction(false);
          } else {
            Toast("success", res?.data?.message);
            setIsOpenJunction(false);
            refetch();
          }
        });
      }
    }
  };

  const getActiveNodes = async () => {
    if (operationId) {
      const data = await equipmentService.getEquipmentForProcessId(operationId);

      let formatttedData = data?.data?.map((e, i) => {
        return {
          label: e?.metadata?.brand + " " + e?.metadata?.model,
          value: e?.id,
          image: e?.metadata?.image,
        };
      });
      setEquipments(formatttedData);
    } else {
      setEquipments([]);
    }
  };
  const getActiveUser = async () => {
    const data = await personalService.activePersonals();
    let formatttedData = data?.data?.map((e, i) => {
      return {
        label: e?.name + " " + e?.lastName,
        value: e?.id,
        image: e?.avatar,
      };
    });
    setPersonels(formatttedData);
  };

  useEffect(() => {
    getActiveUser();
  }, []);

  useEffect(() => {
    getActiveNodes();
  }, [operationId]);

  useEffect(() => {
    if (operations?.length === 1) {
      setOperationId(operations[0].value);
      setHeaderId(operations[0].headerId);
    }
  }, [operations]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-[660px] flex flex-col  mt-6 ">
      <div className="w-full flex-col flex gap-6">
        <div className="w-full flex-row flex items-center h-[72px] gap-3">
          <img src={generateFileUrl(operation?.image)} className="w-[64px] h-[48px] rounded border border-gray-300" />
          <div className="flex flex-col justify-between">
            <p className="font-semibold text-gray-900 w-full   truncate">{operation?.name || "---"}</p>
            <div className="text-[#B54708] text-xs px-1 py-0.5 rounded-md font-medium bg-[#FFFAEB] ">Stok : {orderData?.stock || "---"}</div>
          </div>
        </div>
        <div className="flex flex-row items-start w-full ">
          <div className="w-1/2 flex flex-col gap-4 pr-4 border-r">
            <Selector
              value={operationId}
              items={operations}
              theme={"product"}
              label={t("product:workType")}
              onChange={(e, i) => {
                setOperationId(e);
                setHeaderId(operations.filter((item) => item?.value === e)[0]?.headerId);
              }}
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <PersonnelAndEquipmentSelector
                  value={value}
                  items={equipments}
                  icon={"machine"}
                  theme={"product"}
                  label={t("equipments:equipment")}
                  w
                  onChange={onChange}
                  // onChange={(e) => {
                  //   setValue("station", e);
                  // }}
                  onBlur={onBlur}
                  validate={errors?.station ? "error" : ""}
                  errorMessage={errors?.station ? errors?.station?.message : ""}
                />
              )}
              name="station"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <PersonnelAndEquipmentSelector
                  value={value}
                  items={personels}
                  icon={"personnel"}
                  image={""}
                  theme={"product"}
                  label={t("personals:personal")}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors?.personnel ? "error" : ""}
                  errorMessage={errors?.personnel ? errors?.personnel?.message : ""}
                />
              )}
              name="personnel"
            />

            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Selector
                  value={value}
                  items={junctionTypes}
                  theme={"product"}
                  label={t("product:workType")}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors?.type ? "error" : ""}
                  errorMessage={errors?.type ? errors?.type?.message : ""}
                />
              )}
              name="type"
            />
          </div>

          <div className="w-1/2 flex flex-col gap-4 pl-4 ">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  theme={"product"}
                  label={t("product:numberOfProducts")}
                  errorMessage={errors?.madeWorkCount?.message}
                  validate={errors?.madeWorkCount ? "error" : ""}
                />
              )}
              name="madeWorkCount"
            />
            <DateTimeInput
              label={t("product:jobStartDate")}
              theme={"product"}
              initialValue={moment(startDate).format("YYYY-MM-DDTHH:mm:ss")}
              onChange={(e) => {
                setStartDate(e);
              }}
            />
            <DateTimeInput
              label={t("product:jobEndDate")}
              theme={"product"}
              initialValue={moment(endDate).format("YYYY-MM-DDTHH:mm:ss")}
              onChange={(e) => {
                setEndDate(e);
              }}
            />
          </div>
        </div>
      </div>
      <div className="w-1/2 flex items-center ml-auto  gap-3 mt-8">
        <Button label={t("buttons:stop")} colorType={"secondary-gray"} size={"md"} onClick={() => setIsOpenJunction(false)} />
        <Button type={"submit"} label={t("buttons:save")} colorType={"primary-product"} size={"md"} />
      </div>
    </form>
  );
};

export default AddJunctionManual;

const QtyIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        d="M4.3125 1.75H2.78571C2.07563 1.75 1.5 2.32563 1.5 3.03571V8.90179C1.5 9.61187 2.07563 10.1875 2.78571 10.1875H9.21429C9.92437 10.1875 10.5 9.61187 10.5 8.90179V3.03571C10.5 2.32563 9.92437 1.75 9.21429 1.75H7.6875M4.3125 1.75V5.02098C4.3125 5.06877 4.36279 5.09985 4.40554 5.07848L6 4.28125L7.59446 5.07848C7.63721 5.09985 7.6875 5.06877 7.6875 5.02098V1.75M4.3125 1.75H7.6875"
        stroke="#475467"
      />
    </svg>
  );
};

const ClockIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <g clip-path="url(#clip0_9923_13289)">
        <path
          d="M6 3V6L8 7M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
          stroke="#475467"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9923_13289">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const CalendarIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        d="M10.5 5H1.5M8 1V3M4 1V3M3.9 11H8.1C8.94008 11 9.36012 11 9.68099 10.8365C9.96323 10.6927 10.1927 10.4632 10.3365 10.181C10.5 9.86012 10.5 9.44008 10.5 8.6V4.4C10.5 3.55992 10.5 3.13988 10.3365 2.81901C10.1927 2.53677 9.96323 2.3073 9.68099 2.16349C9.36012 2 8.94008 2 8.1 2H3.9C3.05992 2 2.63988 2 2.31901 2.16349C2.03677 2.3073 1.8073 2.53677 1.66349 2.81901C1.5 3.13988 1.5 3.55992 1.5 4.4V8.6C1.5 9.44008 1.5 9.86012 1.66349 10.181C1.8073 10.4632 2.03677 10.6927 2.31901 10.8365C2.63988 11 3.05992 11 3.9 11Z"
        stroke="#475467"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

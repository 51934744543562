import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import NewSelectInput from "../../../components/inputs/NewSelectInput";
import { Button, Input } from "../../../components";
import { equipmentService } from "../../../services/equipment.service";
import Selector from "../../../components/inputs/Selector";

function FirstAssemblyModal({ setValue, control, errors, valuePercentage, setValuePercentage, hasQualityControl, setHasQualityControl, handleChange }) {
  const { t } = useTranslation();
  const [isOpenCalculator, setIsOpenCalculator] = useState(false);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [assemblyOptions, setAssemblyOptions] = useState([]);
  const closeCalculator = () => {
    setIsOpenCalculator(false);
  };
  const calculateTime = () => {
    const totalMinutes = parseInt(hours) * 60 + parseInt(minutes) + parseInt(seconds) / 60;
    setValue("estimatedTime", totalMinutes.toString());
    setHours(0);
    setMinutes(0);
    setSeconds(0);
    closeCalculator();
  };

  async function getAssemblyOperations() {
    const { data } = await equipmentService.getTypeByProcess("assembly_operation");
    setAssemblyOptions(
      data?.map((item) => {
        return { label: item?.name, value: item?.id };
      })
    );
  }

  useEffect(() => {
    getAssemblyOperations();
  }, []);

  return (
    <div className="flex py-4 flex-col gap-y-6 min-h-[520px] h-[520px] w-full">
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <Selector
            searchable={true}
            value={value}
            items={assemblyOptions}
            theme={"product"}
            label={t("product:internalOperation")}
            onChange={(selectedOption) => {
              setValue("entityId", selectedOption);
            }}
            onBlur={onBlur}
            validate={errors?.entityId ? "error" : ""}
            errorMessage={errors?.entityId ? errors?.entityId?.message : ""}
          />
        )}
        name="entityId"
      />
      <div className={`flex flex-col ${isOpenCalculator && "border-y py-4"} `}>
        <div className="flex w-full items-end gap-x-3">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                theme={"product"}
                label={"Tahmini Süre (dk)"}
                errorMessage={errors?.estimatedTime?.message}
                validate={errors?.estimatedTime ? "error" : ""}
                type={"number"}
              />
            )}
            name="estimatedTime"
          />
          <span className={`rounded-lg ${isOpenCalculator && "shadow-ring-gray"}`}>
            <Button
              colorType={"secondary-gray"}
              iconLeft={<CalculatorIcon />}
              size={"md"}
              onClick={() => {
                setIsOpenCalculator(!isOpenCalculator);
              }}
            />
          </span>
        </div>
        {isOpenCalculator && (
          <div className="flex w-full gap-x-3 mt-4 items-end">
            <Input type="number" label={"saat"} theme={"product"} value={hours} onChange={(e) => setHours(e.target.value)} />
            <Input type="number" label={"dakika"} theme={"product"} value={minutes} onChange={(e) => setMinutes(e.target.value)} />
            <Input type="number" label={"saniye"} theme={"product"} value={seconds} onChange={(e) => setSeconds(e.target.value)} />
            <Button colorType={"secondary-gray"} iconLeft={<CheckIcon />} size={"md"} onClick={calculateTime} />
          </div>
        )}
      </div>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            theme={"product"}
            label={"Dakika Maliyet"}
            errorMessage={errors?.costOfMinute?.message}
            validate={errors?.costOfMinute ? "error" : ""}
          />
        )}
        name="costOfMinute"
      />

      <label className="flex items-center">
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <input
              className="rounded"
              type="checkbox"
              onChange={(e) => {
                setHasQualityControl(e.target.checked);
                setValue("qualityPercent", 0);
                setValuePercentage("");
                setValue("hasQualityControl", e.target.checked);
              }}
              onBlur={onBlur}
              checked={value}
            />
          )}
          name="hasQualityControl"
        />
        <p className="text-sm text-secondary-600  ml-2">{t("person:qualityControl")}</p>
      </label>
      <Input
        label={t("product:qualityPercent")}
        value={valuePercentage}
        theme={"product"}
        onChange={handleChange}
        isPercentage
        disabled={hasQualityControl ? false : true}
      />
      {/* <div onClick={() => setIsChecked(!isChecked)} className="min-h-[20px] max-h-[20px] w-fit flex gap-x-3 cursor-pointer">
        <div
          className={`flex min-w-[20px] max-w-[20px] min-h-[20px] max-h-[20px]  p-[3px] rounded-md items-center justify-center border ${
            isChecked ? "bg-[#DC6803] border-[#DC6803]" : "border-[#D0D5DD]"
          }`}
        >
          {isChecked && <CheckboxIcon />}
        </div>
        <span className="text-[#667085] font-normal text-sm">Quality control is required after this step.</span>
      </div>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            theme={"product"}
            label={"Kalite Yüzdesi"}
            errorMessage={errors?.qualityPercent?.message}
            validate={errors?.qualityPercent ? "error" : ""}
          />
        )}
        name="qualityPercent"
      /> */}
    </div>
  );
}

export default FirstAssemblyModal;

const CalculatorIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M14.5833 5.41667L5.41667 14.5833M7.08333 8.75V5.41667M5.41667 7.08333H8.75M11.25 12.9167H14.5833M6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5Z"
        stroke="#344054"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const CheckIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M16.6668 5L7.50016 14.1667L3.3335 10" stroke="#079455" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

const CheckboxIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path d="M11.6668 3.5L5.25016 9.91667L2.3335 7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, CustomModal, Input, UploadProfile } from "../../components";
import { ArrowLeftIcon, ArrowRightIcon, FlexProductPlusIcon } from "../../assets/icons/productIcons";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { flexProductService } from "../../services/flex-product.service";
import _ from "lodash";
import FlexProductRawMaterialCard from "./FlexProductRawMaterialCard";
import UnitPriceInput from "../../components/inputs/UnitPriceInput";
import StockSelectRawMaterialModal from "./modals/StockSelectRawMaterialModal";
import TextArea from "../../components/inputs/TextArea";
import { Toast } from "../../utils/toastify/toast";
import { instance } from "../../libs/client";
import CropperPdf from "../../components/inputs/CropperPdf";
import generateFileUrl from "../../utils/generateFileUrl";
import Preview from "../../components/modal/Preview";
import { productService } from "../../services/product.service";
import { useQuery } from "@tanstack/react-query";

const StockCreateNewProduct = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { stockId } = useParams();
  const [selectRawCardModal, setSelectRawCardModal] = useState(false);
  const [selectedRawMaterial, setSelectedRawMaterial] = useState(null);
  const [items, setItems] = useState([]);
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("TRY");
  const [priceValidate, setPriceValidate] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [isCrop, setIsCrop] = useState(false);
  const [pdfDataCDN, setPdfDataCDN] = useState("");
  const [cropData, setCropData] = useState("");
  const [croppedDataCDN, setCroppedDataCDN] = useState("");

  const { data, refetch, isLoading, isFetching } = useQuery({
    enabled: Boolean(stockId),
    queryKey: ["find-by-product-stock-edit", stockId],
    queryFn: async () => await productService.productDetailData(stockId),
    retry: 0,
  });

  const priceOptions = [
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
    { label: "GBP", value: "GBP" },
    { label: "RUB", value: "RUB" },
    { label: "UAH", value: "UAH" },
    { label: "TRY", value: "TRY" },
  ];

  const schema = yup.object({
    productPhoto: yup.string().required(`${t("validation:empty")}`),
    productName: yup.string().required(`${t("validation:empty")}`),
    stockCode: yup.string().required(`${t("validation:empty")}`),
    rawMaterialAmount: yup.number().required(`${t("validation:empty")}`),
    description: yup.string().required(`${t("validation:empty")}`),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (value) => {
    let isValid = true;

    if (!selectedRawMaterial) {
      isValid = false;
      Toast("error", "Lütfen hammadde seçiniz");
    }

    if (!price) {
      setPriceValidate("error");
      isValid = false;
    } else {
      setPriceValidate(null);
    }

    if (isValid) {
      const operations = data?.internalOperations?.concat(data?.externalOperations) || [];
      const groupedOperations = _.groupBy(operations, "step");

      const operationsNew = Object.keys(groupedOperations).map((key) => {
        return groupedOperations[key].map((operation) => ({
          description: operation.description || undefined,
          plannableNodes: operation.plannableNodes || undefined,
          process: { id: operation.process?.id } || { id: undefined },
          processId: operation.process?.id || undefined,
          entityId: operation.process?.id || undefined,
          processName: operation.process?.name || undefined,
          plannableSupplier: operation.plannableSupplier || undefined,
          processType: operation.processType || undefined,
          qualityControlPercent: operation.qualityPercent || "",
          step: operation.step,
          type: operation.type || "internal",
        }));
      });

      const sendCreate = {
        image: value?.productPhoto,
        name: value?.productName,
        stockCode: value?.stockCode,
        selectedRawMaterial: selectedRawMaterial,
        quantityUsedPerPiece: value?.rawMaterialAmount,
        description: value?.description,
        currency: currency,
        price: price,
        technicalDrawing: pdfDataCDN,
        technicalDrawingCrop: croppedDataCDN,
        operations: operationsNew,
      };
      sessionStorage.setItem("stockCreateProductFirstData", JSON.stringify(sendCreate));
      if (stockId) {
        navigate(`/app/product/mrp/stock/edit/recipe/${stockId}`);
      } else {
        navigate("/app/product/mrp/stock/create/recipe");
      }
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("stockCreateProductFirstData")) {
      const data = JSON.parse(sessionStorage.getItem("stockCreateProductFirstData"));
      setValue("productPhoto", data?.image);
      setValue("productName", data?.name);
      setValue("stockCode", data?.stockCode);
      setSelectedRawMaterial(data?.selectedRawMaterial);
      setValue("rawMaterialAmount", Number(data?.quantityUsedPerPiece));
      setValue("description", data?.description);
      setPrice(data?.price);
      setCurrency(data?.currency);
      if (data?.technicalDrawingCrop) {
        setCroppedImage(generateFileUrl(data?.technicalDrawingCrop));
      } else {
        setCroppedImage(null);
      }
    }
  }, [sessionStorage.getItem("stockCreateProductFirstData")]);

  useEffect(() => {
    if (data && !sessionStorage.getItem("stockCreateProductFirstData")) {
      setValue("productPhoto", data?.image);
      setValue("productName", data?.name);
      setValue("stockCode", data?.stockCode);
      setSelectedRawMaterial(data?.rawMaterial);
      setValue("rawMaterialAmount", Number(data?.quantityUsedPerPiece));
      setValue("description", data?.description);
      // setPrice(data?.price);
      setCurrency(data?.currency);
      if (data?.technicalDrawingCrop) {
        setCroppedImage(generateFileUrl(data?.technicalDrawingCrop));
      } else {
        setCroppedImage(null);
      }
    }
  }, [data]);

  const uploadBase64 = async (file) => {
    const data = { imageBinary: file };
    const response = await instance({
      method: "post",
      url: "file/upload/base64",
      data: { data },
    });
    setPdfDataCDN(response.data?.file);
  };
  const uploadBase64Cropped = async (file) => {
    const data = { imageBinary: file };
    const response = await instance({
      method: "post",
      url: "file/upload/base64",
      data: { data },
    });
    setCroppedDataCDN(response?.data?.file);
  };
  useEffect(() => {
    pdfData && uploadBase64(pdfData);
    cropData && uploadBase64Cropped(cropData);
  }, [pdfData, cropData]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full h-full gap-y-8 overflow-y-auto overflow-x-hidden scrollbar-hide">
        <div className="flex min-h-[56px] max-h-[56px] h-[56px] w-full items-center justify-start gap-x-2 sticky top-0 z-[50] bg-white">
          <Button size={"md"} iconLeft={<ArrowLeftIcon />} colorType={"tertiary-gray"} onClick={() => navigate(-1)} type={"button"} />
          <p className="text-[#101828] font-semibold text-2xl flex gap-x-3 items-center">
            {t("product:createNewProduct")}
            <p className="text-[#475467] font-normal text-xl">1/2</p>
          </p>
        </div>

        <div className="flex flex-col gap-y-6 w-full">
          <div className="flex w-full min-h-[72px] max-h-[72px] h-[72px] pb-5 justify-between border-b border-[#E4E7EC]">
            <div className="flex flex-col gap-y-1">
              <p className="text-[#101828] font-semibold text-lg">{t("product:productInformation")}</p>
              <p className="text-[#344054] font-normal text-sm">{t("product:generalInformationProduct")} </p>
            </div>
            <div className="flex gap-x-3 max-h-[40px]">
              <span className="flex min-w-[96px] max-w-[96px] w-[96px]">
                <Button onClick={() => navigate(-1)} size={"md"} colorType={"secondary-gray"} label={t("buttons:stop")} />
              </span>
              <span className="flex min-w-[157px] max-w-[157px] w-[157px]">
                <Button size={"md"} colorType={"primary-product"} label={"Reçete Bilgileri"} type={"submit"} iconRight={<ArrowRightIcon />} />
              </span>
            </div>
          </div>
          <div className="flex flex-col w-full gap-y-5">
            <div className="flex w-full gap-x-8 items-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:productImages")}</p>
              </div>
              <div className="flex w-[75%] gap-x-[72px]">
                <div className="flex">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <UploadProfile
                        title={t("product:productImages")}
                        subTitle={t("fileInput:updatePicture")}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        errorMessage={errors?.productPhoto?.message}
                        validate={errors?.productPhoto ? "error" : ""}
                      />
                    )}
                    name="productPhoto"
                  />
                </div>
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:productName")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[60%]">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        theme={"product"}
                        errorMessage={errors?.productName?.message}
                        validate={errors?.productName ? "error" : ""}
                      />
                    )}
                    name="productName"
                  />
                </span>
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:stockCode")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[60%]">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        theme={"product"}
                        errorMessage={errors?.stockCode?.message}
                        validate={errors?.stockCode ? "error" : ""}
                      />
                    )}
                    name="stockCode"
                  />
                </span>
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:technicalPicture")}</p>
              </div>
              <div className="flex w-[75%] gap-x-[72px]">
                <div className="flex">
                  <CropperPdf pdfData={croppedImage} setPdfData={setPdfData} setIsCrop={setIsCrop} pdfLink={generateFileUrl(pdfDataCDN)} />
                </div>
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:rawMaterial")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[70%] gap-x-8">
                  {selectedRawMaterial ? (
                    <div
                      onClick={() => {
                        setSelectRawCardModal(true);
                      }}
                      className="flex w-[324px] min-h-[90px] max-h-[90px] h-[90px] items-center cursor-pointer"
                    >
                      <FlexProductRawMaterialCard item={selectedRawMaterial} />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setSelectRawCardModal(true);
                      }}
                      className="flex w-[324px] min-h-[90px] max-h-[90px] h-[90px] border border-[#D0D5DD] border-dashed shadow-xs rounded-lg px-3 py-2 items-center justify-center cursor-pointer"
                    >
                      <FlexProductPlusIcon />
                    </div>
                  )}
                  <div className="flex w-[50%] ml-4">
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("product:amountTobeUsed")}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          type={"number"}
                          theme={"product"}
                          errorMessage={errors?.rawMaterialAmount?.message}
                          validate={errors?.rawMaterialAmount ? "error" : ""}
                        />
                      )}
                      name="rawMaterialAmount"
                    />
                  </div>
                </span>
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{"Ürün Başı Satış Fiyatı"}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[60%]">
                  <UnitPriceInput
                    theme={"product"}
                    items={priceOptions}
                    setValue={(field, value) => {
                      field === "price" ? setPrice(value) : setCurrency(value);
                    }}
                    price={price}
                    currency={currency}
                    validate={priceValidate ? "error" : ""}
                    errorMessage={priceValidate ? "Bu alan zorunludur" : ""}
                  />
                </span>
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:description")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[60%]">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextArea
                        onBlur={onBlur}
                        onChange={onChange}
                        rows={3}
                        theme={"product"}
                        value={value}
                        validate={errors.description ? "error" : ""}
                        errorMessage={errors?.description?.message}
                      />
                    )}
                    name="description"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
      <CustomModal
        isOpen={selectRawCardModal}
        setIsOpen={setSelectRawCardModal}
        modalTitle={t("product:addRawMaterial")}
        subTitle={t("product:addTheNecessary")}
        children={
          <StockSelectRawMaterialModal
            setSelectedRawMaterial={setSelectedRawMaterial}
            selectedRawMaterial={selectedRawMaterial}
            setSelectRawCardModal={setSelectRawCardModal}
          />
        }
      />
      <CustomModal
        isOpen={isCrop}
        setIsOpen={setIsCrop}
        modalTitle={t("product:pdfCropper")}
        children={<Preview file={pdfData} setCropData={setCropData} setIsCrop={setIsCrop} croppedImage={croppedImage} setCroppedImage={setCroppedImage} />}
      />
    </>
  );
};

export default StockCreateNewProduct;

export const RecipeEdit = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M2.39662 15.0965C2.43491 14.752 2.45405 14.5797 2.50618 14.4186C2.55243 14.2758 2.61778 14.1398 2.70045 14.0144C2.79363 13.8731 2.91621 13.7506 3.16136 13.5054L14.1666 2.50017C15.0871 1.5797 16.5795 1.5797 17.4999 2.50017C18.4204 3.42065 18.4204 4.91303 17.4999 5.83351L6.49469 16.8387C6.24954 17.0839 6.12696 17.2065 5.98566 17.2996C5.86029 17.3823 5.72433 17.4477 5.58146 17.4939C5.42042 17.546 5.24813 17.5652 4.90356 17.6035L2.08325 17.9168L2.39662 15.0965Z"
        stroke="#98A2B3"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const RecipeClose = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M15 5L5 15M5 5L15 15" stroke="#98A2B3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import OperationStepOne from "./OperationStepOne";
const MultiOperationModal = ({
  items,
  setItems,
  stepOneData,
  setStepOneData,
  stepTwoData,
  setStepTwoData,
  setStepThreeData,
  stepThreeData,
  onClose,
  productId,
  refetch,
  groupedItemsLength,
}) => {
  const { t } = useTranslation();
  const [selectedStep, setSelectedStep] = useState(1);
  const selectedTabs = (tabIndex) => {
    switch (tabIndex) {
      case 1:
        return stepOneData;
      case 2:
        return stepTwoData;
      case 3:
        return stepThreeData;
      default:
        return { processName: "Yeni Operasyon Ekle" };
    }
  };
  return (
    <div className="w-full h-full mt-6">
      <div className="flex w-full items-center">
        {[1, 2, 3].map((tabIndex) => (
          <div
            key={tabIndex}
            className={`flex px-1 pb-3 gap-x-3 items-center justify-center w-1/3 cursor-pointer ${selectedStep === tabIndex ? "border-b-[2px] border-[#DC6803] text-[#B54708]" : "border-b border-[#E4E7EC]"
              }`}
            onClick={() => setSelectedStep(tabIndex)}
          >
            {!selectedTabs(tabIndex)?.operation ? <PlusIcon /> : null}
            <p className="font-semibold text-md">{selectedTabs(tabIndex)?.operations?.label || t("addProcess:addNewProcess")}</p>
          </div>
        ))}
      </div>
      <OperationStepOne
        groupedItemsLength={groupedItemsLength}
        oldItems={items}
        setItems={setItems}
        stepOneData={stepOneData}
        setStepOneData={setStepOneData}
        selectedStep={selectedStep}
        setStepThreeData={setStepThreeData}
        stepThreeData={stepThreeData}
        setStepTwoData={setStepTwoData}
        stepTwoData={stepTwoData}
        selectedTabs={selectedTabs}
        onClose={onClose}
        productId={productId}
        refetch={refetch}
      />
    </div>
  );
};

export default MultiOperationModal;

export const PlusIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M9.99984 4.16666V15.8333M4.1665 10H15.8332" stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

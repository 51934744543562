import React, { useState } from 'react'
import { Tooltip } from 'react-tooltip';
import { DecraceStockIcon, NewDotsIcon } from '../../../components/new-cards/card-icons';
import CustomImage from '../../../components/CustomImage';
import { NoEquIcon } from '../../../assets/icons/machineIcons';
import { PieceIconss, SupplyIconss } from './StockProductCard';
import { CalendarIcons } from '../../../assets/icons/productIcons';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const StockDetailRecipeProductCard = ({ item, disableMenu }) => {
    const { t } = useTranslation()
    const pathname = useLocation();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <div
                data-tooltip-id={`${item?.id}`}
                data-tooltip-content={`
                ${t("product:productName")} : ${item?.revisionNo ? (item?.revisionNo != 0 ? `${item?.name} (Rev-${item?.revisionNo})` : item?.name) : item?.name}
                -
                ${t("product:stock")} : ${item?.revisionNo ? (item?.revisionNo != 0 ? `REV-${item?.revisionNo}/${item?.stockCode} ` : item?.stockCode) : item?.stockCode || "--"
                    }
  `}
                data-tooltip-place="right"
                style={{ zIndex: 60 }}
                className="flex bg-white flex-row w-full h-[112px] min-h-[112px] relative group pl-2  pt-2 border border-[#D0D5DD] rounded-lg cursor-grab "
            >
                <div className="flex flex-col w-[100%]">
                    <div className="flex flex-row items-center gap-x-3">
                        {item?.image ? (
                            <div>
                                <CustomImage
                                    borderRadius={2}
                                    src={item?.image}
                                    style={{
                                        width: 64,
                                        height: 48,
                                        minWidth: 64,
                                        minHeight: 48,
                                        borderRadius: 4,
                                        borderWidth: 1,
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        display: "flex",
                                        borderColor: "#0000001A",
                                        backgroundColor: "#fff",
                                    }}
                                />
                            </div>
                        ) : (
                            <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                                <NoEquIcon />
                            </div>
                        )}

                        <div className="flex flex-col items-start">
                            <div className="flex flex-row items-center gap-x-1 w-full">
                                <p className="font-semibold text-secondary-900 text-base w-[185px] truncate">
                                    {item?.revisionNo ? (item?.revisionNo != 0 ? `${item?.name} (Rev-${item?.revisionNo})` : item?.name) : item?.name || "Product Name"}
                                </p>
                            </div>

                            <div className="bg-[#FFFAEB] max-w-[185px] h-6 rounded px-[3px] py-1">
                                <p className="text-xs font-medium max-w-[185px] bg-[#FFFAEB] truncate text-[#B54708]">
                                    {t("product:stock")} :{" "}
                                    {item?.revisionNo ? (item?.revisionNo != 0 ? `REV-${item?.revisionNo}/${item?.stockCode} ` : item?.stockCode) : item?.stockCode || "--"}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center gap-x-2 w-[100%]">
                       
                            <div className="flex flex-col h-[42px] w-[52%] min-w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
                                <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
                                    <p className="text-[10px] font-medium text-secondary-600">{"Stokta"}</p>
                                </div>
                                <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
                                    <p className="text-xs font-medium text-secondary-700">{item?.qualityQuantity || "--"}</p>
                                </div>
                            </div>
                            {/* <div className="flex flex-row items-center mt-1.5">
              <PieceIconss />
              <p className="text-sm font-medium ml-1 text-secondary-700">{item?.qualityQuantity || "--"}</p>
              <p className="text-[10px] ml-0.5 mt-0.5 font-normal text-secondary-500">{t("product:qtyCurrentStock")}</p>
            </div>
            <div className="flex flex-row items-center mt-0.5">
              <SupplyIconss />
              <p className="text-sm font-medium ml-1 text-secondary-700">{"0" || "--"}</p>
              <p className="text-[10px] ml-0.5 mt-0.5 font-normal text-secondary-500">{t("product:qtySupply")}</p>
            </div> */}
                        
                        <div className="w-[45%] h-[42px] flex items-center mt-1.5 self-stretch border  border-[#E4E7EC] rounded ml-auto mr-2 overflow-hidden ">
                            <div className="w-[42px] h-[18px] px-[1px] py-1 -ml-[11.5px] -mr-3 -rotate-90 justify-center items-center flex gap-2 border-b  border-[#E4E7EC] bg-[#F9FAFB]">
                                <span className="text-xxs truncate text-[#475467] font-medium ">{t("product:lastProcess")}</span>
                            </div>
                            <div className="flex flex-col items-start p-1">
                                <div className="flex flex-row items-center ">
                                    <DecraceStockIcon />
                                    <p className="text-xs font-medium ml-1 text-secondary-600">{"0" || "--"}</p>
                                    <p className="text-xxs ml-0.5 font-normal text-secondary-600">{t("product:pcs")}</p>
                                </div>
                                <div className="flex flex-row items-center ">
                                    <CalendarIcons />
                                    <p className="text-xs font-medium ml-1 text-secondary-600">{"--" || "--"}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={`flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 bg-[#F2F4F7] border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px]`}
                >
                    <p className={`text-xxs font-semibold text-secondary-600 -rotate-90`}>{t("product:productss")}</p>
                </div>
                {!disableMenu && (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            if (pathname?.pathname?.includes("raw-material/list")) {
                            } else {
                                setIsOpen(!isOpen);
                            }
                        }}
                        className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
                    >
                        <NewDotsIcon />
                    </div>
                )}

                <Tooltip id={`${item?.id}`} />
            </div>
        </>
    )
}

export default StockDetailRecipeProductCard
import React, { useEffect, useState } from "react";
import { Button } from "../../../components";
import { embeddedServerService } from "../../../services/embedded-server.service";
import { DeleteIcon } from "../../../assets/icons/productIcons";
import { Toast } from "../../../utils/toastify/toast";
import { RefreshIcon } from "./svg/EmbeddedServerIcons";

const DigitalSensorCard = ({
  isActive,
  name,
  whichSensor,
  metadataId,
  savedData,
  setDraggedSensor,
  sensorId,
  refetch,
  priorityList,
  deviceId,
  sensorTypes,
  typeId,
  cards,
  activeValueButtons
}) => {
  const [value, setValue] = useState(0);
  const [isDrag, setIsDrag] = useState(false);
  const [isConsumption, setIsConsumption] = useState(false);
  const [selectedOption, setSelectedOption] = useState("empty");

  const sensorData = { sensorId, value, isConsumption, whichSensor, metadataId, type: "digital" };
  const saveSensor = async (consumption) => {
    await embeddedServerService.saveDigital({ sensorId, isConsumption: consumption, whichSensor, metadataId, type: "digital" }).then(() => refetch());
  };

  const findByPriorityCard = cards?.filter((a) => {
    if (a?.digitalSensor) {
      return a?.digitalSensor?.id === sensorId;
    }
  });
  const valueButtons = [{ value: 0 }, { value: 1 }];

  const resetSensor = async () => {
    const values = {
      deviceId,
      whichSensor,
      status: "reset",
      metadata: metadataId,
    };
    await embeddedServerService.resetDigital(values).then((res) => {
      Toast("success", res.data.message);
      refetch();
    });
  };
  const handleChange = async (event) => {
    setSelectedOption(event.target.value);
    await embeddedServerService
      .saveDigital({ sensorId, isConsumption, whichSensor, metadataId, type: "digital", typeId: event.target.value })
      .then(() => refetch());
  };
  const findOption = sensorTypes.find((e) => e?.value === selectedOption);
  useEffect(() => {
    if (savedData?.isConsumption || savedData?.value || typeId) {
      setValue(savedData?.value);
      setIsConsumption(savedData?.isConsumption);
      setSelectedOption(typeId || "empty");
    }
  }, [savedData]);

  return (
    <div
      onDragStart={(e) => {
        setIsDrag(true);
        setDraggedSensor(
          sensorData.sensorId
            ? { ...sensorData, recordSensorId: sensorId }
            : { ...sensorData, sensorId: `${whichSensor}-${sensorData.type}-${sensorData.value}`, recordSensorId: sensorId }
        );
      }}
      onDragEnd={(e) => {
        setDraggedSensor(null);
        setIsDrag(false);
      }}
      draggable={isActive === 1 ? true : false}
      style={{ opacity: isDrag ? 0 : 1 }}
      className={`w-full h-[48px] border border-secondary-200 transition-all rounded-xl flex items-center ${
        isActive ? " hover:border-gray-300 active:shadow-none bg-white shadow cursor-grab" : "bg-gray-50"
      }`}
    >
      <div className="h-full w-[20%] flex items-center pl-3">
        <div style={{ backgroundColor: isActive ? "#17B26A" : "#F04438" }} className={`w-3 h-3 rounded`} />
        <p className="font-semibold text-secondary-700 ml-2">{name || "Sensor Name"}</p>
      </div>
      <div className="w-[30%] flex h-full items-center justify-center">
        <div className="w-[170px] h-9 border rounded-lg border-gray-300 flex items-center px-3.5 cursor-default relative bg-white">
          <p className="text-sm text-gray-700 flex-1 truncate">{selectedOption ? findOption?.label : "Select an item.."}</p>
          <ArrowDown />
          <select defaultChecked id="select" value={selectedOption} onChange={handleChange} className="absolute inset-0 opacity-0">
            {sensorTypes?.map((item) => (
              <option disabled={item?.value === "empty"} value={item?.value}>
                {item?.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="flex items-center justify-center w-[20%] h-full">
        <div className="flex gap-2">
          {valueButtons.map((item, index) => {
            return (
              <Button
                width={38}
                size={"sm"}
                key={index}
                label={String(item.value)}
                disabled={!isActive || !activeValueButtons?.includes(item.value)}
                colorType={value === item.value ? "primary-embedded" : "secondary-gray"}
                onClick={() => {
                  setValue(item.value);
                }}
              />
            );
          })}
        </div>
      </div>
      <div
        onClick={() => {
          if (isConsumption) {
            setIsConsumption(false);
            saveSensor(false);
          } else {
            setIsConsumption(true);
            saveSensor(true);
          }
        }}
        className="w-[20%] flex items-center justify-center h-full hover:bg-gray-50"
      >
        <div className={`w-5 h-5 rounded-md flex items-center justify-center ${isConsumption ? "bg-[#3E4784]" : "border border-gray-300"}`}>
          {isConsumption && <CheckIcon />}
        </div>
      </div>
      {sensorId && (
        <>
          {!findByPriorityCard?.length > 0 && (
            <div onClick={resetSensor} className="flex-1 items-center justify-center flex hover:bg-red-50 h-full rounded-r-xl cursor-default">
              <RefreshIcon />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const CheckIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
      <path d="M12.1667 3.5L5.75004 9.91667L2.83337 7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

const ArrowDown = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M5 7.5L10 12.5L15 7.5" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default DigitalSensorCard;

import React, { useCallback, useEffect, useRef, useState } from "react";
import generateFileUrl from "../../../utils/generateFileUrl";
import {
  DateCardIcon,
  FreeStockIcon,
  NewAcceptedIcon,
  NewDotsIcon,
  NewRejectedIcon,
  PieceIcon,
  RezervStockIcon,
} from "../../../components/new-cards/card-icons";
import { useTranslation } from "react-i18next";
import { DetailIcon, PencilIcon } from "../../../assets/icons/productIcons";
import { QrCode, SmallDeleteIcon } from "../../../assets/icons/commonIcons";
import { orderService } from "../../../services/order.service";
import { useNavigate } from "react-router-dom";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";
import { Tooltip } from "react-tooltip";
import { CustomModal, Loading } from "../../../components";
import OrderEditModal from "../modals/OrderEditModal";
import { Toast } from "../../../utils/toastify/toast";
import moment from "moment";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import CustomImage from "../../../components/CustomImage";
import WipExitModal from "../modals/WipExitModal";
import OrderWipProductCardTable from "./OrderWipProductCardTable";

const CreateOrderPageProductCard = ({ item, addStock = false, refetch, qrFunction }) => {
  
  const { t } = useTranslation();
  const menuRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState("");
  const [openWipExitModal, setOpenWipExitModal] = useState(false);
  const [isCardClick, setIsCardClick] = useState(false);
  const [selectedCard, setSelectedCard] = useState([])
  const [loading, setLoading] = useState(false)
  const menuOptions = [
    {
      label: t("product:orderDetail"),
      value: "details",
      icon: <DetailIcon color={"#667085"} />,
    },
    {
      label: t("product:orderEdit"),
      value: "edit",
      icon: <PencilIcon />,
    },
    { label: t("buttons:delete"), value: "delete", icon: <SmallDeleteIcon /> },
    { label: t("product:getQrCodes"), value: "qr", icon: <QrCode /> },
    { label: t("product:sendToOrder"), value: "wipExit", icon: <QrCode /> },
  ];

  const onDeleted = async () => {
    await orderService.orderDelete(deleteId).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        refetch();
        setIsAlert(false);
        setDeleteId("");
      } else {
        Toast("error", res.data.message);
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };


  const handleClickOutside = useCallback((event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsCardClick(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);


  const orderWipProduct = async (id) => {
    setLoading(true)
    await orderService.getOrderWip(id).then((res) => {
      setSelectedCard(res?.data)
      setLoading(false)
    });
  };

  return (
    <>
      <div
        ref={menuRef}
        data-tooltip-id={`${item?.id}`}
        data-tooltip-place={"right"}
        data-tooltip-content={`
      ${t("product:productName")} : ${item?.name} 
    
      `}
        className="flex bg-white flex-row  w-[324px] min-w-[324px] h-[112px] min-h-[112px] relative group pl-3 pr-3  pt-2 border border-[#D0D5DD] rounded-lg cursor-pointer "
      >
        <div className="flex flex-col w-[93%]">
          <div className="flex flex-row items-center gap-x-3">
            {item?.image ? (
              <div>
                <CustomImage
                  borderRadius={2}
                  src={item?.image}
                  style={{
                    width: 64,
                    height: 48,
                    minWidth: 64,
                    minHeight: 48,
                    borderRadius: 4,
                    borderWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}
            {/* <img
              className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-300 select-none"
              src={generateFileUrl(item?.image)}
            /> */}
            <div className="flex flex-col items-start">
              <div className="flex flex-row items-center gap-x-1 w-full">
                <p className="font-semibold text-secondary-900 text-base w-[185px] truncate">{item?.name || "Product Name"}</p>
              </div>
              {addStock === true ? (
                <div className="bg-[#FFFAEB] max-w-[185px] h-6 rounded px-[3px] py-1">
                  <div className="flex flex-row items-center ">
                    <p className="text-xs font-medium max-w-[185px] truncate text-[#B54708]">
                      {t("product:stock")} : {item?.stockCode || "--"} +
                    </p>
                    <div className="flex flex-row items-center ml-1">
                      <PieceIconProduct />
                      <p className="text-xs font-medium ml-1 max-w-[185px] truncate text-[#B54708]">1500</p>
                      <p className="text-[10px] font-normal ml-0.5 text-[#B54708]">ad.</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="bg-[#FFFAEB] max-w-[198px] h-6 rounded px-[3px] py-1">
                  <p className="text-xs font-medium max-w-[198px] truncate text-[#B54708]">
                    {t("product:stock")} : {item?.stockCode || "--"}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-1 w-[100%]">
            <div className="flex flex-col h-[42px] w-[28%] min-w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
              <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
                <p className="text-[10px] font-medium text-secondary-600">{t("product:orderQty")}</p>
              </div>
              <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
                <p className="text-xs font-medium text-secondary-700">{item?.piece || "0"}</p>
              </div>
            </div>
            <div className="flex flex-col h-[42px] min-w-[28%] w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
              <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
                <p className="text-[10px] font-medium text-secondary-600">{t("product:inStock")}</p>
              </div>
              <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
                <p className="text-xs font-medium text-secondary-700">{item?.qualityQuantity || "0"}</p>
              </div>
            </div>

            <div onClick={() => {
              setIsCardClick(true && !isCardClick);
              setIsOpen(false)
              if (!isCardClick) {
                orderWipProduct(item?.id)
              }
            }} className="w-[44%] h-[42px] flex items-center mt-1.5 hover:bg-[#F9FAFB] self-stretch border  border-[#E4E7EC] rounded ml-auto overflow-hidden ">
              {loading ? (
                <div className="flex ml-[45px] items-center justify-center">
                  <Loading size={18} secondary={"#B54708"} />
                </div>
              ) : (
                <>
                  <div className="w-[42px] min-w-[42px] h-[18px] px-[1px] py-1 -ml-[11.5px] -mr-3 -rotate-90 justify-center items-center flex gap-2 border-b  border-[#E4E7EC] bg-[#F9FAFB]">
                    <span className="text-xxs truncate text-[#475467] font-medium ">{t("product:workOrder")}</span>
                  </div>
                  <div className="flex flex-col w-full items-start">
                    <div className="flex flex-row items-center w-full border-b px-1">
                      <p className="text-xxs font-normal text-secondary-600">{t("product:production")}</p>
                      <p className="text-xs font-medium ml-auto text-secondary-600">{item?.piece || "0"}</p>
                    </div>
                    <div className="flex flex-row items-center w-full px-1">
                      <p className="text-xxs font-normal text-secondary-600">{t("product:ready")}</p>
                      <p className="text-xs font-medium ml-auto text-secondary-600">{item?.finishedPiece || "0"}</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className={`absolute right-0 bg-[#F2F4F7] bottom-0 flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px] text-secondary-600`}
        >
          <p className={`text-xxs font-semibold text-secondary-600 -rotate-90 whitespace-nowrap`}>{t("person:product")} ({t("product:pcs")})</p>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
          className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <NewDotsIcon />
        </div>
        {isOpen && (
          <div className="absolute w-[190px] p-1 bg-white right-3 top-[16px] border border-secondary-200 rounded-lg shadow-sm flex flex-col z-[160]">
            {menuOptions?.map((items, index) => {
              return (
                <button
                  onClick={() => {
                    if (items.value === "delete") {
                      setIsAlert(true);
                      setDeleteId(item?.id);
                    } else if (items.value === "details") {
                      navigate(`/app/product/mrp/work-in-process/edit/${item?.id}`);
                    } else if (items.value === "qr") {
                      qrFunction(item);
                    } else if (items.value === "edit") {
                      setEditModal(true);
                    } else if (items.value === "wipExit") {
                      setOpenWipExitModal(true);
                    }
                    setIsOpen(false);
                  }}
                  key={index}
                  type="button"
                  className="w-full h-[38px] flex items-center px-2.5 hover:bg-secondary-100 rounded"
                >
                  {items?.icon}
                  <p className={`font-medium text-sm ${index == 2 ? "text-[#D92D20]" : "text-secondary-700"}  ml-1`}>
                    {index === "taskList" ? "" : items?.label}
                  </p>
                </button>
              );
            })}
          </div>
        )}
        <Tooltip className="z-[120]" id={`${item?.id}`} />
        {isCardClick === true && (
          <div className="z-[140] absolute top-[118px]  -left-1 items-start w-[328px]">
            <OrderWipProductCardTable setIsCardClick={setIsCardClick} loading={loading} selectedCard={selectedCard} />
          </div>
        )}
      </div>


      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(deleteId);
        }}
      />
      <CustomModal
        isOpen={openWipExitModal}
        setIsOpen={setOpenWipExitModal}
        modalTitle={t("product:transitionWipOrder")}
        subTitle={t("product:sendToCompletedOrder")}
        width={900}
        padding={true}
        children={<WipExitModal item={item} />}
      />
      <CustomModal
        isOpen={editModal}
        setIsOpen={setEditModal}
        onClose={() => {
          setEditModal(false);
        }}
        modalTitle={t("product:createNewOrder")}
        children={<OrderEditModal selectedProduct={item} setIsOpen={setEditModal} orderData={item} refresh={refetch} editData={item} t={t} />}
      />
    </>
  );
};

export default CreateOrderPageProductCard;

export const OrdersIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        d="M10 5.25V3.4C10 2.55992 10 2.13988 9.83651 1.81901C9.6927 1.53677 9.46323 1.3073 9.18099 1.16349C8.86012 1 8.44008 1 7.6 1H4.4C3.55992 1 3.13988 1 2.81901 1.16349C2.53677 1.3073 2.3073 1.53677 2.16349 1.81901C2 2.13988 2 2.55992 2 3.4V8.6C2 9.44008 2 9.86012 2.16349 10.181C2.3073 10.4632 2.53677 10.6927 2.81901 10.8365C3.13988 11 3.55992 11 4.4 11M6 4.5H4M7 3H4M5 6H4M5.90224 7.57926L7.7523 6.54548C7.82336 6.50578 7.85889 6.48592 7.89654 6.47812C7.92987 6.47122 7.96425 6.47119 7.99758 6.47803C8.03525 6.48577 8.07081 6.50557 8.14194 6.54515L10 7.57926M5.90224 7.57926L7.94703 8.71863M5.90224 7.57926V9.61976C5.90224 9.70508 5.90224 9.74774 5.91478 9.78582C5.92587 9.81951 5.944 9.85045 5.96797 9.8766C5.99506 9.90615 6.03227 9.927 6.1067 9.96871L7.94703 11M10 7.57926L7.94703 8.71863M10 7.57926V9.61931C10 9.70478 10 9.74752 9.98743 9.78566C9.9763 9.81939 9.95811 9.85037 9.93408 9.87653C9.90691 9.9061 9.86959 9.92693 9.79495 9.96858L7.94703 11M7.94703 8.71863V11"
        stroke="#475467"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const PieceIconProduct = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <g clip-path="url(#clip0_17757_65581)">
        <path
          d="M4.125 1H2.25C1.55964 1 1 1.55964 1 2.25V9.75C1 10.4404 1.55964 11 2.25 11H9.75C10.4404 11 11 10.4404 11 9.75V2.25C11 1.55964 10.4404 1 9.75 1H7.875M4.125 1V3.89286C4.125 3.94139 4.17672 3.97242 4.21954 3.94958L6 3L7.78046 3.94958C7.82328 3.97242 7.875 3.94139 7.875 3.89286V1M4.125 1H7.875"
          stroke="#DC6803"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17757_65581">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

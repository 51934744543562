import React, { useState } from "react";
import { EquipmentEditIcon, NewDotsIcon } from "../../../components/new-cards/card-icons";
import { legacyFormatSeconds } from "../../../utils/secondToHoursAndMinute";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../hooks/useStores";
import { PurchaseIcon } from "../../../assets/icons/productIcons";
import { CustomModal } from "../../../components";
import EditStockQuantityModal from "../modals/EditStockQuantityModal";
import StockCardOperationModal from "../modals/StockCardOperationModal";
import ExternalInternalRequestForm from "../modals/ExternalInternalRequestForm";

const NewStockOperationCard = ({ item, occurrences, onClick, product, refetch, stockData, disableMenu }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { auth } = useStore();
  const [isOpen, setIsOpen] = useState(false);
  const [isPurchased, setIsPurchased] = useState(false);
  const [stockEditModal, setStockEditModal] = useState(false);
  const [openStockQuantityEdit, setOpenStockQuantityEdit] = useState(false);
  const statusColor = () => {
    switch (item?.status) {
      case "finished":
        return "#ECFDF3";
      case "started":
        return "#FFFAEB";
      case "rejected":
        return "#D92D20";
      default:
        return "#F2F4F7";
    }
  };

  const operationTypes = (type) => {
    switch (type) {
      case "conversion_operation":
        return "DÖN";
      case "supporter_operation":
        return "YAR";
      case "assembly_operation":
        return "MON";
      case "decomposition_operation":
        return "AYR";
    }
  };

  const menuOptionsSelected = [
    { label: t("product:purchaseRequestCreation"), value: "manuel_stock", icon: <PurchaseIcon /> },
    {
      label: t("product:editStock"),
      value: "stockEdit",
      icon: <EquipmentEditIcon />,
    },
  ];

  const menuOptions =
    auth?.user?.company?.id == "e5c06127-3c5d-44b9-8403-74c160fc7f59" ? menuOptionsSelected : menuOptionsSelected.filter((i) => i.value !== "stockEdit");

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          navigate(`/app/product/mrp/stock/operation-detail/${item?.type}/${item?.id}`);
        }}
        className={`flex w-[312px] h-[112px] flex-col border items-start cursor-pointer rounded-lg border-secondary-300 bg-white relative group `}
      >
        {/* <div style={{ backgroundColor: statusColor() }} className="flex w-full min-h-[4px] max-h-[4px] h-1 rounded-t-[8px]"></div> */}
        <div className="flex flex-col w-[92.5%]">
          <div className="flex flex-row items-center w-[100%]  justify-between pr-1 py-2 pl-3">
            <div className="flex flex-row items-center  h-5 ">
              <div className="px-1  border h-full w-fit rounded bg-[#F2F4F7]">
                {item?.externalOperation ? (
                  <p className="text-xs font-semibold text-secondary-700">{"HİZ"}</p>
                ) : (
                  <p className="text-xs font-semibold text-secondary-700">
                    {item?.processType || item?.process?.type ? operationTypes(item?.processType || item?.process?.type) : "--"}
                  </p>
                )}
              </div>
              <div className="px-1 bg-white max-w-[230px] rounded-r-[8px]">
                <p className="font-semibold text-sm ml-1 text-secondary-700 truncate">
                  {occurrences > 1 ? `${item?.process?.name} ${occurrences}` : item?.process?.name}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center w-full h-full">
            <div className="flex flex-col pr-2 pl-3 w-[37%] min-w-[37%] h-full">
              <div className="flex flex-row items-center w-full justify-between  border-b pb-0.5">
                <p className="font-normal text-secondary-600 ml-1 text-xxs">{t("product:inStocks")}</p>
                <p className="text-xs font-medium max-w-[50px]  truncate text-secondary-700">{item?.previousPiece || "0"}</p>
              </div>
              <div className="flex flex-row items-center w-full justify-between border-b pb-0.5  pt-[4px] ">
                <p className="font-normal ml-1 text-xxs text-[#D92D20]">{t("product:reject")}</p>
                <p className="text-xs font-medium max-w-[40px] truncate text-[#D92D20]">{item?.plannedJunctionLength || 0}</p>
              </div>
              <div className="flex flex-row items-center w-full pb-0.5 pt-[4px] justify-between">
                <p className="font-normal text-secondary-600 ml-1 text-xxs">{t("product:cost")}</p>
                <p className="text-xs font-medium text-secondary-700">$732,32</p>
              </div>
            </div>
            <div className="flex flex-row items-center w-[63%] min-w-[63%] rounded-tl-[4px] border border-r-0 border-b-0 border-[#E4E7EC]">
              <div className="flex flex-col items-start border-r w-1/2 border-r-[#E4E7EC]">
                <div className="px-1 py-[3px] flex items-center rounded-tl-[4px] justify-center border-b w-full bg-[#F9FAFB]">
                  <p className="text-xs font-medium text-secondary-700">{t("product:cycleTime")}</p>
                </div>
                <div className="flex flex-col px-[6px] w-full">
                  <div className="flex px-1.5 py-[3px] flex-row items-center w-full justify-between border-b border-[#E4E7EC]">
                    <p className="text-[10px] text-secondary-500 font-normal">{t("product:target")}</p>
                    <p className="font-medium text-secondary-600 text-xs">{legacyFormatSeconds(item?.estimatedTime) || "--"}</p>
                  </div>
                  <div className="flex px-1.5 py-[1.5px] flex-row items-center w-full justify-between">
                    <p className="text-[10px] text-secondary-500 font-normal">{t("product:applieds")}</p>
                    <p
                      className={`font-medium ${
                        item?.internalOperation?.estimatedTime < item?.newAvgActualCalculate ? "text-[#F04438]" : "text-[#079455] "
                      } text-xs`}
                    >
                      {legacyFormatSeconds(item?.newAvgActualCalculate) || "--"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center  w-1/2">
                <div className="px-1 py-[3px] flex items-center rounded-tl-[4px] justify-center border-b w-full bg-[#F9FAFB]">
                  <p className="text-xs font-medium text-secondary-700">{t("product:setupTime")}</p>
                </div>
                <div className="flex flex-col px-[6px] w-full">
                  <div className="flex px-1.5 py-[3px] flex-row items-center  gap-x-0.5 border-b border-[#E4E7EC]">
                    <p className="text-[10px] text-secondary-500 font-normal">{t("product:target")}</p>
                    <p className="font-medium text-secondary-600 text-xs">{item?.qualityAcceptedPiece || "--"}</p>
                  </div>
                  <div className="flex px-1.5 py-[1.5px] flex-row items-center gap-x-0.5">
                    <p className="text-[10px] text-secondary-500 font-normal">{t("product:applieds")}</p>
                    <p className="font-medium text-[#475467] text-xs">{item?.qualityRejectedPiece || "--"}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundColor: statusColor() }}
            className={`absolute right-0 bottom-0 flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px] text-secondary-600`}
          >
            <p
              className={`text-xxs font-semibold ${
                item?.status === "started"
                  ? "text-[#CA8504]"
                  : item?.status === "finished"
                  ? "text-[#079455]"
                  : item?.status === "rejected"
                  ? "text-white"
                  : "text-[#475467]"
              } -rotate-90 whitespace-nowrap`}
            >
              {t("product:intermediProduct")}
            </p>
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
            }}
            className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
          >
            <NewDotsIcon />
          </div>
          {isOpen && (
            <div className="hidden flex-col absolute min-w-[245px] max-w-[245px] h-fit bg-white border top-[28px] right-[8px] z-[110] rounded-lg px-[6px] py-1 group-hover:flex">
              {menuOptions?.map((items, index) => {
                return (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      if (items.value === "details") {
                        onClick();
                      }
                      if (items.value === "form") {
                        setIsPurchased(true);
                        setIsOpen(false);
                      }
                      if (items.value === "stockEdit") {
                        setStockEditModal(true);
                        setIsOpen(false);
                      }
                      if (items.value === "manuel_stock") {
                        setOpenStockQuantityEdit(true);
                      }
                      setIsOpen(false);
                    }}
                    key={index}
                    type="button"
                    className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                      items?.value === "delete" ? "hover:bg-[#FECDCA]" : "hover:bg-secondary-100"
                    }`}
                  >
                    {items?.icon}
                    <p className={`font-medium text-sm  ${items?.value === "delete" ? "text-[#D92D20]" : "text-secondary-700"}`}>{items.label}</p>
                  </button>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <CustomModal
        isOpen={isPurchased}
        setIsOpen={setIsPurchased}
        // onClose={() => {
        //   setIsPurchased(false);
        // }}
        modalTitle={t("product:purchaseRequestForm")}
        children={
          <ExternalInternalRequestForm
            setIsOpen={isPurchased}
            closeModal={() => {
              setIsPurchased(false);
            }}
            purchaseData={item}
            product={product}
          />
        }
      />
      <CustomModal
        isOpen={stockEditModal}
        setIsOpen={setStockEditModal}
        modalTitle={t("product:editStock")}
        children={
          <StockCardOperationModal
            setIsOpen={setStockEditModal}
            closeModal={() => {
              setStockEditModal(false);
            }}
            refetch={refetch}
            product={product}
            purchaseData={item}
          />
        }
      />

      <CustomModal
        isOpen={openStockQuantityEdit}
        setIsOpen={setOpenStockQuantityEdit}
        onClose={() => {
          setOpenStockQuantityEdit(false);
        }}
        modalTitle={t("product:editStockQuantity")}
        width={400}
        children={
          <EditStockQuantityModal
            legacyRefetch={refetch}
            onClose={() => {
              setOpenStockQuantityEdit(false);
            }}
            item={stockData}
            isOperation={item}
            isStock={true}
          />
        }
      />
    </>
  );
};

export default NewStockOperationCard;

import React, { useState, useEffect } from "react";
import { Button } from "../../components";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useStore } from "../../hooks/useStores";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { purchaseService } from "../../services/purchase.service";
import useWindowDimensions from "../../hooks/useWindowDimension";
import NewOrderHeaderCard from "./components/order/NewOrderHeaderCard";
import { ArrowRight } from "../../assets/icons/equipmentIcon";
import MaterialSupplyHeaderCard from "./cards/material-supply/MaterialSupplyHeaderCard";

const MaterialSupplyListPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { settings, auth } = useStore();
  let navigate = useNavigate();
  const { height } = useWindowDimensions();
  const [selectedGroup, setSelectedGroup] = useState("all");
  const planView = useQuery({
    queryKey: ["purchase-junctions-planned"],
    enabled: false,
    queryFn: async () => await purchaseService.purchaseTransactionsPlanned(id),
    retry: 0,
  });
  const headData = useQuery({
    queryKey: ["all-supplier-group-junctions"],
    enabled: false,
    retry: 0,
    queryFn: async () => await purchaseService.getPurchaseJunctionsForCompany(),
  });
  const refetch = () => {
    planView.refetch();
    headData.refetch();
  };
  useEffect(() => {
    headData.refetch();
    if (id != ":id" && id != "undefined") {
      planView.refetch();
      setSelectedGroup(headData?.data?.operations?.find((item) => item?.id == id));
    }
  }, [id]);
  useEffect(() => {
    if (headData?.data?.operations?.length > 0 && (id == ":id" || id == "undefined")) {
      navigate(`/app/product/mrp/supply/material/${headData?.data?.operations[0]?.id}/list`);
    }
  }, [headData?.data]);
  useEffect(() => {
    if (auth?.user?.company?.id == "e5c06127-3c5d-44b9-8403-74c160fc7f59") {
    } else if (auth?.user?.company?.id == "ab77a50a-7b68-42a2-ba5c-0ebfb5c13325") {
    } else if (auth?.user?.company?.id == "71b0edec-e5d4-4929-bfdb-0e5d5e149592") {
    } else if (auth?.user?.company?.id == "dd1892b9-d9a3-44ed-9131-17c88a74a1b4") {
    } else if (auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706") {
    } else if (auth?.user?.company?.id == "49ee8491-35d7-4eb2-a0e9-bfc95541180f") {
    } else if (auth?.user?.company?.id == "65844198-a9e8-45fa-bc99-7d012a541b35") {
    } else if (auth?.user?.company?.id == "7c5bb5a6-21aa-4d1d-829b-2a6d360b9630") {
    } else if (auth?.user?.company?.id == "f7db6191-5842-455a-99f4-c76763090689") {
    } else if (auth?.user?.company?.id == "776c0df3-0a44-464f-9877-33bd731aef6a") {
    } else if (auth?.user?.company?.id == "065e9b57-9da2-4394-9359-59ba71610dc3") {
    } else if (auth?.user?.company?.id == "e41a9bde-203c-48f2-aeec-4f8ce6344513") {
    } else if (auth?.user?.company?.id == "262d8e02-57e8-427e-9159-6b18b236ca86") {
    } else if (auth?.user?.company?.id == "0c082d32-6d97-4ba9-a2a5-7e41fe8139d9") {
    } else {
      navigate("/app/product/verysoon");
    }
  }, []);

  const tabOptions = [
    { value: "list", label: "Liste" },
    { value: "junction", label: t("product:eventss") },
    { value: "plan", label: t("product:planner") },
  ];

  const tableHeadItems = [
    { name: "Malzemeler" },
    { name: "Stokta" },
    { name: "Aktif Talep" },
    { name: "Bekleyen Talep" },
    { name: "MS Planlı" },
    { name: "MS Plan Adedi" },
    { name: "" },
  ];

  return (
    <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden relative step-1 scrollbar-hide">
      <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
        <span className="mr-3 md:flex sm:flex xs:flex hidden">
          <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
        </span>
        <div className="flex items-center">
          <span className="font-codecMedium text-2xl text-black">upu.</span>
          <p className="text-2xl font-codecMedium text-[#B54708]">supply</p>
          {!(headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading) && (
            <p className="text-xl font-normal ml-2 text-secondary-500">
              {selectedGroup && selectedGroup?.name ? `${t("product:materialSupply")} / ${selectedGroup?.name}` : t("product:materialSupply")}
            </p>
          )}
        </div>
        <span className="ml-auto">
          <Button
            colorType={"primary-product"}
            size={"md"}
            label={t("product:supplierList")}
            onClick={() => navigate("/app/product/mrp/supply/material/list")}
          />
        </span>
      </div>
      {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
        <div className="w-full bg-gray-200 rounded-xl animate-pulse h-[96px] min-h-[96px] flex gap-5" />
      ) : (
        <div className="h-[44px] min-h-[44px] w-full overflow-x-auto flex ">
          {headData?.data?.operations
            ?.sort((a, b) => b?.active + b?.past + b?.pending - (a?.active + a?.past + a?.pending))
            ?.map((item, index) => {
              return (
                <div className={`min-w-[192px] ${index !== headData?.data?.operations?.length - 1 ? "border-b" : "border-b-0"} max-h-[40px] pr-5`}>
                  {/* <NewOrderHeaderCard
                    onClick={async () => {
                      navigate(`/app/product/mrp/supply/material/${item?.id}/list`);
                    }}
                    icon={item?.iconKey}
                    name={item?.name}
                    planned={item?.plannedUnplanned?.planned}
                    unPlanned={item?.plannedUnplanned?.unPlanned}
                    selectedOperation={{ id: selectedGroup?.id, name: item?.name }}
                    id={item?.id}
                  /> */}
                  <MaterialSupplyHeaderCard
                    onClick={async () => {
                      navigate(`/app/product/mrp/supply/material/${item?.id}/list`);
                    }}
                    icon={item?.iconKey}
                    name={item?.name}
                    planned={item?.plannedUnplanned?.planned}
                    unPlanned={item?.plannedUnplanned?.unPlanned}
                    selectedOperation={{ id: selectedGroup?.id, name: item?.name }}
                    id={item?.id}
                  />
                </div>
              );
            })}
        </div>
      )}
      {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
        <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-gray-200 p-[6px] mt-6 mb-4 rounded-xl animate-pulse flex gap-5" />
      ) : (
        <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-[#F9FAFB] border border-[#EAECF0] p-[6px] rounded-xl flex items-center justify-center mt-4 mb-4">
          {tabOptions?.map((option, index) => (
            <div
              className={`w-1/2 h-full rounded-md px-3 py-2  flex items-center justify-center cursor-pointer ${
                "list" == option?.value ? "bg-white shadow-sm" : ""
              }`}
              onClick={() => {
                if (option?.value === "plan") navigate(`/app/product/mrp/supply/material/${id}/plan-view`);
                if (option?.value === "junction") navigate(`/app/product/mrp/supply/material/${id}/junction`);
              }}
            >
              <p className={`text-md font-semibold ${"plan" == option?.value ? "text-[#344054]" : "text-[#667085]"}`}>{option?.label}</p>
            </div>
          ))}
        </div>
      )}
      {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
        <div className="w-full flex-1 bg-gray-200 mt-4 rounded-xl animate-pulse" />
      ) : (
        <>
          <div style={{ minHeight: height - 50 }} className="flex flex-col w-full border rounded-xl">
            <div className="flex max-h-[44px] min-h-[44px] max-w-full min-w-full">
              {tableHeadItems?.map((item, i) => {
                return (
                  <div
                    key={i}
                    scope="col"
                    className={` px-4 py-3 ${
                      i === 0 ? "min-w-[336px] max-w-[336px] rounded-tl-xl" : i === 6 ? "min-w-[72px] max-w-[72px] w-[72px] rounded-tr-xl" : "w-full"
                    } items-center text-center bg-secondary-50 border-gray-200 border-b border-r last:border-r-0`}
                  >
                    <span className={`text-center rounded flex text-xs font-medium justify-center md:line-clamp-2 items-center whitespace-nowrap`}>
                      {item?.name}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="w-full bg-white flex-1 flex flex-col overflow-y-auto overflow-x-hidden scrollbar-hide rounded-xl">
              {[1, 2, 3, 4]?.map((item, index) => {
                return (
                  <div className={`flex w-full items-center border-b h-[72px] min-h-[72px]`}>
                    <div className="flex items-center h-full border-r min-w-[336px] max-w-[336px]">
                      <div className="flex w-full h-full px-6 py-3 gap-x-[6px]">
                        <div className="flex min-w-[64px] max-w-[64px] w-[64px] min-h-[48px] max-h-[48px] h-[48px] rounded border-[#0000001A] border"></div>
                        <div className="flex flex-col w-full justify-center">
                          <p className="text-[#101828] text-md font-semibold px-1 w-[200px] truncate">Raw Material 102</p>
                          <span className="flex w-fit px-1 py-[3px] rounded bg-[#FFFAEB] text-[#B54708] text-xs font-medium">Stok: 600.01.0216</span>
                        </div>
                      </div>
                      <div className="flex items-center justify-center h-full min-w-6 max-w-6 w-6 p-[3px] bg-[#F2F4F7] border-l border-[#E4E7EC]">
                        <p className="text-[#475467] text-xxs font-semibold -rotate-90 whitespace-nowrap">Raw M.</p>
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                      <p className="text-[#344054] text-sm font-medium">300</p>
                      <p className="text-[#667085] text-xs">kg</p>
                    </div>
                    <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                      <p className="text-[#344054] text-sm font-medium">300</p>
                      <p className="text-[#667085] text-xs">kg</p>
                    </div>
                    <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                      <p className="text-[#344054] text-sm font-medium">300</p>
                      <p className="text-[#667085] text-xs">kg</p>
                    </div>
                    <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                      <p className="text-[#344054] text-sm font-medium">300</p>
                      <p className="text-[#667085] text-xs">kg</p>
                    </div>
                    <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                      <p className="text-[#344054] text-sm font-medium">300</p>
                    </div>
                    <div className="flex justify-center items-center h-full  min-w-[72px] max-w-[72px] w-[72px]">
                      <Button
                        colorType={"tertiary-gray"}
                        iconLeft={<ArrowRight />}
                        size={"md"}
                        onClick={() => navigate(`/app/product/mrp/supply/material/purchase-detail/${"dd"}`)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MaterialSupplyListPage;

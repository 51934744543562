import React, { useEffect, useRef, useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import _ from "lodash";
import Badgets from "../../components/buttons/Badgets";
import { Button, CustomModal } from "../../components";
import { useTranslation } from "react-i18next";
import { arrayMoveImmutable } from "array-move";
import { externalOperationService } from "../../services/external-operation.service";
import { processService } from "../../services";
import { useNavigate, useParams } from "react-router-dom";
import { Toast } from "../../utils/toastify/toast";
import { ArrowLeftIcon, PlusIcon } from "../../assets/icons/productIcons";
import CreateReceipeModal from "./modals/CreateReceipeModal";
import { productService } from "../../services/product.service";

const SortableItem = SortableElement(
  ({
    value,
    setIsOpenAddOperationModal,
    setUpdateOperation,
    number,
    setItems,
    oldItems,
    setStepOneData,
    setStepTwoData,
    setStepThreeData,
    externalProcess,
    internalProcess,
    t,
  }) => {
    const menuRef = useRef();

    const operationTypes = (type) => {
      switch (type) {
        case "conversion_operation":
          return "DÖN";
        case "supporter_operation":
          return "YAR";
        case "assembly_operation":
          return "MON";
        case "decomposition_operation":
          return "AYR";
        default:
          return "-";
      }
    };

    const deleteReceipeOperation = async (value) => {
      const formatToObj = oldItems?.flat();

      const values = formatToObj.filter((item) => item?.step !== value[0]?.step);

      let groupedOperations = _.groupBy(values, "step");
      let operationsNew = Object.keys(groupedOperations).map((key) => {
        return groupedOperations[key];
      });
      setItems(operationsNew);
    };

    const getOperations = async (type, processId, operations) => {
      if (type == "internal") {
        const filtered = internalProcess.filter((item) => item.value == processId);
        const filteredStations = filtered[0]?.stations;

        const mergeData = filteredStations?.filter((item) => operations?.some((op) => op.id === item.id));

        const data = mergeData?.map((item) => {
          const operation = operations.find((op) => op.id === item.id);
          return {
            ...item,
            ...operation,
          };
        });

        return data;
      } else {
        const filtered = externalProcess.filter((item) => item.value == processId);

        const filteredStations = filtered[0]?.supplier;

        const mergeData = filteredStations?.filter((item) => operations?.some((op) => op.id === item.id));

        const data = mergeData?.map((item) => {
          const operation = operations.find((op) => op.id === item.id);
          return {
            ...item,
            ...operation,
          };
        });

        return data;
      }
    };

    return (
      <div ref={menuRef} className="select-none">
        <div className="flex flex-col w-[300px] ">
          <div className="flex flex-row items-center w-full pb-2">
            <Badgets colorType={"fill-warning"} size={"sm"} label={number + ".Op"} />
            <div className="items-center gap-x-1 ml-auto hidden group-hover:flex">
              <span
                className="flex max-w-5 min-w-5 w-5 max-h-5 min-h-5 h-5 cursor-pointer"
                onClick={async () => {
                  setUpdateOperation(value);
                  setIsOpenAddOperationModal(true);

                  setStepOneData({
                    id: value[0]?.id,
                    operationType: value[0]?.type,
                    operation: value[0]?.process?.id,
                    enabled: value[0]?.hasQualityControl,
                    qualityPercent: value[0]?.qualityPercent,
                    description: value[0]?.description,
                    step: value[0]?.step,

                    operations: {
                      value: value[0]?.process?.id,
                      label: value[0]?.processName || value[0]?.process?.name,
                      stations: await getOperations(value[0]?.type, value[0]?.process?.id, value[0]?.plannableNodes || value[0]?.plannableSupplier),
                      supplier: await getOperations(value[0]?.type, value[0]?.process?.id, value[0]?.plannableNodes || value[0]?.plannableSupplier),
                    },
                  });
                  setStepTwoData({
                    id: value[1]?.id,
                    operationType: value[1]?.type,
                    operation: value[1]?.process?.id,
                    enabled: value[1]?.hasQualityControl,
                    qualityPercent: value[1]?.qualityPercent,
                    description: value[1]?.description,
                    step: value[0]?.step,

                    operations: {
                      value: value[1]?.process?.id,
                      label: value[1]?.processName || value[1]?.process?.name,
                      stations: await getOperations(value[1]?.type, value[1]?.process?.id, value[1]?.plannableNodes || value[1]?.plannableSupplier),
                      supplier: await getOperations(value[0]?.type, value[0]?.process?.id, value[0]?.plannableNodes || value[0]?.plannableSupplier),
                    },
                  });
                  setStepThreeData({
                    id: value[2]?.id,
                    operationType: value[2]?.type,
                    operation: value[2]?.process?.id,
                    enabled: value[2]?.hasQualityControl,
                    qualityPercent: value[2]?.qualityPercent,
                    description: value[2]?.description,
                    step: value[0]?.step,

                    operations: {
                      value: value[2]?.process?.id,
                      label: value[2]?.processName || value[2]?.process?.name,
                      stations: await getOperations(value[2]?.type, value[2]?.process?.id, value[2]?.plannableNodes || value[2]?.plannableSupplier),
                      supplier: await getOperations(value[0]?.type, value[0]?.process?.id, value[0]?.plannableNodes || value[0]?.plannableSupplier),
                    },
                  });
                }}
              >
                <RecipeEdit />
              </span>
              <span
                className="flex max-w-5 min-w-5 w-5 max-h-5 min-h-5 h-5 cursor-pointer"
                onClick={() => {
                  deleteReceipeOperation(value);
                }}
              >
                <RecipeClose />
              </span>
            </div>
          </div>
          <div className={`flex w-[310px] h-[112px] flex-col border items-start cursor-grap  rounded-lg border-secondary-300  bg-white relative group`}>
            {value?.length == 1 ? (
              <>
                {value?.map((b) => {
                  return (
                    <div className="flex flex-row items-center w-[100%]  justify-between pr-1 py-2 pl-3">
                      <div className="flex flex-row items-center gap-x-0.5 h-5 ">
                        {b?.type === "external" ? (
                          <div className="px-1  border h-full w-fit rounded bg-[#F2F4F7]">
                            <p className="text-sm font-semibold text-secondary-700">{"HİZ"}</p>
                          </div>
                        ) : (
                          <div className="px-1 ml-1 border h-full w-fit rounded bg-[#F2F4F7]">
                            <p className="text-sm font-semibold text-secondary-700">{b?.processType ? operationTypes(b?.processType) : "-"}</p>
                          </div>
                        )}
                        <div className=" bg-white max-w-[230px] ml-1 rounded-r-[8px]">
                          <p className="font-semibold text-sm text-secondary-700  truncate">
                            {b?.internalOperation?.name || b?.externalOperation?.name || b?.processName || b?.process?.name}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="flex flex-row items-center gap-x-2 pr-1 py-1.5 pl-3 w-[290px] min-w-[290px] max-w-[290px] ">
                {value?.map((a, index) => {
                  return (
                    <div className={`flex border items-center w-1/3 h-6 gap-x-1 flex-row rounded`}>
                      <div className="px-1 bg-[#F2F4F7] w-fit border-r h-full flex items-center">
                        {a?.type === "external" ? (
                          <p className="text-xs font-semibold text-secondary-700">{"HİZ"}</p>
                        ) : (
                          <p className="text-xs font-semibold text-secondary-700">{a?.processType ? operationTypes(a?.processType) : "-"}</p>
                        )}
                      </div>
                      <div className="bg-white truncate px-1">
                        <p className="font-semibold text-xs text-secondary-700  truncate">
                          {a?.internalOperation?.name || a?.externalOperation?.name || a?.processName || a?.process?.name}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            <div className="flex flex-row items-start w-full border-b border-t bg-[#F9FAFB]">
              <div className="px-3 py-[3px] w-full">
                <p className="text-xs font-medium text-secondary-700">{t("product:cycleTimeStock")}</p>
              </div>
            </div>

            <div className="flex flex-col items-center px-2 pt-1 w-full">
              <div className="flex flex-row items-center w-full justify-between">
                <p className="text-xs font-normal text-secondary-500">{t("product:target")}</p>
                <p className="text-sm max-w-[220px] truncate font-medium text-secondary-600">
                  {value &&
                    value.length > 0 &&
                    (value
                      ?.reduce((sum, item) => {
                        const itemTotal = item?.avgEstimatedTime == null ? 0 : Number(item?.avgEstimatedTime)?.toFixed(0);
                        return sum + itemTotal / value?.length;
                      }, 0)
                      .toFixed(2) ||
                      "---")}
                </p>
              </div>
              <div className="flex flex-row items-center w-full justify-between">
                <p className="text-xs font-normal text-secondary-500">uygulanan</p>
                <p className="text-sm font-medium text-[#079455]">
                  {value && value.length > 0
                    ? value?.reduce((sum, item) => {
                        const itemTotal = item?.estimatedTime == null ? 0 : Number(item?.estimatedTime)?.toFixed(0);
                        return sum + itemTotal / value?.length;
                      }, 0)
                    : "---"}
                </p>
              </div>
            </div>
          </div>
          <div className="w-[30px] h-px bg-gray-300 absolute -right-[0px] bottom-[57px]" />
        </div>
      </div>
    );
  }
);

const SortableList = SortableContainer(
  ({
    setItems,
    items,
    setIsOpenAddOperationModal,
    setUpdateOperation,
    updateOperation,
    setStepOneData,
    setStepTwoData,
    setStepThreeData,
    internalProcess,
    externalProcess,
    t,
  }) => {
    return (
      <div className="w-full flex flex-wrap h-full gap-y-8">
        {items?.map((value, index) => {
          return (
            <div className="flex flex-col relative h-[145px]">
              <div className="relative group w-[340px] flex">
                <SortableItem
                  key={String(index)}
                  index={index}
                  value={value}
                  number={value[0]?.step}
                  updateOperation={updateOperation}
                  setUpdateOperation={setUpdateOperation}
                  setIsOpenAddOperationModal={setIsOpenAddOperationModal}
                  setItems={setItems}
                  oldItems={items}
                  setStepOneData={setStepOneData}
                  setStepTwoData={setStepTwoData}
                  setStepThreeData={setStepThreeData}
                  externalProcess={externalProcess}
                  internalProcess={internalProcess}
                  t={t}
                />
              </div>
            </div>
          );
        })}
        <div
          className="h-[112px] flex mt-[30px]"
          onClick={() => {
            setUpdateOperation(null);
            setIsOpenAddOperationModal(true);
          }}
        >
          <div className="w-[300px] h-[112px] border border-gray-400 rounded-lg flex items-center justify-center cursor-pointer shadow border-dotted">
            <PlusIconNew />
          </div>
        </div>
      </div>
    );
  }
);

const StockCreateRecipeProduct = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { stockId } = useParams();
  const [items, setItems] = useState([]);
  const [updateOperation, setUpdateOperation] = useState(null);
  const [isOpenAddOperationModal, setIsOpenAddOperationModal] = useState(false);
  const [internalProcess, setInternalProcess] = useState([]);
  const [externalProcess, setExternalProcess] = useState([]);
  const [isOpenModalTab, setIsOpenModalTab] = useState(false);
  const FirstFormData = JSON.parse(sessionStorage.getItem("stockCreateProductFirstData"));

  const defaultStepData = {
    type: "",
    processId: "",
    processName: "Yeni Operasyon Ekle",
    hasQaulityControl: false,
    qualityPercent: "",
    description: "",
    step: "",
    estimatedTime: "",
    estimatedSettingsTime: "",
    costOfMinute: "",
    currency: "",
    process: {
      id: "",
    },
    plannableSupliers: [],
    plannableNodes: [],
  };

  const [isCancelConfirmed, setIsCancelConfirmed] = useState(false);

  const handleCancelClick = () => {
    if (!isCancelConfirmed) {
      Toast("error", "Eğer geri gelirseniz, eklediğiniz reçete silinecektir.");
      setIsCancelConfirmed(true);
    } else {
      navigate(-1);
    }
  };

  const [stepOneData, setStepOneData] = useState(defaultStepData);
  const [stepTwoData, setStepTwoData] = useState(defaultStepData);
  const [stepThreeData, setStepThreeData] = useState(defaultStepData);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const Items = arrayMoveImmutable(items, oldIndex, newIndex);

    const updatedItems = Items.map((group, groupIndex) => {
      return group.map((item) => ({
        ...item,
        step: groupIndex + 1,
        entityId: item?.processId,
      }));
    });

    setItems(updatedItems);
  };

  const handleInternalProcessUpdate = async () => {
    let datas = [];
    await processService.activeProcessesWithStations().then((res) => {
      const newTabsData = res?.data.map((item) => ({
        stations: item.stations,
        supplier: item.supplier,
        label: item.name,
        value: item.id,
        operationType: item.type,
      }));
      datas = newTabsData || [];
    });

    return await setInternalProcess(datas);
  };

  const handleExternalProcessUpdate = async (index) => {
    let datas = [];

    await externalOperationService.listOperation().then((res) => {
      const newTabsData = res?.data.map((item) => ({
        stations: item.stations,
        supplier: item.supplier,
        label: item.name,
        value: item.id,
      }));
      datas = newTabsData || [];
    });

    return await setExternalProcess(datas);
  };
  useEffect(() => {
    handleInternalProcessUpdate();
    handleExternalProcessUpdate();
  }, []);

  const onSubmit = async () => {
    const sendData = {
      ...FirstFormData,
      materialId: FirstFormData?.selectedRawMaterial?.id,
      operations: items.flat(),
    };

    if (stockId) {
      await productService.updateStockProduct(stockId, sendData).then((res) => {
        if (res?.data?.code == 0) {
          Toast("success", res?.data?.message);
          navigate("/app/product/mrp/stock");
        } else {
          Toast("error", res?.data?.message);
        }
      });
    } else {
      await productService.addProductNewDesign(sendData).then((res) => {
        if (res?.data?.code == 0) {
          Toast("success", res?.data?.message);
          navigate("/app/product/mrp/stock");
        } else {
          Toast("error", res?.data?.message);
        }
      });
    }
  };

  useEffect(() => {
    if (FirstFormData?.operations) {
      setItems(FirstFormData?.operations);
    }
  }, []);

  return (
    <>
      <div className="flex flex-col w-full h-full gap-y-5">
        <div className="flex min-h-[56px] max-h-[56px] h-[56px] w-full items-center justify-start gap-x-2 sticky top-0 z-[50] bg-white">
          <Button size={"md"} iconLeft={<ArrowLeftIcon />} colorType={"tertiary-gray"} onClick={handleCancelClick} type={"button"} />
          <p className="text-[#101828] font-semibold text-2xl flex gap-x-3 items-center">
            {t("product:createNewProduct")}
            <p className="text-[#475467] font-normal text-xl">2/2</p>
          </p>
        </div>
        <div className="flex w-full justify-between items-start pb-5 border-b border-[#E4E7EC]">
          <div className="flex flex-col gap-y-1">
            <p className="text-[#101828] font-semibold text-lg">{t("product:receipe")}</p>
            <p className="text-[#344054] font-normal text-sm">{t("product:enterTheProduction")}</p>
          </div>
          <div className="flex gap-x-3 max-h-[40px]">
            <span className="flex min-w-[96px] max-w-[96px] w-[96px]">
              <Button onClick={handleCancelClick} size={"md"} colorType={"secondary-gray"} label={t("buttons:prev")} />
            </span>
            <span className="flex min-w-[96px] max-w-[96px] w-[96px]">
              <Button size={"md"} colorType={"primary-product"} label={t("buttons:save")} onClick={() => onSubmit()} />
            </span>
          </div>
        </div>

        <div className="flex w-full overflow-y-auto overflow-x-hidden scrollbar-hide">
          <SortableList
            axis="xy"
            items={items}
            onSortEnd={onSortEnd}
            distance={1}
            hasOrders={false}
            setUpdateOperation={setUpdateOperation}
            updateOperation={updateOperation}
            setIsOpenAddOperationModal={setIsOpenAddOperationModal}
            setItems={setItems}
            setStepOneData={setStepOneData}
            setStepTwoData={setStepTwoData}
            setStepThreeData={setStepThreeData}
            internalProcess={internalProcess}
            externalProcess={externalProcess}
            t={t}
          />
        </div>
      </div>
      <CustomModal
        onClose={() => {
          setStepOneData(defaultStepData);
          setStepTwoData(defaultStepData);
          setStepThreeData(defaultStepData);
          setIsOpenAddOperationModal(false);
        }}
        width={900}
        isOpen={isOpenAddOperationModal}
        setIsOpen={setIsOpenAddOperationModal}
        modalTitle={t("product:newRecipeStep")}
        titleButton={false}
        buttonColorType={"tertiary-error"}
        buttonIcon={<PlusIcon />}
        buttonSize={"xl"}
        buttonOnClick={() => setIsOpenModalTab(true)}
        buttonClassName={"pt-0 pb-0 hover:bg-[#FFF]"}
        buttonLabel={t("product:createMultiple")}
        children={
          <CreateReceipeModal
            items={items}
            setItems={setItems}
            stepOneData={stepOneData}
            setStepOneData={setStepOneData}
            setStepTwoData={setStepTwoData}
            stepTwoData={stepTwoData}
            setStepThreeData={setStepThreeData}
            stepThreeData={stepThreeData}
            defaultStepData={defaultStepData}
            onClose={() => {
              setStepOneData(defaultStepData);
              setStepTwoData(defaultStepData);
              setStepThreeData(defaultStepData);
              setIsOpenAddOperationModal(false);
            }}
            setIsOpenModalTab={setIsOpenModalTab}
            isOpenModalTab={isOpenModalTab}
          />
        }
      />
    </>
  );
};

export default StockCreateRecipeProduct;

export const RecipeEdit = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M2.39662 15.0965C2.43491 14.752 2.45405 14.5797 2.50618 14.4186C2.55243 14.2758 2.61778 14.1398 2.70045 14.0144C2.79363 13.8731 2.91621 13.7506 3.16136 13.5054L14.1666 2.50017C15.0871 1.5797 16.5795 1.5797 17.4999 2.50017C18.4204 3.42065 18.4204 4.91303 17.4999 5.83351L6.49469 16.8387C6.24954 17.0839 6.12696 17.2065 5.98566 17.2996C5.86029 17.3823 5.72433 17.4477 5.58146 17.4939C5.42042 17.546 5.24813 17.5652 4.90356 17.6035L2.08325 17.9168L2.39662 15.0965Z"
        stroke="#98A2B3"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const RecipeClose = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M15 5L5 15M5 5L15 15" stroke="#98A2B3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

const PlusIconNew = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path d="M24 10V38M10 24H38" stroke="#98A2B3" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

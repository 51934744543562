import React, { useEffect, useState } from "react";
import NewProductCard from "../../../product/components/NewProductCard";
import moment from "moment";
import SearchInput from "../../../../components/inputs/SearchInput";
import diacritics from "diacritics";
import generateFileUrl from "../../../../utils/generateFileUrl";
import { personalService } from "../../../../services/personal.service";
import AddManuelJunctionProductCard from "../components/AddManuelJunctionProductCard";
import NoAvatar from "../../../../components/avatar/NoAvatar";
import { useTranslation } from "react-i18next";

const ProductUser = ({
  setLeftTab,
  leftTab,
  products,
  setSelectedUser,
  selectedUser,
  setSelectedProduct,
  selectedProduct,
  setSelectedOperation,
  selectedOperation,
}) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [personels, setPersonels] = useState([]);

  const [searchValue, setSearchValue] = useState("");
  const handleOperationSelection = (operation) => {
    setSelectedOperation(operation);
  };

  const filteredProducts = products?.filter((product) =>
    diacritics.remove(`${product?.product?.name}`).toLowerCase().includes(diacritics.remove(searchQuery).toLowerCase())
  );

  const filteredUsers = personels?.filter((user) => diacritics.remove(`${user?.label}`).toLowerCase().includes(diacritics.remove(searchValue).toLowerCase()));

  const getActiveUser = async () => {
    const data = await personalService.activePersonals();
    let formatttedData = data?.data?.map((e, i) => {
      return {
        label: e?.name + " " + e?.lastName,
        value: e?.id,
        image: e?.avatar,
      };
    });
    setPersonels(formatttedData);
  };

  useEffect(() => {
    getActiveUser();
  }, []);

  useEffect(() => {
    if (selectedProduct) {
      const product = filteredProducts.find((p) => p.product.id === selectedProduct?.product?.id);
      if (product && product.operations.length > 0) {
        setSelectedOperation(product.operations[0]);
      }
    }
  }, [selectedProduct]);

  return (
    <div className="flex flex-col w-[30%] h-full pl-6 gap-y-5">
      <div className="flex w-full min-h-[40px] max-h-[40px] h-10 border border-[#D0D5DD] rounded-lg">
        <div
          onClick={() => {
            setLeftTab("product");
          }}
          className={`flex w-1/2 border-r border-[#D0D5DD] items-center justify-center cursor-pointer rounded-l-lg ${
            leftTab === "product" ? "bg-[#F9FAFB]" : ""
          }`}
        >
          <p className="text-[#344054] font-semibold text-sm">{t("product:selectProduct")}</p>
        </div>
        <div
          onClick={() => setLeftTab("user")}
          className={`flex w-1/2 items-center justify-center cursor-pointer rounded-r-lg ${leftTab === "user" ? "bg-[#F9FAFB]" : ""}`}
        >
          <p className="text-[#344054] font-semibold text-sm">{t("product:selectPersonnel")}</p>
        </div>
      </div>
      {leftTab === "product" ? (
        <div className="flex flex-col w-full gap-y-[20px]">
          <SearchInput theme={"machine"} setSearchVal={setSearchQuery} />
          <div className="flex min-h-[250px] max-h-[250px] overflow-y-auto overflow-x-hidden scrollbar-hide">
            <div className="flex flex-col gap-y-3 w-full">
              {filteredProducts?.map((product, index) => {
                return (
                  <>
                    <AddManuelJunctionProductCard
                      productData={product}
                      setSelectedProduct={setSelectedProduct}
                      selectedProduct={selectedProduct}
                      name={product?.product?.name}
                      productId={product?.product?.id}
                      orderNo={`${t("product:orderNo")}:` + product?.orderNo}
                      image={product?.product?.image}
                      quantity={product?.product?.qualityQuantity + product?.product?.scrapQuantity}
                      date={moment(product?.deliveryDate).format("DD.MM.YY")}
                    />
                    {selectedProduct?.product?.id === product?.product?.id && (
                      <>
                        {product?.operations?.map((operation, i) => {
                          return (
                            <div className="flex flex-col gap-y-2">
                              <div onClick={() => handleOperationSelection(operation)} className="flex gap-x-2 items-center cursor-pointer w-fit">
                                <div
                                  className={`min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px] w-4 rounded-full flex items-center justify-center ${
                                    selectedOperation?.id === operation.id ? " bg-[#DC6803]" : "border border-secondary-300"
                                  }`}
                                >
                                  {selectedOperation?.id === operation.id && (
                                    <div className="flex min-w-[6px] max-w-[6px] min-h-[6px] max-h-[6px] bg-white rounded-full"></div>
                                  )}
                                </div>
                                <div className="text-secondary-500 font-normal text-sm">{`${operation?.step}.${operation?.internalOperation?.process?.name}`}</div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-full gap-y-[20px]">
          <SearchInput theme={"machine"} setSearchVal={setSearchValue} />
          <div className="flex min-h-[250px] max-h-[250px] overflow-y-auto overflow-x-hidden scrollbar-hide">
            <div className="flex flex-col w-full">
              {filteredUsers?.map((user, index) => {
                return (
                  <div
                    onClick={() => setSelectedUser(user)}
                    className="flex w-full min-h-[56px] rounded-lg px-3 py-2 gap-x-3 cursor-pointer hover:bg-[#F9FAFB]"
                  >
                    {user?.image ? (
                      <img className="flex min-w-[40px] max-w-[40px] w-10 rounded-full border" src={generateFileUrl(user?.image)} />
                    ) : (
                      <NoAvatar name={user.label} theme={"green"} size={40} fontSize={18} />
                    )}

                    <div className="flex flex-col w-full">
                      <p className="text-[#344054] font-semibold text-sm">{user?.label}</p>
                      {/* <p className="text-[#667085] font-normal text-sm">UI/UX Designer</p> */}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductUser;

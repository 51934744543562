import React, { useRef, useState } from "react";
import { CheckIcon, DotsVerticalIcon, FlexProductIcon, PlusIcon, TargetIcon } from "../../../../components/new-cards/card-icons";
import { Tooltip } from "react-tooltip";
import { NoEquIcon } from "../../../../assets/icons/machineIcons";
import CustomImage from "../../../../components/CustomImage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../../hooks/useStores";
import { junctionService } from "../../../../services/junction.service";
import { Toast } from "../../../../utils/toastify/toast";
import AlertModal from "../../../../components/alert-Modal/Alert-Modal";
import CustomJunctionInfoModal from "../../../../components/modal/CustomJunctionInfoModal";
import JunctionInfoModal from "../../../../components/modal/JunctionInfoModal";
import moment from "moment";
import { PieceIcon } from "../../../../assets/icons/productIcons";
const InternalPlannedPlannedCard = ({ data, refetch, setIsOpenSelectUserModal, setSelectedJunction, setIsOpenFinishJunction }) => {
  const { t } = useTranslation();
  const { auth } = useStore();
  const navigate = useNavigate();
  const modalRef = useRef(null);

  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [isAlertPlanned, setIsAlertPlanned] = useState(false);
  const [deleteIdPlanned, setDeleteIdPlanned] = useState("");
  const convertSeconds = (seconds) => {
    if (isNaN(seconds) || seconds == Infinity) return "---";

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Number(seconds % 60).toFixed(0);

    let formattedTime = "";

    if (minutes > 0) {
      formattedTime += minutes + " dk ";
    }

    if (remainingSeconds > 0) {
      formattedTime += remainingSeconds + " sn";
    }

    return formattedTime.trim();
  };
  const handleButtonClick = () => {
    const rect = modalRef?.current?.getBoundingClientRect();
    const x = rect?.left + window?.scrollX;
    const y = rect?.top + window?.scrollY;
    setModalPosition({ top: y, left: x });
    setShowDropDown(!showDropDown);
  };

  const onDeletedPlanned = async () => {
    await junctionService.deleteStationForJunction(data?.id, deleteIdPlanned).then((res) => {
      if (res.data.code == 0) {
        Toast("success", "Junction deleted");
        refetch();
        setIsAlertPlanned(false);
        setDeleteIdPlanned(data?.id);
      } else {
        Toast("error", "Junction not deleted");
        setIsAlertPlanned(false);
        setDeleteIdPlanned("");
      }
    });
  };

  return (
    <>
      <div className="flex w-full flex-col border border-t-4 items-start rounded-t-[4px] rounded-b-lg border-secondary-300 bg-white relative group">
        <div className="flex flex-row items-center w-full gap-x-[6px] pt-[6px] px-2 pb-[6px]">
          <div className="flex flex-row items-center gap-x-[8px]  w-[80%] min-w-[80%]">
            {data?.productType === "flex" ? (
              <>
                {data?.flexProduct?.image ? (
                  <div
                    data-tooltip-id={`${data?.id}`}
                    data-tooltip-place={"right"}
                    data-tooltip-content={`${t("product:productName")} : ${data?.flexProduct?.name} `}
                  >
                    <CustomImage
                      borderRadius={2}
                      src={data?.flexProduct?.image}
                      style={{
                        width: 64,
                        height: 48,
                        minWidth: 64,
                        minHeight: 48,
                        borderRadius: 4,
                        borderWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-[64px] min-w-[64px] min-h-[48px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                    <NoEquIcon />
                  </div>
                )}
              </>
            ) : (
              <>
                {data?.job?.product?.image ? (
                  <div
                    data-tooltip-id={`${data?.id}`}
                    data-tooltip-place={"right"}
                    data-tooltip-content={`${t("product:productName")} : ${data?.job?.product?.name} `}
                  >
                    <CustomImage
                      borderRadius={2}
                      src={data?.job?.product?.image}
                      style={{
                        width: 64,
                        height: 48,
                        minWidth: 64,
                        minHeight: 48,
                        borderRadius: 4,
                        borderWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-[64px] min-w-[64px] min-h-[48px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                    <NoEquIcon />
                  </div>
                )}
              </>
            )}

            <div className="flex flex-col items-start gap-y-[6px]">
              <div className="flex flex-row items-center gap-x-1 w-full">
                {data?.productType === "flex" ? (
                  <>
                    <FlexProductIcon />
                    <p className="text-sm font-semibold text-secondary-900 truncate w-[190px]">{data?.flexProduct?.name || "--"}</p>
                  </>
                ) : (
                  <p className="text-sm font-semibold text-secondary-900 truncate w-[190px]">{data?.job?.product?.name || "--"}</p>
                )}
              </div>

              <p className="text-xs font-normal text-secondary-500">{t("product:selectPersonnel")}</p>
            </div>
          </div>
          <button
            onClick={() => {
              setIsOpenSelectUserModal(true);
              setSelectedJunction(data);
            }}
            type="button"
            className="min-w-[48px] h-[48px] rounded-full border-dashed ml-auto border border-gray-300 flex items-center justify-center mt-1"
          >
            <PlusIcon />
          </button>
        </div>
        <div className="flex flex-row items-center gap-x-4 h-[26px] min-h-[26px] w-full bg-[#F9FAFB] border-t-[0.5px] border-t-secondary-300 pb-[3px] pl-2 pt-[3px] pr-[3px] rounded-b-lg">
          <div className="flex flex-row items-center gap-x-[12px]">
            <div className="flex flex-row items-center">
              <PieceIcon />
              <p className="text-xs font-medium ml-1 text-secondary-600">{data?.targetedWorkCount || "0"}</p>
              <p className="text-[10px] ml-0.5 font-normal text-secondary-500">{t("product:qty")} plan.</p>
            </div>
            <div className="flex flex-row items-center">
              <CalendarIcon />
              <p className="text-xs font-medium ml-1 text-secondary-600">{(Number(data?.targetedWorkCount * data?.estimatedTime) / 86400).toFixed(1) || "0"}</p>
              <p className="text-[10px] ml-0.5 font-normal text-secondary-500">day estimated</p>
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-1 h-5 min-h-5 px-2 py-[2px] border-[0.5px] bg-white border-secondary-300 rounded ml-auto">
            <CheckIcon />
            <p className="text-xxs text text-secondary-600 font-semibold">{t("product:plannedNews")}</p>
          </div>
        </div>
        <div
          ref={modalRef}
          onClick={(e) => {
            e.stopPropagation();
            handleButtonClick();
          }}
          className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <DotsVerticalIcon />
        </div>

        <Tooltip className="z-[99999]" id={`${data?.id}`} />
      </div>
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlertPlanned}
        setIsOpen={setIsAlertPlanned}
        applyButton={() => {
          onDeletedPlanned(deleteIdPlanned);
        }}
      />
      <CustomJunctionInfoModal
        isOpen={showDropDown}
        setIsOpen={setShowDropDown}
        onClose={() => {
          setShowDropDown(false);
        }}
        modalRef={modalRef}
        modalPosition={modalPosition}
        children={
          <JunctionInfoModal
            refetch={refetch}
            setIsOpen={setShowDropDown}
            onClose={() => {
              setShowDropDown(false);
            }}
            onPressNode={() => {
              navigate(
                `/app/machine/digital-twin/equipment-details/${data?.station?.deviceId}-${data?.station?.id}/${moment(
                  data?.startDate ? data?.startDate : new Date()
                ).format("YYYY-MM-DD")}`
              );
            }}
            onPressFinish={(e) => {
              setIsOpenFinishJunction(true);
              setSelectedJunction(data);
            }}
            onPressProduct={(e) => {
              navigate(`/app/product/mrp/work-in-process/detail/${data?.job?.id}+${data?.header?.step}`);
            }}
            onPressOperation={() => {
              navigate(
                `/app/machine/digital-twin/operation-detail/${data?.internalOperation ? data?.internalOperation?.id : data?.externalOperation?.id}/${moment(
                  data?.startDate
                ).format("YYYY-MM-DD")}`
              );
            }}
            showDropDown={showDropDown}
            props={data}
            modalRef={modalRef}
            setShowDropDown={setShowDropDown}
            setIsAlert={setIsAlert}
            setIsAlertPlanned={setIsAlertPlanned}
            status={data?.status}
          />
        }
      />
    </>
  );
};

export default InternalPlannedPlannedCard;
const CalendarIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
      <path
        d="M13 6.66666L1 6.66666M9.66667 1.33333V3.99999M4.33333 1.33333V3.99999M4.2 14.6667L9.8 14.6667C10.9201 14.6667 11.4802 14.6667 11.908 14.4487C12.2843 14.2569 12.5903 13.951 12.782 13.5746C13 13.1468 13 12.5868 13 11.4667V5.86666C13 4.74656 13 4.1865 12.782 3.75868C12.5903 3.38236 12.2843 3.0764 11.908 2.88465C11.4802 2.66666 10.9201 2.66666 9.8 2.66666L4.2 2.66666C3.0799 2.66666 2.51984 2.66666 2.09202 2.88465C1.71569 3.0764 1.40973 3.38236 1.21799 3.75868C1 4.1865 1 4.74656 1 5.86666L1 11.4667C1 12.5868 1 13.1468 1.21799 13.5746C1.40973 13.951 1.71569 14.2569 2.09202 14.4487C2.51984 14.6667 3.0799 14.6667 4.2 14.6667Z"
        stroke="#475467"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
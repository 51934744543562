import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProductOperationDetailHeader from "./ProductOperationDetailHeader";
import OperationJunctions from "./OperationJunctions";
import { orderService } from "../../services/order.service";
import { useQuery } from "@tanstack/react-query";
import { Loading } from "../../components";
import NewOperationDetailHeader from "../machine/Twin/components/NewOperationDetailHeader";
const ProductOperationDetail = () => {
  const navigate = useNavigate();
  const { operationId } = useParams();
  const [selectedOperation, setSelectedOperation] = useState("all");
  const getData = useQuery({
    retry: 0,
    enabled: false,
    queryKey: ["multi-mrp-operations"],
    queryFn: async () => {
      return await orderService.getMultiOperationDetails(operationId.split("+")[0], operationId.split("+")[1])
    }
  });
  useEffect(() => {
    getData.refetch()
  }, [operationId]);
  useEffect(() => {
    if (selectedOperation?.operationId) {
      setSelectedOperation(getData?.data?.operations?.find((item) => item.operationId == selectedOperation?.operationId))
    } else {
      setSelectedOperation(getData?.data?.operations[0])
    }
  }, [getData]);
  return (
    <>
      {getData?.isLoading || getData?.isFetching || getData?.isRefetching ? (
        <div className="w-full h-full flex items-center justify-center">
          <Loading size={32} primary={"#B54708"} secondary={"#FFF"} />
        </div>
      ) : (
        <div className="w-full h-full overflow-y-auto scrollbar-hide">
          <ProductOperationDetailHeader
            navigate={navigate}
            refetch={getData?.refetch}
            operation={getData?.data}
            disabledMove={selectedOperation?.junctions?.length == 0}
            isLoading={getData?.isLoading || getData?.isFetching || getData?.isRefetching}
            isFetching={getData?.isLoading || getData?.isFetching || getData?.isRefetching}
            currentOperation={selectedOperation}
            headerId={selectedOperation?.operationId}
            operationId={operationId}
            orderId={operationId.split("+")[1]}
            stepNo={operationId.split("+")[1]}
          />
          <NewOperationDetailHeader
            data={getData?.data}
            refetch={getData?.refetch}
            selectedOperation={selectedOperation}
            setSelectedOperation={setSelectedOperation}
          />
          <OperationJunctions
            data={selectedOperation?.junctions || []}
            refetch={getData?.refetch}
            operation={getData?.data}
            orderIds={operationId.split("+")[1]}
            onScrolling={() => { }}
            isFetching={getData?.isLoading || getData?.isFetching || getData?.isRefetching}
          />
        </div>
      )}
    </>
  );
};

export default ProductOperationDetail;

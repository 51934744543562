import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  CheckIcon,
  ClockIcon,
  DeleteIcon,
  DotsVerticalIcon,
  EquipmentDetailIcon,
  EquipmentEditIcon,
  FlexProductIcon,
  PieceIcon,
  PlusIcon,
  TargetIcon,
} from "../card-icons";
import CustomImage from "../../CustomImage";
import { NoEquIcon, ProductDetailIcon } from "../../../assets/icons/machineIcons";
import AlertModal from "../../alert-Modal/Alert-Modal";
import CustomModal from "../../modal/CustomModal";
import JunctionUpdate from "../../modal/JunctionUpdate";
import { Toast } from "../../../utils/toastify/toast";
import { junctionService } from "../../../services/junction.service";
import moment from "moment";
import i18next from "i18next";
import Badgets from "../../buttons/Badgets";
import { formatSeconds, secondToDay } from "../../../utils/secondToHoursAndMinute";
import { Tooltip } from "react-tooltip";

const EquipmentDetailPlannedCard = ({ data, operation, page, refetch, setSelectedJunctionForPlanned, setIsOpenSelectProduct }) => {
  console.log(data, "DATAAAAAAA")
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isJunction, setIsJunction] = useState(false);

  const [deleteId, setDeleteId] = useState("");
  const menuOptions = [
    {
      label: t("product:productDetail"),
      value: "productDetail",
      icon: <ProductDetailIcon />,
    },
    { label: t("buttons:delete"), value: "delete", icon: <DeleteIcon /> },
  ];

  const onDeleted = async () => {
    await junctionService.deleteStationForJunction(data?.id).then((res) => {
      if (res.data.code == 0) {
        Toast("success", "Junction deleted");
        refetch();
        setIsAlert(false);
        setDeleteId(data?.id);
      } else {
        Toast("error", "Junction not deleted");
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };

  const convertSeconds = (seconds) => {
    if (isNaN(seconds)) return "---";

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Number(seconds % 60).toFixed(0);

    let formattedTime = "";

    if (minutes > 0) {
      formattedTime += minutes + " dk ";
    }

    if (remainingSeconds > 0) {
      formattedTime += remainingSeconds + " sn";
    }

    return formattedTime.trim();
  };

  let formattedTime = convertSeconds(
    data?.productType === "flex" ? data?.flexHeader?.estimatedTime / data?.flexHeader?.quantity : data?.header?.internalOperation?.estimatedTime
  );



  // let estimatedTime = data?.header?.totalEstimatedTime / data?.header?.piece / 60;
  // let formattedTime = estimatedTime.toFixed(2);

  return (
    <>
      <div className="flex w-full flex-col border border-t-4 items-start rounded-t-[4px] rounded-b-lg border-secondary-300 bg-white relative group">
        <div className="flex flex-row items-center w-full gap-x-[6px] pt-[6px] px-2 pb-[6px]">
          <div className="flex flex-row items-center gap-x-[8px]  w-[80%] min-w-[80%]">
            {data?.productType === "flex" ? (
              <>
                {data?.flexProduct?.image ? (
                  <div
                    data-tooltip-id={`${data?.id}`}
                    data-tooltip-place={"right"}
                    data-tooltip-content={`${t("product:productName")} : ${data?.flexProduct?.name} / ${t("product:stockCode")} : ${data?.flexProduct?.stockCode
                      } `}
                  >
                    <CustomImage
                      borderRadius={2}
                      src={data?.flexProduct?.image}
                      style={{
                        width: 64,
                        height: 48,
                        minWidth: 64,
                        minHeight: 48,
                        borderRadius: 4,
                        borderWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                    <NoEquIcon />
                  </div>
                )}
              </>
            ) : (
              <>
                {data?.job?.product?.image ? (
                  <div
                    data-tooltip-id={`${data?.id}`}
                    data-tooltip-place={"right"}
                    data-tooltip-content={`${t("product:productName")} : ${data?.job?.product?.name} / ${t("product:orderNo")} : ${data?.job?.product?.code} `}
                  >
                    <CustomImage
                      borderRadius={2}
                      src={data?.job?.product?.image}
                      style={{
                        width: 64,
                        height: 48,
                        minWidth: 64,
                        minHeight: 48,
                        borderRadius: 4,
                        borderWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                    <NoEquIcon />
                  </div>
                )}
              </>
            )}

            <div className="flex flex-col items-start gap-y-[6px]">
              <div className="flex flex-row items-center gap-x-1 w-full">
                {data?.productType === "flex" ? (
                  <>
                    <FlexProductIcon />
                    <p className="text-sm font-semibold text-secondary-900 truncate w-[260px]">{data?.flexProduct?.name || "--"}</p>
                  </>
                ) : (
                  <p className="text-sm font-semibold text-secondary-900 truncate w-[280px]">{data?.job?.product?.name || "--"}</p>
                )}
              </div>
              {page === "operationDetail" ? (
                <p className="text-xs font-normal text-secondary-500">{t("product:selectPersonnel")}</p>
              ) : (
                <div className="flex items-center flex-row gap-x-1 w-[280px]">
                  <div className="flex flex-row items-center">
                    {data?.productType === "flex" ? (
                      <>
                        <p className="text-xs font-normal text-secondary-500">{t("product:stockCode")}:</p>
                        <p className="text-xs  w-[110px] truncate font-normal text-secondary-500">{data?.flexProduct?.stockCode}</p>
                      </>
                    ) : (
                      <>
                        <p className="text-xs font-normal text-secondary-500">{t("product:orderNo")}:</p>
                        <p className="text-xs  w-[110px] truncate font-normal text-secondary-500">{data?.job?.product?.code}</p>
                      </>
                    )}
                  </div>
                  <div className="flex flex-row items-center">
                    <PieceIcon />
                    <p className="text-xs font-medium ml-0.5 w-[72px] truncate text-secondary-600">
                      {data?.productType === "flex" ? data?.flexHeader?.quantity || "--" : data?.job?.piece || "--"}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <button
            onClick={() => {
              setSelectedJunctionForPlanned(data);
              setIsOpenSelectProduct(true);
            }}
            type="button"
            className="min-w-[48px] h-[48px] rounded-full border-dashed ml-auto border border-gray-300 flex items-center justify-center"
          >
            <PlusIcon />
          </button>
        </div>
        <div className="flex flex-row items-center gap-x-3 h-[26px] min-h-[26px] w-full bg-[#F9FAFB] border-t-[0.5px] border-t-secondary-300 pb-[3px] pl-2 pt-[3px] pr-[3px] rounded-b-lg">
          <div className="flex flex-row items-center">
            <TargetIcon />
            <p className="text-xs font-medium ml-1 text-secondary-600">{formattedTime || "--"}</p>
            <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:targetMin")}</p>
          </div>
          {/* <div className="flex flex-row items-center">
            <PieceIcon/>
            <p className="text-xs font-medium ml-0.5 text-secondary-600">{data?.job?.piece || "--"}</p>
          </div>
          <div className="flex flex-row items-center">
            <ClockIcon/>
            <p className="text-xs font-medium ml-0.5 text-secondary-600">{secondToDay(data?.header?.totalEstimatedTime) || "--"}</p>
          </div> */}
          {/* <div className="flex flex-row items-center">
            {targetMin < avgActualTime ? <RedAvgIcon /> : <GreenAvgIcon />}
            <p className={`text-xs font-medium ml-1 ${targetMin < avgActualTime ? "text-[#D92D20]" : "text-[#079455]"}`}>{avgActualTime || "--"}</p>
            <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">ortalama</p>
          </div> */}
          <div className="flex flex-row items-center gap-x-1 h-5 min-h-5 px-2 py-[2px] border-[0.5px] bg-white border-secondary-300 rounded ml-auto">
            <CheckIcon />
            <p className="text-xxs text text-secondary-600 font-semibold">{t("product:plannedNews")}</p>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(!showDropDown);
          }}
          className={`hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white ${page === "operationDetail" ? "none" : "group-hover:flex"
            }  right-[3px] top-[3px] z-[50] items-center justify-center cursor-pointer`}
        >
          <DotsVerticalIcon />
        </div>
        {showDropDown && (
          <div
            className={`hidden flex-col absolute min-w-[200px] top-[32px] right-[3px] max-w-[200px] h-fit bg-white border z-[99] rounded-lg px-[6px] py-1 group-hover:flex`}
          >
            {menuOptions?.map((item, index) => {
              return (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (item.value === "productDetail") {
                      const urlFlex = `/app/product/mes/flex/operation/detail/${data?.flexProduct?.id}+${data?.flexHeader?.step}`
                      if (data?.productType === "flex") {
                        navigate(urlFlex);
                      } else {
                        navigate(`/app/product/mrp/work-in-process/detail/${data?.job?.id}+${data?.header?.step}`);
                      }

                    }
                    if (item.value === "delete") {
                      setIsAlert(true);
                    }
                    setShowDropDown(false);
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${item?.value === "delete" || item.value === "finishJunction" ? "hover:bg-error-50" : "hover:bg-secondary-100"
                    }`}
                >
                  {item?.icon}
                  <p
                    className={`font-medium text-sm  ${item?.value === "delete" || item.value === "finishJunction" ? "text-[#D92D20]" : "text-secondary-700"}`}
                  >
                    {item.label}
                  </p>
                </button>
              );
            })}
          </div>
        )}
        {(data?.header?.step || data?.flexHeader?.step) === undefined ? null : (
          <span className="absolute left-1 top-1">
            <Badgets colorType={"fill-gray"} label={data?.productType === "flex" ? data?.flexHeader?.step + ".op" : data?.header?.step + ".op"} size={"sm"} />
          </span>
        )}
        <Tooltip className="z-[120]" id={`${data?.id}`} />
      </div>
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(deleteId);
        }}
      />
      <CustomModal
        isOpen={isJunction}
        setIsOpen={setIsJunction}
        onClose={() => {
          setIsJunction(false);
        }}
        modalTitle={t("product:editJunction")}
        children={
          <JunctionUpdate
            junctionId={data?.id}
            pages={"activeAndPlanned"}
            onClose={() => {
              setIsJunction(false);
            }}
            refetch={refetch}
          />
        }
      />
    </>
  );
};

export default EquipmentDetailPlannedCard;

import React, { useState, useEffect, useRef } from "react";
import { SortIcon } from "../../../assets/icons/productIcons";
import { CloseIconSort, SwitchIconHorizon } from "../../../assets/icons/equipmentIcon";
import { useTranslation } from "react-i18next";

const OrderSort = React.memo(({ sortValue, setSortValue, ascDesc, setAscDesc, options }) => {
  const { t } = useTranslation();
  const menuRef = useRef();
  const [isSortDropdown, setIsSortDropdown] = useState(false);
  const [selectedSort, setSelectedSort] = useState(null);
  const handleSortCancel = (e) => {
    e.stopPropagation();
    setSelectedSort(null);
    setSortValue(null);
    setIsSortDropdown(false);
  };
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsSortDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={menuRef} className="flex w-full h-full relative">
      {selectedSort ? (
        <div
          onClick={() => setIsSortDropdown(true && !isSortDropdown)}
          className={`flex border hover:bg-secondary-50 border-secondary-300 px-[14px] py-[10px] rounded-lg gap-x-1 justify-center items-center cursor-pointer ${
            isSortDropdown ? "shadow-ring-gray" : ""
          }`}
        >
          <span className="min-w-[20px] min-h-[20px]">
            <SortIcon />
          </span>
          <span className="text-sm font-semibold text-secondary-700 whitespace-nowrap">{selectedSort}</span>
          <span onClick={handleSortCancel} className="p-[5px] min-w-[20px] min-h-[20px] hover:bg-secondary-100 rounded">
            <CloseIconSort />
          </span>
        </div>
      ) : (
        <div
          onClick={() => setIsSortDropdown(true && !isSortDropdown)}
          className={`flex border hover:bg-secondary-50 border-secondary-300 px-[14px] py-[10px] rounded-lg gap-x-1 justify-center items-center cursor-pointer ${
            isSortDropdown ? "shadow-ring-gray" : ""
          }`}
        >
          <span className="min-w-[20px] min-h-[20px]">
            <SortIcon />
          </span>
          <span className="text-sm font-semibold text-secondary-700 whitespace-nowrap">{t("product:sorted")}</span>
        </div>
      )}
      {isSortDropdown === true && (
        <div className="flex flex-col min-w-[340px] max-w-[340px] h-fit absolute top-[48px] right-0 bg-white border z-[999999999] rounded-lg shadow-lg py-1 px-[6px] gap-y-[2px] border-secondary-200">
          {options.map((item, index) => (
            <div
              onClick={() => {
                if (sortValue === item.value) {
                  setAscDesc(!ascDesc);
                } else {
                  setAscDesc(true);
                }
                setSelectedSort(item.label);
                setSortValue(item.value);
                setIsSortDropdown(false);
              }}
              key={index}
              className={`flex items-center min-h-[38px] max-h-[38px] rounded-md gap-x-3 px-[10px] py-[9px] cursor-pointer hover:bg-secondary-50 ${
                selectedSort === item.label ? "bg-secondary-50" : ""
              }`}
            >
              <div className="flex gap-x-2 items-center w-full">
                <div className="flex min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px] justify-end items-center">{item?.icon}</div>
                <span className="text-sm text-secondary-700 font-medium">{item?.label}</span>
              </div>
              <div
                className={`flex gap-x-1 items-center justify-center ${
                  selectedSort === item.label ? "text-secondary-600 text-xs font-medium" : " text-secondary-500 text-xs font-normal"
                }`}
              >
                {selectedSort === item.label ? (
                  <>
                    {ascDesc ? (
                      <>
                        <span>{t("product:high")}</span>
                        <span>
                          <SwitchIconHorizon />
                        </span>
                        <span>{t("product:low")}</span>
                      </>
                    ) : (
                      <>
                        <span>{t("product:low")}</span>
                        <span>
                          <SwitchIconHorizon />
                        </span>
                        <span>{t("product:high")}</span>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <span>{t("product:high")}</span>
                    <span>
                      <SwitchIconHorizon />
                    </span>
                    <span>{t("product:low")}</span>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
});
export default OrderSort;

import React from "react";
import { NewDotsIcon } from "../../../../components/new-cards/card-icons";
import { Tooltip } from "react-tooltip";
import { NoEquIcon } from "../../../../assets/icons/machineIcons";
import { useTranslation } from "react-i18next";
import CustomImage from "../../../../components/CustomImage";

const ServiceSupplyPlanViewPlannedCard = ({ item, onClick, statusColor, setDraggedProduct }) => {
  console.log(item,"ITEMMMMM")
  
  const { t } = useTranslation();
  return (
    <>
      <div
        onClick={onClick}
        data-tooltip-id={`${item?.id}`}
        data-tooltip-content={`${t("product:productName")}: ${item?.externalOperation?.product?.name
          ? item?.externalOperation?.product?.name
          : item?.flexProduct?.name
            ? item?.flexProduct?.name
            : item?.name} - ${item?.flexProduct || item?.flexRawMaterial || item?.name ? t("product:flex") : t("product:order")}: ${
          item?.externalOperation?.product?.stockCode
          ? item?.orderData?.orderNo
          : item?.flexProduct?.stockCode
            ? item?.flexProduct?.stockCode
            : item?.stockCode || "--"
        }`}
        data-tooltip-place="right"
        key={item?.id}
        onDragStart={() => setDraggedProduct(item)}
        draggable
        className="flex bg-white flex-row  w-[300px] min-w-[300px] h-[90px] mr-4 min-h-[90px] relative group pl-2  pt-2  border border-[#D0D5DD] rounded-lg cursor-grab "
      >
        <div className="flex flex-col w-[88%]">
          <div className="flex flex-row items-center gap-x-2">
            {item?.flexProduct || item?.flexRawMaterial ? (
              <>
                {item?.flexProduct?.image || item?.flexRawMaterial?.image ? (
                  <div
                    data-tooltip-id={`${item?.id}`}
                    data-tooltip-place={"right"}
                    data-tooltip-content={`${item?.flexProduct?.name || item?.flexRawMaterial?.name} `}
                  >
                    <CustomImage
                      borderRadius={2}
                      src={item?.flexProduct?.image || item?.flexRawMaterial?.image}
                      style={{
                        width: 64,
                        height: 48,
                        minWidth: 64,
                        minHeight: 48,
                        borderRadius: 4,
                        borderWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                    <NoEquIcon />
                  </div>
                )}
              </>
            ) : (
              <>
                {item?.externalOperation?.product?.image ? (
                  <div data-tooltip-id={`${item?.id}`} data-tooltip-place={"right"} data-tooltip-content={`${item?.externalOperation?.product?.name} `}>
                    <CustomImage
                      borderRadius={2}
                      src={item?.externalOperation?.product?.image}
                      style={{
                        width: 64,
                        height: 48,
                        minWidth: 64,
                        minHeight: 48,
                        borderRadius: 4,
                        borderWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                    <NoEquIcon />
                  </div>
                )}
              </>
            )}

            <div className="flex flex-col items-start">
            <div className="flex flex-row items-center gap-x-1 ml-1 w-full">
                {item?.flexProduct || item?.flexRawMaterial || item?.name ? (
                  <>
                    <p className="font-semibold text-secondary-900 text-base w-[165px] truncate">
                      {item?.externalOperation?.product?.name
                        ? item?.externalOperation?.product?.name
                        : item?.flexProduct?.name
                          ? item?.flexProduct?.name
                          : item?.name || "Product Name"}
                    </p>
                  </>
                ) : (
                  <p className="font-semibold text-secondary-900 text-base w-[170px] truncate">{item?.externalOperation?.product?.name || "Product Name"}</p>
                )}
              </div>
              <div
                className={`h-6 max-w-[170px] flex px-1 py-[3px] ml-[2px] items-center justify-center bg-[${item?.flexProduct || item?.flexRawMaterial || item?.name ? "#F9F5FF" : "#FFFAEB"
                  }]`}
              >
                <p
                  className={`text-sm font-normal  max-w-[170px] truncate ${item?.flexProduct || item?.flexRawMaterial || item?.name ? "text-[#6941C6]" : "text-[#B54708]"}`}
                >
                  {item?.flexProduct || item?.flexRawMaterial || item?.name ? t("product:flex") : t("product:order")} :{" "}
                  {item?.externalOperation?.product?.stockCode
                    ? item?.orderData?.orderNo
                    : item?.flexProduct?.stockCode
                      ? item?.flexProduct?.stockCode
                      : item?.stockCode || "--"}
                </p>
              </div>
            
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-[6px]">
            {item?.flexProduct ? (<>
              <div className="flex flex-row items-center mt-1.5">
                <PieceFlexIcon color={"#7F56D9"} />
                <p className="text-sm font-medium ml-1 text-[#6941C6]">{item?.finishedPiece  || "0"}</p>
                <p className="text-[10px] ml-0.5 mt-[3px] font-normal text-secondary-500">{t("product:qtyMade")}</p>
              </div>
            </>) : item?.flexRawMaterial || item?.name ? (
              <>
                <div className="flex flex-row items-center mt-1.5">
                  <PieceFlexIcon color={"#7F56D9"} />
                  <p className="text-sm font-medium ml-1 text-[#6941C6]">{item?.qualityQuantity || "0"}</p>
                  <p className="text-[10px] ml-0.5 mt-[3px] font-normal text-secondary-500">{t("product:qtyMade")}</p>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-row items-center mt-1.5">
                  <PieceFlexIcon color={"#475467"} />
                  <p className="text-sm font-medium ml-1 text-secondary-700">{item?.finishedPiece || "0"}</p>
                  <p className="text-[10px] ml-0.5 mt-[3px] font-normal text-secondary-600">{t("product:qtyMade")}</p>
                </div>
                <div className="flex flex-row items-center mt-1.5">
                  <PieceFlexIcon color={"#DC6803"} />
                  <p className="text-sm font-medium ml-1 text-[#B54708]">{item?.piece - item?.finishedPiece || "0"}</p>
                  <p className="text-[10px] ml-0.5 mt-[3px] font-normal text-secondary-600">{t("product:qtyRemaining")}</p>
                </div>
              </>
            )}
          </div>
        </div>

        <div
          className={`flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 bg-[${item?.flexProduct || item?.flexRawMaterial || item?.name ? "#F9F5FF" : statusColor()
            }] border-secondary-300 h-[70px] min-h-[70px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px]`}
        >
          <p
            className={`text-[10px] font-medium ${item?.status === "started"
              ? "text-[#A15C07]"
              : item?.status === "rejected"
                ? "text-white"
                : item?.status === "finished"
                  ? "text-white"
                  : item?.flexProduct || item?.flexRawMaterial || item?.name
                    ? "text-[#6941C6]"
                    : "text-secondary-500"
              } -rotate-90`}
          >
            {item?.flexProduct || item?.flexRawMaterial || item?.name ? (item?.step ? item?.step + ".Flex" : "Flex") : item?.externalOperation?.step + ".Op"}
          </p>
          {item?.flexProduct || item?.flexRawMaterial || item?.name ? (
            <></>
          ) : (
            <span className="w-3 min-w-3 h-3 min-h-3">
              <ArrowIcon
                color={item?.status === "started" ? "#A15C07" : item?.status === "rejected" ? "#fff" : item?.status === "finished" ? "#fff" : "#475467"}
              />
            </span>
          )}
        </div>

        <div
          onClick={(e) => e.stopPropagation()}
          className="absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <NewDotsIcon />
        </div>
      </div>
      {item?.id && <Tooltip className="z-[999]" id={`${item?.id}`} />}
    </>
  );
};

export default ServiceSupplyPlanViewPlannedCard;

const PieceFlexIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clip-path="url(#clip0_17841_63067)">
        <path
          d="M5.49967 1.3335H2.99967C2.0792 1.3335 1.33301 2.07969 1.33301 3.00016V13.0002C1.33301 13.9206 2.0792 14.6668 2.99967 14.6668H12.9997C13.9201 14.6668 14.6663 13.9206 14.6663 13.0002V3.00016C14.6663 2.07969 13.9201 1.3335 12.9997 1.3335H10.4997M5.49967 1.3335V5.19064C5.49967 5.25535 5.56863 5.29672 5.62573 5.26627L7.99967 4.00016L10.3736 5.26627C10.4307 5.29672 10.4997 5.25535 10.4997 5.19064V1.3335M5.49967 1.3335H10.4997"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17841_63067">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const ArrowIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path d="M6 9.5L6 2.5M6 2.5L2.5 6M6 2.5L9.5 6" stroke={color} stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

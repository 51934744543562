import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button, CustomModal } from "../../components";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useStore } from "../../hooks/useStores";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { purchaseService } from "../../services/purchase.service";
import useWindowDimensions from "../../hooks/useWindowDimension";
import diacritics from "diacritics";
import { junctionService } from "../../services/junction.service";
import Badgets from "../../components/buttons/Badgets";
import { ScrollChevronIcon } from "../../assets/icons/productIcons";
import SearchInput from "../../components/inputs/SearchInput";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import JunctionCardType from "../../components/junction/JunctionCardType";
import generateFileUrl from "../../utils/generateFileUrl";
import NewOrderHeaderCard from "./components/order/NewOrderHeaderCard";
import SelectUserModal from "../../components/modal/SelectUserModal";
import FinishJunctionModal from "../../components/modal/FinishJunctionModal";
import PlanPurchaseJunctionModal from "./modals/PlanPurchaseJunctionModal";
import MaterialSupplyPlannedActiveCard from "./cards/material-supply/MaterialSupplyPlannedActiveCard";
import NewMaterialSupplyPlannedCard from "./cards/material-supply/NewMaterialSupplyPlannedCard";
import MaterialSupplyPlanViewPlannedCard from "./cards/material-supply/MaterialSupplyPlanViewPlannedCard";

const MesMaterialSupplyPlanViewPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { settings, auth } = useStore();
  let navigate = useNavigate();
  const { height } = useWindowDimensions();
  const [selectedGroup, setSelectedGroup] = useState("all");
  const [tableHeadItemsLength, setTableHeadItemsLength] = useState(0);
  const [supplierData, setSupplierData] = useState(null);
  const [isOpenPlanningModal, setIsOpenPlanningModal] = useState(false);
  const [isOpenSelectUserModal, setIsOpenSelectUserModal] = useState(false);
  const [isOpenFinishJunction, setIsOpenFinishJunction] = useState(false);
  const [rowLoading, setRowLoading] = useState(null);
  const [draggedProduct, setDraggedProduct] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedJunction, setSelectedJunction] = useState(null);
  const [arr, setArr] = useState([]);
  const [showFlexProducts, setShowFlexProducts] = useState(true);
  const planView = useQuery({
    queryKey: ["purchase-junctions-planned"],
    enabled: false,
    queryFn: async () => await purchaseService.purchaseTransactionsPlanned(id),
    retry: 0,
  });
  const headData = useQuery({
    queryKey: ["all-supplier-group-junctions"],
    enabled: false,
    retry: 0,
    queryFn: async () => await purchaseService.getPurchaseJunctionsForCompany(),
  });
  const refetch = () => {
    planView.refetch();
    headData.refetch();
  };
  useEffect(() => {
    headData.refetch();
    if (id != ":id" && id != "undefined") {
      planView.refetch();
      setSelectedGroup(headData?.data?.operations?.find((item) => item?.id == id));
    }
  }, [id]);
  useEffect(() => {
    if (headData?.data?.operations?.length > 0 && (id == ":id" || id == "undefined")) {
      navigate(`/app/product/mes/supply/material/${headData?.data?.operations[0]?.id}/plan-view`);
    }
  }, [headData?.data]);
  useEffect(() => {
    if (auth?.user?.company?.id == "e5c06127-3c5d-44b9-8403-74c160fc7f59") {
    } else if (auth?.user?.company?.id == "ab77a50a-7b68-42a2-ba5c-0ebfb5c13325") {
    } else if (auth?.user?.company?.id == "71b0edec-e5d4-4929-bfdb-0e5d5e149592") {
    } else if (auth?.user?.company?.id == "dd1892b9-d9a3-44ed-9131-17c88a74a1b4") {
    } else if (auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706") {
    } else if (auth?.user?.company?.id == "49ee8491-35d7-4eb2-a0e9-bfc95541180f") {
    } else if (auth?.user?.company?.id == "65844198-a9e8-45fa-bc99-7d012a541b35") {
    } else if (auth?.user?.company?.id == "7c5bb5a6-21aa-4d1d-829b-2a6d360b9630") {
    } else if (auth?.user?.company?.id == "f7db6191-5842-455a-99f4-c76763090689") {
    } else if (auth?.user?.company?.id == "776c0df3-0a44-464f-9877-33bd731aef6a") {
    } else if (auth?.user?.company?.id == "065e9b57-9da2-4394-9359-59ba71610dc3") {
    } else if (auth?.user?.company?.id == "e41a9bde-203c-48f2-aeec-4f8ce6344513") {
    } else if (auth?.user?.company?.id == "262d8e02-57e8-427e-9159-6b18b236ca86") {
    } else if (auth?.user?.company?.id == "0c082d32-6d97-4ba9-a2a5-7e41fe8139d9") {
    } else {
      navigate("/app/product/verysoon");
    }
  }, []);
  const tabOptions = [
    { value: "junction", label: t("product:eventss") },
    { value: "plan", label: t("product:planner") },
  ];

  const outerDivRef = useRef(null);
  const mappedDivRefs = useRef([]);

  const handleScroll = (source, index = null) => {
    let currentScrollLeft;

    if (source === "outer") {
      currentScrollLeft = outerDivRef.current?.scrollLeft;
    } else if (source === "mapped") {
      currentScrollLeft = mappedDivRefs.current[index]?.scrollLeft;
    }

    if (outerDivRef.current && source !== "outer") {
      outerDivRef.current.scrollLeft = currentScrollLeft;
    }

    mappedDivRefs.current.forEach((div, idx) => {
      if (div && idx !== index) {
        div.scrollLeft = currentScrollLeft;
      }
    });
  };

  const scrollContainerRef = useRef(null);

  const handleDragUpdate = (event) => {
    const scrollContainer = scrollContainerRef.current;

    if (!scrollContainer) return;

    const { clientWidth, scrollWidth, scrollLeft } = scrollContainer;
    const buffer = 50;
    const edgeDistance = 20;

    if (event?.destination) {
      const dragOffset = event.source.index * 360;
      const dragRight = dragOffset + buffer;
      const dragLeft = dragOffset - buffer;

      if (dragRight > scrollLeft + clientWidth) {
        scrollContainer.scrollBy({ left: edgeDistance, behavior: "smooth" });
      }

      if (dragLeft < scrollLeft) {
        scrollContainer.scrollBy({ left: -edgeDistance, behavior: "smooth" });
      }
    }
  };
  const handleHeaderLength = useCallback(() => {
    if (!planView?.data?.suppliers) return;
    const headLengths = planView?.data.suppliers.map((d) => (d.plannedJunction?.length || 0) + (d.activeJunction?.length || 0));
    setTableHeadItemsLength(Math.max(...headLengths, 2));
  }, [planView?.data]);

  const handleDrop = (node) => {
    setSupplierData(node);
    setIsOpenPlanningModal(true);
  };
  const onDragEnd = async (result) => {
    if (!result.destination) return;
    const newItems = Array.from(arr);
    const findChangedRowData = newItems?.[Number(result.source.droppableId)];
    const destIndex = findChangedRowData?.activeJunction?.length > 0 ? result.destination.index - 1 : result.destination.index;
    const findByIndex = findChangedRowData?.plannedJunction?.findIndex((element) => element?.id === result?.draggableId);
    const movedData = newItems[Number(result.source.droppableId)].plannedJunction.splice(findByIndex, 1)[0];
    newItems[Number(result.source.droppableId)].plannedJunction.splice(destIndex, 0, movedData);
    const newArr = newItems[Number(result.source.droppableId)]?.plannedJunction?.filter(Boolean)?.map((obj, ind) => {
      return { ...obj, planningOrder: ind };
    });
    setArr(newItems?.map((obj) => (obj?.id === newItems[Number(result.source.droppableId)]?.id ? newItems[Number(result.source.droppableId)] : obj)));
    setRowLoading(Number(result.source.droppableId));
    await junctionService.updatePlanningJunctionList(newArr);
    refetch();
  };

  useEffect(() => {
    if (id) {
      setArr(planView?.data?.suppliers);
      handleHeaderLength();
    }
  }, [planView?.data, handleHeaderLength, id]);

  const filter = planView?.data?.orderList
    ?.filter((item) => (!(item?.flexProduct || item?.flexRawMaterial) ? item : showFlexProducts ? item : null))
    ?.filter((a) => a != null);
  const filteredData = filter?.filter((item) => {
    const nameProcessed = diacritics.remove(item?.rawMaterial?.name?.toLowerCase() || "");
    const stockCodeProcessed = diacritics.remove(item?.rawMaterial?.stockCode?.toLowerCase() || "");
    const searchQueryProcessed = diacritics.remove(searchQuery.toLowerCase());
    return nameProcessed.includes(searchQueryProcessed) || stockCodeProcessed.includes(searchQueryProcessed);
  });
  const tableHeadItems = [];
  for (let i = 0; i < Math.max(1, tableHeadItemsLength); i++) {
    tableHeadItems.push({ title: `${i + 1}`, id: i });
  }
  return (
    <div className="w-full h-full flex flex-col overflow-hidden overflow-y-auto scrollbar-hide">
      <>
        <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden relative step-1 scrollbar-hide">
          <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
            <span className="mr-3 md:flex sm:flex xs:flex hidden">
              <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
            </span>
            <div className="flex items-center">
              <span className="font-codecMedium text-2xl text-black">upu.</span>
              <p className="text-2xl font-codecMedium text-[#B54708]">supply</p>
              {!(headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading) && (
                <p className="text-xl font-normal ml-2 text-secondary-500">
                  {selectedGroup && selectedGroup?.name ? `${t("product:materialSupply")} / ${selectedGroup?.name}` : t("product:materialSupply")}
                </p>
              )}
            </div>
            <span className="ml-auto">
              <Button
                colorType={"primary-product"}
                size={"md"}
                label={t("product:supplierList")}
                onClick={() => navigate("/app/product/mes/supply/material/list")}
              />
            </span>
          </div>
          {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
            <div className="w-full bg-gray-200 rounded-xl animate-pulse h-[96px] min-h-[96px] flex gap-5" />
          ) : (
            <div className="h-[116px] min-h-[116px] w-full overflow-x-auto flex ">
              {headData?.data?.operations
                ?.sort((a, b) => b?.active + b?.past + b?.pending - (a?.active + a?.past + a?.pending))
                ?.map((item, index) => {
                  return (
                    <div className={`min-w-[192px] ${index !== headData?.data?.operations?.length - 1 ? "border-b" : "border-b-0"} max-h-[96px] pr-5`}>
                      <NewOrderHeaderCard
                        onClick={async () => {
                          navigate(`/app/product/mes/supply/material/${item?.id}/plan-view`);
                        }}
                        icon={item?.iconKey}
                        name={item?.name}
                        planned={item?.plannedUnplanned?.planned}
                        unPlanned={item?.plannedUnplanned?.unPlanned}
                        selectedOperation={{ id: selectedGroup?.id, name: item?.name }}
                        id={item?.id}
                      />
                    </div>
                  );
                })}
            </div>
          )}
          {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
            <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-gray-200 p-[6px] mt-6 mb-4 rounded-xl animate-pulse flex gap-5" />
          ) : (
            <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-[#F9FAFB] border border-[#EAECF0] p-[6px] rounded-xl flex items-center justify-center mt-6 mb-4">
              {tabOptions?.map((option, index) => (
                <div
                  className={`w-1/2 h-full rounded-md px-3 py-2  flex items-center justify-center cursor-pointer ${
                    "plan" == option?.value ? "bg-white shadow-sm" : ""
                  }`}
                  onClick={() => {
                    if (option?.value != "plan") navigate(`/app/product/mes/supply/material/${id}/junction`);
                  }}
                >
                  <p className={`text-md font-semibold ${"plan" == option?.value ? "text-[#344054]" : "text-[#667085]"}`}>{option?.label}</p>
                </div>
              ))}
            </div>
          )}
          {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
            <div className="w-full flex-1 bg-gray-200 mt-4 rounded-xl animate-pulse" />
          ) : (
            <>
              <div className="flex border border-[#E4E7EC] flex-col rounded-xl">
                <div className="flex w-full px-6 py-3 items-center border-b mb-2">
                  <div className="flex w-full min-h-[28px] max-h-[28px] gap-x-2 items-center">
                    <p className="flex text-[#101828] font-semibold text-lg">
                      {planView?.data?.name} {t("product:products")}
                    </p>
                    <Badgets colorType={"outline-warning"} label={filteredData?.length} />
                  </div>
                  <div className="flex flex-row items-center gap-x-3 justify-end w-full">
                    <div className="flex max-w-[320px] w-full justify-end">
                      <SearchInput theme={"product"} setSearchVal={setSearchQuery} />
                    </div>
                  </div>
                </div>
                <div className="flex w-full px-4 pb-3">
                  {planView?.data?.orderList?.length > 0 && (
                    <div className="w-full pb-4 pt-2 flex overflow-x-auto overflow-y-hidden relative">
                      {filteredData?.map((item, index) => {
                        const statusColor = () => {
                          if (item?.flexProduct || item?.flexRawMaterial) {
                            return "#E9D7FE";
                          } else {
                            switch (item?.status) {
                              case "finished":
                                return "#12B76A";
                              case "started":
                                return "#FDE172";
                              case "rejected":
                                return "#D92D20";
                              case "flexProduct":
                                return "#E9D7FE";
                              default:
                                return "#F2F4F7";
                            }
                          }
                        };
                        return <MaterialSupplyPlanViewPlannedCard item={item} setDraggedProduct={setDraggedProduct} />;
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div style={{ minHeight: height - 300 }} className=" w-full mt-4 border rounded-xl border-[#E4E7EC]  flex flex-col overflow-hidden relative">
                <div className="flex flex-row w-full h-11 min-h-[44px] bg-[#F9FAFB] border-[#EAECF0] rounded-tl-xl rounded-tr-xl border-b">
                  <div
                    className={` min-h-[43px] flex items-center justify-between max-w-[233px] min-w-[233px] px-6 py-3 text-sm bg-[#F9FAFB] font-semibold text-[#344054] border-r border-secondary-200`}
                  >
                    <p className="text-sm text-[#344054] font-semibold">{t("product:stations")}</p>
                  </div>
                  <div
                    ref={outerDivRef}
                    onScroll={() => handleScroll("outer")}
                    id="content-wrapper"
                    className="flex items-center w-full h-full overflow-x-auto overflow-y-hidden"
                  >
                    {tableHeadItems.map((item, index) => (
                      <div
                        key={index}
                        className={` min-h-[44px] flex items-center justify-between min-w-[360px] px-6 py-3 text-sm bg-[#F9FAFB] font-semibold text-[#344054] border-r border-secondary-200`}
                      >
                        {index === 0 ? (
                          <>
                            <p className="text-sm text-[#344054] font-semibold">{t("product:active")}</p>
                          </>
                        ) : index === 1 ? (
                          <>
                            <p className="text-sm text-[#344054] font-semibold">{t("product:plannedWorks")}</p>
                            {item?.title}
                          </>
                        ) : (
                          <>
                            <p></p>
                            {item?.title}
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex-1 w-full flex flex-col overflow-y-auto scrollbar-hide bg-white">
                  {arr?.map((a, b) => {
                    let filledData = [
                      ...(a?.activeJunction || []),
                      ...(a.plannedJunction?.sort((a, b) => a?.planningOrder - b?.planningOrder) || []),
                      ...Array.from(
                        {
                          length: Math.max(tableHeadItemsLength - a?.plannedJunction?.concat(a?.activeJunction)?.length, 0),
                        },
                        () => null
                      ),
                    ];
                    return (
                      <DragDropContext onDragEnd={onDragEnd} onDragUpdate={handleDragUpdate}>
                        <Droppable droppableId={`${b}`} direction="horizontal">
                          {(provided, snapshot) => (
                            <div
                              onDragOver={(e) => e.preventDefault()}
                              onDrop={() => handleDrop(a)}
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              className="flex w-full min-h-[100px]"
                            >
                              <div className="max-w-[335px] min-w-[335px] h-full px-4 py-2 border-r border-b flex items-start">
                                <div className="h-[64px] w-full flex flex-row items-start gap-4">
                                  {a?.image ? (
                                    <img src={generateFileUrl(a?.image)} className="w-[72px] min-w-[72px] min-h-[54px] h-[54px] rounded border object-cover" />
                                  ) : (
                                    <div className="w-[72px] h-[54px] min-w-[72px] min-h-[54px] rounded border text-white text-xl select-none flex items-center justify-center bg-[#DC6803] border-[#DC6803]">
                                      {a?.name?.charAt(0).toUpperCase()}
                                    </div>
                                  )}
                                  <div className="flex flex-col w-full items-start">
                                    <p title={a?.name} className="text-secondary-900 w-[200px] font-semibold truncate">
                                      {a?.name}
                                    </p>
                                    <div className="w-full py-1 flex items-center justify-between border-b border-gray-200">
                                      <p className="text-xs text-secondary-500">{t("product:actives")}</p>
                                      <Badgets colorType={"fill-success"} label={a?.activeJunction?.length || 0} size={"sm"} />
                                    </div>
                                    <div className="w-full py-1 flex items-center justify-between">
                                      <p className="text-xs text-secondary-500">{t("product:newPlanned")}</p>
                                      <Badgets colorType={"fill-gray"} label={a?.plannedJunction?.length || 0} size={"sm"} />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                ref={(el) => {
                                  scrollContainerRef.current = el;
                                  provided.innerRef(el);
                                  mappedDivRefs.current[b] = el;
                                }}
                                onScroll={() => handleScroll("mapped", b)}
                                className="w-full overflow-x-auto scrollbar-hide flex border-b border-gray-200 max-h-[106px] m-0 p-0"
                              >
                                {filledData?.map((item, index) => {
                                  if (item) {
                                    if (item?.status === "active") {
                                      return (
                                        <div className="min-w-[400px] max-w-[400px] flex items-center justify-center py-2 border-r border-gray-200 px-3 bg-white relative group">
                                          <MaterialSupplyPlannedActiveCard item={item} refetch={refetch} />
                                          {/* <MaterialSupplyActivePlannedCard data={item} refetch={refetch} /> */}
                                        </div>
                                      );
                                    } else {
                                      if (b === rowLoading) {
                                        return (
                                          <div className="min-w-[400px] max-w-[400px] flex items-center justify-center py-2 border-r border-gray-200 px-3 bg-white">
                                            <div className="w-full h-full rounded-lg bg-gray-200 animate-pulse" />
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <Draggable key={item?.id} draggableId={item?.id} index={index}>
                                            {(provided, snapshot) => (
                                              <div className="" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <div className="min-w-[400px] max-w-[400px] flex items-center justify-center py-2 border-r border-gray-200 px-3 bg-white h-full">
                                                  {item?.type === "planned" ? (
                                                    <div className="relative w-full group">
                                                      <NewMaterialSupplyPlannedCard
                                                        item={item}
                                                        refetch={refetch}
                                                        setIsOpenSelectUserModal={setIsOpenSelectUserModal}
                                                        setSelectedJunction={setSelectedJunction}
                                                      />
                                                      {/* <MaterialSupplyPlannedCard
                                                              data={item}
                                                              refetch={refetch}
                                                              setIsOpenSelectUserModal={setIsOpenSelectUserModal}
                                                              setSelectedJunction={setSelectedJunction}
                                                            /> */}
                                                    </div>
                                                  ) : (
                                                    <JunctionCardType
                                                      step={item?.header?.step}
                                                      props={{
                                                        leftImage: item?.job?.product?.image,
                                                        texts: {
                                                          first: item?.job?.orderNo,
                                                          second: item?.job?.product?.name,
                                                          a: item?.header?.piece,
                                                          b: String(Math.floor(item?.header?.totalEstimatedTime / 3600)) + " h",
                                                        },
                                                        user: {
                                                          name: item?.user?.name,
                                                          lastName: item?.user?.lastName,
                                                          avatar: item?.user?.avatar || "empty",
                                                        },
                                                      }}
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                          </Draggable>
                                        );
                                      }
                                    }
                                  } else {
                                    return <div className="min-w-[400px] max-w-[400px]" />;
                                  }
                                })}
                              </div>
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
        <CustomModal
          isOpen={isOpenPlanningModal}
          setIsOpen={setIsOpenPlanningModal}
          onClose={() => {
            setIsOpenPlanningModal(false);
          }}
          modalTitle={t("chat:startJob")}
          width="fit-content"
          children={
            <PlanPurchaseJunctionModal
              setIsOpen={setIsOpenPlanningModal}
              processId={planView?.data?.id}
              refetch={refetch}
              selectedJunction={draggedProduct}
              supplierId={supplierData?.id}
              companyId={auth.user.company.id}
            />
          }
        />
        <CustomModal
          isOpen={isOpenSelectUserModal}
          setIsOpen={setIsOpenSelectUserModal}
          onClose={() => {
            setIsOpenSelectUserModal(false);
          }}
          modalTitle={t("chat:startJob")}
          width="fit-content"
          children={
            <SelectUserModal
              t={t}
              setIsOpen={setIsOpenSelectUserModal}
              isOpen={isOpenSelectUserModal}
              selectedJunction={selectedJunction}
              handleData={refetch}
              purchase={true}
            />
          }
        />
        <CustomModal
          isOpen={isOpenFinishJunction}
          setIsOpen={setIsOpenFinishJunction}
          modalTitle={t("product:finishJuction")}
          children={
            <FinishJunctionModal
              handleData={refetch}
              modalTheme={"machine"}
              junctionId={selectedJunction?.id}
              closeModal={() => setIsOpenFinishJunction(false)}
              type={selectedJunction?.internalOperation ? "internal" : "external"}
            />
          }
        />
      </>
    </div>
  );
};

export default MesMaterialSupplyPlanViewPage;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ArrowLeftIcon } from "../../assets/icons/productIcons";
import { Button, CustomModal, Input } from "../../components";
import Badgets from "../../components/buttons/Badgets";
import generateFileUrl from "../../utils/generateFileUrl";
import moment from "moment";
import { CheckIcon, ChevronDown } from "../../assets/icons/modalIcons";
import NewFlexOrderThirdModal from "./modals/NewFlexOrderThirdModal";
import NoAvatar from "../../components/avatar/NoAvatar";
import { legacyOrderService } from "../../services/legacy-new-order-service";
import { Toast } from "../../utils/toastify/toast";
import { orderService } from "../../services/order.service";

const AddNewOrderCustomerThird = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [stockSelected, setStockSelected] = useState([]);
  const [purchaseSelected, setProductionSelected] = useState([]);
  const [selectedRawMaterial, setSelectedRawMaterial] = useState(false);
  const [selectedRawMaterials, setSelectedRawMaterials] = useState({}); // Değişiklik burada
  const [selectedRawModal, setSelectedRawModal] = useState(false);
  const [rowProductsData, setRowProductsData] = useState([]);
  const [formData, setFormData] = useState({});
  const [openDropdown, setOpenDropdown] = useState(false);
  const [urgency, setUrgency] = useState({ label: "1", colorType: "fill-gray" });
  const options = [
    { label: "1", colorType: "fill-gray" },
    { label: "2", colorType: "fill-warning" },
    { label: "3", colorType: "fill-error" },
  ];

  const SecondPageData = JSON.parse(sessionStorage.getItem("secondPageData"));

  const tableHeadItems = [
    t("product:orderProduct"),
    t("product:requiredRaw"),
    t("product:orderQuantityss"),
    t("product:stockAmount"),
    t("product:supplyType"),
    t("product:urgency"),
  ];

  const toggleStockSelected = (item) => {
    const productionQuantity = parseInt(formData[item.id]?.satinAlimYap) || 0;
    if (stockSelected.some((selectedItem) => selectedItem.id === item.id)) {
      setStockSelected(stockSelected.filter((selectedItem) => selectedItem.id !== item.id));
      setFormData((prev) => ({ ...prev, [item.id]: { ...prev[item.id], stoktanGetir: "" } }));
    } else {
      const remainingQuantity = (item?.productionQuantity * (item?.quantityUsedPerPiece || 1) - productionQuantity).toString();
      setStockSelected([...stockSelected, item]);
      handleInputChange(remainingQuantity, item.id, "stoktanGetir", item);
    }
  };

  const togglePurchaseSelected = (item) => {
    const stockQuantity = parseInt(formData[item.id]?.stoktanGetir) || 0;
    if (purchaseSelected.some((selectedItem) => selectedItem.id === item.id)) {
      setProductionSelected(purchaseSelected.filter((selectedItem) => selectedItem.id !== item.id));
      setFormData((prev) => ({ ...prev, [item.id]: { ...prev[item.id], satinAlimYap: "" } }));
    } else {
      const remainingQuantity = (item?.productionQuantity * (item?.quantityUsedPerPiece || 1) - stockQuantity).toString();
      setProductionSelected([...purchaseSelected, item]);
      handleInputChange(remainingQuantity, item.id, "satinAlimYap", item);
    }
  };

  const handleInputChange = (e, itemId, type, item) => {
    let newValue = e;

    if (
      type === "stoktanGetir" &&
      (selectedRawMaterials?.[item?.id]?.activePiece ? selectedRawMaterials?.[item?.id]?.activePiece || 0 : selectedRawMaterials?.[item?.id]?.quantity || 0) >
        item?.productionQuantity * (item?.quantityUsedPerPiece || 1) &&
      e > item?.productionQuantity * (item?.quantityUsedPerPiece || 1)
    ) {
      newValue = item?.productionQuantity * (item?.quantityUsedPerPiece || 1);
    } else if (
      type === "stoktanGetir" &&
      e > (selectedRawMaterials?.[item?.id]?.activePiece ? selectedRawMaterials?.[item?.id]?.activePiece || 0 : selectedRawMaterials?.[item?.id]?.quantity || 0)
    ) {
      newValue = selectedRawMaterials?.[item?.id]?.activePiece
        ? selectedRawMaterials?.[item?.id]?.activePiece || 0
        : selectedRawMaterials?.[item?.id]?.quantity || 0;
      Toast("error", "Stoktan çıkış miktarı, stok miktarından fazla olamaz.");
    }

    if (type === "stoktanGetir") {
      const stoktanGetirValue = newValue;
      const remainingQuantity = item?.productionQuantity * (item?.quantityUsedPerPiece || 1) - stoktanGetirValue;

      setFormData((prev) => ({
        ...prev,
        [itemId]: {
          ...prev[itemId],
          stoktanGetir: newValue,
          satinAlimYap: remainingQuantity > 0 ? remainingQuantity : 0,
        },
      }));
    }

    if (type === "satinAlimYap") {
      const satinAlimValue = newValue;
      const remainingQuantity = item?.productionQuantity * (item?.quantityUsedPerPiece || 1) - satinAlimValue;

      setFormData((prev) => ({
        ...prev,
        [itemId]: {
          ...prev[itemId],
          satinAlimYap: newValue,
          stoktanGetir: remainingQuantity > 0 ? remainingQuantity : 0,
        },
      }));
    }
  };

  const handleRawMaterialSelection = (rawMaterial) => {
    setSelectedRawMaterials((prev) => ({
      ...prev,
      [rowProductsData.id]: rawMaterial,
    }));
    setSelectedRawModal(false);
  };

  const onSubmit = async () => {
    const updatedProducts = SecondPageData?.selectedProducts?.map((item) => ({
      ...item,
      stoktanGetir: parseInt(formData[item.id]?.stoktanGetir) || 0,
      satinAlimYap: parseInt(formData[item.id]?.satinAlimYap) || 0,
      selectedRawMaterial: selectedRawMaterials[item.id] || null,
    }));

    const hasStockError = updatedProducts.find(
      (item) =>
        item.stoktanGetir >
        (selectedRawMaterials?.[item?.id]?.activePiece ? selectedRawMaterials?.[item?.id]?.activePiece || 0 : selectedRawMaterials?.[item?.id]?.quantity || 0)
    );
    const hasOrderError = updatedProducts.find(
      (item) => item?.productionQuantity * (item?.quantityUsedPerPiece || 1) !== item.stoktanGetir + item.satinAlimYap
    );
    const hasRawMaterialError = updatedProducts.find((item) => !item.selectedRawMaterial);

    if (hasRawMaterialError) {
      if (hasRawMaterialError.stockQuantity === hasRawMaterialError.orderedQuantity) {
        setSelectedRawMaterials((prev) => ({
          ...prev,
          [hasRawMaterialError.id]: {},
        }));
      } else {
        Toast("error", `${hasRawMaterialError?.name} ürünü için hammadde seçimi yapmalısınız.`);
        return;
      }
    }
    if (hasStockError) {
      Toast("error", `${hasStockError?.name} Stoktan çıkış miktarı, sipariş miktarından fazla olamaz.`);
      return;
    }
    if (hasOrderError) {
      Toast("error", `${hasOrderError?.name} Ürün için stoktan çıkış ve satın alım miktarı toplamı sipariş miktarına eşit olmalıdır.`);
      return;
    }

    const sendData = {
      orderNo: SecondPageData?.orderCode,
      customer: SecondPageData?.customer?.id,
      customerOrderNo: SecondPageData?.customerOrderNo,
      products: updatedProducts?.map((item) => ({
        stockQuantity: item?.stockQuantity,
        currency: item?.currency,
        productionQuantity: item?.productionQuantity,
        waitingQuantity: item?.waitingQuantity,
        orderedQuantity: item?.orderedQuantity,
        customerStockCode: item?.customerStockCode,
        productPrice: item?.productPrice,
        deliveryDate: item?.deliveryDate,
        product: item?.id,
        purchaseRequest: {
          type: item?.selectedRawMaterial?.process
            ? item?.selectedRawMaterial?.type === "internal"
              ? "semi_internal_product"
              : "semi_external_product"
            : "raw",
          orderedQuantity: item?.orderedQuantity * (item?.quantityUsedPerPiece || 1),
          stockQuantity: item?.stoktanGetir,
          purchaseQuantity: item?.satinAlimYap,
          typeId: item?.selectedRawMaterial?.id,
        },
      })),
    };
    // @Post('/latter/adding-items/:id')
    await orderService.addNewProductToOrder(id, sendData).then((res) => {
      console.log("res", res);
    });
    // await legacyOrderService.flexNewOrder(sendData).then((res) => {
    //   if (res.data.code == 0) {
    //     Toast("success", res?.data?.message);
    //     sessionStorage.removeItem("firstPageData");
    //     sessionStorage.removeItem("secondPageData");
    //     navigate("/app/product/mrp/orders");
    //   } else {
    //     Toast("error", res?.data?.message);
    //   }
    // });
  };

  const handleDropdownToggle = (itemId) => {
    setOpenDropdown(openDropdown === itemId ? null : itemId);
  };

  const handleSelect = (option) => {
    setUrgency(option);
    setOpenDropdown(null);
  };

  const totalOrderAmount = SecondPageData?.selectedProducts
    ?.filter((item) => item.orderedQuantity !== item.stockQuantity)
    ?.reduce((acc, item) => acc + (item.orderedQuantity || 0), 0);
  const totalCost = SecondPageData?.selectedProducts
    ?.filter((item) => item.orderedQuantity !== item.stockQuantity)
    ?.reduce((acc, item) => acc + item.productPrice * (item.orderedQuantity || 0), 0);

  useEffect(() => {
    if (SecondPageData && selectedRawMaterial) {
      const initialStockSelected = [];
      const initialPurchaseSelected = [];

      SecondPageData.selectedProducts.forEach((item) => {
        setStockSelected([]);
        setProductionSelected([]);
        handleInputChange(0, item.id, "satinAlimYap", item);
        handleInputChange(0, item.id, "stoktanGetir", item);
        if (selectedRawMaterials?.[item?.id]) {
          if (
            (selectedRawMaterials?.[item?.id]?.activePiece
              ? selectedRawMaterials?.[item?.id]?.activePiece || 0
              : selectedRawMaterials?.[item?.id]?.quantity || 0) === 0
          ) {
            initialPurchaseSelected.push(item); // Üretime Gönder seçili hale getirilir
            handleInputChange(item?.productionQuantity * (item?.quantityUsedPerPiece || 1), item.id, "satinAlimYap", item);
          } else {
            if (
              (selectedRawMaterials?.[item?.id]?.activePiece
                ? selectedRawMaterials?.[item?.id]?.activePiece || 0
                : selectedRawMaterials?.[item?.id]?.quantity || 0) <=
              item?.productionQuantity * (item?.quantityUsedPerPiece || 1)
            ) {
              initialStockSelected.push(item); // Stoktan Getir seçili hale getirilir
              handleInputChange(
                selectedRawMaterials?.[item?.id]?.activePiece
                  ? selectedRawMaterials?.[item?.id]?.activePiece || 0
                  : selectedRawMaterials?.[item?.id]?.quantity || 0,
                item.id,
                "stoktanGetir",
                item
              );

              const remainingQuantity =
                item?.productionQuantity * (item?.quantityUsedPerPiece || 1) -
                (selectedRawMaterials?.[item?.id]?.activePiece
                  ? selectedRawMaterials?.[item?.id]?.activePiece || 0
                  : selectedRawMaterials?.[item?.id]?.quantity || 0);
              if (remainingQuantity > 0) {
                initialPurchaseSelected.push(item); // Kalan miktar için Üretime Gönder seçili hale getirilir
                handleInputChange(remainingQuantity, item.id, "satinAlimYap", item);
              }
            } else {
              initialStockSelected.push(item); // Tüm sipariş miktarı için Stoktan Getir seçili hale getirilir
              handleInputChange(item?.productionQuantity * (item?.quantityUsedPerPiece || 1), item.id, "stoktanGetir", item);
            }
          }
        }
      });

      setStockSelected(initialStockSelected);
      setProductionSelected(initialPurchaseSelected);
    } else {
      setStockSelected([]);
      setProductionSelected([]);
    }
  }, [selectedRawMaterials]);

  const currencyIcon = (currency) => {
    switch (currency || "TRY") {
      case "USD":
        return "$";
      case "EUR":
        return "€";
      case "GBP":
        return "£";
      case "RUB":
        return "₽";
      case "UAH":
        return "₴";
      case "TRY":
        return "₺";
      default:
        return "₺";
    }
  };

  return (
    <>
      <div className="flex flex-col w-full gap-y-8 h-[95vh]">
        <div className="flex min-h-[56px] max-h-[56px] h-[56px] w-full items-center justify-start gap-x-2 sticky top-0 z-[50] bg-white">
          {/* <Button size={"md"} iconLeft={<ArrowLeftIcon />} colorType={"tertiary-gray"} onClick={() => navigate(-1)} type={"button"} /> */}
          <p className="text-[#101828] font-semibold text-2xl">Siparişe Yeni Ürün Ekle</p>
        </div>

        <div className="flex flex-col gap-y-6 w-full h-full">
          <div className="flex w-full min-h-[72px] max-h-[72px] h-[72px] pb-5 justify-between border-b border-[#E4E7EC]">
            <div className="flex flex-col gap-y-1">
              <p className="text-[#101828] font-semibold text-lg">Tedarik Formu</p>
              <p className="text-[#344054] font-normal text-sm">Sipariş ürünlerinin üretimi için gerekli hammaddelerin tedarik türünü seçin.</p>
            </div>
            <div className="flex gap-x-3 max-h-[40px] min-h-[40px] h-10">
              <span className="flex min-w-[144px] max-w-[144px] w-[144px]">
                <Button
                  size={"md"}
                  colorType={"secondary-gray"}
                  label={t("buttons:previous")}
                  onClick={() => navigate(`/app/product/mrp/orders/new-order/second-page/${id}`)}
                />
              </span>
              <span className="flex min-w-[144px] max-w-[144px] w-[144px]">
                <Button size={"md"} colorType={"primary-product"} label={t("buttons:save")} type={"submit"} onClick={onSubmit} />
              </span>
            </div>
          </div>
          <div className="flex w-full h-full relative">
            <div className="flex flex-col gap-y-5 w-full h-full border border-[#E4E7EC] rounded-t-xl">
              <div className="flex w-full min-h-[48px] max-h-[48px] h-[48px] justify-between bg-[#F9FAFB] rounded-tl-xl items-center px-6 py-3 border-b border-[#E4E7EC]">
                <div className="flex gap-x-2 items-center">
                  <img src={generateFileUrl(SecondPageData?.customer?.image)} className="min-w-[32px] w-[32px] h-[24px] rounded-sm border border-[#0000001A]" />
                  <p className="text-[#101828] font-medium text-sm">{SecondPageData?.customer?.name}</p>
                </div>
                <p className="text-[#344054] text-sm">
                  {t("product:orderCode")}: {SecondPageData?.orderCode}
                </p>
              </div>

              <div className={`flex flex-col w-full h-full px-6`}>
                {/* table header */}
                <div className="flex max-h-[26px] min-h-[26px] w-full">
                  {tableHeadItems.map((item, i) => (
                    <div
                      key={i}
                      className={`flex border-b px-2 pb-2 border-r border-[#E4E7EC] items-center w-[${
                        i === 0 ? "25%" : i === 1 ? "25%" : i === 2 ? "8%" : i === 3 ? "8%" : i === 4 ? "26%" : i === 5 ? "8%" : "0"
                      }] ${i === tableHeadItems.length - 1 ? "border-r-0" : ""} ${i === 0 || i === 1 ? "justify-start" : "justify-center"}`}
                    >
                      <span className="text-center text-xs font-medium cursor-pointer whitespace-nowrap">{item}</span>
                    </div>
                  ))}
                </div>
                {SecondPageData?.selectedProducts
                  ?.filter((item) => item.orderedQuantity !== item.stockQuantity)
                  ?.map((item, i) => (
                    <div key={i} className="flex w-full items-center border-b h-[114px] min-h-[114px] ">
                      {/* tablo 1.kolon */}
                      <div className=" flex items-center py-3 pr-4 border-r h-full w-[25%] justify-start">
                        <div className="flex gap-x-2 p-2 pr-3 w-full min-h-[90px] max-h-[90px] h-[90px] border border-[#D0D5DD] shadow-xs rounded-lg">
                          <div className="flex w-full flex-col gap-y-1">
                            <div className="flex w-full gap-x-3">
                              <img src={generateFileUrl(item?.image)} className="min-w-[64px] w-[64px] h-[48px] rounded border border-[#0000001A]" />
                              <div className="flex flex-col justify-between ">
                                <p className="text-[#101828] font-semibold text-md truncate w-[250px]">{item?.name}</p>
                                <div className="flex min-h-[24px] max-h-[24px] h-6 rounded px-1 py-[3px] items-center w-fit max-w-[250px] bg-[#FFFAEB]">
                                  <p className="text-[#B54708] text-xs font-medium truncate">
                                    {t("product:stock")}: {item?.stockCode || "--"}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="flex w-full gap-x-3 items-center">
                              <div className="flex gap-x-[2px] items-center">
                                <div className="flex gap-x-1 items-center">
                                  <CalendarIcon />
                                  <p className="text-[#475467] font-medium text-sm">{moment(item?.deliveryDate).format("DD.MM.YYYY")}</p>
                                </div>
                                <p className="text-[#667085] font-normal text-xxs mt-[2px]">{t("product:deliveryDate")}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* tablo 2.kolon */}
                      <div className="border-r flex flex-col h-full py-2 px-4  items-center justify-center w-[25%] relative">
                        {selectedRawMaterials?.[item?.id] ? (
                          <>
                            {selectedRawMaterials?.[item?.id]?.process ? (
                              <div
                                onClick={() => {
                                  setRowProductsData(item);
                                  setSelectedRawModal(true);
                                }}
                                className="flex flex-col gap-y-1 justify-between w-full min-h-[90px] max-h-[90px] h-[90px] pl-2 border border-[#D0D5DD] rounded-lg py-2 cursor-pointer relative"
                              >
                                <div className="flex gap-x-3 w-full">
                                  <img src={generateFileUrl(item?.image)} className="min-w-[64px] w-[64px] h-[48px] rounded border border-[#0000001A]" />
                                  <div className="flex flex-col justify-between">
                                    <p className="text-[#101828] font-semibold text-md">{selectedRawMaterials?.[item?.id]?.process?.name}</p>
                                    <div className="flex min-h-[24px] max-h-[24px] h-6 rounded px-1 py-[3px] items-center w-fit max-w-[250px] bg-[#FFFAEB]">
                                      <p className="text-[#B54708] text-xs font-medium truncate">
                                        {t("product:stock")}: {item?.stockCode || "--"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex gap-x-3 w-full items-center">
                                  <div className="flex gap-x-[2px] items-center">
                                    <div className="flex gap-x-1">
                                      <QuantityIcon />
                                      <p className="text-[#475467] font-medium text-xs">{selectedRawMaterials?.[item?.id]?.activePiece || 0}</p>
                                    </div>
                                    <p className="text-[#475467] text-xxs">
                                      {item?.rawMaterial?.unitType || "ad"}. {t("product:workInProcess")}
                                    </p>
                                  </div>
                                </div>
                                <div className="flex min-h-[24px] max-h-[24px] h-6 min-w-[70px] max-w-[70px] w-[70px] border-t border-r border-[#D0D5DD] bg-[#F2F4F7] -rotate-90 absolute bottom-[23px] -right-[23px] rounded-bl-lg rounded-tr-lg p-1 items-center justify-center gap-x-1">
                                  <span className="rotate-90">
                                    <CheckIconOp />
                                  </span>
                                  <p className="text-[#475467] text-xxs font-semibold">{selectedRawMaterials?.[item?.id]?.step}.Op</p>
                                </div>
                                <div className="flex min-h-[22px] max-h-[22px] h-[22px] absolute -left-2 -top-2 border border-[#E4E7EC] bg-[#F9FAFB] rounded-md px-[6px] py-[2px] items-center justify-center">
                                  <p className="text-[#344054] font-medium text-xs">x{item?.quantityUsedPerPiece ?? "1"}</p>
                                </div>
                              </div>
                            ) : (
                              <div
                                onClick={() => {
                                  setRowProductsData(item);
                                  setSelectedRawModal(true);
                                }}
                                className="flex gap-x-2 p-2 pr-3 w-full min-h-[90px] max-h-[90px] h-[90px] border border-[#D0D5DD] shadow-xs rounded-lg cursor-pointer relative"
                              >
                                <div className="flex w-full flex-col gap-y-1">
                                  <div className="flex w-full gap-x-3">
                                    {selectedRawMaterials?.[item?.id]?.image ? (
                                      <img
                                        src={generateFileUrl(selectedRawMaterials?.[item?.id]?.image)}
                                        className="min-w-[64px] w-[64px] h-[48px] rounded border border-[#0000001A]"
                                      />
                                    ) : (
                                      <div className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-200">
                                        <NoAvatar name={selectedRawMaterials?.[item?.id]?.name || "--"} rounded={4} theme={"product"} />
                                      </div>
                                    )}

                                    <div className="flex flex-col justify-between ">
                                      <p className="text-[#101828] font-semibold text-md truncate w-[250px]">
                                        {selectedRawMaterials?.[item?.id]?.name || "--"}
                                      </p>
                                      <div className="flex min-h-[24px] max-h-[24px] h-6 rounded px-1 py-[3px] items-center w-fit max-w-[250px] bg-[#FFFAEB]">
                                        <p className="text-[#B54708] text-xs font-medium truncate">
                                          {t("product:stock")}: {selectedRawMaterials?.[item?.id]?.stockCode || "--"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex w-full gap-x-[2px] items-center">
                                    <div className="flex gap-x-1 items-center">
                                      <FreeIcon />
                                      <p className="text-[#475467] font-medium text-sm">{selectedRawMaterials?.[item?.id]?.quantity || 0}</p>
                                    </div>
                                    <p className="text-[#667085] font-normal text-xxs mt-[2px]">
                                      {selectedRawMaterials?.[item?.id]?.unitType} {t("product:availableStock")}
                                    </p>
                                  </div>
                                </div>
                                <div className="flex min-h-[24px] max-h-[24px] h-6 min-w-[70px] max-w-[70px] w-[70px] border-t border-r border-[#D0D5DD] bg-[#F2F4F7] -rotate-90 absolute bottom-[23px] -right-[23px] rounded-bl-lg rounded-tr-lg p-1 items-center justify-center">
                                  <p className="text-[#475467] font-semibold text-xxs">{t("product:rawMaterial")}</p>
                                </div>
                                <div className="flex min-h-[22px] max-h-[22px] h-[22px] absolute -left-2 -top-2 border border-[#E4E7EC] bg-[#F9FAFB] rounded-md px-[6px] py-[2px] items-center justify-center">
                                  <p className="text-[#344054] font-medium text-xs">x{item?.quantityUsedPerPiece ?? "1"}</p>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <div
                            onClick={() => {
                              setRowProductsData(item);
                              setSelectedRawModal(true);
                            }}
                            className="flex w-full h-full border-[2px] border-dashed shadow-xs border-[#D0D5DD] rounded-lg justify-center items-center cursor-pointer"
                          >
                            <AddPlusIcon />
                          </div>
                        )}
                      </div>
                      {/* tablo 3.kolon */}
                      <div className="flex flex-col h-full py-3  items-center justify-center w-[8%] border-r border-[#E4E7EC]">
                        <p className="text-[#344054] font-medium text-sm">{item?.productionQuantity * (parseInt(item?.quantityUsedPerPiece) || 1)}</p>
                        <p className="text-[#667085] text-xs">{t("product:pieces")}</p>
                      </div>
                      {/* tablo 4.kolon */}
                      <div className="flex flex-col h-full py-3  items-center justify-center w-[8%] border-r border-[#E4E7EC]">
                        <p className="text-[#344054] font-medium text-sm">
                          {selectedRawMaterials?.[item?.id]?.activePiece
                            ? selectedRawMaterials?.[item?.id]?.activePiece || 0
                            : selectedRawMaterials?.[item?.id]?.quantity || 0}
                        </p>
                        <p className="text-[#667085] text-xs">{t("product:pieces")}</p>
                      </div>
                      {/* tablo 5.kolon */}
                      <div className="border-r flex h-full px-6 py-4  items-center justify-center w-[26%] gap-x-6">
                        <div className="flex flex-col gap-y-3 w-1/2">
                          <div
                            onClick={() => {
                              if (
                                (selectedRawMaterials?.[item?.id]?.activePiece
                                  ? selectedRawMaterials?.[item?.id]?.activePiece || 0
                                  : selectedRawMaterials?.[item?.id]?.quantity || 0) === 0
                              ) {
                                Toast("error", "Stok miktarı 0 olan ürünler için stoktan çıkış yapamazsınız.");
                              } else {
                                toggleStockSelected(item);
                              }
                            }}
                            className={`flex gap-x-2 items-center  w-fit ${
                              (selectedRawMaterials?.[item?.id]?.activePiece
                                ? selectedRawMaterials?.[item?.id]?.activePiece || 0
                                : selectedRawMaterials?.[item?.id]?.quantity || 0) === 0
                                ? "cursor-not-allowed opacity-50"
                                : "cursor-pointer"
                            }`}
                          >
                            <div
                              className={`min-w-[16px] w-4 h-4 rounded flex items-center justify-center border ${
                                stockSelected.some((selectedItem) => selectedItem.id === item.id)
                                  ? "bg-[#DC6803] border-[#DC6803] text-[#fff]"
                                  : "border-[#D0D5DD]"
                              }`}
                            >
                              {stockSelected.some((selectedItem) => selectedItem.id === item.id) && <CheckIcon />}
                            </div>
                            <div className="text-secondary-500 font-normal text-sm">{t("product:bringFromStock")}</div>
                            <span className="group flex relative">
                              <HelpCircleIcon />
                              <div className="hidden absolute min-w-[293px] max-w-[293px] w-[293px] min-h-[52px] max-h-[52px] h-[52px] group-hover:flex bottom-[26px] -left-[136px] bg-[#0C111D] rounded-lg px-3 py-2 text-center">
                                <p className="text-[#FFFFFF] font-semibold text-xs">{t("product:enterTheDeducted")}</p>
                              </div>
                              <span className="hidden absolute group-hover:flex bottom-[20px]">
                                <TriangleTooltipIcon />
                              </span>
                            </span>
                          </div>
                          <div className="flex w-full h-full">
                            <Input
                              icon={<QuantityIcon />}
                              theme="product"
                              value={formData[item.id]?.stoktanGetir || ""}
                              onChange={(e) => handleInputChange(e?.target?.value, item.id, "stoktanGetir", item)}
                              disabled={!stockSelected.some((selectedItem) => selectedItem.id === item.id)}
                            />
                          </div>
                        </div>
                        <div className="flex flex-col gap-y-3 w-1/2">
                          <div onClick={() => togglePurchaseSelected(item)} className="flex gap-x-2 items-center cursor-pointer w-fit">
                            <div
                              className={`min-w-[16px] w-4 h-4 rounded flex items-center justify-center border ${
                                purchaseSelected.some((selectedItem) => selectedItem.id === item.id)
                                  ? "bg-[#DC6803] border-[#DC6803] text-[#fff]"
                                  : "border-[#D0D5DD]"
                              }`}
                            >
                              {purchaseSelected.some((selectedItem) => selectedItem.id === item.id) && <CheckIcon />}
                            </div>
                            <div className="text-secondary-500 font-normal text-sm">{t("product:markPurchase")}</div>
                            <span className="group flex relative">
                              <HelpCircleIcon />
                              <div className="hidden absolute min-w-[370px] max-w-[370px] w-[370px] min-h-[70px] max-h-[70px] h-[70px] group-hover:flex bottom-[26px] -left-[175px] bg-[#0C111D] rounded-lg px-3 py-2 text-center">
                                <p className="text-[#FFFFFF] font-semibold text-xs">{t("product:youCanSendTheEntire")}</p>
                              </div>
                              <span className="hidden absolute group-hover:flex bottom-[20px]">
                                <TriangleTooltipIcon />
                              </span>
                            </span>
                          </div>
                          <div className="flex w-full h-full">
                            <Input
                              icon={<QuantityIcon />}
                              theme="product"
                              value={formData[item.id]?.satinAlimYap || ""}
                              onChange={(e) => handleInputChange(e?.target?.value, item.id, "satinAlimYap", item)}
                              disabled={!purchaseSelected.some((selectedItem) => selectedItem.id === item.id)}
                            />
                          </div>
                        </div>
                      </div>
                      {/* tablo 6.kolon */}
                      <div className="flex h-full p-3 items-center justify-center w-[8%] gap-x-1 text-[#98A2B3]">
                        <span onClick={() => handleDropdownToggle(item.id)} className="flex cursor-pointer justify-center items-center relative">
                          <Badgets colorType={urgency.colorType} label={urgency.label} />
                          <ChevronDown />
                          {openDropdown === item.id && (
                            <div className="absolute w-[52px] h-[132px] bg-white border shadow-lg border-[#E4E7EC] rounded-lg flex flex-col py-1 px-[6px] top-[28px] right-0 z-[100]">
                              {options.map((option, index) => (
                                <div key={index} onClick={() => handleSelect(option)} className="flex w-10 h-10 items-center justify-center cursor-pointer">
                                  <Badgets colorType={option.colorType} label={option.label} />
                                </div>
                              ))}
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="absolute w-full min-h-[72px] max-h-[72px] h-[72px] px-6 bottom-4">
              <div className="flex w-full min-h-[72px] max-h-[72px] h-[72px] bg-[#B54708] shadow-lg rounded-xl justify-between">
                <div className="flex h-full py-3 px-4 gap-x-4 items-center">
                  <div className="flex min-w-[64px] max-w-[64px] min-h-[48px] max-h-[48px] relative">
                    <img
                      className="absolute z-[10] top-[8px] left-[-2px] min-w-[40px] max-w-[40px] min-h-[30px] max-h-[30px] border-[2px] border-[#FFFFFFCC] rounded"
                      src={generateFileUrl(SecondPageData?.selectedProducts?.[0]?.image)}
                    />
                    <img
                      className="absolute z-[20] top-[-2px] left-[10px] min-w-[40px] max-w-[40px] min-h-[30px] max-h-[30px] border-[2px] border-[#FFFFFFCC] rounded"
                      src={generateFileUrl(SecondPageData?.selectedProducts?.[1]?.image)}
                    />
                    <img
                      className="absolute z-[30] bottom-[-2px] right-[-2px] min-w-[40px] max-w-[40px] min-h-[30px] max-h-[30px] border-[2px] border-[#FFFFFFCC] rounded"
                      src={generateFileUrl(SecondPageData?.selectedProducts?.[2]?.image)}
                    />
                  </div>
                  <div className="flex gap-x-1 items-center">
                    <p className="text-[#FFFFFF] font-semibold text-xl">
                      {SecondPageData?.selectedProducts?.filter((item) => item.orderedQuantity !== item.stockQuantity)?.length}
                    </p>
                    <p className="text-[#FFFFFF] font-normal text-md">{t("product:productsss")}</p>
                  </div>
                </div>
                <div className="flex py-3 text-[#FFFFFF] font-semibold text-lg">
                  <div className="flex px-5 items-center justify-center border-r border-[#FFFFFF4D]">{t("product:totalAmount")}</div>
                  <div className="flex flex-col gap-y-[2px] items-center justify-center px-5 border-r border-[#FFFFFF4D]">
                    <p className="text-xs font-medium">{t("product:orderQuantity")}</p>
                    <p>{totalOrderAmount}</p>
                  </div>
                  <div className="flex flex-col gap-y-[2px] items-center justify-center px-5 border-r border-[#FFFFFF4D]">
                    <p className="text-xs font-medium">{t("product:totalSales")}</p>
                    <p>
                      {currencyIcon(SecondPageData?.selectedProducts[0]?.currency)} {totalCost}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        isOpen={selectedRawModal}
        setIsOpen={setSelectedRawModal}
        modalTitle={t("product:addRawMaterialIntermediate")}
        subTitle={t("product:addTheRequired")}
        width={500}
        children={
          <NewFlexOrderThirdModal
            rowProductsData={rowProductsData}
            setSelectedRawMaterial={setSelectedRawMaterial}
            selectedRawMaterial={selectedRawMaterial}
            setSelectRawCardModal={setSelectedRawModal}
            handleRawMaterialSelection={handleRawMaterialSelection}
          />
        }
      />
    </>
  );
};

export default AddNewOrderCustomerThird;

const QuantityIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M5.75 2.375H3.71429C2.76751 2.375 2 3.14251 2 4.08929V11.9107C2 12.8575 2.76751 13.625 3.71429 13.625H12.2857C13.2325 13.625 14 12.8575 14 11.9107V4.08929C14 3.14251 13.2325 2.375 12.2857 2.375H10.25M5.75 2.375V6.73631C5.75 6.80003 5.81706 6.84147 5.87405 6.81298L8 5.75L10.126 6.81298C10.1829 6.84147 10.25 6.80003 10.25 6.73631V2.375M5.75 2.375H10.25"
        stroke="#475467"
      />
    </svg>
  );
};

const HelpCircleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clip-path="url(#clip0_17461_3132)">
        <path
          d="M6.06016 6.00016C6.2169 5.55461 6.52626 5.1789 6.93347 4.93958C7.34067 4.70027 7.81943 4.61279 8.28495 4.69264C8.75047 4.77249 9.17271 5.01451 9.47688 5.37585C9.78105 5.73718 9.94753 6.19451 9.94683 6.66683C9.94683 8.00016 7.94683 8.66683 7.94683 8.66683M8.00016 11.3335H8.00683M14.6668 8.00016C14.6668 11.6821 11.6821 14.6668 8.00016 14.6668C4.31826 14.6668 1.3335 11.6821 1.3335 8.00016C1.3335 4.31826 4.31826 1.3335 8.00016 1.3335C11.6821 1.3335 14.6668 4.31826 14.6668 8.00016Z"
          stroke="#98A2B3"
          stroke-width="1.33"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17461_3132">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const TriangleTooltipIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="9" viewBox="0 0 17 9" fill="none">
      <path
        d="M8.5 0.49993L14.5711 0.49993C15.462 0.49993 15.9081 1.57707 15.2782 2.20704L9.20711 8.2781C8.81658 8.66863 8.18342 8.66863 7.79289 8.2781L1.72183 2.20704C1.09186 1.57707 1.53803 0.49993 2.42893 0.49993L8.5 0.49993Z"
        fill="#0C111D"
      />
    </svg>
  );
};

const CalendarIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M14 6.66659H2M10.6667 1.33325V3.99992M5.33333 1.33325V3.99992M5.2 14.6666H10.8C11.9201 14.6666 12.4802 14.6666 12.908 14.4486C13.2843 14.2569 13.5903 13.9509 13.782 13.5746C14 13.1467 14 12.5867 14 11.4666V5.86659C14 4.74648 14 4.18643 13.782 3.7586C13.5903 3.38228 13.2843 3.07632 12.908 2.88457C12.4802 2.66659 11.9201 2.66659 10.8 2.66659H5.2C4.0799 2.66659 3.51984 2.66659 3.09202 2.88457C2.71569 3.07632 2.40973 3.38228 2.21799 3.7586C2 4.18643 2 4.74648 2 5.86659V11.4666C2 12.5867 2 13.1467 2.21799 13.5746C2.40973 13.9509 2.71569 14.2569 3.09202 14.4486C3.51984 14.6666 4.0799 14.6666 5.2 14.6666Z"
        stroke="#475467"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const AddPlusIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path d="M15.9998 6.6665V25.3332M6.6665 15.9998H25.3332" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

const CheckIconOp = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path d="M3 2L8.5 7.5L6 10" stroke="#475467" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

const FreeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clip-path="url(#clip0_18020_22290)">
        <path
          d="M5.50016 1.3335H3.00016C2.07969 1.3335 1.3335 2.07969 1.3335 3.00016V13.0002C1.3335 13.9206 2.07969 14.6668 3.00016 14.6668H6.51868M5.50016 1.3335V5.19064C5.50016 5.25535 5.56912 5.29672 5.62621 5.26627L8.00016 4.00016L10.3741 5.26627C10.4312 5.29672 10.5002 5.25535 10.5002 5.19064V1.3335M5.50016 1.3335H10.5002M10.5002 1.3335H13.0002C13.9206 1.3335 14.6668 2.07969 14.6668 3.00016V7.20981M14.6675 11.9602H9.29022M14.6675 11.9602L11.987 14.6668M14.6675 11.9602L11.987 9.29856"
          stroke="#475467"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_18020_22290">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import React from "react";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";
import { TableArrowLeft } from "../../assets/icons/lossIcon";
import Badgets from "../../components/buttons/Badgets";

const OrderLogDetail = () => {
  const navigate = useNavigate();

  const productsHeadItems = [
    "Ürün Adı & Stok Kodu",
    "Termin",
    "Sipariş Miktarı",
    "İş Emri Oluşturulan Miktar",
    "Gönderilen Miktar",
    "Birim Satış Fiyatı",
    "Toplam Satış Fiyatı",
    "Düzenle",
  ];
  const productsTableWidths = ["30%", "8%", "10%", "15%", "12%", "10%", "10%", "72px"];

  const LogHeadItems = ["Kod", "Tarih", "Eylem", "Miktar", "Kişi", "Müşteri", "Git"];
  const LogTableWidths = ["21%", "18%", "18%", "18%", "21%", "auto", "5%"];

  <div className="flex w-full border-y border-[#E4E7EC] bg-[#F9FAFB] min-h-[34px] max-h-[34px] h-[34px]">
    {productsHeadItems.map((item, i) => (
      <div key={i} className="flex px-6 py-2 border-r border-[#E4E7EC]" style={{ width: productsTableWidths[i] }}>
        <span className="text-[#344054] text-xs font-medium whitespace-nowrap">{item}</span>
      </div>
    ))}
  </div>;

  return (
    <div className="flex flex-col gap-y-8 h-full w-full">
      <div className="flex w-full justify-between items-center min-h-[56px] max-h-[56px] h-[56px] border-b border-[#E4E7EC]">
        <div className="flex gap-x-2 min-h-[40px] max-h-[40px] h-10 items-center">
          <Button
            colorType={"tertiary-gray"}
            onClick={() => {
              navigate(-1);
            }}
            size={"md"}
            iconLeft={
              <div className="rotate-180">
                <TableArrowLeft />
              </div>
            }
          />
          <div className="flex gap-x-2 items-end">
            <p className="text-[#B54708] font-semibold text-2xl">ORD-00001</p>
            <p className="text-[#475467] text-xl">Sipariş Detayı</p>
          </div>
        </div>
      </div>
      <div className="flex w-full min-h-[68px] max-h-[68px] h-[68px] gap-x-6">
        <div className="flex gap-x-3 px-4 py-3 h-full rounded-lg border border-[#D0D5DD] w-full">
          <div className="flex min-w-[57px] max-w-[57px] w-[57px] min-h-11 max-h-11 h-11 border border-[#0000001A]"></div>
          <div className="flex flex-col">
            <p className="text-[#475467] text-sm">Müşteri</p>
            <p className="text-[#B54708] font-semibold text-md">Baca Makine Mühendislik</p>
          </div>
        </div>
        <div className="flex flex-col px-4 py-3 h-full rounded-lg border border-[#D0D5DD] min-w-[159px] max-w-[159px] w-[159px]">
          <p className="text-[#475467] text-sm">Sipariş No</p>
          <p className="text-[#B54708] font-semibold text-md">ORD-00001</p>
        </div>
        <div className="flex flex-col px-4 py-3 h-full rounded-lg border border-[#D0D5DD] min-w-[159px] max-w-[159px] w-[159px]">
          <p className="text-[#475467] text-sm">Müşteri Sipariş No</p>
          <p className="text-[#B54708] font-semibold text-md">SP-88.232.01</p>
        </div>
        <div className="flex flex-col px-4 py-3 h-full rounded-lg border border-[#D0D5DD] min-w-[159px] max-w-[159px] w-[159px]">
          <p className="text-[#475467] text-sm">Toplam Fiyat</p>
          <p className="text-[#B54708] font-semibold text-md">£ 120.000,00</p>
        </div>
        <div className="flex flex-col px-4 py-3 h-full rounded-lg border border-[#D0D5DD] min-w-[159px] max-w-[159px] w-[159px]">
          <p className="text-[#475467] text-sm">Oluşturulma Tarihi</p>
          <p className="text-[#B54708] font-semibold text-md">24.04.2024</p>
        </div>
        <div className="flex gap-x-3 px-4 py-3 h-full rounded-lg border border-[#D0D5DD] w-full">
          <div className="flex min-w-[57px] max-w-[57px] w-[57px] min-h-11 max-h-11 h-11 border border-[#0000001A]"></div>
          <div className="flex flex-col">
            <p className="text-[#475467] text-sm">Siparişi Oluşturan Personel</p>
            <p className="text-[#344054] font-semibold text-md">Doğukan Cangüloğlu</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full border border-[#E4E7EC] shadow-xs rounded-xl">
        <div className="flex w-full h-14 px-6 py-4 justify-start items-center gap-x-2">
          <p className="text-[#101828] font-semibold text-md">Ürünler</p>
          <Badgets colorType={"outline-error"} label={"3"} size={"sm"} />
        </div>
        <div className="flex w-full border-y border-[#E4E7EC] bg-[#F9FAFB] min-h-[34px] max-h-[34px] h-[34px]">
          {productsHeadItems.map((item, i) => (
            <div
              key={i}
              className={`flex border-r last:border-0 border-[#E4E7EC] items-center min-w-[${productsTableWidths[i]}] max-w-[${productsTableWidths[i]}] w-[${
                productsTableWidths[i]
              }] ${i === 7 || i === 6 || i === 5 ? "px-4" : "px-6"} ${i === 0 ? "justify-start" : "justify-center"} py-2`}
            >
              <span className="text-[#344054] text-xs font-medium whitespace-nowrap">{item}</span>
            </div>
          ))}
        </div>
        <div className="flex flex-col w-full">
          <div className="flex min-h-20 max-h-20 h-20 w-full">
            <div className="flex w-[30%] border-r gap-x-2 px-6 py-4 h-full items-center justify-start">
              <div className="flex min-w-[64px] max-w-[64px] w-[64px] min-h-12 max-h-12 h-12 border border-[#0000001A]"></div>
              <div className="flex flex-col">
                <p className="text-[#101828] text-md font-semibold pl-1">Müşteri</p>
                <div className="flex px-1 py-[3px] rounded bg-[#FFFAEB]">
                  <p className="text-[#B54708] text-xs font-medium">Stok: 600.01.0216</p>
                </div>
              </div>
            </div>
            <div className="flex w-[8%] border-r items-center justify-center">
              <p className="text-[#344054] font-medium text-sm">19.04.2024</p>
            </div>
            <div className="flex flex-col w-[10%] border-r items-center justify-center">
              <p className="text-[#344054] font-medium text-sm">30.000</p>
              <p className="text-[#667085] text-xs">adet</p>
            </div>
            <div className="flex flex-col w-[15%] border-r items-center justify-center">
              <p className="text-[#344054] font-medium text-sm">30.000</p>
              <p className="text-[#667085] text-xs">adet</p>
            </div>
            <div className="flex flex-col w-[12%] border-r items-center justify-center">
              <p className="text-[#344054] font-medium text-sm">30.000</p>
              <p className="text-[#667085] text-xs">adet</p>
            </div>
            <div className="flex w-[10%] border-r items-center justify-center">
              <p className="text-[#344054] font-medium text-sm">₺ 12,24</p>
            </div>
            <div className="flex w-[10%] border-r items-center justify-center">
              <p className="text-[#344054] font-medium text-sm">₺ 90.242.42,24</p>
            </div>
            <div className="flex min-w-[72px] max-w-[72px] w-[72px] items-center justify-center">
              <Button colorType={"secondary-gray"} size={"sm"} iconLeft={<EditIcon />} />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full border border-[#E4E7EC] shadow-xs rounded-xl">
        <div className="flex w-full h-14 px-6 py-4 justify-start items-center gap-x-2">
          <p className="text-[#101828] font-semibold text-md">Ürünler</p>
          <Badgets colorType={"outline-error"} label={"3"} size={"sm"} />
        </div>
        <div className="flex w-full border-y border-[#E4E7EC] bg-[#F9FAFB] min-h-[34px] max-h-[34px] h-[34px]">
          {LogHeadItems.map((item, i) => (
            <div
              key={i}
              className={`flex px-4 py-2 last:border-0 border-r border-[#E4E7EC] ${
                i === 0 || i === 4
                  ? "min-w-[20%] max-w-[20%] w-[20%]"
                  : i === 1 || i === 2 || i === 3
                  ? "min-w-[16%] max-w-[16%] w-[16%]"
                  : i === 5
                  ? "w-full"
                  : "min-w-[72px] max-w-[72px] w-[72px]"
              }`}
            >
              <span className="text-[#344054] text-xs font-medium whitespace-nowrap">{item}</span>
            </div>
          ))}
        </div>
        <div className="flex flex-col w-full">
          <div className="flex min-h-20 max-h-20 h-20 w-full">
            <div className="flex w-[20%] border-r gap-x-2 px-6 py-4 h-full items-center justify-start"></div>
            <div className="flex flex-col w-[16%] border-r items-center justify-center">
              <p className="text-[#344054] font-medium text-sm">30.000</p>
              <p className="text-[#667085] text-xs">adet</p>
            </div>
            <div className="flex flex-col w-[16%] border-r items-center justify-center">
              <p className="text-[#344054] font-medium text-sm">30.000</p>
              <p className="text-[#667085] text-xs">adet</p>
            </div>
            <div className="flex flex-col w-[16%] border-r items-center justify-center">
              <p className="text-[#344054] font-medium text-sm">30.000</p>
              <p className="text-[#667085] text-xs">adet</p>
            </div>
            <div className="flex w-[20%] border-r items-center justify-center">
              <p className="text-[#344054] font-medium text-sm">₺ 12,24</p>
            </div>
            <div className="flex w-full border-r items-center justify-center">
              <p className="text-[#344054] font-medium text-sm">₺ 90.242.42,24</p>
            </div>
            <div className="flex min-w-[72px] max-w-[72px] w-[72px] items-center justify-center">
              <Button colorType={"secondary-gray"} size={"sm"} iconLeft={<EditIcon />} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderLogDetail;

const EditIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M2.39735 15.0965C2.43564 14.752 2.45478 14.5797 2.50691 14.4186C2.55316 14.2758 2.61851 14.1398 2.70118 14.0144C2.79436 13.8731 2.91694 13.7506 3.16209 13.5054L14.1673 2.50017C15.0878 1.5797 16.5802 1.5797 17.5007 2.50017C18.4211 3.42065 18.4211 4.91303 17.5007 5.83351L6.49542 16.8387C6.25027 17.0839 6.1277 17.2065 5.98639 17.2996C5.86102 17.3823 5.72506 17.4477 5.58219 17.4939C5.42115 17.546 5.24887 17.5652 4.90429 17.6035L2.08398 17.9168L2.39735 15.0965Z"
        stroke="#344054"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

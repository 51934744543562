import React from "react";
import { Button, Input } from "../../../../components";
import { PlusIcon } from "../../../../assets/icons/stepsIcons";
import OperationEventCard from "../../../../components/new-cards/junction-card/OperationEventCard";
import AddManuelJunctionFormEventCard from "../components/AddManuelJunctionFormEventCard";
import { Controller } from "react-hook-form";
import Selector from "../../../../components/inputs/Selector";
import { useTranslation } from "react-i18next";

const MainForm = ({
  control,
  errors,
  setLeftTab,
  selectedProduct,
  setWorkType,
  workType,
  selectedUser,
  selectedOperation,
  equipmentData,
  startDate,
  endDate,
  workPiece,
  setWorkPiece,
  leftTab,
  selectedDates,
}) => {
  const { t } = useTranslation();
  const junctionTypes = [
    { label: t("product:work"), value: "work" },
    { label: t("product:setup"), value: "setup" },
  ];
  return (
    <div className="flex flex-col w-[40%] px-6 border rounded-lg bg-[#F9FAFB] justify-center items-center gap-y-8">
      <div className="flex gap-x-6 w-full">
        <Selector
          value={workType}
          items={junctionTypes}
          theme={"machine"}
          label={t("product:workType")}
          onChange={(value) => {
            setWorkType(value);
          }}
          validate={errors?.type ? "error" : ""}
          errorMessage={errors?.type ? errors?.type?.message : ""}
        />
        <Input
          value={workPiece}
          onChange={(event) => setWorkPiece(event.target.value)}
          theme={"machine"}
          label={t("product:numberOfProducts")}
          errorMessage={errors?.madeWorkCount?.message}
          validate={errors?.madeWorkCount ? "error" : ""}
        />
      </div>
      <div className="flex w-full">
        <AddManuelJunctionFormEventCard
          selectedProduct={selectedProduct?.product}
          workType={workType}
          selectedUser={selectedUser}
          selectedOperation={selectedOperation}
          equipmentData={equipmentData}
          startDate={startDate}
          endDate={endDate}
          workPiece={workPiece}
          addUserOnClick={() => setLeftTab("user")}
          selectedDates={selectedDates}
        />
      </div>
      <div className="flex w-full gap-x-5 min-h-[38px] max-h-[38px] h-[38px]">
        <div
          onClick={() => setLeftTab("product")}
          className={`flex cursor-pointer w-full border px-3 py-2 rounded-lg  border-[#D0D5DD] min-h-[36px] max-h-[36px] h-9 justify-center items-center ${
            leftTab === "product" ? "shadow-ring-gray" : "bg-[#FFFFFF] shadow-xs"
          }`}
        >
          <p className="text-[#344054] font-semibold text-sm">{t("product:selectProduct")}</p>
        </div>
        <div
          onClick={() => setLeftTab("user")}
          className={`flex cursor-pointer w-full gap-x-1 border px-3 py-2 rounded-lg  border-[#D0D5DD] min-h-[36px] max-h-[36px] h-9 justify-center items-center ${
            leftTab === "user" ? "shadow-ring-gray" : "bg-[#FFFFFF] shadow-xs"
          }`}
        >
          <PlusIcon />
          <p className="text-[#344054] font-semibold text-sm">{t("product:selectPersonnel")}</p>
        </div>
        <div
          className={`flex gap-x-1 shadow-xs w-full border px-3 py-2 rounded-lg  border-[#E4E7EC] min-h-[36px] max-h-[36px] h-9 justify-center items-center bg-[#FFF] text-[#98A2B3] `}
        >
          <PlusIcon />
          <p className="font-semibold text-sm">{t("product:selectEquipment")}</p>
        </div>
      </div>
    </div>
  );
};

export default MainForm;

import React, { useEffect, useState } from "react";
import { Button, CustomModal } from "../../components";
import { ArrowLeftIcon } from "./quality/assets/qualitySvg";
import { useNavigate, useParams } from "react-router-dom";
import generateFileUrl from "../../utils/generateFileUrl";
import { EditIcon } from "../../assets/icons/departmentIcon";
import SearchInput from "../../components/inputs/SearchInput";
import { FilterIcon, SortIcon } from "../../assets/icons/productIcons";
import Badgets from "../../components/buttons/Badgets";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { rawMaterialService } from "../../services/raw-material.service";
import NoAvatar from "../../components/avatar/NoAvatar";
import { useTranslation } from "react-i18next";
import StockDetailProductCard from "./cards/StockDetailProductCard";
import OrderWipRawMaterialCard from "./cards/OrderWipRawMaterialCard";
import StockDetailSupplyCard from "./cards/StockDetailSupplyCard";
import OrderRawMaterialCard from "./cards/OrderRawMaterialCard";
import StockProductCard from "./cards/StockProductCard";
import StockDetailOperationCard from "./cards/StockDetailOperationCard";
import StockDetailRecipeProductCard from "./cards/StockDetailRecipeProductCard";
import StockDetailRecipeRawMaterialCard from "./cards/StockDetailRecipeRawMaterialCard";
import { productService } from "../../services/product.service";
import { legacyFormatSeconds } from "../../utils/secondToHoursAndMinute";
import StockDetailRecipeOperationModal from "./modals/StockDetailRecipeOperationModal";
import { DateIcon } from "../../assets/icons/commonIcons";

const StockDetailOrderRecipe = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { stockId } = useParams();
  const [operationModal, setOperationModal] = useState(false);
  const [productData, setProductData] = useState(null);
  const [junctionData, setJunctionData] = useState(null);
  const [operationData, setOperationData] = useState(null);
  const [partialData, setpartialData] = useState(null);
  const [loading, setLoading] = useState(false);

  const { data, isLoading, refetch, isFetching } = useQuery({
    queryKey: ["orderRecipeDetail", stockId],
    queryFn: async () => (stockId ? await productService.orderRecipeList(stockId) : ""),
    enabled: !!stockId,
    retry: 0,
  });

  const getStockDetailRecipe = async (productId, partialId, partialDetailsId) => {
    setLoading(true);
    try {
      const response = await productService.orderRecipeDeteilList(productId, partialId, partialDetailsId);
      setProductData(response?.product);
      setJunctionData(response?.junctionList);
      setOperationData(response?.operation);
      setpartialData(response?.partial);
    } catch (error) {
      console.error("error", error);
    }
    setLoading(false);
  };

  const handleOpenModal = (partialId, operationId) => {
    setOperationModal(true);
    getStockDetailRecipe(data?.product?.id, partialId, operationId);
  };

  // const formatLogMessage = (log) => {
  //   const boldClass = "font-semibold text-[#101828] text-sm";
  //   const reason = `${log?.reason}-${log?.type}`;
  //   switch (reason) {
  //     case "reserv-outcome":
  //       if (log?.purchaseTransaction?.order) {
  //         return (
  //           <>
  //             <span className={boldClass}>{log?.purchaseTransaction?.order?.orderNo || "--"}</span> nolu sipariş için{" "}
  //             <span className={boldClass}>
  //               {log?.purchaseTransaction?.orderedUnit || "--"} {data?.unitType || "ad"}
  //             </span>{" "}
  //             hammadde rezerv edildi.
  //           </>
  //         );
  //       } else {
  //         return (
  //           <>
  //             <span className={boldClass}>Order Yok</span>
  //           </>
  //         );
  //       }
  //     case "purchase-info":
  //       return (
  //         <>
  //           <span className={boldClass}>{log?.purchaseTransaction?.order?.orderNo || "--"}</span> nolu sipariş için{" "}
  //           <span className={boldClass}>
  //             {log?.purchaseTransaction?.orderedUnit || "--"} {data?.unitType || "ad"}
  //           </span>{" "}
  //           hammadde satın alındı.
  //         </>
  //       );
  //     case "purchase-income":
  //       const supplierNames = log?.purchaseTransaction?.junctions
  //         ? log?.purchaseTransaction?.junctions.map((junction) => junction?.supplier?.name).filter((name) => name)
  //         : [];

  //       const uniqueSuppliers = log?.purchaseTransaction?.junctions
  //         ? [...new Set(log?.purchaseTransaction?.junctions.map((junction) => junction?.supplier?.id))]
  //         : [];

  //       const supplierText = supplierNames?.length > 0 ? (uniqueSuppliers?.length === 1 ? supplierNames[0] : supplierNames?.join(", ")) : "tedarikçi";

  //       return (
  //         <>
  //           <span className={boldClass}>Ek Stok Artırımı</span> için{" "}
  //           <span className={boldClass}>
  //             {log?.purchaseTransaction?.orderedUnit || "--"} {data?.unitType || "ad"}
  //           </span>{" "}
  //           hammadde <span className={boldClass}>{supplierText}</span> tarafından tedarik edildi ve{" "}
  //           <span className={boldClass}>{log?.user?.name + " " + log?.user?.lastName}</span> tarafından satın alındı.
  //         </>
  //       );

  //     case "stock-income":
  //       return (
  //         <>
  //           <span className={boldClass}>{log?.purchaseTransaction?.order?.orderNo || "--"}</span> nolu sipariş için{" "}
  //           <span className={boldClass}>
  //             {log?.purchaseTransaction?.orderedUnit || "--"} {data?.unitType || "ad"}
  //           </span>{" "}
  //           hammadde stoğa girdi.
  //         </>
  //       );
  //     case "stock-outcome":
  //       return (
  //         <>
  //           <span className={boldClass}>{log?.purchaseTransaction?.order?.orderNo || "--"}</span> nolu sipariş için{" "}
  //           <span className={boldClass}>
  //             {log?.purchaseTransaction?.orderedUnit || "--"} {data?.unitType || "ad"}
  //           </span>{" "}
  //           hammadde stoktan çıktı.
  //         </>
  //       );
  //     case "supply_request-info":
  //       if (log?.purchaseTransaction?.order) {
  //         return (
  //           <>
  //             <span className={boldClass}>{log?.purchaseTransaction?.order?.orderNo || "--"}</span> nolu sipariş için{" "}
  //             <span className={boldClass}>
  //               {log?.purchaseTransaction?.orderedUnit || "--"} {data?.unitType || "ad"}
  //             </span>{" "}
  //             tedarik talebi <span className={boldClass}>{log?.user?.name + " " + log?.user?.lastName || "--"}</span> tarafından oluşturuldu.
  //           </>
  //         );
  //       } else {
  //         return (
  //           <>
  //             <span className={boldClass}>Ek Stok Artırımı</span> için{" "}
  //             <span className={boldClass}>
  //               {log?.purchaseTransaction?.orderedUnit || "--"} {data?.unitType || "ad"}
  //             </span>{" "}
  //             tedarik talebi <span className={boldClass}>{log?.user?.name + " " + log?.user?.lastName || "--"}</span> tarafından oluşturuldu.
  //           </>
  //         );
  //       }
  //     case "manual-income":
  //       return (
  //         <>
  //           Hammadde stoğu <span className={boldClass}>{log?.user?.name + " " + log?.user?.lastName}</span> tarafından{" "}
  //           <span className={boldClass}>
  //             {log?.purchaseTransaction?.orderedUnit || "--"} {data?.unitType || "ad"}
  //           </span>{" "}
  //           manuel olarak artırıldı.
  //         </>
  //       );
  //     case "manual-outcome":
  //       return (
  //         <>
  //           Hammadde stoğu <span className={boldClass}>{log?.user?.name + log?.user?.lastName}</span> tarafından{" "}
  //           <span className={boldClass}>
  //             {log?.purchaseTransaction?.orderedUnit || "--"} {data?.unitType || "ad"}
  //           </span>{" "}
  //           manuel olarak azaltıldı.
  //         </>
  //       );
  //     default:
  //       return `Bilinmeyen işlem türü: ${reason}`;
  //   }
  // };

  const operationList = data?.product?.internalOperations?.concat(data?.product?.externalOperations);

  const tabOptions = [
    { value: "general-info", label: t("product:generalAndInformation") },
    { value: "orders-recipe", label: t("product:pastWorkOrdersAndRecipe") },
  ];

  const workOrderList = data?.workOrderList || []; // workOrderList dizisi data içinde bulunuyor

  // Tüm operasyonları işlemek için kullanilir
  const operationCount = workOrderList?.reduce((max, item) => {
    return item?.operations ? Math.max(max, item?.operations?.length) : max;
  }, 0);

  const tableHeadItems = [
    { name: t("product:productss") },
    { name: t("product:suplierss") },
    ...Array.from({ length: operationCount }, (_, i) => ({ name: `${i + 1}.${t("loss:operation")}` })),
  ];

  const tableHeadItemsSecond = [
    { name: t("product:workOrderss") },
    { name: t("product:appliedSupply") },

    ...Array.from({ length: operationCount }, () => ({
      part: 1,
      datas: [
        { name: t("product:eventNew"), part: 2 },
        { name: t("product:cycleTimess"), part: 2 },
        { name: t("product:opCost"), part: 2 },
      ],
    })),
  ];

  const workOrderCounts = {};

  return (
    <>
      {isLoading || isFetching ? (
        <div className="bg-gray-200 animate-pulse rounded-lg w-full h-full" />
      ) : (
        <div className="flex flex-col w-full h-full gap-y-6 overflow-y-auto overflow-x-hidden scrollbar-hide">
          {/* header */}
          <div className="flex w-full min-h-[56px] max-h-[56px] h-[56px] justify-between items-center border-b border-[#E4E7EC] sticky top-0 bg-[#fff] z-[50]">
            <div className="flex gap-x-2 items-center">
              <Button size={"md"} iconLeft={<ArrowLeftIcon />} colorType={"tertiary-gray"} onClick={() => navigate(-1)} type={"button"} />
              <div className="flex gap-x-3 items-center">
                {data?.product?.image ? (
                  <img
                    className="h-9 w-12 min-h-[36px] max-h-[36px] min-w-[48px] max-w-[48px] rounded border border-[#0000001A]"
                    src={generateFileUrl(data?.product?.image)}
                    width={48}
                    height={36}
                    alt={data?.name?.charAt(0).toUpperCase()}
                  />
                ) : (
                  <NoAvatar name={data?.product?.name || "--"} rounded={4} theme={"product"} minWidth="48px" minHeight="36px" />
                )}
                <div className="flex flex-row items-center gap-x-3">
                  <p className="text-[#101828] font-semibold text-2xl">{data?.product?.name || "--"}</p>
                  <p className="text-xl mt-1 text-secondary-600">
                    {t("product:stock")}: {data?.product?.stockCode || "--"}
                  </p>
                </div>
              </div>
            </div>
            <span>
              <Button
                colorType={"secondary-product"}
                iconLeft={<EditIcon />}
                label={t("buttons:edit")}
                size={"md"}
                onClick={() => navigate(`/app/product/mrp/stock/edit/${stockId}`)}
              />
            </span>
          </div>
          {/* detail header */}
          <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-[#F9FAFB] border border-[#EAECF0] p-[6px] rounded-xl flex items-center justify-center">
            {tabOptions?.map((option, index) => (
              <div
                className={`w-1/2 h-full rounded-md px-3 py-2  flex items-center justify-center cursor-pointer ${
                  "orders-recipe" == option?.value ? "bg-white shadow-sm" : ""
                }`}
                onClick={() => {
                  if (option?.value != "orders-recipe") navigate(`/app/product/mrp/stock/detail/general-info/${stockId}`);
                }}
              >
                <p className={`text-md font-semibold ${"orders-recipe" == option?.value ? "text-[#344054]" : "text-[#667085]"}`}>{option?.label}</p>
              </div>
            ))}
          </div>
          <div className="flex flex-col w-full border shadow-lg border-[#E4E7EC] rounded-xl min-h-[87vh]">
            <div className="flex w-full min-h-[64px] max-h-[64px] h-[64px] justify-between items-center px-6 py-3">
              <p className="text-xl font-semibold text-secondary-900">{t("product:pastWorkOrders")}</p>
              <div className="flex gap-x-3 items-center">
                <SearchInput />
                <span>
                  <Button colorType={"secondary-gray"} iconLeft={<SortIcon />} size={"md"} label={"Sort"} />
                </span>
                <span>
                  <Button colorType={"secondary-gray"} iconLeft={<FilterIcon />} size={"md"} label={"Filter"} />
                </span>
              </div>
            </div>
            <div className="flex flex-col overflow-x-auto min-h-[181px] w-full border-b overflow-y-hidden">
              <div className="flex max-h-[36px] min-h-[36px] border-t w-full bg-[#F9FAFB]">
                {tableHeadItems?.map((item, i) => (
                  <div
                    key={i}
                    className={`flex border-b px-3 py-1 border-r border-[#E4E7EC] items-center last:border-r ${
                      i === 0 || i === 1 ? "min-w-[344px] max-w-[344px] w-[344px] justify-center" : "min-w-[344px] max-w-[344px] w-[344px] justify-center"
                    }`}
                  >
                    <span className="text-center text-xs font-medium cursor-pointer truncate">{item?.name}</span>
                  </div>
                ))}
              </div>

              <div className="flex flex-row items-start">
                <div className="w-[344px] min-w-[344px] border-r flex justify-center">
                  <div className="p-4 w-full">
                    <StockDetailRecipeProductCard item={data?.product} />
                  </div>
                </div>

                <div className="w-[344px] min-w-[344px] border-r flex justify-center">
                  <div className="p-4 w-full">
                    <StockDetailRecipeRawMaterialCard item={data?.product?.rawMaterial} />
                  </div>
                </div>

                {operationList?.map((item, index) => (
                  <div key={index} className="w-[344px] min-w-[344px] border-r flex justify-center">
                    <div className="p-4 w-full">
                      <StockDetailOperationCard status={false} item={item} />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex flex-col w-full h-full overflow-y-hidden  overflow-x-auto">
              <div className="flex max-h-[36px] min-h-[36px] max-w-full min-w-full bg-[#F9FAFB]">
                {tableHeadItemsSecond?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      scope="col"
                      className={`${
                        item?.part ? "px-0 py-0" : "px-6 py-2"
                      }  min-w-[344px] max-w-[344px] items-center text-center bg-secondary-50 border-gray-200 border-b border-r last:border-r-0`}
                    >
                      {item?.part ? (
                        <div className="flex h-full items-center ">
                          {item?.datas?.map((da, i) => {
                            return (
                              <div
                                className={`h-full ${i === 0 ? "w-[72px] min-w-[72px]" : "w-1/2"} ${i === item?.datas?.length - 1 ? "border-r" : "border-r"}  `}
                              >
                                <span
                                  className={`text-center mt-2 rounded flex text-xs font-medium justify-center md:line-clamp-2 items-center whitespace-nowrap`}
                                >
                                  {da?.name}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <span className={`text-center rounded flex text-xs font-medium justify-center md:line-clamp-2 items-center whitespace-nowrap`}>
                          {item?.name}
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-col w-full h-full overflow-y-auto scrollbar-hide">
                <div className="w-full bg-white flex-1 flex flex-col ">
                  {data?.workOrderList.map((item, index) => {
                    const workOrderNo = item?.partial?.workOrderNo || "--";
                    if (workOrderCounts[workOrderNo]) {
                      workOrderCounts[workOrderNo] += 1;
                    } else {
                      workOrderCounts[workOrderNo] = 1;
                    }
                    const workNumber = workOrderCounts[workOrderNo];

                    return (
                      <div className={`flex w-full ${index % 2 === 0 ? "bg-white" : "bg-secondary-50"} items-center border-b h-[116px]  min-h-[116px]`}>
                        <div className="flex w-[344px] min-w-[344px] h-full">
                          <div className="w-full border-r px-4 py-2 flex flex-col items-start">
                            <div className="flex flex-row justify-between w-full items-center">
                              <p className="text-lg font-semibold text-[#B54708]">{`${workOrderNo} / ${workNumber}`}</p>
                              <div className="flex items-center gap-x-1 border border-[#E4E7EC] px-3 py-1 rounded-md">
                                <QuantityAcceptedIcon />
                                <p className="text-sm font-medium text-[#067647]">{item?.quantity || "--"}</p>
                                <p className="text-sm text-secondary-700 font-medium">/ {"1999"}</p>
                                <p className="text-xs mt-0.5 text-[#475467]">{t("product:pcs")}</p>
                              </div>
                            </div>
                            <div className="flex items-center cursor-pointer mt-auto w-full justify-between pt-1">
                              <div className="flex items-center gap-x-2">
                                <OrderNoIcon />
                                <p className="text-xs text-[#475467]">{t("product:orderNo")}</p>
                              </div>
                              <div className="flex items-center gap-x-1">
                                <p className="text-sm font-medium text-secondary-700">{item?.partial?.orderDetails?.order?.orderNo || "--"}</p>
                                {/* <LinkIcon /> */}
                              </div>
                            </div>
                            <div className="flex items-center w-full justify-between pt-1">
                              <div className="flex items-center gap-x-2">
                                <DateIcon />
                                <p className="text-xs text-[#475467]">{t("report:creationDate")}</p>
                              </div>
                              <div className="flex items-center gap-x-1">
                                <p className="text-sm font-medium text-secondary-700">{moment(item?.createdDate).format("DD.MM.YYYY") || "--"}</p>
                              </div>
                            </div>
                            <div className="flex items-center w-full justify-between pt-1 ">
                              <div className="flex items-center gap-x-2">
                                <DeliveryIcon />
                                <p className="text-xs text-[#475467]">{t("table:deliveryDate")}</p>
                              </div>
                              <div className="flex items-center gap-x-1">
                                <p className="text-sm font-medium text-secondary-700">
                                  {moment(item?.partial?.orderDetails?.deliveryDate).format("DD.MM.YYYY") || "--"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* tedarik kismi */}
                        <div className="flex w-[344px] min-w-[344px]  h-full">
                          <div className="w-full border-r  flex flex-row items-center">
                            <div className="flex flex-col items-center justify-center px-6 py-3 h-full w-1/2 border-r">
                              <p className="text-lg font-medium text-secondary-700">{item?.usedRawMaterial || "0"}</p>
                              <p className="text-sm text-secondary-600">{data?.product?.rawMaterial?.unitType || "--"}</p>
                            </div>
                            <div className="flex flex-col items-center justify-center px-6 py-3 h-full w-1/2">
                              <p className="text-lg font-medium text-secondary-700">{item?.usedRawMaterialPrice || "0"}</p>
                              <p className="text-sm text-secondary-600">{t("product:avgTotalCost")}</p>
                            </div>
                          </div>
                        </div>

                        {item?.operations
                          .sort((a, b) => {
                            return a?.step - b?.step;
                          })
                          ?.map((e) => {
                            return (
                              <div className="flex w-[344px] min-w-[344px]  h-full">
                                <div
                                  onClick={() => {
                                    handleOpenModal(item?.id, e?.id);
                                  }}
                                  className="w-full border-r hover:bg-secondary-100 cursor-pointer flex flex-row items-center"
                                >
                                  <div className="flex flex-col items-center justify-center px-4 py-3 h-full w-[72px] min-w-[72px] border-r">
                                    <p className="text-md font-medium text-secondary-700">{e?.events}</p>
                                    <p className="text-xs text-secondary-600">{t("product:eventNewss")}</p>
                                  </div>
                                  <div className="flex flex-col items-center justify-center h-full w-1/2 border-r">
                                    <div className="flex flex-row items-center px-2 py-3 justify-between w-full border-b">
                                      <p className="text-xs text-[#667085]">{t("product:target")}</p>
                                      <p className="text-base text-[#475467]">{legacyFormatSeconds(e?.targetTime) || "--"}</p>
                                    </div>
                                    <div className="flex flex-row items-center px-2 py-3 justify-between w-full">
                                      <p className="text-xs text-[#667085]">{t("product:applieds")}</p>
                                      <p className={`text-base ${e?.targetTime < e?.cycleTime ? "text-[#F04438]" : "text-[#079455] "}`}>
                                        {/* {Number(e?.cycleTime).toFixed(2)} */}
                                        {legacyFormatSeconds(e?.cycleTime) || "--"}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="flex flex-col items-center justify-center h-full w-1/2">
                                    <div className="flex flex-col items-center px-2 py-2 justify-center w-full border-b">
                                      <p className="text-base text-[#475467]">{e?.defaultPrice || "--"}</p>
                                      <p className="text-xs text-[#344054]">
                                        {t("product:tot")}:{e?.defaultPrice * item?.quantity || "--"}
                                      </p>
                                    </div>
                                    <div className="flex flex-col items-center px-2 py-2 justify-center w-full">
                                      <p className="text-base text-[#475467]">{Number(e?.appliedPrice).toFixed(2)}</p>
                                      <p className="text-xs text-[#344054]">
                                        {t("product:tot")}:{Number(e?.appliedPrice * item?.quantity).toFixed(2)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <CustomModal
        isOpen={operationModal}
        setIsOpen={setOperationModal}
        modalTitle={t("product:operationDetail")}
        width={776}
        padding={false}
        children={
          <StockDetailRecipeOperationModal
            loading={loading}
            partialData={partialData}
            productData={productData}
            operationData={operationData}
            junctionData={junctionData}
          />
        }
      />
    </>
  );
};

export default StockDetailOrderRecipe;

const QuantityIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
      <g clip-path="url(#clip0_18549_86040)">
        <path
          d="M4.7915 1H2.9165C2.22615 1 1.6665 1.55964 1.6665 2.25V9.75C1.6665 10.4404 2.22615 11 2.9165 11H10.4165C11.1069 11 11.6665 10.4404 11.6665 9.75V2.25C11.6665 1.55964 11.1069 1 10.4165 1H8.5415M4.7915 1V3.89286C4.7915 3.94139 4.84322 3.97242 4.88604 3.94958L6.6665 3L8.44697 3.94958C8.48979 3.97242 8.5415 3.94139 8.5415 3.89286V1M4.7915 1H8.5415"
          stroke="#475467"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_18549_86040">
          <rect width="12" height="12" fill="white" transform="translate(0.666504)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const QuantityDeclinedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
      <g clip-path="url(#clip0_18549_86054)">
        <path
          d="M4.95801 1H3.08301C2.39265 1 1.83301 1.55964 1.83301 2.25V9.75C1.83301 10.4404 2.39265 11 3.08301 11H5.7219M4.95801 1V3.89286C4.95801 3.94139 5.00972 3.97242 5.05255 3.94958L6.83301 3L8.61347 3.94958C8.65629 3.97242 8.70801 3.94139 8.70801 3.89286V1M4.95801 1H8.70801M8.70801 1H10.583C11.2734 1 11.833 1.55964 11.833 2.25V5.40724M8.19645 7.34116L9.76991 8.93436M11.3506 10.5013L9.76991 8.93436M9.76991 8.93436L11.3506 7.34116M9.76991 8.93436L8.19645 10.5013"
          stroke="#F04438"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_18549_86054">
          <rect width="12" height="12" fill="white" transform="translate(0.833008)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const QuantityAcceptedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clip-path="url(#clip0_19634_72135)">
        <path
          d="M5.4987 1.33325H2.9987C2.07822 1.33325 1.33203 2.07944 1.33203 2.99992V12.9999C1.33203 13.9204 2.07822 14.6666 2.9987 14.6666H6.51722M5.4987 1.33325V5.19039C5.4987 5.2551 5.56765 5.29648 5.62475 5.26602L7.9987 3.99992L10.3726 5.26602C10.4297 5.29648 10.4987 5.2551 10.4987 5.19039V1.33325M5.4987 1.33325H10.4987M10.4987 1.33325H12.9987C13.9192 1.33325 14.6654 2.07944 14.6654 2.99992V7.20957M8.57914 12.1224L10.4701 14.0025L14.6679 9.8101"
          stroke="#17B26A"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_19634_72135">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const OrderNoIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M13.3332 6.99992V4.53325C13.3332 3.41315 13.3332 2.85309 13.1152 2.42527C12.9234 2.04895 12.6175 1.74299 12.2412 1.55124C11.8133 1.33325 11.2533 1.33325 10.1332 1.33325H5.8665C4.7464 1.33325 4.18635 1.33325 3.75852 1.55124C3.3822 1.74299 3.07624 2.04895 2.88449 2.42527C2.6665 2.85309 2.6665 3.41315 2.6665 4.53325V11.4666C2.6665 12.5867 2.6665 13.1467 2.88449 13.5746C3.07624 13.9509 3.3822 14.2569 3.75852 14.4486C4.18635 14.6666 4.7464 14.6666 5.8665 14.6666M7.99984 5.99992H5.33317M9.33317 3.99992H5.33317M6.6665 7.99992H5.33317M7.8695 10.1056L10.3362 8.72723C10.431 8.67429 10.4784 8.64782 10.5286 8.63741C10.573 8.62821 10.6188 8.62817 10.6633 8.6373C10.7135 8.64762 10.7609 8.67401 10.8558 8.72679L13.3332 10.1056M7.8695 10.1056L10.5959 11.6248M7.8695 10.1056V12.8263C7.8695 12.94 7.8695 12.9969 7.88621 13.0477C7.901 13.0926 7.92517 13.1339 7.95713 13.1687C7.99325 13.2081 8.04287 13.2359 8.14211 13.2915L10.5959 14.6666M13.3332 10.1056L10.5959 11.6248M13.3332 10.1056V12.8257C13.3332 12.9396 13.3332 12.9966 13.3164 13.0475C13.3016 13.0924 13.2773 13.1338 13.2453 13.1686C13.209 13.208 13.1593 13.2358 13.0598 13.2914L10.5959 14.6666M10.5959 11.6248V14.6666"
        stroke="#475467"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const LinkIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M14 6L14 2M14 2H10M14 2L8.66667 7.33333M6.66667 3.33333H5.2C4.0799 3.33333 3.51984 3.33333 3.09202 3.55132C2.71569 3.74307 2.40973 4.04903 2.21799 4.42535C2 4.85318 2 5.41323 2 6.53333V10.8C2 11.9201 2 12.4802 2.21799 12.908C2.40973 13.2843 2.71569 13.5903 3.09202 13.782C3.51984 14 4.0799 14 5.2 14H9.46667C10.5868 14 11.1468 14 11.5746 13.782C11.951 13.5903 12.2569 13.2843 12.4487 12.908C12.6667 12.4802 12.6667 11.9201 12.6667 10.8V9.33333"
        stroke="#667085"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
const DeliveryIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M14 6.66659H2M14 8.33325V5.86659C14 4.74648 14 4.18643 13.782 3.7586C13.5903 3.38228 13.2843 3.07632 12.908 2.88457C12.4802 2.66659 11.9201 2.66659 10.8 2.66659H5.2C4.0799 2.66659 3.51984 2.66659 3.09202 2.88457C2.71569 3.07632 2.40973 3.38228 2.21799 3.7586C2 4.18643 2 4.74648 2 5.86659V11.4666C2 12.5867 2 13.1467 2.21799 13.5746C2.40973 13.9509 2.71569 14.2569 3.09202 14.4486C3.51984 14.6666 4.0799 14.6666 5.2 14.6666H8M10.6667 1.33325V3.99992M5.33333 1.33325V3.99992M9.66667 12.6666L11 13.9999L14 10.9999"
        stroke="#475467"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

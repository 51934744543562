import React from "react";
import { EventsIcon, NewAcceptedIcon, NewDotsIcon, NewRejectedIcon, PlannedIcon, ReadyIcon } from "../../../components/new-cards/card-icons";
import { useNavigate } from "react-router-dom";
import { legacyFormatSeconds } from "../../../utils/secondToHoursAndMinute";
import { useTranslation } from "react-i18next";
import { QuantityAcceptedIcon } from "../../../assets/icons/productIcons";

const StockDetailOperationCard = ({ item, status, occurrences, orderData, showOperationDetail = true }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const statusColor = () => {
    switch (item?.status) {
      case "finished":
        return "#ECFDF3";
      case "started":
        return "#FFFAEB";
      case "rejected":
        return "#D92D20";
      default:
        return "#F2F4F7";
    }
  };

  const operationTypes = (type) => {
    switch (type) {
      case "conversion_operation":
        return "DÖN";
      case "supporter_operation":
        return "YAR";
      case "assembly_operation":
        return "MON";
      case "decomposition_operation":
        return "AYR";
    }
  };

  return (
    <>
      <div
        // onClick={() => {
        //   showOperationDetail && navigate(`/app/product/mrp/work-in-process/detail/${orderData?.id}+${item?.step}`);
        // }}
        className={`flex w-[312px] h-[112px] flex-col border items-start cursor-pointer rounded-lg border-secondary-300 bg-white relative group `}
      >
      {status && <div style={{ backgroundColor: statusColor() }} className="flex w-full min-h-[4px] max-h-[4px] h-1 rounded-t-[8px]"></div>}
        <div className="flex flex-col w-[92.5%]">
          <div className="flex flex-row items-center w-[100%]  justify-between pr-1 py-2 pl-3">
            <div className="flex flex-row items-center  h-5 ">
              <div className="px-1  border h-full w-fit rounded bg-[#F2F4F7]">
                {item?.type === "external" ? (
                  <p className="text-xs font-semibold text-secondary-700">{"HİZ"}</p>
                ) : (
                  <p className="text-xs font-semibold text-secondary-700">
                    {item?.processType || item?.process?.type ? operationTypes(item?.processType || item?.process?.type) : "--"}
                  </p>
                )}
              </div>
              <div className="px-1 bg-white max-w-[230px] rounded-r-[8px]">
                <p className="font-semibold text-sm ml-1 text-secondary-700 truncate">
                  {occurrences > 1 ? `${item?.process?.name} ${occurrences}` : item?.process?.name || "Product Name"}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center w-full h-full">
            <div className="flex flex-col pr-2 pl-3 w-[37%] min-w-[37%] h-full">
              <div className="flex flex-row items-center w-full justify-between  border-b pb-0.5">
                <p className="font-normal text-secondary-600 ml-1 text-xxs">{t("product:inStocks")}</p>
                <p className="text-xs font-medium max-w-[50px]  truncate text-secondary-700">{item?.activePiece || "0"}</p>
              </div>
              <div className="flex flex-row items-center w-full justify-between border-b pb-0.5  pt-[4px] ">
                <p className="font-normal ml-1 text-xxs text-[#D92D20]">{t("product:reject")}</p>
                <p className="text-xs font-medium max-w-[40px] truncate text-[#D92D20]">{item?.rejectedPiece || 0}</p>
              </div>
              <div className="flex flex-row items-center w-full pb-0.5 pt-[4px] justify-between">
                <p className="font-normal text-secondary-600 ml-1 text-xxs">{t("product:cost")}</p>
                <p className="text-xs font-medium text-secondary-700">{item?.currentPurchasePrice || "0"} {item?.currency} </p>
              </div>
            </div>
            <div className="flex flex-row items-center w-[63%] min-w-[63%] rounded-tl-[4px] border border-r-0 border-b-0 border-[#E4E7EC]">
              <div className="flex flex-col items-start border-r w-1/2 border-r-[#E4E7EC]">
                <div className="px-1 py-[3px] flex items-center rounded-tl-[4px] justify-center border-b w-full bg-[#F9FAFB]">
                  <p className="text-xs font-medium text-secondary-700">{t("product:cycleTime")}</p>
                </div>
                <div className="flex flex-col px-[6px] w-full">
                  <div className="flex px-1.5 py-[3px] flex-row items-center w-full justify-between border-b border-[#E4E7EC]">
                    <p className="text-[10px] text-secondary-500 font-normal">{t("product:target")}</p>
                    <p className="font-medium text-secondary-600 text-xs">{legacyFormatSeconds(item?.estimatedTime) || "--"}</p>
                  </div>
                  <div className="flex px-1.5 py-[1.5px] flex-row items-center w-full justify-between">
                    <p className="text-[10px] text-secondary-500 font-normal">{t("product:applieds")}</p>
                    <p
                      className={`font-medium ${item?.estimatedTime < item?.avgActualTime ? "text-[#F04438]" : "text-[#079455] "
                        } text-xs`}
                    >
                      {legacyFormatSeconds(item?.avgActualTime) || "--"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center  w-1/2">
                <div className="px-1 py-[3px] flex items-center rounded-tl-[4px] justify-center border-b w-full bg-[#F9FAFB]">
                  <p className="text-xs font-medium text-secondary-700">{t("product:setupTime")}</p>
                </div>
                <div className="flex flex-col px-[6px] w-full">
                  <div className="flex px-1.5 py-[3px] flex-row items-center  gap-x-0.5 border-b border-[#E4E7EC]">
                    <p className="text-[10px] text-secondary-500 font-normal">{t("product:target")}</p>
                    <p className="font-medium text-secondary-600 ml-auto text-xs">{legacyFormatSeconds(item?.estimatedSettingsTime) || "--"}</p>
                  </div>
                  <div className="flex px-1.5 py-[1.5px] flex-row items-center gap-x-0.5">
                    <p className="text-[10px] text-secondary-500 font-normal">{t("product:applieds")}</p>
                    <p
                      className={`font-medium ml-auto ${item?.estimatedSettingsTime < item?.avgActualTime ? "text-[#F04438]" : "text-[#079455] "
                        } text-xs`}
                    >
                      {legacyFormatSeconds(item?.avgActualTime) || "--"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundColor: statusColor() }}
            className={`absolute right-0 bottom-0 flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px] text-secondary-600`}
          >
            <p
              className={`text-xxs font-semibold ${item?.status === "started"
                  ? "text-[#CA8504]"
                  : item?.status === "finished"
                    ? "text-[#079455]"
                    : item?.status === "rejected"
                      ? "text-white"
                      : "text-[#475467]"
                } -rotate-90 whitespace-nowrap`}
            >
              {t("product:semiProduct")}
            </p>
          </div>
          <div
           
            className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
          >
            <NewDotsIcon />
          </div>
        </div>
      </div>
    </>
  );
};

export default StockDetailOperationCard;

const SubtractIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.9 0C1.57452 0 0.5 1.07452 0.5 2.4V9.6C0.5 10.9255 1.57452 12 2.9 12H10.1C11.4255 12 12.5 10.9255 12.5 9.6V2.4C12.5 1.07452 11.4255 0 10.1 0H2.9ZM4.1832 3.15663C4.1832 2.76272 4.1832 2.56576 4.26534 2.45719C4.33689 2.3626 4.44625 2.30407 4.56464 2.297C4.70054 2.28889 4.86442 2.39814 5.19218 2.61665L9.45744 5.46015C9.72826 5.6407 9.86367 5.73098 9.91086 5.84476C9.95211 5.94424 9.95211 6.05604 9.91086 6.15552C9.86367 6.2693 9.72826 6.35958 9.45744 6.54012L9.45744 6.54013L5.19218 9.38363L5.19217 9.38364C4.86441 9.60214 4.70054 9.71139 4.56464 9.70328C4.44625 9.69621 4.33689 9.63768 4.26534 9.54309C4.1832 9.43452 4.1832 9.23756 4.1832 8.84365V3.15663ZM5.41016 4.7521C5.41016 4.5792 5.41016 4.49275 5.44621 4.4451C5.47761 4.40359 5.52561 4.3779 5.57758 4.37479C5.63722 4.37123 5.70915 4.41919 5.85301 4.51509L7.72509 5.76315L7.7251 5.76315C7.84396 5.84239 7.90339 5.88201 7.9241 5.93196C7.94221 5.97562 7.94221 6.02469 7.9241 6.06835C7.90339 6.11829 7.84396 6.15791 7.7251 6.23716L7.72509 6.23716L5.85301 7.48522C5.70915 7.58112 5.63722 7.62907 5.57758 7.62551C5.52561 7.62241 5.47761 7.59672 5.44621 7.55521C5.41016 7.50755 5.41016 7.4211 5.41016 7.24821V4.7521Z"
        fill="#17B26A"
      />
    </svg>
  );
};

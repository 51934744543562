import React, { useState } from "react";
import { Button, CustomModal } from "../../components";
import { ArrowLeftIcon } from "./quality/assets/qualitySvg";
import { useNavigate, useParams } from "react-router-dom";
import generateFileUrl from "../../utils/generateFileUrl";
import { EditIcon } from "../../assets/icons/departmentIcon";
import SearchInput from "../../components/inputs/SearchInput";
import { FilterIcon, SortIcon } from "../../assets/icons/productIcons";
import Badgets from "../../components/buttons/Badgets";
import RawMaterialMonthlyChart from "./chart/RawMaterialMonthlyChart";
import { useQuery } from "@tanstack/react-query";
import { reportService } from "../../services/report.service";
import { timeFormatter } from "../../utils/timezoneFormatter";
import moment from "moment";
import { rawMaterialService } from "../../services/raw-material.service";
import NoAvatar from "../../components/avatar/NoAvatar";
import EditStockQuantityModal from "./modals/EditStockQuantityModal";
import { useTranslation } from "react-i18next";

const RawMaterialDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const { data, isLoading, refetch, isFetching } = useQuery({
    queryKey: ["getRawMaterialDetail", id],
    queryFn: async () => (id ? await rawMaterialService.getPurchaseList(id) : ""),
    enabled: !!id,
    retry: 0,
  });

  const formatLogMessage = (log) => {
    const boldClass = "font-semibold text-[#101828] text-sm";
    const reason = `${log?.reason}-${log?.type}`;
    switch (reason) {
      case "reserv-outcome":
        if (log?.purchaseTransaction?.order) {
          return (
            <>
              <span className={boldClass}>{log?.purchaseTransaction?.order?.orderNo || "--"}</span> nolu sipariş için{" "}
              <span className={boldClass}>
                {log?.purchaseTransaction?.orderedUnit || "--"} {data?.unitType || "ad"}
              </span>{" "}
              hammadde rezerv edildi.
            </>
          );
        } else {
          return (
            <>
              <span className={boldClass}>Order Yok</span>
            </>
          );
        }
      case "purchase-info":
        return (
          <>
            <span className={boldClass}>{log?.purchaseTransaction?.order?.orderNo || "--"}</span> nolu sipariş için{" "}
            <span className={boldClass}>
              {log?.purchaseTransaction?.orderedUnit || "--"} {data?.unitType || "ad"}
            </span>{" "}
            hammadde satın alındı.
          </>
        );
      case "purchase-income":
        const supplierNames = log?.purchaseTransaction?.junctions
          ? log?.purchaseTransaction?.junctions.map((junction) => junction?.supplier?.name).filter((name) => name)
          : [];

        const uniqueSuppliers = log?.purchaseTransaction?.junctions
          ? [...new Set(log?.purchaseTransaction?.junctions.map((junction) => junction?.supplier?.id))]
          : [];

        const supplierText = supplierNames?.length > 0 ? (uniqueSuppliers?.length === 1 ? supplierNames[0] : supplierNames?.join(", ")) : "tedarikçi";

        return (
          <>
            <span className={boldClass}>Ek Stok Artırımı</span> için{" "}
            <span className={boldClass}>
              {log?.purchaseTransaction?.orderedUnit || "--"} {data?.unitType || "ad"}
            </span>{" "}
            hammadde <span className={boldClass}>{supplierText}</span> tarafından tedarik edildi ve{" "}
            <span className={boldClass}>{log?.user?.name + " " + log?.user?.lastName}</span> tarafından satın alındı.
          </>
        );

      case "stock-income":
        return (
          <>
            <span className={boldClass}>{log?.purchaseTransaction?.order?.orderNo || "--"}</span> nolu sipariş için{" "}
            <span className={boldClass}>
              {log?.purchaseTransaction?.orderedUnit || "--"} {data?.unitType || "ad"}
            </span>{" "}
            hammadde stoğa girdi.
          </>
        );
      case "stock-outcome":
        return (
          <>
            <span className={boldClass}>{log?.purchaseTransaction?.order?.orderNo || "--"}</span> nolu sipariş için{" "}
            <span className={boldClass}>
              {log?.purchaseTransaction?.orderedUnit || "--"} {data?.unitType || "ad"}
            </span>{" "}
            hammadde stoktan çıktı.
          </>
        );
      case "supply_request-info":
        if (log?.purchaseTransaction?.order) {
          return (
            <>
              <span className={boldClass}>{log?.purchaseTransaction?.order?.orderNo || "--"}</span> nolu sipariş için{" "}
              <span className={boldClass}>
                {log?.purchaseTransaction?.orderedUnit || "--"} {data?.unitType || "ad"}
              </span>{" "}
              tedarik talebi <span className={boldClass}>{log?.user?.name + " " + log?.user?.lastName || "--"}</span> tarafından oluşturuldu.
            </>
          );
        } else {
          return (
            <>
              <span className={boldClass}>Ek Stok Artırımı</span> için{" "}
              <span className={boldClass}>
                {log?.purchaseTransaction?.orderedUnit || "--"} {data?.unitType || "ad"}
              </span>{" "}
              tedarik talebi <span className={boldClass}>{log?.user?.name + " " + log?.user?.lastName || "--"}</span> tarafından oluşturuldu.
            </>
          );
        }
      case "manual-income":
        return (
          <>
            Hammadde stoğu <span className={boldClass}>{log?.user?.name + " " + log?.user?.lastName}</span> tarafından{" "}
            <span className={boldClass}>
              {log?.purchaseTransaction?.orderedUnit || "--"} {data?.unitType || "ad"}
            </span>{" "}
            manuel olarak artırıldı.
          </>
        );
      case "manual-outcome":
        return (
          <>
            Hammadde stoğu <span className={boldClass}>{log?.user?.name + log?.user?.lastName}</span> tarafından{" "}
            <span className={boldClass}>
              {log?.purchaseTransaction?.orderedUnit || "--"} {data?.unitType || "ad"}
            </span>{" "}
            manuel olarak azaltıldı.
          </>
        );
      default:
        return `Bilinmeyen işlem türü: ${reason}`;
    }
  };

  return (
    <div className="flex flex-col w-full h-full gap-y-6 overflow-y-auto overflow-x-hidden scrollbar-hide">
      {/* header */}
      <div className="flex w-full min-h-[56px] max-h-[56px] h-[56px] justify-between items-center border-b border-[#E4E7EC] sticky top-0 bg-[#fff] z-[50]">
        <div className="flex gap-x-2 items-center">
          <Button size={"md"} iconLeft={<ArrowLeftIcon />} colorType={"tertiary-gray"} onClick={() => navigate(-1)} type={"button"} />
          <div className="flex gap-x-3 items-center">
            {data?.image ? (
              <img
                className="h-9 w-12 min-h-[36px] max-h-[36px] min-w-[48px] max-w-[48px] rounded border border-[#0000001A]"
                src={generateFileUrl(data?.image)}
                width={48}
                height={36}
                alt={data?.name?.charAt(0).toUpperCase()}
              />
            ) : (
              <NoAvatar name={data?.name || "--"} rounded={4} theme={"product"} minWidth="48px" minHeight="36px" />
            )}
            <p className="text-[#101828] font-semibold text-xl">{data?.name || "--"}</p>
          </div>
        </div>
        <span>
          <Button
            colorType={"secondary-product"}
            iconLeft={<EditIcon />}
            label={t("buttons:edit")}
            size={"md"}
            onClick={() => navigate(`/app/product/mrp/stock/raw-material/edit/${id}`)}
          />
        </span>
      </div>
      {/* detail header */}
      <div className="flex gap-x-6">
        {/* ilk tablo */}
        <div className="flex flex-col border border-[#E4E7EC] rounded-lg w-1/3">
          <div className="flex w-full min-h-[34px] max-h-[34px] bg-[#F2F4F7] border-b border-[#E4E7EC] text-[#475467] font-normal text-xs rounded-t-lg">
            <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">{t("product:stockCodeRawMaterial")}</div>
            <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">{t("product:type")}</div>
            <div className="w-1/3 flex items-center px-4">{t("product:unit")}</div>
          </div>
          <div className="flex w-full min-h-[34px] max-h-[34px] bg-[#fff] text-[#101828] font-medium text-xs rounded-b-lg">
            <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">{data?.stockCode || "--"}</div>
            <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">{t("product:rawMaterial")}</div>
            <div className="w-1/3 flex items-center px-4">{data?.unitType || "ad"}</div>
          </div>
          <div className="flex w-full min-h-[34px] max-h-[34px] bg-[#F2F4F7] border-y border-[#E4E7EC] text-[#475467] font-normal text-xs">
            <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC] whitespace-nowrap">{t("product:supplierGroup")}</div>
            <div className="w-2/3 flex items-center px-4">{t("product:description")}</div>
          </div>
          <div className="flex w-full min-h-[34px] max-h-[34px] bg-[#fff] text-[#101828] border-b border-[#E4E7EC] font-medium text-xs rounded-b-lg">
            <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">{data?.supplierGroup?.name || "0"}</div>
            <div className="w-2/3 flex items-center px-4">{data?.description || "--"}</div>
          </div>
        </div>
        {/* ikinci tablo */}
        <div className="flex flex-col border border-[#E4E7EC] rounded-lg w-1/3">
          <div className="flex w-full min-h-[34px] max-h-[34px] bg-[#F2F4F7] border-b border-[#E4E7EC] text-[#475467] font-normal text-xs rounded-t-lg">
            <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">{t("product:currentStock")}</div>
            <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">{t("product:maxStock")}</div>
            <div className="w-1/3 flex items-center px-4">{t("product:minStock")}</div>
          </div>
          <div className="flex w-full min-h-[34px] max-h-[34px] bg-[#fff] text-[#101828] font-medium text-xs rounded-b-lg">
            <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">{data?.quantity || "0"}</div>
            <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">{data?.maxStock || "0"}</div>
            <div className="w-1/3 flex items-center px-4">{data?.minStock || "0"}</div>
          </div>
          <div className="flex w-full min-h-[34px] max-h-[34px] bg-[#F2F4F7] border-y border-[#E4E7EC] text-[#475467] font-normal text-xs">
            <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">{t("product:reservedStock")}</div>
            <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">{t("product:availableStock")}</div>
            <div className="w-1/3 flex items-center px-4">{t("product:criticalStock")}</div>
          </div>
          <div className="flex w-full min-h-[34px] max-h-[34px] bg-[#fff] text-[#101828] border-b border-[#E4E7EC] font-medium text-xs rounded-b-lg">
            <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">{data?.reserveStock || "0"}</div>
            <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">{data?.freeStock || "0"}</div>
            <div className="w-1/3 flex items-center px-4">{data?.criticalStock || "0"}</div>
          </div>
        </div>
        {/* üçüncü tablo */}
        <div className="flex flex-col border border-[#E4E7EC] rounded-lg w-1/3">
          <div className="flex w-full min-h-[34px] max-h-[34px] bg-[#F2F4F7] border-b border-[#E4E7EC] text-[#475467] font-normal text-xs rounded-t-lg">
            <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">{t("product:currentPrice")}</div>
            <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">{t("product:highestPrice")}</div>
            <div className="w-1/3 flex items-center px-4">{t("product:lowestPrice")}</div>
          </div>
          <div className="flex w-full min-h-[34px] max-h-[34px] bg-[#fff] text-[#101828] font-medium text-xs rounded-b-lg">
            <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">{data?.currentPrice || "0"}</div>
            <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">{data?.maxPrice || "0"}</div>
            <div className="w-1/3 flex items-center px-4">{data?.minPrice || "0"}</div>
          </div>
          <div className="flex w-full min-h-[34px] max-h-[34px] bg-[#F2F4F7] border-y border-[#E4E7EC] text-[#475467] font-normal text-xs">
            <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">{t("product:averageStockPrice")}</div>
            <div className="w-2/3 flex items-center px-4">{t("product:totalAllPurchases")}</div>
          </div>
          <div className="flex w-full min-h-[34px] max-h-[34px] bg-[#fff] text-[#101828] border-b border-[#E4E7EC] font-medium text-xs rounded-b-lg">
            <div className="w-1/3 flex items-center px-4 border-r border-[#E4E7EC]">{data?.averagePrice || "0"}</div>
            <div className="w-2/3 flex items-center px-4">{data?.totalPriceValue || "0"}</div>
          </div>
        </div>
      </div>
      {/* monthly chart */}
      <div className="flex w-full">
        {isLoading || isFetching ? (
          <div className="bg-gray-200 animate-pulse rounded-lg w-full min-h-[275px]" />
        ) : (
          <div className="flex w-full border rounded-lg shadow border-secondary-200">
            <RawMaterialMonthlyChart
              defaultLegends={data?.monthlyData?.legend}
              data={data?.monthlyData}
              end={data?.monthlyData?.endDate}
              start={data?.monthlyData?.startDate}
              selectedDate={moment(timeFormatter(new Date())?.formatted).format("YYYY-MM-DD")}
              selectedEquipmentId={data?.id || data?.nodeId}
              refetch={refetch}
              unitType={data?.unitType}
              currency={data?.currency}
            />
          </div>
        )}
      </div>
      {/* stock movements */}
      <div className="flex flex-col w-full border shadow-lg border-[#E4E7EC] rounded-xl min-h-[87vh]">
        <div className="flex w-full min-h-[64px] max-h-[64px] h-[64px] justify-between items-center px-6 py-3 border-b border-[#E4E7EC]">
          <p className="text-[#101828] font-semibold text-lg">{t("product:rawMaterialStockMovements")}</p>
          <div className="flex gap-x-3 items-center">
            <SearchInput />
            <Button colorType={"secondary-gray"} iconLeft={<SortIcon />} size={"md"} />
            <Button colorType={"secondary-gray"} iconLeft={<FilterIcon />} size={"md"} />
          </div>
        </div>
        <div className="flex flex-col h-full overflow-y-auto overflow-x-hidden scrollbar-hide">
          {data?.logs?.map((item, index) => {
            return (
              <div
                className={`flex w-full min-h-[54px] max-h-[54px] h-[54px] ${index % 2 === 0 ? "bg-[#fff]" : "bg-[#F9FAFB]"} ${
                  data?.logs?.length - 1 === index ? "rounded-b-xl" : "border-b border-[#E4E7EC]"
                }`}
              >
                {" "}
                <div className="flex min-w-[140px] max-w-[140px] w-[140px] justify-center items-center px-6 py-4 gap-x-2">
                  <span
                    className={`flex min-w-[20px] max-w-[20px] w-[20px] min-h-[20px] max-h-[20px] h-[20px] ${
                      item?.type === "income"
                        ? "text-[#17B26A] -rotate-90"
                        : item?.type === "outcome"
                        ? "text-[#F04438] rotate-90"
                        : item?.type === "info"
                        ? "text-[#475467]"
                        : ""
                    }`}
                  >
                    <TriangleIcon />
                  </span>
                  <span className="flex gap-x-1 items-center justify-center">
                    <p
                      className={`text-sm font-medium ${
                        item?.type === "income" ? "text-[#17B26A]" : item?.type === "outcome" ? "text-[#F04438]" : item?.type === "info" ? "text-[#475467]" : ""
                      }`}
                    >
                      {item?.purchaseTransaction?.orderedUnit || "0"}
                    </p>
                    <p className="text-[#475467] font-normal text-xs">{data?.unitType || "ad"}</p>
                  </span>
                </div>
                <div className="flex min-w-[200px] max-w-[200px] w-[200px] py-4 items-center justify-center gap-x-1">
                  <p className="text-[#475467] font-medium text-xs">{moment(item?.createdDate).format("DD.MM.YYYY")}</p>
                  <p className="text-[#667085] font-normal text-xs">{moment(item?.createdDate).format("dddd")}</p>
                  <p className="text-[#667085] font-normal text-xs">{moment(item?.createdDate).format("HH:mm")}</p>
                </div>
                <div className="flex min-w-[140px] max-w-[140px] w-[140px] justify-center items-center px-6 py-4">
                  <Badgets
                    colorType={
                      item?.type === "income" ? "fill-success" : item?.type === "outcome" ? "fill-error" : item?.type === "outcome" ? "fill-gray" : "fill-gray"
                    }
                    label={
                      item?.reason === "supply_request"
                        ? t("product:supplyRequest")
                        : item?.reason === "reserv"
                        ? t("product:reserve")
                        : item?.reason === "manual"
                        ? `${t("product:manual")} ${item?.type === "income" ? t("product:entry") : item?.type === "outcome" ? t("product:exit") : ""}`
                        : item?.reason === "purchase"
                        ? t("product:purchases")
                        : item?.reason === "stock"
                        ? `${t("product:stock")} ${item?.type === "income" ? t("product:entry") : item?.type === "outcome" ? t("product:exit") : ""}`
                        : ""
                    }
                  />
                </div>
                <div className="flex w-[68%] px-5 py-4">
                  <p className="truncate text-[#475467] font-normal text-sm">{formatLogMessage(item)}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RawMaterialDetail;

const TriangleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M5.74528 2.08725L16.8322 9.68002C16.8848 9.71948 16.927 9.76852 16.956 9.82377C16.985 9.87902 17 9.93915 17 10C17 10.0609 16.985 10.121 16.956 10.1762C16.927 10.2315 16.8848 10.2805 16.8322 10.32L5.74529 17.9127C5.67738 17.9601 5.59566 17.9895 5.50955 17.9977C5.42345 18.0058 5.33648 17.9924 5.25869 17.9588C5.18117 17.925 5.11589 17.8729 5.07019 17.8082C5.02449 17.7435 5.00018 17.6689 5 17.5928L5 2.40723C5.00018 2.33107 5.02449 2.25646 5.07019 2.19179C5.11589 2.12711 5.18117 2.07495 5.25869 2.04116C5.33648 2.00763 5.42345 1.99416 5.50955 2.00232C5.59566 2.01048 5.67738 2.03992 5.74528 2.08725Z"
        fill="currentColor"
      />
    </svg>
  );
};

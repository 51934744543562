import React from 'react'
import CustomImage from '../../../components/CustomImage';
import { NoEquIcon } from '../../../assets/icons/machineIcons';
import { useTranslation } from 'react-i18next';
import { NewDotsIcon } from '../../../components/new-cards/card-icons';
import { Tooltip } from 'react-tooltip';

const StockDetailRecipeRawMaterialCard = ({ item }) => {
    const { t } = useTranslation()
    const units = [
        { shortLabel: "kg", value: "kilogram" },
        { shortLabel: "ton", value: "ton" },
        { shortLabel: "gr", value: "gram" },
        { shortLabel: "mg", value: "miligram" },
        { shortLabel: "mcg", value: "mikrogram" },
        { shortLabel: "lb", value: "libre" },
        { shortLabel: "oz", value: "ons" },
        { shortLabel: "m", value: "metre" },
        { shortLabel: "mm", value: "milimetre" },
        { shortLabel: "gal", value: "galon" },
        { shortLabel: "m2", value: "metrekare" },
        { shortLabel: "mm2", value: "milimetrekare" },
        { shortLabel: "in2", value: "inçkare" },
        { shortLabel: "ad", value: "adet" },
        { shortLabel: "pkg", value: "paket" },
        { shortLabel: "box", value: "kutu" },
      ];
    const getUnitLabel = (unit) => {
        if (unit === undefined) {
          return "--";
        }
        const foundUnit = units.find((u) => u.value === unit);
        return foundUnit ? foundUnit.shortLabel : unit;
      };
    return (
        <div

            className="flex bg-white flex-row mr-4 w-[312px] min-w-[312px] h-[112px] min-h-[112px] relative group pl-3 pr-3  pt-2 border border-[#D0D5DD] rounded-lg cursor-grab "
        >
            <div className="flex flex-col w-[93%]">
                <div className="flex flex-row items-center gap-x-3">
                    {item?.image ? (
                        <div
                            data-tooltip-id={`${item?.id}`}
                            data-tooltip-place="right"
                            data-tooltip-content={`${t("product:productName")}: ${item?.name} - ${t("product:stockCode")}: ${item?.stockCode
                                }`}
                        >
                            <CustomImage
                                borderRadius={2}
                                src={item?.image}
                                style={{
                                    width: 64,
                                    height: 48,
                                    minWidth: 64,
                                    minHeight: 48,
                                    borderRadius: 4,
                                    borderWidth: 1,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    display: "flex",
                                    borderColor: "#0000001A",
                                    backgroundColor: "#fff",
                                }}
                            />
                        </div>
                    ) : (
                        <div
                            data-tooltip-id={`${item?.id}`}
                            data-tooltip-place="right"
                            data-tooltip-content={`${t("product:productName")}: ${item?.name} - ${t("product:stockCode")}: ${item?.stockCode
                                }`}
                            className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                        >
                            <NoEquIcon />
                        </div>
                    )}
                    <div className="flex flex-col items-start">
                        <div className="flex flex-row items-center gap-x-1  w-full">
                            <p className="font-semibold w-[175px] text-secondary-900 text-base truncate">

                              {item?.name || "Product Name"}
                            </p>
                        </div>
                        <div className={`h-6 max-w-[175px] flex px-1 py-[3px] ml-[2px] items-center justify-center bg-[#FFFAEB]`}>
                            <p className={`text-sm font-normal bg-[#FFFAEB] max-w-[175px] truncate text-[#B54708]`}>
                                {t("product:stock")} :{" "}
                               {item?.stockCode}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-center gap-x-1 w-[100%]">
                    <div className="flex flex-col h-[42px] w-[28%] min-w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
                        <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
                            <p className="text-[10px] font-medium text-secondary-600">{"Stokta"}</p>
                        </div>
                        <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
                            <p className="text-xs font-medium text-secondary-700">{item?.freeStock || "--"}</p>
                        </div>
                    </div>
                    <div className="flex flex-col h-[42px] min-w-[28%] w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
                        <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
                            <p className="text-[10px] font-medium text-secondary-600">{"Aktif Talep"}</p>
                        </div>
                        <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
                            <p className="text-xs font-medium text-secondary-700">{"0" || "--"}</p>
                        </div>
                    </div>
                    <div className="w-[44%] h-[42px] flex items-center mt-1.5 self-stretch border  border-[#E4E7EC] rounded ml-auto overflow-hidden ">
                        <div className="w-[42px] min-w-[42px] h-[18px] px-[1px] py-1 -ml-[11.5px] -mr-3 -rotate-90 justify-center items-center flex gap-2 border-b  border-[#E4E7EC] bg-[#F9FAFB]">
                            <span className="text-xxs truncate text-[#475467] font-medium ">{"MS"}</span>
                        </div>
                        <div className="flex flex-col w-full items-start">
                            <div className="flex flex-row items-center w-full border-b px-1">
                                <p className="text-xxs font-normal text-secondary-600">{"Kalan"}</p>
                                <p className="text-xs font-medium ml-auto text-secondary-600">{"0" || "0"}</p>
                            </div>
                            <div className="flex flex-row items-center w-full px-1">
                                <p className="text-xxs font-normal text-secondary-600">{"Planlı"}</p>
                                <p className="text-xs font-medium ml-auto text-secondary-600">{"0" || "0"}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`absolute right-0 bg-[#F2F4F7] bottom-0 flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px] text-secondary-600`}
            >
                <p className={`text-[10px] font-semibold whitespace-nowrap text-[#475467] -rotate-90`}>
                    {"R.Material"} ({getUnitLabel(item?.unitType)})
                </p>
            </div>
            <div
              
                className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
            >
                <NewDotsIcon />
            </div>

            <Tooltip className="z-[120]" id={`${item?.id}`} />
        </div>
    )
}

export default StockDetailRecipeRawMaterialCard


import React, { useState } from "react";
import NoAvatar from "../../../components/avatar/NoAvatar";
import generateFileUrl from "../../../utils/generateFileUrl";
import { Button, Input } from "../../../components";
import moment from "moment";
import { orderService } from "../../../services/order.service";
import { useQuery } from "@tanstack/react-query";
import Badgets from "../../../components/buttons/Badgets";
import SearchInput from "../../../components/inputs/SearchInput";
import { Toast } from "../../../utils/toastify/toast";
import { useTranslation } from "react-i18next";

const WipExitModal = ({ item }) => {
  const {t} = useTranslation()
  const { data, isLoading, refetch, isFetching } = useQuery({
    queryKey: ["wip-exit-modal-detail"],
    queryFn: async () => await orderService.getOrderWip(item?.id),
    retry: 0,
  });

  const [sendAmounts, setSendAmounts] = useState({});

  const handleInputChange = (id, value) => {
    setSendAmounts((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleSubmit = async () => {
    const sendData = data?.data
      ?.filter((item) => sendAmounts[item?.id] && parseInt(sendAmounts[item?.id]) > 0)
      .map((item) => ({
        // id: item?.details[0]?.id,
        id:item?.id,
        desiredQuantity: parseInt(sendAmounts[item?.id]),
      }));

    const totalDesiredQuantity = sendData.reduce((total, item) => total + item?.desiredQuantity, 0);

    if (totalDesiredQuantity > item?.finishedPiece) {
      Toast("error", `Girilen miktar hazır adetten büyük olamaz (max: ${item?.finishedPiece}).`);
      return;
    }

    if (sendData?.length > 0) {
      await orderService.transferJunctionsToOrder(item?.id, sendData).then((res) => {
        if (res.data.code === 1) {
          Toast("success", res.data.message);
        } else {
          Toast("error", res.data.message);
        }
      });
    } else {
      Toast("error", "Lütfen gerekli alanları doldurun.");
    }
  };
  const tableHeadItems = [t("product:orderNoCustomer"), t("table:deliveryDate"), t("product:orderQuantityss"), t("product:totalDeliveredQuantity"), t("product:remainingOrders"), t("product:quantityToBeSent")];
  return (
    <div className="flex flex-col w-full h-full pt-4">
      <div className="flex w-full px-6 py-5 gap-x-6 border-t border-[#E4E7EC]">
        {item?.product?.image ? (
          <img
            className="flex min-w-[64px] max-w-[64px] w-[64px] min-h-[48px] max-h-[48px] h-[48px] rounded border border-[#0000001A]"
            src={generateFileUrl(item?.product?.image)}
            alt={item?.product?.name}
          />
        ) : (
          <div className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-200 overflow-hidden">
            <NoAvatar name={item?.product?.name || "--"} rounded={4} theme={"product"} />
          </div>
        )}
        <div className="flex flex-col w-[464px]">
          <p className="ml-1 text-[#101828] font-semibold text-md w-[370px] truncate">{item?.product?.name || "--"}</p>
          <div className="flex w-fit max-w-[370px] truncate px-1 py-[3px] bg-[#FFFAEB] rounded items-center">
            <p className="text-[#B54708] text-xs font-medium">{item?.product?.stockCode || "--"}</p>
          </div>
        </div>
        <div className="flex w-[276px]">
          <div className="flex flex-col gap-y-1 w-1/2">
            <p className="text-[#475467] text-sm">{t("product:readyQuantity")}</p>
            <div className="flex gap-x-[6px]">
              <QuantityAcceptedIcon />
              <p className="text-md font-semibold text-[#079455]">{item?.finishedPiece ?? "0"}</p>
            </div>
          </div>
          <div className="flex flex-col gap-y-1 w-1/2">
            <p className="text-[#475467] text-sm">{t("product:scrapQuantitys")}</p>
            <div className="flex gap-x-[6px]">
              <QuantityRejectedIcon />
              <p className="text-md font-semibold text-[#D92D20]">{item?.rejectedPiece ?? "0"}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full min-h-[50vh] max-h-[50vh] h-[50vh] overflow-y-auto overflow-x-hidden scrollbar-hide border rounded-t-xl border-[#E4E7EC]">
        <div className="flex w-full justify-between min-h-[64px] max-h-[64px] h-[64px] px-6 py-3 items-center">
          <div className="flex gap-x-2 items-center justify-center">
            <p className="text-[#101828] font-semibold text-lg">{t("product:openOrder")}</p>
            <Badgets colorType={"outline-error"} label={data?.data?.length || 0} size={"sm"} />
          </div>
          <div className="flex min-w-[240px] max-w-[240px] w-[240px] ">
            <SearchInput theme={"product"} />
          </div>
        </div>
        <div className="flex max-h-[26px] min-h-[26px] w-full bg-[#F9FAFB] sticky top-0 z-[10]">
          {tableHeadItems.map((item, i) => (
            <div
              key={i}
              className={`flex border-b px-2 border-r border-[#E4E7EC] items-center justify-center text-center w-[${
                i === 0 ? "24%" : i === 1 || i === 2 || i === 4 ? "13%" : i === 3 ? "16%" : "21%"
              }]`}
            >
              <span className="text-center text-xs font-medium cursor-pointer whitespace-nowrap">{item}</span>
            </div>
          ))}
        </div>
        {data?.data?.map((order, i) => (
          <div key={i} className="flex w-full items-center border-b h-[76px] min-h-[76px]">
            {/* tablo 1.kolon */}
            <div className="border-r flex h-full items-center justify-between w-[24%] px-6 py-3">
              <div className="flex flex-col">
                <p className="text-[#101828] text-sm font-semibold">{order?.workOrderNo || "0"}</p>
                {/* <p className="text-[#475467] text-xs">{order?.customer?.name || "--"}</p> */}
                {/* eksik */}
              </div>
              {/* <img
                className="w-[48px] min-w-[48px] max-w-[48px] h-[36px] min-h-[36px] max-h-[36px] rounded border border-[#0000001A]"
                src={generateFileUrl("12b2f70e-1a01-4429-a59d-b5bd6aa7f3a3")}
              /> */}
            </div>
            {/* tablo 2.kolon */}
            <div className="border-r flex flex-col h-full items-center justify-center w-[13%] py-[19px]">
              <p className="text-[#344054] text-sm font-medium">{moment(order?.createdDate).format("DD.MM.YYYY") || "--"}</p>
              {/* <p className="text-[#667085] text-xs">{moment(order?.details[0]?.deliveryDate).format("dddd")}</p> */}
            </div>
            {/* tablo 3.kolon */}
            <div className="border-r flex flex-col h-full items-center justify-center w-[13%] py-[19px]">
              <p className="text-[#344054] text-sm font-medium">{order?.desiredQuantity || "0"}</p>
              <p className="text-[#667085] text-xs">{t("product:pieces")}</p>
            </div>
            {/* tablo 4.kolon */}
            <div className="border-r flex flex-col h-full items-center justify-center w-[16%] py-[19px]">
              <p className="text-[#344054] text-sm font-medium">{order?.finishedQuantity ?? "0"}</p>
              <p className="text-[#667085] text-xs">{t("product:pieces")}</p>
            </div>
            {/* tablo 5.kolon */}
            <div className="border-r flex flex-col h-full items-center justify-center w-[13%] py-[19px]">
              <p className="text-[#344054] text-sm font-medium">{order?.desiredQuantity - order?.finishedQuantity ?? "0"}</p>
              <p className="text-[#667085] text-xs">{t("product:pieces")}</p>
            </div>
            {/* tablo 6.kolon */}
            <div className="flex w-[21%] h-full px-6 py-[18px]">
              <Input
                icon={<QuantityIcon />}
                theme={"product"}
                type={"number"}
                value={sendAmounts[order?.id] || ""}
                onChange={(e) => handleInputChange(order?.id, e?.target?.value)}
                disabled={item?.finishedPiece > 0 ? false : true}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="flex w-full px-6 pt-6 gap-x-3">
        <Button colorType={"secondary-gray"} label={t("buttons:stop")} size={"lg"} />
        <Button colorType={"primary-product"} label={t("buttons:send")} size={"lg"} onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default WipExitModal;

const QuantityAcceptedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_17929_78674)">
        <path
          d="M6.87337 1.66669H3.74837C2.59778 1.66669 1.66504 2.59943 1.66504 3.75002V16.25C1.66504 17.4006 2.59778 18.3334 3.74837 18.3334H8.14652M6.87337 1.66669V6.48812C6.87337 6.569 6.95957 6.62072 7.03094 6.58265L9.99837 5.00002L12.9658 6.58265C13.0372 6.62072 13.1234 6.569 13.1234 6.48812V1.66669M6.87337 1.66669H13.1234M13.1234 1.66669H16.2484C17.399 1.66669 18.3317 2.59943 18.3317 3.75002V9.01208M10.7239 15.1531L13.0876 17.5033L18.3348 12.2628"
          stroke="#17B26A"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17929_78674">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const QuantityRejectedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_17929_78679)">
        <path
          d="M6.87533 1.66669H3.75033C2.59973 1.66669 1.66699 2.59943 1.66699 3.75002V16.25C1.66699 17.4006 2.59973 18.3334 3.75033 18.3334H8.14847M6.87533 1.66669V6.48812C6.87533 6.569 6.96152 6.62072 7.03289 6.58265L10.0003 5.00002L12.9678 6.58265C13.0391 6.62072 13.1253 6.569 13.1253 6.48812V1.66669M6.87533 1.66669H13.1253M13.1253 1.66669H16.2503C17.4009 1.66669 18.3337 2.59943 18.3337 3.75002V9.01208M12.2727 12.2353L14.8952 14.8906M17.5297 17.5022L14.8952 14.8906M14.8952 14.8906L17.5297 12.2353M14.8952 14.8906L12.2727 17.5022"
          stroke="#F04438"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17929_78679">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const QuantityIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_18121_1076)">
        <path
          d="M6.87533 1.66669H4.16699C2.78628 1.66669 1.66699 2.78597 1.66699 4.16669V15.8334C1.66699 17.2141 2.78628 18.3334 4.16699 18.3334H15.8337C17.2144 18.3334 18.3337 17.2141 18.3337 15.8334V4.16669C18.3337 2.78598 17.2144 1.66669 15.8337 1.66669H13.1253M6.87533 1.66669V6.4524C6.87533 6.54946 6.97876 6.61152 7.0644 6.56585L10.0003 5.00002L12.9363 6.56585C13.0219 6.61152 13.1253 6.54946 13.1253 6.4524V1.66669M6.87533 1.66669H13.1253"
          stroke="#667085"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_18121_1076">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import React, { useState } from "react";
import NewOrderCard from "./components/NewOrderCard";
import { useTranslation } from "react-i18next";
import OrderPurchaseCard from "./components/OrderPurchaseCard";
import InternalOperationCard from "./components/InternalOperationCard";
import ExternalOperationCard from "./components/ExternalOperationCard";
import { ArrowDownIcon } from "../../assets/icons/stepsIcons";
import AssemblyCard from "./components/AssemblyCard";

const MontageTest = ({ tableHeadItemsLength }) => {
  const data = [
    {
      order: {
        id: "38eed7e7-a2e7-4026-b780-fa49847b7215",
        piece: 250,
        finishedPiece: 0,
        rejectedPiece: 0,
        orderNo: "RDTZ72",
        deliveryDate: "2024-03-20T21:00:00.000Z",
        product: {
          name: "RMU Redüktör Z72 Dişli-F /  1",
          image: "32bf0633-47f0-455a-8da5-92c0e9d27869",
        },
        rawMaterial: {
          status: "approved",
          qualityStatus: "accepted",
          orderedQuantity: 250,
          acceptedQuantity: 250,
          rejectedQuantity: 0,
          material: {
            source: "out",
            type: "stick",
            unit: "kilogram",
          },
        },
      },
      isProduct: true,
      operations: [
        {
          id: "M01",
          name: "M",
          step: 1,
          montage: [
            {
              order: {
                id: "2557aa19-526a-4cf6-89f5-520ff6704a5f",
                piece: 400,
                finishedPiece: 0,
                rejectedPiece: 0,
                orderNo: "702040725001/0133",
                deliveryDate: "2024-05-10T21:00:00.000Z",
                product: {
                  id: "a73a0408-260e-49ef-a2aa-98b8c26c580a",
                  name: "RMU Redüktör Z14 Pinyon Dişli /  1",
                  image: "d2a015d6-80c2-44a6-9f10-5604a8bacb8f",
                },
                rawMaterial: {
                  status: "approved",
                  qualityStatus: "accepted",
                  orderedQuantity: 400,
                  acceptedQuantity: 400,
                  rejectedQuantity: 0,
                  material: {
                    source: "out",
                    type: "stick",
                    unit: "kilogram",
                  },
                },
              },
              id: 3,
              montageId: "M01",
              operations: [
                {
                  name: "CNC Torna",
                  id: "c8172b74-93f9-4bf4-8e92-df8097288a39",
                  step: 1,
                  totalEstimatedTime: 24000,
                  piece: 400,
                  finishedPiece: 400,
                  rejectedPiece: 0,
                  avgActualTime: 107.22183036629156,
                  type: "internal",
                  status: "finished",
                  qualityStatus: "waiting",
                  hasQualityControl: true,

                  totalJunctionTime: 58278,
                  totalJunctionLength: 3,
                  newAvgActualCalculate: 134,
                  previousPiece: 400,
                  qualityAcceptedPiece: 400,
                  qualityRejectedPiece: 0,
                  internalOperation: {
                    estimatedTime: 60,
                  },
                },
                {
                  name: "CNC Torna",
                  id: "3409ab1b-c8c3-4e8a-87e8-89b010099a5e",
                  step: 2,
                  totalEstimatedTime: 24000,
                  piece: 400,
                  finishedPiece: 400,
                  rejectedPiece: 0,
                  avgActualTime: 106.95741509634834,
                  type: "internal",
                  status: "finished",
                  qualityStatus: "waiting",
                  hasQualityControl: true,
                  totalJunctionTime: 52237,
                  totalJunctionLength: 3,
                  newAvgActualCalculate: 119,
                  previousPiece: 400,
                  qualityAcceptedPiece: 400,
                  qualityRejectedPiece: 0,
                  internalOperation: {
                    estimatedTime: 60,
                  },
                },
                {
                  name: "CNC Freze",
                  id: "25a4cc4e-30ff-40fa-8a09-a49fda375543",
                  step: 3,
                  totalEstimatedTime: 24000,
                  piece: 400,
                  finishedPiece: 286,
                  rejectedPiece: 0,
                  avgActualTime: 51.97902097902098,
                  type: "internal",
                  status: "started",
                  qualityStatus: "waiting",
                  hasQualityControl: true,
                  totalJunctionTime: 21717,
                  totalJunctionLength: 1,
                  newAvgActualCalculate: 52,
                  previousPiece: 400,
                  qualityAcceptedPiece: 286,
                  qualityRejectedPiece: 0,
                  internalOperation: {
                    estimatedTime: 60,
                  },
                },
                {
                  name: "CNC Dişli",
                  id: "ccde377b-c3eb-4280-bb90-4638165929c3",
                  step: 4,
                  totalEstimatedTime: 34320,
                  piece: 400,
                  finishedPiece: 128,
                  rejectedPiece: 0,
                  avgActualTime: 249.84375,
                  type: "internal",
                  status: "started",
                  qualityStatus: "waiting",
                  totalJunctionTime: 31980,
                  totalJunctionLength: 1,
                  newAvgActualCalculate: 250,
                  previousPiece: 286,
                  qualityAcceptedPiece: 128,
                  qualityRejectedPiece: 0,
                  internalOperation: {
                    estimatedTime: 120,
                  },
                },
                {
                  name: "Islah",
                  id: "95eb8676-2960-4d68-86f3-4e18247eee10",
                  step: 5,
                  totalEstimatedTime: 7,
                  piece: 400,
                  finishedPiece: 0,
                  rejectedPiece: 0,
                  avgActualTime: 0,
                  type: "external",
                  status: "passive",
                  qualityStatus: "waiting",
                  totalJunctionTime: 0,
                  totalJunctionLength: 0,
                  newAvgActualCalculate: 0,
                  previousPiece: 128,
                  qualityAcceptedPiece: 0,
                  qualityRejectedPiece: 0,
                  externalOperation: {
                    estimatedTime: 7,
                  },
                },
              ],
            },
            {
              order: {
                id: "223268e1-e109-4d89-85b7-2799374fed9b",
                piece: 250,
                finishedPiece: 0,
                rejectedPiece: 0,
                orderNo: "RMU0132",
                deliveryDate: "2024-05-17T21:00:00.000Z",
                product: {
                  id: "21b1aafa-bca9-4b74-9d35-52283cac0dae",
                  name: "RMU Redüktör Altıköşe Mil-F /  1",
                  image: "775c9532-67e0-4736-ba6f-7af3af9735b0",
                },
                rawMaterial: {
                  status: "approved",
                  qualityStatus: "accepted",
                  orderedQuantity: 250,
                  acceptedQuantity: 250,
                  rejectedQuantity: 0,
                  material: {
                    source: "out",
                    type: "stick",
                    unit: "kilogram",
                  },
                },
              },
              id: 4,
              name: "SUB",
              montageId: "M01",
              operations: [
                {
                  name: "CNC Freze",
                  id: "cfd4a952-fae1-4f01-bb9a-0784c6fc9d01",
                  step: 1,
                  totalEstimatedTime: 30000,
                  piece: 250,
                  finishedPiece: 105,
                  rejectedPiece: 0,
                  avgActualTime: 342.32,
                  type: "internal",
                  status: "started",
                  qualityStatus: "waiting",
                  hasQualityControl: true,
                  totalJunctionTime: 35944,
                  totalJunctionLength: 1,
                  newAvgActualCalculate: 342,
                  previousPiece: 250,
                  qualityAcceptedPiece: 105,
                  qualityRejectedPiece: 0,
                  internalOperation: {
                    estimatedTime: 120,
                  },
                },
                {
                  name: "CNC Freze",
                  id: "41b3a2e4-6f2c-4cd7-8772-8806cd6d3ad3",
                  step: 2,
                  totalEstimatedTime: 12600,
                  piece: 250,
                  finishedPiece: 0,
                  rejectedPiece: 0,
                  avgActualTime: 0,
                  type: "internal",
                  status: "started",
                  qualityStatus: "waiting",
                  hasQualityControl: true,
                  totalJunctionTime: 43109,
                  totalJunctionLength: 0,
                  newAvgActualCalculate: null,
                  previousPiece: 105,
                  qualityAcceptedPiece: 0,
                  qualityRejectedPiece: 0,
                  internalOperation: {
                    estimatedTime: 120,
                  },
                },
                {
                  name: "Islah",
                  id: "8466638a-5fbc-4a46-8478-fdfda8fa9584",
                  step: 3,
                  totalEstimatedTime: 7,
                  piece: 250,
                  finishedPiece: 0,
                  rejectedPiece: 0,
                  avgActualTime: 0,
                  type: "external",
                  status: "passive",
                  qualityStatus: "waiting",
                  hasQualityControl: true,
                  totalJunctionTime: 0,
                  totalJunctionLength: 0,
                  newAvgActualCalculate: 0,
                  previousPiece: 0,
                  qualityAcceptedPiece: 0,
                  qualityRejectedPiece: 0,
                  externalOperation: {
                    estimatedTime: 7,
                  },
                },
                {
                  name: "Fosfat Kaplama",
                  id: "02880f16-0b21-467e-8f33-8e648fe05522",
                  step: 4,
                  totalEstimatedTime: 7,
                  piece: 250,
                  finishedPiece: 0,
                  rejectedPiece: 0,
                  avgActualTime: 0,
                  type: "external",
                  status: "passive",
                  qualityStatus: "waiting",
                  hasQualityControl: true,
                  totalJunctionTime: 0,
                  totalJunctionLength: 0,
                  newAvgActualCalculate: 0,
                  previousPiece: 0,
                  qualityAcceptedPiece: 0,
                  qualityRejectedPiece: 0,
                  externalOperation: {
                    estimatedTime: 7,
                  },
                },
              ],
            },
          ],
        },
        {
          name: "CNC Freze",
          id: "2949fb8f-e9d1-4858-9314-c197db330443",
          step: 1,
          totalEstimatedTime: 30000,
          piece: 250,
          finishedPiece: 99,
          rejectedPiece: 0,
          avgActualTime: 165.07,
          type: "internal",
          status: "started",
          qualityStatus: "waiting",
          hasQualityControl: true,
          totalJunctionTime: 62258,
          totalJunctionLength: 1,
          newAvgActualCalculate: 165,
          previousPiece: 250,
          qualityAcceptedPiece: 99,
          qualityRejectedPiece: 0,
          internalOperation: {
            estimatedTime: 120,
          },
        },
        {
          name: "CNC Dişli",
          id: "be76f27b-1be4-43be-b183-0cc4fa0ee67e",
          step: 3,
          totalEstimatedTime: 0,
          piece: 250,
          finishedPiece: 0,
          rejectedPiece: 0,
          avgActualTime: 0,
          type: "internal",
          status: "passive",
          qualityStatus: "waiting",
          hasQualityControl: true,
          totalJunctionTime: 0,
          totalJunctionLength: 0,
          newAvgActualCalculate: null,
          previousPiece: 0,
          qualityAcceptedPiece: 0,
          qualityRejectedPiece: 0,
          internalOperation: {
            estimatedTime: 360,
          },
        },
        {
          name: "Islah",
          id: "9c19c20f-5340-4ecb-bfd8-efb2f0ea53ab",
          step: 4,
          totalEstimatedTime: 7,
          piece: 250,
          finishedPiece: 0,
          rejectedPiece: 0,
          avgActualTime: 0,
          type: "external",
          status: "passive",
          qualityStatus: "waiting",
          hasQualityControl: true,
          totalJunctionTime: 0,
          totalJunctionLength: 0,
          newAvgActualCalculate: 0,
          previousPiece: 0,
          qualityAcceptedPiece: 0,
          qualityRejectedPiece: 0,
          externalOperation: {
            estimatedTime: 7,
          },
        },
        {
          name: "Fosfat Kaplama",
          id: "9fefba88-1366-46a8-87b2-f7ab96c32fbf",
          step: 5,
          totalEstimatedTime: 7,
          piece: 250,
          finishedPiece: 0,
          rejectedPiece: 0,
          avgActualTime: 0,
          type: "external",
          status: "passive",
          qualityStatus: "waiting",
          hasQualityControl: true,
          totalJunctionTime: 0,
          totalJunctionLength: 0,
          newAvgActualCalculate: 0,
          previousPiece: 0,
          qualityAcceptedPiece: 0,
          qualityRejectedPiece: 0,
          externalOperation: {
            estimatedTime: 7,
          },
        },
      ],
    },
    {
      order: {
        id: "69386dd2-cd38-4933-a790-a2ff6da9b37b",
        piece: 1100,
        finishedPiece: 397,
        rejectedPiece: 0,
        orderNo: "RMU0153",
        workOrderNumber: null,
        deliveryDate: "2024-04-19T21:00:00.000Z",
        product: {
          id: "759286f2-6cde-4790-a87b-199deaf71899",
          name: "YÜK AYIRICI REDÜKTÖR BRONZ BURÇ 1 /  1",
          image: "8140af6e-0836-450e-9f0c-c3c0c8d0b5c0",
        },
        rawMaterial: {
          status: "approved",
          qualityStatus: "accepted",
          orderedQuantity: 1100,
          acceptedQuantity: 1100,
          rejectedQuantity: 0,
          material: {
            source: "out",
            type: "stick",
            unit: "kilogram",
          },
        },
      },
      isProduct: true,
      operations: [
        {
          id: "M05",
          name: "M",
          step: 1,
          montage: [
            {
              order: {
                id: "6c57793d-2c6e-438d-8cb3-e8991ec17671",
                piece: 1100,
                finishedPiece: 0,
                rejectedPiece: 0,
                orderNo: "RDTHDM",
                workOrderNumber: null,
                deliveryDate: "2024-05-16T21:00:00.000Z",
                product: {
                  id: "3cefe364-c08a-4c1d-ba05-ed7e6de98896",
                  name: "YÜK AYIRICI REDÜKTÖR HELİS DİŞLİ MİLİ /  1",
                  image: "be59e8eb-f57a-4c94-8e24-7a5836686fcd",
                },
                rawMaterial: {
                  status: "approved",
                  qualityStatus: "accepted",
                  orderedQuantity: 1100,
                  acceptedQuantity: 1100,
                  rejectedQuantity: 0,
                  material: {
                    source: "out",
                    type: "stick",
                    unit: "kilogram",
                  },
                },
              },
              id: 3,
              montageId: "M05",
              operations: [
                ///
                {
                  id: "M06",
                  name: "M",
                  step: 1,
                  montage: [
                    {
                      order: {
                        id: "95e62ef4-a389-4bf5-84ad-0b724e03d299",
                        piece: 2200,
                        finishedPiece: 0,
                        rejectedPiece: 0,
                        orderNo: "DNM0002",
                        workOrderNumber: null,
                        deliveryDate: "2024-12-30T21:00:00.000Z",
                        product: {
                          id: "0cc6c155-4bfa-42f8-9608-37aee03247f1",
                          name: "Helis Dişli Pinyonu /  1",
                          image: "7cefd3b4-0edd-4f0b-a338-c172a6d23633",
                        },
                        rawMaterial: {
                          double: true,
                          status: "approved",
                          qualityStatus: "accepted",
                          orderedQuantity: 2200,
                          acceptedQuantity: 2200,
                          rejectedQuantity: 0,
                          material: {
                            source: "out",
                            type: "stick",
                            unit: "adet",
                          },
                        },
                      },
                      id: 3,
                      montageId: "M06",
                      operations: [],
                    },
                    {
                      order: {
                        id: "94e952ec-8204-4780-9005-d9009550fac1",
                        piece: 1100,
                        finishedPiece: 0,
                        rejectedPiece: 0,
                        orderNo: "DNM0017",
                        workOrderNumber: null,
                        deliveryDate: "2024-12-30T21:00:00.000Z",
                        product: {
                          id: "dbf6a08f-8c1a-4131-ac20-a6d8c7a852fb",
                          name: "Kurma Kilit Parçası (Kam Kaplini) /  1",
                          image: "5efdb09c-5cac-4ca8-8530-4a488881507b",
                        },
                        rawMaterial: {
                          status: "approved",
                          qualityStatus: "accepted",
                          orderedQuantity: 1100,
                          acceptedQuantity: 1100,
                          rejectedQuantity: 0,
                          material: {
                            source: "out",
                            type: "stick",
                            unit: "adet",
                          },
                        },
                      },
                      id: 4,
                      name: "SUB",
                      montageId: "M06",
                      operations: [],
                    },
                  ],
                },
                ///
                {
                  name: "Fosfat Kaplama",
                  id: "e961263f-7786-42c3-a2cc-74a6c25b7e2e",
                  step: 2,
                  totalEstimatedTime: 7,
                  piece: 1100,
                  finishedPiece: 0,
                  rejectedPiece: 0,
                  avgActualTime: 0,
                  type: "external",
                  status: "passive",
                  qualityStatus: "waiting",
                  hasQualityControl: true,
                  totalJunctionTime: 0,
                  totalJunctionLength: 0,
                  newAvgActualCalculate: 0,
                  previousPiece: 0,
                  qualityAcceptedPiece: 0,
                  qualityRejectedPiece: 0,
                  externalOperation: {
                    estimatedTime: 6,
                  },
                },
                {
                  name: "CNC Torna",
                  id: "7046d24e-a43d-46d9-baf1-030cbc683151",
                  step: 1,
                  totalEstimatedTime: 84000,
                  piece: 1100,
                  finishedPiece: 0,
                  rejectedPiece: 0,
                  avgActualTime: 0,
                  type: "internal",
                  status: "passive",
                  qualityStatus: "waiting",
                  hasQualityControl: true,
                  externalOperation: null,
                  totalJunctionTime: 0,
                  totalJunctionLength: 0,
                  newAvgActualCalculate: null,
                  previousPiece: 1100,
                  qualityAcceptedPiece: 0,
                  qualityRejectedPiece: 0,
                  internalOperation: {
                    estimatedTime: 120,
                  },
                },
                {
                  name: "CNC Freze",
                  id: "25a4cc4e-30ff-40fa-8a09-a49fda375543",
                  step: 3,
                  totalEstimatedTime: 24000,
                  piece: 400,
                  finishedPiece: 286,
                  rejectedPiece: 0,
                  avgActualTime: 51.97902097902098,
                  type: "internal",
                  status: "started",
                  qualityStatus: "waiting",
                  hasQualityControl: true,
                  totalJunctionTime: 21717,
                  totalJunctionLength: 1,
                  newAvgActualCalculate: 52,
                  previousPiece: 400,
                  qualityAcceptedPiece: 286,
                  qualityRejectedPiece: 0,
                  internalOperation: {
                    estimatedTime: 60,
                  },
                },
              ],
            },
            {
              order: {
                id: "4d528aae-a0d2-462d-98ee-5ff0de122e60",
                piece: 1100,
                finishedPiece: 0,
                rejectedPiece: 0,
                orderNo: "2024030450/5010-0011",
                workOrderNumber: null,
                deliveryDate: "2024-05-13T21:00:00.000Z",
                product: {
                  id: "5ed18f79-7b5b-4b8f-b56f-4ac6d4ca0714",
                  name: "Yük Ayırıcı Redüktör Eksantrik Mili /  1",
                  image: "c21d7051-16ab-4fb6-9fda-91ffdeec379a",
                },
                rawMaterial: {
                  status: "approved",
                  qualityStatus: "accepted",
                  orderedQuantity: 1100,
                  acceptedQuantity: 1100,
                  rejectedQuantity: 0,
                  material: {
                    source: "out",
                    type: "stick",
                    unit: "kilogram",
                  },
                },
              },
              id: 4,
              name: "SUB",
              montageId: "M05",
              operations: [
                {
                  name: "CNC Torna",
                  id: "7f524e14-32de-459a-ad79-fd295715737b",
                  step: 1,
                  totalEstimatedTime: 138820,
                  piece: 1100,
                  finishedPiece: 541,
                  rejectedPiece: 0,
                  avgActualTime: 347,
                  type: "internal",
                  status: "started",
                  qualityStatus: "waiting",
                  hasQualityControl: true,
                  totalJunctionTime: 35944,
                  totalJunctionLength: 1,
                  newAvgActualCalculate: 342,
                  previousPiece: 250,
                  qualityAcceptedPiece: 105,
                  qualityRejectedPiece: 0,
                  internalOperation: {
                    estimatedTime: 220,
                  },
                },
                {
                  name: "CNC Freze",
                  id: "1355f0ee-0928-4778-9336-87176fb67c6f",
                  step: 2,
                  totalEstimatedTime: 57346,
                  piece: 1100,
                  finishedPiece: 407,
                  rejectedPiece: 0,
                  avgActualTime: 231.208336768258,
                  type: "internal",
                  status: "started",
                  qualityStatus: "waiting",
                  hasQualityControl: true,
                  externalOperation: null,
                  totalJunctionTime: 131137,
                  totalJunctionLength: 5,
                  newAvgActualCalculate: 226,
                  previousPiece: 541,
                  qualityAcceptedPiece: 407,
                  qualityRejectedPiece: 0,
                  internalOperation: {
                    estimatedTime: 106,
                  },
                },
                {
                  name: "CNC Freze",
                  id: "f7c78a89-4987-4324-b5a2-6165372803aa",
                  step: 3,
                  totalEstimatedTime: 158730,
                  piece: 1100,
                  finishedPiece: 323,
                  rejectedPiece: 0,
                  avgActualTime: 565.7838427295707,
                  type: "internal",
                  status: "started",
                  qualityStatus: "waiting",
                  hasQualityControl: true,
                  totalJunctionTime: 291809,
                  totalJunctionLength: 7,
                  newAvgActualCalculate: 501,
                  previousPiece: 407,
                  qualityAcceptedPiece: 323,
                  qualityRejectedPiece: 0,
                  internalOperation: {
                    estimatedTime: 390,
                  },
                },
              ],
            },
          ],
        },
        {
          name: "CNC Torna",
          id: "49160056-2285-4d72-9f6a-e4eaf1a7df4d",
          step: 2,
          totalEstimatedTime: 66000,
          piece: 1100,
          finishedPiece: 397,
          rejectedPiece: 0,
          avgActualTime: 63.44,
          type: "internal",
          status: "started",
          qualityStatus: "waiting",
          hasQualityControl: true,
          totalJunctionTime: 62889,
          totalJunctionLength: 1,
          newAvgActualCalculate: 63,
          previousPiece: 1100,
          qualityAcceptedPiece: 397,
          qualityRejectedPiece: 0,
          internalOperation: {
            estimatedTime: 60,
          },
        },
      ],
    },
  ];
  const [openMontageRows, setOpenMontageRows] = useState([]);
  return (
    <div className="w-full">
      {data.map((item, index) => {
        const filledOperations = [
          ...(item?.operations || []),
          ...[
            ...Array.from(
              {
                length: Math.max(tableHeadItemsLength - (item?.operations || []).length + 1, 0),
              },
              () => null
            ),
          ],
        ];

        return (
          <Recursive
            item={item}
            filledOperations={filledOperations}
            index={index}
            setOpenMontageRows={setOpenMontageRows}
            openMontageRows={openMontageRows}
            tableHeadItemsLength={tableHeadItemsLength}
          />
        );
      })}
    </div>
  );
};
const Recursive = ({ item, openMontageRows, setOpenMontageRows, step, filledOperations, tableHeadItemsLength }) => {
  return (
    <>
      {item?.isProduct && <Row item={item} setOpenMontageRows={setOpenMontageRows} openMontageRows={openMontageRows} filledOperations={filledOperations} />}
      {item.operations.map((el, index) => {
        return (
          <>
            {el?.montage ? (
              <div className={`w-full flex flex-col`}>
                {el?.montage &&
                  el?.montage.map((child, i) => {
                    const filledOperations = [
                      ...(child.operations || []),
                      // ...Array.from(
                      //   {
                      //     length: Math.max(
                      //       tableHeadItemsLength -
                      //         (child.operations || []).length +
                      //         1,
                      //       0
                      //     ),
                      //   },
                      //   () => null
                      // ),
                    ];
                    return (
                      <div key={child.id} className="w-full  ">
                        <Row
                          item={child}
                          setOpenMontageRows={setOpenMontageRows}
                          openMontageRows={openMontageRows}
                          step={(Number(step) ? step : 0) + el?.step}
                          prevStep={el?.step}
                          filledOperations={filledOperations}
                        />
                        <Recursive
                          item={child}
                          setOpenMontageRows={setOpenMontageRows}
                          openMontageRows={openMontageRows}
                          step={(Number(step) ? step : 0) + el?.step}
                        />
                      </div>
                    );
                  })}
              </div>
            ) : null}
          </>
        );
      })}
    </>
  );
};

const Row = ({ item, openMontageRows, setOpenMontageRows, step, prevStep, filledOperations }) => {
  const { t } = useTranslation();
  return (
    <>
      {openMontageRows?.includes(item?.montageId) || item?.isProduct ? (
        <div className="w-full flex">
          {[...Array(Number(step) ? step : 0)].map((el, index) => {
            return <div className="flex w-[326px] min-w-[326px]"></div>;
          })}{" "}
          <div
            style={
              {
                // marginLeft: `${Number(Number(Number(step) ? step : 0) * 356)}px`,
              }
            }
            className="flex w-full h-[128px]"
          >
            <div className="max-w-[356px] min-w-[356px] h-full px-4 py-2 border-r relative">
              <NewOrderCard t={t} order={item?.order} refetch={() => {}} qrFunction={() => {}} />
              {!item?.isProduct && (
                <>
                  <div
                    style={{
                      height: `70px`,
                      width: "2px",
                      backgroundColor: "#D0D5DD",
                      position: "absolute",
                      right: `${Number(prevStep) * 320 + 350}px`,
                      top: -4,
                    }}
                  />
                  {item?.order?.rawMaterial?.double && (
                    <div
                      style={{
                        color: "#101828",
                        position: "absolute",
                        right: 350,
                        top: "30%",
                        fontSize: 14,
                      }}
                    >
                      x2
                    </div>
                  )}
                  <div
                    style={{
                      width: `${Number(prevStep) * 320}px`,
                      height: "2px",
                      backgroundColor: "#D0D5DD",
                      position: "absolute",
                      right: 350,
                    }}
                    className="top-1/2"
                  />
                  <div
                    style={{
                      position: "absolute",
                      left: 0,
                      rotate: "270deg",
                      top: 55,
                      color: "#D0D5DD",
                    }}
                  >
                    <ArrowDownIcon />
                  </div>
                </>
              )}
            </div>
            <div id="content-wrapper" className="w-full overflow-x-auto scrollbar-hide flex border-b border-gray-200">
              <>
                {step != undefined && (
                  <div className="min-w-[346px] flex items-center justify-center py-2 px-4 border-r border-gray-200">
                    <OrderPurchaseCard
                      status={item?.order?.rawMaterial?.status}
                      qualityStatus={item?.order?.rawMaterial?.qualityStatus}
                      source={item?.order?.rawMaterial?.material?.source}
                      acceptedQuantity={item?.order?.rawMaterial?.acceptedQuantity}
                      rejectedQuantity={item?.order?.rawMaterial?.rejectedQuantity}
                      type={item?.order?.rawMaterial?.material?.type}
                      t={t}
                      orderedQuantity={item?.order?.rawMaterial?.orderedQuantity}
                    />
                  </div>
                )}
              </>
              {}
              {[...filledOperations]
                .filter((el) => !el?.isPurchase)
                .map((el, index) => {
                  const operationName = el?.name;
                  const occurrences = filledOperations.slice(0, index).filter((op) => op && op.name === operationName).length + 1;
                  return (
                    <div key={index} className="min-w-[346px] flex items-center justify-center py-2 px-4 border-r border-gray-200">
                      {!el?.isPurchase ? (
                        el?.montage ? (
                          <div
                            className="w-full"
                            onClick={() => {
                              if (openMontageRows?.includes(el?.id)) {
                                if (openMontageRows[0] == el?.id) {
                                  setOpenMontageRows([]);
                                } else {
                                  setOpenMontageRows(openMontageRows?.filter((e) => e !== el?.id));
                                }
                              } else {
                                setOpenMontageRows([...openMontageRows, el?.id]);
                              }
                            }}
                          >
                            <AssemblyCard
                              hoursJunctionTime={36}
                              hours={120}
                              operations={{
                                previousPiece: 90,
                                qualityAcceptedPiece: 0,
                                hasQualityControl: true,
                                qualityAcceptedPiece: 0,
                                qualityRejectedPiece: 0,
                                internalOperation: {
                                  estimatedTime: 800,
                                },
                                newAvgActualCalculate: 686,
                                estimatedTime: 2079,
                                totalJunctionLength: 2,
                              }}
                            />
                          </div>
                        ) : (
                          el && (
                            <>
                              {el?.type === "internal" ? (
                                <InternalOperationCard
                                  status={el?.status}
                                  iconKey={el?.iconKey}
                                  name={occurrences > 1 ? `${operationName} ${occurrences}` : operationName}
                                  totalJunctionTime={el?.totalJunctionTime}
                                  totalEstimatedTime={el?.totalEstimatedTime}
                                  orderData={item.order}
                                  operations={el}
                                />
                              ) : (
                                <ExternalOperationCard
                                  status={el?.status}
                                  iconKey={el?.iconKey}
                                  name={occurrences > 1 ? `${operationName} ${occurrences}` : operationName}
                                  orderData={item.order}
                                  operations={el}
                                />
                              )}
                            </>
                          )
                        )
                      ) : null}
                    </div>
                  );
                })}
              {[...Array(Number(step) ? 11 - filledOperations?.length : 0)].map((el, index) => {
                return <div className="min-w-[346px] flex items-center justify-center py-2 px-4 border-r border-gray-200"></div>;
              })}{" "}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default MontageTest;

import React, { useEffect, useState, useRef } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import Badgets from "../../../components/buttons/Badgets";
import { productService } from "../../../services/product.service";
import { Toast } from "../../../utils/toastify/toast";
import { CustomModal } from "../../../components";
import { useTranslation } from "react-i18next";
import CopyProductModal from "../modals/CopyProductModal";
import { DangerIcon } from "../../../assets/icons/machineIcons";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";
import AssemblyModal from "../modals/AssemblyModal";
import generateFileUrl from "../../../utils/generateFileUrl";
import MultiOperationModal from "../modals/MultiOpModal/MultiOperationModal";
import { RecipeClose, RecipeEdit } from "../NewFlexProduct";
import { processService } from "../../../services";
import { externalOperationService } from "../../../services/external-operation.service";

const SortableItem = SortableElement(
  ({
    value,
    setIsDeleteOperation,
    setSelectedOperation,
    setIsOpenAddOperationModal,
    setStepOneData,
    setStepTwoData,
    setStepThreeData,
    internalProcess,
    externalProcess,
  }) => {
    const { t } = useTranslation();
    const menuRef = useRef();

    const operationTypes = (type) => {
      switch (type) {
        case "conversion_operation":
          return "DÖN";
        case "supporter_operation":
          return "YAR";
        case "assembly_operation":
          return "MON";
        case "decomposition_operation":
          return "AYR";
        default:
          return "DÖN";
      }
    };

    const getOperations = async (type, processId, operations) => {
      if (type == "internal") {
        const filtered = internalProcess.filter((item) => item.value == processId);
        const filteredStations = filtered[0]?.stations;
        const mergeData = filteredStations?.filter((item) => operations?.some((op) => op.id === item.id));
        const data = mergeData?.map((item) => {
          const operation = operations.find((op) => op.id === item.id);
          return {
            ...item,
            ...operation,
          };
        });
        return data;
      } else {
        const filtered = externalProcess.filter((item) => item.value == processId);
        const filteredStations = filtered[0]?.supplier;
        const mergeData = filteredStations?.filter((item) => operations?.some((op) => op.id === item.id));
        const data = mergeData?.map((item) => {
          const operation = operations.find((op) => op.id === item.id);
          return {
            ...item,
            ...operation,
          };
        });
        return data;
      }
    };
    const calculateAverageActualTime = (data) => {
      const validActualTimes = data?.map((item) => item?.avgActualTime)?.filter((time) => typeof time === "number" && !isNaN(time));
      const total = validActualTimes.reduce((acc, curr) => acc + curr, 0);
      const average = data?.length > 0 ? total / data?.length : 0;
      return average;
    };
    const calculateAverageEstimatedTime = (data) => {
      const validEstimatedTimes = data?.map((item) => item?.estimatedTime)?.filter((time) => typeof time === "number" && !isNaN(time));
      const total = validEstimatedTimes.reduce((acc, curr) => acc + curr, 0);
      const average = data?.length > 0 ? total / data?.length : 0;
      return average;
    };
    const averageEstimatedTimeInternal = calculateAverageEstimatedTime(value?.filter((a) => a?.type == "internal"));
    const averageActualTimeInternal = calculateAverageActualTime(value?.filter((a) => a?.type == "internal"));
    const averageEstimatedTimeExternal = calculateAverageEstimatedTime(value?.filter((a) => a?.type == "external"));
    return (
      <div ref={menuRef} className="select-none">
        <div className="flex flex-col w-[300px] gap-y-2">
          <div className="flex flex-row items-center w-full">
            <Badgets colorType={"fill-warning"} size={"sm"} label={value[0]?.step + ".Op"} />
            <div className="items-center gap-x-2 ml-auto flex">
              <span
                onClick={async () => {
                  setIsOpenAddOperationModal(true);
                  if (value[0]) {
                    setStepOneData({
                      id: value[0]?.id,
                      operationType: value[0]?.type,
                      operation: value[0]?.process?.id,
                      enabled: value[0]?.hasQualityControl,
                      qualityPercent: value[0]?.qualityPercent,
                      description: value[0]?.description,
                      step: value[0]?.step,
                      estimatedTime: value[0]?.estimatedTime,
                      estimatedSettingsTime: value[0]?.estimatedSettingsTime,
                      costOfMinute: value[0]?.costOfMinute ? value[0]?.costOfMinute : value[0]?.costOfPiece,
                      currency: value[0]?.currency,
                      isAllSame: true,
                      operations: {
                        value: value[0]?.process?.id,
                        label: value[0]?.process?.name,
                        stations: await getOperations(value[0]?.type, value[0]?.process?.id, value[0]?.plannableNodes || value[0]?.plannableSupplier),
                        supplier: await getOperations(value[0]?.type, value[0]?.process?.id, value[0]?.plannableNodes || value[0]?.plannableSupplier),
                      },
                    });
                  }
                  if (value[1]) {
                    setStepTwoData({
                      id: value[1]?.id,
                      operationType: value[1]?.type,
                      operation: value[1]?.process?.id,
                      enabled: value[1]?.hasQualityControl,
                      qualityPercent: value[1]?.qualityPercent,
                      description: value[1]?.description,
                      step: value[1]?.step,
                      estimatedTime: value[1]?.estimatedTime,
                      estimatedSettingsTime: value[1]?.estimatedSettingsTime,
                      costOfMinute: value[1]?.costOfMinute ? value[1]?.costOfMinute : value[1]?.costOfPiece,
                      currency: value[1]?.currency,
                      isAllSame: true,
                      operations: {
                        value: value[1]?.process?.id,
                        label: value[1]?.process?.name,
                        stations: await getOperations(value[1]?.type, value[1]?.process?.id, value[1]?.plannableNodes || value[1]?.plannableSupplier),
                        supplier: await getOperations(value[1]?.type, value[1]?.process?.id, value[1]?.plannableNodes || value[1]?.plannableSupplier),
                      },
                    });
                  }
                  if (value[2]) {
                    setStepThreeData({
                      id: value[2]?.id,
                      operationType: value[2]?.type,
                      operation: value[2]?.process?.id,
                      enabled: value[2]?.hasQualityControl,
                      qualityPercent: value[2]?.qualityPercent,
                      description: value[2]?.description,
                      step: value[2]?.step,
                      estimatedTime: value[2]?.estimatedTime,
                      estimatedSettingsTime: value[2]?.estimatedSettingsTime,
                      costOfMinute: value[2]?.costOfMinute ? value[2]?.costOfMinute : value[2]?.costOfPiece,
                      currency: value[2]?.currency,
                      isAllSame: true,
                      operations: {
                        value: value[2]?.process?.id,
                        label: value[2]?.process?.name,
                        stations: await getOperations(value[2]?.type, value[2]?.process?.id, value[2]?.plannableNodes || value[2]?.plannableSupplier),
                        supplier: await getOperations(value[2]?.type, value[2]?.process?.id, value[2]?.plannableNodes || value[2]?.plannableSupplier),
                      },
                    });
                  }
                }}
                className="flex min-w-[20px] max-w-[20px] w-[20px] min-h-[20px] max-h-[20px] h-[20px] cursor-pointer"
              >
                <RecipeEdit />
              </span>
              <span
                onClick={() => {
                  setSelectedOperation(value);
                  setIsDeleteOperation(true);
                }}
                className="flex min-w-[20px] max-w-[20px] w-[20px] min-h-[20px] max-h-[20px] h-[20px] cursor-pointer"
              >
                <RecipeClose />
              </span>
            </div>
          </div>
          <div className={`flex w-[310px] h-[112px] flex-col border items-start cursor-grap  rounded-lg border-secondary-300  bg-white relative group`}>
            {value?.length == 1 ? (
              <>
                {value?.map((b) => {
                  return (
                    <div className="flex flex-row items-center w-[100%]  justify-between pr-1 py-2 pl-3">
                      <div className="flex flex-row items-center gap-x-0.5 h-5 ">
                        {b?.type === "external" ? (
                          <div className="px-1  border h-full w-fit rounded bg-[#F2F4F7]">
                            <p className="text-sm font-semibold text-secondary-700">{"HİZ"}</p>
                          </div>
                        ) : (
                          <div className="px-1 ml-1 border h-full w-fit rounded bg-[#F2F4F7]">
                            <p className="text-sm font-semibold text-secondary-700">{b?.process?.type ? operationTypes(b?.process?.type) : "-"}</p>
                          </div>
                        )}
                        <div className=" bg-white max-w-[230px] ml-1 rounded-r-[8px]">
                          <p className="font-semibold text-sm text-secondary-700  truncate">{b?.process?.name}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="flex flex-row items-center gap-x-2 pr-1 py-1.5 pl-3 w-[290px] min-w-[290px] max-w-[290px] ">
                {value?.map((a) => {
                  return (
                    <div className={`flex border items-center w-1/3 h-6 gap-x-1 flex-row rounded`}>
                      <div className="px-1 bg-[#F2F4F7] w-fit border-r h-full flex items-center">
                        {a?.type === "external" ? (
                          <p className="text-xs font-semibold text-secondary-700">{"HİZ"}</p>
                        ) : (
                          <p className="text-xs font-semibold text-secondary-700">{a?.process?.type ? operationTypes(a?.process?.type) : "-"}</p>
                        )}
                      </div>
                      <div className="bg-white truncate px-1">
                        <p className="font-semibold text-xs text-secondary-700  truncate">{a?.process?.name}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div className="flex flex-row items-start w-full border-b border-t bg-[#F9FAFB]">
              <div className="px-3 py-[3px] w-full">
                <p className="text-xs font-medium text-secondary-700">{t("product:cycleTime")}</p>
              </div>
            </div>
            <div className="flex flex-col items-center px-2 pt-1 w-full">
              <div className="flex flex-row items-center w-full justify-between">
                <p className="text-xs font-normal text-secondary-500">{t("product:target")}</p>
                <p className="text-sm max-w-[220px] truncate font-medium text-secondary-600">
                  {value?.filter((a) => a?.type == "internal")?.length > 0 && convertSeconds(averageEstimatedTimeInternal)}
                  {(value?.filter((a) => a?.type == "external")?.length > 0 && value?.filter((a) => a?.type == "internal")?.length > 0) && ' / '}
                  {value?.filter((a) => a?.type == "external")?.length > 0 && convertDays(averageEstimatedTimeExternal)}
                </p>
              </div>
              <div className="flex flex-row items-center w-full justify-between">
                <p className="text-xs font-normal text-secondary-500">uygulanan</p>
                <div className="text-sm truncate flex justify-end">
                  <p className={`text-sm font-medium ${averageEstimatedTimeInternal > averageActualTimeInternal ? 'text-[#079455]' : 'text-red-500'}`}>
                    {value?.filter((a) => a?.type == "internal")?.length > 0 && convertSeconds(averageActualTimeInternal)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[30px] h-px bg-gray-300 absolute -right-[0px] bottom-[56px]" />
        </div>
      </div>
    );
  }
);

const SortableList = SortableContainer(
  ({
    items,
    setSelectedOperation,
    setIsDeleteOperation,
    hasOrders,
    setIsOpen,
    setIsOpenAddOperationModal,
    setStepOneData,
    setStepTwoData,
    setStepThreeData,
    internalProcess,
    externalProcess,
  }) => {
    const [data, setData] = useState([]);
    const [selectedAssemblyId, setSelectedAssemblyId] = useState(null);

    return (
      <div className="w-full flex flex-row gap-y-8 h-full">
        {items?.map((value, index) => (
          <div className="flex flex-col relative ">
            <div
              onClick={async () => {
                if (value?.process?.type === "assembly_operation") {
                  setSelectedAssemblyId(value?.id);
                  if (data?.length > 1) {
                    setData([]);
                  } else {
                    await productService.getAssemblyParts(value?.id).then(({ data }) => {
                      setData(data);
                    });
                  }
                }
              }}
              className="relative group w-[340px] flex flex-col"
            >
              <SortableItem
                key={String(index)}
                index={index}
                value={value}
                number={index}
                dataIndex={items?.length}
                setIsDeleteOperation={setIsDeleteOperation}
                setIsOpenAddOperationModal={setIsOpenAddOperationModal}
                setStepOneData={setStepOneData}
                setStepTwoData={setStepTwoData}
                setStepThreeData={setStepThreeData}
                internalProcess={internalProcess}
                externalProcess={externalProcess}
                setSelectedOperation={setSelectedOperation}
              />
            </div>
            <div className="flex flex-col absolute top-[102px] gap-[22px]">
              {data?.map((item, index) => {
                return <Row selectedAssemblyId={selectedAssemblyId} data={data} value={value} item={item} key={index} index={index} />;
              })}
            </div>
          </div>
        ))}
        <div
          className="h-[112px] flex mt-[30px]"
          onClick={() => {
            if (hasOrders) {
              setIsOpen(true);
            } else {
              setSelectedOperation(null);
              setIsOpenAddOperationModal(true);
            }
          }}
        >
          <div className="w-[300px] h-[112px] border border-gray-400 rounded-lg flex items-center justify-center cursor-pointer shadow border-dashed">
            <PlusIcon />
          </div>
        </div>
      </div>
    );
  }
);

const StockOperationGrid = ({ refetch, stockId, items, isLoading, setItems, hasOrders, copyData }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOperation, setSelectedOperation] = useState({});
  const [isDeleteOperation, setIsDeleteOperation] = useState(false);
  const [isOpenAddOperationModal, setIsOpenAddOperationModal] = useState(false);
  const [isFirstModal, setIsFirstModal] = useState(true);
  const [isAssemblyModalOpen, setIsAssemblyModalOpen] = useState(false);
  const [internalProcess, setInternalProcess] = useState([]);
  const [externalProcess, setExternalProcess] = useState([]);
  // MULTIPLE OPERATION MODAL
  const defaultStepData = {
    isAllSame: true,
    type: "",
    processId: "",
    processName: "Yeni Operasyon Ekle",
    hasQaulityControl: false,
    qualityPercent: "",
    description: "",
    step: "",
    estimatedTime: "",
    estimatedSettingsTime: "",
    costOfMinute: "",
    currency: "",
    process: {
      id: "",
    },
    plannableSupliers: [],
    plannableNodes: [],
  };
  const [isOpenModalTab, setIsOpenModalTab] = useState(false);
  const [stepOneData, setStepOneData] = useState(defaultStepData);
  const [stepTwoData, setStepTwoData] = useState(defaultStepData);
  const [stepThreeData, setStepThreeData] = useState(defaultStepData);

  const groupByStepArray = (arr) => {
    let grouped = {};
    if (arr) {
      grouped = arr.reduce((acc, curr) => {
        const step = curr.step;
        if (!acc[step]) {
          acc[step] = [];
        }
        acc[step].push(curr);
        return acc;
      }, {});
    }

    return Object.keys(grouped).map((step) => grouped[step]);
  };

  const groupedValuesArray = groupByStepArray(items);

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    const Items = arrayMoveImmutable(groupedValuesArray, oldIndex, newIndex);
    const updatedItems = Items.map((group, groupIndex) => {
      return group.map((item) => ({
        ...item,
        step: groupIndex + 1,
      }));
    });
    if (JSON.stringify(groupedValuesArray) === JSON.stringify(updatedItems)) {
      return;
    }
    await productService.updateOperation(stockId, updatedItems.flat()).then((res) => {
      if (res?.data?.code === 0) {
        setItems(updatedItems.flat());
        Toast("success", res?.data?.message);
        refetch();
      } else if (res?.data?.code === 1) {
        Toast("error", res?.data?.message);
        setIsOpen(true);
      } else {
        Toast("error", res?.data?.message);
      }
    });
  };

  const deleteOperation = async () => {
    const promises = selectedOperation.map(async (operation) => {
      await productService.deleteOperation(stockId, operation?.id, operation?.type).then((res) => {
        if (res?.data?.code === 0) {
          Toast("success", res?.data?.message);
          refetch();
        } else if (res?.data?.code === 1) {
          Toast("error", res?.data?.message);
          setIsOpen(true);
        } else {
          Toast("error", res?.data?.message);
        }
      });
    });

    await Promise.all(promises);

    setIsDeleteOperation(false);
  };

  const handleInternalProcessUpdate = async () => {
    let datas = [];
    await processService.activeProcessesWithStations().then((res) => {
      const newTabsData = res?.data.map((item) => ({
        stations: item.stations,
        supplier: item.supplier,
        label: item.name,
        value: item.id,
        operationType: item.type,
      }));
      datas = newTabsData || [];
    });

    return await setInternalProcess(datas);
  };

  const handleExternalProcessUpdate = async (index) => {
    let datas = [];

    await externalOperationService.listOperation().then((res) => {
      const newTabsData = res?.data.map((item) => ({
        stations: item.stations,
        supplier: item.supplier,
        label: item.name,
        value: item.id,
      }));
      datas = newTabsData || [];
    });

    return await setExternalProcess(datas);
  };
  useEffect(() => {
    handleInternalProcessUpdate();
    handleExternalProcessUpdate();
  }, []);

  return (
    <div className="w-full pt-6 flex h-full">
      <div className="h-full flex w-full overflow-x-auto overflow-y-visible">
        <SortableList
          axis="xy"
          items={groupedValuesArray}
          onSortEnd={onSortEnd}
          distance={1}
          setSelectedOperation={setSelectedOperation}
          setIsDeleteOperation={setIsDeleteOperation}
          hasOrders={hasOrders}
          setIsOpen={setIsOpen}
          setIsOpenAddOperationModal={setIsOpenAddOperationModal}
          setStepOneData={setStepOneData}
          setStepTwoData={setStepTwoData}
          setStepThreeData={setStepThreeData}
          internalProcess={internalProcess}
          externalProcess={externalProcess}
        />
      </div>
      <CustomModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        modalTitle={t("product:updateProduct")}
        width="fit-content"
        children={
          <CopyProductModal t={t} setIsOpen={setIsOpen} label={t("buttons:copy")} text={t("product:confirmText")} stockId={stockId} copyData={copyData} />
        }
      />
      <CustomModal
        onClose={() => {
          setStepOneData(defaultStepData);
          setStepTwoData(defaultStepData);
          setStepThreeData(defaultStepData);
          setIsOpenAddOperationModal(false);
        }}
        width={900}
        isOpen={isOpenAddOperationModal}
        setIsOpen={setIsOpenAddOperationModal}
        modalTitle={t("product:newRecipeStep")}
        titleButton={false}
        buttonColorType={"tertiary-error"}
        buttonIcon={<PlusIcon />}
        buttonSize={"xl"}
        buttonOnClick={() => setIsOpenModalTab(true)}
        buttonClassName={"pt-0 pb-0 hover:bg-[#FFF]"}
        buttonLabel={t("product:createMultiple")}
        children={
          <MultiOperationModal
            items={items}
            setItems={setItems}
            stepOneData={stepOneData}
            setStepOneData={setStepOneData}
            setStepTwoData={setStepTwoData}
            stepTwoData={stepTwoData}
            setStepThreeData={setStepThreeData}
            stepThreeData={stepThreeData}
            defaultStepData={defaultStepData}
            onClose={() => {
              setStepOneData(defaultStepData);
              setStepTwoData(defaultStepData);
              setStepThreeData(defaultStepData);
              setIsOpenAddOperationModal(false);
            }}
            setIsOpenModalTab={setIsOpenModalTab}
            isOpenModalTab={isOpenModalTab}
            productId={stockId}
            refetch={refetch}
            groupedItemsLength={groupedValuesArray.length}
          />
        }
      />
      <CustomModal
        isOpen={isAssemblyModalOpen}
        setIsOpen={setIsAssemblyModalOpen}
        modalTitle={t("product:mergeOperationSteps")}
        subTitle={isFirstModal ? t("product:generalInformation") : t("product:selectMinMaterials")}
        height={710}
        width={isFirstModal ? 500 : 744}
        children={
          <AssemblyModal
            selectedProduct={{ id: stockId }}
            setIsFirstModal={setIsFirstModal}
            isFirstModal={isFirstModal}
            closeModal={() => {
              setIsAssemblyModalOpen(false);
            }}
          />
        }
      />
      <AlertModal
        isOpen={isDeleteOperation}
        setIsOpen={setIsDeleteOperation}
        title={t("product:deleteOperation")}
        subTitle={t("product:cannotBeUndone")}
        applyButton={deleteOperation}
        icon={<DangerIcon />}
      />
    </div>
  );
};

const Row = ({ selectedAssemblyId, value, data, item, index }) => {
  const externalOP = value?.type === "external" ? true : false;
  const [secondaryData, setSecondaryData] = useState([]);
  const [findOther, setFindOther] = useState(null);
  const [id, setId] = useState(null);
  const { t } = useTranslation();
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
  };

  return selectedAssemblyId === value?.id && data?.length > 0 ? (
    <div className="flex flex-col">
      <div className="flex  gap-[40px] relative">
        <div className="flex flex-col items-center relative">
          {findOther ? (
            <div
              style={{
                height: findOther === index ? `${secondaryData?.length * 120}px` : "22px",
                top: findOther === index ? `-${secondaryData?.length * 120}px` : "-22px",
              }}
              className={`w-px bg-gray-300 absolute`}
            />
          ) : (
            <div className="w-px bg-gray-300 h-[22px] absolute -top-[22px]" />
          )}
          <div className="flex flex-col w-[300px] h-[80px] border px-2 py-2 gap-2 border-gray-300 rounded-lg relative bg-white shadow">
            <div className="flex w-full">
              <img className="w-[56px] h-[42px] rounded-md" src={generateFileUrl(item?.product?.image)} />
              <div className="flex flex-col flex-1 ml-2">
                <p className="truncate text-sm font-semibold text-secondary-900">{item?.product?.name}</p>
                <p className="truncate text-xs font-medium text-secondary-500">{"Stock ID: " + item?.product?.stockCode}</p>
              </div>
            </div>
            <div className="flex w-full items-center">
              <div className="flex items-center w-1/2">
                <QtyIcon color={"#475467"} />
                <p className="text-xs font-medium text-secondary-600 ml-1">{"---"}</p>
                <p className="text-[10px] text-gray-500 ml-1">top. stok</p>
              </div>

              <div className="flex items-center w-1/2">
                <QtyIcon color={"#B54708"} />
                <p className="text-xs font-medium text-[#B54708] ml-1">{"x" + item?.quantity}</p>
                <p className="text-[10px] text-gray-500 ml-1">miktar</p>
              </div>
            </div>
          </div>
        </div>
        {[...item?.product?.internalOperations, ...item?.product?.externalOperations].map((a, b) => {
          return (
            <div className="relative">
              <div
                onClick={async () => {
                  if (a?.processType === "assembly_operation") {
                    const finder = data?.findIndex((element) => {
                      return element.id === item?.id;
                    });
                    setFindOther(finder);
                    setId(a?.id);
                    if (secondaryData.length > 1) {
                      setSecondaryData([]);
                    } else {
                      await productService.getAssemblyParts(a?.id).then(({ data }) => {
                        setSecondaryData(data);
                      });
                    }
                  }
                }}
                className="relative"
              >
                <div className="w-[40px] h-px bg-gray-300 absolute -left-[40px] bottom-10" />
                <div key={b} className={`flex flex-col w-[300px] h-[80px] border px-3 py-2  border-gray-300 rounded-lg relative bg-white shadow`}>
                  <div className="absolute top-2 right-2">
                    <Badgets colorType={"fill-warning"} size={"sm"} label={a?.step + ".Op"} />
                  </div>
                  <div className="flex flex-row  gap-x-2 w-[90%]">
                    <div className="w-5 h-5">
                      {a?.processType === "assembly_operation" ? <TransformOpIcon /> : a?.type === "internal" ? <InternalIcon /> : <ExternalIcon />}
                    </div>
                    <span className="text-sm font-semibold text-[#344054]  truncate w-[97%]">{a?.process?.name}</span>
                  </div>
                  {externalOP ? (
                    <>
                      <div className="flex flex-col items-start w-[85%] mt-[2px]">
                        <div className="flex border-b w-full">
                          <div className="min-w-[90px] w-[90px] border-r pr-[6px] py-[2px] text-xs font-normal text-[#475467]">
                            {t("product:singleProcess")}
                          </div>
                          <div className="w-full  px-[6px] py-[2px] flex gap-x-[2px] items-end">
                            <span className="font-medium text-xs text-[#475467]">{a?.madeTransaction}</span>
                          </div>
                        </div>
                        <div className="flex">
                          <div className="min-w-[90px] w-[90px] border-r pr-[6px] py-[2px] text-xs font-normal text-[#475467]">
                            {t("product:estimatedTimes")}
                          </div>
                          <div className="w-full  px-[6px] py-[2px] flex gap-x-[2px] items-end">
                            <span className="text-xs  font-medium text-[#667085]">
                              {a?.estimatedTime} {t("days:day")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : a?.processType === "assembly_operation" ? (
                    <>
                      <div className="flex flex-col items-start w-[85%] gap-y-1">
                        <div className="flex flex-row items-center gap-x-3 w-full">
                          <div className="flex flex-row gap-x-1 items-center mt-1 w-full">
                            <TimeIcon />
                            <p className="text-xs font-medium text-secondary-700">{formatTime(a?.estimatedTime)}</p>
                            <p className="text-[10px] font-normal text-secondary-600">{t("product:targetMin")}</p>
                          </div>
                          <div className="flex flex-row gap-x-1 items-center mt-1 w-full">
                            <SquareIcon />
                            <p className="text-xs font-medium text-[#079455]">{a?.costOfMinute}</p>
                            <p className="text-[10px] font-normal text-secondary-600">{t("product:levels")}</p>
                          </div>
                        </div>

                        <div className="flex flex-row gap-x-1 items-center w-full">
                          <PieceIcon />
                          <p className="text-xs font-medium text-secondary-700">{a?.costOfMinute}</p>
                          <p className="text-[10px] font-normal text-secondary-600">{t("product:subProductsNumber")}</p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex flex-col items-start w-[85%] gap-y-1">
                        <div className="flex flex-row gap-x-1 items-center mt-1 w-full">
                          <TimeIcon />
                          <p className="text-xs font-medium text-secondary-700">{formatTime(a?.estimatedTime)}</p>
                          <p className="text-[10px] font-normal text-secondary-600">{t("product:targetMin")}</p>
                        </div>
                        <div className="flex flex-row gap-x-1 items-center w-full">
                          <SquareIcon />
                          <p className="text-xs font-medium text-[#079455]">{a?.costOfMinute}</p>
                          <p className="text-[10px] font-normal text-secondary-600">{t("product:levels")}</p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {secondaryData.length > 0 ? (
                <>
                  <div className="flex flex-col top-[102px] gap-[22px] mt-[20px]">
                    {secondaryData?.map((i, _) => {
                      return <Row data={secondaryData} selectedAssemblyId={id} value={a} item={i} />;
                    })}
                  </div>
                </>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};

export default StockOperationGrid;

const PlusIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path d="M24 10V38M10 24H38" stroke="#98A2B3" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const QtyIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M5.75 2.375H3.71429C2.76751 2.375 2 3.14251 2 4.08929V11.9107C2 12.8575 2.76751 13.625 3.71429 13.625H12.2857C13.2325 13.625 14 12.8575 14 11.9107V4.08929C14 3.14251 13.2325 2.375 12.2857 2.375H10.25M5.75 2.375V6.73631C5.75 6.80003 5.81706 6.84147 5.87405 6.81298L8 5.75L10.126 6.81298C10.1829 6.84147 10.25 6.80003 10.25 6.73631V2.375M5.75 2.375H10.25"
        stroke={color}
      />
    </svg>
  );
};

const PieceIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        d="M4.3125 1.78125H2.78571C2.07563 1.78125 1.5 2.35688 1.5 3.06696V8.93304C1.5 9.64312 2.07563 10.2187 2.78571 10.2187H9.21429C9.92437 10.2187 10.5 9.64312 10.5 8.93304V3.06696C10.5 2.35688 9.92437 1.78125 9.21429 1.78125H7.6875M4.3125 1.78125V5.05223C4.3125 5.10002 4.36279 5.1311 4.40554 5.10973L6 4.3125L7.59446 5.10973C7.63721 5.1311 7.6875 5.10002 7.6875 5.05223V1.78125M4.3125 1.78125H7.6875"
        stroke="#475467"
      />
    </svg>
  );
};

const SquareIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path d="M5.57676 4.07251V6.32892L4.1665 7.17507" stroke="#079455" stroke-linecap="round" stroke-linejoin="round" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.0932 6.68412C11.1206 6.45957 11.1346 6.23091 11.1346 5.99896C11.1346 2.90291 8.6248 0.393066 5.52875 0.393066C2.4327 0.393066 -0.0771484 2.90291 -0.0771484 5.99896C-0.0771484 9.09502 2.4327 11.6049 5.52875 11.6049C6.0331 11.6049 6.52189 11.5383 6.98682 11.4134V10.3693C6.52859 10.5221 6.03834 10.6049 5.52875 10.6049C2.98498 10.6049 0.922852 8.54273 0.922852 5.99896C0.922852 3.4552 2.98498 1.39307 5.52875 1.39307C8.07252 1.39307 10.1346 3.4552 10.1346 5.99896C10.1346 6.00767 10.1346 6.01637 10.1346 6.02507L11.0932 6.68412Z"
        fill="#079455"
      />
      <path
        d="M8.11523 6.8346C8.11523 6.60307 8.11523 6.48731 8.16351 6.4235C8.20556 6.3679 8.26984 6.3335 8.33943 6.32935C8.4193 6.32458 8.51562 6.38879 8.70826 6.51722L11.2152 8.18849C11.3743 8.2946 11.4539 8.34766 11.4817 8.41454C11.5059 8.47301 11.5059 8.53872 11.4817 8.59718C11.4539 8.66406 11.3743 8.71712 11.2152 8.82324L8.70826 10.4945C8.51562 10.6229 8.4193 10.6871 8.33943 10.6824C8.26984 10.6782 8.20556 10.6438 8.16351 10.5882C8.11523 10.5244 8.11523 10.4087 8.11523 10.1771V6.8346Z"
        stroke="#079455"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const TimeIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <g clip-path="url(#clip0_13334_10332)">
        <path
          d="M6 3V6L8 7M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
          stroke="#475467"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs></defs>
    </svg>
  );
};

const TransformOpIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M14.3847 0.666748H3.61501C1.9866 0.666748 0.666504 1.98684 0.666504 3.61526V14.3849C0.666504 16.0133 1.9866 17.3334 3.61501 17.3334H14.3847C16.0131 17.3334 17.3332 16.0133 17.3332 14.3849V3.61526C17.3332 1.98684 16.0131 0.666748 14.3847 0.666748Z"
        stroke="#475467"
        stroke-width="1.25"
        stroke-miterlimit="10"
      />
      <path d="M14.6114 9H3.38818" stroke="#475467" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" />
      <path
        d="M7.07031 3.99145L8.9476 5.86877C8.97639 5.89756 9.02306 5.89756 9.05185 5.86877L10.9291 3.99145"
        stroke="#475467"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M10.9291 14.0085L9.05185 12.1313C9.02306 12.1025 8.97639 12.1025 8.9476 12.1313L7.07031 14.0085"
        stroke="#475467"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </svg>
  );
};

const ExternalIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M11.404 7.55701C11.3215 7.47451 11.2802 7.43326 11.2666 7.38391C11.2545 7.34049 11.257 7.29292 11.2737 7.248C11.2927 7.19693 11.3385 7.15109 11.4302 7.05942L14.7693 3.72031L13.5908 2.54179L17.9447 2.31264L17.7156 6.66658L16.5371 5.48807L13.1979 8.82719C13.1063 8.91886 13.0604 8.96469 13.0094 8.98365C12.9644 9.00033 12.9169 9.00283 12.8735 8.9908C12.8241 8.97713 12.7828 8.93588 12.7003 8.85338L11.404 7.55701Z"
        stroke="#344054"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.09228 7.94661C6.99225 9.04665 6.99227 10.8302 8.09231 11.9302C9.19234 13.0303 10.9759 13.0303 12.0759 11.9302M10.5979 2.68042L10.1888 1.78127C10.0438 1.4626 9.68974 1.29547 9.35157 1.38606L6.4423 2.1656C6.10416 2.25622 5.88107 2.578 5.91485 2.92645L6.0102 3.90971C6.11892 5.03127 5.17696 5.97323 4.0554 5.86451L3.07214 5.76916C2.72369 5.73538 2.40191 5.95847 2.31129 6.29661L1.53175 9.20588C1.44116 9.54405 1.60829 9.89815 1.92696 10.0431L2.82611 10.4522C3.85178 10.9188 4.19658 12.2055 3.54162 13.1225L2.96745 13.9263C2.76398 14.2112 2.79626 14.6014 3.04378 14.849L5.17357 16.9787C5.42109 17.2263 5.81134 17.2585 6.09619 17.0551L6.90003 16.4809C7.81698 15.8259 9.10372 16.1707 9.57034 17.1964L9.97942 18.0956C10.1244 18.4142 10.4785 18.5814 10.8166 18.4908L13.7259 17.7112C14.0641 17.6206 14.2871 17.2988 14.2534 16.9504L14.158 15.9671C14.0493 14.8456 14.9913 13.9036 16.1128 14.0123L17.0961 14.1077C17.4445 14.1415 17.7663 13.9184 17.8569 13.5802L18.6365 10.671C18.7271 10.3328 18.5599 9.97869 18.2413 9.83373"
        stroke="#344054"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const InternalIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M14.3847 0.666748H3.61501C1.9866 0.666748 0.666504 1.98684 0.666504 3.61526V14.3849C0.666504 16.0133 1.9866 17.3334 3.61501 17.3334H14.3847C16.0131 17.3334 17.3332 16.0133 17.3332 14.3849V3.61526C17.3332 1.98684 16.0131 0.666748 14.3847 0.666748Z"
        stroke="#475467"
        stroke-width="1.25"
        stroke-miterlimit="10"
      />
      <path d="M14.6114 9H3.38818" stroke="#475467" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" />
      <path
        d="M11.9482 6.05147C11.9482 6.05147 11.9101 5.78318 10.8063 4.67284C9.70241 3.5625 7.91269 3.5625 6.80883 4.67284C6.41773 5.06624 6.16519 5.54624 6.05123 6.05147M11.9482 6.05147V4.156M11.9482 6.05147H10.0638"
        stroke="#475467"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.05176 11.9485C6.05176 11.9485 6.08986 12.2168 7.19372 13.3272C8.29759 14.4375 10.0873 14.4375 11.1912 13.3272C11.5823 12.9338 11.8348 12.4538 11.9488 11.9485M6.05176 11.9485V13.844M6.05176 11.9485H7.93617"
        stroke="#475467"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const convertSeconds = (seconds) => {
  if (isNaN(seconds) || seconds == Infinity) return "---";
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Number(seconds % 60).toFixed(0);
  let formattedTime = "";
  if (minutes > 0) {
    formattedTime += minutes + " dk ";
  }
  if (remainingSeconds > 0) {
    formattedTime += remainingSeconds + " sn";
  }
  return formattedTime.trim();
};

const convertDays = (days) => {
  if (isNaN(days) || days == Infinity) return "---";
  const weeks = Math.floor(days / 7);
  const remainingDays = Number(days % 7).toFixed(0);
  let formattedTime = "";
  if (weeks > 0) {
    formattedTime += weeks + " week ";
  }
  if (remainingDays > 0) {
    formattedTime += remainingDays + " day";
  }
  return formattedTime.trim();
};
import React from "react";
import CustomImage from "../../../components/CustomImage";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import { DecraceStockIcon, FreeStockIcon, NewDotsIcon, RezervStockIcon } from "../../../components/new-cards/card-icons";
import { CalendarIcons } from "../../../assets/icons/productIcons";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

const StockDetailProductCard = ({ item, quantityUsedPerPiece }) => {
  console.log("item", item);
  const { t } = useTranslation();
  return (
    <>
      <div
        data-tooltip-id={`${item?.id}`}
        data-tooltip-place={"right"}
        data-tooltip-content={`${item?.name} `}
        className="flex bg-white flex-row  w-[300px] min-w-[300px] h-[90px] min-h-[90px] relative group pl-2  pt-2 border border-[#D0D5DD] rounded-lg cursor-grab "
      >
        <div className="flex flex-col w-[100%]">
          <div className="flex flex-row items-center gap-x-3">
            {item?.image ? (
              <div>
                <CustomImage
                  borderRadius={2}
                  src={item?.image}
                  style={{
                    width: 64,
                    height: 48,
                    minWidth: 64,
                    minHeight: 48,
                    borderRadius: 4,
                    borderWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}

            <div className="flex flex-col items-start">
              <div className="flex flex-row items-center gap-x-1 w-full">
                <p className="font-semibold text-secondary-900 text-base w-[185px] truncate">{item?.name || "Product Name"}</p>
              </div>

              <div className="bg-[#FFFAEB] max-w-[185px] h-6 rounded px-[3px] py-1">
                <p className="text-xs font-medium max-w-[185px] truncate text-[#B54708]">
                  {t("product:stock")} : {item?.stockCode || "--"}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-2 w-[100%]">
            <div className="flex flex-col items-start">
              <div className="flex flex-row items-center mt-1.5">
                <p className="text-sm font-medium ml-1 text-secondary-700">x{quantityUsedPerPiece || "1"}</p>
                <p className="text-[10px] ml-0.5 mt-0.5 font-normal text-secondary-500">ürün başı miktar</p>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 bg-[#F2F4F7] border-secondary-300 h-[70px] min-h-[70px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px]`}
        >
          {i18next?.language === "tr" ? (
            <p className={`text-xxs font-semibold text-secondary-600 -rotate-90`}>{"Hammadde"}</p>
          ) : (
            <div className="flex flex-col items-center">
              <p className={`text-[10px] font-semibold text-[#475467] -rotate-90`}>Hammadde</p>
            </div>
          )}
        </div>
        <div
          onClick={(e) => e.stopPropagation()}
          className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <NewDotsIcon />
        </div>
        <Tooltip id={`${item?.id}`} />
      </div>
    </>
  );
};

export default StockDetailProductCard;

import React, { useState } from "react";
import { Button, CustomModal } from "../../components";
import { ArrowLeft } from "../../assets/icons/commonIcons";
import { ArrowUpRight } from "../../assets/icons/stepsIcons";
import { DotsHorizontalIcon, PlusIcon } from "../../assets/icons/productIcons";
import { ArrowRight } from "../../assets/icons/equipmentIcon";
import { useNavigate } from "react-router-dom";
import { AttachmentIconTask } from "../../assets/icons/PersonIcons";
import MaterialSupplyPlanModal from "./modals/MaterialSupplyPlanModal";
import { EditIconMachine } from "../../assets/icons/machineIcons";
import { DeleteIcon } from "../../assets/icons/departmentIcon";

const MaterialSupplyPurchaseDetail = () => {
  const navigate = useNavigate();
  const [isOpenPlanningModal, setIsOpenPlanningModal] = useState(false);

  const tableHeadItems = [
    { name: "Malzemeler" },
    { name: "Stokta" },
    { name: "Aktif Talep" },
    { name: "Bekleyen Talep" },
    { name: "MS Planlı" },
    { name: "MS Plan Adedi" },
  ];

  const detailTableHeadItems = [
    { name: "Teslim Alan Personel" },
    { name: "Teslim Alım Tarihi" },
    { name: "Teslim Alınan Miktar" },
    { name: "Kalite Kontrol" },
    { name: "" },
    { name: "" },
  ];

  return (
    <>
      <div className="flex flex-col w-full h-full gap-y-6">
        <div className="flex w-full min-h-[56px] max-h-[56px] h-[56px] py-2 justify-between border-b border-[#E4E7EC]">
          <div className="flex gap-x-2 h-full items-center">
            <Button colorType={"tertiary-gray"} iconLeft={<ArrowLeft />} size={"md"} onClick={() => navigate(-1)} />
            <p className="text-[#101828] font-semibold text-2xl">Malzeme Satın Alım Detayı</p>
          </div>
          <div className="flex gap-x-3 h-full">
            <Button colorType={"secondary-gray"} label={"Stok Detayı"} iconRight={<ArrowUpRight />} size={"md"} />
            <Button
              colorType={"primary-product"}
              label={"Yeni Malzeme Alım"}
              iconLeft={<PlusIcon />}
              size={"md"}
              onClick={() => setIsOpenPlanningModal(true)}
            />
          </div>
        </div>
        <div className="flex flex-col w-full border rounded-xl bg-[#F2F4F7] h-[86vh] overflow-y-auto scrollbar-hide overflow-x-hidden">
          <div className="flex max-h-[36px] min-h-[36px] max-w-full min-w-full">
            {tableHeadItems?.map((item, i) => {
              return (
                <div
                  key={i}
                  scope="col"
                  className={`px-6 py-2 ${
                    i === 0 ? "min-w-[336px] max-w-[336px] rounded-tl-xl" : i === 6 ? "min-w-[72px] max-w-[72px] w-[72px] rounded-tr-xl" : "w-full"
                  } items-center text-center bg-secondary-50 border-gray-200 border-b border-r last:border-r-0`}
                >
                  <span className={`text-center rounded flex text-xs font-medium justify-center md:line-clamp-2 items-center whitespace-nowrap`}>
                    {item?.name}
                  </span>
                </div>
              );
            })}
          </div>
          <div className="w-full  h-full flex flex-col">
            <div className={`flex w-full items-center border-b h-[72px] min-h-[72px] bg-white`}>
              <div className="flex items-center h-full border-r min-w-[336px] max-w-[336px]">
                <div className="flex w-full h-full px-6 py-3 gap-x-[6px]">
                  <div className="flex min-w-[64px] max-w-[64px] w-[64px] min-h-[48px] max-h-[48px] h-[48px] rounded border-[#0000001A] border"></div>
                  <div className="flex flex-col w-full justify-center">
                    <p className="text-[#101828] text-md font-semibold px-1">Raw Material 102</p>
                    <span className="flex w-fit px-1 py-[3px] rounded bg-[#FFFAEB] text-[#B54708] text-xs font-medium">Stok: 600.01.0216</span>
                  </div>
                </div>
                <div className="flex items-center justify-center h-full min-w-6 max-w-6 w-6 p-[3px] bg-[#F2F4F7] border-l border-[#E4E7EC]">
                  <p className="text-[#475467] text-xxs font-semibold -rotate-90 whitespace-nowrap">Raw M.</p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                <p className="text-[#344054] text-sm font-medium">300</p>
                <p className="text-[#667085] text-xs">kg</p>
              </div>
              <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                <p className="text-[#344054] text-sm font-medium">300</p>
                <p className="text-[#667085] text-xs">kg</p>
              </div>
              <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                <p className="text-[#344054] text-sm font-medium">300</p>
                <p className="text-[#667085] text-xs">kg</p>
              </div>
              <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                <p className="text-[#344054] text-sm font-medium">300</p>
                <p className="text-[#667085] text-xs">kg</p>
              </div>
              <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                <p className="text-[#344054] text-sm font-medium">300</p>
              </div>
            </div>
            <div className="flex flex-col gap-y-4 w-full h-full  px-6 py-4">
              {[1, 2, 3, 4, 5].map((item, index) => {
                return (
                  <div className="flex flex-col w-full h-fit border rounded-xl shadow-xs border-[#E4E7EC] bg-white">
                    <div className="flex w-full min-h-[60px] max-h-[60px] h-[60px] justify-between px-6 py-3">
                      <div className="flex h-full w-fit items-center gap-x-3">
                        <div className="flex w-full gap-x-2 items-center">
                          <OrderIcon />
                          <div className="flex gap-x-2 items-center">
                            <p className="text-[#475467] text-sm">Tedarik (WIMS) Kodu</p>
                            <p className="text-[#B54708] font-semibold text-lg">MS-00001</p>
                          </div>
                        </div>
                        <span className="w-5 h-5">
                          <ChevronRight />
                        </span>
                        <div className="flex gap-x-3 items-center">
                          <p className="text-[#475467] text-sm">Tedarikçi</p>
                          <div className="flex gap-x-2 items-center">
                            <span className="flex min-w-10 max-w-10 w-10 min-h-[30px] max-h-[30px] h-[30px] border border-[#0000001A] rounded"></span>
                            <p className="text-[#B54708] font-semibold text-md whitespace-nowrap">Mavi Çelik</p>
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-x-3 h-full">
                        <Button colorType={"secondary-gray"} iconRight={<PlusIcon />} size={"sm"} />
                        <Button colorType={"secondary-machine"} iconRight={<EditIconMachine />} size={"sm"} />
                        <Button colorType={"secondary-error"} iconRight={<DeleteIcon />} size={"sm"} />
                      </div>
                    </div>
                    <div className="flex w-full min-h-[76px] max-h-[76px] h-[76px] gap-x-8 px-6 pb-3">
                      <div className="flex h-full w-fit border border-[#D0D5DD] rounded-lg px-4 py-3 gap-x-3">
                        <span className="flex min-w-10 max-w-10 w-10 min-h-10 max-h-10 h-10 rounded-full border border-[#00000014]"></span>
                        <div className="flex flex-col w-full">
                          <p className="text-[#344054] text-sm font-medium whitespace-nowrap">Doğukan Cangüloğlu</p>
                          <p className="text-[#667085] text-xs">Lojistikçi</p>
                        </div>
                      </div>
                      <div className="flex h-full w-full border border-[#D0D5DD] rounded-lg">
                        <div className="flex flex-col w-full px-4 py-3 h-full border-r border-[#D0D5DD] items-start justify-center">
                          <p className="text-[#475467] text-xs whitespace-nowrap">Toplam Miktar</p>
                          <span className="flex gap-x-[2px] items-end">
                            <p className="text-[#B54708] font-semibold text-sm">2.000</p>
                            <p className="text-[#475467] text-xs">kg</p>
                          </span>
                        </div>
                        <div className="flex flex-col w-full px-4 py-3 h-full border-r border-[#D0D5DD] items-start justify-center">
                          <p className="text-[#475467] text-xs whitespace-nowrap">Teslim Alınan Miktar</p>
                          <span className="flex gap-x-[2px] items-end">
                            <p className="text-[#B54708] font-semibold text-sm">2.000</p>
                            <p className="text-[#475467] text-xs">kg</p>
                          </span>
                        </div>
                        <div className="flex flex-col w-full px-4 py-3 h-full">
                          <p className="text-[#475467] text-xs whitespace-nowrap">Kalan Miktar</p>
                          <span className="flex gap-x-[2px] items-end ">
                            <p className="text-[#B54708] font-semibold text-sm">2.000</p>
                            <p className="text-[#475467] text-xs">kg</p>
                          </span>
                        </div>
                      </div>
                      <div className="flex h-full w-fit border border-[#D0D5DD] rounded-lg">
                        <div className="flex flex-col w-fit px-4 py-3 h-full border-r border-[#D0D5DD] items-start justify-center">
                          <p className="text-[#475467] text-xs whitespace-nowrap">Birim Fiyat</p>
                          <span className="flex gap-x-[2px] items-end">
                            <p className="text-[#B54708] font-semibold text-sm whitespace-nowrap">£ 20,00</p>
                          </span>
                        </div>
                        <div className="flex flex-col w-fit px-4 py-3 h-full items-start justify-center">
                          <p className="text-[#475467] text-xs whitespace-nowrap">Toplam Fiyat</p>
                          <span className="flex gap-x-[2px] items-end">
                            <p className="text-[#B54708] font-semibold text-sm whitespace-nowrap">£ 120.000,00</p>
                          </span>
                        </div>
                      </div>
                      <div className="flex h-full w-fit border border-[#D0D5DD] rounded-lg">
                        <div className="flex flex-col w-fit px-4 py-3 h-full border-r border-[#D0D5DD] items-start justify-center">
                          <p className="text-[#475467] text-xs whitespace-nowrap">Birim Fiyat</p>
                          <span className="flex gap-x-[2px] items-end">
                            <p className="text-[#B54708] font-semibold text-sm whitespace-nowrap">£ 20,00</p>
                          </span>
                        </div>
                        <div className="flex flex-col w-fit px-4 py-3 h-full items-start justify-center">
                          <p className="text-[#475467] text-xs whitespace-nowrap">Toplam Fiyat</p>
                          <span className="flex gap-x-[2px] items-end">
                            <p className="text-[#B54708] font-semibold text-sm whitespace-nowrap">£ 120.000,00</p>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex max-h-[28px] min-h-[28px] max-w-full min-w-full">
                      {detailTableHeadItems?.map((item, i) => {
                        return (
                          <div
                            key={i}
                            scope="col"
                            className={`px-6 py-1 ${
                              i === 0 ? "min-w-[240px] max-w-[240px]" : i === 4 || i === 5 ? "min-w-[72px] max-w-[72px] w-[72px]" : "w-full"
                            } border-t items-center text-center bg-secondary-50 border-gray-200 border-b border-r last:border-r-0`}
                          >
                            <span className={`text-center rounded flex text-xs font-medium justify-center md:line-clamp-2 items-center whitespace-nowrap`}>
                              {item?.name}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                    {[1, 2, 3, 4, 5].map((item, index) => {
                      return (
                        <div className={`flex w-full items-center border-b h-[72px] min-h-[72px] last:border-b-0`}>
                          <div className="flex items-center h-full border-r min-w-[240px] max-w-[240px] px-6 py-4 gap-x-3">
                            <span className="flex min-w-10 max-w-10 w-10 min-h-10 max-h-10 h-10 rounded-full border border-[#00000014]"></span>
                            <div className="flex flex-col w-full">
                              <p className="text-[#344054] text-sm font-medium whitespace-nowrap">Doğukan Cangüloğlu</p>
                              <p className="text-[#667085] text-xs">Lojistikçi</p>
                            </div>
                          </div>
                          <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                            <p className="text-[#344054] text-sm font-medium">15.04.2024</p>
                            <p className="text-[#667085] text-xs">Pazartesi</p>
                          </div>
                          <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                            <p className="text-[#344054] text-sm font-medium">1.000</p>
                            <p className="text-[#667085] text-xs">kg</p>
                          </div>
                          <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                            <span className="flex gap-x-1 items-center">
                              <p className="text-[#079455] text-sm font-medium">2.500</p>
                              <p className="text-[#667085] text-xs">adet</p>
                            </span>
                            <span className="flex gap-x-1 items-center">
                              <p className="text-[#D92D20] text-sm font-medium">500</p>
                              <p className="text-[#667085] text-xs">adet</p>
                            </span>
                          </div>
                          <div className="flex flex-col items-center justify-center h-full border-r w-[72px] max-w-[72px] min-w-[72px] py-4 relative">
                            <Button colorType={"tertiary-gray"} size={"md"} iconLeft={<AttachmentIconTask />} />
                            <span className="flex absolute right-[10px] top-[14px] w-4 h-4 rounded-full bg-[#DC6803] items-center justify-center text-[#FFFFFF] text-xs font-semibold">
                              1
                            </span>
                          </div>
                          <div className="flex flex-col items-center justify-center h-full w-[72px] max-w-[72px] min-w-[72px] py-4">
                            <Button colorType={"tertiary-gray"} size={"md"} iconLeft={<DotsHorizontalIcon />} />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        isOpen={isOpenPlanningModal}
        setIsOpen={setIsOpenPlanningModal}
        onClose={() => {
          setIsOpenPlanningModal(false);
        }}
        modalTitle={"Satın Alım Planla"}
        subTitle={"Planlama detaylarını belirtin"}
        width="fit-content"
        children={
          <MaterialSupplyPlanModal
          // setIsOpen={setIsOpenPlanningModal}
          // processId={planView?.data?.id}
          // refetch={refetch}
          // selectedJunction={isOpenPlanningModalData}
          // // supplierId={supplierData?.id}
          // companyId={auth.user.company.id}
          // planView={planView}
          />
        }
      />
    </>
  );
};

export default MaterialSupplyPurchaseDetail;

const ChevronRight = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M7.5 15L12.5 10L7.5 5" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

const OrderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M16.6673 8.74984V5.6665C16.6673 4.26637 16.6673 3.56631 16.3948 3.03153C16.1552 2.56112 15.7727 2.17867 15.3023 1.93899C14.7675 1.6665 14.0674 1.6665 12.6673 1.6665H7.33398C5.93385 1.6665 5.23379 1.6665 4.69901 1.93899C4.2286 2.17867 3.84615 2.56112 3.60647 3.03153C3.33398 3.56631 3.33398 4.26637 3.33398 5.6665V14.3332C3.33398 15.7333 3.33398 16.4334 3.60647 16.9681C3.84615 17.4386 4.2286 17.821 4.69901 18.0607C5.23379 18.3332 5.93385 18.3332 7.33398 18.3332M10.0007 7.49984H6.66732M11.6673 4.99984H6.66732M8.33398 9.99984H6.66732M9.83772 12.6319L12.8562 10.9452C12.9984 10.8658 13.0694 10.8261 13.1447 10.8105C13.2114 10.7967 13.2801 10.7967 13.3468 10.8103C13.4221 10.8258 13.4933 10.8654 13.6355 10.9446L16.6673 12.6319M9.83772 12.6319L13.2457 14.5309M9.83772 12.6319V15.9547C9.83772 16.1253 9.83772 16.2106 9.86279 16.2868C9.88497 16.3541 9.92123 16.416 9.96917 16.4683C10.0234 16.5274 10.0978 16.5691 10.2466 16.6525L13.2457 18.3332M16.6673 12.6319L13.2457 14.5309M16.6673 12.6319V15.9537C16.6673 16.1247 16.6673 16.2102 16.6422 16.2864C16.6199 16.3539 16.5835 16.4159 16.5355 16.4682C16.4811 16.5273 16.4065 16.569 16.2572 16.6523L13.2457 18.3332M13.2457 14.5309V18.3332"
        stroke="#475467"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

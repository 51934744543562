import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment';
import { Loading } from '../../../components';


const OrderWipProductCardTable = ({ setIsCardClick, selectedCard, loading }) => {
    const { t } = useTranslation()
    const tableHeadItems = [
        { name: t("product:orderNo"), value: "orderNo", type: "" },
        { name: t("product:totalQuantity"), value: "totalQuantity", type: "" },
        { name: t("product:quantitys"), value: "remainingQuantity", type: "number" },
    ];

    return (
        <div className='flex flex-col items-start w-full rounded-lg border border-[#E4E7EC] bg-white relative shadow-lg'>
            <div className="flex max-h-[26px] min-h-[26px] max-w-full min-w-full">
                {tableHeadItems?.map((item, i) => {
                    return (
                        <div
                            key={i}
                            scope="col"
                            className={`flex h-[26px] min-h-[26px] max-h-[26px] py-1 ${i === 2 ? "" : "border-r"} ${i === 1 || i === 2
                                ? `w-[30%] justify-center ${i === 2 ? "rounded-tr-md" : ""} text-center items-center px-2`
                                : i === 0
                                    ? "w-[40%] items-center px-3 rounded-tl-md text-start"
                                    : ""
                                } bg-secondary-50  border-[#E4E7EC] border-b`}
                        >
                            <span
                                className={`text-center  flex text-xxs text-secondary-600 font-medium items-center whitespace-nowrap`}
                            >
                                {item?.name}
                            </span>
                        </div>
                    );
                })}
            </div>
            <div className='w-full bg-[#FFF]  flex flex-col max-h-[160px] overflow-y-scroll scrollbar-hide'>

                {selectedCard?.map((items, key) => (
                    <div key={key} className={`flex w-full items-center border-b h-[54px] min-h-[54px] `}>
                        <div className={`py-2 px-3 overflow-hidden flex items-start flex-col ${key === selectedCard?.length -1 ? "rounded-bl-[6px]" : ""}  border-r h-[54px] min-w-[40%] max-w-[40%] w-[40%]`}>
                            <p className='text-[#B54708] font-semibold w-[110px] truncate text-xs'>{items?.workOrderNo || "--"}</p>
                            <p className='text-secondary-600 font-normal text-xs'>{moment(items?.createdDate).format("DD.MM.YYYY") || "--"}</p>
                        </div>
                        <div className="min-w-[30%] max-w-[30%] w-[30%] border-r h-full p-2 flex items-center justify-center">
                            <p className="whitespace-nowrap text-center max-w-[110px] truncate overflow-hidden text-ellipsis text-xs text-secondary-600 dark:text-[#F5F5F5] font-medium">
                                {items?.desiredQuantity || "--"}
                            </p>
                            <p className='text-xxs text-secondary-500 font-normal ml-0.5'>{t("product:pcs")}</p>
                        </div>
                        <div className={`h-full p-2 flex items-center ${key === selectedCard?.length -1 ? "rounded-bl-[6px]" : ""} justify-center min-w-[30%] max-w-[30%] w-[30%]`}>
                            <p className="whitespace-nowrap text-center max-w-[110px] truncate overflow-hidden text-ellipsis text-xs text-secondary-600 dark:text-[#F5F5F5] font-medium">
                                {items?.desiredQuantity - items?.finishedQuantity || "--"}
                            </p>
                            <p className='text-xxs text-secondary-500 font-normal ml-0.5'>{t("product:pcs")}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className='absolute flex flex-col gap-y-1 -top-2 -right-2'>
                <div onClick={() => setIsCardClick(false)} className='flex items-center cursor-pointer justify-center border p-[2px] bg-white rounded w-4 h-4 min-w-4 min-h-4 border-[#E4E7EC]'>
                    <CloseIcons />
                </div>
                <div className='flex items-center cursor-pointer justify-center border p-[2px] bg-white rounded w-4 h-4 min-w-4 min-h-4 border-[#E4E7EC]'>
                    <ChevronIcon />
                </div>
            </div>
        </div>
    )
}

export default OrderWipProductCardTable

export const ChevronIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M4 12L12 4M12 4H6.66667M12 4V9.33333" stroke="#475467" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export const CloseIcons = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M12 4L4 12M4 4L12 12" stroke="#475467" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};


import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CustomModal } from "../../../components";
import QuantityModal from "./QuantityModal";
import { CheckIcon } from "../../../assets/icons/stepsIcons";
import { observer } from "mobx-react-lite";
import { ClockIcon3, DotsVerticalIcon, QuantityIcon } from "../../../assets/icons/commonIcons";
import UpdateToleranceValue from "./UpdateToleranceValue";

const colors = [
  localStorage.color_theme === "dark" ? "#9E77ED" : "#9E77ED",
  localStorage.color_theme === "dark" ? "#F79009" : "#F04438",
  localStorage.color_theme === "dark" ? "#15B79E" : "#DC6803",
  localStorage.color_theme === "dark" ? "#FFFF5D" : "#0E9384",
  localStorage.color_theme === "dark" ? "#2E90FA" : "#12B76A",
  localStorage.color_theme === "dark" ? "#8CF3AF" : "#EAAA08",
  localStorage.color_theme === "dark" ? "#F79009" : "#4E5BA6",
  localStorage.color_theme === "dark" ? "#667085" : "#008999",
];

const WorkCountCard = observer(({ workCount, metadataId, refetch }) => {
  const { t } = useTranslation();
  const contentWrapper = useRef(null);
  const [isOpenWorkCountModal, setIsOpenWorkCountModal] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [showDropDown, setShowDropDown] = useState(false);
  const [isUpdateTolerance, setIsUpdateTolerance] = useState(false);

  useEffect(() => {
    const initialCheckboxStates = {};
    workCount?.forEach((_, index) => {
      initialCheckboxStates[index] = true;
    });
    setCheckboxStates(initialCheckboxStates);
  }, [workCount]);

  const sorted = workCount?.sort(
    (a, b) => {
      if (a.count !== b.count) {
        return b.count - a.count;
      }
    },
    [workCount]
  );

  const firstElement = workCount.shift();

  workCount.sort((a, b) => Math.abs(a.maxRepeatValue - firstElement.maxRepeatValue) - Math.abs(b.maxRepeatValue - firstElement.maxRepeatValue));

  workCount.unshift(firstElement);

  const totalSelectedWorkCount = () => {
    let totalCount = 0;
    for (const index in checkboxStates) {
      if (checkboxStates[index]) {
        totalCount += workCount[index]?.count || 0;
      }
    }
    return totalCount;
  };

  const toggleCheckbox = (index) => {
    setCheckboxStates((prevState) => {
      const newCheckboxStates = {
        ...prevState,
        [index]: !prevState[index],
      };

      const allSelected = Object.values(newCheckboxStates).every((isChecked) => isChecked);
      setIsAllSelected(allSelected);

      return newCheckboxStates;
    });
  };

  const allSelected = () => {
    const newCheckboxStates = {};
    workCount?.forEach((_, index) => {
      newCheckboxStates[index] = !isAllSelected;
    });
    setCheckboxStates(newCheckboxStates);
    setIsAllSelected(!isAllSelected);
  };
  return (
    <>
      <div className="w-full flex min-h-[72px] h-[72px] mb-4 justify-start items-center border dark:border-[#525252] dark:bg-[#292929] border-secondary-300 rounded-md pointer mt-4">
        <div className="flex flex-col whitespace-nowrap border-r h-full px-4 py-2 items-center text-[#475467] justify-center min-w-[160px] relative group">
          <div className="flex items-center justify-start gap-x-1 w-full">
            <QuantityIcon />
            <div className="flex gap-x-1 items-center">
              <span className="text-[#101828] font-semibold text-base">{totalSelectedWorkCount()}</span>
              <span className="text-[#667085] font-normal text-sm mt-[2px]">{t("product:totalpcs")}</span>
            </div>
          </div>
          <div onClick={allSelected} className="flex gap-x-2 w-full items-center justify-start cursor-pointer">
            <div
              className={`flex min-h-[16px] max-h-[16px] h-4 min-w-[16px] max-w-[16px] w-4 border border-[#D0D5DD] rounded p-[2px] items-center justify-center ${
                isAllSelected && "bg-[#7F56D9] border-[0px]"
              } `}
            >
              {isAllSelected && <CheckIcon color={"white"} />}
            </div>
            <p className="text-[#667085] font-normal text-sm">{t("person:selectAll")}</p>
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setShowDropDown(!showDropDown);
            }}
            className="flex min-w-[20px] max-w-[20px] w-5 min-h-[20px] max-h-[20px] h-5 absolute right-1 top-2 cursor-pointer "
          >
            <DotsVerticalIcon />
            {showDropDown && (
              <div className="hidden flex-col absolute w-fit h-fit bg-white border left-1 top-5 z-[99] rounded-lg px-[6px] py-1 group-hover:flex">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsUpdateTolerance(true);
                    setShowDropDown(false);
                  }}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 hover:bg-secondary-100`}
                >
                  {/* {item?.icon} */}
                  <p className={`font-medium text-sm text-secondary-700`}>{t("product:updateToleranceValue")}</p>
                </button>
              </div>
            )}
          </div>
        </div>
        <div ref={contentWrapper} className="flex flex-row p-1 pl-2 items-center overflow-x-auto gap-x-3">
          {workCount?.map((item, index) => {
            const isChecked = checkboxStates[index] || false;
            return (
              <div
                onDoubleClick={(e) => {
                  setIsOpenWorkCountModal(true);
                }}
                onClick={(e) => {
                  toggleCheckbox(index);
                }}
                key={index}
                style={{
                  backgroundColor: isChecked ? `${colors[index % colors.length]}` : "#F2F4F7",
                  color: isChecked ? `${colors[index % colors.length]}` : "#98A2B3",
                }}
                className={`w-fit max-h-[48px] flex items-center py-0.5 px-2 rounded-lg gap-x-2 cursor-pointer border transition duration-300 ease-in-out ${
                  isChecked ? `border-[${colors[index % colors.length]}]` : "border-[#EAECF0]"
                }`}
              >
                <div
                  className={`flex min-w-[16px] max-w-[16px] bg-white min-h-[16px] max-h-[16px] rounded p-[2px] items-center justify-center border transition duration-300 ease-in-out ${
                    isChecked ? `border-[${colors[index % colors.length]}]` : "border-[#D0D5DD]"
                  }`}
                >
                  {isChecked ? <CheckIcon color={`${colors[index % colors.length]}`} /> : null}
                </div>
                <div
                  style={{
                    backgroundColor: `${localStorage.color_theme === "dark" ? "#F4EBFF" : "#FFFFFF"}`,
                  }}
                  className="h-5 w-5 flex items-center justify-center font-semibold text-[10px] rounded-full p-2"
                >
                  {item?.workQty ? item?.workQty : 0}
                </div>

                <div className="flex flex-col">
                  <div className="flex flex-row items-center justify-start gap-x-2">
                    <span className={`text-xs font-normal ${isChecked ? "text-[#FAFAFA]" : "text-[#98A2B3]"}`}>
                      <QuantityIcon />
                    </span>
                    <span className={`text-sm font-semibold whitespace-nowrap ${isChecked ? "text-[#FAFAFA] dark:text-[#FFFFFF]" : "text-[#98A2B3]"}`}>
                      {item?.count ? item?.count : 0} {t("product:pcs")}
                    </span>
                  </div>
                  <div className="flex flex-row items-center justify-start gap-x-2">
                    <span className={`text-xs font-normal ${isChecked ? "text-[#FAFAFA]" : "text-[#98A2B3]"}`}>
                      <ClockIcon3 />
                    </span>
                    <span className={` text-sm font-semibold whitespace-nowrap ${isChecked ? "text-[#FAFAFA] dark:text-[#FFFFFF]" : "text-[#98A2B3]"}`}>
                      {item?.maxRepeatValue ? item?.maxRepeatValue : 0} ± {item?.workTolerance ? item?.workTolerance : 0}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <CustomModal
        isOpen={isOpenWorkCountModal}
        setIsOpen={setIsOpenWorkCountModal}
        modalTitle={t("consoleModal:qty")}
        children={<QuantityModal t={t} workCount={workCount} onClose={() => setIsOpenWorkCountModal(false)} />}
      />
      <CustomModal
        isOpen={isUpdateTolerance}
        setIsOpen={setIsUpdateTolerance}
        modalTitle={t("product:updateToleranceValue")}
        width={400}
        children={<UpdateToleranceValue t={t} onClose={() => setIsUpdateTolerance(false)} metadataId={metadataId} refetch={refetch} />}
      />
    </>
  );
});
export default WorkCountCard;

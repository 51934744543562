import React, { useState } from "react";
import * as yup from "yup";
import { Button, UploadProfile } from "../../../components";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import QtyInput from "../../../components/inputs/QtyInput";
import PurchaseFinishJunctionCard from "../../../components/new-cards/material-supply/PurchaseFinishJunctionCard";
import MaterialSupplyEventCardPlannedTriple from "../../../components/new-cards/material-supply/MaterialSupplyEventCardPlannedTriple";
import { purchaseService } from "../../../services/purchase.service";
import { Toast } from "../../../utils/toastify/toast";

const NewPurchaseFinishModal = ({ junctionData, setIsOpen, refetch }) => {
  const { t } = useTranslation(["product"]);
  console.log("junctionData", junctionData);
  const [priceValues, setPriceValues] = useState({});

  const schema = yup.object({
    purchaseFile: yup.string().notRequired(),
    secondPurchaseFile: yup.string().notRequired(),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (value) => {
    const data = {
      plannedJunctionRef: junctionData?.id,
      incomingUnit: priceValues,
      purchaseFile: value?.purchaseFile,
      secondPurchaseFile: value?.secondPurchaseFile,
      requestId: junctionData?.purchaseRequest?.id,
      supplierId: junctionData?.supplier?.id,
    };
    console.log("data", data);
    await purchaseService.startPurchaseJunction(data).then((res) => {
      if (res?.data?.code === 0) {
        Toast("success", res?.data?.message);
        setIsOpen(false);
        refetch();
      } else {
        Toast("error", res?.data?.message);
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col min-w-[335px] max-w-[335px] w-[335px]">
      <div className="flex flex-col gap-y-4">
        <div className="flex w-full pt-5">
          <MaterialSupplyEventCardPlannedTriple data={junctionData} />
        </div>
        <div className="h-[1px] w-full bg-[#E4E7EC]"></div>
        <div className="flex flex-col gap-y-6 w-full pb-5">
          <QtyInput
            theme={"product"}
            qtyType={junctionData?.rawMaterial ? junctionData?.rawMaterial?.unit : t("product:pcs")}
            onChange={(e) => {
              setPriceValues(e?.target?.value);
            }}
            value={priceValues}
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <UploadProfile title={t("product:controlForm")} subTitle={"Remove Picture"} onChange={onChange} onBlur={onBlur} value={value} />
            )}
            name="purchaseFile"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <UploadProfile title={t("product:approvalForm")} subTitle={"Remove Picture"} onChange={onChange} onBlur={onBlur} value={value} />
            )}
            name="secondPurchaseFile"
          />
        </div>
      </div>
      <div className="flex flex-col gap-y-3">
        <span>
          <Button colorType={"primary-product"} label={"Satın Alımı Tamamla"} size={"lg"} type={"submit"} />
        </span>
        <span>
          <Button colorType={"secondary-gray"} label={"Vazgeç"} size={"lg"} />
        </span>
      </div>
    </form>
  );
};

export default NewPurchaseFinishModal;

import moment from "moment";
import React, { useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import Button from "../buttons/Button";
import { companyService } from "../../services/company.service";
import { Toast } from "../../utils/toastify/toast";
import Input from "../inputs/Input";
import { useTranslation } from "react-i18next";

const AddHolidayModal = ({ closeModal, refetch }) => {
  const {t} = useTranslation()
  const [name, setName] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedRange, setSelectedRange] = useState({ from: null, to: null });
  const [selectedType, setSelectedType] = useState("multi");
  const handleDayRemove = (dayToRemove) => {
    const newSelectedDays = selectedDays.filter((day) => day.getTime() !== dayToRemove.getTime());
    setSelectedDays(newSelectedDays);
  };
  const handleReset = () => {
    setSelectedRange({ from: null, to: null });
  };
  const handleSaveData = async () => {
    const data = {
      name,
      shiftGroupId: "holiday",
      type: selectedType === "multi" ? "multiDate" : "dateRange",
      dates:
        selectedType === "multi"
          ? selectedDays.map((e) => moment(e).format("YYYY-MM-DD"))
          : [moment(selectedRange.from).format("YYYY-MM-DD"), moment(selectedRange.to).format("YYYY-MM-DD")],
    };
    if (data.name === "") {
      Toast("error", "Tatil adı boş bırakılamaz");
      return;
    }
    if (data.dates.length === 0) {
      Toast("error", "Tarih seçimi yapmalısınız");
      return;
    }
    await companyService.nonstandartShift(data).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        closeModal();
      } else {
        Toast("error", res.data.message);
      }
    });
  };
  return (
    <div className="w-[608px] flex flex-col mt-5">
      <div className="w-full mb-6">
        <Input value={name} onChange={({ target }) => setName(target.value)} label={t("addShift:holidayName")} />
      </div>
      <div className="flex justify-between">
        <div className="flex-1 w-full flex flex-col overflow-hidden mr-8">
          <div className="flex flex-col gap-3">
            <p className="text-secondary-700 font-semibold">{t("addShift:calendarType")}</p>
            <button
              className="flex items-center gap-2 group"
              onClick={() => {
                handleReset();
                setSelectedType("multi");
              }}
            >
              <div className={`w-4 h-4 rounded-full ${selectedType === "multi" ? "border-[5px] border-primary-600" : "border"}`} />
              <p className={`text-sm text-secondary-600 group-hover:text-secondary-900`}>{t("addShift:selectOneOrMore")}</p>
            </button>
            <button
              className="flex items-center gap-2 group"
              onClick={() => {
                setSelectedDays([]);
                setSelectedType("range");
              }}
            >
              <div className={`w-4 h-4 rounded-full ${selectedType === "range" ? "border-[5px] border-primary-600" : "border"}`} />
              <p className="text-sm text-secondary-600 group-hover:text-secondary-900">{t("addShift:selectDateRange")}</p>
            </button>
          </div>

          {selectedDays?.length > 0 && (
            <div className="flex flex-col mt-6 gap-3 overflow-hidden">
              <p className="text-secondary-700 font-semibold">{t("addShift:selectedDates")}</p>
              <ul className="gap-3 flex flex-col overflow-y-scroll h-[244px] scrollbar-hide border-b">
                {selectedDays.map((day, index) => (
                  <li className="flex items-center gap-2" key={index}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                      <circle cx="4" cy="4" r="4" fill="#7F56D9" />
                    </svg>
                    <p className="truncate text-sm text-secondary-600 ">{moment(day).format("LL - dddd")}</p>
                    <button type="button" className="ml-auto text-secondary-400 hover:text-secondary-900" onClick={() => handleDayRemove(day)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M12 4L4 12M4 4L12 12" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="w-[328px] border rounded-lg h-[392px] flex items-center justify-center shadow">
          {selectedType === "multi" ? (
            <DayPicker
              disabled={{ before: new Date() }}
              mode="multiple"
              selected={selectedDays}
              onSelect={setSelectedDays}
              modifiersClassNames={{
                selected: "bg-[#7F56D9] text-white font-medium",
              }}
            />
          ) : (
            <DayPicker disabled={{ before: new Date() }} mode="range" selected={selectedRange} onSelect={setSelectedRange} />
          )}
        </div>
      </div>
      <div className="flex w-full items-center justify-between gap-3 mt-6">
        <Button colorType={"secondary-gray"} size={"md"} onClick={() => {}} label={t("buttons:stop")} />
        <Button colorType={"primary-machine"} size={"md"} onClick={handleSaveData} label={t("buttons:save")} />
      </div>
    </div>
  );
};

export default AddHolidayModal;

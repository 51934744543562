import React from 'react'
import Badgets from '../../buttons/Badgets'
import { AcceptedIcon, ClockIcon, EquCalculate, FlexProductIcon, GreenAvgIcon, JunctionNumberIcon, JunctionNumberIconSelected, PieceIcon, RedAvgIcon, RejectedIcon, TargetIcon } from '../card-icons'
import NoAvatar from '../../avatar/NoAvatar'
import CustomImage from '../../CustomImage'
import { NoEquIcon } from '../../../assets/icons/machineIcons'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
const PersonnelDetailTimelineDoneCard = ({data,selectedJunction,junctionNumber}) => {
    const { t } = useTranslation();

    const oneOrderTime = (start, end, count, type) => {
      const diff = moment(end).diff(moment(start), "seconds");
      const oneOrder = diff / count;
      return type === "setup" ? diff : `${oneOrder?.toFixed(0)}`;
    };
  
    const convertSeconds = (seconds) => {
      if (isNaN(seconds) || seconds == Infinity) return "---";
  
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Number(seconds % 60).toFixed(0);
  
      let formattedTime = "";
      
      if (minutes > 0) {
        formattedTime += minutes + " dk ";
      }
  
      if (remainingSeconds > 0) {
        formattedTime += remainingSeconds + " sn";
      }
  
      return formattedTime.trim();
    }
  
    const totalEstimatedTime = data?.junction?.productType === "flex" ? data?.junction?.flexHeader?.estimatedTime : data?.junction?.header?.internalOperation?.estimatedTime;
    const setupEstimatedTime = data?.junction?.productType === "flex" ? data?.junction?.flexHeader?.estimatedSettingsTime * 60 : data?.junction?.header?.internalOperation?.estimatedSettingsTime * 60
    
    const formattedEstimatedOpTime = data?.junction?.type === "setup" ? convertSeconds(data?.junction?.productType === "flex" ? data?.junction?.flexHeader?.estimatedSettingsTime * 60 : data?.junction?.header?.internalOperation?.estimatedSettingsTime * 60) : convertSeconds(totalEstimatedTime);
  
    const startDate = data?.junction?.startDate;
    const endDate = data?.junction?.endDate;
    const madeWorkCount = data?.junction?.metadata?.madeWorkCount;
    const type = data?.junction?.type;
    const oneOrderTimeInSeconds = oneOrderTime(startDate, endDate, madeWorkCount, type);
    const formattedOneOrderTime = convertSeconds(oneOrderTimeInSeconds);
  
    const isRed = data?.junction?.type == "setup" ? setupEstimatedTime < oneOrderTimeInSeconds : totalEstimatedTime < oneOrderTimeInSeconds
  return (
    <div className='flex flex-col items-start'>
      <div
        className={`flex w-full flex-col border border-t-4 ${
          data?.junction?.type === "work" ? "border-t-success-500" : data?.junction?.type === "setup" ? "border-t-[#EAAA08]" : "border-t-[#98A2B3]"
        } items-start rounded-t-[4px] rounded-b-lg border-secondary-300 bg-white relative group`}
      >
        <div className="flex flex-row items-center w-full gap-x-[6px] pt-[6px] px-2 pb-[6px]">
          <div className="flex flex-row items-center gap-x-2 ">
            {data?.junction?.productType === "flex" ? (
              <>
              {data?.junction?.flexProduct?.image ? (
              <div>
                <CustomImage
                  borderRadius={2}
                  src={data?.junction?.flexProduct?.image}
                  style={{
                    width: 64,
                    height: 48,
                    minWidth: 64,
                    minHeight: 48,
                    borderRadius: 4,
                    borderWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}
              </>
            ):(
              <>
              {data?.junction?.job?.product?.image ? (
              <div>
                <CustomImage
                  borderRadius={2}
                  src={data?.junction?.job?.product?.image}
                  style={{
                    width: 64,
                    height: 48,
                    minWidth: 64,
                    minHeight: 48,
                    borderRadius: 4,
                    borderWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}
              </>
            )}
            
            <div className="flex flex-col items-start gap-y-1">
            <div className="flex flex-row items-center gap-x-1 w-full">
                {data?.junction?.productType === "flex" ? (
                  <>
                    <FlexProductIcon />
                    <p className="text-sm font-semibold text-secondary-900 w-[180px] truncate">{data?.junction?.flexProduct?.name || "--"}</p>
                  </>
                ) : (
                  <p className="text-sm font-semibold text-secondary-900 w-[180px] truncate">{data?.junction?.job?.product?.name || "--"}</p>
                )}
              </div>
              
              <div className="flex flex-row items-center gap-x-3">
                <div className="flex flex-row items-center">
                  <ClockIcon />
                  <p className="text-xs font-medium ml-1 text-secondary-600">
                  {/* {secondToHoursAndMinute2(data?.junction?.startDate, data?.junction?.endDate || new Date())} */}
                  {moment(data?.junction?.startDate).format("HH:mm") || "--"} - {moment(data?.junction?.endDate).format("HH:mm") || "--"}
                  </p>
                  <p className="text-xxs font-normal mt-[1px] ml-[2px] text-secondary-600">{t("product:hours")}</p>
                </div>
                <div className="flex flex-row items-center">
                  <PieceIcon />
                  <p className="text-xs font-medium ml-1 text-secondary-600">{data?.junction?.metadata?.madeWorkCount || "--"}</p>
                  <p className="text-xxs font-normal mt-[1px] ml-[2px] text-secondary-600">{t("product:pcs")}</p>
                </div>
              </div>
            </div>
          </div>
          {data?.junction?.station?.metadata?.image ? (
            <div
              data-tooltip-id={`${data?.id}`}
              data-tooltip-place={"left"}
              data-tooltip-content={`${data?.junction?.station?.metadata?.brand} ${data?.junction?.station?.metadata?.model}`}
              className="cursor-pointer"
            >
              
              <CustomImage
                src={data?.junction?.station?.metadata?.image}
                style={{
                  width: 72,
                  height: 54,
                  minWidth: 72,
                  minHeight: 54,
                  flexDirection: "column",
                  borderRadius: 4,
                  display: "flex",
                  borderColor: "#0000001A",
                  backgroundColor: "#fff",
                }}
              />
            </div>
          ) : (
            <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                <NoEquIcon />
              </div>
          )}
          <div className="flex flex-col z-[20] items-start rounded border-[0.5px] ml-auto absolute border-[#D0D5DD] w-[86px] min-w-[86px] bg-white top-[6px] right-[6px]">
          {data?.junction?.metadata?.qualityStatus === "waiting" ? (
                  <>
                    <div className={`flex flex-row items-center justify-center gap-x-1 h-5 min-h-5 px-[2px] py-[2px] ${data?.junction?.productType === "flex" ? "bg-[#F9F5FF]" : "bg-[#F9FAFB]"}  w-full rounded-t-[4px] border-b-[0.5px] border-secondary-300 ml-auto`}>
                      <p className="text-xxs text text-secondary-600 font-semibold">{t("product:applied")}</p>
                    </div>
                  </>
                ) : (
                  <div className={`flex flex-row items-center justify-center gap-x-1 h-5 min-h-5 px-[2px] py-[2px] ${data?.junction?.productType === "flex" ? "bg-[#F9F5FF]" : "bg-[#F9FAFB]"}  w-full rounded-t-[4px] border-b-[0.5px] border-secondary-300 ml-auto`}>
                    <p className="text-xxs text text-secondary-600 font-semibold">{t("product:qualityNew")}</p>
                    <div className="h-[14px] min-h-[14px] flex items-center justify-center bg-[#B54708] px-1 py-[1px] rounded">
                      <p className="text-xxs font-semibold text-white"> %{data?.junction?.productType === "flex" ? data?.junction?.flexHeader?.qualityPercent || "--" : data?.junction?.header?.internalOperation?.qualityPercent || "--"}</p>
                    </div>
                  </div>
                )}
            <div className="flex flex-row items-center justify-center w-full px-[6px] py-1">
              <div className="w-[14px] h-[14px] min-w-[14px] min-h-[14px]">
              {data?.junction?.metadata?.qualityStatus === "waiting" ? <PieceIcon /> : <AcceptedIcon />}
              </div>
              <p className={`text-xs font-medium ml-1 ${data?.junction?.metadata?.qualityStatus === "waiting" ? "text-secondary-600" : "text-[#079455]"} `}>
                    {data?.junction?.metadata?.qualityStatus === "waiting" ? data?.junction?.madeWorkCount || "0" : data?.junction?.metadata?.acceptedWorkCount}
                  </p>
              <p className="text-[10px] font-normal ml-0.5 text-secondary-500">{t("product:pcs")}</p>
            </div>
            <div className="flex flex-row items-center justify-center w-full px-[6px] py-1">
              <div className="w-[14px] h-[14px] min-w-[14px] min-h-[14px]">
              {data?.junction?.metadata?.qualityStatus === "waiting" ? <EquCalculate /> : <RejectedIcon />}
              </div>
              <p className={`text-xs font-medium ${data?.junction?.metadata?.qualityStatus === "waiting" ? "text-[#6941C6]" : "text-error-600"} ml-1 `}>
                  {data?.junction?.metadata?.qualityStatus === "waiting" ? data?.junction?.metadata?.calculateWorkCount || "0" : data?.junction?.metadata?.rejectedWorkCount || "0"}
                  </p>
              <p className="text-xxs font-normal ml-0.5 text-secondary-500">{t("product:pcs")}</p>
            </div>
          </div>
        </div>
        <div className={`flex flex-row items-center gap-x-4 h-[26px] min-h-[26px] w-full ${data?.junction?.productType === "flex" ? "bg-[#F9F5FF]" : "bg-[#F9FAFB]"} border-t-[0.5px] border-t-secondary-300 pb-[3px] pl-2 pt-[3px] pr-[3px] rounded-b-lg`}>
          <div className="flex flex-row items-center">
            <TargetIcon />
            <p className="text-xs font-medium ml-1 text-secondary-600">{formattedEstimatedOpTime || "--"}</p>
            <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:targetMinActualss")}</p>
          </div>
          <div className="flex flex-row items-center">
            {isRed ? <RedAvgIcon /> : <GreenAvgIcon />}
            <p className={`text-xs font-medium ml-1 ${isRed ? "text-[#D92D20]" : "text-[#079455]"}`}>{formattedOneOrderTime || "--"}</p>
            <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:average")}</p>
          </div>
          {/* backendde yapilinca eklenecek 12.06.2024 */}
          {/* <div className="flex flex-row items-center">
        <MadePlayIcon />
        <p className="text-xs font-medium ml-1 text-[#CA8504]">{data?.madeTime || "--"}</p>
        <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:doneMin")}</p>
      </div> */}
        </div>

        {(data?.junction?.header?.step || data?.junction?.flexHeader?.step) === undefined ? null : (
          <span className="absolute left-1 top-1">
            <Badgets colorType={"fill-gray"} label={data?.junction?.productType === "flex" ? data?.junction?.flexHeader?.step + ".op" : data?.junction?.header?.step + ".op"} size={"sm"} />
          </span>
        )}
      </div>
      <div className="flex flex-row min-w-[440px] max-w-[440px] items-center gap-x-3 w-full mt-3">
            {selectedJunction?.id === data?.junction?.id ? (
              <div className="flex items-center justify-between w-full">
                <div className="flex flex-row items-center gap-x-1">
                  <JunctionNumberIconSelected color={"#6941C6"} />
                  <p className="text-md font-semibold text-[#6941C6]">{junctionNumber(data?.junction?.id)}</p>
                </div>
              </div>
            ) : (
              <div className="flex flex-row items-center gap-x-1">
                <JunctionNumberIcon color={"#667085"} />
                <p className="text-md font-semibold text-secondary-600">{junctionNumber(data?.junction?.id)}</p>
              </div>
            )}
            <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
              <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                <p className="text-xs font-normal text-secondary-600">{"upu.point"}</p>
              </div>
              <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                <p className={`text-base w-[100px] text-center truncate font-semibold text-[#9B7D07]`}>{data?.junction?.metadata?.upuPoint?.toFixed(2) || "0"}</p>
              </div>
            </div>
            <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
              <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                <p className="text-xs  font-normal text-secondary-600">{t("reports:performance")}</p>
              </div>
              <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                <p className={`text-base w-[100px] truncate text-center font-semibold text-[#079455]`}>
                  %{data?.junction?.metadata?.performancePercent > 1 ? 1 * 100 : (data?.junction?.metadata?.performancePercent * 100)?.toFixed(0) || "0"}
                </p>
              </div>
            </div>
            <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
              <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                <p className="text-xs font-normal text-secondary-600">{t("product:qualityControlRate")}</p>
              </div>
              <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                <p className={`text-base w-[100px] truncate text-center font-semibold text-secondary-600`}>
                  %{data?.junction?.metadata?.qualityPercent > 1 ? 1 * 100 : (data?.junction?.metadata?.qualityPercent * 100)?.toFixed(0) || "0"}
                </p>
              </div>
            </div>
          </div>
    </div>
  )
}

export default PersonnelDetailTimelineDoneCard
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button, CustomModal } from "../../components";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useStore } from "../../hooks/useStores";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { junctionService } from "../../services/junction.service";
import { orderService } from "../../services/order.service";
import NewOrderHeaderCard from "./components/order/NewOrderHeaderCard";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import diacritics from "diacritics";
import DroppedJunctionPlan from "./components/DroppedJunctionPlan";
import SelectUserModal from "../../components/modal/SelectUserModal";
import SelectProductModal from "../../components/modal/SelectProductModal";
import FinishJunctionModal from "../../components/modal/FinishJunctionModal";
import InternalPlanViewPlannedCard from "./components/order/InternalPlanViewPlannedCard";
import InternalPlannedStationCard from "./components/order/InternalPlannedStationCard";
import InternalPlannedActiveCard from "./components/order/InternalPlannedActiveCard";
import InternalPlannedPlannedCard from "./components/order/InternalPlannedPlannedCard";
import InternalPlanViewNextPlanCard from "./components/order/InternalPlanViewNextPlanCard";
import InternalPlannedPassiveCard from "./components/order/InternalPlannedPassiveCard";
import InternalPlannedDifferentPlannedCard from "./components/order/InternalPlannedDifferentPlannedCard";
import { Toggle } from "../machine/Twin/components";
import { ScrollChevronIcon } from "../../assets/icons/productIcons";
import SearchInput from "../../components/inputs/SearchInput";
import Badgets from "../../components/buttons/Badgets";
import useWindowDimensions from "../../hooks/useWindowDimension";
const InternalOperationPlanView = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { settings, auth } = useStore();
  let navigate = useNavigate();
  const { height } = useWindowDimensions();
  const [selectedGroup, setSelectedGroup] = useState("all");
  const [tableHeadItemsLength, setTableHeadItemsLength] = useState(0);
  const [drappedEquipment, setDrappedEquipment] = useState(null);
  const [isOpenPlanningModal, setIsOpenPlanningModal] = useState(false);
  const [isOpenSelectUserModal, setIsOpenSelectUserModal] = useState(false);
  const [isOpenSelectProductModal, setIsOpenSelectProductModal] = useState(false);
  const [isOpenFinishJunction, setIsOpenFinishJunction] = useState(false);
  const [rowLoading, setRowLoading] = useState(null);
  const [draggedProduct, setDraggedProduct] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedJunction, setSelectedJunction] = useState(null);
  const [arr, setArr] = useState([]);
  const [showFlexProducts, setShowFlexProducts] = useState(true);
  const planView = useQuery({
    queryKey: ["operations-junctions-planned"],
    enabled: false,
    queryFn: async () => await junctionService.getInternalOperationPlanview(id),
    retry: 0,
  });
  const headData = useQuery({
    queryKey: ["all-operations-junctions"],
    enabled: false,
    retry: 0,
    queryFn: async () => await orderService.getOrderHeadData(),
  });
  const refetch = () => {
    planView.refetch();
    headData.refetch();
    setRowLoading(null);
  };
  useEffect(() => {
    headData.refetch();
    if (id != ":id" && id != "undefined") {
      planView.refetch();
    }
  }, [id]);
  useEffect(() => {
    if (headData?.data?.data?.operation?.length > 0 && (id == ":id" || id == "undefined")) {
      navigate(`/app/product/mrp/supply/operation/${headData?.data?.data?.operation[0]?.id}/plan-view`);
    } else {
      setSelectedGroup(headData?.data?.data?.operation?.find((item) => item?.id == id));
    }
  }, [headData?.data]);
  useEffect(() => {
    if (auth?.user?.company?.id == "e5c06127-3c5d-44b9-8403-74c160fc7f59") {
    } else if (auth?.user?.company?.id == "ab77a50a-7b68-42a2-ba5c-0ebfb5c13325") {
    } else if (auth?.user?.company?.id == "71b0edec-e5d4-4929-bfdb-0e5d5e149592") {
    } else if (auth?.user?.company?.id == "dd1892b9-d9a3-44ed-9131-17c88a74a1b4") {
    } else if (auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706") {
    } else if (auth?.user?.company?.id == "49ee8491-35d7-4eb2-a0e9-bfc95541180f") {
    } else if (auth?.user?.company?.id == "65844198-a9e8-45fa-bc99-7d012a541b35") {
    } else if (auth?.user?.company?.id == "7c5bb5a6-21aa-4d1d-829b-2a6d360b9630") {
    } else if (auth?.user?.company?.id == "f7db6191-5842-455a-99f4-c76763090689") {
    } else if (auth?.user?.company?.id == "776c0df3-0a44-464f-9877-33bd731aef6a") {
    } else if (auth?.user?.company?.id == "065e9b57-9da2-4394-9359-59ba71610dc3") {
    } else if (auth?.user?.company?.id == "e41a9bde-203c-48f2-aeec-4f8ce6344513") {
    } else if (auth?.user?.company?.id == "262d8e02-57e8-427e-9159-6b18b236ca86") {
    } else if (auth?.user?.company?.id == "0c082d32-6d97-4ba9-a2a5-7e41fe8139d9") {
    } else {
      navigate("/app/product/verysoon");
    }
  }, []);
  const handleHeaderLength = useCallback(() => {
    if (!planView?.data?.equipments) return;
    const headLengths = planView?.data.equipments.map((d) => (d.plannedJunction?.length || 0) + (d.activeJunction?.length || 0));
    setTableHeadItemsLength(Math.max(...headLengths, 2));
  }, [planView?.data]);

  const handleDrop = (node) => {
    setDrappedEquipment(node);
    setIsOpenPlanningModal(true);
  };
  const onDragEnd = async (result) => {
    if (!result.destination) return;
    const newItems = Array.from(arr);
    const findChangedRowData = newItems[Number(result.source.droppableId)];
    const destIndex = findChangedRowData.activeJunction.length > 0 ? result.destination.index - 1 : result.destination.index;
    const findByIndex = findChangedRowData.plannedJunction.findIndex((element) => element.id === result.draggableId);
    const movedData = newItems[Number(result.source.droppableId)].plannedJunction.splice(findByIndex, 1)[0];
    newItems[Number(result.source.droppableId)].plannedJunction.splice(destIndex, 0, movedData);
    const newArr = newItems[Number(result.source.droppableId)].plannedJunction.filter(Boolean).map((obj, ind) => ({ ...obj, planningOrder: ind }));
    setRowLoading(Number(result.source.droppableId));
    setArr(newItems.map((obj) => (obj.id === newItems[Number(result.source.droppableId)].id ? newItems[Number(result.source.droppableId)] : obj)));
    await junctionService.updatePlanningJunctionList(newArr);
    refetch();
  };

  const outerDivRef = useRef(null);
  const mappedDivRefs = useRef([]);

  const handleScroll = (source, index = null) => {
    let currentScrollLeft;

    if (source === "outer") {
      currentScrollLeft = outerDivRef.current?.scrollLeft;
    } else if (source === "mapped") {
      currentScrollLeft = mappedDivRefs.current[index]?.scrollLeft;
    }

    if (outerDivRef.current && source !== "outer") {
      outerDivRef.current.scrollLeft = currentScrollLeft;
    }

    mappedDivRefs.current.forEach((div, idx) => {
      if (div && idx !== index) {
        div.scrollLeft = currentScrollLeft;
      }
    });
  };

  const scrollContainerRef = useRef(null);

  const handleDragUpdate = (event) => {
    const scrollContainer = scrollContainerRef.current;

    if (!scrollContainer) return;

    const { clientWidth, scrollWidth, scrollLeft } = scrollContainer;
    const buffer = 50;
    const edgeDistance = 20;

    if (event?.destination) {
      const dragOffset = event.source.index * 360;
      const dragRight = dragOffset + buffer;
      const dragLeft = dragOffset - buffer;

      if (dragRight > scrollLeft + clientWidth) {
        scrollContainer.scrollBy({ left: edgeDistance, behavior: "smooth" });
      }

      if (dragLeft < scrollLeft) {
        scrollContainer.scrollBy({ left: -edgeDistance, behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    if (id) {
      setArr(planView?.data?.equipments);
      handleHeaderLength();
    }
  }, [planView?.data, handleHeaderLength, id]);
  const filtered = planView?.data?.orderList?.filter((item) => {
    const nameProcessed = diacritics.remove(
      item?.internalOperation?.product?.name?.toLowerCase() || item?.name?.toLowerCase() || item?.flexProduct?.name?.toLowerCase() || ""
    );
    const stockCodeProcessed = diacritics.remove(
      item?.orderData?.orderNo?.toLowerCase() || item?.stockCode?.toLowerCase() || item?.flexProduct?.stockCode?.toLowerCase() || ""
    );
    const searchQueryProcessed = diacritics.remove(searchQuery.toLowerCase());
    const res = nameProcessed.includes(searchQueryProcessed) || stockCodeProcessed.includes(searchQueryProcessed);
    return !(item?.flexProduct || item?.flexRawMaterial || item?.name) ? res : showFlexProducts ? res : null;
  });
  const filteredData = filtered?.filter((item) => item != null);
  const groupedData = filteredData?.reduce((acc, item) => {
    const productName = item?.internalOperation?.product?.name;
    if (!acc[productName]) {
      acc[productName] = [];
    }
    acc[productName].push(item);
    return acc;
  }, {});
  const orderedGroupKeys = Object.keys(groupedData || {}).sort();
  const tableHeadItems = [];
  for (let i = 0; i < tableHeadItemsLength; i++) {
    tableHeadItems.push({ title: `${i - 1}`, id: i });
  }
  const tabOptions = [
    { value: "plan", label: t("product:planner") },
    { value: "junction", label: t("product:eventss") },
  ];
  return (
    <div className="w-full h-full flex flex-col overflow-hidden overflow-y-auto scrollbar-hide">
      <>
        <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden relative step-1 scrollbar-hide">
          <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
            <span className="mr-3 md:flex sm:flex xs:flex hidden">
              <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
            </span>
            <div className="flex items-center">
              <span className="font-codecMedium text-2xl text-black">Operations</span>
              {/* <p className="text-2xl font-codecMedium text-[#B54708]">planner</p> */}
              {!(headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading) && (
                <p className="text-xl font-normal ml-2 text-secondary-500">
                  {selectedGroup && selectedGroup?.name ? `${selectedGroup?.name}` : "Internal Operations Planning"}
                </p>
              )}
            </div>
          </div>
          {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
            <div className="w-full bg-gray-200 rounded-xl animate-pulse h-[96px] min-h-[96px] flex gap-5" />
          ) : (
            <div className="h-[116px] min-h-[116px] w-full pb-0 overflow-x-auto flex ">
              {headData?.data?.data?.operation
                ?.sort((first, second) => second?.total - first?.total)
                ?.map((item, index) => {
                  return (
                    <div className={`min-w-[192px] ${index !== headData?.data?.data?.operation?.length - 1 ? "border-b" : "border-b-0"} max-h-[96px] pr-5`}>
                      <NewOrderHeaderCard
                        onClick={async () => {
                          navigate(`/app/product/mrp/supply/operation/${item?.id}/plan-view`);
                        }}
                        icon={item?.iconKey}
                        name={item?.name}
                        planned={Number(item?.plannedWorkLoad / 86400).toFixed(1)}
                        unPlanned={Number((item?.total - item?.plannedWorkLoad) / 86400).toFixed(1)}
                        selectedOperation={{ id: selectedGroup?.id, name: item?.name }}
                        id={item?.id}
                      />
                    </div>
                  );
                })}
            </div>
          )}
          {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
            <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-gray-200 p-[6px] mt-6 mb-4 rounded-xl animate-pulse flex gap-5" />
          ) : (
            <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-[#F9FAFB] border border-[#EAECF0] p-[6px] rounded-xl flex items-center justify-center mt-6 mb-4">
              {tabOptions?.map((option, index) => (
                <div
                  className={`w-1/2 h-full rounded-md px-3 py-2  flex items-center justify-center cursor-pointer ${
                    "plan" == option?.value ? "bg-white shadow-sm" : ""
                  }`}
                  onClick={() => {
                    if (option?.value != "plan") navigate(`/app/product/mrp/supply/operation/${id}/junction`);
                  }}
                >
                  <p className={`text-md font-semibold ${"plan" == option?.value ? "text-[#344054]" : "text-[#667085]"}`}>{option?.label}</p>
                </div>
              ))}
            </div>
          )}
          {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
            <div className="w-full flex-1 bg-gray-200 mt-4 rounded-xl animate-pulse" />
          ) : (
            <>
              <div className="flex border flex-col rounded-xl">
                <div className="flex w-full px-6 py-3 items-center border-b mb-2">
                  <div className="flex w-full min-h-[28px] max-h-[28px] gap-x-2 items-center">
                    <p className="flex text-[#101828] font-semibold text-lg">
                      {planView?.data?.name} {t("product:products")}
                    </p>
                    <Badgets colorType={"outline-warning"} label={filteredData?.length} />
                  </div>
                  <div className="flex flex-row items-center gap-x-3 justify-end w-full">
                    <div className="flex max-w-[320px] w-full justify-end">
                      <SearchInput theme={"product"} setSearchVal={setSearchQuery} />
                    </div>
                    <div className="flex  gap-x-4 items-center justify-end">
                      <p className="text-[#6941C6] font-semibold text-sm">{t("product:showFlex")}</p>
                      <Toggle enabled={showFlexProducts} setEnabled={(enabled) => setShowFlexProducts(enabled)} size={"sm"} />
                    </div>
                  </div>
                </div>
                <div className="flex w-full px-4 pb-3">
                  <div className="flex flex-col gap-y-1 w-[130px] min-w-[130px]">
                    <div className="flex flex-col gap-y-1 items-center w-[130px] min-w-[130px] pr-4">
                      <div className="flex flex-col gap-y-1 border border-[#D0D5DD] px-3 py-[6px] rounded w-full">
                        <p className="text-xs font-normal text-secondary-600 ">{t("product:totalWorkload")} </p>
                        <p className="text-sm font-medium text-secondary-700">
                          {Number(planView?.data?.totalWorkLoad / 86400).toFixed(1) || 0} {t("product:day")}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-1 items-center w-[130px] min-w-[130px] pr-4">
                      <div className="flex flex-col gap-y-1 border border-[#D0D5DD] px-3 py-[6px] rounded w-full">
                        <p className="text-xs font-normal text-secondary-600">{t("product:stationAvg")}</p>
                        <p className="text-sm font-medium text-secondary-700">
                          {Number(planView?.data?.totalWorkLoad / 86400 / planView?.data?.equipments?.length)?.toFixed(1) || 0} {t("product:day")}
                        </p>
                      </div>
                    </div>
                  </div>
                  {planView?.data?.orderList?.length > 0 && (
                    <div className="w-full  overflow-x-auto flex  overflow-y-hidden relative">
                      {orderedGroupKeys.map((productName) => {
                        const items = groupedData[productName];
                        return (
                          <div className="flex" key={productName}>
                            {items.map((item) => {
                              const statusColor = () => {
                                if (item?.flexProduct || item?.flexRawMaterial || item?.name) {
                                  return "#E9D7FE";
                                } else {
                                  switch (item?.status) {
                                    case "finished":
                                      return "#12B76A";
                                    case "started":
                                      return "#FDE172";
                                    case "rejected":
                                      return "#D92D20";
                                    case "flexProduct":
                                      return "#E9D7FE";
                                    default:
                                      return "#F2F4F7";
                                  }
                                }
                              };
                              return <InternalPlanViewPlannedCard item={item} statusColor={statusColor} setDraggedProduct={setDraggedProduct} />;
                            })}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div style={{ minHeight: height - 270 }} className=" w-full mt-4 border rounded-xl border-gray-300  flex flex-col overflow-hidden relative">
                <div className="flex flex-row w-full h-11 min-h-[44px] bg-[#F9FAFB] border-[#EAECF0] rounded-tl-xl rounded-tr-xl border-b">
                  <div
                    className={` min-h-[43px] flex items-center justify-between max-w-[233px] min-w-[233px] px-6 py-3 text-sm bg-[#F9FAFB] font-semibold text-[#344054] border-r border-secondary-200`}
                  >
                    <p className="text-sm text-[#344054] font-semibold">{t("product:stations")}</p>
                  </div>
                  <div
                    ref={outerDivRef}
                    onScroll={() => handleScroll("outer")}
                    id="content-wrapper"
                    className="flex items-center w-full h-full overflow-x-auto overflow-y-hidden"
                  >
                    {tableHeadItems.map((item, index) => (
                      <div
                        key={index}
                        className={` min-h-[44px] flex items-center justify-between min-w-[360px] px-6 py-3 text-sm bg-[#F9FAFB] font-semibold text-[#344054] border-r border-secondary-200`}
                      >
                        {index === 0 ? (
                          <>
                            <p className="text-sm text-[#344054] font-semibold">{t("product:active")}</p>
                          </>
                        ) : index === 1 ? (
                          <>
                            <p className="text-sm text-[#344054] font-semibold">{t("product:plannedWorks")}</p>
                            {item?.title}
                          </>
                        ) : (
                          <>
                            <p></p>
                            {item?.title}
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="flex-1 w-full flex flex-col overflow-y-auto scrollbar-hide bg-white">
                  {arr?.map((a, b) => {
                    let filledData = [
                      ...(a?.activeJunction || []),
                      ...(a.plannedJunction?.sort((a, b) => a?.planningOrder - b?.planningOrder) || []),
                      ...Array.from(
                        {
                          length: Math.max(tableHeadItemsLength - a?.plannedJunction?.concat(a?.activeJunction)?.length, 0),
                        },
                        () => null
                      ),
                    ];
                    return (
                      <DragDropContext onDragEnd={onDragEnd} onDragUpdate={handleDragUpdate}>
                        <Droppable droppableId={`${b}`} direction="horizontal">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              onDragOver={(e) => e.preventDefault()}
                              onDrop={() => handleDrop(a)}
                              className="flex w-full min-h-[106px]"
                            >
                              <div className={`flex w-[233px] sticky z-[50] h-full min-w-[233px] max-w-[233px] pl-6 pr-3 bg-white py-2 border-r border-b`}>
                                <InternalPlannedStationCard filledData={filledData} a={a} />
                              </div>

                              <div
                                ref={(el) => {
                                  scrollContainerRef.current = el;
                                  provided.innerRef(el);
                                  mappedDivRefs.current[b] = el;
                                }}
                                onScroll={() => handleScroll("mapped", b)}
                                id="content-wrapper"
                                className="w-full overflow-x-auto overflow-y-hidden scrollbar-hide flex border-b border-gray-200 max-h-[106px] m-0 p-0"
                              >
                                {filledData?.map((item, index) => {
                                  if (item) {
                                    if (item?.status === "active") {
                                      return (
                                        <div
                                          className={`min-w-[360px] max-w-[360px] z-50 flex items-center justify-center py-2 border-r border-gray-200 px-3 bg-white cursor-pointer`}
                                        >
                                          <div className="cursor-pointer w-full">
                                            <InternalPlannedActiveCard
                                              data={item}
                                              setIsOpenFinishJunction={setIsOpenFinishJunction}
                                              setSelectedJunction={setSelectedJunction}
                                              refetch={refetch}
                                            />
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      if (b === rowLoading) {
                                        return (
                                          <div className="min-w-[360px] max-w-[360px] flex items-center justify-center py-2 border-r border-gray-200 px-3 bg-white">
                                            <div className="w-full h-full rounded-lg bg-gray-200 animate-pulse" />
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <Draggable key={item?.id} draggableId={item?.id} index={index}>
                                            {(provided, snapshot) => (
                                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <div
                                                  className={`min-w-[360px] max-w-[360px] flex items-center justify-center py-2 border-r min-h-[106px] max-h-[106px] border-gray-200 px-3 bg-white`}
                                                >
                                                  {item?.status === "planned" ? (
                                                    <>
                                                      {index === 0 ? (
                                                        <div className="relative w-full group">
                                                          <InternalPlannedPlannedCard
                                                            data={item}
                                                            refetch={refetch}
                                                            setIsOpenSelectUserModal={setIsOpenSelectUserModal}
                                                            setSelectedJunction={setSelectedJunction}
                                                            setIsOpenFinishJunction={setIsOpenFinishJunction}
                                                          />
                                                        </div>
                                                      ) : (
                                                        <InternalPlanViewNextPlanCard
                                                          item={item}
                                                          operationId={id}
                                                          handlePlanData={planView?.refetch}
                                                          handleHeaderData={headData?.refetch}
                                                        />
                                                      )}
                                                    </>
                                                  ) : (
                                                    <InternalPlannedDifferentPlannedCard item={item} />
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                          </Draggable>
                                        );
                                      }
                                    }
                                  } else {
                                    return (
                                      <>
                                        {index === 0 ? (
                                          <div
                                            className={`min-w-[360px] max-w-[360px] flex items-center justify-center py-2 border-r border-gray-200 px-3 bg-white`}
                                          >
                                            <InternalPlannedPassiveCard
                                              setIsOpenSelectProductModal={setIsOpenSelectProductModal}
                                              setDrappedEquipment={setDrappedEquipment}
                                              a={a}
                                            />
                                          </div>
                                        ) : (
                                          <div className="min-w-[360px] max-w-[360px]" />
                                        )}
                                      </>
                                    );
                                  }
                                })}
                                {filledData?.length < 1 && (
                                  <div className="min-w-[360px] max-w-[360px] min-h-[106px] max-h-[106px] flex items-center justify-center py-2 border-r border-gray-200 px-3 bg-white">
                                    <InternalPlannedPassiveCard
                                      setIsOpenSelectProductModal={setIsOpenSelectProductModal}
                                      setDrappedEquipment={setDrappedEquipment}
                                      a={a}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    );
                  })}
                </div>
              </div>
            </>
          )}
          {draggedProduct && (
            <DroppedJunctionPlan
              nodeData={drappedEquipment}
              orderData={draggedProduct}
              isOpen={isOpenPlanningModal}
              setIsOpen={setIsOpenPlanningModal}
              refetch={refetch}
              processId={planView?.data?.id}
            />
          )}
        </div>
        <CustomModal
          isOpen={isOpenSelectUserModal}
          setIsOpen={setIsOpenSelectUserModal}
          onClose={() => {
            setIsOpenSelectUserModal(false);
          }}
          modalTitle={t("chat:startJob")}
          width="fit-content"
          children={
            <SelectUserModal
              t={t}
              setIsOpen={setIsOpenSelectUserModal}
              isOpen={isOpenSelectUserModal}
              selectedJunction={selectedJunction}
              handleData={refetch}
            />
          }
        />
        <CustomModal
          isOpen={isOpenSelectProductModal}
          setIsOpen={setIsOpenSelectProductModal}
          onClose={() => {
            setIsOpenSelectProductModal(false);
          }}
          modalTitle={t("chat:startJob")}
          width="fit-content"
          children={
            <SelectProductModal
              setIsOpenSelectProductModal={setIsOpenSelectProductModal}
              filteredData={filteredData}
              setIsOpenPlanningModal={setIsOpenPlanningModal}
              setDraggedProduct={setDraggedProduct}
            />
          }
        />
        <CustomModal
          isOpen={isOpenFinishJunction}
          setIsOpen={setIsOpenFinishJunction}
          modalTitle={t("product:finishJuction")}
          children={
            <FinishJunctionModal
              handleData={refetch}
              modalTheme={"product"}
              junctionId={selectedJunction?.id}
              closeModal={() => setIsOpenFinishJunction(false)}
              type={selectedJunction?.internalOperation ? "internal" : "external"}
              productType={selectedJunction?.productType}
            />
          }
        />
      </>
    </div>
  );
};

export default InternalOperationPlanView;

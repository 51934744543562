import React, { useEffect, useState } from "react";
import { Button, Input, TimeInput, UploadProfile } from "../../../components";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneInput from "../../../components/inputs/PhoneInput";
import moment from "moment";
import { personalService } from "../../../services/personal.service";
import { roleService } from "../../../services/role.service";
import i18next from "i18next";
import Selector from "../../../components/inputs/Selector";
import { Toast } from "../../../utils/toastify/toast";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckIcon } from "../../../assets/icons/stepsIcons";

const AddUserModal = ({
  handleData,
  selectedPersonal,
  setSelectedPersonal,
  setIsOpen,
}) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [perms, setPerms] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [isPhone, setIsPhone] = useState(window?.innerWidth < 575);
  const [isDepartmentsAvailable, setIsDepartmentsAvailable] = useState(false);

  const schema = yup.object({
    id: yup.string().notRequired(),
    name: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    lastName: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    department: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    email: yup
      .string()
      .email()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:email")}`),
    avatar: yup.string(),
    authorization: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    startDate: yup
      .date()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:date")}`),
    upuPoint: yup
      .number()
      // .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    totalCost: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    salary: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    talents: yup.array().notRequired(),
    gains: yup.array().notRequired(),
    phoneNumber: yup.string().required(`${t("validation:empty")}`),
    checked: yup.boolean(),
  });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const getDepartmant = async () => {
    const { data } = await personalService.getDepartments();
    if (data?.length < 0) {
      Toast("warning", t("step:nullDepartment"));
      setIsDepartmentsAvailable(false);
      setTimeout(() => {
        navigate("/step/department");
      }, 2000);
    } else {
      setIsDepartmentsAvailable(true);
    }
    setDepartments(
      data.map((e) => {
        return {
          label: e.name,
          value: e.id,
        };
      })
    );
  };

  const handleUser = async () => {
    setValue("id", selectedPersonal?.id);
    setValue("avatar", selectedPersonal?.avatar);
    setValue("name", selectedPersonal?.name);
    setValue("authorization", selectedPersonal?.roleId);
    setValue("lastName", selectedPersonal?.lastName);
    setValue("phoneNumber", selectedPersonal?.phoneNumber);
    setValue("email", selectedPersonal?.email);
    setValue(
      "startDate",
      selectedPersonal?.metadata?.startDate
        ? moment(selectedPersonal?.metadata?.startDate).format(
            "YYYY-MM-DDTHH:mm:ss.sssZ"
          )
        : new Date()
    );
    setValue("gains", selectedPersonal?.talents);
    setValue("talents", selectedPersonal?.processes);
    setValue("upuPoint", selectedPersonal?.upuPoint);
    setValue("totalCost", selectedPersonal?.metadata?.totalCost);
    setValue("salary", selectedPersonal?.metadata?.salary);
    setValue("department", selectedPersonal?.metadata?.department?.id);
  };

  const addAbilitysAndTalents = async (id, talents, gains) => {
    if (talents?.length > 0) {
      talents.forEach(async (ability) => {
        const sendData = {
          userId: id,
          process: ability.talentName,
          points: ability.talentPoint,
          invokDate: ability.talentDate,
        };
        const { data } = await personalService.addProcess(sendData);
      });
    }

    if (gains?.length > 0) {
      gains.forEach(async (prize) => {
        const sendData = {
          id: prize.id,
          name: prize.name,
          institution: prize.institution,
          pointsReceived: prize.pointsReceived,
          fullPoints: prize.fullPoint,
          certificateNumber: prize.certficationNo,
          image: prize.file,
          invokeDate: prize.date,
          userId: id,
        };

        const { data } = await personalService.addTalent(sendData);
      });
    }

    return true;
  };

  const onSubmit = async (data) => {
    var sendData = {
      name: data.name,
      lastName: data.lastName,
      avatar: data.avatar || "",
      email: data.email,
      salaryCurrency: data?.salaryCurrency || "₺",
      authorization: data.authorization,
      phoneNumber: data.phoneNumber,
      processes:
        data.talents?.map((d) => {
          delete d.unique;
          return {
            ...d,
          };
        }) || [],
      talents:
        data.gains?.map((d) => {
          delete d.unique;
          return {
            ...d,
          };
        }) || [],
      metadata: {
        upuPoint: data.upuPoint || 0,
        totalCost: data.totalCost,
        salaryCurrency: data.salaryCurrency || "₺",
        salary: data.salary,
        description: data.description || "",
        startDate: data.startDate,
        department: data.department,
      },
    };

    if (selectedPersonal && selectedPersonal?.id) {
      await personalService
        .updatePersonal(selectedPersonal?.id, sendData)
        .then(async ({ data }) => {
          const result = await addAbilitysAndTalents(
            selectedPersonal.id,
            data.talents,
            data.gains
          );
          handleData();
          setIsOpen(false);
          Toast("success", t("addPersonal:updatedPersonalMessage"));
        });
    } else {
      personalService.addPersonal(sendData).then((response) => {
        if (response?.data?.code == 0) {
          handleData();
          setIsOpen(false);
          Toast("success", t("addPersonal:addedNewPersonalMessage"));
        } else if (response?.data?.code == 2) {
          setIsOpen(false);
          Toast("error", t("addPersonal:noLimit"));
        } else if (response?.data?.code == 3) {
          handleData();
          setIsOpen(false);
          Toast("success", t("addPersonal:personalAddedButLimit"));
        } else if (response?.data?.code == 4) {
          setIsOpen(false);
          Toast("error", t("addPersonal:mailInUse"));
        } else {
          setIsOpen(false);
          Toast("error", t("product:errors"));
        }
      });
    }
  };

  useEffect(() => {
    roleService.getRoles().then((roles) => {
      let selectPerms = roles?.data?.map((role) => {
        return {
          label: role?.name[i18next?.language],
          value: role?._id,
        };
      });

      setPerms(selectPerms);
    });
    getDepartmant();
    if (selectedPersonal && selectedPersonal?.id) {
      handleUser();
    }
  }, []);

  useEffect(() => {
    const handleSize = () => {
      setIsPhone(window?.innerWidth < 575);
    };
    window.addEventListener("resize", handleSize);
    return () => {
      window.removeEventListener("resize", handleSize);
    };
  }, []);

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col items-start w-[710px] sm:max-w-[500px] md:w-[688px] xs:w-[335px] lg:w-[710px] xs:h-[600px] h-full pt-6"
      >
        <div className="flex flex-row items-start w-full h-full xs:flex-col  xs:overflow-y-scroll xs:scrollbar-hide gap-6">
          <div className="flex flex-col gap-4 w-full h-full">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <UploadProfile
                  rounded
                  // theme={theme}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  title={t("personals:profilePicture")}
                  subTitle={t("fileInput:updatePicture")}
                  borderRadius={100}
                />
              )}
              name="avatar"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={"ID"}
                  disabled={true}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  validate={errors.id ? "error" : ""}
                />
              )}
              name="id"
            />

            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Selector
                  label={t("table:authorization")}
                  items={perms}
                  onChange={onChange}
                  value={value}
                  theme={pathname.includes("personnel") ? "person" : ""}
                  validate={errors.authorization ? "error" : ""}
                  errorMessage={
                    errors.authorization ? errors.authorization.message : ""
                  }
                />
              )}
              name="authorization"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("addPersonal:name")}
                  disabled={false}
                  onBlur={onBlur}
                  theme={pathname.includes("personnel") ? "person" : ""}
                  onChange={onChange}
                  value={value}
                />
              )}
              name="name"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("addPersonal:surname")}
                  width={"100%"}
                  disabled={false}
                  onBlur={onBlur}
                  onChange={onChange}
                  theme={pathname.includes("personnel") ? "person" : ""}
                  value={value}
                  validate={errors.lastName ? "error" : ""}
                  errorMessage={errors.lastName ? errors.lastName.message : ""}
                />
              )}
              name="lastName"
            />
            <Controller
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  label={t("phone:phoneNumber")}
                  // type={"number"} //waiting for change in api then uncomment it [it comes in string format instead of integer]
                  width={"100%"}
                  disabled={false}
                  value={value}
                  onChange={onChange}
                  theme={pathname.includes("personnel") ? "person" : "machine"}
                  validate={errors.phoneNumber ? "error" : ""}
                  errorMessage={
                    errors.phoneNumber ? errors?.phoneNumber?.message : ""
                  }
                />
              )}
              name="phoneNumber"
            />
          </div>
          <div className="flex flex-col gap-4 w-full h-full">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("addPersonal:email")}
                  disabled={false}
                  onBlur={onBlur}
                  onChange={onChange}
                  theme={pathname.includes("personnel") ? "person" : "machine"}
                  value={value}
                  validate={errors.email ? "error" : ""}
                  errorMessage={errors.email ? errors.email.message : ""}
                />
              )}
              name="email"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TimeInput
                  label={t("addPersonal:emplyDate")}
                  onChange={onChange}
                  theme={pathname.includes("personnel") ? "person" : "machine"}
                  value={
                    value ? moment(value).format("YYYY-MM-DD") : new Date()
                  }
                />
              )}
              name="startDate"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("addPersonal:upuPoint")}
                  disabled={true}
                  onBlur={onBlur}
                  onChange={onChange}
                  theme={pathname.includes("personnel") ? "person" : "machine"}
                  value={value}
                  type={"number"}
                  validate={errors.upuPoint ? "error" : ""}
                  errorMessage={errors.upuPoint ? errors.upuPoint.message : ""}
                />
              )}
              name="upuPoint"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("addPersonal:totalCostPerson")}
                  width={"100%"}
                  disabled={false}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  theme={pathname.includes("personnel") ? "person" : "machine"}
                  type={"number"}
                  validate={errors.totalCost ? "error" : ""}
                  errorMessage={
                    errors.totalCost ? errors.totalCost.message : ""
                  }
                />
              )}
              name="totalCost"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("addPersonal:personSalary")}
                  width={"100%"}
                  disabled={false}
                  theme={pathname.includes("personnel") ? "person" : "machine"}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  type={"number"}
                  validate={errors.salary ? "error" : ""}
                  errorMessage={errors.salary ? errors.salary.message : ""}
                />
              )}
              name="salary"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Selector
                  label={t("addPersonal:selectDepartment")}
                  items={departments}
                  onChange={(e) => {
                    setValue("department", e);
                  }}
                  value={value}
                  theme={pathname.includes("personnel") ? "person" : "machine"}
                  validate={errors.department ? "error" : ""}
                  errorMessage={
                    errors.department ? errors.department.message : ""
                  }
                  dropdownPosition={isPhone ? "downwards" : "upwards"}
                />
              )}
              name="department"
            />
            <label className="flex items-center gap-2 sm:hidden">
              <div
                onClick={() => setChecked(!checked)}
                className={`flex items-center justify-center h-5 w-5 rounded-md ${
                  checked ? "bg-[#7F56D9]" : "border border-[#D0D5DD]"
                }`}
              >
                {checked ? <CheckIcon color={"white"} /> : null}
              </div>
              <p className="text-sm text-[#667085] font-normal dark:text-[#D6D6D6]">
                {t("department:appointSelectedDepartment")}
              </p>
            </label>
          </div>
        </div>

        {/*handling design sm screen only*/}
        <label className="hidden items-center gap-2 mt-4 sm:flex">
          <div
            onClick={() => setChecked(!checked)}
            className={`flex items-center justify-center h-5 w-5 rounded-md ${
              checked ? "bg-[#7F56D9]" : "border border-[#D0D5DD]"
            }`}
          >
            {checked ? <CheckIcon color={"white"} /> : null}
          </div>
          <p className="text-sm text-[#667085] font-normal dark:text-[#D6D6D6]">
            {t("department:appointSelectedDepartment")}
          </p>
        </label>

        <p className="text-[#667085] text-sm font-normal py-4 dark:text-[#D6D6D6]">
          {t("addPersonal:departmentAdded")}
        </p>
        <div className="flex flex-row gap-3 items-center w-full">
          <Button
            colorType={"secondary-gray"}
            size={"md"}
            label={t("buttons:stop")}
            onClick={() => {
              setIsOpen(false);
              setSelectedPersonal(null);
            }}
          />
          <Button
            colorType={
              pathname.includes("personnel")
                ? "primary-person"
                : "primary-machine"
            }
            size={"md"}
            label={t("buttons:save")}
            disabled={!isDepartmentsAvailable}
            type={"submit"}
          />
        </div>
      </form>
    </>
  );
};

export default AddUserModal;

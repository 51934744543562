import React, { useState } from "react";
import { EquipmentEditIcon, NewDotsIcon, PieceIcon, ScrapIcons } from "../../../components/new-cards/card-icons";
import { legacyFormatSeconds } from "../../../utils/secondToHoursAndMinute";
import { PurchaseIcon } from "../../../assets/icons/commonIcons";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../hooks/useStores";
import { CustomModal } from "../../../components";
import StockCardOperationModal from "../modals/StockCardOperationModal";
import ExternalInternalRequestForm from "../modals/ExternalInternalRequestForm";
import EditStockQuantityModal from "../modals/EditStockQuantityModal";
import { useNavigate } from "react-router-dom";

const StockOperationCard = ({ item, occurrences, product, refetch, stockData, disableMenu }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth } = useStore();
  const [isOpen, setIsOpen] = useState(false);
  const [isPurchased, setIsPurchased] = useState(false);
  const [stockEditModal, setStockEditModal] = useState(false);
  const [openStockQuantityEdit, setOpenStockQuantityEdit] = useState(false);

  const operationTypes = (type) => {
    switch (type) {
      case "conversion_operation":
        return "DÖN";
      case "supporter_operation":
        return "YAR";
      case "assembly_operation":
        return "MON";
      case "decomposition_operation":
        return "AYR";
    }
  };

  const menuOptionsSelected = [
    { label: t("product:editStockQuantity"), value: "manuel_stock", icon: <EquipmentEditIcon /> },
    {
      label: t("product:purchaseRequestCreation"),
      value: "form",
      icon: <PurchaseIcon />,
    },
    {
      label: t("product:editStock"),
      value: "stockEdit",
      icon: <EquipmentEditIcon />,
    },
  ];

  const menuOptions =
    auth?.user?.company?.id == "e5c06127-3c5d-44b9-8403-74c160fc7f59" ? menuOptionsSelected : menuOptionsSelected.filter((i) => i.value !== "stockEdit");

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          navigate(`/app/product/mrp/stock/operation-detail/${item?.type}/${item?.id}`);
        }}
        className={`flex w-[312px] h-[112px] flex-col border items-start cursor-pointer  rounded-lg border-secondary-300  bg-white relative group `}
      >
        <div className="flex flex-row items-center w-[91%] justify-between pr-1 pt-3 pb-2 pl-3">
          <div className="flex flex-row items-center  ">
            <div className="px-1 rounded h-full w-fit border border-[#E4E7EC]  bg-[#F2F4F7]">
              {item?.externalOperation ? (
                <p className="text-xs font-semibold text-secondary-700">{"HİZ"}</p>
              ) : (
                <p className="text-xs font-semibold text-secondary-700">
                  {item?.processType || item?.process?.type ? operationTypes(item?.processType || item?.process?.type) : "--"}
                </p>
              )}
            </div>
            <div className="px-1 bg-white max-w-[230px] rounded-r-[8px]">
              <p className="font-semibold ml-1 text-sm text-secondary-700 truncate">
                {occurrences > 1 ? `${item?.process?.name} ${occurrences}` : item?.process?.name}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center w-full">
          <div className="flex flex-row items-center w-[100%] border-l-0 border border-r-0 border-b-0 border-[#E4E7EC]">
            <div className="flex flex-col items-start border-r w-1/2 border-r-[#E4E7EC]">
              <div className="px-1.5 py-[3px] flex items-center rounded-tl-[4px] justify-center border-b w-full bg-[#F9FAFB]">
                <p className="text-xs font-medium text-secondary-700">{t("product:cycleTimeStock")}</p>
              </div>
              <div className="flex px-1.5 py-[3px] flex-row items-center w-full justify-between">
                <p className="text-[10px] text-secondary-500 font-normal">{t("product:target")}</p>
                <p className="font-medium max-w-[80px] truncate text-secondary-600 text-xs">{legacyFormatSeconds(item?.estimatedTime) || "--"}</p>
              </div>
              <div className="flex px-1.5 py-[1.5px] flex-row items-center w-full justify-between">
                <p className="text-[10px] text-secondary-500 font-normal">{t("product:applieds")}</p>
                <p className="font-medium max-w-[80px] truncate text-[#079455] text-xs">{legacyFormatSeconds(item?.avgActualTime) || "--"}</p>
              </div>
            </div>

            <div className="flex flex-col items-center  w-1/2">
              <div className="px-1.5 py-[3px] flex items-center rounded-tl-[4px] justify-center border-b w-full bg-[#F9FAFB]">
                <p className="text-xs font-medium text-secondary-700">{t("product:intermediateStock")}</p>
              </div>
              <div className="flex px-1.5 py-[3px] flex-row items-center  gap-x-0.5">
                <PieceIcon />
                <p className="font-medium text-secondary-600 text-xs">{item?.activePiece || "0"}</p>
              </div>
              <div className="flex px-1.5 py-[1.5px] flex-row items-center gap-x-0.5">
                <ScrapIcons />
                <p className="font-medium text-[#475467] text-xs">{item?.rejectedPiece || "0"}</p>
              </div>
            </div>
          </div>
        </div>
        {!disableMenu && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
            }}
            className={`absolute top-2 w-[20px] min-h-[24px] max-h-[24px] min-w-[20px] right-1 h-[24px] cursor-pointer`}
          >
            <NewDotsIcon />
          </div>
        )}
        {isOpen && (
          <div className="hidden flex-col absolute min-w-[225px] max-w-[225px] h-fit bg-white border top-[28px] right-[8px] z-[110] rounded-lg px-[6px] py-1 group-hover:flex">
            {menuOptions?.map((items, index) => {
              return (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (items.value === "form") {
                      setIsPurchased(true);
                      setIsOpen(false);
                    }
                    if (items.value === "stockEdit") {
                      setStockEditModal(true);
                      setIsOpen(false);
                    }
                    if (items.value === "manuel_stock") {
                      setOpenStockQuantityEdit(true);
                    }
                    setIsOpen(false);
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                    items?.value === "delete" ? "hover:bg-[#FECDCA]" : "hover:bg-secondary-100"
                  }`}
                >
                  {items?.icon}
                  <p className={`font-medium text-sm  ${items?.value === "delete" ? "text-[#D92D20]" : "text-secondary-700"}`}>{items.label}</p>
                </button>
              );
            })}
          </div>
        )}
      </div>
      <CustomModal
        isOpen={isPurchased}
        setIsOpen={setIsPurchased}
        modalTitle={t("product:purchaseRequestForm")}
        children={
          <ExternalInternalRequestForm
            setIsOpen={isPurchased}
            closeModal={() => {
              setIsPurchased(false);
            }}
            purchaseData={item}
            product={product}
          />
        }
      />
      <CustomModal
        isOpen={stockEditModal}
        setIsOpen={setStockEditModal}
        modalTitle={t("product:editStock")}
        children={
          <StockCardOperationModal
            setIsOpen={setStockEditModal}
            closeModal={() => {
              setStockEditModal(false);
            }}
            refetch={refetch}
            product={product}
            purchaseData={item}
          />
        }
      />

      <CustomModal
        isOpen={openStockQuantityEdit}
        setIsOpen={setOpenStockQuantityEdit}
        onClose={() => {
          setOpenStockQuantityEdit(false);
        }}
        modalTitle={t("product:editStockQuantity")}
        width={400}
        children={
          <EditStockQuantityModal
            legacyRefetch={refetch}
            onClose={() => {
              setOpenStockQuantityEdit(false);
            }}
            item={stockData}
            isOperation={item}
            isStock={true}
          />
        }
      />
    </>
  );
};

export default StockOperationCard;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ArrowLeftIcon } from "../../assets/icons/productIcons";
import { Button, Input, Loading } from "../../components";
import generateFileUrl from "../../utils/generateFileUrl";
import { CheckIcon } from "../../assets/icons/modalIcons";
import { Toast } from "../../utils/toastify/toast";
import { legacyOrderService } from "../../services/legacy-new-order-service";

const NewFlexOrderSecond = ({}) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [stockSelected, setStockSelected] = useState([]);
  const [productionSelected, setProductionSelected] = useState([]);
  const [holdSelected, setHoldSelected] = useState([]);
  const [formData, setFormData] = useState({});
  const FirstPageData = JSON.parse(sessionStorage.getItem("firstPageData"));
  const tableHeadItems = [
    {
      name  : t("product:productsAndMaterialss"),
      width : "20%",

    } ,
    {
      name  : t("product:deadline"),
      width : "10%",

    } ,
    {
      name  : t("product:orderQuantityss"),
      width : "10%",

    } ,
    {
      name  : t("product:stockAmount"),
      width : "10%",

    } ,
    {
      name  : t("product:supplyTypeAndPlanning"),
      width : "50%",

    } ,
  ]
  const [buttonLoading,setButtonLoading] = useState(false)
  const toggleStockSelected = (item) => {
    const productionQuantity = parseInt(formData[item.id]?.uretimeGonder) || 0;
    const waitingQuantity = parseInt(formData[item.id]?.hold) || 0;
    const validQuantity = productionQuantity || waitingQuantity;

    if (stockSelected.some((selectedItem) => selectedItem.id === item.id)) {
      setStockSelected(stockSelected.filter((selectedItem) => selectedItem.id !== item.id));
      setFormData((prev) => ({ ...prev, [item.id]: { ...prev[item.id], stoktanGetir: "" } }));
    } else {
      const remainingQuantity = (item.orderedQuantity - validQuantity).toString();
      setStockSelected([...stockSelected, item]);
      handleInputChange(remainingQuantity, item.id, "stoktanGetir", item);
    }
  };


  const tableHead = [
    {
      name  : t("product:productsAndMaterialss"),
      width : "22%",

    } ,
    {
      name  : t("product:deadline"),
      width : "9%",

    } ,
    {
      name  : t("product:orderQuantityss"),
      width : "9%",

    } ,
    {
      name  : t("product:stockAmount"),
      width : "9%",

    } ,
    {
      name  : t("product:supplyTypeAndPlanning"),
      width : "60%",

    } ,
  ]


  const toggleProductionSelected = (item) => {
    const stockQuantity = parseInt(formData[item.id]?.stoktanGetir) || 0;
    const waitingQuantity = parseInt(formData[item.id]?.hold) || 0;
    const validQuantity = stockQuantity || waitingQuantity;

    if (productionSelected.some((selectedItem) => selectedItem.id === item.id)) {
      setProductionSelected(productionSelected.filter((selectedItem) => selectedItem.id !== item.id));
      setFormData((prev) => ({ ...prev, [item.id]: { ...prev[item.id], uretimeGonder: "" } }));
    } else {
      const remainingQuantity = (item.orderedQuantity - validQuantity).toString();
      setProductionSelected([...productionSelected, item]);
      handleInputChange(remainingQuantity, item.id, "uretimeGonder", item);
    }
  };

  const toggleHoldSelected = (item) => {
    const stockQuantity = parseInt(formData[item.id]?.stoktanGetir) || 0;
    const productionQuantity = parseInt(formData[item.id]?.uretimeGonder) || 0;
    const validQuantity = stockQuantity || productionQuantity;

    if (holdSelected.some((selectedItem) => selectedItem.id === item.id)) {
      setHoldSelected(holdSelected.filter((selectedItem) => selectedItem.id !== item.id));
      setFormData((prev) => ({ ...prev, [item.id]: { ...prev[item.id], hold: "" } }));
    } else {
      const remainingQuantity = (item.orderedQuantity - validQuantity).toString();
      setHoldSelected([...holdSelected, item]);
      handleInputChange(remainingQuantity, item.id, "hold", item);
    }
  };
  const handleInputChange = (value, id, field, item) => {
    const stoktanSelected = stockSelected.some((selectedItem) => selectedItem.id === id);
    const uretimeSelected = productionSelected.some((selectedItem) => selectedItem.id === id);
    const holdSelectedItem = holdSelected.some((selectedItem) => selectedItem.id === id);

    const selectedCount = [stoktanSelected, uretimeSelected, holdSelectedItem].filter(Boolean).length;

    if (selectedCount === 3) {
      setFormData((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          [field]: value,
        },
      }));
      return;
    }

    let remainingAmount = item.orderedQuantity - value;

    if (remainingAmount < 0) {
      value = item.orderedQuantity;
      remainingAmount = "0";
    } else if (remainingAmount === 0) {
      remainingAmount = "0";
    } else if (value < 0) {
      value = "0";
      remainingAmount = item.orderedQuantity;
    }

    if (stoktanSelected && field === "stoktanGetir") {
      if (uretimeSelected) {
        setFormData((prev) => ({
          ...prev,
          [id]: {
            ...prev[id],
            stoktanGetir: value,
            uretimeGonder: remainingAmount,
          },
        }));
      } else if (holdSelectedItem) {
        setFormData((prev) => ({
          ...prev,
          [id]: {
            ...prev[id],
            stoktanGetir: value,
            hold: remainingAmount,
          },
        }));
      }
    } else if (uretimeSelected && field === "uretimeGonder") {
      if (stoktanSelected) {
        setFormData((prev) => ({
          ...prev,
          [id]: {
            ...prev[id],
            uretimeGonder: value,
            stoktanGetir: remainingAmount,
          },
        }));
      } else if (holdSelectedItem) {
        setFormData((prev) => ({
          ...prev,
          [id]: {
            ...prev[id],
            uretimeGonder: value,
            hold: remainingAmount,
          },
        }));
      }
    } else if (holdSelectedItem && field === "hold") {
      if (stoktanSelected) {
        setFormData((prev) => ({
          ...prev,
          [id]: {
            ...prev[id],
            hold: value,
            stoktanGetir: remainingAmount,
          },
        }));
      } else if (uretimeSelected) {
        setFormData((prev) => ({
          ...prev,
          [id]: {
            ...prev[id],
            hold: value,
            uretimeGonder: remainingAmount,
          },
        }));
      }
    }

    setFormData((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: value,
      },
    }));
  };

  const onSubmit = async () => {
    if (buttonLoading) return; 
    setButtonLoading(true);
    const updatedProducts = FirstPageData?.selectedProducts.map((item) => ({
      ...item,
      stockQuantity: parseInt(formData[item.id]?.stoktanGetir) || 0,
      productionQuantity: parseInt(formData[item.id]?.uretimeGonder) || 0,
      waitingQuantity: parseInt(formData[item.id]?.hold) || 0,
    }));

    const hasStockError = updatedProducts.find((item) => (item.qualityQuantity < 0 ? 0 : item.qualityQuantity || 0) < item.stockQuantity);
    const hasOrderError = updatedProducts.find((item) => item.orderedQuantity !== item.stockQuantity + item.productionQuantity + item.waitingQuantity);

    if (hasStockError) {
      Toast("error", `${hasStockError?.name} Stoktan çıkış miktarı, stok miktarından fazla olamaz.`);
      return;
    }

    if (hasOrderError) {
      Toast("error", `${hasOrderError?.name} Sipariş miktarı, stoktan çıkış, üretime gönderim ve bekletme miktarlarının toplamına eşit olmalıdır.`);
      return;
    }

    const allQuantitiesEqual = updatedProducts.every((item) => item.stockQuantity === item.orderedQuantity);

    if (allQuantitiesEqual) {
      const sendDataDene = {
        orderNo: FirstPageData?.orderCode,
        customer: FirstPageData?.customer?.id,
        customerOrderNo: FirstPageData?.customerOrderNo,
        products: updatedProducts.map((item) => ({
          currency: item?.currency,
          stockQuantity: item?.stockQuantity,
          productionQuantity: item?.productionQuantity,
          waitingQuantity: item?.waitingQuantity,
          customerStockCode: item?.customerStockCode,
          orderedQuantity: item?.orderedQuantity,
          productPrice: item?.productPrice,
          deliveryDate: item?.deliveryDate,
          product: item?.id,
          purchaseRequest: {
            type: "raw",
            orderedQuantity: item?.orderedQuantity,
            stockQuantity: item?.stockQuantity,
            purchaseQuantity: 0,
            typeId: undefined,
          },
        })),
      };
      await legacyOrderService.flexNewOrder(sendDataDene).then((res) => {
        if (res.data.code == 0) {
          sessionStorage.removeItem("firstPageData");
          sessionStorage.removeItem("secondPageData");
          navigate("/app/product/mrp/orders");
        } else {
          Toast("error", res?.data?.message);
        }
      });
    } else {
      const sendData = {
        customer: FirstPageData?.customer,
        orderCode: FirstPageData?.orderCode,
        customerOrderNo: FirstPageData?.customerOrderNo,
        selectedProducts: updatedProducts,
      };

      sessionStorage.setItem("secondPageData", JSON.stringify(sendData));
      navigate("/app/product/mrp/orders/new-order/third-page");
    }
    setButtonLoading(false)
  };

  useEffect(() => {
    if (FirstPageData) {
      const initialStockSelected = [];
      const initialProductionSelected = [];

      FirstPageData.selectedProducts.forEach((item) => {
        if (item.qualityQuantity === null || item.qualityQuantity === 0) {
          // Stok miktarı 0 ise, "Stoktan Getir" seçilemez
          initialProductionSelected.push(item); // Üretime Gönder seçili hale getirilir
          handleInputChange(item.orderedQuantity, item.id, "uretimeGonder", item);
        } else {
          if (item.qualityQuantity <= item.orderedQuantity) {
            // Stok miktarı sipariş miktarından küçük veya eşitse
            initialStockSelected.push(item); // Stoktan Getir seçili hale getirilir
            handleInputChange(item.qualityQuantity, item.id, "stoktanGetir", item);

            const remainingQuantity = item.orderedQuantity - item.qualityQuantity;
            if (remainingQuantity > 0) {
              initialProductionSelected.push(item); // Kalan miktar için Üretime Gönder seçili hale getirilir
              handleInputChange(remainingQuantity, item.id, "uretimeGonder", item);
            }
          } else {
            // Stok miktarı sipariş miktarından büyükse
            initialStockSelected.push(item); // Tüm sipariş miktarı için Stoktan Getir seçili hale getirilir
            handleInputChange(item.orderedQuantity, item.id, "stoktanGetir", item);
          }
        }
      });

      setStockSelected(initialStockSelected);
      setProductionSelected(initialProductionSelected);
    }
  }, []);

  useEffect(() => {
    const storedData = sessionStorage.getItem("secondPageData");

    if (storedData) {
      const data = JSON.parse(storedData);

      const mappedData = data.selectedProducts.reduce((acc, product) => {
        acc[product.id] = {
          stoktanGetir: product.stockQuantity,
          uretimeGonder: product.productionQuantity,
          hold: product.waitingQuantity,
        };
        return acc;
      }, {});

      setFormData(mappedData);

      const initialProductionSelected = data.selectedProducts.filter((product) => product.productionQuantity > 0);

      const initialHoldSelected = data.selectedProducts.filter((product) => product.waitingQuantity > 0);

      const initialStockSelected = data.selectedProducts.filter((product) => product.stockQuantity > 0);

      setProductionSelected(initialProductionSelected);
      setHoldSelected(initialHoldSelected);
      setStockSelected(initialStockSelected);
    }
  }, [sessionStorage.getItem("secondPageData")]);
  return (
    <>
      <div className="flex flex-col w-full gap-y-8 h-[95vh]">
        <div className="flex min-h-[56px] max-h-[56px] h-[56px] w-full items-center justify-start gap-x-2 sticky top-0 z-[50] bg-white">
          <Button size={"md"} iconLeft={<ArrowLeftIcon />} colorType={"tertiary-gray"} onClick={() => navigate(-1)} type={"button"} />
          <p className="text-[#101828] font-semibold text-2xl">{t("product:createNewFlexRaw")}</p>
        </div>

        <div className="flex flex-col gap-y-6 w-full h-full">
          <div className="flex w-full min-h-[72px] max-h-[72px] h-[72px] pb-5 justify-between border-b border-[#E4E7EC]">
            <div className="flex flex-col gap-y-1">
              <p className="text-[#101828] font-semibold text-lg">{t("product:supplyTypeAndPlanning")}</p>
              <p className="text-[#344054] font-normal text-sm">{t("product:youCanDeduct")}</p>
            </div>
            <div className="flex gap-x-3 max-h-[40px] min-h-[40px] h-10">
              <span className="flex min-w-[144px] max-w-[144px] w-[144px]">
                <Button size={"md"} colorType={"secondary-gray"} label={t("buttons:previous")} onClick={() => navigate("/app/product/mrp/orders/new-order")} />
              </span>
              <span className="flex min-w-[144px] max-w-[144px] w-[144px]">
                <Button disabled={buttonLoading ? true : false}   size={"md"} colorType={"primary-product"} label={buttonLoading ? <Loading color={"#fff"} secondary={"#fff"} size={22} /> : t("buttons:save")} type={"submit"} onClick={onSubmit} />
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-y-5 w-full h-[75vh] border border-[#E4E7EC] rounded-t-xl">
            <div className="flex w-full min-h-[48px] max-h-[48px] h-[48px] justify-between bg-[#F9FAFB] rounded-t-xl items-center px-6 py-3 border-b border-[#E4E7EC]">
              <div className="flex gap-x-2 items-center">
                <img src={generateFileUrl(FirstPageData?.customer?.image)} className="min-w-[32px] w-[32px] h-[24px] rounded-sm border border-[#0000001A]" />
                <p className="text-[#101828] font-medium text-sm">{FirstPageData?.customer?.name}</p>
              </div>
              <p className="text-[#344054] text-sm">{t("product:orderCode")}: {FirstPageData?.orderCode}</p>
            </div>

            <div className={`flex flex-col w-full h-full px-6 overflow-y-auto overflow-x-hidden scrollbar-hide`}>
              {/* table header */}
              <div className="flex max-h-[26px] min-h-[26px] w-full sticky top-0 bg-white z-50">
                {tableHeadItems.map((item, i) => (
                  <div
                    key={i}
                    className={`flex border-b px-2 pb-2 border-r  border-[#E4E7EC] items-center w-[${item.width}] ${i === tableHeadItems.length - 1 ? "border-r-0" : ""} ${i === 0 ? "justify-start" : "justify-center"}`}
                  >
                    <span className="text-center text-xs font-medium cursor-pointer whitespace-nowrap">{item?.name}</span>
                  </div>
                ))}
              </div>
              {FirstPageData?.selectedProducts?.map((item, i) => (

                console.log( "logg item" , item),
                <div key={i} className="flex w-full items-center border-b h-[100px] min-h-[100px]">
                  {/* tablo 1.kolon */}
                  <div className=" flex items-center gap-x-3 py-4 pr-6 border-r h-full justify-start w-[20%]">
                    <img className="w-[64px] h-[48px] rounded border border-[#0000001A]" src={generateFileUrl(item?.image)} />
                    <div className="flex flex-col gap-y-1 justify-center">
                      <p className="text-[#101828] font-semibold text-md">{item?.name || "--"}</p>
                      <p className="text-[#475467] text-sm">{t("prodcut:stock")} ID: {item?.stockCode || "--"}</p>
                    </div>
                  </div>
                  
                  {/* tablo 2.kolon */}
                  <div className="border-r flex flex-col h-full p-3  items-center justify-center w-[10%] min-w-[120px]">
                    <p className="text-md text-secondary-600 dark:text-[#F5F5F5] font-normal text-center">{item?.deliveryDate || "--:--:--"}</p>
                   
                  </div>
                   {/* tablo 3.kolon */}
                   <div className="border-r flex flex-col h-full p-3  items-center justify-center w-[10%] min-w-[120px]">
                    <p className="text-md text-secondary-600 dark:text-[#F5F5F5] font-normal text-center">{item?.orderedQuantity || "0"}</p>
                    <p className="text-sm font-normal text-secondary-500">{t("product:pieces")}</p>
                  </div>
                  {/* tablo 4.kolon */}
                  <div className="border-r flex flex-col h-full p-3  items-center justify-center w-[10%] min-w-[120px]">
                    <p
                      className={`whitespace-nowrap text-center overflow-hidden  ${
                        item?.stockCount < item?.orderCount ? "text-error-600" : "text-success-600"
                      } text-ellipsis text-md  dark:text-[#F5F5F5] font-normal`}
                    >
                      {item?.qualityQuantity || "0"}
                    </p>
                    <p className="text-sm font-normal text-secondary-500">{t("product:pieces")}</p>
                  </div>
                  {/* tablo 5.kolon */}
                  <div className="flex h-full px-6 py-4 justify-between w-[50%] gap-x-6">
                    <div className="flex flex-col gap-y-3 w-1/3 min-w-[202px]">
                      <div
                        onClick={() => {
                          if (item.qualityQuantity === null || item.qualityQuantity === 0) {
                            Toast("error", "Stok miktarı 0 olan ürünler için stoktan çıkış yapamazsınız.");
                          } else {
                            toggleStockSelected(item);
                          }
                        }}
                        className={`flex gap-x-2 items-center w-fit ${
                          item.qualityQuantity === null || item.qualityQuantity === 0 ? "cursor-not-allowed opacity-50" : "cursor-pointer"
                        }`}
                      >
                        <div
                          className={`min-w-[16px] w-4 h-4 rounded flex items-center justify-center border ${
                            stockSelected.some((selectedItem) => selectedItem.id === item.id) ? "bg-[#DC6803] border-[#DC6803] text-[#fff]" : "border-[#D0D5DD]"
                          }`}
                        >
                          {stockSelected.some((selectedItem) => selectedItem.id === item.id) && <CheckIcon />}
                        </div>
                        <div className="text-secondary-500 font-normal text-sm">{t("product:bringFromStock")}</div>
                        <span className="group flex relative">
                          <HelpCircleIcon />
                          <div className="hidden absolute min-w-[293px] max-w-[293px] w-[293px] min-h-[52px] max-h-[52px] h-[52px] group-hover:flex bottom-[26px] -left-[136px] bg-[#0C111D] rounded-lg px-3 py-2 text-center">
                            <p className="text-[#FFFFFF] font-semibold text-xs">{t("product:enterTheDeducted")}</p>
                          </div>
                          <span className="hidden absolute group-hover:flex bottom-[20px]">
                            <TriangleTooltipIcon />
                          </span>
                        </span>
                      </div>
                      <div className="flex w-full h-full">
                        <Input
                          icon={<QuantityIcon />}
                          theme="product"
                          type={"number"}
                          value={formData[item.id]?.stoktanGetir || ""}
                          onChange={(e) => handleInputChange(e.target.value, item.id, "stoktanGetir", item)}
                          disabled={!stockSelected.some((selectedItem) => selectedItem.id === item.id)}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-3 w-1/3 min-w-[202px]">
                      <div onClick={() => toggleProductionSelected(item)} className="flex gap-x-2 items-center cursor-pointer w-fit">
                        <div
                          className={`min-w-[16px] w-4 h-4 rounded flex items-center justify-center border ${
                            productionSelected.some((selectedItem) => selectedItem.id === item.id)
                              ? "bg-[#DC6803] border-[#DC6803] text-[#fff]"
                              : "border-[#D0D5DD]"
                          }`}
                        >
                          {productionSelected.some((selectedItem) => selectedItem.id === item.id) && <CheckIcon />}
                        </div>
                        <div className="text-secondary-500 font-normal text-sm">{t("product:sendToProduction")}</div>
                        <span className="group flex relative">
                          <HelpCircleIcon />
                          <div className="hidden absolute min-w-[370px] max-w-[370px] w-[370px] min-h-[70px] max-h-[70px] h-[70px] group-hover:flex bottom-[26px] -left-[175px] bg-[#0C111D] rounded-lg px-3 py-2 text-center">
                            <p className="text-[#FFFFFF] font-semibold text-xs">
                              {t("product:youCanSendPortion")}
                            </p>
                          </div>
                          <span className="hidden absolute group-hover:flex bottom-[20px]">
                            <TriangleTooltipIcon />
                          </span>
                        </span>
                      </div>
                      <div className="flex w-full h-full">
                        <Input
                          icon={<QuantityIcon />}
                          theme="product"
                          type={"number"}
                          value={formData[item.id]?.uretimeGonder || ""}
                          onChange={(e) => handleInputChange(e.target.value, item.id, "uretimeGonder", item)}
                          disabled={!productionSelected.some((selectedItem) => selectedItem.id === item.id)}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-3 w-1/3 min-w-[202px]">
                      <div onClick={() => toggleHoldSelected(item)} className="flex gap-x-2 items-center cursor-pointer w-fit">
                        <div
                          className={`min-w-[16px] w-4 h-4 rounded flex items-center justify-center border ${
                            holdSelected.some((selectedItem) => selectedItem.id === item.id) ? "bg-[#DC6803] border-[#DC6803] text-[#fff]" : "border-[#D0D5DD]"
                          }`}
                        >
                          {holdSelected.some((selectedItem) => selectedItem.id === item.id) && <CheckIcon />}
                        </div>
                        <div className="text-secondary-500 font-normal text-sm">{t("product:holdProduce")}</div>
                        <span className="group flex relative">
                          <HelpCircleIcon />
                          <div className="hidden absolute min-w-[293px] max-w-[293px] w-[293px] min-h-[52px] max-h-[52px] h-[52px] group-hover:flex bottom-[26px] -left-[186px] bg-[#0C111D] rounded-lg px-3 py-2 text-center">
                            <p className="text-[#FFFFFF] font-semibold text-xs">{t("product:youCanCreateOrder")}</p>
                          </div>
                          <span className="hidden absolute group-hover:flex bottom-[20px]">
                            <TriangleTooltipIcon />
                          </span>
                        </span>
                      </div>
                      <div className="flex w-full h-full">
                        <Input
                          icon={<QuantityIcon />}
                          theme="product"
                          type={"number"}
                          value={formData[item.id]?.hold || ""}
                          onChange={(e) => handleInputChange(e.target.value, item.id, "hold", item)}
                          disabled={!holdSelected.some((selectedItem) => selectedItem.id === item.id)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewFlexOrderSecond;

const QuantityIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M5.75 2.375H3.71429C2.76751 2.375 2 3.14251 2 4.08929V11.9107C2 12.8575 2.76751 13.625 3.71429 13.625H12.2857C13.2325 13.625 14 12.8575 14 11.9107V4.08929C14 3.14251 13.2325 2.375 12.2857 2.375H10.25M5.75 2.375V6.73631C5.75 6.80003 5.81706 6.84147 5.87405 6.81298L8 5.75L10.126 6.81298C10.1829 6.84147 10.25 6.80003 10.25 6.73631V2.375M5.75 2.375H10.25"
        stroke="#475467"
      />
    </svg>
  );
};

const HelpCircleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_17461_3132)">
        <path
          d="M6.06016 6.00016C6.2169 5.55461 6.52626 5.1789 6.93347 4.93958C7.34067 4.70027 7.81943 4.61279 8.28495 4.69264C8.75047 4.77249 9.17271 5.01451 9.47688 5.37585C9.78105 5.73718 9.94753 6.19451 9.94683 6.66683C9.94683 8.00016 7.94683 8.66683 7.94683 8.66683M8.00016 11.3335H8.00683M14.6668 8.00016C14.6668 11.6821 11.6821 14.6668 8.00016 14.6668C4.31826 14.6668 1.3335 11.6821 1.3335 8.00016C1.3335 4.31826 4.31826 1.3335 8.00016 1.3335C11.6821 1.3335 14.6668 4.31826 14.6668 8.00016Z"
          stroke="#98A2B3"
          strokeWidth="1.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17461_3132">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const TriangleTooltipIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="9" viewBox="0 0 17 9" fill="none">
      <path
        d="M8.5 0.49993L14.5711 0.49993C15.462 0.49993 15.9081 1.57707 15.2782 2.20704L9.20711 8.2781C8.81658 8.66863 8.18342 8.66863 7.79289 8.2781L1.72183 2.20704C1.09186 1.57707 1.53803 0.49993 2.42893 0.49993L8.5 0.49993Z"
        fill="#0C111D"
      />
    </svg>
  );
};

import React, { useState } from "react";
import Input from "../inputs/Input";
import Button from "../buttons/Button";
import { Toast } from "../../utils/toastify/toast";
import { productService } from "../../services/product.service";
import MintueSecondInput from "../inputs/MintueSecondInput";
import { useTranslation } from "react-i18next";
import { flexProductService } from "../../services/flex-product.service";

const EditTimeModal = ({ modalType, onClose, defaultTime, operationId, operationType, refetch,isFlex=false }) => {

  const { t } = useTranslation();
  const [time, setTime] = useState(defaultTime ? defaultTime : "0");
  const handleSave = async () => {
    let body;
    if (operationType === "internal") {
      if (modalType) {
        body = { type: "internal", estimatedSettingsTime: time };
      } else {
        body = { type: "internal", estimatedTime: time };
      }
    } else {
      if (modalType) {
        body = { type: "external", estimatedSettingsTime: time };
      } else {
        body = { type: "external", estimatedTime: time };
      }
    }
if (isFlex) {
  await flexProductService.updateFlexOperation(operationId, body).then((res) => {
    if (res.data.code == 0) {
      Toast("success", res?.data?.message);
      refetch();
    } else {
      Toast("error", res?.data?.message);
    }
    onClose();
  });
} else {
  await productService.updateProductOperation(operationId, body).then((res) => {
    if (res.data.code == 0) {
      Toast("success", res?.data?.message);
      refetch();
    } else {
      Toast("error", res?.data?.message);
    }
    onClose();
  });
}
    
  };

  return (
    <div className="isFlex isFlex-col gap-y-6 mt-6">
      {modalType ? (
        <Input
          theme={"product"}
          label={t("product:estimatedSetupTime") + " " + `${operationType === "internal" ? `(${t("product:min")})` : `(${t("product:day")})`}`}
          modalType={"number"}
          placeholder={String(time)}
          value={time}
          onChange={(event) => {
            setTime(event.target.value);
          }}
        />
      ) : (
        <>
          {operationType === "internal" ? (
            <MintueSecondInput
              label={t("product:estimatedTime")}
              value={time}
              theme={"product"}
              onChange={(event) => {
                setTime(event);
              }}
            />
          ) : (
            <Input
              theme={"product"}
              label={t("product:estimatedWorkTime") + " " + `(${t("product:day")})`}
              modalType={"number"}
              placeholder={String(time)}
              value={time}
              onChange={(event) => {
                setTime(event.target.value);
              }}
            />
          )}
        </>
      )}

      <div className="flex gap-x-3 pt-3">
        <Button colorType={"secondary-gray"} size={"lg"} label={t("buttons:stop")} onClick={onClose} />
        <Button colorType={"primary-product"} size={"lg"} label={t("buttons:update")} onClick={handleSave} />
      </div>
    </div>
  );
};

export default EditTimeModal;

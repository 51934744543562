import React, { useState } from "react";
import { EquipmentEditIcon, NewAcceptedIcon, NewDotsIcon, NewRejectedIcon, PieceIcon } from "../../../components/new-cards/card-icons";
import generateFileUrl from "../../../utils/generateFileUrl";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import CustomImage from "../../../components/CustomImage";

const NewFlexProductCard = ({ item, refetch, qrFunction }) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false);
  const [promptEdit, setPromptEdit] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (id) => () => {
    navigate(`/app/product/mrp/orders/flex/create/${id}`);
  };

  return (
    <>
      <div
        //       data-tooltip-id={`${item?.id}`}
        //       data-tooltip-place={"right"}
        //       data-tooltip-content={`
        //  ${item?.name}
        //  `}
        className="flex bg-white flex-row  w-[312px] min-w-[312px] h-[112px] min-h-[112px] relative group pl-3  pt-2 border border-[#D0D5DD] rounded-lg cursor-grab "
      >
        <div className="flex flex-col w-[100%]">
          <div className="flex flex-row items-center gap-x-3">
            {item?.image ? (
              <div>
                <CustomImage
                  borderRadius={2}
                  src={item?.image}
                  style={{
                    width: 64,
                    height: 48,
                    minWidth: 64,
                    minHeight: 48,
                    borderRadius: 4,
                    borderWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}
            {/* <img className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-300 select-none" src={generateFileUrl(item?.image)} /> */}
            <div className="flex flex-col items-start">
              <div className="flex flex-row items-center gap-x-1 w-full">
                <p className="font-semibold text-secondary-900 text-base w-[185px] truncate">{item?.name || "Product Name"}</p>
              </div>

              <div className="bg-[#F9FAFB] max-w-[185px] h-6 rounded px-[3px] py-1">
                <p className="text-xs font-medium max-w-[185px] truncate text-secondary-700">
                  {t("product:flex")} : {item?.stockCode || "--"}
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-row items-center w-full ">
            <div className="w-full h-[42px] flex items-center mt-1.5 self-stretch border  border-[#E4E7EC] rounded ml-auto mr-3 overflow-hidden ">
              <div className=" h-full px-2 py-1   justify-center items-center flex gap-2 border-r  border-[#E4E7EC] bg-[#F9FAFB]">
                <span className="text-[10px] truncate text-secondary-700 font-medium ">{t("product:ready")}</span>
              </div>
              <div className="w-1/2 border-r border-r-secondary-200 h-full">
                <div className="px-2 py-[3px] flex flex-col items-center justify-center gap-y-[2px]">
                  <p className="text-[10px] font-normal text-secondary-600">{t("product:approvedQuantity")}</p>
                  <div className="flex flex-row items-center">
                    <NewAcceptedIcon />
                    <p className="text-xs ml-1 font-medium text-[#079455]">{item?.qualityQuantity || "0"}</p>
                    <p className="text-[10px] ml-0.5  font-normal text-secondary-600">{t("product:pcs")}</p>
                  </div>
                </div>
              </div>
              <div className="w-1/2  border-r-secondary-200 h-full">
                <div className="px-2 py-[3px] flex flex-col items-center justify-center gap-y-[2px]">
                  <p className="text-[10px] font-normal text-secondary-600">{t("product:rejectedQuantitys")}</p>
                  <div className="flex flex-row items-center">
                    <NewRejectedIcon />
                    <p className="text-xs ml-1 font-medium text-[#D92D20]">{item?.scrapQuantity || "0"}</p>
                    <p className="text-[10px] ml-0.5  font-normal text-secondary-600">{t("product:pcs")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Tooltip className="z-50" position={"right"} id={`${item?.id}`} /> */}
        <div
          className={`flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 bg-[#F9F5FF] border-[#D0D5DD] h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px]`}
        >
          <div className="flex flex-col items-center gap-y-[14px]">
            <p className={`text-[10px] font-semibold text-[#7F56D9] -rotate-90`}>{t("product:productss")}</p>
            <p className={`text-[10px] font-semibold text-[#7F56D9] ${i18next?.language === "tr" ? "-mt-[1px]" : "mt-[4px]"} -rotate-90`}>{t("product:flex")}</p>
          </div>

        </div>
        <div
          //  onClick={() => setPromptEdit(!promptEdit)}
          onClick={(e) => {
            e.stopPropagation();
            setPromptEdit(!promptEdit);
          }}
          className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <NewDotsIcon />
        </div>
        {promptEdit && (
          <div
            type="button"
            // onClick={() => navigate("/app/product/order/flex/create")}
            onClick={handleNavigation(item?.id)}
            className="cursor-pointer absolute w-[130px] h-10 p-1 bg-white right-1 top-[16px] border border-secondary-200 rounded-lg shadow-sm flex flex-col z-[100]"
          >
            <div className="w-full h-full gap-x-2 flex justify-start px-[4px] items-center">
              <EquipmentEditIcon />
              <span className="font-medium text-md text-secondary-900">{t("table:edit")}</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NewFlexProductCard;

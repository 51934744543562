import React from "react";
import { Button } from "../../../components";
import { PlusIcon } from "../../../assets/icons/productIcons";
import SearchInput from "../../../components/inputs/SearchInput";
import NewRawMaterialCard from "../../../components/junction/NewRawMaterialCard";
import { useQuery } from "@tanstack/react-query";
import { flexProductService } from "../../../services/flex-product.service";
import { useNavigate } from "react-router-dom";
import FlexProductRawMaterialCard from "../FlexProductRawMaterialCard";
import { rawMaterialService } from "../../../services/raw-material.service";
import RawMaterialStockCard from "../cards/RawMaterialStockCard";

const StockSelectRawMaterialModal = ({ setSelectedRawMaterial, selectedRawMaterial, setSelectRawCardModal }) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["raw-material-list"],
    queryFn: async () => {
      return await rawMaterialService.getRawMaterials();
    },
    retry: 0,
  });

  const navigate = useNavigate();

  return (
    <div className="flex flex-col min-w-[354px] max-w-[354px] h-full border-t mt-5 py-4 gap-y-6">
      <Button
        colorType={"secondary-gray"}
        label={"Yeni Hammadde Ekle"}
        iconLeft={<PlusIcon />}
        onClick={() => {
          navigate("/app/product/mrp/stock/create/raw-material");
        }}
      />
      <div className="flex flex-col w-full h-full gap-y-5">
        <SearchInput placeholder={"Search Raw Material"} theme={"product"} />
        <div className="flex flex-col w-full h-[50vh] overflow-y-auto overflow-x-hidden scrollbar-hide">
          {data?.map((item, index) => (
            <div className="flex min-h-[128px] max-h-[128px] w-full">
              <div
                onClick={() => {
                  setSelectedRawMaterial(item);
                  setSelectRawCardModal(false);
                }}
                className="w-full h-full flex flex-col gap-y-3 items-center "
              >
                {/* <FlexProductRawMaterialCard item={item} /> */}
                <RawMaterialStockCard item={item} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StockSelectRawMaterialModal;

import React, { useState, useEffect } from "react";
import { Button, CustomModal, Loading } from "../../components";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useStore } from "../../hooks/useStores";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { QualityTab } from "./quality/components";
import { Toast } from "../../utils/toastify/toast";
import { orderService } from "../../services/order.service";
import NewOrderHeaderCard from "./components/order/NewOrderHeaderCard";
import { junctionService } from "../../services/junction.service";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import QualityDoneCard from "../../components/new-cards/quality-done-card/QualityDoneCard";
import DoneAndQualityWaitingCard from "../../components/new-cards/done-quality-waiting-card/DoneAndQualityWaitingCard";
import ActiveCard from "../../components/new-cards/active-card/ActiveCard";
import PlannedCard from "../../components/new-cards/plan-card/PlannedCard";
import moment from "moment";
import TransactionDetailModal from "../../components/modal/TransactionDetailModal";
import FinishJunctionModal from "../../components/modal/FinishJunctionModal";
import SelectUserModal from "../../components/modal/SelectUserModal";
import QualityControlForm from "../../components/modal/QualityControlForm";
import { ScrollChevronIcon } from "../../assets/icons/productIcons";
import InternalOperationPlannedEventCard from "../../components/new-cards/junction-card/InternalOperationPlannedEventCard";
import InternalOperationPlannedActiveEventCard from "../../components/new-cards/junction-card/InternalOperationPlannedActiveEventCard";
import InternalOperationPlannedWaitingEventCard from "../../components/new-cards/junction-card/InternalOperationPlannedWaitingEventCard";
import InternalOperationPlannedDoneEventCard from "../../components/new-cards/junction-card/InternalOperationPlannedDoneEventCard";
import useWindowDimensions from "../../hooks/useWindowDimension";
const InternalOperationJunction = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { height } = useWindowDimensions();
  const { settings, auth } = useStore();
  let navigate = useNavigate();
  const [selectedGroup, setSelectedGroup] = useState("all");
  const [isLeftArrowHidden, setIsLeftArrowHidden] = useState(true);
  const [isRightArrowHidden, setIsRightArrowHidden] = useState(false);
  const [productType, setProductType] = useState("standart");
  const [junctionType, setJunctionType] = useState("internal");
  const [isSelectUserModal, setIsSelectUserModal] = useState();
  const [isOpenJunctionPiece, setIsOpenJunctionPiece] = useState(false);
  const [junctionDetailOpen, setJunctionDetailOpen] = useState(false);
  const [selectedJunction, setSelectedJunction] = useState(null);
  const [finishJunctionId, setFinishJunctionId] = useState(null);
  const [junctionId, setJunctionId] = useState(null);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const planView = useQuery({
    queryKey: ["operations-junctions-planned"],
    enabled: false,
    queryFn: async () => await junctionService.getInternalOperationJunctions(id, "all"),
    retry: 0,
  });
  const headData = useQuery({
    queryKey: ["all-operations-junctions"],
    enabled: false,
    retry: 0,
    queryFn: async () => await orderService.getOrderHeadData(),
  });
  const refetch = () => {
    planView.refetch();
    headData.refetch();
  };
  useEffect(() => {
    headData.refetch();
    if (id != ":id" && id != "undefined") {
      planView.refetch();
      setSelectedGroup(headData?.data?.data?.operation?.find((item) => item?.id == id));
    }
  }, [id]);
  useEffect(() => {
    if (headData?.data?.data?.operation?.length > 0 && (id == ":id" || id == "undefined")) {
      navigate(`/app/product/mrp/supply/operation/${headData?.data?.data?.operation[0]?.id}/junction`);
    }
  }, [headData?.data]);
  const activeJunctions = planView?.data?.activeJunctions ? planView?.data?.activeJunctions?.data : [];
  const completedWorks = planView?.data?.pendingJunctions ? planView?.data?.pendingJunctions?.data : [];
  const completedAndApprovedWorks = planView?.data?.pastJunctions ? planView?.data?.pastJunctions?.data : [];
  const moments = require("moment");
  require("moment/locale/tr");
  const sortedCompletedWorks = completedWorks?.sort((a, b) => moment(b?.startDate).unix() - moment(a?.startDate).unix());
  const groupedByDateCompleted = sortedCompletedWorks?.reduce((acc, currentItem) => {
    const endDate = moments(currentItem?.startDate).locale("tr").format("LL");
    if (!acc[endDate]) {
      acc[endDate] = [];
    }
    acc[endDate].push(currentItem);
    return acc;
  }, {});
  const sortedCompletedAndApproved = completedAndApprovedWorks?.sort((a, b) => moment(b?.startDate).unix() - moment(a?.startDate).unix());
  const groupedByDateCompletedAndApproved = sortedCompletedAndApproved?.reduce((acc, currentItem) => {
    const endDate = moments(currentItem?.startDate).locale("tr").format("LL");
    if (!acc[endDate]) {
      acc[endDate] = [];
    }
    acc[endDate].push(currentItem);
    return acc;
  }, {});
  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  const handleScroll = () => {
    const container = document.getElementById("content-wrapper");
    const isAtBeginning = container.scrollLeft === 0;
    const isAtEnd = container.scrollLeft >= container.scrollWidth - container.clientWidth;
    setIsLeftArrowHidden(isAtBeginning);
    setIsRightArrowHidden(isAtEnd);
  };
  useEffect(() => {
    const container = document.getElementById("content-wrapper");
    container?.addEventListener("scroll", handleScroll);
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  const handleCardMove = (sourceColumn, destinationColumn, taskId) => {
    if (sourceColumn === "active" && destinationColumn === "waiting") {
      setFinishJunctionId(taskId);
      setIsOpenJunctionPiece(true);
    } else if (sourceColumn === "waiting" && destinationColumn === "finished") {
      setJunctionId(taskId);
      setIsOpenForm(true);
    }
  };
  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) return;
    if (destination.droppableId === source.droppableId) {
      Toast("error", t("product:sameColumnWarning"));
      return;
    }
    const start = columns[source.droppableId];
    const finish = columns[destination.droppableId];
    const validMoves = [
      { from: "active", to: "waiting" },
      { from: "waiting", to: "finished" },
    ];
    const isValidMove = validMoves.some((move) => move.from === source.droppableId && move.to === destination.droppableId);
    if (!isValidMove) {
      Toast("error", t("product:columnDragDropWarning"));
      return;
    }
    const startTaskIds = Array.from(start);
    startTaskIds.splice(source.index, 1);
    const newStart = startTaskIds;
    const finishTaskIds = Array.from(finish);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = finishTaskIds;
    setColumns({
      ...columns,
      [source.droppableId]: newStart,
      [destination.droppableId]: newFinish,
    });
    handleCardMove(source.droppableId, destination.droppableId, draggableId);
  };
  const [columns, setColumns] = useState({
    active: activeJunctions,
    waiting: sortedCompletedWorks,
    finished: sortedCompletedAndApproved,
  });
  useEffect(() => {
    if (auth?.user?.company?.id == "e5c06127-3c5d-44b9-8403-74c160fc7f59") {
    } else if (auth?.user?.company?.id == "ab77a50a-7b68-42a2-ba5c-0ebfb5c13325") {
    } else if (auth?.user?.company?.id == "71b0edec-e5d4-4929-bfdb-0e5d5e149592") {
    } else if (auth?.user?.company?.id == "dd1892b9-d9a3-44ed-9131-17c88a74a1b4") {
    } else if (auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706") {
    } else if (auth?.user?.company?.id == "49ee8491-35d7-4eb2-a0e9-bfc95541180f") {
    } else if (auth?.user?.company?.id == "65844198-a9e8-45fa-bc99-7d012a541b35") {
    } else if (auth?.user?.company?.id == "7c5bb5a6-21aa-4d1d-829b-2a6d360b9630") {
    } else if (auth?.user?.company?.id == "f7db6191-5842-455a-99f4-c76763090689") {
    } else if (auth?.user?.company?.id == "776c0df3-0a44-464f-9877-33bd731aef6a") {
    } else if (auth?.user?.company?.id == "065e9b57-9da2-4394-9359-59ba71610dc3") {
    } else if (auth?.user?.company?.id == "e41a9bde-203c-48f2-aeec-4f8ce6344513") {
    } else if (auth?.user?.company?.id == "262d8e02-57e8-427e-9159-6b18b236ca86") {
    } else if (auth?.user?.company?.id == "0c082d32-6d97-4ba9-a2a5-7e41fe8139d9") {
    } else {
      navigate("/app/product/verysoon");
    }
  }, []);
  const tabOptions = [
    { value: "plan", label: t("product:planner") },
    { value: "junction", label: t("product:eventss") },
  ];
  return (
    <div className="w-full h-full flex flex-col overflow-hidden overflow-y-auto scrollbar-hide">
      <>
        <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
          <span className="mr-3 md:flex sm:flex xs:flex hidden">
            <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
          </span>
          <div className="flex items-center">
            <span className="font-codecMedium text-2xl text-black">Operations</span>
            {/* <p className="text-2xl font-codecMedium text-[#B54708]">planner</p> */}
            {!(headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading) && (
              <p className="text-xl font-normal ml-2 text-secondary-500">
                {selectedGroup && selectedGroup?.name ? `${selectedGroup?.name}` : "Internal Operations Junctions"}
              </p>
            )}
          </div>
        </div>
        {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
          <div className="w-full bg-gray-200 rounded-xl animate-pulse h-[96px] min-h-[96px] flex gap-5" />
        ) : (
          <div className="h-[116px] min-h-[116px] w-full overflow-x-auto flex">
            {headData?.data?.data?.operation
              ?.sort((first, second) => second?.total - first?.total)
              ?.map((item, index) => {
                return (
                  <div className={`min-w-[192px] ${index !== headData?.data?.data?.operation?.length - 1 ? "border-b" : "border-b-0"} max-h-[96px] pr-5`}>
                    <NewOrderHeaderCard
                      onClick={async () => {
                        navigate(`/app/product/mrp/supply/operation/${item?.id}/junction`);
                      }}
                      icon={item?.iconKey}
                      name={item?.name}
                      planned={Number(item?.plannedWorkLoad / 86400).toFixed(1)}
                      unPlanned={Number((item?.total - item?.plannedWorkLoad) / 86400).toFixed(1)}
                      selectedOperation={{ id: selectedGroup?.id, name: item?.name }}
                      id={item?.id}
                    />
                  </div>
                );
              })}
          </div>
        )}
        {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
          <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-gray-200 p-[6px] mt-6 mb-4 rounded-xl animate-pulse flex gap-5" />
        ) : (
          <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-[#F9FAFB] border border-[#EAECF0] p-[6px] rounded-xl flex items-center justify-center mt-6 mb-4">
            {tabOptions?.map((option, index) => (
              <div
                className={`w-1/2 h-full rounded-md px-3 py-2  flex items-center justify-center cursor-pointer ${
                  "junction" == option?.value ? "bg-white shadow-sm" : ""
                }`}
                onClick={() => {
                  if (option?.value != "junction") navigate(`/app/product/mrp/supply/operation/${id}/plan-view`);
                }}
              >
                <p className={`text-md font-semibold ${"junction" == option?.value ? "text-[#344054]" : "text-[#667085]"}`}>{option?.label}</p>
              </div>
            ))}
          </div>
        )}
        {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
          <div className="w-full h-full bg-gray-200 rounded-xl animate-pulse mt-4" />
        ) : (
          <div style={{ minHeight: height - 135 }} className="flex w-full h-full rounded-xl mt-4 flex-col ">
            <div className="w-full h-full rounded-xl flex flex-col relative">
              <DragDropContext onDragEnd={onDragEnd}>
                <div id="content-wrapper" className="flex w-full flex-1 md:overflow-x-auto overflow-hidden">
                  <QualityTab
                    type={"active"}
                    length={activeJunctions?.filter((i) => i?.station)?.length}
                    children={
                      <Droppable droppableId="active">
                        {(provided) => (
                          <div {...provided.droppableProps} ref={provided.innerRef} className="w-full overflow-y-auto flex flex-col scrollbar-hide h-full">
                            {activeJunctions
                              ?.sort((b, a) => moment(a?.startDate ? a.startDate : 0).unix() - moment(b?.startDate ? b?.startDate : 0).unix())
                              ?.map((item, index) => {
                                if (item?.status === "active") {
                                  return (
                                    <Draggable key={item?.id} draggableId={item?.id} index={index}>
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className={`${snapshot.isDragging ? "max-w-[500px]" : ""} w-full mb-3`}
                                          key={index}
                                        >
                                          <div className="flex flex-row items-center gap-x-2 w-full border-b pb-1">
                                            <p className="text-sm font-medium text-secondary-700">{moments(new Date()).locale("tr").format("LL")}</p>
                                          </div>
                                          <div className="mt-3">
                                            <InternalOperationPlannedActiveEventCard
                                              data={item}
                                              setProductType={setProductType}
                                              setJunctionType={setJunctionType}
                                              setFinishJunctionId={setFinishJunctionId}
                                              setIsOpenJunctionPiece={setIsOpenJunctionPiece}
                                              refetch={refetch}
                                            />
                                            {/* <ActiveCard
                                                  data={item}
                                                  operation={{ operation: item?.header }}
                                                  refetch={refetch}
                                                  setProductType={setProductType}
                                                  setJunctionType={setJunctionType}
                                                  setFinishJunctionId={setFinishJunctionId}
                                                  setIsOpenJunctionPiece={setIsOpenJunctionPiece}
                                                /> */}
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                } else {
                                  return (
                                    <div className="w-full mb-3 relative group" key={index}>
                                      <div className="flex flex-row items-center gap-x-2 w-full border-b pb-1">
                                        <p className="text-sm font-medium text-secondary-700">{moments(new Date()).locale("tr").format("LL")}</p>
                                      </div>
                                      <div className="mt-2">
                                        {/* <InternalOperationPlannedEventCard data={item}/> */}
                                        <PlannedCard
                                          data={item}
                                          operation={{ operation: item?.header }}
                                          refetch={refetch}
                                          setIsSelectUserModal={setIsSelectUserModal}
                                          setSelectedJunction={setSelectedJunction}
                                        />
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                          </div>
                        )}
                      </Droppable>
                    }
                  />
                  <QualityTab
                    type={"waiting"}
                    length={completedWorks?.length}
                    children={
                      <Droppable droppableId="waiting">
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="w-full flex-1 overflow-y-auto flex flex-col scrollbar-hide gap-y-2 h-full"
                          >
                            {Object.keys(groupedByDateCompleted).map((date, index) => {
                              return (
                                <div key={index}>
                                  <div className="flex flex-row items-center gap-x-2 w-full border-b pb-1">
                                    <p className="text-sm font-medium text-secondary-700">{date}</p>
                                  </div>
                                  {groupedByDateCompleted[date].map((item, i) => (
                                    <Draggable key={item?.id} draggableId={item?.id} index={index}>
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className={`${snapshot.isDragging ? "max-w-[500px]" : ""} w-full mb-3 mt-3`}
                                          key={i}
                                        >
                                          <div className="flex flex-col">
                                            <InternalOperationPlannedWaitingEventCard
                                              data={item}
                                              refetch={refetch}
                                              setIsOpenForm={setIsOpenForm}
                                              setProductType={setProductType}
                                              setJunctionId={setJunctionId}
                                            />
                                            {/* <DoneAndQualityWaitingCard
                                                  data={item}
                                                  operation={{ operation: item?.header }}
                                                  setJunctionId={setJunctionId}
                                                  setIsOpenForm={setIsOpenForm}
                                                  setProductType={setProductType}
                                                  refetch={refetch}
                                                /> */}
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </Droppable>
                    }
                  />
                  <QualityTab
                    type={"finished"}
                    length={completedAndApprovedWorks?.length}
                    children={
                      <Droppable droppableId="finished">
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="w-full flex-1 overflow-y-auto flex flex-col scrollbar-hide gap-y-2 h-full"
                          >
                            {Object.keys(groupedByDateCompletedAndApproved).map((date, index) => {
                              return (
                                <div key={index}>
                                  <div className="flex flex-row items-center gap-x-2 w-full border-b pb-1">
                                    <p className="text-sm font-medium text-secondary-700">{date}</p>
                                  </div>
                                  {groupedByDateCompletedAndApproved[date].map((item, i) => (
                                    <Draggable key={item?.id} draggableId={item?.id} index={index}>
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className={`${snapshot.isDragging ? "max-w-[500px]" : ""} w-full mt-3`}
                                          key={i}
                                        >
                                          <InternalOperationPlannedDoneEventCard data={item} refetch={refetch} />
                                          {/* <QualityDoneCard data={item} operation={{ operation: item?.header }} refetch={refetch} /> */}
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </Droppable>
                    }
                  />
                  {(planView?.isFetching || planView?.isLoading) && (
                    <div className="w-full absolute bottom-0 p-2 px-3 flex items-center bg-white border-b border-secondary-200 flex-1 justify-center">
                      <Loading size={20} primary={"#B54708"} secondary={"#fff"} />
                    </div>
                  )}
                </div>
              </DragDropContext>
            </div>
          </div>
        )}
      </>
      <CustomModal
        isOpen={isOpenJunctionPiece}
        setIsOpen={setIsOpenJunctionPiece}
        onClose={() => {
          setIsOpenJunctionPiece(false);
        }}
        modalTitle={t("product:finishJuction")}
        width="fit-content"
        children={
          <FinishJunctionModal
            t={t}
            setIsOpen={setIsOpenJunctionPiece}
            isOpen={isOpenJunctionPiece}
            handleData={refetch}
            junctionId={finishJunctionId}
            productType={productType}
            type={"internal"}
            closeModal={() => {
              setIsOpenJunctionPiece(false);
            }}
          />
        }
      />
      <CustomModal
        isOpen={isOpenForm}
        setIsOpen={setIsOpenForm}
        modalTitle={t("product:qualityControlForm")}
        onClose={() => {
          setIsOpenForm(false);
        }}
        children={
          <QualityControlForm
            onClose={() => {
              setIsOpenForm(false);
            }}
            productType={productType}
            junctionId={junctionId}
            refetch={refetch}
          />
        }
      />
      <CustomModal
        isOpen={junctionDetailOpen}
        setIsOpen={setJunctionDetailOpen}
        onClose={() => {
          setJunctionDetailOpen(false);
        }}
        modalTitle={t("product:transactionDetail")}
        width="fit-content"
        children={<TransactionDetailModal t={t} setIsOpen={setJunctionDetailOpen} isOpen={junctionDetailOpen} id={finishJunctionId} />}
      />
      <CustomModal
        isOpen={isSelectUserModal}
        setIsOpen={setIsSelectUserModal}
        onClose={() => {
          setIsSelectUserModal(false);
        }}
        modalTitle={t("chat:startJob")}
        width="fit-content"
        children={
          <SelectUserModal t={t} setIsOpen={setIsSelectUserModal} isOpen={isSelectUserModal} selectedJunction={selectedJunction} handleData={refetch} />
        }
      />
    </div>
  );
};
export default InternalOperationJunction;

import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ArrowLeftIcon } from "../../assets/icons/productIcons";
import { Button, Input } from "../../components";
import generateFileUrl from "../../utils/generateFileUrl";
import { CheckIcon } from "../../assets/icons/modalIcons";
import { Toast } from "../../utils/toastify/toast";
import NoAvatar from "../../components/avatar/NoAvatar";
import { orderService } from "../../services/order.service";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";

const ManagementOrderPlanning = ({ }) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { orderId } = useParams();
  const [stockQuantity, setStockQuantity] = useState(0);
  const [productionQuantity, setProductionQuantity] = useState(0);
  const [isStockChecked, setIsStockChecked] = useState(false);
  const [isProductionChecked, setIsProductionChecked] = useState(false);

  const { data, isLoading, refetch, isFetching } = useQuery({
    queryKey: ["management-orders", orderId],
    queryFn: async () => (orderId ? await orderService.getOrderDetails(orderId) : ""),
    enabled: !!orderId,
    retry: 0,
  });

  const handleStockQuantity = (value) => {
    if (value > data?.data?.product?.qualityQuantity) {
      setStockQuantity(data?.data?.product?.qualityQuantity);
    } else {
      setStockQuantity(value);
    }
  };

  const tableHeadItems = [
    t("product:productss"),
    t("product:orderQuantityss"),
    t("product:stockAmount"),
    t("table:deliveryDate"),
    t("product:totalDeliveredQuantity"),
    t("product:quantityInProduction"),
    t("product:quantityNotSend"),
  ];
  const activePartial = data?.data?.partial?.filter((item) => item?.status == "active");
  const activePartialTotal = activePartial?.reduce((acc, item) => acc + item?.desiredQuantity, 0);

  const onSubmit = async () => {
    if (productionQuantity + stockQuantity > data?.data?.remainingQuantity) {
      Toast("error", "Toplam miktar, kalan miktarı aşamaz.");
    } else if (productionQuantity <= 0) {
      const sendData = {
        stockQuantity: stockQuantity || 0,
        productionQuantity: productionQuantity || 0,
        firstPageData: data?.data,
      };
      // await flexJunctionService.qualityFlexJunction(sendData).then((res) => {
      //   if (res.data.code === 0) {
      //     Toast("success", res?.data?.message);
      //   } else {
      //     Toast("error", res?.data?.message);
      //   }
      // });
    } else {
      const sendData = {
        stockQuantity: stockQuantity,
        productionQuantity: productionQuantity,
        firstPageData: data?.data,
      };

      sessionStorage.setItem("managementPlanningData", JSON.stringify(sendData));
      navigate(`/app/product/mrp/orders/management/supply-form/${orderId}`);
    }
  };
  return (
    <>
      <div className="flex flex-col w-full gap-y-8 h-[95vh]">
        <div className="flex min-h-[56px] max-h-[56px] h-[56px] w-full items-center justify-start gap-x-2 sticky top-0 z-[50] bg-white">
          <Button size={"md"} iconLeft={<ArrowLeftIcon />} colorType={"tertiary-gray"} onClick={() => navigate(-1)} type={"button"} />
          <p className="text-[#101828] font-semibold text-2xl">{t("product:manageOrder")}</p>
        </div>

        <div className="flex flex-col gap-y-6 w-full h-full">
          <div className="flex flex-col gap-y-1 w-full min-h-[72px] max-h-[72px] h-[72px] pb-5 justify-between border-b border-[#E4E7EC]">
            <p className="text-[#101828] font-semibold text-lg">{t("product:supplyTypeAndPlanning")}</p>
            <p className="text-[#344054] font-normal text-sm">{t("product:youCanDeduct")}</p>
          </div>
          <div className="flex flex-col gap-y-5 w-full h-fit border border-[#E4E7EC] rounded-xl">
            <div className="flex w-full min-h-[48px] max-h-[48px] h-[48px] justify-between bg-[#F9FAFB] rounded-t-xl items-center px-6 py-3 border-b border-[#E4E7EC]">
              <div className="flex gap-x-2 items-center">
                {data?.data?.order?.customer?.image ? (
                  <img
                    src={generateFileUrl(data?.data?.order?.customer?.image)}
                    className="min-w-[32px] w-[32px] h-[24px] rounded-sm border border-[#0000001A]"
                  />
                ) : (
                  <div className="w-[32px] h-[24px] min-w-[32px] min-h-[24px] flex items-center justify-center rounded border border-secondary-200 overflow-hidden">
                  <NoAvatar name={data?.data?.order?.customer?.name || "--"} minHeight={24} minWidth={32} rounded={4} theme={"product"} />
                </div>
                )}

                <p className="text-[#101828] font-medium text-sm">{data?.data?.order?.customer?.name || "--"}</p>
              </div>
              <div className="flex gap-x-2 items-center justify-center">
                <OrderIcon />
                <div className="flex gap-x-2 items-center">
                  <p className="text-[#475467] text-sm">{t("product:manufacturerOrderNo")}</p>
                  <p className="text-[#B54708] font-semibold text-md">{data?.data?.order?.orderNo || "--"}</p>
                </div>
              </div>
              <div className="flex gap-x-2 items-center justify-center">
                <OrderIcon />
                <div className="flex gap-x-2 items-center">
                  <p className="text-[#475467] text-sm">{t("product:customerOrderNo")}</p>
                  <p className="text-[#B54708] font-semibold text-md">{data?.data?.order?.customerOrderNo || "--"}</p>
                </div>
              </div>
              <div className="flex gap-x-2 items-center justify-center">
                <CalenderIcon />
                <div className="flex gap-x-2 items-center">
                  <p className="text-[#475467] text-sm">{t("product:orderGeneratedDate")}</p>
                  <p className="text-[#B54708] font-semibold text-md">{moment(data?.data?.order?.createdDate).format("DD.MM.YYYY")}</p>
                </div>
              </div>
            </div>
            <div className={`flex flex-col w-full h-full px-6`}>
              {/* table header */}
              <div className="flex max-h-[26px] min-h-[26px] w-full">
                {tableHeadItems.map((item, i) => (
                  <div
                    key={i}
                    className={`flex border-b px-3 py-1 border-r border-[#E4E7EC] items-center last:border-r-0 ${i === 0 ? "min-w-[400px] max-w-[400px] w-[400px]" : "w-full min-w-[50px] justify-center"
                      }`}
                  >
                    <span className="text-center text-xs font-medium cursor-pointer truncate ">{item}</span>
                  </div>
                ))}
              </div>
              <div className="flex w-full items-center h-[100px] min-h-[100px] ">
                {/* tablo 1.kolon */}
                <div className=" flex items-center gap-x-3 py-4 pr-6 border-r h-full min-w-[400px] max-w-[400px] w-[400px] justify-start">
                  {data?.data?.product?.image ? (
                    <img className="w-[64px] h-[48px] rounded border border-[#0000001A]" src={generateFileUrl(data?.data?.product?.image)} />
                  ) : (
                    <div className="flex min-w-[64px] max-w-[64px] w-[64px] min-h-[48px] max-h-[48px] h-[48px]">
                      <NoAvatar theme={"product"} name={data?.data?.product?.name} minWidth="64px" minHeight="48px" rounded={4} />
                    </div>
                  )}
                  <div className="flex flex-col gap-y-1 justify-center">
                    <p className="text-[#101828] font-semibold text-md">{data?.data?.product?.name || "--"}</p>
                    <p className="text-[#475467] text-sm">{data?.data?.product?.stockCode || "--"}</p>
                  </div>
                </div>
                {/* tablo 2.kolon */}
                <div className="border-r flex flex-col h-full py-3 items-center justify-center w-full">
                  <p className="text-md text-secondary-600 dark:text-[#F5F5F5] font-normal text-center">{data?.data?.orderedQuantity || "0"}</p>
                  <p className="text-sm font-normal text-secondary-500">{t("product:pieces")}</p>
                </div>
                {/* tablo 3.kolon */}
                <div className="border-r flex flex-col h-full py-3 items-center justify-center w-full">
                  <p
                    className={`whitespace-nowrap text-center overflow-hidden 
                      text-ellipsis text-md  dark:text-[#F5F5F5] font-normal ${data?.data?.product?.qualityQuantity < data?.data?.orderedQuantity ? "text-error-600" : "text-success-600"
                      } `}
                  >
                    {data?.data?.product?.qualityQuantity || "0"}
                  </p>
                  <p className="text-sm font-normal text-secondary-500">{t("product:pieces")}</p>
                </div>
                {/* tablo 4.kolon */}
                <div className="border-r flex flex-col h-full py-3 items-center justify-center w-full">
                  <p className="text-md text-secondary-600 dark:text-[#F5F5F5] font-normal text-center">
                    {moment(data?.data?.deliveryDate)?.format("DD.MM.YYYY")}
                  </p>
                  <p className="text-sm font-normal text-secondary-500">{t("product:pieces")}</p>
                </div>
                {/* tablo 5.kolon */}
                <div className="border-r flex flex-col h-full py-3 items-center justify-center w-full">
                  <p className="text-md text-secondary-600 dark:text-[#F5F5F5] font-normal text-center">{data?.data?.finishedQuantity || "0"}</p>
                  <p className="text-sm font-normal text-secondary-500">{t("product:pieces")}</p>
                </div>
                {/* tablo 6.kolon */}
                <div className="border-r flex flex-col h-full py-3 items-center justify-center w-full">
                  <p className="text-md text-secondary-600 dark:text-[#F5F5F5] font-normal text-center">{activePartialTotal || "0"}</p>
                  <p className="text-sm font-normal text-secondary-500">{t("product:pieces")}</p>
                </div>
                {/* tablo 7.kolon */}
                <div className="flex flex-col h-full py-3 items-center justify-center w-full">
                  <p className="text-md text-secondary-600 dark:text-[#F5F5F5] font-normal text-center">
                    {data?.data?.orderedQuantity - activePartialTotal || "0"}
                  </p>
                  <p className="text-sm font-normal text-secondary-500">{t("product:pieces")}</p>
                </div>
              </div>
            </div>
          </div>
          {data?.data?.orderedQuantity === activePartialTotal ? (
            <></>
          ) : (
            <>
              <div className="flex w-full min-h-[68px] max-h-[68px] h-[68px] items-center justify-end">
                <div className="flex gap-x-8 h-full w-[520px] min-w-[520px] max-w-[520px]">
                  <div className="flex flex-col gap-y-3 w-1/2">
                    <div
                      onClick={() => {
                        if (data?.data?.product?.qualityQuantity === null || data?.data?.product?.qualityQuantity === 0) {
                          Toast("error", "Stok miktarı 0 olan ürünler için stoktan çıkış yapamazsınız.");
                        } else {
                          setIsStockChecked(!isStockChecked);
                        }
                      }}
                      className={`flex gap-x-2 items-center w-fit cursor-pointer ${data?.data?.product?.qualityQuantity === null || data?.data?.product?.qualityQuantity === 0
                          ? "cursor-not-allowed opacity-50"
                          : "cursor-pointer"
                        }`}
                    >
                      <div
                        className={`min-w-[16px] w-4 h-4 rounded flex items-center justify-center border border-[#D0D5DD] ${isStockChecked ? "bg-[#DC6803] border-[#DC6803] text-[#fff]" : "border-[#D0D5DD]"
                          }`}
                      >
                        {isStockChecked && <CheckIcon />}
                      </div>
                      <div className="text-secondary-500 font-normal text-sm">{t("product:bringFromStock")}</div>
                      <span className="group flex relative">
                        <HelpCircleIcon />
                        <div className="hidden absolute min-w-[293px] max-w-[293px] w-[293px] min-h-[52px] max-h-[52px] h-[52px] group-hover:flex bottom-[26px] -left-[136px] bg-[#0C111D] rounded-lg px-3 py-2 text-center">
                          <p className="text-[#FFFFFF] font-semibold text-xs">{t("product:enterTheQuantity")}</p>
                        </div>
                        <span className="hidden absolute group-hover:flex bottom-[20px]">
                          <TriangleTooltipIcon />
                        </span>
                      </span>
                    </div>
                    <div className="flex w-full h-full">
                      <Input
                        icon={<QuantityIcon />}
                        theme="product"
                        type={"number"}
                        value={stockQuantity}
                        onChange={(e) => handleStockQuantity(parseInt(e.target.value))}
                        disabled={!isStockChecked}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-3 w-1/2">
                    <div onClick={() => setIsProductionChecked(!isProductionChecked)} className={`flex gap-x-2 items-center w-fit cursor-pointer`}>
                      <div
                        className={`min-w-[16px] w-4 h-4 rounded flex items-center justify-center border ${isProductionChecked ? "bg-[#DC6803] border-[#DC6803] text-[#fff]" : "border-[#D0D5DD]"
                          }`}
                      >
                        {isProductionChecked && <CheckIcon />}
                      </div>
                      <div className="text-secondary-500 font-normal text-sm">{t("product:sendToProduction")}</div>
                      <span className="group flex relative">
                        <HelpCircleIcon />
                        <div className="hidden absolute min-w-[370px] max-w-[370px] w-[370px] min-h-[70px] max-h-[70px] h-[70px] group-hover:flex bottom-[26px] -left-[260px] bg-[#0C111D] rounded-lg px-3 py-2 text-center">
                          <p className="text-[#FFFFFF] font-semibold text-xs">
                            {t("product:youCanSendTheEntire")}
                          </p>
                        </div>
                        <span className="hidden absolute group-hover:flex bottom-[20px]">
                          <TriangleTooltipIcon />
                        </span>
                      </span>
                    </div>
                    <div className="flex w-full h-full">
                      <Input
                        icon={<QuantityIcon />}
                        theme="product"
                        type={"number"}
                        value={productionQuantity}
                        onChange={(e) => setProductionQuantity(parseInt(e.target.value))}
                        disabled={!isProductionChecked}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex min-h-[60px] max-h-[60px] h-[60px] w-full border-t border-[#E4E7EC] items-center justify-end pt-5">
                <div className="flex min-w-[300px] max-w-[300px] w-[300px] gap-x-3">
                  <Button
                    size={"md"}
                    colorType={"secondary-gray"}
                    label={t("buttons:stop")}
                    onClick={() => {
                      sessionStorage.removeItem("managementPlanningData");
                      navigate("/app/product/mrp/orders");
                    }}
                  />
                  <Button size={"md"} colorType={"primary-product"} label={t("buttons:next")} type={"submit"} onClick={onSubmit} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ManagementOrderPlanning;

const QuantityIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M5.75 2.375H3.71429C2.76751 2.375 2 3.14251 2 4.08929V11.9107C2 12.8575 2.76751 13.625 3.71429 13.625H12.2857C13.2325 13.625 14 12.8575 14 11.9107V4.08929C14 3.14251 13.2325 2.375 12.2857 2.375H10.25M5.75 2.375V6.73631C5.75 6.80003 5.81706 6.84147 5.87405 6.81298L8 5.75L10.126 6.81298C10.1829 6.84147 10.25 6.80003 10.25 6.73631V2.375M5.75 2.375H10.25"
        stroke="#475467"
      />
    </svg>
  );
};

const HelpCircleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clip-path="url(#clip0_17461_3132)">
        <path
          d="M6.06016 6.00016C6.2169 5.55461 6.52626 5.1789 6.93347 4.93958C7.34067 4.70027 7.81943 4.61279 8.28495 4.69264C8.75047 4.77249 9.17271 5.01451 9.47688 5.37585C9.78105 5.73718 9.94753 6.19451 9.94683 6.66683C9.94683 8.00016 7.94683 8.66683 7.94683 8.66683M8.00016 11.3335H8.00683M14.6668 8.00016C14.6668 11.6821 11.6821 14.6668 8.00016 14.6668C4.31826 14.6668 1.3335 11.6821 1.3335 8.00016C1.3335 4.31826 4.31826 1.3335 8.00016 1.3335C11.6821 1.3335 14.6668 4.31826 14.6668 8.00016Z"
          stroke="#98A2B3"
          stroke-width="1.33"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17461_3132">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const TriangleTooltipIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="9" viewBox="0 0 17 9" fill="none">
      <path
        d="M8.5 0.49993L14.5711 0.49993C15.462 0.49993 15.9081 1.57707 15.2782 2.20704L9.20711 8.2781C8.81658 8.66863 8.18342 8.66863 7.79289 8.2781L1.72183 2.20704C1.09186 1.57707 1.53803 0.49993 2.42893 0.49993L8.5 0.49993Z"
        fill="#0C111D"
      />
    </svg>
  );
};

const CalenderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M17.5 8.33317H2.5M13.3333 1.6665V4.99984M6.66667 1.6665V4.99984M6.5 18.3332H13.5C14.9001 18.3332 15.6002 18.3332 16.135 18.0607C16.6054 17.821 16.9878 17.4386 17.2275 16.9681C17.5 16.4334 17.5 15.7333 17.5 14.3332V7.33317C17.5 5.93304 17.5 5.23297 17.2275 4.69819C16.9878 4.22779 16.6054 3.84534 16.135 3.60565C15.6002 3.33317 14.9001 3.33317 13.5 3.33317H6.5C5.09987 3.33317 4.3998 3.33317 3.86502 3.60565C3.39462 3.84534 3.01217 4.22779 2.77248 4.69819C2.5 5.23297 2.5 5.93304 2.5 7.33317V14.3332C2.5 15.7333 2.5 16.4334 2.77248 16.9681C3.01217 17.4386 3.39462 17.821 3.86502 18.0607C4.3998 18.3332 5.09987 18.3332 6.5 18.3332Z"
        stroke="#475467"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const OrderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M16.6667 8.74984V5.6665C16.6667 4.26637 16.6667 3.56631 16.3942 3.03153C16.1545 2.56112 15.7721 2.17867 15.3017 1.93899C14.7669 1.6665 14.0668 1.6665 12.6667 1.6665H7.33337C5.93324 1.6665 5.23318 1.6665 4.6984 1.93899C4.22799 2.17867 3.84554 2.56112 3.60586 3.03153C3.33337 3.56631 3.33337 4.26637 3.33337 5.6665V14.3332C3.33337 15.7333 3.33337 16.4334 3.60586 16.9681C3.84554 17.4386 4.22799 17.821 4.6984 18.0607C5.23318 18.3332 5.93324 18.3332 7.33337 18.3332M10 7.49984H6.66671M11.6667 4.99984H6.66671M8.33337 9.99984H6.66671M9.83711 12.6319L12.8556 10.9452C12.9978 10.8658 13.0688 10.8261 13.1441 10.8105C13.2108 10.7967 13.2795 10.7967 13.3462 10.8103C13.4215 10.8258 13.4927 10.8654 13.6349 10.9446L16.6667 12.6319M9.83711 12.6319L13.2451 14.5309M9.83711 12.6319V15.9547C9.83711 16.1253 9.83711 16.2106 9.86218 16.2868C9.88436 16.3541 9.92062 16.416 9.96856 16.4683C10.0227 16.5274 10.0972 16.5691 10.246 16.6525L13.2451 18.3332M16.6667 12.6319L13.2451 14.5309M16.6667 12.6319V15.9537C16.6667 16.1247 16.6667 16.2102 16.6416 16.2864C16.6193 16.3539 16.5829 16.4159 16.5349 16.4682C16.4805 16.5273 16.4059 16.569 16.2566 16.6523L13.2451 18.3332M13.2451 14.5309V18.3332"
        stroke="#475467"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

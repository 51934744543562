import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SearchInput from "../../components/inputs/SearchInput";
import { Button, CustomModal, Loading, PaginationComponent } from "../../components";
import { PlusIcon } from "../../assets/icons/stepsIcons";
import { TableArrowLeft } from "../../assets/icons/lossIcon";
import { useTranslation } from "react-i18next";
import { DeleteIcon, EditIcon } from "../../assets/icons/departmentIcon";
import SupplierModal from "./modals/SupplierModal";
import { rawMaterialService } from "../../services/raw-material.service";
import { useQuery } from "@tanstack/react-query";
import generateFileUrl from "../../utils/generateFileUrl";
import diacritics from "diacritics";
import AlertModal from "../../components/alert-Modal/Alert-Modal";

import { externalOperationService } from "../../services/external-operation.service";
import NoAvatar from "../../components/avatar/NoAvatar";
import { purchaseService } from "../../services/purchase.service";
import AddSupplierGroupModal from "./modals/AddSupplierGroupModal";
import { SwitchIcon } from "../../assets/icons/commonIcons";
import { FilterIcon } from "../../assets/icons/productIcons";

const SupplierList = () => {
  const { t } = useTranslation();
  const { type } = useParams();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [onEdit, setOnEdit] = useState(null);
  const [suppliermodal, setSupplierModal] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isSelectTab, setIsSelectTab] = useState(type || "material");
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [selectedGroup, setSelectedGroup] = useState("all");
  const [addSupplierGroup, setAddSupplierGroup] = useState(false);
  const getGroups = useQuery({
    queryKey: [isSelectTab == "material" ? "getSupplierGroups" : "getExternalSupplierGroups"],
    enabled: true,
    queryFn: async () => {
      if (isSelectTab == "material") {
        return await purchaseService.getSuppliersGroup();
      } else {
        return await externalOperationService.listOperation();
      }
    },
    retry: 0,
  });

  const { data, isLoading, refetch, isFetching } = useQuery({
    queryKey: [isSelectTab == "material" ? "supplierList" : "external-supplierList"],
    enabled: false,
    queryFn: async () => {
      if (isSelectTab == "material") {
        return selectedGroup != "all" ? await purchaseService.supplierListByGroup(selectedGroup) : await rawMaterialService.supplierList();
      } else {
        return selectedGroup != "all" ? await externalOperationService.supplierListByGroup(selectedGroup) : await externalOperationService.listSupplier();
      }
    },
    retry: 0,
  });

  const tableHeadItemsMaterial = [
    { name: "Image", value: "image", type: "" },

    { name: t("addCompany:companyName"), value: "companyName", type: "" },
    {
      name: t("product:productionType"),
      value: "productionType",
      type: "string",
    },
    { name: t("table:phoneNo"), value: "phoneNumber", type: "number" },
    { name: t("product:email"), value: "email", type: "string" },
    {
      name: t("addCompany:contactName"),
      value: "authorization",
      type: "string",
    },
    { name: t("routes:adress"), value: "address", type: "string" },
    { name: t("buttons:edit"), value: "edit" },
    { name: t("buttons:delete"), value: "delete", type: "" },
  ];
  const tableHeadItems = [
    { name: "Image", value: "image", type: "" },
    { name: t("addCompany:companyName"), value: "companyName", type: "" },
    { name: t("table:phoneNo"), value: "phoneNumber", type: "number" },
    { name: t("product:email"), value: "email", type: "string" },
    {
      name: t("addCompany:contactName"),
      value: "authorization",
      type: "string",
    },
    { name: t("routes:adress"), value: "address", type: "string" },
    { name: t("buttons:edit"), value: "edit" },
    { name: t("buttons:delete"), value: "delete", type: "" },
  ];

  // const tableHeadItems = [
  //   { name: "Image", value: "image", type: "" },

  //   { name: t("addCompany:companyName"), value: "companyName", type: "" },
  //   {
  //     name: t("product:productionType"),
  //     value: "productionType",
  //     type: "string",
  //   },
  //   { name: t("table:phoneNo"), value: "phoneNumber", type: "number" },
  //   { name: t("product:email"), value: "email", type: "string" },
  //   {
  //     name: t("addCompany:contactName"),
  //     value: "authorization",
  //     type: "string",
  //   },
  //   { name: t("routes:adress"), value: "address", type: "string" },
  //   { name: t("buttons:edit"), value: "edit" },
  //   { name: t("buttons:delete"), value: "delete", type: "" },
  // ];
  const filteredSupplier =
    isSelectTab == "material"
      ? data?.filter((d) => diacritics.remove(`${d?.name} ${d?.productionType}`).toLowerCase().includes(diacritics.remove(searchQuery).toLowerCase()))
      : data?.data?.filter((d) => diacritics.remove(`${d?.name} ${d?.productionType}`).toLowerCase().includes(diacritics.remove(searchQuery).toLowerCase()));
  useEffect(() => {
    refetch();
  }, [selectedGroup, isSelectTab]);
  return (
    <>
      <div className="w-full h-full flex flex-col overflow-hidden">
        <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
          <Button
            colorType={"tertiary-gray"}
            onClick={() => {
              navigate(-1);
            }}
            size={"sm"}
            iconLeft={
              <div className="rotate-180">
                <TableArrowLeft />
              </div>
            }
          />

          <div className="flex items-center">
            <span className="text-2xl text-secondary-900 font-semibold ml-2">{t("product:supplierList")}</span>
          </div>
          <div className="ml-auto flex flex-row items-center gap-x-3">
            <Button
              colorType={"primary-product"}
              size={"md"}
              label={t("product:createNewSupplier")}
              iconLeft={<PlusIcon />}
              onClick={() => {
                setOnEdit(null);
                setSupplierModal(true);
              }}
            />
          </div>
        </div>
        <div className="flex w-full min-h-[40px] max-h-[40px] items-center">
          <div className="flex border h-full items-center justify-center rounded-lg text-sm font-semibold text-secondary-700 whitespace-nowrap cursor-pointer">
            <div
              onClick={() => {
                setSelectedGroup("all");
                setIsSelectTab("material");
              }}
              className={`flex w-1/2 items-center justify-center h-full px-4 py-2 ${isSelectTab == "material" && "bg-secondary-100"}`}
            >
              {t("product:materialSupplier")}
            </div>
            <div
              onClick={() => {
                setSelectedGroup("all");
                setIsSelectTab("service");
              }}
              className={`flex w-1/2 items-center justify-center border-l h-full px-4 py-2 ${isSelectTab == "service" && "bg-secondary-100"}`}
            >
              {t("product:serviceSupplier")}
            </div>
          </div>
          <div className="flex ml-auto h-full items-center gap-x-3 min-h-[40px] max-h-[40px]">
            <span className="flex min-w-[220px] max-w-[220px] h-full items-center justify-center">
              <SearchInput setSearchVal={setSearchQuery} theme={"product"} />
            </span>
            <Button colorType={"secondary-gray"} size={"md"} label={t("product:sorted")} iconLeft={<SwitchIcon />} />
            <Button colorType={"secondary-gray"} size={"md"} label={t("product:filters")} iconLeft={<FilterIcon />} />
          </div>
        </div>
        <div className="flex w-full  items-center overflow-x-auto pb-3 gap-4 pt-4">
          {isSelectTab == "material" && (
            <button
              type="button"
              onClick={() => setAddSupplierGroup(true)}
              className="h-9 w-9 min-h-[36px] min-w-[36px] rounded-lg border shadow items-center justify-center flex border-gray-300"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M10.0003 4.1665V15.8332M4.16699 9.99984H15.8337"
                  stroke="#344054"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          )}
          <button
            type="button"
            onClick={() => setSelectedGroup("all")}
            className={`min-w-[180px] px-4 h-9 rounded-lg border shadow ${selectedGroup === "all" ? "border-[#DC6803]" : "border-gray-300"}`}
          >
            <p className={`text-sm font-semibold ${selectedGroup === "all" ? "text-[#B54708]" : "text-gray-700"}`}>{t("product:allNew")}</p>
          </button>
          {getGroups?.data?.data?.map((item) => (
            <button
              type="button"
              key={item?.id}
              onClick={() => setSelectedGroup(item?.id)}
              className={`min-w-[180px] px-4 h-9 rounded-lg border shadow ${selectedGroup === item?.id ? "border-[#DC6803]" : "border-gray-300"}`}
            >
              <p className={`text-sm font-semibold truncate ${selectedGroup === item?.id ? "text-[#B54708]" : "text-gray-700"}`}>{item?.name}</p>
            </button>
          ))}
        </div>
        {isLoading || isFetching ? (
          <div className="w-full h-full mb-3 bg-gray-200 animate-pulse rounded-lg" />
        ) : (
          <div className="flex-1 w-full mt-4 border rounded-xl flex flex-col overflow-hidden relative sm:px-11">
            <div id="content-wrapper" className="min-w-full max-w-full w-full h-[86vh] rounded-xl overflow-x-auto scrollbar-hide flex flex-col">
              <div className="flex max-h-[44px] min-h-[44px] max-w-full min-w-full">
                {isSelectTab == "material" ? (
                  <>
                    {tableHeadItemsMaterial?.map((item, i) => {
                      if (isSelectTab == "service" && i === 2) {
                        return null;
                      }
                      return (
                        <div
                          key={i}
                          scope="col"
                          className={`flex px-4 py-3 ${i === 8 ? "" : "border-r"} ${
                            i === 7 || i === 8
                              ? "w-[5%]  md:min-w-[9%] md:w-[9%] sm:min-w-[80px] sm:max-w-[80px] justify-center items-center"
                              : i === 5
                              ? "w-[12%] md:min-w-[10%] md:w-[10%] sm:min-w-[150px] sm:max-w-[150px] justify-center items-center"
                              : i === 0
                              ? "w-[6%] md:min-w-[9%] md:w-[9%] sm:min-w-[200px] sm:max-w-[200px] justify-center items-center"
                              : i === 2
                              ? `w-[10%] md:min-w-[12%] md:w-[12%] sm:min-w-[200px] sm:max-w-[200px] hidden`
                              : i === 1
                              ? "w-[18%] md:min-w-[13%] md:w-[13%] sm:min-w-[200px] sm:max-w-[200px]"
                              : i === 3
                              ? "w-[11%] md:min-w-[12%] md:w-[12%] sm:min-w-[200px] sm:max-w-[200px] justify-center items-center"
                              : i === 4
                              ? "w-[15%] md:min-w-[11%] md:w-[11%] sm:min-w-[200px] sm:max-w-[200px] justify-center items-center"
                              : i == 6
                              ? `w-[18%] md:min-w-[15%] md:w-[15%] sm:min-w-[200px] sm:max-w-[200px]`
                              : ""
                          }] items-center text-center bg-secondary-50 border-gray-200 border-b border-r`}
                        >
                          <span
                            className={`text-center rounded flex md:line-clamp-2  text-xs font-medium ${
                              i === 1 || i === 6 ? "" : "justify-center"
                            }  items-center whitespace-nowrap`}
                          >
                            {item?.name}
                          </span>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {tableHeadItems?.map((item, i) => {
                      if (isSelectTab == "material" && i === 2) {
                        return null;
                      }
                      return (
                        <div
                          key={i}
                          scope="col"
                          className={`flex px-4 py-3 ${i === 7 ? "" : "border-r"} ${
                            i === 6 || i === 7
                              ? "w-[5%]  md:min-w-[6%] md:w-[6%] sm:min-w-[80px] sm:max-w-[80px] justify-center items-center"
                              : i === 4
                              ? "w-[12%] md:min-w-[13%] md:w-[13%] sm:min-w-[150px] sm:max-w-[150px] justify-center items-center"
                              : i === 0
                              ? `w-[6%] md:min-w-[9%] md:w-[9%] sm:min-w-[200px] sm:max-w-[200px] hidden`
                              : i === 1
                              ? "w-[18%] md:min-w-[15%] md:w-[15%] sm:min-w-[200px] sm:max-w-[200px]"
                              : i === 2
                              ? "w-[11%] md:min-w-[13%] md:w-[13%] sm:min-w-[200px] sm:max-w-[200px] justify-center items-center"
                              : i === 3
                              ? "w-[15%] md:min-w-[14%] md:w-[14%] sm:min-w-[200px] sm:max-w-[200px] justify-center items-center"
                              : i == 5
                              ? `w-[28%] md:min-w-[24%] md:w-[24%] sm:min-w-[200px] sm:max-w-[200px]`
                              : ""
                          }] items-center text-center bg-secondary-50 border-gray-200 border-b border-r`}
                        >
                          <span
                            className={`text-center rounded flex text-xs font-medium ${
                              i === 1 || i === 6 ? "" : "justify-center"
                            }  items-center whitespace-nowrap`}
                          >
                            {item?.name}
                          </span>
                        </div>
                      );
                    })}
                  </>
                )}
                {/* {tableHeadItems.map((item, i) => {
                    if (isSelectTab == "service" && i === 2) {
                      return null;
                    }
                    return (
                      <div
                        key={i}
                        scope="col"
                        className={`flex px-4 py-3 ${
                          i === 8 ? "" : "border-r"
                        } ${
                          i === 7 || i === 8
                            ? "w-[5%] sm:min-w-[80px] sm:max-w-[80px] justify-center items-center"
                            : i === 5
                            ? "w-[12%] sm:min-w-[150px] sm:max-w-[150px] justify-center items-center"
                            : i === 0
                            ? "w-[6%] sm:min-w-[200px] sm:max-w-[200px] justify-center items-center"
                            : i === 2
                            ? `w-[10%] sm:min-w-[200px] sm:max-w-[200px] hidden`
                            : i === 1
                            ? "w-[18%] sm:min-w-[200px] sm:max-w-[200px]"
                            : i === 3
                            ? "w-[11%] sm:min-w-[200px] sm:max-w-[200px] justify-center items-center"
                            : i === 4
                            ? "w-[15%] sm:min-w-[200px] sm:max-w-[200px] justify-center items-center"
                            : i == 6
                            ? `${
                                isSelectTab == "material"
                                  ? "w-[18%]"
                                  : "w-[28%]"
                              } sm:min-w-[200px] sm:max-w-[200px]`
                            : ""
                        }] items-center text-center bg-secondary-50 border-gray-200 border-b border-r`}
                      >
                        <span
                          className={`text-center rounded flex text-xs font-medium ${
                            i === 1 || i === 6 ? "" : "justify-center"
                          }  items-center whitespace-nowrap`}
                        >
                          {item?.name}
                        </span>
                      </div>
                    );
                  })} */}
              </div>
              <div className="w-full bg-[#FFF] flex-1 flex flex-col">
                {filteredSupplier?.map((item, key) => (
                  <>
                    {isSelectTab == "material" ? (
                      <div key={key} className={`flex w-full items-center border-b h-[72px] min-h-[72px] `}>
                        <div className="py-4 px-4 overflow-hidden flex items-center justify-center border-r h-[72px] w-[6%] md:min-w-[9%] md:w-[9%] sm:w-[93px] xs:w-[93px]">
                          <div className="w-[60px] h-[45px] min-w-[60px] min-h-[45px] rounded border border-secondary-200 overflow-hidden">
                            {item.image ? (
                              <img src={generateFileUrl(item?.image)} />
                            ) : (
                              <div className="w-[60px] h-[45px] min-w-[60px] min-h-[45px] flex rounded  overflow-hidden">
                                <NoAvatar name={item?.name || "--"} rounded={4} theme={"product"} minHeight="45px" minWidth="60px" />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="w-[18%] border-r h-full p-3 flex items-center md:min-w-[13%] md:w-[13%] sm:min-w-[200px] sm:max-w-[200px]">
                          <p className="w-[90%] whitespace-nowrap  overflow-hidden text-ellipsis text-sm text-gray-500 dark:text-[#F5F5F5] font-normal">
                            {item?.name}
                          </p>
                        </div>
                        {isSelectTab == "material" && (
                          <div className=" border-r h-full p-3 flex items-center md:min-w-[12%] md:w-[12%] justify-center w-[10%] sm:min-w-[150px] sm:max-w-[150px]">
                            <p className="w-[90%] text-center text-sm text-gray-500 dark:text-[#F5F5F5] font-normal">{item?.productionType}</p>
                          </div>
                        )}
                        <div className="border-r h-full p-3 flex items-center justify-center w-[11%] md:min-w-[12%] md:w-[12%] sm:min-w-[150px] sm:max-w-[150px]">
                          <p className="w-[90%] whitespace-nowrap text-center overflow-hidden text-ellipsis text-sm text-gray-500 dark:text-[#F5F5F5] font-normal">
                            {item?.contactPhone}
                          </p>
                        </div>
                        <div className="border-r h-full p-3 flex items-center justify-center w-[15%] md:min-w-[11%] md:w-[11%] sm:min-w-[150px] sm:max-w-[150px]">
                          <p className="w-[90%] whitespace-nowrap overflow-hidden text-center text-ellipsis text-sm text-gray-500 dark:text-[#F5F5F5] font-normal">
                            {item?.contactEmail}
                          </p>
                        </div>
                        <div className="whitespace-nowrap h-full flex p-3 border-r text-center items-center justify-center text-sm text-gray-500 dark:text-[#F5F5F5] font-normal w-[12%] md:min-w-[10%] md:w-[10%] sm:min-w-[150px] sm:max-w-[150px]">
                          <p className="w-[90%] whitespace-nowrap overflow-hidden text-center text-ellipsis text-sm text-gray-500 dark:text-[#F5F5F5] font-normal">
                            {item?.contactName}
                          </p>
                        </div>
                        <div className={`h-full border-r flex  p-3 items-center w-[18%] md:min-w-[15%] md:w-[15%] sm:min-w-[150px] sm:max-w-[150px]`}>
                          <p className="w-[100%] text-sm text-gray-500 dark:text-[#F5F5F5] font-normal text-ellipsis whitespace-nowrap overflow-hidden">
                            {item?.address}
                          </p>
                        </div>
                        <div className="h-full items-center border-r flex justify-center w-[5%] md:min-w-[9%] md:w-[9%] sm:min-w-[80px] sm:max-w-[80px]">
                          <Button
                            onClick={() => {
                              setOnEdit(item);
                              setSupplierModal(true);
                            }}
                            colorType={"tertiary-gray"}
                            iconLeft={<EditIcon />}
                            size={"sm"}
                          />
                        </div>
                        <div className="h-full items-center flex justify-center w-[5%] md:min-w-[9%] md:w-[9%] sm:min-w-[80px] sm:max-w-[80px] ">
                          <Button
                            // onClick={() => {
                            //   setDeleteId(item?.id);
                            //   setIsAlert(true);
                            // }}
                            colorType={"tertiary-error"}
                            iconLeft={<DeleteIcon />}
                            size={"sm"}
                          />
                        </div>
                      </div>
                    ) : (
                      <div key={key} className={`flex w-full items-center border-b h-[72px] min-h-[72px] `}>
                        <div className="py-4 px-4 overflow-hidden flex items-center justify-center border-r h-[72px] w-[6%] md:min-w-[9%] md:w-[9%] sm:w-[93px] xs:w-[93px]">
                          <div className="w-[60px] h-[45px] min-w-[60px] min-h-[45px] rounded border border-secondary-200 overflow-hidden">
                            {item.image ? (
                              <img src={generateFileUrl(item?.image)} />
                            ) : (
                              <div className="w-[60px] h-[45px] min-w-[60px] min-h-[45px] flex rounded  overflow-hidden">
                                <NoAvatar name={item?.name || "--"} rounded={4} theme={"product"} minHeight="45px" minWidth="60px" />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="w-[18%] md:min-w-[15%] md:w-[15%] border-r h-full p-3 flex items-center  sm:min-w-[200px] sm:max-w-[200px]">
                          <p className="w-[90%] whitespace-nowrap  overflow-hidden text-ellipsis text-sm text-gray-500 dark:text-[#F5F5F5] font-normal">
                            {item?.name}
                          </p>
                        </div>

                        <div className="border-r h-full p-3 flex items-center justify-center w-[11%] md:min-w-[13%] md:w-[13%] sm:min-w-[150px] sm:max-w-[150px]">
                          <p className="w-[90%] whitespace-nowrap text-center overflow-hidden text-ellipsis text-sm text-gray-500 dark:text-[#F5F5F5] font-normal">
                            {item?.contactPhone}
                          </p>
                        </div>
                        <div className="border-r h-full p-3 flex items-center justify-center w-[15%]  md:min-w-[14%] md:w-[14%] sm:min-w-[150px] sm:max-w-[150px]">
                          <p className="w-[90%] whitespace-nowrap overflow-hidden text-center text-ellipsis text-sm text-gray-500 dark:text-[#F5F5F5] font-normal">
                            {item?.contactEmail}
                          </p>
                        </div>
                        <div className="whitespace-nowrap h-full flex p-3 border-r text-center items-center justify-center text-sm text-gray-500 dark:text-[#F5F5F5] font-normal w-[12%] md:min-w-[13%] md:w-[13%] sm:min-w-[150px] sm:max-w-[150px]">
                          <p className="w-[90%] whitespace-nowrap overflow-hidden text-center text-ellipsis text-sm text-gray-500 dark:text-[#F5F5F5] font-normal">
                            {item?.contactName}
                          </p>
                        </div>
                        <div className={`h-full border-r flex  p-3 items-center w-[28%] md:min-w-[24%] md:w-[24%] sm:min-w-[150px] sm:max-w-[150px]`}>
                          <p className="w-[100%] text-sm text-gray-500 dark:text-[#F5F5F5] font-normal text-ellipsis whitespace-nowrap overflow-hidden">
                            {item?.address}
                          </p>
                        </div>
                        <div className="h-full items-center  md:min-w-[6%] md:w-[6%] border-r flex justify-center w-[5%] sm:min-w-[80px] sm:max-w-[80px]">
                          <Button
                            onClick={() => {
                              setOnEdit(item);
                              setSupplierModal(true);
                            }}
                            colorType={"tertiary-gray"}
                            iconLeft={<EditIcon />}
                            size={"sm"}
                          />
                        </div>
                        <div className="h-full items-center  md:min-w-[6%] md:w-[6%] flex justify-center w-[5%] sm:min-w-[80px] sm:max-w-[80px] ">
                          <Button
                            // onClick={() => {
                            //   setDeleteId(item?.id);
                            //   setIsAlert(true);
                            // }}
                            colorType={"tertiary-error"}
                            iconLeft={<DeleteIcon />}
                            size={"sm"}
                          />
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
            <div className="z-50 border-t border-[#D0D5DD] bg-white rounded-b-xl">
              <PaginationComponent pageNo={pageNo} setPageNo={setPageNo} pageCount={pageCount} />
            </div>
          </div>
        )}
      </div>
      <CustomModal
        isOpen={addSupplierGroup}
        setIsOpen={setAddSupplierGroup}
        modalTitle={t("product:createSupplierGroup")}
        children={
          <AddSupplierGroupModal
            closeModal={() => setAddSupplierGroup(false)}
            // refetch={getGroups.refetch()}
          />
        }
      />
      <CustomModal
        isOpen={suppliermodal}
        setIsOpen={setSupplierModal}
        onClose={() => {
          setSupplierModal(false);
        }}
        modalTitle={t("product:addSupplier")}
        children={
          <SupplierModal
            isSelectTab={isSelectTab}
            onClose={() => {
              setSupplierModal(false);
            }}
            refetch={refetch}
            selected={onEdit}
            groupsData={getGroups?.data?.data}
          />
        }
      />
      <AlertModal
        title={"Tedarikçi Sil"}
        subTitle={"Bu işlem geri alınamaz.Emin misiniz ?"}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        // applyButton={() => {
        //   onDeleted(deleteId);
        // }}
      />
    </>
  );
};

export default SupplierList;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import generateFileUrl from "../../../../utils/generateFileUrl";
import { DeleteIcon, DotsVerticalIcon, FlexProductIcon, PieceIcon } from "../../../../components/new-cards/card-icons";
import { Tooltip } from "react-tooltip";
import AlertModal from "../../../../components/alert-Modal/Alert-Modal";
import { junctionService } from "../../../../services/junction.service";
import { Toast } from "../../../../utils/toastify/toast";

const InternalPlanViewNextPlanCard = ({ setDraggedProduct, item, targetCount, handlePlanData, operationId, handleHeaderData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);

  const statusColor = () => {
    if (item?.flexProduct) {
      return "#E9D7FE";
    } else {
      switch (item?.status) {
        case "finished":
          return "#12B76A";
        case "started":
          return "#FDE172";
        case "planned":
          return "#FDE172";
        case "rejected":
          return "#D92D20";
        default:
          return "#F2F4F7";
      }
    }
  };
  const menuOptions = [{ label: t("buttons:delete"), value: "delete", icon: <DeleteIcon /> }];
  return (
    <>
      <div
        onClick={() => {
          if (item?.flexProduct) {
            navigate(`/app/product/mes/flex/operation/detail/${item?.flexProduct?.id}+${item?.flexHeader?.step}`);
          } else {
            navigate(`/app/product/mrp/work-in-process/detail/${item?.job?.id}+${item?.header?.step}`);
          }
        }}
        data-tooltip-id={`${item?.id}`}
        data-tooltip-content={`${t("product:productName")}: ${item?.job ? item?.job?.product?.name : item?.flexProduct?.name} - ${t("product:stockCode")}: ${item?.job ? item?.job?.product?.stockCode : item?.flexProduct?.stockCode
          }`}
        data-tooltip-place="right"
        key={item?.id}
        draggable
        onDragStart={() => setDraggedProduct(item)}
        className="flex bg-white flex-row  w-[335px] min-w-[335px] h-[91px]  min-h-[91px] relative group pl-2  pt-2  border border-[#D0D5DD] rounded-lg cursor-grab "
      >
        <div className="flex flex-col w-[88%]">
          <div className="flex flex-row items-center gap-x-3">
            <img
              onClick={() => {
                if (item?.flexProduct) {
                  navigate(`/app/product/mes/flex/operation/detail/${item?.flexProduct?.id}+${item?.flexHeader?.step}`);
                } else {
                  navigate(`/app/product/mrp/work-in-process/detail/${item?.job?.id}+${item?.header?.step}`);
                }
              }}
              className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-300 select-none"
              src={generateFileUrl(item?.job ? item?.job?.product?.image : item?.flexProduct?.image)}
            />
            <div className="flex flex-col items-start">
              <div className="flex flex-row items-center gap-x-1 w-full">
                {item?.flexProduct ? (
                  <>
                    <p className="font-semibold text-secondary-900 text-base w-[170px] truncate">
                      {" "}
                      {item?.internalOperation?.product?.name ? item?.internalOperation?.product?.name : item?.flexProduct?.name || "Product Name"}
                    </p>
                  </>
                ) : (
                  <p className="font-semibold text-secondary-900 text-base w-[170px] truncate"> {item?.job?.product?.name || "Product Name"}</p>
                )}
              </div>
              <div
                className={`h-6 max-w-[210px] flex  py-[3px] items-center justify-center bg-[${item?.flexProduct || item?.flexRawMaterial || item?.name ? "#F9F5FF" : "#FFFAEB"
                  }]`}
              >
                <p className={`text-sm  truncate  font-normal ${item?.flexProduct || item?.flexRawMaterial || item?.name ? "text-[#6941C6]" : "text-[#B54708]"}`}>
                  {item?.flexProduct || item?.flexRawMaterial || item?.name ? t("product:flex") : t("product:stockCode")} :{" "}
                  {item?.job ? item?.job?.product?.stockCode : item?.flexProduct?.stockCode || "--"}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-[6px]">
            <div className="flex flex-row items-center mt-2.5">
              <PieceIcon />
              <p className="text-xs font-medium ml-1 text-secondary-600">{item?.targetedWorkCount || "0"}</p>
              <p className="text-[10px] ml-0.5 font-normal text-secondary-500">{t("product:qty")}</p>
            </div>
            <div className="flex flex-row items-center mt-2.5">
              <CalendarIcon />
              <p className="text-xs font-medium ml-1 text-secondary-600">{(Number(item?.targetedWorkCount * item?.estimatedTime) / 86400).toFixed(1) || "0"}</p>
              <p className="text-[10px] ml-0.5 font-normal text-secondary-500">day estimated</p>
            </div>
          </div>
        </div>
        <div
          className={`flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 bg-[${item?.flexProduct || item?.flexRawMaterial || item?.name ? "#F9F5FF" : statusColor()
            }] border-secondary-300 h-[70px] min-h-[70px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px]`}
        >
          <p
            className={`text-[10px] font-medium ${item?.status === "started"
              ? "text-[#A15C07]"
              : item?.status === "rejected"
                ? "text-white"
                : item?.status === "finished"
                  ? "text-white"
                  : item?.flexProduct || item?.flexRawMaterial || item?.name
                    ? "text-[#6941C6]"
                    : "text-secondary-500"
              } -rotate-90`}
          >
            {item?.flexProduct || item?.flexRawMaterial || item?.name ? (item?.flexHeader?.step ? item?.flexHeader?.step + ".Flex" : "Flex") : item?.header?.step + ".Op"}
          </p>
          {item?.flexProduct || item?.flexRawMaterial || item?.name ? (
            <></>
          ) : (
            <span className="w-3 min-w-3 h-3 min-h-3">
              <ArrowIcon
                color={item?.status === "started" ? "#A15C07" : item?.status === "rejected" ? "#fff" : item?.status === "finished" ? "#fff" : "#475467"}
              />
            </span>
          )}
        </div>

        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(!showDropDown);
          }}
          className="flex absolute min-h-[24px] rotate-90 max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <DotsVerticalIcon />
        </div>
        {showDropDown && (
          <div
            className={`hidden flex-col absolute min-w-[200px] top-[16px] right-[3px] max-w-[200px] z-[999] h-fit bg-white border rounded-lg px-[6px] py-1 group-hover:flex`}
          >
            {menuOptions?.map((item, index) => {
              return (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (item.value === "delete") {
                      setIsAlert(true);
                    }
                    setShowDropDown(false);
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${item?.value === "delete" || item.value === "finishJunction" ? "hover:bg-error-50" : "hover:bg-secondary-100"
                    }`}
                >
                  {item?.icon}
                  <p
                    className={`font-medium text-sm  ${item?.value === "delete" || item.value === "finishJunction" ? "text-[#D92D20]" : "text-secondary-700"}`}
                  >
                    {item.label}
                  </p>
                </button>
              );
            })}
          </div>
        )}
      </div>
      {item?.id && <Tooltip className="z-[999]" id={`${item?.id}`} />}
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={async () => {
          await junctionService.deleteStationForJunction(item?.id).then((res) => {
            if (res.data.code == 0) {
              Toast("success", res.data.message);
              handlePlanData(operationId);
              handleHeaderData();
            } else {
              Toast("error", res.data.message);
            }
          });
        }}
      />
    </>
  );
};

export default InternalPlanViewNextPlanCard;

const CalendarIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
      <path
        d="M13 6.66666L1 6.66666M9.66667 1.33333V3.99999M4.33333 1.33333V3.99999M4.2 14.6667L9.8 14.6667C10.9201 14.6667 11.4802 14.6667 11.908 14.4487C12.2843 14.2569 12.5903 13.951 12.782 13.5746C13 13.1468 13 12.5868 13 11.4667V5.86666C13 4.74656 13 4.1865 12.782 3.75868C12.5903 3.38236 12.2843 3.0764 11.908 2.88465C11.4802 2.66666 10.9201 2.66666 9.8 2.66666L4.2 2.66666C3.0799 2.66666 2.51984 2.66666 2.09202 2.88465C1.71569 3.0764 1.40973 3.38236 1.21799 3.75868C1 4.1865 1 4.74656 1 5.86666L1 11.4667C1 12.5868 1 13.1468 1.21799 13.5746C1.40973 13.951 1.71569 14.2569 2.09202 14.4487C2.51984 14.6667 3.0799 14.6667 4.2 14.6667Z"
        stroke="#475467"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const PieceFlexIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clip-path="url(#clip0_17841_63067)">
        <path
          d="M5.49967 1.3335H2.99967C2.0792 1.3335 1.33301 2.07969 1.33301 3.00016V13.0002C1.33301 13.9206 2.0792 14.6668 2.99967 14.6668H12.9997C13.9201 14.6668 14.6663 13.9206 14.6663 13.0002V3.00016C14.6663 2.07969 13.9201 1.3335 12.9997 1.3335H10.4997M5.49967 1.3335V5.19064C5.49967 5.25535 5.56863 5.29672 5.62573 5.26627L7.99967 4.00016L10.3736 5.26627C10.4307 5.29672 10.4997 5.25535 10.4997 5.19064V1.3335M5.49967 1.3335H10.4997"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17841_63067">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const ArrowIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path d="M6 9.5L6 2.5M6 2.5L2.5 6M6 2.5L9.5 6" stroke={color} stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

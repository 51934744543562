import React, { useState, useEffect, useRef, act } from "react";
import useWindowDimensions from "../../hooks/useWindowDimension";
import moment from "moment";
import { timeFormatter } from "../../utils/timezoneFormatter";
import MultipleEventRect from "./components/MultipleEventRect";
import { SingleEventRect } from "./components/SingleEventRect";
import { formatSeconds, secondToHoursAndMinute2 } from "../../utils/secondToHoursAndMinute";
import ApprovedCard from "../junction/ApprovedCard";
import { authStore } from "../../stores/auth.store";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import LossesCardType from "../junction/LossesCardType";
import { NotFound404 } from "../../assets/illustrations";
import Button from "../buttons/Button";
import TimeRange from "react-video-timelines-slider";
import "./styles.css";
import { getStartOfDayForCompany } from "./TimelineSlider";
import EquipmentDetailTimelineDoneCard from "../new-cards/equipment-detail-card/EquipmentDetailTimelineDoneCard";
import EquipmentDetailTimelineLossCard from "../new-cards/loss-card/EquipmentDetailTimelineLossCard";
import EquipmentDetailTimelineActiveCard from "../new-cards/equipment-detail-card/EquipmentDetailTimelineActiveCard";
import { JunctionNumberIcon, JunctionNumberIconSelected } from "../new-cards/card-icons";
import EquipmentDetailTimelineTodayDoneCard from "../new-cards/equipment-detail-card/EquipmentDetailTimelineTodayDoneCard";
import TimeLineBarChart from "./components/TimeLineBarChart";

const EquipmentUpuTimeline = observer(
  ({
    startTime,
    endTime,
    refetch,
    //kontrols
    setStartTime,
    setEndTime,
    setSelectedJunction,
    selectedJunction,
    defaultStartTime,
    defaultEndTime,
    //grouped data
    groupedDatas,
    //junctionDatas
    timelineChart,
    junctionData,
    //last
    timelineValues,
    setTimelineValues,
    shiftValues,
    setShiftValues,
    PastJunctionData,
    date,
    //bpttom
    setIsOpen,
    setDefaultStartForm,
    setDefaultEndForm,
    barChartData,
  }) => {
    const { width, height } = useWindowDimensions();
    const _ = require("lodash");
    const { t } = useTranslation();
    const [emptyTimes, setEmptyTimes] = useState([]);
    const [barValues, setBarValues] = useState([]);
    const [selectedDayType, setSelectedDayType] = useState(1);
    const [selectedShift, setSelectedShift] = useState(null);
    const [showTooltip, setShowTooltip] = useState(null);
    const [mouseX, setMouseX] = useState(null);
    const [onlyJuntionValues, setOnlyJunctionValues] = useState([]);
    const totalWidth = width;

    const totalWidthh = totalWidth - 160;
    let svgWidth = window.innerWidth - 160;
    let svgHeight = 125;

    const handleMouseOver = (index, xPos, yPos, e) => {
      if (index.index == 0) {
        junctionData.filter((e) => {
          if (e.id == index.junctionId) {
            setShowTooltip({ index, xPos, yPos, junction: e });
          } else if (index?.lossesId) {
            setShowTooltip({ index, xPos, yPos });
          }
        });
      } else {
        setShowTooltip({ index, xPos, yPos });
      }
    };

    const handleMouseMove = (e) => {
      setMouseX(e.clientX);
    };

    const handleMouseOut = () => {
      if (showTooltip) {
        setShowTooltip(null);
      }
    };
    const handleShiftClickSecond = (index) => {
      if (index?.junctionId) {
        if (selectedJunction === index?.junctionId) {
          setSelectedJunction(!selectedJunction);
          setStartTime(defaultStartTime);
          setEndTime(defaultEndTime);
        } else {
          PastJunctionData(index);
          setSelectedJunction(index?.junctionId);
          setStartTime(new Date(timeFormatter(index?.startDate).formatted).valueOf());
          setEndTime(new Date(timeFormatter(index?.endDate).formatted).valueOf());
        }
      }
    };

    const handleShiftClick = (index) => {
      const startDate = new Date(timeFormatter(index.startDate)?.formatted).valueOf();
      const endDate = new Date(timeFormatter(index.endDate)?.formatted).valueOf();

      const shiftDuration = moment(endDate).diff(moment(startDate), "minutes");
      let adjustedStartDate = startDate;
      let adjustedEndDate = endDate;

      if (shiftDuration < 30) {
        adjustedStartDate = moment(startDate).subtract(30, "minutes").valueOf();
        adjustedEndDate = moment(startDate).add(30, "minutes").valueOf();
      }

      if (selectedJunction) {
        setStartTime(new Date(timeFormatter(selectedJunction?.startDate)?.formatted).valueOf());
        setEndTime(new Date(timeFormatter(selectedJunction?.endDate)?.formatted).valueOf());
      } else {
        setStartTime(adjustedStartDate);
        setEndTime(adjustedEndDate);
      }
    };

    const junctionNumber = (item) => {
      const junctions = junctionData?.filter((a) => a.status != "planned");

      let sortedJunctions = junctions?.sort((a, b) => moment(a?.startDate)?.unix() - moment(b?.startDate)?.unix());

      const datas = sortedJunctions?.map((value, index) => {
        if (value?.id == item) {
          return index + 1;
        } else {
          return "";
        }
      });

      const lastData = datas?.filter((a) => a != "");

      return lastData[0];
    };
    const [nodeId, setNodeId] = useState(null);
    const calculateData = async (data) => {
      const datas = [];
      data?.map((value, no) => {
        if (value?.nodeId) {
          setNodeId(value?.nodeId);
        }
        value?.value.map((da, i) => {
          datas.push({
            name: value.name,
            type: da?.index == 1 ? "status" : da.index == 2 ? "shift" : da.index == 0 ? "junction" : "",
            junctionId: da?.junctionId,
            lossesId: da?.lossesId,
            actionId: da?.actionId,
            productData: da?.productName,
            operationNo: da?.operationNo,
            lossesName: da?.lossesName,
            startDate: da?.start,
            endDate: da?.end,
            timeDiff: da?.timeDiff,
            index: da?.index,
            junctionType: da?.type,
            value: [
              da?.index,
              /// moment(da.start).unix(),
              // moment(da.end).unix(),
              moment(timeFormatter(da.start, authStore?.user?.company?.timeZone).formatted)?.unix(),
              moment(timeFormatter(da.end, authStore?.user?.company?.timeZone).formatted)?.unix(),
              da.timeDiff,
            ],
            shiftId: da?.shiftId,
            color:
              da?.index == 1
                ? value?.itemStyle?.normal?.color
                : da?.index == 2
                ? value?.itemStyle?.normal?.color
                : da?.index == 0
                ? da?.type == "setup"
                  ? "#EAAA08"
                  : da.type == "losses"
                  ? "#D92D20"
                  : "#12B76A"
                : value?.itemStyle?.normal?.color,
          });
        });
      });

      const Valuess = datas?.sort((a, b) => a.value[1] - b.value[1]);
      const lastValues = _.uniqWith(Valuess, _.isEqual);

      let timelineDatas = Valuess?.filter((item) => {
        return item?.index != 2;
      });

      let shiftValues = lastValues?.filter((item) => {
        return item?.index == 2;
      });

      let junctionValues = lastValues?.filter((item) => {
        return item?.index == 0;
      });

      let junctionValues2 = lastValues?.filter((item) => {
        return item?.index == 0 && item.junctionType != "subEvent";
      });

      const [hours, minutes, seconds] = authStore?.user?.company?.dayStartHour?.split(":").map(Number);

      const newDayStartMoment = moment().hours(hours).minutes(minutes).seconds(seconds).subtract(3, "hours");
      const newDayStartHour = newDayStartMoment.format("HH:mm:ss");

      const noShiftStartDate = moment(date).format("YYYY-MM-DD") + " " + newDayStartHour;
      const noShiftEndDate = moment(date).add(1, "day").format("YYYY-MM-DD") + " " + authStore.user.company.dayStartHour;

      const startDate = shiftValues[0]?.startDate === undefined ? noShiftStartDate : shiftValues[0]?.startDate;
      const endDate = shiftValues[shiftValues.length - 1]?.endDate === undefined ? noShiftEndDate : shiftValues[shiftValues.length - 1]?.endDate;

      const endDateJunction = timelineDatas[timelineDatas.length - 1]?.endDate;

      const emptyTimes = [];
      const startUnixTime = moment.utc(startDate).unix(); // UTC formatında start date

      const endUnixTime = moment(startDate).format("YYYY-MM-DD") != moment().format("YYYY-MM-DD") ? moment.utc(endDateJunction).unix() : moment.utc().unix(); // UTC formatında end date

      let prevEndTime = startUnixTime;

      junctionValues2.forEach((value) => {
        const startTime = value.value[1];
        const endTime = value.value[2];

        if (startTime > prevEndTime) {
          emptyTimes.push([prevEndTime, startTime]);
        }

        prevEndTime = endTime;
      });

      if (prevEndTime < endUnixTime) {
        emptyTimes.push([prevEndTime, endUnixTime]);
      }

      const sortedEmptyTimes = emptyTimes.sort((a, b) => a[0] - b[0]);

      const junctionData = sortedEmptyTimes.map(([start, end]) => ({
        actionId: undefined,
        color: "#12B7",
        endDate: moment.utc(new Date(end * 1000).toISOString()).format("YYYY-MM-DD HH:mm:ss.SSS"),
        startDate: moment.utc(new Date(start * 1000).toISOString()).format("YYYY-MM-DD HH:mm:ss.SSS"),
        index: 0,
        junctionId: start,
        junctionType: "empty",
        timeDiff: end - start,
        value: [0, start, end, end - start],
      }));

      const sorted = [...timelineDatas, ...junctionData];

      await setShiftValues(shiftValues);
      await setTimelineValues(sorted);
      await setOnlyJunctionValues(junctionValues2);
    };
    const calculateTotalTime = (data) => {
      const totalTimeMap = {};

      data.forEach((item) => {
        if (item?.index === 1) {
          const name = item?.name;
          const startTime = moment(item.startDate);
          const endTime = moment(item.endDate);
          const diffTime = endTime - startTime;
          const hours = diffTime / (1000 * 3600);
          const color = item?.color;

          // İlgili adın süresini ve rengini topla
          if (totalTimeMap[name]) {
            totalTimeMap[name].totalTime += hours;
          } else {
            totalTimeMap[name] = {
              totalTime: hours,
              color: color,
            };
          }
        }
      });

      const result = Object.keys(totalTimeMap).map((name) => ({
        name,
        totalTime: totalTimeMap[name].totalTime.toFixed(1) + " h",
        color: totalTimeMap[name].color,
      }));

      setBarValues(result);
    };

    let totalGreenWidth = 0;
    let totalRedWidth = 0;

    const groupWidths = {};
    let toplam = 0;

    useEffect(() => {
      calculateData(timelineChart);
    }, []);

    useEffect(() => {
      calculateTotalTime(timelineValues);
    }, [timelineValues]);

    const [invalidShiftDetected, setInvalidShiftDetected] = useState(false);
    useEffect(() => {
      const checkShifts = () => {
        const invalidShiftExists = shiftValues.some((shift) => {
          const shiftStartTime = new Date(timeFormatter(shift?.startDate)?.formatted)?.getTime();
          return isNaN(shiftStartTime) || shiftStartTime === undefined;
        });

        if (invalidShiftExists) {
          setInvalidShiftDetected(true);
        }
      };

      checkShifts();
    }, [shiftValues]);

    const path = window.location.pathname;

    return (
      <div
        className=" w-full flex flex-col  border border-[#D0D5DD] rounded-xl  pl-4  bg-white"
        style={{
          width: totalWidthh - 150,
        }}
      >
        {invalidShiftDetected ? (
          <div className="w-full h-full flex items-center justify-center min-h-[150px] flex-col py-4 gap-y-4">
            <NotFound404 width={150} height={60} />
            <p className="text-sm font-semibold">{t("signals:internetConnectionLost")}</p>
            <Button
              label={"Yeniden Dene"}
              size={"sm"}
              width={width / 7}
              colorType={"primary-machine"}
              onClick={() => {
                window.location.reload();
              }}
            />
          </div>
        ) : (
          <>
            <div className="w-full  rounded-t-lg  flex items-center  mt-2 mb-2 relative  ">
              <span className="isolate inline-flex rounded-lg">
                <button
                  onClick={() => {
                    setStartTime(defaultStartTime);
                    setEndTime(defaultEndTime);
                    setSelectedDayType(1);
                    setSelectedJunction(null);
                    refetch();
                  }}
                  type="button"
                  className="relative inline-flex items-center rounded-l-lg  px-4 py-2 text-sm font-semibold text-secondary-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                  style={{
                    backgroundColor: selectedDayType == 1 ? "#F9FAFB" : "white",
                  }}
                >
                  {t("gauge:allDay")}
                </button>
                {Object?.values(groupedDatas)
                  ?.sort((a, b) => moment(a?.start)?.unix() - moment(b?.start)?.unix())
                  ?.map((shift, index) => {
                    const dateToSend = shift?.end || new Date();
                    const modifiedShift = {
                      ...shift,
                      startDate: moment(shift?.start).utc().format("YYYY-MM-DD HH:mm:ss"),
                      endDate: moment(shift?.end).utc().format("YYYY-MM-DD HH:mm:ss"),
                      nodeId: nodeId,
                    };
                    return (
                      <button
                        onClick={() => {
                          setStartTime(new Date(timeFormatter(shift?.start)?.formatted).valueOf());
                          setEndTime(new Date(timeFormatter(shift?.end)?.formatted).valueOf());
                          setSelectedDayType(shift?.id);
                          PastJunctionData(modifiedShift, dateToSend);
                        }}
                        type="button"
                        className="relative -ml-px inline-flex items-center  px-4 py-2 text-sm font-semibold text-secondary-700 ring-1 ring-inset ring-gray-300  focus:z-10 last:rounded-r-md "
                        style={{
                          backgroundColor: selectedDayType == shift?.id ? "#F9FAFB" : "white",
                        }}
                      >
                        <span
                          className={`w-3 h-3 rounded-sm mr-2`}
                          style={{
                            backgroundColor: shift?.shift?.color,
                          }}
                        ></span>
                        {shift?.shift?.name}
                      </button>
                    );
                  })}
              </span>
            </div>
            <div className="w-full max-h-[40px] min-h-[40px] relative mt-2 pr-3">
              <TimeRange
                // error={error}
                ticksNumber={30}
                selectedInterval={startTime == endTime ? getStartOfDayForCompany(startTime, startTime + 3600000) : getStartOfDayForCompany(startTime, endTime)}
                timelineInterval={
                  defaultStartTime == defaultEndTime
                    ? getStartOfDayForCompany(defaultStartTime, defaultStartTime + 3600000)
                    : getStartOfDayForCompany(defaultStartTime, defaultEndTime)
                }
                onUpdateCallback={(interval) => {
                  "";
                }}
                onChangeCallback={(interval) => {
                  if (interval[0] == interval[1]) {
                    setStartTime(moment(interval[0]).valueOf());
                    setEndTime(moment(interval[0]).add(1, "hour").valueOf());
                  } else {
                    setStartTime(moment(interval[0]).valueOf());
                    setEndTime(moment(interval[1]).valueOf());
                  }
                }}
                step={1}
                disabledIntervals={[]}
              />
            </div>
            <div className="w-full flex flex-col  relative pr-3 mt-2">
              <svg width={totalWidth - 150} height={svgHeight}>
                <svg width={svgWidth} height="115" y={15}>
                  <g clip-path="url(#clip0_12199_84484)"></g>
                </svg>

                {shiftValues?.map((shift, index) => {
                  const { startDate, endDate, color, timeDiff } = shift;
                  const shiftStartTime = new Date(timeFormatter(shift?.startDate)?.formatted)?.getTime();
                  const shiftStartTimeDene = new Date(timeFormatter(shiftValues?.[0])?.formatted)?.getTime();

                  const shiftEndTime = new Date(timeFormatter(endDate)?.formatted)?.getTime();

                  const timeRange = endTime - startTime;

                  const shiftX = selectedShift === index ? 0 : ((shiftStartTime - startTime) / timeRange) * svgWidth;
                  const shiftWidth = selectedShift === index ? svgWidth : ((shiftEndTime - shiftStartTime) / timeRange) * svgWidth;
                  const modifiedShift = {
                    ...shift,
                    startDate: moment(shift?.startDate).add(3, "hour").format("YYYY-MM-DD HH:mm:ss"),
                    endDate: moment(shift?.endDate).add(3, "hour").format("YYYY-MM-DD HH:mm:ss"),
                    nodeId: nodeId,
                  };
                  const dateToSend = shift?.endDate || new Date();
                  return (
                    <>
                      <SingleEventRect
                        startPos={shiftX}
                        width={shiftWidth}
                        onClick={() => {
                          if (selectedJunction === modifiedShift?.shiftId) {
                            setSelectedJunction(!selectedJunction);
                            setStartTime(defaultStartTime);
                            setEndTime(defaultEndTime);
                            refetch();
                          } else {
                            PastJunctionData(modifiedShift, dateToSend);
                            handleShiftClick(shift);
                          }
                        }}
                        event={shift}
                      />
                      {shiftWidth > 15 ? (
                        <>
                          <defs>
                            <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
                              <path
                                d="M-1,1 l2,-2
               M0,4 l4,-4
               M3,5 l2,-2"
                                style={{ stroke: "#000000", strokeWidth: 1 }}
                              />
                            </pattern>
                          </defs>
                          <rect
                            key={index}
                            x={shiftX}
                            y={22}
                            width={shiftWidth}
                            height={svgHeight - 10}
                            fill={shift?.color === "#FFFFFF" ? "url(#diagonalHatch)" : shift?.color}
                            opacity={0.2}
                            onClick={() => handleShiftClick(shift)}
                            style={{ cursor: "pointer" }}
                            ry={"4"}
                          ></rect>
                        </>
                      ) : (
                        <>
                          <defs>
                            <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
                              <path
                                d="M-1,1 l2,-2
         M0,4 l4,-4
         M3,5 l2,-2"
                                style={{ stroke: "#000000", strokeWidth: 1 }}
                              />
                            </pattern>
                          </defs>
                          <rect
                            key={index}
                            x={shiftX}
                            y={22}
                            width={shiftWidth}
                            height={svgHeight - 10}
                            fill={shift?.color === "#FFFFFF" ? "url(#diagonalHatch)" : "#FFFFFF"}
                            opacity={0.2}
                            onClick={() => handleShiftClick(shift)}
                            style={{ cursor: "pointer" }}
                            ry={"4"}
                          ></rect>
                        </>
                      )}
                    </>
                  );
                })}

                {timelineValues?.map((shift, index) => {
                  const { startDate, endDate, color, timeDiff } = shift;
                  const shiftStartTime = new Date(timeFormatter(startDate)?.formatted)?.getTime();
                  const shiftEndTime = new Date(timeFormatter(endDate)?.formatted)?.getTime();
                  const timeRange = endTime - startTime;

                  if (selectedShift !== null && selectedShift !== index) {
                    return null;
                  }

                  const shiftX = selectedShift === index ? 0 : ((shiftStartTime - startTime) / timeRange) * svgWidth;
                  const statusWidth = ((shiftEndTime - shiftStartTime) / timeRange) * svgWidth;
                  let yOffset = 70;

                  let modifiedStartDate = shift.startDate;
                  let modifiedEndDate = shift.endDate;

                  const shiftDuration = moment(endDate).diff(moment(startDate), "minutes");
                  if (shiftDuration < 30) {
                    modifiedStartDate = moment(startDate).subtract(30, "minutes").format("YYYY-MM-DD HH:mm:ss");
                    modifiedEndDate = moment(startDate).add(30, "minutes").format("YYYY-MM-DD HH:mm:ss");
                  } else {
                    modifiedStartDate = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
                    modifiedEndDate = moment(endDate).format("YYYY-MM-DD HH:mm:ss");
                  }

                  const modifiedShift = {
                    ...shift,
                    startDate: modifiedStartDate,
                    endDate: modifiedEndDate,
                    nodeId: nodeId,
                  };

                  const dateToSend = shift?.endDate || new Date();

                  return (
                    <>
                      <MultipleEventRect
                        type={"equipment"}
                        key={index}
                        yOffset={yOffset}
                        startPos={shiftX}
                        width={statusWidth}
                        widthS={statusWidth}
                        modifiedShift={modifiedShift}
                        selectedJunction={selectedJunction}
                        path={path}
                        event={shift}
                        onClick={() => {
                          if (selectedJunction === modifiedShift?.junctionId) {
                            setSelectedJunction(!selectedJunction);
                            setStartTime(defaultStartTime);
                            setEndTime(defaultEndTime);
                            refetch();
                          } else {
                            PastJunctionData(modifiedShift, dateToSend);
                            handleShiftClick(shift);
                          }
                        }}
                        handleMouseOver={handleMouseOver}
                        handleMouseOut={handleMouseOut}
                        handleMouseMove={handleMouseMove}
                        junctionNumber={junctionNumber}
                        // selectedJunction={selectedJunction}
                        //bottom
                        setDefaultEndForm={setDefaultEndForm}
                        setDefaultStartForm={setDefaultStartForm}
                        setIsOpen={setIsOpen}
                        //edit event
                        defaultStartTime={moment(defaultStartTime).format("YYYY-MM-DD HH:mm:ss")}
                        defaultEndTime={moment(defaultEndTime).format("YYYY-MM-DD HH:mm:ss")}
                        onlyJuntionValues={onlyJuntionValues}
                      />
                    </>
                  );
                })}
              </svg>
              {/* <TimeRangeSlider 
              initialMin={startTime}
              initialMax={endTime}
              /> */}
              {showTooltip && showTooltip?.index?.index == 0 && showTooltip?.index?.junctionId ? (
                <div
                  style={{
                    right: totalWidthh - mouseX < 600 ? "68%" : "4%",
                    top: showTooltip.yPos - 90,
                  }}
                  className={`absolute z-50 bg-white p-2  rounded-lg shadow-md `}
                >
                  <div className="w-[470px] min-w-[470px]">
                    {moment(date).format("DD-MM-YYYY") == moment(new Date())?.format("DD-MM-YYYY") ? (
                      <>
                        {showTooltip?.junction?.status === "completed" ? (
                          <>
                            {showTooltip?.junction?.station ? (
                              <div
                                className={`flex flex-col mr-4 cursor-pointer min-w-[467px] max-w-[467px] hover:bg-secondary-50 items-start pt-2 px-3 pb-3 w-full  ${
                                  selectedJunction === showTooltip?.junction?.id
                                    ? "border-[#7F56D9] border-[1.5px]"
                                    : "border-[#E4E7EC] hover:border-secondary-300 border"
                                }  rounded-lg `}
                              >
                                <div className="min-w-[440px] max-w-[440px] relative cursor-pointer ">
                                  <div className="flex flex-col gap-y-8 mt-2">
                                    <EquipmentDetailTimelineTodayDoneCard data={showTooltip} />
                                  </div>
                                </div>
                                <div className="flex flex-row min-w-[440px] max-w-[440px] items-center gap-x-3 w-full mt-3">
                                  {selectedJunction === showTooltip?.junction?.id ? (
                                    <div className="flex items-center justify-between w-full">
                                      <div className="flex flex-row items-center gap-x-1">
                                        <JunctionNumberIconSelected color={"#6941C6"} />
                                        <p className="text-md font-semibold text-[#6941C6]">{junctionNumber(showTooltip?.junction?.id)}</p>
                                      </div>
                                      {/* <p className="text-md font-semibold text-[#6941C6]">{t("global:selected")}</p> */}
                                    </div>
                                  ) : (
                                    <div className="flex flex-row items-center gap-x-1">
                                      <JunctionNumberIcon color={"#667085"} />
                                      <p className="text-md font-semibold text-secondary-600">{junctionNumber(showTooltip?.junction?.id)}</p>
                                    </div>
                                  )}
                                  <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
                                    <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                                      <p className="text-xs font-normal text-secondary-600">{"upu.point"}</p>
                                    </div>
                                    <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                                      <p className={`text-base w-[100px] text-center truncate font-semibold text-[#9B7D07]`}>
                                        {showTooltip?.junction?.metadata?.upuPoint?.toFixed(2) || "0"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
                                    <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                                      <p className="text-xs  font-normal text-secondary-600">{t("reports:performance")}</p>
                                    </div>
                                    <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                                      <p className={`text-base w-[100px] truncate text-center font-semibold text-[#079455]`}>
                                        {/* %{(showTooltip?.junction?.metadata?.performancePercent * 100)?.toFixed(0) || "0"} */}%
                                        {showTooltip?.junction?.metadata?.performancePercent > 1
                                          ? 1 * 100
                                          : (showTooltip?.junction?.metadata?.performancePercent * 100)?.toFixed(0) || "0"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
                                    <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                                      <p className="text-xs font-normal text-secondary-600">{t("product:qualityControlRate")}</p>
                                    </div>
                                    <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                                      <p className={`text-base w-[100px] truncate text-center font-semibold text-secondary-600`}>
                                        {/* {showTooltip?.junction?.metadata?.qualityPercent || "0"} */}%
                                        {showTooltip?.junction?.metadata?.qualityPercent > 1
                                          ? 1 * 100
                                          : (showTooltip?.junction?.metadata?.qualityPercent * 100)?.toFixed(2) || "0"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <div className="min-w-[465px] max-w-[465px] flex gap-y-2 flex-col  relative cursor-pointer ">
                            <div className="flex flex-col gap-y-8 mt-2">
                              <EquipmentDetailTimelineActiveCard data={showTooltip} />
                            </div>
                            {selectedJunction === showTooltip?.junction?.id ? (
                              <div className="flex items-center justify-between w-full">
                                <div className="flex flex-row items-center gap-x-1">
                                  <JunctionNumberIconSelected color={"#6941C6"} />
                                  <p className="text-md font-semibold text-[#6941C6]">{junctionNumber(showTooltip?.junction?.id)}</p>
                                  <p className="font-medium text-sm text-[#6941C6]">{t("product:activeEvents")}</p>
                                </div>
                                <p className="text-md font-semibold text-[#6941C6]">{t("global:selected")}</p>
                              </div>
                            ) : (
                              <div className="flex flex-row items-center gap-x-1">
                                <JunctionNumberIcon color={"#667085"} />
                                <p className="text-md font-semibold text-secondary-600">{junctionNumber(showTooltip?.junction?.id)}</p>
                                <p className="font-medium text-sm text-secondary-600">{t("product:activeEvents")}</p>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {showTooltip?.junction?.status === "completed" && (
                          <div
                            className={`flex flex-col mr-4 cursor-pointer min-w-[467px] max-w-[467px] hover:bg-secondary-50 items-start pt-2 px-3 pb-3 w-full  ${
                              selectedJunction === showTooltip?.junction?.id
                                ? "border-[#7F56D9] border-[1.5px]"
                                : "border-[#E4E7EC] hover:border-secondary-300 border"
                            }  rounded-lg `}
                          >
                            <div className="min-w-[440px] max-w-[440px] relative cursor-pointer ">
                              <div className="flex flex-col gap-y-8 mt-2">
                                <EquipmentDetailTimelineDoneCard data={showTooltip} />
                              </div>
                            </div>
                            <div className="flex flex-row min-w-[440px] max-w-[440px] items-center gap-x-3 w-full mt-3">
                              {selectedJunction === showTooltip?.junction?.id ? (
                                <div className="flex items-center justify-between w-full">
                                  <div className="flex flex-row items-center gap-x-1">
                                    <JunctionNumberIconSelected color={"#6941C6"} />
                                    <p className="text-md font-semibold text-[#6941C6]">{junctionNumber(showTooltip?.junction?.id)}</p>
                                  </div>
                                  {/* <p className="text-md font-semibold text-[#6941C6]">{t("global:selected")}</p> */}
                                </div>
                              ) : (
                                <div className="flex flex-row items-center gap-x-1">
                                  <JunctionNumberIcon color={"#667085"} />
                                  <p className="text-md font-semibold text-secondary-600">{junctionNumber(showTooltip?.junction?.id)}</p>
                                </div>
                              )}
                              <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
                                <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                                  <p className="text-xs font-normal text-secondary-600">{"upu.point"}</p>
                                </div>
                                <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                                  <p className={`text-base w-[100px] text-center truncate font-semibold text-[#9B7D07]`}>
                                    {showTooltip?.junction?.metadata?.upuPoint?.toFixed(2) || "0"}
                                  </p>
                                </div>
                              </div>
                              <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
                                <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                                  <p className="text-xs  font-normal text-secondary-600">{t("reports:performance")}</p>
                                </div>
                                <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                                  <p className={`text-base w-[100px] truncate text-center font-semibold text-[#079455]`}>
                                    %
                                    {showTooltip?.junction?.metadata?.performancePercent > 1
                                      ? 1 * 100
                                      : (showTooltip?.junction?.metadata?.performancePercent * 100)?.toFixed(0) || "0"}
                                  </p>
                                </div>
                              </div>
                              <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
                                <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                                  <p className="text-xs font-normal text-secondary-600">{t("product:qualityControlRate")}</p>
                                </div>
                                <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                                  <p className={`text-base w-[100px] truncate text-center font-semibold text-secondary-600`}>
                                    %
                                    {showTooltip?.junction?.metadata?.qualityPercent > 1
                                      ? 1 * 100
                                      : (showTooltip?.junction?.metadata?.qualityPercent * 100)?.toFixed(0) || "0"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ) : showTooltip && showTooltip?.index?.lossesId ? (
                <div
                  style={{
                    right: totalWidthh - mouseX < 600 ? "68%" : "4%",
                    top: showTooltip.yPos - 90,
                  }}
                  className={`absolute z-50 bg-white p-2  rounded-lg shadow-md  `}
                >
                  {/* <LossesCardType
                    title={showTooltip?.index?.lossesName || "Undefined Loss"}
                    subTitle={showTooltip?.index?.reasonDescription || "Unkown"}
                    startTime={moment(timeFormatter(showTooltip?.index?.startDate).formatted).format("HH:mm")}
                    endTime={moment(timeFormatter(showTooltip?.index?.endDate).formatted).format("HH:mm")}
                    id={showTooltip?.index?.id}
                  /> */}
                  <EquipmentDetailTimelineLossCard data={showTooltip} />
                </div>
              ) : showTooltip && showTooltip?.index?.index == 1 ? (
                <div
                  className={`absolute z-50 bg-white p-2  rounded-lg shadow-md`}
                  style={{
                    left: (showTooltip?.xPos + 30) * 2 > totalWidthh ? showTooltip?.xPos - 280 : showTooltip?.xPos + 40,
                    top: showTooltip?.yPos - 140,
                    borderTop: "5px solid " + showTooltip?.index?.color,
                  }}
                >
                  <div className="flex items-center mb-1 ">
                    <div
                      style={{
                        width: "12px",
                        height: "12px",
                        backgroundColor: showTooltip?.index?.color,
                        borderRadius: 2,
                      }}
                    ></div>

                    <p
                      className="text-md font-semibold"
                      style={{
                        color: showTooltip?.index?.color === "#FFFFFF" ? "black" : showTooltip?.index?.color,
                        marginLeft: "6px",
                      }}
                    >
                      {showTooltip?.index?.name}
                    </p>
                  </div>
                  <div className="flex flex-col w-full items-center justify-center">
                    <div className="flex w-full border-b border-[#EAECF0]">
                      <div className="flex min-w-[110px] max-w-[110px] border-r border-[#EAECF0] text-[#475467] text-sm font-normal py-1 pr-2">
                        Start-End Time
                      </div>
                      <div className="flex min-w-[142px] max-w-[172px] gap-x-1 text-[#475467] font-medium text-sm py-1 pl-2">
                        <span>{moment(timeFormatter(showTooltip?.index?.startDate).formatted).format("HH:mm:ss")}</span>-
                        <span>{moment(timeFormatter(showTooltip?.index?.endDate).formatted).format("HH:mm:ss")}</span>
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="flex min-w-[110px] max-w-[110px] border-r border-[#EAECF0] text-[#475467] text-sm font-normal py-1 pr-2">Total Time</div>
                      <div className="flex min-w-[142px] max-w-[172px] gap-x-1 text-[#475467] font-medium text-sm py-1 pl-2">
                        <span>
                          {formatSeconds(
                            moment(timeFormatter(showTooltip?.index?.endDate).formatted).diff(
                              moment(timeFormatter(showTooltip?.index?.startDate).formatted),
                              "seconds"
                            )
                          )}
                        </span>
                        -
                        <span>
                          {moment(timeFormatter(showTooltip?.index?.endDate).formatted).diff(
                            moment(timeFormatter(showTooltip?.index?.startDate).formatted),
                            "seconds"
                          ) +
                            " " +
                            t("product:second")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <TimeLineBarChart
              barChartData={barChartData}
              totalWidth={totalWidth}
              timelineValues={timelineValues}
              startTime={startTime}
              endTime={endTime}
              shiftlyData={groupedDatas}
            />
          </>
        )}
      </div>
    );
  }
);

export default EquipmentUpuTimeline;

import React, { useState, useEffect } from "react";
import { Button, CustomModal } from "../../components";
import { ArrowLeftIcon, MoveIcon, PlusIconNew } from "../../assets/icons/productIcons";
import { useTranslation } from "react-i18next";
import NewPlannedJunctionModal from "./modals/NewPlannedJunctionModal";
import FinishOperationSelectNode from "../../components/modal/FinishOperationSelectNode";
import NewPlannedJunctionExternalModal from "./modals/NewPlannedJunctionExternalModal";
import { Toast } from "../../utils/toastify/toast";
import FinishOperationDropDown from "./components/FinishOperationDropDown";
import AddJunctionManual from "../../components/modal/AddJunctionManual";

const ProductOperationDetailHeader = ({
  navigate,
  refetch,
  operation,
  headerId,
  currentOperation,
  operationId,
  disabledMove,
  isLoading,
  isFetching,
  stepNo 
}) => {
  const [newPlannedJunctionModal, setNewPlannedJunctionModal] = useState(false);
  const [newPlannedJunctionExternalModal, setNewPlannedJunctionExternalModal] = useState(false);
  const [isOpenFinishOperation, setIsOpenFinishOperation] = useState(false);
  const [isOpenJunction, setIsOpenJunction] = useState(false);
  const [windowSize, setWindowSize] = useState(window?.innerWidth);
  const [status, setStatus] = useState("");
  const { t } = useTranslation();
  const operationData = {
    product: {
      id: currentOperation?.operationId ? currentOperation?.operationId : operation?.flexProductId,
      name: operation?.name,
      image: operation?.image,
    },
    orderNo: operation?.orderNo,
    orderId: operation?.orderId,
  };

  //
  useEffect(() => {
    setStatus(operation?.operation?.status);
  }, [status]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window?.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div>
        <div className="w-full h-[78px] border-b flex items-center sticky top-0 z-50 bg-white ">
          <Button size={"md"} iconLeft={<ArrowLeftIcon />} colorType={"tertiary-gray"} onClick={() => navigate(-1)} />
          <div className="flex flex-row items-center ml-3">
            <h1 className="text-xl font-semibold text-gray-900">{t("product:operationDetail")}</h1>
            <p className="ml-2 text-xl font-normal text-[#475467]">{operation?.name}</p>
          </div>
          <div className="flex items-center ml-auto gap-3 mr-1">
            <FinishOperationDropDown
              setIsOpenFinishOperation={setIsOpenFinishOperation}
              setIsOpenJunction={setIsOpenJunction}
              //
              disabled={status != "finished" ? false : true}
            />
            <Button
              colorType={"secondary-product"}
              iconLeft={<MoveIcon />}
              label={t("product:moveJunction")}
              size={"md"}
              disabled={disabledMove}
              onClick={() => {
                navigate(`/app/product/mrp/work-in-process/detail/${currentOperation?.operationId}+${operationId?.split('+')[0]}/move-junction`);
              }}
            />
            <Button
              colorType={"primary-product"}
              size={"md"}
              label={t("product:planWork")}
              iconLeft={<PlusIconNew color={"#FFFFFF"} />}
              onClick={() => {
                //
                if (operation?.operation?.status == "finished") {
                  Toast("error", t("product:noPlannedJunction"));
                } else {
                  if (currentOperation?.type == "external") {
                    setNewPlannedJunctionExternalModal(true);
                  } else {
                    setNewPlannedJunctionModal(true);
                  }
                }
              }}
            />
          </div>
        </div>
        <CustomModal
          isOpen={isOpenFinishOperation}
          setIsOpen={setIsOpenFinishOperation}
          onClose={() => {
            setIsOpenFinishOperation(false);
          }}
          modalTitle={t("product:finishThisOperation")}
          width="fit-content"
          children={
            <FinishOperationSelectNode
              refetch={refetch}
              orderData={operationData}
              isLoading={isLoading}
              isFetching={isFetching}
              currentOperation={currentOperation}
              processId={currentOperation?.processId}
              onClose={() => {
                setIsOpenFinishOperation(false);
              }}
              operationData={operationData}
              stepNo={stepNo}
            />
          }
        />
        <CustomModal
          isOpen={isOpenJunction}
          setIsOpen={setIsOpenJunction}
          onClose={() => {
            setIsOpenJunction(false);
          }}
          modalTitle={t("product:junctionManually")}
          width="fit-content"
          children={
            <AddJunctionManual
              refetch={refetch}
              productType={'standart'}
              operation={{ ...operation, processId: currentOperation?.processId }}
              orderData={{ product: { id: operation?.orderId }, stock: operation?.stockCode }}
              operations={[
                {
                  value: currentOperation?.processId,
                  label: currentOperation?.operationName,
                  type: currentOperation?.type,
                  headerId: currentOperation?.operationId,
                },
              ]}
              setIsOpenJunction={setIsOpenJunction}
            />
          }
        />
        <CustomModal
          modalTitle={t("product:createNewWork")}
          setIsOpen={setNewPlannedJunctionModal}
          isOpen={newPlannedJunctionModal}
          children={
            <NewPlannedJunctionModal
              operationData={operationData}
              headerId={headerId}
              getJunctions={refetch}
              closeModal={() => setNewPlannedJunctionModal(false)}
              processId={currentOperation?.processId}
            />
          }
        />
        <CustomModal
          modalTitle={t("product:createNewWork")}
          setIsOpen={setNewPlannedJunctionExternalModal}
          isOpen={newPlannedJunctionExternalModal}
          children={
            <NewPlannedJunctionExternalModal
              operationData={operationData}
              headerId={headerId}
              getJunctions={refetch}
              closeModal={() => setNewPlannedJunctionExternalModal(false)}
              processId={currentOperation?.processId}
            />
          }
        />
      </div>
    </>
  );
};

export default ProductOperationDetailHeader;

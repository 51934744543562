import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ArrowLeftIcon, FilterIcon, MinusIcon, PlusIcon } from "../../assets/icons/productIcons";
import { Button, CustomModal, Input, Loading } from "../../components";
import Selector from "../../components/inputs/Selector";
import Badgets from "../../components/buttons/Badgets";

import SearchInput from "../../components/inputs/SearchInput";
import generateFileUrl from "../../utils/generateFileUrl";
import moment from "moment";
import LegacyAddProduct from "./modals/LegacyAddProduct";
import { customerService } from "../../services/customer.service";
import { useQuery } from "@tanstack/react-query";
import { productService } from "../../services/product.service";
import { Toast } from "../../utils/toastify/toast";
import diacritics from "diacritics";
import StockDetailRecipeProductCard from "./cards/StockDetailRecipeProductCard";
import OrderWipProductCard from "./cards/OrderWipProductCard";
import CreateOrderPageProductCard from "./cards/CreateOrderPageProductCard";

const NewFlexOrder = ({ }) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [isAddedProducts, setIsAddedProducts] = useState([]);
  const [isOpenNewPorductModal, setIsOpenNewPorductModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [customer, setCustomer] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [orderCode, setOrderCode] = useState("");
  const [customerOrderNo, setCustomerOrderNo] = useState("");
  const [buttonLoading,setButtonLoading] = useState(false)


  const [selectedRadio, setSelectedRadio] = useState('stock')


  const customerList = useQuery({
    queryKey: ["customer-list"],
    queryFn: async () => await customerService.getCustomerList(),
    retry: 0,
  });

  const productList = useQuery({
    queryKey: ["list-product"],
    queryFn: async () => {
      return await productService.getAllListProduct();
    },
    retry: 0,
  });

  const handleRemoveProduct = (index) => {
    setIsAddedProducts((prevProducts) => prevProducts.filter((_, i) => i !== index));
  };

  const totalOrderAmount = isAddedProducts?.reduce((acc, item) => acc + (item.orderedQuantity || 0), 0);
  const totalCost = isAddedProducts?.reduce((acc, item) => acc + item.productPrice * (item.orderedQuantity || 0), 0);

  const onSubmit = () => {
    if (buttonLoading) return; 
    setButtonLoading(true);
    if (!customer) {
      Toast("error", "Müşteri seçiniz");
      return;
    }
    if (!orderCode) {
      Toast("error", "Sipariş kodu giriniz");
      return;
    }
    if (!customerOrderNo) {
      Toast("error", "Müşteri sipariş kodu giriniz");
      return;
    }
    if (isAddedProducts.length === 0) {
      Toast("error", "Lütfen en az bir ürün ekleyiniz");
      return;
    }
    const sendData = { customer: customer, orderCode: orderCode, selectedProducts: isAddedProducts, customerOrderNo: customerOrderNo };
    sessionStorage.setItem("firstPageData", JSON.stringify(sendData));
    navigate("/app/product/mrp/orders/new-order/second-page");
    setButtonLoading(false)
  };

  useEffect(() => {
    if (sessionStorage.getItem("firstPageData")) {
      const data = JSON.parse(sessionStorage.getItem("firstPageData"));
      setCustomer(data?.customer);
      setOrderCode(data?.orderCode);
      setCustomerOrderNo(data?.customerOrderNo);
      setIsAddedProducts(data?.selectedProducts);
    }
  }, [sessionStorage.getItem("firstPageData")]);

  const FilteredProducts = productList?.data?.data?.filter(
    (item) =>
      diacritics.remove(`${item?.stockCode}`).toLowerCase().includes(diacritics.remove(searchQuery.toLowerCase())) ||
      diacritics.remove(`${item?.name}`).toLowerCase().includes(diacritics.remove(searchQuery).toLowerCase())
  );

  const tableHeadItems = [
    t("product:productsAndMaterialss"),
    t("product:orderQuantityss"),
    t("product:delivery"),
    t("product:unitSale"),
    t("product:totalSales"),
    t("product:remove"),
  ];

  const currencyIcon = (currency) => {
    switch (currency || "TRY") {
      case "USD":
        return "$";
      case "EUR":
        return "€";
      case "GBP":
        return "£";
      case "RUB":
        return "₽";
      case "UAH":
        return "₴";
      case "TRY":
        return "₺";
      default:
        return "₺";
    }
  };

  return (
    <>
      <div className="flex flex-col w-full gap-y-8 h-[95vh]">
        {customerList?.isLoading || customerList?.isFetching ? (
          <div className="bg-gray-200 animate-pulse rounded-lg w-full min-h-[56px]" />
        ) : (
          <div className="flex min-h-[56px] max-h-[56px] h-[56px] w-full items-center justify-start gap-x-2 sticky top-0 z-[50] bg-white">
            <Button size={"md"} iconLeft={<ArrowLeftIcon />} colorType={"tertiary-gray"} onClick={() => navigate(-1)} type={"button"} />
            <p className="text-[#101828] font-semibold text-2xl">{t("product:createNewOrder")}</p>
          </div>
        )}

        <div className="flex flex-col gap-y-6 w-full h-full">
          {customerList?.isLoading || customerList?.isFetching ? (
            <div className="bg-gray-200 animate-pulse rounded-lg w-full  min-h-[72px]" />
          ) : (
            <div className="flex w-full min-h-[72px] max-h-[72px] h-[72px] pb-5 justify-between border-b border-[#E4E7EC]">
              <div className="flex flex-col gap-y-1">
                <p className="text-[#101828] font-semibold text-lg">{t("product:orderProducts")}</p>
                <p className="text-[#344054] font-normal text-sm">{t("product:productRelated")}</p>
              </div>
              <div className="flex gap-x-3 max-h-[40px] min-h-[40px] h-10">
                <span className="flex min-w-[144px] max-w-[144px] w-[144px]">
                  <Button
                    size={"md"}
                    colorType={"secondary-gray"}
                    label={t("buttons:stop")}
                    onClick={() => {
                      sessionStorage.removeItem("firstPageData");
                      sessionStorage.removeItem("secondPageData");
                      navigate("/app/product/mrp/orders");
                    }}
                  />
                </span>
                <span className="flex min-w-[144px] max-w-[144px] w-[144px]">
                  <Button disabled={buttonLoading ? true : false}  size={"md"} colorType={"primary-product"} label={buttonLoading ? <Loading color={"#fff"} secondary={"#fff"} size={22} /> : t("buttons:save")} onClick={onSubmit} />
                </span>
              </div>
            </div>
          )}
          {customerList?.isLoading || customerList?.isFetching ? (
            <div className="bg-gray-200 animate-pulse rounded-lg w-full  min-h-[86px]" />
          ) : (
            <div className="flex flex-col w-full min-h-[86px] max-h-[86px] ">
            
            <div className="flex items-center gap-x-2 mb-6">
                <div className="flex items-center gap-x-2"
                onClick={()=>{setSelectedRadio('stock')}}
                >
                  <div className={`w-5 h-5 items-center flex justify-center rounded-full  border-[#D0D5DD] border-[1px]  ${selectedRadio == 'stock' ? 'bg-[#DC6803]' : 'bg-white'}`}>
                    {selectedRadio == 'stock' && <div className="w-2  h-2 -mt-0.5 rounded-full bg-white"></div>}
                  </div>
                  <p className="text-sm text-secondary-700 font-medium"> Siparişe Üretim Yap</p>
                </div>
                <div className="flex items-center gap-x-2"
                onClick={()=>{setSelectedRadio('order')}}
                >
                  <div className={`w-5 h-5 items-center flex justify-center rounded-full  border-[#D0D5DD] border-[1px]  ${selectedRadio == 'order' ? 'bg-[#DC6803]' : 'bg-white'}`}>
                    {selectedRadio == 'order' && <div className="w-2  h-2 -mt-0.5 rounded-full bg-white"></div>}
                  </div>
                  <p className="text-sm text-secondary-700 font-medium"> Siparişe Üretim Yap</p>
                </div>
                </div>

              <div className="flex w-full min-h-[86px] max-h-[86px] pb-6 gap-x-6 ">
              <span className="flex w-[33%]">
                <Input
                  label={t("product:orderCode")}
                  theme={"product"}
                  onChange={(e) => {
                    setOrderCode(e.target.value);
                  }}
                  value={orderCode}
                />
              </span>
                <span className="flex w-[33%]">
                <Selector
                  label={t("product:customer")}
                  items={customerList?.data?.data?.map((item) => {
                    return { label: item?.name, value: item?.id };
                  })}
                  theme={"product"}
                  placeholder={t("product:selectCustomer")}
                  onChange={(e) => {
                    setCustomer(customerList?.data?.data?.find((item) => item?.id === e));
                  }}
                  value={customer?.id}
                />
              </span>
              <span className="flex w-[33%]">
                <Input
                  label={t("product:customerOrderCode")}
                  theme={"product"}
                  onChange={(e) => {
                    setCustomerOrderNo(e.target.value);
                  }}
                  value={customerOrderNo}
                />
              </span>
            </div>
            </div>
          )}

          {productList?.isLoading || productList?.isFetching ? (
            <div className="bg-gray-200 animate-pulse rounded-lg w-full  min-h-[61vh]" />
          ) : (
            <div className="flex w-full h-full border  border-[#E4E7EC] rounded-t-xl mt-6  ">
              <div className="flex flex-col h-full w-[420px] min-w-[420px] max-w-[420px] border-r border-[#EAECF0] gap-y-5">
                <div className="flex min-h-[40px] max-h-[40px] h-[40px] bg-[#F9FAFB] rounded-tl-xl border-b border-[#EAECF0] py-2 px-6 gap-x-2 items-center">
                  <p className="text-[#101828] font-semibold text-sm">{t("product:allProduct")}</p>
                  <Badgets colorType={"outline-warning"} size={"sm"} label={productList?.data?.data?.length || 0} />
                </div>
                <div className="flex gap-x-3 px-6 w-full min-h-[40px] max-h-[40px] h-[40px]">
                  <SearchInput setSearchVal={setSearchQuery} theme={"product"} />
                  <Button iconLeft={<FilterIcon />} colorType={"secondary-gray"} size={"md"} />
                </div>
                <div className="flex flex-col  w-full h-full overflow-y-auto overflow-x-hidden scrollbar-hide ">
                  {FilteredProducts?.map((item, index) => {
                    return (
                     
                     <div className="flex items-center justify-between even:bg-secondary-100   shadow-xs pr-6  ">
                     <div key={index} className="flex justify-center items-center w-full min-h-[136px] max-h-[136px] h-[136px]  ">
                        {/* <div className="flex w-full flex-col gap-y-1">
                          <div className="flex w-full gap-x-3">
                            <img src={generateFileUrl(item?.image)} className="min-w-[64px] w-[64px] h-[48px] rounded border border-[#0000001A]" />
                            <div className="flex flex-col justify-between ">
                              <p className="text-[#101828] font-semibold text-md truncate w-[250px]">{item?.name}</p>
                              <p className="text-[#475467] font-normal text-sm">
                                {t("product:stock")}: {item?.stockCode}
                              </p>
                            </div>
                          </div>
                          <div className="flex py-[2px] pr-[6px] gap-x-[2px] items-center">
                            <div className="flex gap-x-1 items-center">
                              <QuantityIcon />
                              <p className="text-[#475467] font-medium text-xs">{item?.qualityQuantity || 0}</p>
                            </div>
                            <p className="text-[#667085] text-xxs font-normal">{t("product:totalStock")}</p>
                          </div>
                        </div> */}
                        
                        {console.log('item' , item)}

                        <CreateOrderPageProductCard  item={item}/>
                        
                      </div>
                      <div className="flex items-center justify-center min-w-[36px] max-w-[36px] w-[36px]">
                      <Button
                        iconLeft={<PlusIcon />}
                        colorType={"secondary-gray"}
                        size={"sm"}
                        onClick={() => {
                          setSelectedProduct(item);
                          const isProductAdded = isAddedProducts.some((product) => product?.id === item?.id);

                          if (isProductAdded) {
                            Toast("error", "Ürün zaten ekli");
                          } else {
                            setIsOpenNewPorductModal(true);
                          }
                        }}
                      />
                    </div>
                    </div>
                    );
                  })}
                </div>
              </div>
              <div className="flex flex-col min-h-full w-[80%] gap-y-5 relative">
                <div className="flex min-h-[40px] max-h-[40px] h-[40px] bg-[#F9FAFB] rounded-tl-xl border-b border-[#EAECF0] py-2 px-6 gap-x-2 items-center">
                  <p className="text-[#101828] font-semibold text-sm">{t("product:addedProducts")}</p>
                  <Badgets colorType={"outline-warning"} size={"sm"} label={isAddedProducts?.length} />
                </div>
                <div className="flex flex-col w-full h-[73%] px-6 overflow-y-auto overflow-x-hidden scrollbar-hide pb-6 ">
                  {/* table header */}
                  <div className="flex max-h-[26px] min-h-[26px] h-[26px] w-full sticky top-0 bg-white">
                    {tableHeadItems.map((item, i) => (
                      <div
                        key={i}
                        className={`flex border-b border-r border-[#E4E7EC] items-center  px-3 pb-2 w-[${i === 0 ? "30%" : i === 1 || i === 2 ? "15%" : i === 3 ? "13%" : i === 4 ? "18%" : i === 5 ? "9%" : ""
                          }] ${i === 5 ? "border-r-0" : ""} ${i === 0 ? "justify-start" : "justify-center"}`}
                      >
                        <span className="text-center text-xs font-medium cursor-pointer whitespace-nowrap">{item}</span>
                      </div>
                    ))}
                  </div>
                  {isAddedProducts?.map((item, index) => {
                    return (
                      <div className="flex w-full items-center border-b h-[80px] min-h-[80px]">
                        {/* tablo 1.kolon */}
                        <div className="flex gap-x-3 h-full items-center justify-start w-[30%] py-4 pr-4 border-r border-[#E4E7EC]">
                          <img className="w-[64px] h-[48px] rounded border border-[#0000001A]" src={generateFileUrl(item?.image)} />
                          <div className="flex flex-col gap-y-1 justify-center">
                            <p className="text-[#101828] font-semibold text-md">{item?.name}</p>
                            <p className="text-[#475467] text-sm">
                              {t("product:stock")}: {item?.stockCode}
                            </p>
                          </div>
                        </div>
                        {/* tablo 2.kolon */}
                        <div className="flex flex-col h-full py-3  items-center justify-center w-[15%] border-r border-[#E4E7EC]">
                          <p className="text-[#344054] font-medium text-sm">{item?.orderedQuantity}</p>
                          <p className="text-[#667085] text-xs">{t("product:pieces")}</p>
                        </div>
                        {/* tablo 3.kolon */}
                        <div className="flex h-full py-3  items-center justify-center w-[15%] border-r border-[#E4E7EC]">
                          <p className="text-[#344054] font-medium text-sm">{moment(item?.deliveryDate).format("DD.MM.YYYY")}</p>
                        </div>
                        {/* tablo 4.kolon */}
                        <div className="flex w-[13%] items-center justify-center h-full py-3 border-r border-[#E4E7EC]">
                          <p className="text-[#344054] font-medium text-sm">
                            {currencyIcon(item?.currency)} {item?.productPrice}
                          </p>
                        </div>
                        {/* tablo 5.kolon */}
                        <div className="flex w-[18%] items-center justify-center h-full py-3 border-r border-[#E4E7EC]">
                          <p className="text-[#344054] font-medium text-sm">
                            {currencyIcon(item?.currency)} {item?.productPrice * item?.orderedQuantity}
                          </p>
                        </div>
                        {/* tablo 6.kolon */}
                        <div className="flex w-[9%] h-full py-3 justify-center items-center">
                          <Button colorType={"secondary-product"} iconLeft={<MinusIcon />} onClick={() => handleRemoveProduct(index)} size={"sm"} />
                        </div>
                      </div>
                    );
                  })}
                  {isAddedProducts?.length > 0 && (
                    <div className="flex absolute bottom-3 right-0 min-h-[72px] max-h-[72px] h-[72px] px-6 w-full">
                      <div className="flex w-full min-h-[72px] max-h-[72px] h-[72px] bg-[#B54708] shadow-lg rounded-xl justify-between items-center">
                        <div className="flex h-full py-3 px-4 gap-x-4 items-center">
                          <div className="flex min-w-[64px] max-w-[64px] min-h-[48px] max-h-[48px] relative">
                            <img
                              className="absolute z-[10] top-[8px] left-[-2px] min-w-[40px] max-w-[40px] min-h-[30px] max-h-[30px] border-[2px] border-[#FFFFFFCC] rounded"
                              src={generateFileUrl(isAddedProducts?.[0]?.image)}
                            />
                            <img
                              className="absolute z-[20] top-[-2px] left-[10px] min-w-[40px] max-w-[40px] min-h-[30px] max-h-[30px] border-[2px] border-[#FFFFFFCC] rounded"
                              src={generateFileUrl(isAddedProducts?.[1]?.image)}
                            />
                            <img
                              className="absolute z-[30] bottom-[-2px] right-[-2px] min-w-[40px] max-w-[40px] min-h-[30px] max-h-[30px] border-[2px] border-[#FFFFFFCC] rounded"
                              src={generateFileUrl(isAddedProducts?.[2]?.image)}
                            />
                          </div>
                          <div className="flex gap-x-1 items-center">
                            <p className="text-[#FFFFFF] font-semibold text-xl">{isAddedProducts?.length}</p>
                            <p className="text-[#FFFFFF] font-normal text-md">{t("product:productsss")}</p>
                          </div>
                        </div>
                        <div className="flex py-3 text-[#FFFFFF] font-semibold text-lg">
                          <div className="flex px-5 items-center justify-center border-r border-[#FFFFFF4D]">{t("product:totalAmount")}</div>
                          <div className="flex flex-col gap-y-[2px] items-center justify-center px-5 border-r border-[#FFFFFF4D]">
                            <p className="text-xs font-medium">{t("product:orderQuantity")}</p>
                            <p>{totalOrderAmount}</p>
                          </div>
                          <div className="flex flex-col gap-y-[2px] items-center justify-center px-5 border-r border-[#FFFFFF4D]">
                            <p className="text-xs font-medium">{t("product:totalSales")}</p>
                            <p>
                              {currencyIcon(isAddedProducts?.[0]?.currency)} {totalCost}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <CustomModal
        isOpen={isOpenNewPorductModal}
        setIsOpen={setIsOpenNewPorductModal}
        modalTitle={t("product:addProductt")}
        width={400}
        children={
          <LegacyAddProduct
            selectedProduct={selectedProduct}
            setIsOpenNewPorductModal={setIsOpenNewPorductModal}
            setIsAddedProducts={setIsAddedProducts}
            isAddedProducts={isAddedProducts}
          />
        }
      />
    </>
  );
};

export default NewFlexOrder;

const QuantityIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M5.75 2.375H3.71429C2.76751 2.375 2 3.14251 2 4.08929V11.9107C2 12.8575 2.76751 13.625 3.71429 13.625H12.2857C13.2325 13.625 14 12.8575 14 11.9107V4.08929C14 3.14251 13.2325 2.375 12.2857 2.375H10.25M5.75 2.375V6.73631C5.75 6.80003 5.81706 6.84147 5.87405 6.81298L8 5.75L10.126 6.81298C10.1829 6.84147 10.25 6.80003 10.25 6.73631V2.375M5.75 2.375H10.25"
        stroke="#475467"
      />
    </svg>
  );
};

import React from "react";
import CustomImage from "../../CustomImage";
import Badgets from "../../buttons/Badgets";
import { ClockIcon, FlexProductIcon, PieceIcon, TargetIcon } from "../card-icons";
import NoAvatar from "../../avatar/NoAvatar";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import { useTranslation } from "react-i18next";
import { secondToHoursAndMinute2 } from "../../../utils/secondToHoursAndMinute";

const EquipmentDetailTimelineActiveCard = ({ data }) => {
  const { t } = useTranslation();
  const convertSeconds = (seconds) => {
    if (isNaN(seconds)) return "---";

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Number(seconds % 60).toFixed(0);

    let formattedTime = "";

    if (minutes > 0) {
      formattedTime += minutes + " dk ";
    }

    if (remainingSeconds > 0) {
      formattedTime += remainingSeconds + " sn";
    }

    return formattedTime.trim();
  };

  let formattedTime =
    data?.junction?.type === "setup"
      ? convertSeconds(
          data?.junction?.productType === "flex"
            ? data?.junction?.flexHeader?.estimatedSettingsTime * 60
            : data?.junction?.header?.internalOperation?.estimatedSettingsTime * 60
        )
      : convertSeconds(
          data?.junction?.productType === "flex"
            ? data?.junction?.flexHeader?.estimatedTime / data?.junction?.flexHeader
            : data?.junction?.header?.totalEstimatedTime / data?.junction?.header?.piece
        );
  return (
    <>
      <div
        className={`flex w-full flex-col border border-t-4 cursor-pointer ${
          data?.junction?.type == "work" ? "border-t-success-500 " : data?.junction?.type === "setup" ? "border-t-[#EAAA08]" : "border-t-[#98A2B3]"
        } items-start rounded-t-[4px] rounded-b-lg border-secondary-300 bg-white relative group`}
      >
        <div className="flex flex-row items-center w-full gap-x-[6px] pt-[6px] px-2 pb-[6px]">
          <div className="flex flex-row items-center gap-x-[8px]  w-[80%] min-w-[80%]">
            {data?.junction?.productType === "flex" ? (
              <>
                {data?.junction?.flexProduct?.image ? (
                  <div>
                    <CustomImage
                      borderRadius={2}
                      src={data?.junction?.flexProduct?.image}
                      style={{
                        width: 64,
                        height: 48,
                        minWidth: 64,
                        minHeight: 48,
                        borderRadius: 4,
                        borderWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                    <NoEquIcon />
                  </div>
                )}
              </>
            ) : (
              <>
                {data?.junction?.job?.product?.image ? (
                  <div>
                    <CustomImage
                      borderRadius={2}
                      src={data?.junction?.job?.product?.image}
                      style={{
                        width: 64,
                        height: 48,
                        minWidth: 64,
                        minHeight: 48,
                        borderRadius: 4,
                        borderWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                    <NoEquIcon />
                  </div>
                )}
              </>
            )}

            <div className="flex flex-col items-start gap-y-[6px]">
              <div className="flex flex-row items-center gap-x-1 w-full">
                {data?.junction?.productType === "flex" ? (
                  <>
                    <FlexProductIcon />
                    <p className="text-sm font-semibold text-secondary-900 truncate w-[285px]">{data?.junction?.flexProduct?.name || "--"}</p>
                  </>
                ) : (
                  <p className="text-sm font-semibold text-secondary-900 truncate w-[300px]">{data?.junction?.job?.product?.name || "--"}</p>
                )}
              </div>
              <div className="flex flex-row items-center gap-x-2">
                <div className="flex flex-row items-center">
                  <ClockIcon />
                  <p className="text-xs font-medium ml-1 text-secondary-600">{secondToHoursAndMinute2(data?.junction?.startDate, new Date())}</p>
                  <p className="text-xxs font-normal ml-0.5 mt-[1px] text-secondary-600">{t("product:timeStarted")}</p>
                </div>
                <div className="flex flex-row items-center">
                  <PieceIcon />
                  <p className="text-xs font-medium ml-1 text-secondary-600">
                    {data?.junction?.productType === "flex" ? data?.junction?.flexHeader?.quantity : data?.junction?.header?.piece}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {data?.junction?.user?.avatar ? (
            <div className="cursor-pointer ml-auto">
              <CustomImage
                src={data?.junction?.user?.avatar}
                style={{
                  width: 48,
                  height: 48,
                  flexDirection: "column",
                  borderRadius: 100,
                  display: "flex",
                  border: "1px solid #0000001A",
                  borderColor: "#0000001A",
                  backgroundColor: "#fff",
                }}
              />
            </div>
          ) : (
            <div className="cursor-pointer ml-auto">
              <NoAvatar size={48} fontSize={22} name={data?.junction?.user?.name} lastName={data?.junction?.user?.lastName} color={"#B54708"} />
            </div>
          )}
        </div>
        <div
          className={`flex flex-row items-center gap-x-4 h-[26px] ${
            data?.junction?.productType === "flex" ? "bg-[#F9F5FF]" : "bg-[#F9FAFB]"
          } min-h-[26px] w-full   border-t-[0.5px] border-t-secondary-300 pb-[3px] pl-2 pt-[3px] pr-[3px] rounded-b-lg`}
        >
          <div className="flex flex-row items-center">
            <TargetIcon />
            <p className="text-xs font-medium ml-1 text-secondary-600">{formattedTime || "--"}</p>
            <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:targetMinActualss")}</p>
          </div>
          <div className="flex flex-row items-center gap-x-1 h-5 min-h-5 pl-2 pr-1 py-[2px] border-[0.5px] bg-white border-secondary-300 rounded ml-auto">
            <p className="text-xxs text text-secondary-600 font-semibold">{t("product:qualityNew")}</p>
            <div className="h-[14px] min-h-[14px] flex items-center justify-center bg-[#B54708] px-1 py-[1px] rounded-[2px]">
              <p className="text-xxs font-semibold text-white">
                %
                {data?.junction?.productType === "flex"
                  ? data?.junction?.flexHeader?.qualityPercent || "--"
                  : data?.junction?.header?.internalOperation?.qualityPercent || "--"}
              </p>
            </div>
          </div>
        </div>
        {(data?.junction?.header?.step || data?.junction?.flexHeader?.step) === undefined ? null : (
          <span className="absolute left-1 top-1">
            <Badgets
              colorType={"fill-gray"}
              label={data?.junction?.productType === "flex" ? data?.junction?.flexHeader?.step + ".op" : data?.junction?.header?.step + ".op"}
              size={"sm"}
            />
          </span>
        )}
      </div>
    </>
  );
};

export default EquipmentDetailTimelineActiveCard;

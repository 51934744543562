import React, { useState, useEffect } from "react";
import { Button, CustomModal } from "../../components";
import { ArrowLeftIcon, MoveIcon, PlusIconNew } from "../../assets/icons/productIcons";
import { useTranslation } from "react-i18next";
import NewPlannedJunctionModal from "./modals/NewPlannedJunctionModal";
import FinishOperationSelectNode from "../../components/modal/FinishOperationSelectNode";
import NewPlannedJunctionExternalModal from "./modals/NewPlannedJunctionExternalModal";
import { Toast } from "../../utils/toastify/toast";
import FinishOperationDropDown from "./components/FinishOperationDropDown";
import AddJunctionManual from "../../components/modal/AddJunctionManual";
const FlexProductOperationDetailHeader = ({
  navigate,
  refetch,
  operation,
  headerId,
  currentOperation,
  operationId,
  orderId,
  disabledMove,
  productType = "standart",
  data,
}) => {
  const { t } = useTranslation();
  const [newPlannedJunctionModal, setNewPlannedJunctionModal] = useState(false);
  const [newPlannedJunctionExternalModal, setNewPlannedJunctionExternalModal] = useState(false);
  const [isOpenJunction, setIsOpenJunction] = useState(false);
  const [isOpenFinishOperation, setIsOpenFinishOperation] = useState(false);
  const [status, setStatus] = useState("");
  const operationData = {
    product: {
      id: operation?.operationId ? operation?.operationId : operation?.flexProductId,
      name: operation?.name,
      image: operation?.image,
    },
    orderNo: operation?.orderNo,
    orderId,
  };






  useEffect(() => {
    setStatus(operation?.operation?.status);
  }, [status]);
  return (
    <>
      <div>
        <div className="w-full h-[78px] border-b flex items-center sticky top-0 z-50 bg-white ">
          <Button size={"md"} iconLeft={<ArrowLeftIcon />} colorType={"tertiary-gray"} onClick={() => navigate(-1)} />
          <div className="flex flex-row items-center ml-3">
            <h1 className="text-xl font-semibold text-gray-900">{t("product:operationDetail")}</h1>
            <p className="ml-2 text-xl font-normal text-[#475467]">{operation?.name}</p>
          </div>
          <div className="flex items-center ml-auto gap-3 mr-1">
            {productType != "flex" && (
              <Button
                colorType={"secondary-product"}
                iconLeft={<MoveIcon />}
                label={t("product:moveJunction")}
                size={"md"}
                disabled={disabledMove}
                onClick={() => {
                  navigate(`/app/product/mrp/work-in-process/detail/${operationId}/move-junction`);
                }}
              />
            )}

            <FinishOperationDropDown
              setIsOpenFinishOperation={setIsOpenFinishOperation}
              setIsOpenJunction={setIsOpenJunction}
              disabled={status != "finished" ? false : true}
            />
            <Button
              colorType={"primary-product"}
              size={"md"}
              label={t("product:planWork")}
              iconLeft={<PlusIconNew color={"#FFFFFF"} />}
              // disabled={currentOperation == false ? true : false}
              disabled={false}
              onClick={() => {
                if (currentOperation?.productType == "external") {
                  setNewPlannedJunctionExternalModal(true);
                } else {
                  setNewPlannedJunctionModal(true);
                }
              }}
            />
          </div>
        </div>

        <CustomModal
          isOpen={isOpenJunction}
          setIsOpen={setIsOpenJunction}
          onClose={() => {
            setIsOpenJunction(false);
          }}
          modalTitle={t("product:junctionManually")}
          width="fit-content"
          children={
            <AddJunctionManual
              refetch={refetch}
              operation={operation}
              orderData={operationData}
              setIsOpenJunction={setIsOpenJunction}
              productType={'flex'}
              operations={data?.operations?.map((e) => {
                return {
                  value: e?.processId,
                  label: e?.operationName,
                  type: e?.processType,
                  headerId: e?.operationId,
                };
              })}
            />
          }
        />
        <CustomModal
          modalTitle={t("product:createNewWork")}
          setIsOpen={setNewPlannedJunctionModal}
          isOpen={newPlannedJunctionModal}
          children={
            <NewPlannedJunctionModal
              operationData={operationData}
              headerId={headerId}
              getJunctions={refetch}
              closeModal={() => setNewPlannedJunctionModal(false)}
              processId={currentOperation?.processId}
              isFlex={true}
            />
          }
        />
        <CustomModal
          modalTitle={t("product:createNewWork")}
          setIsOpen={setNewPlannedJunctionExternalModal}
          isOpen={newPlannedJunctionExternalModal}
          children={
            <NewPlannedJunctionExternalModal
              operationData={operationData}
              headerId={headerId}
              getJunctions={refetch}
              closeModal={() => setNewPlannedJunctionExternalModal(false)}
              processId={currentOperation?.processId}
            />
          }
        />
      </div>
    </>
  );
};

export default FlexProductOperationDetailHeader;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AcceptedIcon,
  DotsVerticalIcon,
  EndClockIcon,
  EquCalculate,
  EquipmentDetailIcon,
  EquipmentEditIcon,
  FlexProductIcon,
  PersonnelDetailIcon,
  PieceIcon,
  QualityDetailIcon,
  RejectedIcon,
  StartClockIcon,
} from "../card-icons";
import { EquipmentDeleteIcon, NoEquIcon, ProductDetailIcon } from "../../../assets/icons/machineIcons";
import CustomImage from "../../CustomImage";
import NoAvatar from "../../avatar/NoAvatar";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { timeFormatter } from "../../../utils/timezoneFormatter";
import { getFormattedNavigateDate } from "../../../utils/getFomattedNavigateDate";
import { useStore } from "../../../hooks/useStores";
const OperationDetailSelectedDoneEventCard = ({ data, items, operationName }) => {
  const filterJunction = items?.find((a) => a?.id == data?.junctionId);
  const { t } = useTranslation();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const navigate = useNavigate();
  const [isJunction, setIsJunction] = useState(false);
  const [editJunction, setEditJunction] = useState(false);
  const { auth } = useStore();
  const menuOptions = [
    {
      label: t("product:equipmentDetails"),
      value: "equipmentDetails",
      icon: <EquipmentDetailIcon />,
    },
    {
      label: t("product:personnelDetail"),
      value: "personnelDetail",
      icon: <PersonnelDetailIcon />,
    },
  ];
  const productType = "flex"


  return (
    <>
      <div className="flex w-full flex-row justify-center items-center group relative">
        <div className="flex flex-col items-start w-[49.5%] min-w-[49.5%] border-t-0 border rounded-lg border-secondary-300 mr-auto">
          <div
            className={`w-full border-t-4 rounded-t-[8px] ${filterJunction?.type == "work" ? "border-t-success-500 " : filterJunction?.type === "setup" ? "border-t-[#EAAA08]" : "border-t-[#98A2B3]"
              }`}
          ></div>

          <div className="flex flex-row items-center w-full border-b">
            {filterJunction?.station?.metadata?.image ? (
              <div
                data-tooltip-id={`${filterJunction?.id}`}
                data-tooltip-place={"right"}
                data-tooltip-content={`
              ${filterJunction?.station?.metadata?.brand + "" + filterJunction?.station?.metadata?.model} 
              `}
              >
                <CustomImage
                  src={filterJunction?.station?.metadata?.image}
                  style={{
                    width: 56,
                    height: 42,
                    minWidth: 56,
                    minHeight: 42,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderRadius: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="w-[56px] min-w-[56px] h-[42px]  border border-l-0 border-b-0 border-secondary-300 bg-secondary-50 flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}
            <div className="flex flex-col items-start px-2">
              <div className="flex flex-row items-center gap-x-1 mr-auto">
                <StartClockIcon />
                <p className="text-xs font-normal text-secondary-600">{moment(filterJunction?.startDate)?.format("HH:mm")}</p>
              </div>
              <div className="flex flex-row items-center gap-x-1 mr-auto">
                <EndClockIcon />
                <p className="text-xs font-normal text-secondary-600">
                  {moment(filterJunction?.endDate === null ? new Date() : filterJunction?.endDate)?.format("HH:mm")}
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-start w-full pl-3 py-1 pr-[44px]">
            <p className="text-xs font-normal text-secondary-500">
              {`(${filterJunction?.productType === "flex" ? filterJunction?.flexHeader?.step + ".Op" : filterJunction?.header?.step + ".Op" || "--"}) ` + `${operationName}`}
            </p>
            <p className="text-xs font-semibold text-secondary-700 truncate w-[99%]">
              {filterJunction?.station.metadata?.brand + "" + filterJunction?.station.metadata?.model || "--"}
            </p>
          </div>
        </div>

        <div className="cursor-pointer flex absolute  w-[50px] h-[50px] mb-6 min-w-[50px] min-h-[50px] z-[100]">
          {filterJunction?.user?.avatar ? (
            <div
              data-tooltip-id={`${filterJunction?.id}`}
              data-tooltip-place={"bottom"}
              data-tooltip-content={`
          ${filterJunction?.user?.name} ${filterJunction?.user?.lastName} 
          `}
              className="cursor-pointer"
            >
              <CustomImage
                src={filterJunction?.user?.avatar}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 100,
                  borderColor: "#0000001A",
                  backgroundColor: "#fff",
                  borderWidth: 2,
                  borderStyle: "solid",
                  boxShadow: "0 0 0 2px #00000008",
                }}
              />
            </div>
          ) : (
            <div
              data-tooltip-id={`${filterJunction?.id}`}
              data-tooltip-place={"bottom"}
              data-tooltip-content={`
          ${filterJunction?.user?.name} ${filterJunction?.user?.lastName} 
          `}
              className="cursor-pointer"
            >
              <NoAvatar size={48} fontSize={22} name={filterJunction?.user?.name} lastName={filterJunction?.user?.lastName} color={"#B54708"} />
            </div>
          )}
        </div>

        <div className="absolute w-[86px] min-w-[86px] mt-14 flex items-center border-[0.5px] border-secondary-300 rounded-[34px] gap-x-[2px] bg-white pl-1 pr-[2px] py-[2px]">
          {filterJunction?.metadata?.qualityStatus === "waiting" ? (
            <>
              <p className="text-xxs ml-5 font-normal text-secondary-600">{t("product:applied")}</p>
            </>
          ) : (
            <>
              <p className="text-xxs font-normal text-secondary-600">{t("product:qualityNew")}</p>
              <div className="flex items-center justify-center rounded-[32px] bg-[#B54708] px-1 ml-auto w-[36px]">
                {filterJunction?.productType === "flex" ? (
                  <p className="text-xxs text-white font-bold"> %{filterJunction?.flexHeader?.qualityPercent || "--"}</p>
                ) : (
                  <p className="text-xxs text-white font-bold"> %{filterJunction?.header?.internalOperation?.qualityPercent || "--"}</p>
                )}
              </div>
            </>
          )}
        </div>

        <div className="flex flex-col items-start w-[49.5%] min-w-[49.5%] border-t-0 border rounded-lg border-secondary-300 ml-auto">
          <div
            className={`w-full border-t-4 rounded-t-[8px] ${filterJunction?.type == "work" ? "border-t-success-500 " : filterJunction?.type === "setup" ? "border-t-[#EAAA08]" : "border-t-[#98A2B3]"
              }`}
          ></div>
          <div className="flex flex-row items-center w-full border-b justify-end">
            <div className="flex flex-col items-start px-2">
              <div className="flex flex-row items-center gap-x-1 ml-auto">
                <p className={`text-xs font-normal ${filterJunction?.metadata?.qualityStatus === "waiting" ? "text-secondary-600" : "text-[#079455]"} `}>
                  {filterJunction?.metadata?.qualityStatus === "waiting" ? filterJunction?.madeWorkCount || "0" : filterJunction?.metadata?.acceptedWorkCount}
                </p>
                {filterJunction?.metadata?.qualityStatus === "waiting" ? <PieceIcon /> : <AcceptedIcon />}
              </div>
              <div className="flex flex-row items-center gap-x-1 ml-auto">
                <p className={`text-xs font-normal ${filterJunction?.metadata?.qualityStatus === "waiting" ? "text-secondary-600" : "text-error-500"} `}>
                  {filterJunction?.metadata?.qualityStatus === "waiting"
                    ? filterJunction?.metadata?.calculateWorkCount || "0"
                    : filterJunction?.metadata?.rejectedWorkCount || "0"}
                </p>
                {filterJunction?.metadata?.qualityStatus === "waiting" ? <EquCalculate /> : <RejectedIcon />}
              </div>
            </div>
            {filterJunction?.productType === "flex" ? (
              <>
                {filterJunction?.flexProduct?.image ? (
                  <div
                    data-tooltip-id={`${filterJunction?.id}`}
                    data-tooltip-place={"left"}
                    data-tooltip-content={`
      ${filterJunction?.flexProduct?.name} 
      `}
                  >
                    <CustomImage
                      src={filterJunction?.flexProduct?.image}
                      style={{
                        width: 56,
                        height: 42,
                        minWidth: 56,
                        minHeight: 42,
                        borderTopRightRadius: 4,
                        borderBottomRightRadius: 4,
                        borderRadius: 0,
                        borderLeftWidth: 1,
                        borderRightWidth: 0,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-[56px] min-w-[56px] h-[42px] bg-[#DC6803] flex border border-[#DC6803] items-center justify-center font-medium text-white text-xl">
                    {filterJunction?.flexProduct?.name.slice(0, 2)?.toUpperCase()}
                  </div>
                )}
              </>
            ) : (
              <>
                {filterJunction?.job?.product?.image ? (
                  <div
                    data-tooltip-id={`${filterJunction?.id}`}
                    data-tooltip-place={"left"}
                    data-tooltip-content={`
      ${data?.productData} 
      `}
                  >
                    <CustomImage
                      src={filterJunction?.job?.product?.image}
                      style={{
                        width: 56,
                        height: 42,
                        minWidth: 56,
                        minHeight: 42,
                        borderTopRightRadius: 4,
                        borderBottomRightRadius: 4,
                        borderRadius: 0,
                        borderLeftWidth: 1,
                        borderRightWidth: 0,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-[56px] min-w-[56px] h-[42px] bg-[#DC6803] flex border border-[#DC6803] items-center justify-center font-medium text-white text-xl">
                    {filterJunction?.job?.product?.name.slice(0, 2)?.toUpperCase()}
                  </div>
                )}
              </>
            )}
          </div>

          <div className="flex flex-col items-end w-full pr-3 py-1 pl-[44px]">
            <p className="text-xs font-normal text-secondary-500 w-[99%] truncate ml-auto text-end">
              {filterJunction?.productType === "flex" ? filterJunction?.flexProduct?.stockCode : filterJunction?.job?.orderNo || "--"}
            </p>
            <div className="flex flex-row items-center gap-x-1 w-full">
              {filterJunction?.productType === "flex" ? (
                <>
                  <FlexProductIcon />
                  <p className="text-xs font-semibold text-end text-secondary-700 truncate ml-auto w-[99%]">{filterJunction?.flexProduct?.name || "--"}</p>
                </>
              ) : (
                <p className="text-xs font-semibold text-end text-secondary-700 truncate ml-auto w-[99%]">{filterJunction?.job?.product?.name || "--"}</p>
              )}
            </div>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(!showDropDown);
          }}
          className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[6px] z-[50] items-center justify-center cursor-pointer"
        >
          <DotsVerticalIcon />
        </div>
        {showDropDown && (
          <div className="hidden flex-col absolute min-w-[200px] max-w-[200px] h-fit bg-white border top-[32px] right-[4px] z-[110] rounded-lg px-[6px] py-1 group-hover:flex">
            {menuOptions?.map((item, index) => {
              const formattedDate = getFormattedNavigateDate(data?.startDate, auth.user.company.dayStartHour);
              return (
                <button
                  onClick={(e) => {
                    e.stopPropagation();

                    if (item?.value === "equipmentDetails") {
                      navigate(
                        `/app/machine/digital-twin/equipment-details/${filterJunction?.station.deviceId}-${filterJunction?.station?.id}/${formattedDate}`
                      );
                    }
                    if (item?.value === "personnelDetail") {
                      navigate(`/app/machine/digital-twin/user-detail/${filterJunction?.user?.id}/${formattedDate}`);
                    }

                    if (item.value === "editJunction") {
                      setEditJunction(true);
                    }
                    setShowDropDown(false);
                    e.stopPropagation();
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${item?.value === "delete" ? "hover:bg-[#FECDCA]" : "hover:bg-secondary-100"
                    }`}
                >
                  {item?.icon}
                  <p className={`font-medium text-sm  ${item?.value === "delete" ? "text-[#D92D20]" : "text-secondary-700"}`}>{item.label}</p>
                </button>
              );
            })}
          </div>
        )}
        <Tooltip className="z-[120]" id={`${filterJunction?.id}`} />
      </div>
      {/* <AlertModal
      title={t("product:deleteOrder")}
      subTitle={t("equipments:deleteAlert")}
      isOpen={isAlert}
      setIsOpen={setIsAlert}
      applyButton={() => {
        onDeleted(deleteId);
      }}
    />
    <CustomModal
      isOpen={isJunction}
      setIsOpen={setIsJunction}
      onClose={() => {
        setIsJunction(false);
      }}
      modalTitle={t("product:qualityControlForm")}
      children={
        <QualityControlForm
          junctionData={data}
          refetch={refetch}
          setIsOpen={setIsJunction}
          onClose={() => {
            setIsJunction(false);
          }}
          junctionId={data?.id}
        />
      }
    />
    <CustomModal
      isOpen={editJunction}
      setIsOpen={setEditJunction}
      onClose={() => {
        setEditJunction(false);
      }}
      modalTitle={t("product:editJunction")}
      children={
        <JunctionUpdate
          junctionId={data?.id}
          onClose={() => {
            setEditJunction(false);
          }}
          refetch={refetch}
        />
      }
    /> */}
    </>
  );
};

export default OperationDetailSelectedDoneEventCard;

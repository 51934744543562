import React from "react";
import { EventsIcon, NewAcceptedIcon, NewDotsIcon, NewRejectedIcon, PlannedIcon, ReadyIcon } from "../../../components/new-cards/card-icons";
import { useNavigate } from "react-router-dom";
import { legacyFormatSeconds } from "../../../utils/secondToHoursAndMinute";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const IntermediateWipCard = ({ item, status, operationName = "Operation Name", onClick, occurrences, orderData, showOperationDetail = true }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const statusColor = () => {
    switch (item?.status) {
      case "finished":
        return "#ECFDF3";
      case "started":
        return "#FFFAEB";
      case "rejected":
        return "#D92D20";
      default:
        return "#F2F4F7";
    }
  };

  const operationTypes = (type) => {
    switch (type) {
      case "conversion_operation":
        return "DÖN";
      case "supporter_operation":
        return "YAR";
      case "assembly_operation":
        return "MON";
      case "decomposition_operation":
        return "AYR";
    }
  };

  return (
    <div
      // onClick={() => {
      //   showOperationDetail && navigate(`/app/product/mrp/work-in-process/detail/${orderData?.id}+${item?.step}`);
      // }}
      className={`flex w-[312px] h-[112px] flex-col border items-start cursor-pointer rounded-lg border-secondary-300 bg-white relative group `}
    >
      {/* <div style={{ backgroundColor: statusColor() }} className="flex w-full min-h-[4px] max-h-[4px] h-1 rounded-t-[8px]"></div> */}
      <div className="flex flex-row items-center w-[100%]  justify-between pr-1 py-2 pl-3">
        <div className="flex flex-row items-center  h-5 ">
          <div className="px-1  border h-full w-fit rounded bg-[#F2F4F7]">
            {item?.externalOperation ? (
              <p className="text-xs font-semibold text-secondary-700">{"HİZ"}</p>
            ) : (
              <p className="text-xs font-semibold text-secondary-700">
                {item?.internalOperation?.process?.type || item?.internalOperation?.processType
                  ? operationTypes(item?.internalOperation?.process?.type || item?.internalOperation?.processType)
                  : "--"}
              </p>
            )}
          </div>
          <div className="px-1 bg-white max-w-[230px] rounded-r-[8px]">
            <p className="font-semibold text-sm ml-1 text-secondary-700 truncate">
              {occurrences > 1
                ? `${item?.internalOperation?.process?.name || item?.externalOperation?.process?.name} ${occurrences}`
                : item?.internalOperation?.process?.name || item?.externalOperation?.process?.name || "--"}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center w-full h-full">
        <div className="flex flex-col px-2 w-[107px] h-full">
          <div className="flex flex-row items-center w-full justify-between  border-b pb-0.5">
            <p className="font-normal text-secondary-600 ml-1 text-xxs">Stokta</p>
            <p className="text-xs font-medium max-w-[50px]  truncate text-secondary-700">{item?.qualityPercent || "0"}</p>
          </div>
          <div className="flex flex-row items-center w-full justify-between border-b pb-0.5  pt-[4px] text-[#D92D20]">
            <p className="font-normal ml-1 text-xxs">Red</p>
            <p className="text-xs font-medium max-w-[40px] truncate text-secondary-700">{item?.rejectedPiece || 0}</p>
          </div>
          <div className="flex flex-row items-center w-full pb-0.5 pt-[4px] justify-between">
            <p className="font-normal text-secondary-600 ml-1 text-xxs">Maliyet</p>
            <p className="text-xs font-medium text-secondary-700">$732,32</p>
          </div>
        </div>
        <div className="flex flex-row items-center rounded-tl-[4px] border border-r-0 border-b-0 border-[#E4E7EC]">
          <div className="flex flex-col items-start border-r w-[98px] border-r-[#E4E7EC]">
            <div className="px-1 py-[3px] flex items-center rounded-tl-[4px] justify-center border-b w-full bg-[#F9FAFB]">
              <p className="text-xs font-medium text-secondary-700">Çevrim S. (dk)</p>
            </div>
            <div className="flex flex-col px-[6px] w-full">
              <div className="flex px-1.5 py-[3px] flex-row items-center w-full justify-between border-b border-[#E4E7EC]">
                <p className="text-[10px] text-secondary-500 font-normal">{t("product:target")}</p>
                <p className="font-medium text-secondary-600 text-xs">{legacyFormatSeconds(item?.estimatedTime) || "--"}</p>
              </div>
              <div className="flex px-1.5 py-[1.5px] flex-row items-center w-full justify-between">
                <p className="text-[10px] text-secondary-500 font-normal">applied</p>
                <p className={`font-medium ${item?.estimatedTime < item?.avgActualTime ? "text-[#F04438]" : "text-[#079455] "} text-xs`}>
                  {console.log("item", item)}
                  {legacyFormatSeconds(item?.avgActualTime) || "--"}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center  w-[83px]">
            <div className="px-1.5 py-[3px] flex items-center rounded-tl-[4px] justify-center border-b w-full bg-[#F9FAFB]">
              <p className="text-xs font-medium text-secondary-700">Ayar S. (dk)</p>
            </div>
            <div className="flex flex-col px-[6px] w-full">
              <div className="flex px-1.5 py-[3px] flex-row items-center  gap-x-0.5 border-b border-[#E4E7EC]">
                <p className="text-[10px] text-secondary-500 font-normal">{t("product:target")}</p>
                <p className="font-medium text-secondary-600 text-xs">{legacyFormatSeconds(item?.estimatedSettingsTime) || "--"}</p>
              </div>
              <div className="flex px-1.5 py-[1.5px] flex-row items-center gap-x-0.5">
                <p className="text-[10px] text-secondary-500 font-normal">applied</p>
                <p className="font-medium text-[#475467] text-xs">{item?.qualityRejectedPiece || "--"}</p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ backgroundColor: statusColor() }}
          className={`absolute right-0 bottom-0 flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px] text-secondary-600`}
        >
          {i18next?.language === "tr" ? (
            <p className={`text-xxs font-semibold -rotate-90 whitespace-nowrap`}>{"Ara Mamül"}</p>
          ) : (
            <div className="flex items-center">
              <p className={`text-xxs font-semibold -rotate-90 whitespace-nowrap`}>Ara Mamül</p>
            </div>
          )}
        </div>
        <div
          onClick={(e) => e.stopPropagation()}
          className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <NewDotsIcon />
        </div>
      </div>
    </div>
  );
};

export default IntermediateWipCard;

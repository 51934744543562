import React, { useState, useEffect } from "react";
import { Button, CustomModal, Loading } from "../../components";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useStore } from "../../hooks/useStores";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { purchaseService } from "../../services/purchase.service";
import diacritics from "diacritics";
import MaterialSupplyEventCard from "../../components/new-cards/junction-card/MaterialSupplyEventCard";
import MaterialSupplyActiveEventCard from "../../components/new-cards/junction-card/MaterialSupplyActiveEventCard";
import { QualityTab } from "./quality/components";
import { Toast } from "../../utils/toastify/toast";
import NewOrderHeaderCard from "./components/order/NewOrderHeaderCard";
import useWindowDimensions from "../../hooks/useWindowDimension";
import NewQualityControlForm from "./modals/NewQualityControlForm";
import NewPurchaseFinishModal from "./modals/NewPurchaseFinishModal";
import MaterialSupplyPlanModal from "./modals/MaterialSupplyPlanModal";
import UnplannedMaterialSupplyJunctionCard from "../../components/junction/UnplannedMaterialSupplyJunctionCard";
import SearchInput from "../../components/inputs/SearchInput";
import { FilterIcon, SortIcon } from "../../assets/icons/productIcons";
import MaterialSupplyHeaderCard from "./cards/material-supply/MaterialSupplyHeaderCard";
import MaterialSupplyPlanModalRawCard from "../../components/junction/MaterialSupplyPlanModalRawCard";
import MaterialSupplyEventCardPlannedTriple from "../../components/new-cards/material-supply/MaterialSupplyEventCardPlannedTriple";
import MaterialSupplyReceivedCard from "../../components/new-cards/material-supply/MaterialSupplyReceivedCard";
import MaterialSupplyQualityAppliedCard from "../../components/new-cards/material-supply/MaterialSupplyQualityAppliedCard";
import MaterialSupplyTab from "./materialSupply/components/MaterialSupplyTab";
const MaterialSupplyJunction = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { settings, auth } = useStore();
  const { height, width } = useWindowDimensions();
  let navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("all");
  const [finishPurhcaseModal, setFinishPurhcaseModal] = useState(false);
  const [finishPurhcaseModalData, setFinishPurhcaseModalData] = useState(null);
  const [purchaseQualityForm, setPurchaseQualityForm] = useState(false);
  const [purchaseQualityFormData, setPurchaseQualityFormData] = useState(null);
  const [isLeftArrowHidden, setIsLeftArrowHidden] = useState(true);
  const [isRightArrowHidden, setIsRightArrowHidden] = useState(false);
  const [draggedJunction, setDraggedJunction] = useState(null);
  const [isOpenPlanningModal, setIsOpenPlanningModal] = useState(false);
  const [isOpenPlanningModalData, setIsOpenPlanningModalData] = useState(null);
  const headData = useQuery({
    queryKey: ["all-supplier-group-junctions"],
    enabled: false,
    retry: 0,
    queryFn: async () => await purchaseService.getPurchaseJunctionsForCompany(),
  });
  const planView = useQuery({
    queryKey: ["purchase-junctions-planned"],
    enabled: false,
    queryFn: async () => await purchaseService.purchaseTransactionsPlanned(id, "follow"),
    retry: 0,
  });
  const refetch = () => {
    planView.refetch();
    headData.refetch();
  };
  useEffect(() => {
    headData.refetch();
    if (id != ":id" && id != "undefined") {
      planView.refetch();
      setSelectedGroup(headData?.data?.operations?.find((item) => item?.id == id));
    }
  }, [id]);
  useEffect(() => {
    if (headData?.data?.operations?.length > 0 && (id == ":id" || id == "undefined")) {
      navigate(`/app/product/mrp/supply/material/${headData?.data?.operations[0]?.id}/junction`);
    }
  }, [headData?.data]);
  const filteredPurchaseJunctionUnplannedData = planView?.data?.orderList
    ?.filter((d) => d.status == "waiting")
    ?.filter((d) => {
      const rawMaterialName = d?.rawMaterial?.name || "";
      const normalizedRawMaterialName = diacritics.remove(rawMaterialName).toLowerCase();
      const normalizedSearchValue = diacritics.remove(searchTerm || "").toLowerCase();
      return normalizedRawMaterialName.includes(normalizedSearchValue);
    });
  const filteredPurchaseJunctionPastJunctionData = planView?.data?.subJunctions
    ?.filter((d) => d.quality == "applied")
    ?.filter((d) => {
      const rawMaterialName = d?.purchaseRequest?.rawMaterial?.name || "";
      const normalizedRawMaterialName = diacritics.remove(rawMaterialName).toLowerCase();
      const normalizedSearchValue = diacritics.remove(searchTerm || "").toLowerCase();
      return normalizedRawMaterialName.includes(normalizedSearchValue);
    });
  const filteredPurchaseJunctionPendingJunctionData = planView?.data?.subJunctions
    ?.filter((d) => d.quality == "waiting")
    ?.filter((d) => {
      const rawMaterialName = d?.purchaseRequest?.rawMaterial?.name || "";
      const normalizedRawMaterialName = diacritics.remove(rawMaterialName).toLowerCase();
      const normalizedSearchValue = diacritics.remove(searchTerm || "").toLowerCase();
      return normalizedRawMaterialName.includes(normalizedSearchValue);
    });
  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  const handleScroll = () => {
    const container = document.getElementById("content-wrapper");
    const isAtBeginning = container.scrollLeft === 0;
    const isAtEnd = container.scrollLeft >= container.scrollWidth - container.clientWidth;
    setIsLeftArrowHidden(isAtBeginning);
    setIsRightArrowHidden(isAtEnd);
  };
  useEffect(() => {
    const container = document.getElementById("content-wrapper");
    container?.addEventListener("scroll", handleScroll);
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  useEffect(() => {
    if (auth?.user?.company?.id == "e5c06127-3c5d-44b9-8403-74c160fc7f59") {
    } else if (auth?.user?.company?.id == "ab77a50a-7b68-42a2-ba5c-0ebfb5c13325") {
    } else if (auth?.user?.company?.id == "71b0edec-e5d4-4929-bfdb-0e5d5e149592") {
    } else if (auth?.user?.company?.id == "dd1892b9-d9a3-44ed-9131-17c88a74a1b4") {
    } else if (auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706") {
    } else if (auth?.user?.company?.id == "49ee8491-35d7-4eb2-a0e9-bfc95541180f") {
    } else if (auth?.user?.company?.id == "65844198-a9e8-45fa-bc99-7d012a541b35") {
    } else if (auth?.user?.company?.id == "7c5bb5a6-21aa-4d1d-829b-2a6d360b9630") {
    } else if (auth?.user?.company?.id == "f7db6191-5842-455a-99f4-c76763090689") {
    } else if (auth?.user?.company?.id == "776c0df3-0a44-464f-9877-33bd731aef6a") {
    } else if (auth?.user?.company?.id == "065e9b57-9da2-4394-9359-59ba71610dc3") {
    } else if (auth?.user?.company?.id == "e41a9bde-203c-48f2-aeec-4f8ce6344513") {
    } else if (auth?.user?.company?.id == "262d8e02-57e8-427e-9159-6b18b236ca86") {
    } else if (auth?.user?.company?.id == "0c082d32-6d97-4ba9-a2a5-7e41fe8139d9") {
    } else {
      navigate("/app/product/verysoon");
    }
  }, []);

  const handlePlannedOnDrop = () => {
    if (draggedJunction?.id) {
      if (!(draggedJunction?.status === "waiting")) {
        Toast("error", t("product:columnDragDropWarning"));
        return;
      }
      setIsOpenPlanningModal(true);
      setIsOpenPlanningModalData(draggedJunction);
    }
  };

  const handleFinishedOnDrop = () => {
    console.log("draggedJunction", draggedJunction);
    if (draggedJunction?.id) {
      if (!(draggedJunction?.quality === "waiting")) {
        Toast("error", t("product:columnDragDropWarning"));
        return;
      }
      setPurchaseQualityForm(true);
      setPurchaseQualityFormData(draggedJunction);
    }
  };

  const handleWaitingOnDrop = () => {
    if (draggedJunction?.id) {
      if (!(draggedJunction?.status === "active")) {
        Toast("error", t("product:columnDragDropWarning"));
        return;
      }
      setFinishPurhcaseModal(true);
      setFinishPurhcaseModalData(draggedJunction);
    }
  };

  const tabOptions = [
    { value: "list", label: "Liste" },
    { value: "junction", label: t("product:eventss") },
    { value: "plan", label: t("product:planner") },
  ];

  return (
    <div className="w-full h-full flex flex-col overflow-hidden overflow-y-auto scrollbar-hide">
      <>
        <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
          <span className="mr-3 md:flex sm:flex xs:flex hidden">
            <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
          </span>
          <div className="flex items-center">
            <span className="font-codecMedium text-2xl text-black">upu.</span>
            <p className="text-2xl font-codecMedium text-[#B54708]">supply</p>
            {!(headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading) && (
              <p className="text-xl font-normal ml-2 text-secondary-500">
                {selectedGroup && selectedGroup?.name ? `${t("product:materialSupply")} / ${selectedGroup?.name}` : t("product:materialSupply")}
              </p>
            )}
          </div>
          <span className="ml-auto">
            <Button
              colorType={"primary-product"}
              size={"md"}
              label={t("product:supplierList")}
              onClick={() => navigate("/app/product/mrp/supply/material/list")}
            />
          </span>
        </div>
        {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
          <div className="w-full bg-gray-200 rounded-xl animate-pulse h-[96px] min-h-[96px] flex gap-5" />
        ) : (
          <div className="h-[44px] min-h-[44px] w-full overflow-x-auto flex ">
            {headData?.data?.operations
              ?.sort((a, b) => b?.active + b?.past + b?.pending - (a?.active + a?.past + a?.pending))
              ?.map((item, index) => {
                return (
                  <div className={`min-w-[192px] ${index !== headData?.data?.operations?.length - 1 ? "border-b" : "border-b-0"} max-h-[40px] pr-5`}>
                    <MaterialSupplyHeaderCard
                      onClick={async () => {
                        navigate(`/app/product/mrp/supply/material/${item?.id}/junction`);
                      }}
                      icon={item?.iconKey}
                      name={item?.name}
                      planned={item?.plannedUnplanned?.planned}
                      unPlanned={item?.plannedUnplanned?.unPlanned}
                      selectedOperation={{ id: id, name: item?.name }}
                      id={item?.id}
                    />
                  </div>
                );
              })}
          </div>
        )}
        {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
          <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-gray-200 p-[6px] mt-6 mb-4 rounded-xl animate-pulse flex gap-5" />
        ) : (
          <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-[#F9FAFB] border border-[#EAECF0] p-[6px] rounded-xl flex items-center justify-center mt-4 mb-4">
            {tabOptions?.map((option, index) => (
              <div
                className={`w-1/2 h-full rounded-md px-3 py-2  flex items-center justify-center cursor-pointer ${
                  "junction" == option?.value ? "bg-white shadow-sm" : ""
                }`}
                onClick={() => {
                  if (option?.value === "plan") navigate(`/app/product/mrp/supply/material/${id}/plan-view`);
                  if (option?.value === "list") navigate(`/app/product/mrp/supply/material/${id}/list`);
                }}
              >
                <p className={`text-md font-semibold ${"junction" == option?.value ? "text-[#344054]" : "text-[#667085]"}`}>{option?.label}</p>
              </div>
            ))}
          </div>
        )}
        {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
          <div className="w-full h-full bg-gray-200 rounded-xl animate-pulse mt-4" />
        ) : (
          <div style={{ minHeight: height - 135 }} className="flex w-full h-full rounded-xl mt-4 relative border border-[#E4E7EC] bg-[#F9FAFB]">
            <div className="flex flex-col min-w-[344px] max-w-[344px] w-[344px] bg-white rounded-tl-xl border-r border-[#E4E7EC]">
              <div className="flex w-full min-h-[52px] max-h-[52px] h-[52px] px-5 py-3 text-[#101828] text-lg font-semibold border-b border-[#E4E7EC]">
                Aktif Talepler
              </div>
              <div className="flex flex-col w-full px-4 py-3 gap-y-4">
                {filteredPurchaseJunctionUnplannedData?.map((item, index) => {
                  return (
                    <div draggable onDragStart={() => setDraggedJunction(item)} key={index} className="min-h-[80px]">
                      <MaterialSupplyPlanModalRawCard item={item} />
                    </div>
                  );
                })}
              </div>
            </div>
            <div style={{ minWidth: width - 475 }} className="flex flex-col h-full px-6 py-4">
              <div className="flex min-h-10 max-h-10 h-10 items-center justify-end gap-x-3">
                <span className="flex">
                  <SearchInput />
                </span>
                <Button colorType={"secondary-gray"} size={"md"} iconLeft={<SortIcon />} />
                <Button colorType={"secondary-gray"} size={"md"} iconLeft={<FilterIcon />} />
              </div>
              <div className="flex w-full h-full overflow-x-auto scrollbar-hide overflow-y-hidden">
                <MaterialSupplyTab
                  length={planView?.data?.plannedJunctions?.length || 0}
                  type={"planned"}
                  onDragOver={(e) => e.preventDefault()}
                  onDragEnd={() => {
                    setDraggedJunction(null);
                  }}
                  onDrop={() => {
                    handlePlannedOnDrop();
                  }}
                  children={
                    <PlannedJunctionListController
                      setDraggedJunction={setDraggedJunction}
                      items={planView?.data?.plannedJunctions}
                      navigate={navigate}
                      setFinishPurhcaseModal={setFinishPurhcaseModal}
                      setFinishPurhcaseModalData={setFinishPurhcaseModalData}
                    />
                  }
                />
                <MaterialSupplyTab
                  length={filteredPurchaseJunctionPendingJunctionData?.length || 0}
                  type={"received"}
                  onDragEnd={() => {
                    setDraggedJunction(null);
                  }}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={() => {
                    handleWaitingOnDrop();
                  }}
                  children={
                    <JunctionListController
                      setDraggedJunction={setDraggedJunction}
                      items={filteredPurchaseJunctionPendingJunctionData}
                      setPurchaseQualityForm={setPurchaseQualityForm}
                      setPurchaseQualityFormData={setPurchaseQualityFormData}
                      navigate={navigate}
                    />
                  }
                />
                <MaterialSupplyTab
                  length={filteredPurchaseJunctionPastJunctionData?.length || 0}
                  type={"complate"}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={() => {
                    handleFinishedOnDrop();
                  }}
                  children={
                    <QualityApliedController
                      setDraggedJunction={setDraggedJunction}
                      items={filteredPurchaseJunctionPastJunctionData}
                      navigate={navigate}
                      setPurchaseQualityForm={setPurchaseQualityForm}
                      setPurchaseQualityFormData={setPurchaseQualityFormData}
                    />
                  }
                />
              </div>
            </div>

            {(planView?.isFetching || planView?.isLoading) && (
              <div className="w-full absolute bottom-0 p-2 px-3 flex items-center bg-white border-b border-secondary-200 flex-1 justify-center">
                <Loading size={20} primary={"#B54708"} secondary={"#fff"} />
              </div>
            )}
          </div>
        )}
      </>
      <CustomModal
        isOpen={finishPurhcaseModal}
        setIsOpen={setFinishPurhcaseModal}
        modalTitle={"Teslim Al"}
        subTitle={"Alım miktarını ve varsa ek formları ekleyin"}
        children={<NewPurchaseFinishModal refetch={refetch} setIsOpen={setFinishPurhcaseModal} junctionData={finishPurhcaseModalData} />}
      />
      <CustomModal
        isOpen={purchaseQualityForm}
        setIsOpen={setPurchaseQualityForm}
        modalTitle={"Kalite Kontrol Formu"}
        children={<NewQualityControlForm refetch={refetch} setIsOpen={setPurchaseQualityForm} junctionData={purchaseQualityFormData} />}
      />
      <CustomModal
        isOpen={isOpenPlanningModal}
        setIsOpen={setIsOpenPlanningModal}
        onClose={() => {
          setIsOpenPlanningModal(false);
        }}
        width="fit-content"
        children={<MaterialSupplyPlanModal setIsOpen={setIsOpenPlanningModal} refetch={refetch} selectedJunction={isOpenPlanningModalData} groupId={id} />}
      />
    </div>
  );
};

export default MaterialSupplyJunction;

const JunctionListController = ({ items, setPurchaseQualityFormData, setPurchaseQualityForm, setDraggedJunction }) => {
  return items?.map((item, index) => {
    return (
      <div draggable onDragStart={() => setDraggedJunction(item)} key={index} className="min-h-[80px] mb-3">
        <MaterialSupplyReceivedCard data={item} setPurchaseQualityForm={setPurchaseQualityForm} setPurchaseQualityFormData={setPurchaseQualityFormData} />
      </div>
    );
  });
};

const QualityApliedController = ({ items, setPurchaseQualityFormData, setPurchaseQualityForm, setDraggedJunction }) => {
  return items?.map((item, index) => {
    return (
      <div draggable onDragStart={() => setDraggedJunction(item)} key={index} className="min-h-[80px] mb-3">
        <MaterialSupplyQualityAppliedCard data={item} setPurchaseQualityForm={setPurchaseQualityForm} setPurchaseQualityFormData={setPurchaseQualityFormData} />
      </div>
    );
  });
};

const PlannedJunctionListController = ({ items, setFinishPurhcaseModal, setFinishPurhcaseModalData, setDraggedJunction }) => {
  return items?.map((item, index) => {
    return (
      <div draggable onDragStart={() => setDraggedJunction(item)} key={index} className="min-h-[80px] cursor-pointer mb-3">
        <MaterialSupplyEventCardPlannedTriple
          data={item}
          setFinishPurhcaseModal={setFinishPurhcaseModal}
          setFinishPurhcaseModalData={setFinishPurhcaseModalData}
        />
      </div>
    );
  });
};

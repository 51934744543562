const en = {
  dashboard: {
    title: "Bird’s-eye view of manufacturing",
    plannedProduction: "Planned Productıon",
    notifications: "Notifications",
    totalPoint: "Total Point",
    capacityUtilizationRate: "Capacity Utilization Rates",
  },
  personals: {
    personal: "Personnel",
    personals: "Personnels",
    title: "persons of your company",
    addPersonal: " Add Personnel",
    addAttachments: "Add Attachment",
    startEndTime: "Start-End Time",
    commentHere: "Comment here",
    showMore: "Show more",
    less: "Less",
    profilePicture: "Profile picture",
    profileInfo: "Profile information",
    updateInfo: "Update your profile",
    password: "Password",
    oldPassword: "Old Password",
    newPassword: "New Password",
    retype: "Type again new password",
  },
  equipments: {
    equipment: "Equipment",
    equipments: "Equipments",
    title: "equipments of your company",
    deleteEquipment: "Delete Equipment",
    deleteAlert: "Are you sure that you want to delete ? This action cannot be undone.",
    defined: "Defined Equipment",
    undefined: "Undefined Equipment",
    noLimitEmbeddedServer: "Embedded Server limit is full.",
    areYouSure: "Are you sure that you want to delete ? This action cannot be undone.",
    sendErrorMessage: "Send Error Message",
  },
  report: {
    table: "Reports",
    reports: "Reports",
    addNewReport: "Add New Report",
    reportName: "Report Name",
    creationDate: "Creation Date",
    updateDate: "Update Date",
    select: "Select Area",
    title: "reports of your company",
    creationDate: "Creation Date",
    setDeadLine: "Set a Deadline",
    getNewReport: "Get New Report",
    nameTheReport: "Name The Report",
    reportDelete: "do you confirm that the report will be deleted?",
  },
  routes: {
    createCompany: "Company",
    language: "Language",
    addProcess: "Internal Operation",
    operationName: "Operation Name",
    addPersonal: "Add Personnel",
    processResponsible: "Operation Responsible",
    departmentResponsible: "Department Responsible",
    addEquipment: "Equipment",
    shift: "Shift",
    endOfSteps: "Congrats",
    personals: "Personnels",
    equipments: "Equipments",
    departments: "Departments",
    department: "Department",
    maintinance: "Maintenance",
    reports: "Reports",
    losses: "Losses",
    twin: "Digital Twin & KPI",
    unplannedLoss: "Unplanned Losses",
    // settings
    settings: "Settings",
    profile: "Profile",
    company: "Company",
    personnel: "Personnel",
    notifications: "Notifications",
    theme: "Theme",
    upuPoint: "Upu Point",
    adress: "Address",
    external: "External Operation",
    Product: "Products",
    Order: "Order",
    Stock: "Stock",
    saveCustomer: "Save Customer",
    OperationList: "Operation List",
    unplannedLosses: "Unplanned Losses",
    lossesOfYourCompany: "losses of your company",
    companyReports: "Company Reports",
    mapEditor: "Map Editor",
    addNewArea: "Add New Area",
    area: "Area",
    alreadyInMap: "Already in map",
    templates: "Templates",
    wall: "Wall",
    doorEight: "Door (80cm)",
    doorThree: "Door (3m)",
    roundColumn: "Round Column",
    squareColumn: "Square Column",
    width: "Width (m)",
    height: "Height (m)",
    MaintenanceLibrary: "Maintenance Library",
    ConnectedEquipment: "Connected Equipment",
    CareCenter: "Maintenance Center",
    customizeYourMap: "Customize Your Map",
    returnDefault: "Return to Default Map",
  },
  table: {
    image: "Image",
    name: "Name",
    surname: "Surname",
    contactName: "Contact Name & Surname",
    nameSurname: "Name & Surname",
    edit: "Edit",
    authorization: "Authorization",
    personal: "personnel",
    machineModel: "Machine Model",
    machineName: "Machine Name",
    searchMachineName: "Search Machine Name",
    score: "Score",
    piece: "Pieces",
    phoneNo: "Phone Number",
    lastMaintenance: "Last Maintenance",
    activeCare: "Active Care",
    hourlyApproachingMaintenance: "Hourly Approaching Care",
    dailyApproachingMaintenance: "Daily Approaching Care",
    registeredCare: "Registered Care",
    faultName: "Fault Name",
    thePassingTime: "Passing Time",
    faultType: "Fault Type",
    noExplanation: "No Explanation Given",
    unidentifiedFault: "Unidentified Fault",
    identifiedFault: "Identified Fault",
    causeOfFailure: "Cause Of Failure",
    faultDescription: "Fault Description",
    deliveryDate: "Delivery Date",
  },
  label: {
    date: "Date",
    alphabetical: "Alphabetical",
    authorization: "Authorization",
    talent: "Talent",
    data: "Data",
    noData: "No Data !",
  },
  legends: {
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
  },
  signals: {
    noElectric: "No electric in the machine",
    active: "Active working",
    standby: "StandBy",
    fault: "Fault",
    break: "Break",
    internetConnectionLost: "Internet connection lost",
    sensorCableRemoved: "Sensor cable removed",
    softwareUpdate: "Software update",
    noElectricTower: "No electric in the tower",
  },
  days: {
    day: "Day",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    addNewShift: "Add New Shift",
    addShift: "Add Shift",
    addShiftCredit: "Purchase Additional Shift Credits",
  },
  buttons: {
    start: "Start",
    addStartDateTime: "Add Start Date / Time",
    login: "Login",
    next: "Next",
    skip: "Skip",
    back: "Back",
    nextDay: "Next Day",
    prev: "Previous",
    plan: "Plan",
    previousDay: "Previous Day",
    add: "Add",
    edit: "Edit",
    delete: "Delete",
    addNew: "Add New",
    change: "Change",
    stop: "Cancel",
    approve: "Approve",
    save: "Save",
    daily: "Daily",
    load: "Load",
    exportXlsx: "Export xlsx",
    weekly: "Weekly",
    monthly: "Monthly",
    monthlyDetails: "Monthly Details",
    yearly: "Yearly",
    send: "Send",
    selectAll: "Select All",
    timeSelect: "Select Time Interval",
    selectDate: "Select Date",
    create: "Create",
    request: "Request",
    createPool: "Create Poll",
    logOut: "Log Out",
    closed: "Close",
    okey: "OK",
    cancel: "Cancel",
    copy: "Copy",
    advance: "Advance",
    archive: "Archive",
    reject: "Reject",
    view: "View",
    previous: "Previous",
    detail: "Detail",
    apply: "Apply",
    tryAgain: "Try Again",
    submitForm: "Submit Form",
    redraw: "Re-draw",
    createMap: "Create Map",
    leave: "Leave",
    newNotification: "New Notification",
    newTaskGroup: "New Task Group",
    exit: "Exit",
    exitButton: "Please exit with the cancel button.",
    addQuestions: "Add Questions",
    saveChanges: "Save Changes",
    createNewInternalOparation: "Create New Internal Operation",
    previousOperation: "Previous Operation",
    nextOperation: "Next Operation",
    reset: "Reset",
    sendToQualityControl: "Approve Sale & Send to Quality Control",
    resetSensorSettings: "Reset Sensor Settings",
    saveEndExit: "Save and Exit",
    cancelAndQuit: "Cancel & Quit",
    makeSettings: "Make Settings",
    finish: "Finish",
    buyOperationCredits: "Buy Additional Operation Credits",
    buyDepartmentCredits: "Buy Additional Department Credit",
    update: "Update",
    starts: "Start",
  },
  colors: {
    blue: "Blue",
    red: "Red",
    gray: "Gray",
    orange: "Orange",
    purple: "Purple",
    green: "Green",
  },
  step: {
    title: "Step",
    updateSupplier: "Update Supplier",
    noDataFound: "No Data Found !",
    nullDepartment: "No departments found. Redirecting to add a department.",
  },
  kanban: {
    pendingJobs: "Waiting Works",
    activeJobs: "Active Works",
    finishedJobs: "Finished Works",
    activeJobMap: "Active Job",
    activeWorks: "After the active works are completed, they are recorded on the chart.",
    noActivePersonel: "No active personnel",
    noActiveJob: "No active job",
    losses: "Losses",
    barChart: "Bar Chart data not found",
    newShift: "New Shift",
    previousShift: "Previous Shift",
    nextShift: "Next Shift",
    testShift: "Test Shift",
    noShiftFound: "No Shift Found",
    viewTimeline: "Please add a shift to be able to view the timeline list.",
    designYourMap: "Design your own map according to your preferences.",
    map: "Map",
    editYourMap: "You can edit your map as you like or draw it from scratch. The changes you make will not affect other users.",
  },
  fileInput: {
    uploadImageError: "Please upload a valid image file (JPEG, JPG veya PNG)",
    profilSubTitle: "Click to upload picture.",
    uploadHeaderPicture: "Profile Picture",
    updatePicture: "Update picture",
    documentSubTitle: "Click To Upload Document",
    uploaderHeaderDoc: "Document",
    uploadAvatar: "Upload Avatar",
    changePic: "Change Picture",
    cropPicture: "Crop Picture",
    removePicture: "Remove Picture",
    uploadFile: "Upload File",
    uploaderFile: "Upload File",
    uploadPicture: "Upload a Picture",
    //Equipment
    equipmentPicture: "Equipment Picture",
    equipSubTitle: "Click to upload profile picture.",
  },
  timeLine: {
    title: "Time Line",
  },
  login: {
    title: "Login",
    subTitle: "You can log in for UPU",
    email: "E-mail",
    password: "Password",
    sublink: "Want to learn about meet with",
    loginTitle: "Log in",
    welcomeMessage: "Welcome back! Please enter your details.",
    selectCompany: "Welcome back! Please select a company.",
    wrongCredentials: "Email or Password is Incorrect!",
    tryAgain: "A problem was encountered, please try again.",
    rememberMe: "Remember me",
    forgotPassword: "Forgot password",
    dontHaveAccount: "Don’t have an account?",
    noWorries: "No worries, we’ll send you reset instructions.",
    backToLogin: "Back to log in",
    usernotFound: "Warning.! not found user",
    mailSent: "Successful, check your e-mail address. You are redirected to the login page",
    machineQuote:
      "You will be able to manage your maintenance in addition to accessing performance data, energy analysis, and loss information for all of your machines.",
    productQuote:
      " You will be able to take advantage of all upu.io's features, which cover every aspect of your production and include production tracking, supply management and cost analysis.",
    personQuote:
      "In addition to ready-made modules such as messaging, surveys and suggestions, you will have access to all of your employees' performance data.",
  },
  onboard: {
    title: "Welcome to",
    subTitle: " You need to complete the necessary steps to create your registration and use upu. Click the “Start” button to complete the steps.",
  },
  personelInfo: {
    title: "Personal Information",
    name: "Name",
    surname: "Surname",
    email: "E-Mail",
    password: "Password",
  },
  addCompany: {
    title: "Create Company",
    update: "Update your company's picture and information.",
    uploaderHeader: "Upload Company Logo",
    subtitle: "Click to add your logo",
    companyName: "Company Name",
    company: "Company",
    companyAdress: "Company Adress",
    companyMail: "Company Mail",
    companyNumber: "Company Phone",
    companyFiscalNumber: "Fiscal Year Start Date",
    contactEmail: "Contact Email",
    contactName: "Contact Name",
    contactNumber: "Contact Number",
    taxOffice: "Tax Office",
    taxNumber: "Tax Number",
    status: "Solutions",
    country: "Country",
    timeZone: "Time Zone",
    equipmentCredit: "Equipment Credit",
    personCredit: "Person Credit",
    fiscalYear: "Start of fiscal year",
    updateCompany: "Update Your Company Information",
    goToSetup: "Go To Setup",
    kwhUnit: "kVAh Unit Price",
    forUnitPrice: "Upu.point for Unit Price",
    cCount: "C-Count Coefficient",
    changeCompany: "Company has been changed.",
    createMapPosition: "You need to create a map to position your equipment according to the company's field.",
    companyLogo: "Company Logo",
    switch: "Switch",
    switchCompany: "Switch Company",
    switchTitle: "Select the company(ies) to which you want to switch.",
  },
  addPersonal: {
    noLimit: "Personnel limit is full.",
    personalAddedButLimit: "Personel added but limit is full.",
    mailInUse: "This mail is already in use.",
    title: "Add Personnel",
    uploaderHeader: "Add Process",
    searchInput: "Search Personnel",
    roles: "Roles",
    //Card Header
    personCredit: "User Credit",
    creditTitle: "You need credits to add more personnel.",
    deletedPersonalMessage: "Personel deleted.",
    //No person
    subtitle: "You can add employees working in your company. ",

    //Console Modal

    //Add Person Modal
    modalTitle: "Add New Personnel",
    selectAut: "Select Authorization",
    selectDepartment: "Department",
    personId: "Personal ID",
    name: "Name",
    surname: "Surname",
    email: "E-Mail",
    emplyDate: "Employment Date",
    upuPoint: "Upu Point",
    totalCostPerson: "Total Cost of Personnel",
    explanation: "Explanation",

    //Yetenek ve Proses Seçimi
    abilityTitle: "Ability and Process Selection",
    personSalary: "Person Salary",
    talent: "Talent",
    point: "Point",
    date: "Date",
    addTalent: "Add Talent",
    noTalent: "No Talent Card",
    talentMessage: "Click the Add New Talent button for the talent and operation information you can assign to your personnel.",
    editYourStaff: "Edit your staff roles or purchase new staff credits.",
    buyStaff: "Purchase a Personal Loan",
    //Kazanılmış Eğitim Başarı
    successTitle: "Earned Education - Award - Success",
    success: "Success",
    soot: "Soot",
    certificate: "Certificate No",
    scored: "Scored",
    fullScore: "Full Score",
    changePic: "Change Picture",

    //Modal
    updatePersonal: "Update Personnel",
    addNewPersonal: "Add New Personnel",
    deletePersonal: "Delete Selected Personnel?",
    deletePersonalWarning: "The personal you choose and their data will be deleted, are you sure?",
    updatedPersonalMessage: "Personnel updated successfully",
    addedNewPersonalMessage: "Personnel added successfully",
    //Tabs
    personalInfo: "Personnel Info",
    personalTalent: "Personnel Talents",
    personalSuccess: "Personnel Success",
    //TalentCard
    approvingUser: "Approving by",
    talent: "Talent",
    selectProcess: "Select process",
    deleteTalent: "Delete Selected Talent?",
    deleteTalentMessage: "The Talent you selected will be deleted, are you sure?",
    //SuccessCard
    noSuccess: "No Success Card",
    addSuccess: "Add Success Card",
    successMessage: "Click the 'Add New' button for success and education information you can assign to your personnel.",
    uploadCertificate: "Upload Certificate",
    removeCertificate: "Remove Uploaded Certificate",
    deleteSuccess: "Delete Selected Success?",
    deleteSuccessMessage: "The Success you selected will be deleted, are you sure?",
    departmentAdded: "*You can assign your personnel as the head of the department you added.",
    departmentEmpty: "department is empty.",
    addPersonnel: "You can add your staff working in your company and assign them to the departments you create.",
  },
  addProcess: {
    noLimit: "Operation limit is full.",
    noLimitExternal: "External Operation limit is full.",
    processAddedButLimit: "Operation added but limit is full.",
    title: "Add Operation",
    uploaderHeader: "Add Operation",
    detail: "Operation Detail",
    equipments: "Equipments",
    reset: "Data",
    process: "Process",
    //ProcessModal
    updateProcess: "Update Operation",
    decomposition: "Decomposition Operation",
    assembly: "Assembly Operation",
    conversion: "Conversion Operation",
    supporter: "Supporter Operation",
    addNewProcess: "Add New Operation",
    name: "Process Name",
    subTitle: "Select Operation Manager",
    searchPlaceholder: "Search Operation ",
    deleteProcess: "Delete Selected Operation?",
    deleteProcessWarning: "The operation you choose and their data will be deleted, are you sure?",
    updatedProcessMessage: "Operation updated successfully",
    addedNewProcessMessage: "Operation added successfully",
    deletedProcessMessage: "Operation deleted",
    //No Prcoess
    noTitle: "You can add your in-house operations such as milling, turning, cutting and categorize the operations you have added according to your equipment.",
    //Modal
    processName: "Operation Name",
    type: "Operation Type",
    workTolerance: "Work Tolerance",
    utilization: "Capacity Utilization Rate",
    measurable: "Data Health",
    qualityRate: "Quality Rate",
    performance: "Performance",
    avaibility: "Avaibility",
    availability: "Availability",
    rate: "Rate",
    stationTolerance: "Station Tolerance (sn)",
    stationTolerances: "Station Tolerance",
    thisFieldCannot: "This field cannot be left blank",
    Availability: "Availability",
    Performance: "Performance",
    Utilization: "Capacity Utilization Rate",
    searchIcon: "Search Icon",
    macAddress: "Mac-address",
    //Tabs
    processChart: "Operation Chart",
    processInfo: "Operation Info",
    processIcon: "Operation Icon",
    responsibleUser: " User",
    noUser: "No Responsible User",
    equipmentName: "Equipment Name",
    stationName: "Station Name",
    operatorName: "Operator Name",
    shifts: "Shifts",
    oee: "OEE",
    qualityrate: "Quality Rate",
    userList: "User List",
    nodeList: "Node List",
    externalStep: "You can carry out your company's external operations.",
    equipmentStep: "You can add the equipment you have in your company and include your equipment in the operations you have previously created.",
    addShiftStep: "By adding your company's working hours, breaks and holidays to the system, you can follow them from the calendar and inform your staff.",
    stepCompany: "You have defined your company to upu.io. You can create a digital twin of your company by pressing the start button.",
    editOperation: "Edit Operation",
    operationDetail: "Operation Detail",
  },

  addProcessResponsible: {
    title: "Add Operation Responsible",
    departmentTitle: "Add Department Responsible",
    chooseResponsible: "Add Responsible for Operation",
    chooseResponsibleDepartment: "Add Responsible for Department",
  },

  equipmentTypes: {
    automatic: "Automatic Equipment",
    manuel: "Manuel Equipment",
    passive: "Passive Equipment",
    assembly: "Montage Equipment",
    handTools: "Hand Tool Equipment",
  },
  addEquipment: {
    allEquipments: "All Equipments",
    responsibleEquipment: "Responsible Equipment",
    serialNo: "Serial No",
    title: "Add Equipment",

    //With Equipment
    undefinedTitle: "Undefined Equipment",
    undefinedSub: "Undefined",
    definedTitle: "Defined Equipment",
    definedSub: "Defined",
    //Equipment Modal
    updateEq: "Update Equipment",
    addEqu: "Add New Equipment",
    brand: "Brand",
    modal: "Modal",
    process: "Process",
    productYear: "Year of Production",
    currentValEq: "Current Value",
    upuPoint: "UPU Point",
    addManualEvent: "Add Manual Event",
    eventStartEnd: "Event Start & End",
    startDateAndTime: "Start Date & Time",
    endDateAndTime: "End Date & Time",
    theStartOfCannot: "The start date cannot be earlier than the minimum required value",
    theEndDateMustBe: "The end date must be between the minimum and maximum values",
    theEndDateCannotBe: "The end date cannot be earlier than the start date",
    saveStartEndTime: "Save Start and End Time",
    minuteCost: "Minute Cost",
    scoreboardCoef: "Scoreboard Coefficient",
    depreciationRate: "Depreciation Rate",
    usefulLife: "Useful Life",
    workedPeople: "No. of People",
    sequenceNo: "Serial Number",
    deletedEqu: "Equipment deleted",
    qrCode: "QR Code",

    //No equipment
    noTitle: "You can add the equipment in your company and then include your equipment in the operations you previously created.",

    //searchınput
    searchNode: "Search Equipment",
    searchProduct: "Search a product",

    //node credit
    outOfCredit: "No Credit Left",
    outOfCreditSub: "You do not have an equipment node. You can buy Nodes to add new equipment.",
    buyCredit: "Buy Credit",

    //delete node
    deleteNode: "Delete Selected Equipment?",
    deleteNodeMessage: "The equipment you choose and their data will be deleted, are you sure?",

    //modal
    updatedNode: "Equipment Updated",
    addedNode: "Equipment Identified",
    addedFailedNode: "Equipment Identification Failed",
    defineEquipment: "Define Equipment",
    nodeId: "Node ID",
    equipmentModel: "Equipment Model",
    equipmentSerialNo: "Equipment Serial No",
  },

  addShift: {
    title: "Add New Shift",
    updateShift: "Update Shift",
    //No shift
    noTitle: "Your company's work areas include breaks and vacation systems, you can follow the calendar and be aware of your staff.",

    //Shift Modal
    shiftTitle: "Title",
    supervisor: "Shift Responsible",
    startTime: "Start Time",
    dayStartTime: "Day Start Time",
    shiftName: "Shift Name",
    shiftDayName: "Shift Day Name",
    createNewDailyShift: "Create New Daily Shift",
    addShifts: "Add Shift",
    registeredShifts: "Registered Shifts",
    addNewDailyShift: "Add New Daily Shift",
    standartShifts: "Standart Shifts",
    nonStandartShifts: "Non-Standard Shifts",
    editShift: "Edit Shift",
    removeShift: "Remove Shift",
    calendarType: "Calendar Type",
    selectOneOrMore: "Select one or more dates.",
    selectDateRange: "Select date range.",
    selectedDates: "Selected Dates",
    informations: "Information",
    enterTheInformation: "Enter the information for the first shift of the day.",
    enterTheSecondInformation: "Enter the information for the second shift of the day.",
    enterTheThirdInformation: "Enter the information for the third shift of the day.",
    planShiftDay: "Plan Shift Day",
    editHoliday: "Edit Holiday",
    startEndTimes: "Start & End Time",
    shiftColor: "Shift Color",
    defineYourBreaks: "Define your breaks within the shift.",
    endTime: "End Time",
    Stopovers: "Stopovers",
    stopTitle: "Stopover Title",
    daysTitle: "Days",
    repeatShift: "Repeat of Shift",
    endShift: "Does your shift have an end date?",
    never: "Never",
    color: "Calendar Color",

    //tabs
    shiftInfo: "Shift Info",
    breaks: "Breaks",

    //search
    searchShift: "Search Shift",

    //delete shift
    deleteShift: "Delete Selected Shift?",
    deleteShiftMessage: "The shift you choose and their data will be deleted, are you sure?",
    deletedShift: "Shift deleted",

    //
    addedShift: "Shift Added",
    updatedShift: "Shift Updated",

    //breaks
    addBreak: "Add New Break",
    noBreak: "No Break Card",
    noBreakSub: "Click the “Add New” button for activities you can add to your shifts.",
    deleteBreakCard: "Delete Selected Break?",
    deleteBreakCardSub: "The break you choose will be deleted, are you sure?",
    updateYourCompany: "Update your company's shift and vacation information.",
    addNewHoliday: "Add New Holiday",
    holidays: "Holidays",
    activateHoliday: "Activate Holiday",
    feastOfRamadan: "Feast of Ramadan",
    addHoliday: "Add Holiday",
    holidayName: "Holiday Name",

    //update shift type modal
    weeklyOrDaily: "Do you want to update the selected shift weekly or daily?",
    chooseWeeklyOrDaily: "Weekly or Daily Update",
    updateDaily: "Update Shift Daily",
  },

  //congrats
  congrats: {
    title: "installation completed!",
    subTitle: "You have defined your company to upu.io. You can create a digital twin of your company by pressing the Start button.",
  },

  reports: {
    teorical: "Teorical",
    planned: "Planned",
    measurable: "Data Health",
    avaibility: "Avaibility",
    performance: "Performance",
    upuPoint: "UPU Point",
    kwh: "kVAh",
    quality: "Quality",
    utilization: "Capacity Utilization Rate",
    cross_selection: "Cross Selection",
    quality_loss: "Quality Loss",
    performance_loss: "Performance Loss",
    work_loss: "Work Loss",
    data_loss: "Data Loss",
    break_loss: "Break Loss",
    dataNotAvailable: "Data not available",
    node: "Equipments",
    user: "Users",
    process: "Process",
    plannedTime: "Planned Production Time",
    measurableTime: "Measurable Production Time",
    theme: "Theme",
    chooseInterface: "Choose Interface Theme",
    lightTheme: "Light Theme",
    darkTheme: "Dark Theme",
    willBeAddedSoon: "Will Be Added Soon",
  },

  validation: {
    empty: "Cannot be empty",
    string: "Input must be in string format",
    email: "Input must be in email format",
    number: "Input must be in number format",
    max30: "30 is the maximum value for this input",
    min0: "0 is the minimum value for this input",
    date: "Input must be in date format",
    max100: "100 is the maximum value for this input",
  },

  //maintinance

  maintinance: {
    title: "maintinances for compnany",
  },

  shift: {
    title: "shifts for company",
  },

  gauge: {
    allData: "All Data Reset",
    kpi: "Delete Target",
    prevData: "Previous Data",
    nextData: "Next Data",
    getData: "Get Data",
    resetKpiValue: "Reset KPI Values",
    allDataDesc: "Resets all your calculated data",
    kpiDesc: "Deletes your target data",
    prevDataDesc: "Shows the previous day data",
    nextDataDesc: "Shows the next day data",
    kpiAdi: "KPI Adi",
    availability: "Availability",
    utilization: "Capacity Utilization Rate",
    performance: "Performance",
    returnNextData: "Return Next Data",
    allDay: "All Day",
    returnPreviousData: "Return Previous Data",
    resetAllData: "Reset All Data",
    nextDatass: "Next Data",
    resetKpi: "Reset Kpi",
    plannedJunction: "Planned Junctions",
  },

  group: {
    questionLeaveGroup: "Leave Group ?",
    textLeaveGroup: "Are you sure you want to leave the group ?",
    groupPhoto: "Group Photo",
    taskGroupName: "Task Group Name",
    groupName: "Group Name",
  },

  department: {
    searchDepartment: "Search Department",
    title: "Add Department",
    subsTitle:
      "Before adding the personnel working in your company; You can provide ease of management by creating departments such as accounting, human resources, and production.",
    modalTitle: "Add New Department",
    textDepartment: "The department you selected and its data will be completely deleted, are you sure?",
    questionDepartment: "Delete Department",
    deleteDepartment: "Delete",
    updateDepartment: "Update Department",
    departmentName: "Department Name",
    deleteDepartments: "Delete Department?",
    appointSelectedDepartment: "Appoint head of the selected department.*",
    departmentDelete: "Delete Department",
  },
  phone: {
    phoneNumber: "Phone Number",
    contactNumber: "Contact Number",
  },
  addDepartment: {
    createdDepartment: "Department crated",
    updatedDepartment: "Department updated",
    deletedDepartment: "Department deleted",
    noLimit: "Department limit is full",
  },
  tasks: {
    inProgg: "In Progg.",
    total: "Total",
    todo: "To-Do",
    done: "Done",
    approved: "Approved",
    move: "Move",
    expired: "Expired",
    noTask: "No Task For Now!",
    confirm: "Confirm",
    alreadyConfirmed: "This task is already confirmed",
    decline: "Decline",
    alreadyDeclined: "This task is already in To-Do's",
    edit: "Edit",
    delete: "Delete",
    taskAdded: "Task successfully added",
    addFile: "Add File",
    save: "Save",
    addParticipants: "Add Participants",
    allTasks: "All Tasks",
    myTasks: "My Tasks",
    all: "All",
    taskPlaceholder: "Write the task subject here.",
    tasks: "Tasks",
    comment: "Comments",
    attachments: "Attachments",
    activity: "Activity",
    view: "view",
    labels: "Labels",
    noTaskLimit: "Task limit is full.",
    taskCreated: "Task created.",
  },
  chat: {
    message: "Message",
    personalMessage: "Personal Message",
    groups: "Groups",
    task: "Task",
    notification: "Notification",
    score: "Score",
    attend: "New Permission",
    poll: "Poll",
    search: "Search",
    noMessage: "No message, Start one now!",
    clickToSendMessage: "Click to send message",
    admin: "Admin",
    operators: "Operators",
    newMessage: "New Message",
    newGroup: "New Group",
    choose: "Choose Everyone",
    chooseDepartment: "Choose a department",
    chooseRead: "Read All",
    pendingSurveys: "Pending Poll",
    noPendingPoll: "No Pending Poll !",
    surveyEndDate: "Poll End Date",
    permissionRequest: "Permission Request Form",
    newAnnouncement: "New Announcement",
    selectAllPerson: "Select All Personnel",
    sendAnnouncement: "Send Announcement",
    selectUser: "Select User",
    announcementDetail: "Announcement Detail",
    announcementTitle: "Announcement Title",
    addAnnouncement: "Add Announcement",
    addQuestion: "Add Question",
    reachedMaxLimit: "You have reached the max limit",
    maxLimit: "Max limit reached",
    sendPoll: "Send Poll",
    permissionType: "Permission Type",
    permissionRange: "Permission Range",
    waitingPermission: "Waiting Permission",
    personToAskPermission: "Person to ask for Permission",
    dayTime: "Day / Time",
    permissionStatement: "Permission Description",
    startingDate: "Starting Date",
    endDate: "End Date",
    personAuthorized: "Person To Be Authorized",
    newPool: "Create New Poll",
    titlePool: "Poll Title",
    editPhoto: "Edit Photo",
    whoSee: "Those Who See",
    archives: "Archive",
    minimumValue: "Minimum Value",
    totalValue: "Total Value",
    question: "Question",
    answer: "Answer",
    addNewTask: "Add New Task",
    newTask: "New Task",
    editTask: "Edit Task",
    participants: "Participants",
    otherUser: "Other Users",
    taskEndDate: "Task End Date",
    usersAdmin: "Participants/Admins",
    addGroupTitle: "Add Group Title",
    addNewContact: "Add New Contact",
    addNewGroup: "Create Task Group",
    today: "Today",
    thisMonth: "This Month",
    noToday: "No Data For Today !",
    skillCards: "All Skill Cards",
    notSkillCards: "Skill Card Not Found",
    checkOut: "Do you want to log out ?",
    noPoll: "No pending poll !",
    noPast: "No past poll !",
    pastPoll: "Past Poll",
    pollCompleting: "Thank you for completing the poll already.",
    userNotify: "Send the Announcement to",
    pollDelete: "Are you sure you want to delete the poll?",
    pollEnd: "Are you sure you want to end the poll?",
    terminate: "Terminate",
    pollAgain: "Are you sure you want to share the survey again?",
    publish: "Publish",
    pollEmpty: "Cannot create empty poll",
    taskApproved: "this task has already been approved",
    taskDone: "This task is already among the things to be done.",
    taskDeleted: "Task Deleted",
    taskUpdated: "Task Updated",
    notAuthorized: "You are not authorized for this operation",
    announcementCannot: "An announcement cannot be created without adding a user.",
    leftTheGroup: "Left The Group",
    pollCreated: "Poll created.",
    announcementCreated: "Announcement created",
    groupCreated: "Group Created",
    transaction: "The transaction was performed successfully",
    notTransaction: "Operation Failed",
    waiting: "Waiting",
    staffIdentityCard: "Staff Identity Card",
    nameSurnames: "Name Surname",
    role: "Role",
    equipmentCode: "Equipment Code",
    numberOfPersonnel: "Number of Personnel Able to Work",
    clickInstall: "Click to install",
    userInstall: "Click to add person",
    taskResponsible: "Task manager",
    deleteAll: "Delete All",
    addNewLabel: "Add New Label",
    addLabel: "Add Label",
    editLabel: "Edit Label",
    sendToPoll: "Send the poll to",
    taskHeader: "Task Header",
    groupMember: "Group Member",
    selectTaskAdmin: "Select as a task admin",
    newGroupTask: "New Group Task",
    archiveEmpty: "Archive is empty.",
    user: "User",
    labels: "Label",
    sort: "Sort",
    filter: "Filter",
    allLabels: "All Labels",
    moveCard: "Move Mission Card",
    taskNumber: "Task Number",
    deadLine: "Deadline",
    checkInCheckOut: "Check In - Check Out",
    permissions: "Permissions",
    permissionTab: "Permission",
    permissionApprovalForm: "Permission Approval Form",
    beginning: "Beginning",
    end: "End",
    hour: "Hour",
    time: "Time",
    day: "Day",
    breastfeedingBreak: "Breastfeeding break",
    sickLeave: "Sick leave",
    unpaidLeave: "6 months of unpaid leave after childbirth",
    maternityLeave: "Maternity leave",
    weekendLeave: "Weekend leave",
    nationalHoliday: "National holidays and public holiday leave",
    jobSearch: "Job search leave",
    startJob: "Start a Job",
    excusedAbsences: "Excused absences",
    permissionType: "Permission Type",
    activePermissions: "Active Permission",
    deniedPermission: "Rejected Permission",
    deletePermission: "Are you sure you want to delete this permission? This action cannot be undone.",
    approvedDetail: "Approved Permission Detail",
    rejectedDetail: "Rejected Permission Detail",
    startTime: "Start Time",
    endTime: "End Time",
    qualityControlResponsible: "Quality Control Responsible",
    noLimitPoll: "Poll limit is full.",
    pollAddedButLimit: "Poll added but limit is full.",
    comingSoon: "Coming soon...",
  },
  product: {
    deadline : "Deadline",
    createdDate: "Created Date",
    newExternal: "Add New External Operation",
    stockAndProducts: "Stock and Products",
    sendToOrder: "Send to Order",
    transitionWipOrder: "Transition from WIP to Order",
    sendToCompletedOrder: "Send the completed products to orders.",
    readyQuantity: "Ready Quantity",
    scrapQuantitys: "Scrap Quantity",
    openOrder: "Open Orders",
    orderNoCustomer: "Order No / Customer",
    quantityToBeSent: "Quantity to be Send",
    stock: "Stock",
    total: "total",
    totalStock: "total stock",
    totalOrder: "total order",
    fromStock: "from stock",
    totalScrap: "total scrap",
    deliveryDate: "delivery date",
    orId: "Or.ID",
    sorted: "Sort",
    planAndWork: "Drag and drop to plan",
    newPlanned: "Planned",
    activeWorkss: "active work",
    earnedPoints: "Earned Points",
    totalWorkTime: "Total Work Time",
    workTime: "Work Time",
    quantityMade: "Made Quantity",
    qualityControlResponsible: "Quality Control Responsible",
    qualityControlAttachment: "Quality Control Attachment",
    allOperations: "All operations outside your production area.",
    productionArea: "Converting, separating or combining operations included in your production area.",
    totalLimit: "Total Limit",
    filters: "Filter",
    listed: "listed",
    metricTon: "Metric Ton",
    ounce: "Ounce",
    gallon: "Gallon",
    squareMetre: "Square Metre",
    squareMilimetre: "Square Milimetre",
    squareInch: "Square Inch",
    package: "Package",
    box: "Box",
    timeStarted: "time started",
    itemsPerpage: "items per page",
    qualityQuantitity: "Quality Quantity",
    timeStarteds: "start",
    timeEnds: "end",
    equipmentDetails: "Equipment Detail",
    personnelDetail: "Personnel Detail",
    actives: "Active",
    waiting: "Pending",
    pasted: "Past",
    purchaseds: "purchased",
    planPurchase: "Plan Purchase",
    specifyPlanning: "Specify the planning details according to the requests.",
    requests: "Requests",
    requestedQuantity: "Request. Quantity",
    plannedQuantityss: "Planned Quantity",
    totalQuantityss: "Total Quantity",
    unitCost: "unit cost",
    orderNoStockRequest: "Order No / Stock Request",
    orderQuantityss: "Order Quantity",
    creationDates: "Creation Date",
    qualityNew: "Qlty R.",
    selectPersonnel: "Select a staff to start the task.",
    plannedNew: "Planned",
    materialSupplier: "Material Suppliers",
    serviceSupplier: "Service Suppliers",
    purchaseDetails: "Purchase Details",
    purchaseForm: "Purchase Form",
    startPurchase: "Start Purchase",
    editRawMaterial: "Edit Raw Materials / Consumption",
    crateRawMaterialCons: "Create New Raw Materials / Consumption",
    rawMaterialPhoto: "Raw Material / Consumption Photo",
    rawMaterialConsName: "Raw Material / Consumption Name",
    stockCodeRawMaterial: "Stock Code",
    operationCode: "Operation Code",
    unplanned: "Unplanned",
    rawMaterialConstType: "Raw Material / Consumption Type",
    associatedGroup: "Associated Supplier Group",
    unitPriceRawMaterial: "Unit / Price",
    quantitys: "Quantity",
    currentStockQuantity: "Current Stock Quantity",
    criticalStockQuantity: "Critical Stock Quantity",
    targetedQuantitys: "Quantity",
    productss: "Product",
    orderCodeNew: "order code",
    customerCompany: "Customer Company",
    unitsCost: "Unit Cost",
    totalCost: "Total Cost",
    approvedQuantitys: "Approved Quantity",
    rejectedQuantityss: "Rejected Quantity",
    activePurchases: "Active and Planned Purchases",
    qualityPendingPurchases: "Completed & Quality Pending Purchases",
    qualityCompletedQuality: "Purchases with Completed Quality Control",
    purchaseApproval: "Purchase Approval",
    addTheSupplier: "Add the supplier and quantity from which to purchase",
    sameColumnWarning: "Cannot move within the same column",
    columnDragDropWarning: "Invalid move",
    estimatedDeliveryDate: "Estimated Delivery Date",
    totalStocks: "total stock",
    rawMaterials: "raw material",
    receivedQuantity: "Received Quantity",
    qualityAttachmentOne: "Quality Attachment 1",
    qualityAttachmentTwo: "Quality Attachment 2",
    attachment: "Attachment",
    dontZeroPieces: "Pieces value cannot be zero or negative.",
    manage: "Manage",
    productDetail: "Product Detail",
    junctionManually: "Add Event Manually",
    junctionEditManually: "Edit Event Manually",
    breakDateAndTime: "Break Date & Time",
    updateBreakTime: "Update Break Time",
    saveBreakTime: "Save Break Time",
    addBreaks: "Add Break",
    eventNew: "Event",
    producedProduct: "Produced Product Quantity",
    qualityControlNew: "Quality Control Forms",
    quantityRejectedProduct: "Quantity Rejected Products",
    junctionSubtitile: "Your junction card is ready. Choose an action.",
    entireOperation: "Finish Entire Operation",
    junctionSubtitile: "Your junction card is ready. Choose an action.",
    workType: "Work Type",
    scrapQuantity: "Scrap Quantity",
    sortOrder: "order",
    email: "E-Mail",
    productionType: "Production Type",
    supplierList: "Supplier List",
    createNewSupplier: "Create New Supplier",
    addSupplier: "Add Supplier",
    rawMaterialss: "Raw Materials",
    unitPriceAndUnit: "Unit Price & Unit",
    totalShiftTime: "Total Shift Time",
    selectedTime: "Selected Time",
    finishedPiece: "Finished Pieces",
    internalOperationsQualityControl: "Internal Operations Quality Control",
    rejectedPieces: "Rejected Pieces",
    high: "High",
    low: "Low",
    hours: "h",
    timeRange: "Time Range",
    chooseStartDate: "Choose Start Date",
    chooseEndDate: "Choose End Date",
    applyFilter: "Apply Filter",
    deliveryDates: "deliv. d.",
    orderDates: "order d.",
    jobStartDate: "Job Start Date",
    jobEndDate: "Job End Date",
    activeAcceptedPiece: "act. acc. qty.",
    activeRejectedPiece: "act. rej. qty.",
    technicalPicture: "Technical Picture",
    order: "Order",
    orders: "Orders",
    stockNo: "Stock No",
    takeBreak: "Take a Break",
    resumeWorkAction: "Resume Work Action",
    theOngoing: "The ongoing work action can be temporarily paused. All tasks will be automatically terminated at the end of the shift.",
    resumeWorkPaused: "Resume the work you paused.",
    rawMaterialNames: "Raw M. Name",
    rawMaterialStock: "Raw M. Stock",
    pcs: "qty",
    pcss: "-qty",
    stockId: "Stock ID",
    endDates: "end date",
    startDates: "start date",
    totalRejectedPiece: "tot.rej.qty.",
    operationTypes: "Operation Type ",
    orderId: "Order ID",
    minPieceAvg: "min-qty-avg",
    suplierss: "Supply",
    min: "min",
    second: "s",
    moveJunction: "Move Junctions",
    day: "day",
    opType: "Op. Type",
    estTime: "Est. Time",
    rawMaterialTypess: "Raw M. Type",
    rawMaterialSourcess: "Raw M. Source",
    previousIncoming: "Pre.Incom.",
    fromPrevious: "From the Previous Operation",
    fromPreviousSecond: "Quantity from Previous Operation",
    madeTotal: "Made / Total Quantity",
    totalpcs: "total qty",
    previousIncome: "Pr. Income",
    addRawMaterial: "Add Raw Material",
    unitQuantity: "Unit Quantity",
    orderQuantity: "Order Quantity",
    updateToleranceValue: "Update Tolerance Value",
    editStock: "Edit Stock Quantity",
    stockEdit: "Stock Edit",
    closestDelivery: "Closest Delivery",
    ready: "Ready",
    currentStock: "Current Stock",
    increaceAmount: "Increaseable Amount",
    selectProduct: "Select Product",
    decreaceAmount: "Decrementable Amount",
    orderQuantitys: "order quantity",
    pieceModal: "Piece",
    material: "Material",
    flex: "Flex",
    raw: "Raw",
    flexRawMaterialCard: "Flex Raw Mater.",
    qtyMade: "qty. made",
    qtyRemaining: "qty. remaining",
    lastProcess: "Last Pr.",
    qtyReserveStock: "qty. reserved stock",
    reservedStockss: "reserved stock",
    freeStock: "free stock",
    qtyFreeStock: "qty. free stock",
    clearFilters: "Clear Filters",
    supplierGroups: "Supplier Groups",
    priceRange: "Price Range",
    atLeast: "At Least",
    atMost: "At Most",
    thisFieldRequired: "This field is required.",
    reserveStockNew: "Reserve Stock",
    lastTransaction: "Last Transaction",
    increasing: "Increasing",
    decreasing: "Decreasing",
    nearestDate: "Nearest Date",
    latestDate: "Latest Date",
    cycleTime: "Cycle T. (min)",
    cycleTimes: "Cycle T. (day)",
    target: "target",
    applieds: "applied",
    backlog: "Backlog",
    planneds: "Planned",
    semiProduct: "Semi Product",
    inSupply: "In Supply",
    remainings: "Remain.",
    appliedss: "Applied",
    rawMaterialsNew: "R.Material",
    qualityQty: "Quality (qty)",
    qtySupply: "qty. supply",
    qtyCurrentStock: "qty. cur. stock",
    cycleTimeStock: "Cycle Time (min)",
    applieds: "applied",
    totalQuantity: "Total Quantity",
    remainedQuantity: "Remained Quantity",
    production: "Open",
    workOrder: "W. O.",
    orderQty: "In Order",
    inStock: "In Stock",
    inStocks: "In Stock",
    workOrderQty: "Work O.(qty)",
    remainder: "remain.",
    intermediProduct: "Int. Product",
    waitings: "Waiting",
    reject: "Reject",
    setupTime: "Setup T.(min)",
    cycleTimeStockDay: "Cycle Time (day)",
    intermediateStock: "Intermediate Stock (qty)",
    targetDeliveryTime: "tar. del. time",
    urgencyLevel: "urgency level",
    opens: "Open",
    plannedQuantity: "planned quantity",
    numberOfProduct: "Number of parts produced in this operation",
    done: "Applied",
    pieceConfirm: "pieces do you confirm?",
    junctionList: "Junction List",
    editJunction: "Edit Junction",
    junctionType: "Junction Type",
    planView: "Plan View",
    stationAndWorkActions: "Stations & Work Actions",
    deliveryDate: "delivery date",
    jobStartTime: "Job Start Time",
    jobEndTime: "Job End Time",
    rawMaterialTypes: "R.Material Type",
    rawMaterialSources: "R.Material Source",
    editStockQuantity: "Edit Stock Quantity",
    increaseWithPurchase: "Increase with Purchase",
    increaseManually: "Increase Manually",
    decreaseManually: "Decrease Manually",
    maxStock: "Maximum Stock",
    minStock: "Minimum Stock",
    editRawMaterials: "Edit Raw Material",
    reservedStock: "Reserved Stock",
    availableStock: "Available Stock",
    criticalStock: "Critical Stock",
    currentPrice: "Current Price",
    highestPrice: "Highest Price",
    lowestPrice: "Lowest Price",
    averageStockPrice: "Average Stock Price",
    totalAllPurchases: "Total Price (All Purchases)",
    rawMaterialStockMovements: "Raw Material Stock Movements",
    supplyRequest: "Supply Request",
    reserve: "Reserved",
    manual: "Manual",
    entry: "Entry",
    exit: "Exit",
    ordersAndCustomers: "Orders & Customers",
    customerList: "Customer List",
    customerGroup: "Customer Group",
    deliveryDateProduct: "Delivery Date",
    pieces: "quantity",
    quantityCost: "Quantity-Cost",
    capacityUtilizationRateNew: "Capacity U.R.",
    addProduct: "Add Product",
    addPersonnels: "Add Personnel",
    saveGroup: "Save Group",
    createCustomerGroup: "Create Customer Group",
    createCustomer: "Create Customer",
    billingAddress: "Billing Address",
    addCustomer: "Add Customer",
    customerLogo: "Customer Logo",
    customerName: "Customer Name",
    authorizedPerson: "Authorized Person",
    letBilling: "Let the billing address be the same as the delivery address.",
    deliveryAddress: "Delivery Address",
    customer: "Customer",
    selectCustomer: "Select Customer",
    customerOrderCode: "Customer Order Code",
    allProduct: "All Products",
    addedProducts: "Added Products",
    productsss: "product",
    totalAmount: "Total Amount",
    totalSales: "Total Sales",
    addProductt: "Add Product",
    customerStockCode: "Customer Stock Code (optional)",
    unitSalePrice: "Unit Sale Price",
    unitSale: "Unit Sale",
    remove: "Remove",
    passives: "Passive",
    operationType: "Operation Type",
    activeApprove: "active approved quantity",
    totalRejectedQuality: "total rejected quantity",
    targetMin: "target min",
    estimatedTimes: "Estimated Time (second)",
    averageActual: "Average Actual Time",
    approvedQuantity: "Approved Quantity",
    rejectedQuantitys: "Rejected Quantity",
    eventss: "Events",
    qualityControlRate: "Quality C. Rate",
    newAssembly: "Create New Assembly Product",
    createNewProduct: "Create New Product",
    newProduct: "New Product",
    createProduct: "Create Product",
    pendingWorks: "Pending Works",
    pastWorks: "Past Works",
    applied: "Applied",
    defineLoss: "Define Loss",
    amountTobeUsed: "Quantity to be Used per Unit",
    purchased: "Purchased",
    equipment: "equipment",
    qty: "qty",
    activeAndPlannedName: "Active & Planned Works",
    orderDetail: "Order Detail",
    deleteProduct: "Delete Product",
    rawMaterialList: "Raw Material List",
    flexRawMaterialList: "Flex Raw Material List",
    pendingPurchases: "Pending Purchases",
    productSalesApproval: "Product Raw Material Sales Approval",
    createRawMaterial: "Create New Raw Material",
    createFlexRawMaterial: "Create Flex Raw Material",
    rawMaterialName: "Raw Material Name",
    unit: "Unit",
    units: "unit",
    price: "Price",
    prices: "price",
    purchases: "Purchase",
    type: "Type",
    minAvg: "min avg",
    tgtMin: "tgt min",
    plannedNews: "Planned",
    personnelWorkTime: "Personnel Work Time",
    createNewRawMaterial: "Create New Raw Material",
    descriptionOptionel: "Description (optional)",
    controlForm: "Control Form",
    approvalForm: "Approval Form",
    completedNew: "Completed",
    allNew: "All",
    activeTasks: "Active Jobs",
    stockOperation: "Stock Operation",
    completedPendingJobs: "Completed & Quality Pending Jobs",
    remainingFromOtherDays: "Remaining from Other Days",
    completedJobs: "Quality Control Completed Jobs",
    workOrders: "Work Request",
    delayed: "Delayed",
    targetMinActual: "tgt min-avg",
    targetMinActualss: "tgt-avg",
    minQtyAvg: "min-qty-avg",
    rejectionForm: "Rejection Form",
    sendQualityForm: "Send Quality Form",
    salesConfirmation: "Sales Confirmation",
    rawMaterialImage: "Raw Material Image",
    rejectSale: "Reject Sale",
    approveSale: "Approve Sale",
    inComingQuantity: "Number of Incoming Products",
    rejectedQuantity: "Number of Rejected Products",
    chooseOperationType: "Choose Operation Type",
    internalOperation: "Internal Operation",
    externalOperation: "External Operation",
    externalOperations: "External Operations",
    editCustomer: "Edit Customer",
    editExternalOperation: "External Operation Edit",
    addNewInternalOperation: "Add New Internal Operation",
    addNewExternalOperation: "Add New External Operation",
    newExternalOperationStep: "New External Operation Step",
    newInternalOperationStep: "New Internal Operation Step",
    createNewOrder: "Create New Order",
    newOrder: "New Order",
    operationDetail: "Operation Detail",
    operation: "Operation",
    seperationOperation: "Separation Operation",
    unificationOperation: "Unification Operation",
    transformationOperation: "Transformation Operation",
    productImage: "Product Image",
    productName: "Product Name",
    implementProcess: "Implement in-process quantity control.",
    revizionNo: "Revizion No",
    productCode: "Product Code",
    stockCode: "Stock Code",
    addTechnicalDrawing: "Add Technical Drawing",
    pastWorkOrders: "Past Work Orders",
    generalAndInformation: "General Information & Transactions",
    pastWorkOrdersAndRecipe: "Past Work Orders & Recipe",
    workOrderss: "Work Orders",
    appliedSupply: "Applied Supply",
    cycleTimess: "Cycle Time",
    opCost: "Op. Cost",
    avgTotalCost: "avg. total cost",
    eventNewss: "event",
    person: "Person",
    placeLoaction: "Place / Location",
    go: "Go",
    tot: "tot",
    updateTechnicalDrawing: "Update Technical Draving",
    rawMaterialType: "Material Type",
    rawMaterialSource: "Raw Material Source",
    rawMaterial: "Raw Material",
    width: "Width",
    density: "Density",
    diameter: "Diameter",
    innerDiameter: "Inner Diameter",
    height: "Height",
    description: "Description",
    products: "Products",
    leastTwoProduct: "Select at Least Two Products",
    pdfCropper: "Pdf Cropper",
    internalOperationName: "Internal Operation Name",
    estimatedTime: "Estimated Time (Minute/Second)",
    estimatedTimes: "Estimated Time",
    appliedTimes: "Applied Time",
    processtypeonproduct: "Process Type on Product",
    costOfMinute: "Cost / Minute",
    minuteCost: "Minute cost",
    estimatedSettingTime: "Estimated Setting Time (Minute)",
    settingTime: "Setting Time",
    externalOperationName: "External Operation Name",
    supplierss: "supplier",
    supplier: "Supplier",
    suppliers: "Suppliers",
    activeEvents: "Active Event",
    planedEvent: "Planned Event",
    totalWorkload: "Total Workload",
    activeProduct: "Active Product",
    stationAvg: "Station Avg.",
    unplanned: "Unplanned",
    openOrders: "open order",
    flexProduct: "Flex Product",
    works: "works",
    totalPlannedWorks: "Total Plan. Work",
    stations: "Stations",
    plannedWorks: "Planned Works",
    work: "Work",
    setup: "Setup",
    totalTime: "total time",
    orderNo: "Order No",
    finishJuction: "Finish Junction",
    addedSupplier: "Supplier Added",
    noLimitSupplier: "Supplier limit is full.",
    createSupplier: "Create Supplier",
    addNewSupplier: "Add New Supplier",
    deleteSupplier: "Delete Supplier",
    chooseSupplier: "Choose Supplier",
    noSupplier: "No Supplier",
    supplierName: "Supplier Name",
    madeTransaction: "Made Transaction",
    average: "average",
    doneMin: "done min.",
    estimatedTotalTime: "Estimated Total Time (Day)",
    costOfPiece: "Cost / Piece",
    cannotBeUndone: "This action cannot be undone. Do you confirm?",
    deleteProduct: "Delete Product",
    deleteOperation: "Are you sure you want to delete this operation?",
    deleteThisProduct: "Are you sure you want to delete this product?",
    salesApproval: "Product / Material Sales Approval",
    purChasedMaterial: "Purchased raw material",
    deleteOrder: "Delete Order",
    planWork: "Plan Work",
    plannedEquipment: "This product will be planned on this equipment. Do you confirm ?",
    productScheduled: "This product will be scheduled with this supplier. Do you confirm?",
    finishThisOperation: "Finish This Operation",
    plannedWorks: "Planned Works",
    plannedwork: "Work is planned with new equipment. Do you approve?",
    createWork: "Create a Work",
    completedWorks: "Completed Works",
    createNewWork: "Create New Work",
    getQrCodes: "Get QR Code",
    pastOrders: " Past Orders",
    orderList: "Order List",
    materialSupply: "Material Supply",
    serviceSupply: "Service Supply",
    operationQualityControl: "Operation Quality Control",
    serviceIncomingQuality: "Service Incoming Quality Control",
    materialIncomingQuality: "Material Incoming Quality Control",
    productStock: "Products",
    planning: "Planning",
    planner: "Planner",
    materialCompany: "Raw material from the company",
    addPhoto: "Add Photo",
    approvalRejection: "Approval / Rejection Description",
    completeThisOperation: "Complete This Operation",
    exportOperation: "Export Operation",
    importOperation: "Import Operation",
    finishedWorks: "Finished Works (Setting)",
    finishedWork: "Finished Works",
    waitingJobs: "Waiting Jobs",
    activeJob: "Active Jobs (Settings)",
    waitingQualityApproval: "Waiting quality approval",
    allWorkOperation: "All work in the operation will be considered completed and approved.",
    finishOperation: "Finish the operation",
    qualityUpdateError: "Start date cannot be after end date",
    finish: "Finish it",
    selectedEquipment: "User knowledge is required before selecting equipment.",
    noEquipment: "Equipment not available!",
    errors: "Error!",
    sendError: "Send Error",
    chooses: "Choose",
    transactionDetail: "Transaction Detail",
    productApproval: "Product Approval",
    choosePersonnel: "Choose Personnel",
    selectPersonnel: "Select Personnel",
    selectEquipment: "Select Equipment",
    chooseEquipment: "Choose Equipment",
    setting: "Setting",
    all: "All",
    thePastDays: "Past Days",
    qualtyControl: "Quality Control",
    productionResponsible: "Production Responsible",
    noActiveOrder: "No active orders",
    deleteCard: "Delete Card?",
    startEnd: "Start / End Date",
    actualEstimated: "Estimated / Actual Time",
    stockCode: "Stock Code",
    orderCode: "Order Code",
    totalApproved: "Total / Approved / Rejected Piece",
    appendixTechnicial: "Appendix / Technical Draving",
    estimatedActual: "Estimated / Actual Total Cost",
    estimatedActualQuantity: "Estimated / Actual Quantity Cost",
    deleteOrder: "Delete Order",
    deleteOperation: "Delete Operation",
    orderNo: "Order No",
    orders: "Orders",
    viewDrawing: "View Drawing",
    estimatedWorkTime: "Estimated Work Time",
    estimatedWorkTimeUpdate: "Update Estimated Work Time",
    averageWorkTime: "Average Actual Work Time",
    estimatedSetupTime: "Estimated Setup Time",
    estimatedSetupTimeUpdate: "Update Estimated Setup Time",
    averageSetupTime: "Average Actual Setup Time",
    startFinish: "start-end",
    startFinishTwo: "start-end",
    responsiblePersonnel: "Responsible Personnel",
    approvedForm: "Confirmation Form",
    clickImage: "Click to Upload Image",
    approvedProductCount: "Num. Approved Products",
    splitJob: "Split the Job into Different Orders",
    wantToSplit: "Enter the number of parts you want to split the job into, and then select the relevant orders and operations.",
    uploadImage: "Upload Image",
    firstPart: "1st Part",
    secondPart: "2nd Part",
    operationNo: "Operation No",
    quantity: "Quantity",
    availableTransport: "There is no product available to transport the selected junctions.",
    moveSelectedJunction: "Move Selected Junctions",
    noPlannedJunction: "You cannot plan and start a new junction because the operation is finished.",
    descOptional: "Description (Optional)",
    rejectProduct: "Reject Product",
    confirmProduct: "Confirm Product",
    planWithEquipment: "Are you approving the job plan with the new equipment?",
    goToDetail: "Go To Product Detail",
    youAreViwing: "You Are Viewing the Drawing",
    qualityControlForm: "Quality Control Form",
    productRawMaterial: "Product Raw Material Quality Control",
    qualityApprovalForm: "Quality Approval Form",
    qualityRejectionForm: "Quality Rejection Form",
    numberOfProducts: "Number of Products Made",
    numberOfRejected: "Num. Rejected Products",
    createSupplierGroup: "Create Supplier Group",
    showFlex: "Show Flex Products",
    flexibleProductManagement: "Flexible Product Management",
    createNewFlexible: "Create New Flexible Product",
    productInformation: "Product Information",
    manageOrder: "Manage Order",
    supplyTypeAndPlanning: "Supply Type & Planning",
    youCanDeduct: "You can deduct the products from stock, send them to production, or do both.",
    selectTheSupply: "Select the supply type for the raw materials required for the production of order items.",
    quantityInProduction: "Quantity in Production",
    quantityNotSend: "Quantity Not Sent to Production",
    enterTheQuantity: "Enter the quantity to be deducted from stock without sending to production.",
    sendToProduction: "Send to Production",
    youCanSendTheEntire:
      "You can send the entire order quantity or a portion of it to the work order. However, you will need to create another work order for the remaining quantity.",
    orderProduct: "Order Product",
    requiredRaw: "Required Raw Materials & Semi-Finished Products",
    urgency: "Urgency",
    enterTheDeducted: "Enter the quantity to be deducted from stock without sending to production.",
    youCanSendPortion:
      "You can send the entire order quantity or a portion of it to the work order. However, you will need to create another work order for the remaining quantity.",
    holdProduce: "Hold - Produce Later",
    youCanCreateOrder: "You can create the order and put it on hold, then produce it later.",
    workInProcess: "work in process inventory",
    addRawMaterialIntermediate: "Add Raw Materials & Intermediate Products",
    addTheRequired: "Add the required raw material or intermediate product for the item.",
    generalInformationProduct: "Enter the general information of the product.",
    productImages: "Product Image",
    enterRecipeInfor: "Enter Recipe Information",
    enterTheProduction: "Enter the production recipe for the product.",
    removeAll: "Remove All",
    planProduct: "Plan Product",
    plannedQuantitys: "Planned Quantity",
    newRecipeStep: "New Recipe Step",
    createMultiple: "Create Multiple Operations",
    addTheNecessary: "Add the necessary raw materials for the product.",
    serviceSupplys: "Service Supply",
    addQualityControl: "Add quality control after this operation",
    estimatedProductionTime: "Estimated Production Time",
    timeAndCost: "Time and Cost",
    manufacturerOrderNo: "Manufacturer Order No",
    customerOrderNo: "Customer Order No",
    operationCost: "Operation Cost",
    totalPrice: "Total Price",
    manufacturerStockCode: "Manufacturer Stock Code",
    unitPrice: "Unit Price",
    customerStockCodes: "Customer Stock Code",
    unitOperationCost: "Unit Operation Cost",
    operationCosts: "Opr. Cost",
    salesPrice: "Sales Price",
    taxAmount: "Tax Amount",
    remaining: "Remaining",
    delivered: "Delivered",
    enterThePerUnit: "Enter the per unit production and setup times, and the per minute costs of the stations linked to the operation.",
    allOperationStandart: "All Operation Standard",
    estimatedSetupTimes: "Estimated Setup Time",
    enterThePerUnitSetup: "Enter the per-unit production and setup times, and the per-minute costs of the stations associated with the operation.",
    createNewFlexRaw: "Create New Flexible Raw Material",
    rawMaterialInformations: "Raw Material Informations",
    enterTheInformationRaw: "Enter the general information related to the raw material.",
    selectRawMaterialType: "Select Raw Material Type",
    unitAndUnitPrice: "Unit / Unit Price",
    supplierGroup: "Supplier Group",
    selectSupplierGroup: "Select Supplier Group",
    criticalStockLimit: "Critical Stock Limit",
    allOperations: "All Operations",
    settingStart: "Setting Start",
    settingUpStarted: "Setting up is started on the equipment you have selected, do you confirm?",
    rawMaterialCompany: "Raw mat.comp.",
    rawMaterialCompanys: "From comp.",
    purchasedRawMaterials: "Purchased",
    purchasedRawMaterial: "Purchased raw material",
    purchasing: "Purchasing",
    finishOperationTitle: "Mark Operation Completed",
    noLimitStock: "Stock limit is full.",
    noLimitOrder: "Order limit is full.",
    stockDetail: "Stock Detail",
    createOrder: "Create Order",
    updateProduct: "Update Product",
    confirmText:
      "There are orders for this product. Instead of updating, you can create a copy of the product and register a new product or continue the update process, but remember, your orders will be deleted when you update the product.",
    goWithCopy: "Go with Copy",
    updateAnyway: "Update Anyway",
    copyProduct: "Copy Product",
    copyProductText: "You can copy the product and create a new product with the same features.",
    activeAndPlanned: "Active / Planned Jobs",
    completedAndApproved: "Completed / Approved Works",
    completedWorks: "Completed Works",

    pastOrders: "Past Orders",
    activeOrders: "Active Orders",
    qualityPercent: "Quality Percentage",
    receipe: "Recipe",
    details: "Details",
    addOperation: "Add Operation",
    active: "active",
    planned: "planned",
    serviceOperationType: "Operation Type",
    productsAndMaterials: "Products and Materials",
    productsAndMaterialss: "Product and Raw Materials",
    perUnitCost: "Unit Cost",
    goToOrder: "Go to Order",
    productAndRawmaterial: "Products and/or raw materials to be sent for purchase for additional stock supply",
    totalStock: "total stock",
    deliveryDate: "delivery date",
    hammadde: "commodity",
    hamade: "Raw materials",
    expectedDelivery: "Estimated Delivery Date",
    unit: "Unit",
    cost: "Cost",
    productUnit: "Product Cost",
    orderQuantity: "Order Quantity",
    events: "Events",
    source: "Source",
    orderAmount: "Order Amount",
    purchase: "Purchase",
    supplyForm: "Supply Form",
    productCode: "Product Code",
    stockCode: "Stock code",
    orderQuantity: "Order Quantity",
    delivery: "Delivery Date",
    materialRequiredForWork: "Products and/or raw materials required for the work order",
    workOrderCode: "Work Order Code",
    markPurchase: "Make a Purchase",
    totalNoOfProduct: "Total Number of Products",
    bringFromStock: "Bring from Stock",
    station: "Station",
    stockAmount: "Amount of stock",
    supplyType: "Supply type",
    failedCreation: "Failed to create",
    sentRawMaterials: "Products and/or raw materials to be sent for purchase for the work order",
    excessPurchased: "If the quantity to be purchased is more than the order requirement Excess quantity will be shown as additional stock purchase.",
    additionalRawmaterials: "Products and/or raw materials to be sent for purchase for additional stock supply",
    purchaseRequestForm: "Purchase Request Form",
    purchaseRequestCreation: "Create purchase request",
    singleProcess: "Process",
    subProductsNumber: "number of sub-products",
    levels: "min-ad-avg",
    mergeOperationSteps: "New Merge Operation Step",
    generalInformation: "Genaral information",
    selectMinMaterials: "Select at least two products/raw materials for this operation",
    operations: "operation",
    active: "Active",
    waiting: "Pending",
    completed: "Completed",
    rejected: "Rejected",
    orderEdit: "Edit Order",
    totalOrderQuantity: "Total Ordered Quantity",
    totalDeliveredQuantity: "Quantity Delivered",
    remainingOrders: "Remaining Amount",
    activeOrders: "active orders",
    customerGroup: "Customer Group",
    orderNumber: "Order No",
    orderGeneratedDate: "Order Creation Date",
    orderedQuantity: "Order Quantity",
    deliveringDate: "Date of delivery",
    expandableProducts: "Products",
    addNewProductOrder: "Add New Product to Order",
    orderProducts: "Order Products",
    selectTheGeneral: "Select the general information and products related to the order.",
  },

  consoleModal: {
    title: "Shiftly Equipment Details",
    time: "Time",
    qty: "Qty",
    count: "Count",
    workQty: "Work Quantity",
    minCount: "Min Count",
    noDataForShift: "Data not found",
    noConnection: "Your Transaction Has Not Been Taken Because There Is No Device Connection. Please Check Device Connection.",
    errorSent: "Fault Error Sent",
    errorRemoved: "Fault Ended",
    seeAuthorization:
      "You cannot see upu.twin because you do not have authorization. You can see the details of the operations you are authorized by double-clicking on them in the menu above.",
    notAuthorization: "You are not authorized",
    connectToServer: "Cannot connect to the server!",
    sensorError: "Sensor Error!",
    applySaveLegends: "Apply Saved Legends",
    saveSelectedLegends: "Save Selected Legends",
  },
  losses: {
    addNewType: "Add New Fault Type",
    editType: "Edit Fault Type",
    general: "General",
    iconLib: "Icon Library",
    viewAll: "View All",
    typeName: "Type Name",
    typeDescription: "Type Description",
  },
  //settings KPI
  settingsKpi: {
    subTitle: "Update KPI information. ",
    firmWide: "Firm Wide",
    personels: "Users",
    plannedProduction: "Planned Production Time",
    theoreticalProduction: "Theoretical Production Time",
    performance: "Performance",
  },
  loss: {
    graphView: "Graph View",
    listView: "List View",
    typeOfLoss: "Loss Types",
    allTime: "All Time",
    monthly: "Monthly",
    timeLoss: "Time Loss",
    time: "Time",
    costLoss: "Cost Loss",
    cost: "Cost",
    energyLoss: "Energy Loss",
    consumption: "Consumption",
    filter: "Filters",
    export: "Export",
    lostType: "Lost Type",
    operation: "Operation",
    machine: "Machine Name",
    start: "Start",
    end: "End",
    timeMass: "Time Mass",
    faultTypes: "Fault Types",
    lossCode: "Loss Code",
    cannotLoss: "This action cannot be undone?",
    icon: "Icon",
    noDataFound: "No Data Found!",
    searchLoss: "Search...",
    electricKwh: "Electric (kVAh)",
    electricKw: "Electric (kVA)",
    costt: "Cost (t)",
    costThree: "Cost (3t)",
    code: "Code",
    getQrCode: "Get a QR-Code",
    lossIcon: "Loss Icon",
    lossName: "Loss Name",
    sensorSetting: "Sensor Setting",
    editLossType: "Edit Loss Type",
  },
  settingTab: {
    profileAndPassword: "Profile & Password",
    company: "Company",
    departments: "Departments",
    internalOperation: "Internal Operation",
    shiftManagement: "Shift Management",
    manageYourProfile: "Manage your profile and preferences.",
    profilePhoto: "Profile Photo",
    currentPassword: "Enter your current password to chance your password.",
    currentPasswords: "Current Password",
    consfirmNewPassword: "Confirm New Password",
    updateYourCompany: " Update your company details.",
    manageYourDepartments: "Manage your deparments.",
    manageYourExternal: "Manage your external operations.",
    newDepartment: "Create New Department",
    generalInformation: "General Information",
    affiliatedPersonnel: "Affiliated Personnel",
    labelNameEmpty: "Label name is empty.",
    internalOperations: "Internal Operations",
    externalOperations: "External Operation",
    equipments: "Equipments",
    identifyEquipments: "Identify and organize your equipments.",
    manageYourInternal: "Manage your internal operations.",
    updateInternalOperation: "Update Internal Operation",
    connectedEquipment: "Connected Equipment",
    shiftManagement: "Shift Management",
    manageYourShift: "Manage your shifts and breaks.",
    shiftInfo: "Shift Info",
    breaks: "Breaks",
    noBreak: "No Break!",
    deleteBreak: "Delete Break?",
    areYouSureDelete: "Are you sure you want to delete?",
    breakName: "Break Name",
    updateYourKpi: "Update your kpi values.",
    success: "Success.",
    failed: "Başarısız!",
    define: "Define",
    defineNewEquipment: "Define New Equipment",
    undefinedNodes: "Undefined Nodes",
    costNew: "cost(t)",
    costThreeNew: "cost(3t)",
    statusType: "Status Type",
    consume: "Consume",
    energy: "Energy",
    start: "Start",
    newCreateExternalOperation: "Create New External Operation",
    end: "End",
    graphDataNotFound: "Graph data not found!",
    totalTime: "Total Time",
    profilePhotoUpdate: "Profile Photo Updated",
    reenterPassword: "Please re-enter the password manually instead of pasting.",
  },
  person: {
    selectItem: "Please select an item from the list.",
    newPoll: "New Poll",
    newGroup: "New Group",
    newMessage: "New Message",
    createTaskGroup: "Create a Task Group",
    startConversation: "Start a Conversation",
    createNewPoll: "Create a New Poll",
    groupInfo: "Group Info",
    groupMembers: "Group Members",
    clickSendMessage: "Click to send message",
    directChats: "Direct Chats",
    groups: "Groups",
    muteGroup: "Mute Group",
    unmuteGroup: "Unmute Group",
    leaveGroup: "Leave Group",
    areYouLeave: "Are you sure you want to leave the group?",
    areYouDelete: "Are you sure you want to leave the group?",
    areYouMute: "Are you sure you want to mute the group?",
    areYouUnmuteGroup: "Do you want to unmute the group?",
    areYouMutePerson: "Are you sure you want to mute the person?",
    totalPersonnel: "Total Personnel",
    activePersonnel: "Active Personnel",
    totalPersonnels: "Total Per.",
    activePersonnels: "Active Per.",
    passivePersonnel: "Passive Personnel",
    allPersonnel: "All Personnel",
    areYouUnmutePerson: "Do you want to unmute the contact?",
    muteThePerson: "Mute the person",
    unmutePerson: "Unmute person",
    mute: "Mute",
    unmute: "Unmute",
    admins: "Admins",
    editGroup: "Edit Group",
    createNewTask: "Create New Task",
    taskSubjectCreated: "Task subject cannot be left blank and if personnel selection is not made, a task cannot be created!",
    taskSubject: "Task subject cannot be left blank!",
    taskCannot: "Tasks cannot be created without selecting personnel!",
    taskSuccess: "The task has been created successfully.",
    addUser: "Add User",
    writeTask: "Enter Task..",
    addComment: "Enter comment...",
    markAsAllRead: "Mark as All Read",
    deleteNoti: "Delete Notifications",
    totalNotificationLimit: "Total Notification Limit",
    selectAll: "Select All",
    pollCompleted: "Poll Completed",
    finishPoll: "Finish Poll",
    deletePoll: "Delete Poll",
    rePublishPoll: "Republish Poll",
    areDeletePoll: "Are you sure you want to delete the poll?",
    areFinishPoll: "Are you sure you want to finish the poll?",
    areRetweetPoll: "Are you sure you want to republish the poll?",
    completePoll: "Complete Poll",
    product: "Product",
    connectAndDisconnect: "Connect and Disconnect Loss",
    qualityControl: "Quality control",
    availablePersonnels: "Available Personnels",
    leadersOfToday: "Leaders of the Day",
    start: "Start",
    description: "Description",
    semiMaterialStockCode: "Semi-Material Stock Code/Optinal",
    totalTaskLimit: "Total Task Limit",
  },
  embedded: {
    activeAnalogSensor: "Active Analog Sensör",
    connectedEquipment: "Connected Equipment",
    activeDigitalSensor: "Active Digital Sensor",
    statusColors: "Status Colors",
    deviceState: "Device State",
    stmVersion: "Stm Version",
    sensorFusion: "Sensor Fusion Software",
    connectionSoftware: "Communication Software",
    espVersion: "Esp Version",
    status: "Status",
    macAddress: "Mac Address",
    ipAddress: "IP Address",
    sensorType: "Sensor Type",
    activeOutput: "Active Output",
    makeSure: "Please make sure you fill out all information completely!",
    sensorDetails: "Sensor Details",
    graph: "Graph",
    sensorType: "Sensor Type",
    valueType: "Value Type",
    maxValue: "Max Value",
    minValue: "Min Value",
    registerDeleted: "Registered sensor information has been deleted.",
    digitalIntegral: "Digital Integral Frequency",
    digitalTotalTime: "Digital Total Time Value",
    digitalTotalCount: "Digital Total Count Value",
    resetSensor: "Reset Sensor Setting",
    willBeReset: "All sensor settings will be reset. This action cannot be undone.",
    timeRemaining: "Time Remaining",
    realTimeFiltered: "Real-Time Filtered",
    unfilteredRealTime: "Unfiltered Real-Time",
    changesNotSaved: "Reset Sensor Setting",
    areYouSureChanges: "Are you sure you want to proceed to the next step without saving the changes?",
    resetDeviceSettings: "Reset Device Settings",
    failedConnectEmbedded: "Failed to Connect to Embedded Server",
    quickEntry: "Quick Entry",
    getQr: "Get a QR Code",
    printQr: "Download or print a QR code",
    print: "Print",
    download: "DownLoad",
    embeddedServerSession: "Embedded server session will automatically expire in 15 minutes.",
    leavePage: "You are about to leave the page. Your changes may not be saved.",
    connectionLost: "Connection is being lost",
    areYouSaveChanges: "Are you sure you want to exit without saving changes?",
    disconnect: "Disconnect",
    resetDevice: "Reset Device",
    actionCannot: "This action cannot be undone.",
    internetConnection: "Operation Failed. Check Your Internet Connection!",
    networkError: "Network Error",
    pleaseWaitData: "Please wait while the data is loading.",
    mapEditor: "Map Editor",
    templates: "Templates",
    areaSettings: "Area Settings",
    width: "Width",
    height: "Height",
    mapSaved: "Map has been successfully saved. Please wait.",
    mapSevedEmptyArea: "The map has been successfully saved, empty areas will not be saved, please wait.",
    works: "Works",
    startJob: "Start Job",
    operationSuccessfull: "Operation successfull !",
    startJob: "Start Job",
    completedWorks: "Competed Works",
    viewAll: "View All Planned Works",
  },
  tower: {
    grayTitle: "Closed",
    grayText: "Indicates that the device or the machine it is connected to has no power.",
    yellowTitle: "Wait",
    yellowText: "Indicates that the equipment's switch is on, it is ready, but it is not actively working.",
    greenTitle: "Running",
    greenText: "Indicates that the machine it is connected to is actively running.",
    redTitle: "Error",
    redText: "Indicates that the machine it is connected to has gone into failure mode.",
    purpleTitle1: "Digital Sensor and Analog Sensor",
    purpleText1: "Indicates that the selected Digital or Analog sensor from the embedded server has been disconnected.",
    purpleTitle2: "Lost bluetooth signal",
    purpleText2: "Indicates that a connection could not be established with the Bluetooth sensor.",
    purpleTitle3: "kVAh Sensor Malfunction",
    purpleText3: "Indicates that there is a malfunction in the kVAh sensor or it is not connected.",
    purpleFlashingTitle: "Sensor Saturation Point",
    purpleFlashingText: "It indicates that the sensor has reached its saturation point.",
    whiteTitle1: "Shift Start-End",
    whiteText1: "Indicates shift beginnings and endings.",
    whiteTitle2: "Online Timed",
    whiteText2: "Indicates a remote status signal.",
    whiteTitle3: "Sensor",
    whiteText3: "Means sensor settings are not adjusted.",
    lightBlueTitle: "Lost Wi-Fi connection",
    lightBlueText: "Indicates the status of the equipment trying to connect to the Internet.",
    darkBueTitle1: "Ongoing Maintenance",
    darkBlueText1: "Indicates that the equipment is currently under maintenance.",
    darkBueTitle2: "Planned Maintenance",
    darkBlueText2: "Indicates that the equipment is due for scheduled maintenance.",
    darkBueTitle3: "Prescribed Maintenance",
    darkBlueText3: "Indicates that the equipment is about to fail.",
    orangeTitle: "ESP Software Update",
    orangeText: "Indicates that Upu Tower is performing a software update.",
    flashingRedTitle: "Cloud Dis-connection",
    flashingRedText: "Indicates if the equipment is disconnected from the cloud.",
  },
  global: {
    pageNotFound: "Page not found..!",
    pageNotLoad: "Page could not load",
    sorryMsg: "Sorry, the page you are looking for doesn't exist or has been moved. Here are some helpful links:",
    toHome: "Take me home",
    goBack: "Go back",
    selected: "Selected",
    returnActiveWorks: "Return Active Works",
  },
  errors: {
    oldAndNewPassword: "Old-Password & New-Password cannot be same",
    apiError: "API or Service down, Please try after sometime.",
  },
  password: {
    passwordConditions:
      "The password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one special character and one number.",
    passwordNotMatch: "Passwords do not match",
    passwordNotValid: "Password does not meet requirements",
    passwordChanged: "Password changed successfully",
    passwordNotChanged: "Password not changed",
    createNewPassword: "Create New Password",
    continue: "Continue",
    newPassword: "New Password",
    newPasswordRepeat: "New Password Repeat",
    english: "English",
    turkish: "Turkish",
    passwordRequired: "Password is required",
    currentPasswordNotTrue: "Current password is not true",
  },
};

export default en;

import React, { useEffect, useState } from "react";
import Badgets from "../../../../components/buttons/Badgets";
import CustomModal from "../../../../components/modal/CustomModal";
import SelectUserModal from "../../../../components/modal/SelectUserModal";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { timeFormatter } from "../../../../utils/timezoneFormatter";
import { CloseIcon } from "../../../../assets/icons/departmentIcon";
import EquipmentDetailDoneCard from "../../../../components/new-cards/equipment-detail-card/EquipmentDetailDoneCard";
import EquipmentDetailTodayDoneCard from "../../../../components/new-cards/equipment-detail-card/EquipmentDetailTodayDoneCard";
import EquipmentDetailActiveCard from "../../../../components/new-cards/active-card/EquipmentDetailActiveCard";
import FinishJunctionModal from "../../../../components/modal/FinishJunctionModal";
import EquipmentDetailPlannedCard from "../../../../components/new-cards/equipment-detail-card/EquipmentDetailPlannedCard";
import { ArrowRightIcon, JunctionNumberIcon, JunctionNumberIconSelected } from "../../../../components/new-cards/card-icons";

const EquipmentJunctions = ({
  junctionData = [],
  date,
  data,
  PastJunctionData,
  selectedJunction,
  setSelectedJunction,
  activeLength,
  defaultStartTime,
  defaultEndTime,
  //new timeline prop
  refetch,
  setStartTime,
  setEndTime,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOpenSelectProduct, setIsOpenSelectProduct] = useState(false);
  const [selectedJunctionForPlanned, setSelectedJunctionForPlanned] = useState(null);
  const [qualityControlFormFix, setQualityControlFormFix] = useState(false);
  const [junctionType, setJunctionType] = useState("internal");
  const [finishJunctionId, setFinishJunctionId] = useState(null);
  const [productType, setProductType] = useState("standart");
  const [selectedStatus, setSelectedStatus] = useState("active");
  const [isOpenJunctionPiece, setIsOpenJunctionPiece] = useState(false);
  // const [filterBadge, setFilterBadge] = useState("all");
  // const [filteredData, setFilteredData] = useState([]);
  const _data = junctionData
    ?.sort((f, s) => f?.planningOrder - s?.planningOrder)
    ?.filter((el) => {
      return el?.status === "planned";
    })[0];

  const sortedJunctions = junctionData?.sort((f, s) => moment(f?.startDate).unix() - moment(s?.startDate).unix());
  const activeJunction = junctionData?.filter((item) => item?.status === "active");

  const junctionNumber = (item) => {
    const junctions = sortedJunctions?.filter((a) => a.status != "planned");
    const sorted = junctions?.sort((a, b) => moment(a?.startDate).unix() - moment(b?.startDate).unix());
    const data = sorted.map((value, index) => {
      if (value?.id == item) {
        return index + 1;
      } else {
        return "";
      }
    });
    const lastData = data.filter((a) => a != "");
    return lastData[0];
  };
  const activeJunctionLength = junctionData?.filter((item) => item.status != "planned");
  // const activeJunctionLengths = activeJunctionLength?.length;
  const activeJunctionLengths = junctionData?.filter((item) => item?.status === "active");
  const completedJunctionLength = junctionData?.filter((item) => item?.status === "completed");
  const plannedJunctionLength = junctionData?.filter((item) => item?.status === "planned");
  const setupJunctionLength = junctionData?.filter((item) => item?.type === "setup");

  // useEffect(() => {
  //   // filterBadge değiştiğinde junctionData'yı filtrele ve güncelle
  //   const filtered = junctionData?.filter((a) => {
  //     switch (filterBadge) {
  //       case "active":
  //         return a?.status === "active";
  //       case "completed":
  //         return a?.status === "completed";
  //       case "planned":
  //         return a?.status === "planned";
  //       case "setup":
  //         return a?.type === "setup";
  //       default:
  //         return a;
  //     }
  //   });
  //   setFilteredData(filtered);
  // }, [filterBadge, junctionData]);

  // const handleFilterChange = (filterType) => {
  //   setFilterBadge(filterType);
  // };

  const combinedJunctions = [...activeJunction, ...junctionData?.filter((obj) => !activeJunction.some((active) => active.id === obj.id))];

  // selectedStatus'a göre filtreleme
  const filteredJunctions = combinedJunctions
    ?.filter((item) => {
      if (selectedStatus === "active") {
        return item?.status === "active"; // Sadece "active" kartlar
      } else if (selectedStatus === "planned") {
        return item?.id === _data?.id; // Sadece `_data` olarak aldığımız junction'ı kartları gösterir
      } else if (selectedStatus === "completed") {
        return item?.status === "completed"; // Sadece "completed" kartları gösterir
      } else {
        return item?.status === selectedStatus;
      }
    })
    ?.sort((a, b) => {
      if (a?.status === "completed" && b?.status !== "completed") {
        return 1;
      } else if (a?.status !== "completed" && b?.status === "completed") {
        return -1;
      } else {
        return 0;
      }
    });

  useEffect(() => {
    if (moment(date)?.format("DD-MM-YYYY") === moment(new Date())?.format("DD-MM-YYYY")) {
      if (activeJunctionLengths?.length == 0) {
        setSelectedStatus("planned")
      } else {
        setSelectedStatus("active");
      }
    } else {
      setSelectedStatus("completed");
    }
  }, [date,activeJunctionLengths?.length]);

  return (
    <>
      <div className="w-full  px-4 flex items-start mt-3 mb-1">
        <div className="flex flex-col gap-2 w-[136px] min-w-[136px] max-w-[136px]">
          <p className={`text-lg font-semibold text-secondary-900`}>{t("product:eventss")}</p>
          {/* <div className="w-full h-[30px] flex items-center justify-between rounded-lg border border-secondary-300 bg-white pl-2 py-1 pr-0.5">
            <p className="text-xs font-semibold text-[#067647]">{t("embedded:works")}</p>
            <Badgets label={combinedJunctions?.length} size={"sm"} colorType={"fill-success"} />
          </div> */}
          {moment(date)?.format("DD-MM-YYYY") === moment(new Date())?.format("DD-MM-YYYY") ? (
            <>
              <div
                onClick={() => setSelectedStatus("active")}
                className={`w-full h-[30px] flex items-center justify-between cursor-pointer rounded-lg border ${selectedStatus === "active" ? "bg-[#F9FAFB]" : "bg-white"} border-secondary-300  pl-2 py-1 cursor-pointer pr-0.5`}
              >

                <p className="text-xs font-semibold text-[#067647]">{t("product:active")}</p>
                <Badgets label={activeJunctionLengths?.length} size={"sm"} colorType={"fill-success"} />

              </div>
              <div
                onClick={() => setSelectedStatus("planned")}
                className={`w-full h-[30px] flex items-center justify-between cursor-pointer rounded-lg border border-secondary-300 ${selectedStatus === "planned" ? "bg-[#F9FAFB]" : "bg-white"}  pl-2 py-1 pr-0.5`}
              >
                <p className="text-xs font-semibold text-[#344054]">{t("product:newPlanned")}</p>
                <Badgets label={plannedJunctionLength?.length > 0 ? 1 : 0} size={"sm"} colorType={"fill-gray"} />
              </div>
              <div
                onClick={() => setSelectedStatus("completed")}
                className={`w-full h-[30px] flex items-center justify-between cursor-pointer rounded-lg border border-secondary-300 ${selectedStatus === "completed" ? "bg-[#F9FAFB]" : "bg-white"} pl-2 py-1 pr-0.5`}
              >
                <p className="text-xs font-semibold text-[#6941C6]">{t("product:completedNew")}</p>
                <Badgets label={completedJunctionLength?.length} colorType={"fill-primary"} size={"sm"} />
                {/* <p className="text-xs font-medium text-[#6941C6]">{completedJunctionLength?.length} Completed</p> */}
              </div>
            </>
          ) : (
            <div
              onClick={() => setSelectedStatus("completed")}
              className={`w-full h-[30px] flex items-center justify-between cursor-pointer rounded-lg border ${selectedStatus === "completed" ? "bg-[#F9FAFB]" : "bg-white"} border-secondary-300 bg-white pl-2 py-1 pr-0.5`}
            >
              <p className="text-xs font-semibold text-[#6941C6]">{t("product:completedNew")}</p>
              <Badgets label={completedJunctionLength?.length} colorType={"fill-primary"} size={"sm"} />
              {/* <p className="text-xs font-medium text-[#6941C6]">{completedJunctionLength?.length} Completed</p> */}
            </div>
          )}
        </div>

        <div className="flex-1 flex items-start ml-5 pb-2 overflow-x-auto ">
          {/* {activeJunction && (
            <div
              onClick={() => {
                if (!qualityControlFormFix) {
                  if (selectedJunction === activeJunction?.id) {
                    setSelectedJunction(!selectedJunction);
                    setStartTime(defaultStartTime);
                    setEndTime(new Date());
                    refetch();
                  } else {
                    const dateToSend = new Date();

                    PastJunctionData(activeJunction, dateToSend);
                    setStartTime(new Date(timeFormatter(activeJunction?.startDate).formatted).valueOf());
                    setEndTime(new Date(timeFormatter(new Date()).formatted).valueOf());
                  }
                }
              }}
              className={`flex flex-col mr-4  cursor-pointer min-w-[467px] max-w-[467px] hover:bg-secondary-50 items-start pt-2 px-3 pb-3 w-full  ${
                selectedJunction === activeJunction?.id ? "border-[#7F56D9] border-[1.5px]" : "border-[#E4E7EC] hover:border-secondary-300 border"
              }  rounded-lg `}
            >
              <div className="min-w-[440px] max-w-[440px] gap-y-2 flex flex-col relative cursor-pointer">
                <div className="flex flex-col gap-y-8 mt-2 ">
                  <EquipmentDetailActiveCard
                    data={activeJunction}
                    setQualityControlFormFix={setQualityControlFormFix}
                    refetch={refetch}
                    setJunctionType={setJunctionType}
                    setFinishJunctionId={setFinishJunctionId}
                    setIsOpenJunctionPiece={setIsOpenJunctionPiece}
                  />
                </div>
                {selectedJunction === activeJunction?.id ? (
                  <div className="flex items-center justify-between w-full">
                    <div className="flex flex-row items-center gap-x-1">
                      <JunctionNumberIconSelected color={"#6941C6"} />
                      <p className="text-md font-semibold text-[#6941C6]">{junctionNumber(activeJunction?.id)}</p>
                      <p className="font-medium text-sm text-[#6941C6]">{t("product:activeEvents")}</p>
                    </div>
                    <p className="text-md font-semibold text-[#6941C6]">{t("global:selected")}</p>
                  </div>
                ) : (
                  <div className="flex flex-row items-center gap-x-1">
                    <JunctionNumberIcon color={"#667085"} />
                    <p className="text-md font-semibold text-secondary-600">{junctionNumber(activeJunction?.id)}</p>
                    <p className="font-medium text-sm text-secondary-600">{t("product:activeEvents")}</p>
                  </div>
                )}
              </div>
            </div>
          )} */}
          {filteredJunctions
            ?.map((item, index) => {
              if (moment(date)?.format("DD-MM-YYYY") === moment(new Date())?.format("DD-MM-YYYY")) {
                if (item?.status === "active") {
                  return (
                    <div
                      onClick={() => {
                        if (!qualityControlFormFix) {
                          if (selectedJunction === item?.id) {
                            setSelectedJunction(!selectedJunction);
                            setStartTime(defaultStartTime);
                            setEndTime(new Date());
                            refetch();
                          } else {
                            const dateToSend = new Date();
                            setSelectedJunction(item);
                            PastJunctionData(item, dateToSend);
                            setStartTime(new Date(timeFormatter(item?.startDate).formatted).valueOf());
                            setEndTime(new Date(timeFormatter(new Date()).formatted).valueOf());
                          }
                        }
                      }}
                      className={`flex flex-col mr-4  cursor-pointer min-w-[467px] max-w-[467px] hover:bg-secondary-50 items-start pt-2 px-3 pb-3 w-full  ${selectedJunction === item?.id ? "border-[#7F56D9] border-[1.5px]" : "border-[#E4E7EC] hover:border-secondary-300 border"
                        }  rounded-lg `}
                    >
                      <div className="min-w-[440px] max-w-[440px] gap-y-2 flex flex-col relative cursor-pointer">
                        <div className="flex flex-col gap-y-8 mt-2 ">
                          <EquipmentDetailActiveCard
                            data={item}
                            junctionData={junctionData}
                            setQualityControlFormFix={setQualityControlFormFix}
                            refetch={refetch}
                            setJunctionType={setJunctionType}
                            setFinishJunctionId={setFinishJunctionId}
                            setIsOpenJunctionPiece={setIsOpenJunctionPiece}
                            setProductType={setProductType}
                          />
                        </div>
                        {selectedJunction === item?.id ? (
                          <div className="flex items-center justify-between w-full">
                            <div className="flex flex-row items-center gap-x-1">
                              <JunctionNumberIconSelected color={"#6941C6"} />
                              <p className="text-md font-semibold text-[#6941C6]">{junctionNumber(item?.id)}</p>
                              <p className="font-medium text-sm text-[#6941C6]">{t("product:activeEvents")}</p>
                            </div>
                            <p className="text-md font-semibold text-[#6941C6]">{t("global:selected")}</p>
                          </div>
                        ) : (
                          <div className="flex flex-row items-center gap-x-1">
                            <JunctionNumberIcon color={"#667085"} />
                            <p className="text-md font-semibold text-secondary-600">{junctionNumber(item?.id)}</p>
                            <p className="font-medium text-sm text-secondary-600">{t("product:activeEvents")}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                } else if (item?.status === "completed") {
                  if (item?.station) {
                    return (
                      <div
                        onClick={() => {
                          if (!qualityControlFormFix) {
                            if (selectedJunction === item?.id) {
                              setSelectedJunction(!selectedJunction);
                              setStartTime(defaultStartTime);
                              setEndTime(defaultEndTime);
                              refetch();
                            } else {
                              const dateToSend = item?.endDate || new Date();

                              let items = {
                                ...item,
                                startDate: moment(item?.startDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS"),
                                endDate: moment(item?.endDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS"),
                              };

                              PastJunctionData(items, dateToSend);
                              setStartTime(new Date(timeFormatter(item?.startDate).formatted).valueOf());
                              setEndTime(new Date(timeFormatter(item?.endDate).formatted).valueOf());
                            }
                          }
                        }}
                        className={`flex flex-col mr-4 cursor-pointer min-w-[467px] max-w-[467px] hover:bg-secondary-50 items-start pt-2 px-3 pb-3 w-full  ${selectedJunction === item?.id ? "border-[#7F56D9] border-[1.5px]" : "border-[#E4E7EC] hover:border-secondary-300 border"
                          }  rounded-lg `}
                      >
                        <div className="min-w-[440px] max-w-[440px] relative cursor-pointer ">
                          <div
                            className="flex flex-col gap-y-8 mt-2"
                            onClick={() => {
                              {
                                setStartTime(new Date(timeFormatter(item?.startDate).formatted).valueOf());
                                setEndTime(new Date(timeFormatter(item?.endDate).formatted).valueOf());
                              }
                            }}
                          >
                            <EquipmentDetailTodayDoneCard data={item} setQualityControlFormFix={setQualityControlFormFix} refetch={refetch} />
                          </div>
                        </div>
                        <div className="flex flex-row min-w-[440px] max-w-[440px] items-center gap-x-3 w-full mt-3">
                          {selectedJunction === item?.id ? (
                            <div className="flex items-center justify-between w-full">
                              <div className="flex flex-row items-center gap-x-1">
                                <JunctionNumberIconSelected color={"#6941C6"} />
                                <p className="text-md font-semibold text-[#6941C6]">{junctionNumber(item?.id)}</p>
                              </div>
                              {/* <p className="text-md font-semibold text-[#6941C6]">{t("global:selected")}</p> */}
                            </div>
                          ) : (
                            <div className="flex flex-row items-center gap-x-1">
                              <JunctionNumberIcon color={"#667085"} />
                              <p className="text-md font-semibold text-secondary-600">{junctionNumber(item?.id)}</p>
                            </div>
                          )}
                          <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
                            <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                              <p className="text-xs font-normal text-secondary-600">{"upu.point"}</p>
                            </div>
                            <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                              <p className={`text-base w-[100px] text-center truncate font-semibold text-[#9B7D07]`}>
                                {item?.metadata?.upuPoint?.toFixed(2) || "0"}
                              </p>
                            </div>
                          </div>
                          <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
                            <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                              <p className="text-xs  font-normal text-secondary-600">{t("reports:performance")}</p>
                            </div>
                            <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                              <p className={`text-base w-[100px] truncate text-center font-semibold text-[#079455]`}>
                                %{item?.metadata?.performancePercent > 1 ? 1 * 100 : (item?.metadata?.performancePercent * 100)?.toFixed(0) || "0"}
                              </p>
                            </div>
                          </div>
                          <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
                            <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                              <p className="text-xs font-normal text-secondary-600">{t("product:qualityControlRate")}</p>
                            </div>
                            <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                              <p className={`text-base w-[100px] truncate text-center font-semibold text-secondary-600`}>
                                %{item?.metadata?.qualityPercent > 1 ? 1 * 100 : (item?.metadata?.qualityPercent * 100)?.toFixed(0) || "0"}
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* {selectedJunction === item?.id ? (
                          <div className="w-[100px] max-w-[100px] absolute -top-7   z-20 animate-pulse  ">
                            <Badgets label={t("global:selected")} size={"sm"} colorType={"outline-machine"} iconLeft={<CloseIcon />} />
                          </div>
                        ) : (
                          <div className="w-[100px] max-w-[100px] absolute -top-7   z-20 ">
                            <Badgets label={junctionNumber(item?.id) + ".Junction"} size={"sm"} colorType={"outline-gray"} />
                          </div>
                        )} */}
                        {/* <EquipmentDetailTodayDoneCard data={item} setQualityControlFormFix={setQualityControlFormFix} refetch={refetch} /> */}
                      </div>
                    );
                  } else {
                    return <></>;
                  }
                } else if (item?.status === "planned") {
                  if (item?.station) {
                    return (
                      <div
                        className={`flex flex-col mr-4 border-[#E4E7EC] hover:border-secondary-300 border  cursor-pointer min-w-[467px] max-w-[467px] hover:bg-secondary-50 items-start pt-2 px-3 pb-3 w-full rounded-lg `}
                        key={index}
                      >
                        <div className="min-w-[440px] flex flex-col gap-y-3 max-w-[440px] relative cursor-pointer ">
                          <div className="flex flex-col gap-y-8 mt-2">
                            <EquipmentDetailPlannedCard
                              data={item}
                              refetch={refetch}
                              setSelectedJunctionForPlanned={setSelectedJunctionForPlanned}
                              setIsOpenSelectProduct={setIsOpenSelectProduct}
                            />
                          </div>
                          <div className="flex flex-row items-center gap-x-1">
                            <JunctionNumberIcon color={"#667085"} />

                            <p className="font-medium text-sm text-secondary-600">{t("product:planedEvent")}</p>
                            <div
                              onClick={() => {
                                if (item?.internalOperation) {
                                  navigate(`/app/product/mrp/work-in-process/operation-detail/${item?.internalOperation?.id}`);
                                }
                              }}
                              className="ml-auto flex flex-row items-center gap-x-1 cursor-pointer"
                            >
                              <p className="text-sm font-semibold text-[#6941C6]">{t("losses:viewAll")}</p>
                              <ArrowRightIcon />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                }
              } else {
                return (
                  item?.status === "completed" && (
                    <div
                      onClick={() => {
                        if (selectedJunction === item?.id) {
                          setSelectedJunction(!selectedJunction);
                          setStartTime(defaultStartTime);
                          setEndTime(defaultEndTime);
                        } else {
                          let items = {
                            ...item,
                            startDate: moment(item?.startDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS"),
                            endDate: moment(item?.endDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS"),
                          };
                          PastJunctionData(items);
                        }
                      }}
                      className={`flex flex-col mr-4 cursor-pointer min-w-[467px] max-w-[467px] hover:bg-secondary-50 items-start pt-2 px-3 pb-3 w-full  ${selectedJunction === item?.id ? "border-[#7F56D9] border-[1.5px]" : "border-[#E4E7EC] hover:border-secondary-300 border"
                        }  rounded-lg `}
                    >
                      <div className="min-w-[440px] max-w-[440px] relative cursor-pointer ">
                        <div
                          className="flex flex-col gap-y-8 mt-2"
                          onClick={() => {
                            {
                              setStartTime(new Date(timeFormatter(item?.startDate).formatted).valueOf());
                              setEndTime(new Date(timeFormatter(item?.endDate).formatted).valueOf());
                            }
                          }}
                        >
                          <EquipmentDetailDoneCard data={item} refetch={refetch} setQualityControlFormFix={setQualityControlFormFix} />
                        </div>
                      </div>
                      <div className="flex flex-row min-w-[440px] max-w-[440px] items-center gap-x-3 w-full mt-3">
                        {selectedJunction === item?.id ? (
                          <div className="flex items-center justify-between w-full">
                            <div className="flex flex-row items-center gap-x-1">
                              <JunctionNumberIconSelected color={"#6941C6"} />
                              <p className="text-md font-semibold text-[#6941C6]">{junctionNumber(item?.id)}</p>
                            </div>
                            {/* <p className="text-md font-semibold text-[#6941C6]">{t("global:selected")}</p> */}
                          </div>
                        ) : (
                          <div className="flex flex-row items-center gap-x-1">
                            <JunctionNumberIcon color={"#667085"} />
                            <p className="text-md font-semibold text-secondary-600">{junctionNumber(item?.id)}</p>
                          </div>
                        )}
                        <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
                          <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                            <p className="text-xs font-normal text-secondary-600">{"upu.point"}</p>
                          </div>
                          <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                            <p className={`text-base w-[100px] text-center truncate font-semibold text-[#9B7D07]`}>
                              {item?.metadata?.upuPoint?.toFixed(2) || "0"}
                            </p>
                          </div>
                        </div>
                        <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
                          <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                            <p className="text-xs  font-normal text-secondary-600">{t("reports:performance")}</p>
                          </div>
                          <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                            <p className={`text-base w-[100px] truncate text-center font-semibold text-[#079455]`}>
                              %{item?.metadata?.performancePercent > 1 ? 1 * 100 : (item?.metadata?.performancePercent * 100)?.toFixed(0) || "0"}
                            </p>
                          </div>
                        </div>
                        <div className="w-1/3 border border-[#E4E7EC] bg-white rounded shadow-xs flex flex-col items-center">
                          <div className="px-1 py-[2px] flex items-center rounded-t-[4px] w-full justify-center bg-[#F9FAFB] border-b border-b-[#E4E7EC]">
                            <p className="text-xs font-normal text-secondary-600">{t("product:qualityControlRate")}</p>
                          </div>
                          <div className="px-2 py-[2px] flex items-center justify-center bg-white">
                            <p className={`text-base w-[100px] truncate text-center font-semibold text-secondary-600`}>
                              %{item?.metadata?.qualityPercent > 1 ? 1 * 100 : (item?.metadata?.qualityPercent * 100)?.toFixed(0) || "0"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                );
              }
            })}
        </div>
      </div>
      <CustomModal
        isOpen={isOpenSelectProduct}
        setIsOpen={setIsOpenSelectProduct}
        modalTitle={t("chat:startJob")}
        subTitle={t("product:junctionSubtitile")}
        isBadges={false}
        badgesColor={"fill-primary"}
        badgesSize={"md"}
        children={<SelectUserModal selectedJunction={selectedJunctionForPlanned} setIsOpen={setIsOpenSelectProduct} handleData={refetch} />}
      />
      <CustomModal
        isOpen={isOpenJunctionPiece}
        setIsOpen={setIsOpenJunctionPiece}
        onClose={() => {
          setIsOpenJunctionPiece(false);
        }}
        modalTitle={t("product:finishJuction")}
        width="fit-content"
        children={
          <FinishJunctionModal
            t={t}
            setIsOpen={setIsOpenJunctionPiece}
            isOpen={isOpenJunctionPiece}
            handleData={refetch}
            junctionId={finishJunctionId}
            productType={productType}
            type={junctionType}
            closeModal={() => {
              setIsOpenJunctionPiece(false);
            }}
          />
        }
      />
    </>
  );
};

export default EquipmentJunctions;

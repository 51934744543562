import React, { useState } from "react";
import CustomImage from "../../CustomImage";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import { CheckIcon, DeleteIcon, DotsVerticalIcon, EquipmentDetailIcon, EquipmentEditIcon, FlexProductIcon, PlusIcon, TargetIcon } from "../card-icons";
import { useTranslation } from "react-i18next";
import AlertModal from "../../alert-Modal/Alert-Modal";
import { Toast } from "../../../utils/toastify/toast";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import JunctionUpdate from "../../modal/JunctionUpdate";
import CustomModal from "../../modal/CustomModal";
import { junctionService } from "../../../services/junction.service";
import { Tooltip } from "react-tooltip";
import { getFormattedNavigateDate } from "../../../utils/getFomattedNavigateDate";
import { useStore } from "../../../hooks/useStores";

const PlannedCard = ({ data, operation, refetch, setIsSelectUserModal, setSelectedJunction }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isJunction, setIsJunction] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const { auth } = useStore();

  const menuOptions = [
    {
      label: t("product:equipmentDetails"),
      value: "equipmentDetail",
      icon: <EquipmentDetailIcon />,
    },
    {
      label: t("product:editJunction"),
      value: "editJunction",
      icon: <EquipmentEditIcon />,
    },
    { label: t("buttons:delete"), value: "delete", icon: <DeleteIcon /> },
  ];

  const onDeleted = async () => {
    await junctionService.deleteStationForJunction(data?.id).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        refetch();
        setIsAlert(false);
        setDeleteId(data?.id);
      } else {
        Toast("error", res.data.message);
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };

  const convertSeconds = (seconds) => {
    if (isNaN(seconds) || seconds == Infinity) return "---";

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Number(seconds % 60).toFixed(0);

    let formattedTime = "";

    if (minutes > 0) {
      formattedTime += minutes + " dk ";
    }

    if (remainingSeconds > 0) {
      formattedTime += remainingSeconds + " sn";
    }

    return formattedTime.trim();
  };

  const totalEstimatedTime = data?.productType === "flex" ? data?.flexHeader?.estimatedTime : data?.header?.totalEstimatedTime;
  const piece = data?.productType === "flex" ? data?.flexHeader?.quantity : data?.header?.piece;
  const formattedEstimatedOpTime = convertSeconds(totalEstimatedTime / piece);

  return (
    <>
      <div className="flex w-full flex-col border border-t-4 items-start rounded-t-[4px] rounded-b-lg border-secondary-300 bg-white relative group">
        <div className="flex flex-row items-center w-full gap-x-[6px] pt-[6px] px-2 pb-[6px]">
          <div className="flex flex-row items-center gap-x-[8px]  w-[80%] min-w-[80%]">
            {data?.station?.metadata?.image ? (
              <div
                data-tooltip-id={`${data?.id}`}
                data-tooltip-place={"right"}
                data-tooltip-content={`${t("addProcess:stationName")} : ${data?.station?.metadata?.brand + " " + data?.station?.metadata?.model} `}
              >
                <CustomImage
                  borderRadius={2}
                  src={data?.station?.metadata?.image}
                  style={{
                    width: 64,
                    height: 48,
                    minWidth: 64,
                    minHeight: 48,
                    borderRadius: 4,
                    borderWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="w-[64px] min-w-[64px] min-h-[48px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}
            <div className="flex flex-col items-start gap-y-[6px]">
              <div className="flex flex-row items-center gap-x-1 w-full">
                {data?.productType === "flex" ? (
                  <>
                    <FlexProductIcon />
                    <p className="text-sm font-semibold text-secondary-900 truncate w-[300px]">
                      {data?.station?.metadata?.brand + " " + data?.station?.metadata?.model || "--"}
                    </p>
                  </>
                ) : (
                  <p className="text-sm font-semibold text-secondary-900 truncate w-[300px]">
                    {data?.station?.metadata?.brand + " " + data?.station?.metadata?.model || "--"}
                  </p>
                )}
              </div>

              <p className="text-xs font-normal text-secondary-500">{t("product:selectPersonnel")}</p>
            </div>
          </div>
          <button
            onClick={() => {
              if (operation?.operation?.status === "finished") {
                Toast("error", t("product:noPlannedJunction"));
              } else {
                setIsSelectUserModal(true);
                setSelectedJunction(data);
              }
            }}
            type="button"
            className="min-w-[48px] h-[48px] rounded-full border-dashed ml-auto border border-gray-300 flex items-center justify-center mt-1"
          >
            <PlusIcon />
          </button>
        </div>
        <div
          className={`flex flex-row items-center gap-x-4 h-[26px] min-h-[26px] w-full ${
            data?.productType === "flex" ? "bg-[#F9F5FF]" : "bg-[#F9FAFB]"
          }  border-t-[0.5px] border-t-secondary-300 pb-[3px] pl-2 pt-[3px] pr-[3px] rounded-b-lg`}
        >
          <div className="flex flex-row items-center">
            <TargetIcon />
            <p className="text-xs font-medium ml-1 text-secondary-600">{formattedEstimatedOpTime || "--"}</p>
            <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:targetMin")}</p>
          </div>
          {/* <div className="flex flex-row items-center">
            {targetMin < avgActualTime ? <RedAvgIcon /> : <GreenAvgIcon />}
            <p className={`text-xs font-medium ml-1 ${targetMin < avgActualTime ? "text-[#D92D20]" : "text-[#079455]"}`}>{avgActualTime || "--"}</p>
            <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">ortalama</p>
          </div> */}
          <div className="flex flex-row items-center gap-x-1 h-5 min-h-5 px-2 py-[2px] border-[0.5px] bg-white border-secondary-300 rounded ml-auto">
            <CheckIcon />
            <p className="text-xxs text text-secondary-600 font-semibold">{t("product:plannedNews")}</p>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(!showDropDown);
          }}
          className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <DotsVerticalIcon />
        </div>
        {showDropDown && (
          <div
            className={`hidden flex-col absolute min-w-[200px] top-[32px] right-[3px] max-w-[200px] h-fit bg-white border z-[99] rounded-lg px-[6px] py-1 group-hover:flex`}
          >
            {menuOptions?.map((item, index) => {
              const formattedDate = getFormattedNavigateDate(data?.startDate === null ? new Date() : data?.startDate, auth.user.company.dayStartHour);
              return (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (item.value === "editJunction") {
                      setIsJunction(true);
                    }
                    if (item.value === "equipmentDetail") {
                      navigate(`/app/machine/digital-twin/equipment-details/${data?.station?.deviceId}-${data?.station?.id}/${formattedDate}`);
                    }
                    if (item.value === "delete") {
                      setIsAlert(true);
                    }
                    setShowDropDown(false);
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                    item?.value === "delete" || item.value === "finishJunction" ? "hover:bg-error-50" : "hover:bg-secondary-100"
                  }`}
                >
                  {item?.icon}
                  <p
                    className={`font-medium text-sm  ${item?.value === "delete" || item.value === "finishJunction" ? "text-[#D92D20]" : "text-secondary-700"}`}
                  >
                    {item.label}
                  </p>
                </button>
              );
            })}
          </div>
        )}
        <Tooltip className="z-[99999]" id={`${data?.id}`} />
      </div>
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(deleteId);
        }}
      />
      <CustomModal
        isOpen={isJunction}
        setIsOpen={setIsJunction}
        onClose={() => {
          setIsJunction(false);
        }}
        modalTitle={t("product:editJunction")}
        children={
          <JunctionUpdate
            junctionId={data?.id}
            pages={"activeAndPlanned"}
            onClose={() => {
              setIsJunction(false);
            }}
            refetch={refetch}
          />
        }
      />
    </>
  );
};

export default PlannedCard;

import React, { memo } from "react";
import Modal from "react-modal";
import Button from "../buttons/Button";
import { Delete, Loader } from "../../assets/icons/stepsIcons";
import useWindowDimensions from "../../hooks/useWindowDimension";
import { settingsStore } from "../../stores/settings.store";
import { useTranslation } from "react-i18next";

const AlertModal = ({
  title,
  subTitle,
  applyButton,
  isOpen,
  setIsOpen,
  icon,
  label,
  loading,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: 99999,
      borderRadius: "12px",
      width: width > 700 ? "400px" : width < 575 ? "70%" : "60%",
      backgroundColor: settingsStore.theme === "light" ? "#FFF" : "#0F0F0F",
      borderColor: settingsStore.theme === "light" ? "#FFF" : "#292929",
      // borderColor: "black"
    },
  };

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        overlayClassName="fixed inset-0 flex items-center justify-center bg-[#0F0F0F] bg-opacity-50 backdrop-blur-[4px] z-[999]"
      >
        <div className="w-full h-[200px] xs:h-[308px] items-center justify-center flex flex-col ">
          <div className="flex  flex-col items-center gap-2 xs:gap-4 w-full justify-center h-[72px]  ">
            <div
              className={`flex w-12 h-12 p-3  justify-center items-center rounded bg-[#FEE4E2]`}
            >
              {icon ? (
                <div
                  className={`flex w-12 h-12 p-3 justify-center items-center rounded bg-[#FEE4E2]`}
                >
                  {icon}
                </div>
              ) : (
                <div
                  className={`flex w-12 h-12 p-3 justify-center items-center rounded bg-[#FEE4E2]`}
                >
                  <Delete />
                </div>
              )}
            </div>

            <span className="w-full h-7 flex items-center justify-center font-semibold text-lg text-[#101828] dark:text-[#FAFAFA] mb-1 text-center mt-4">
              {title}
            </span>
            <span className="w-full h-14 font-normal items-center text-center justify-center text-sm text-[#667085] dark:text-[#D6D6D6] mt-2">
              {subTitle}
            </span>
            <div className="flex flex-row xs:flex-col xs:gap-3 w-full h-10 items-end gap-x-3 mt-4 ">
              <Button
                onClick={closeModal}
                size="sm"
                label={t("buttons:stop")}
                colorType={"secondary-gray"}
              />
              {loading ? (
                <>
                  {loading ? (
                    <div className="flex items-center justify-center bg-[#D92D20]  rounded-lg w-full h-[38px] max-h-[38px] px-3 py-2">
                      <Loader
                        currentColor="#D92D20"
                        currentFill="currentFill"
                      />
                    </div>
                  ) : (
                    <Button
                      onClick={applyButton}
                      size="sm"
                      label={label || t("buttons:delete")}
                      colorType={"primary-error"}
                    />
                  )}
                </>
              ) : (
                <Button
                  onClick={applyButton}
                  size="sm"
                  label={label || t("buttons:delete")}
                  colorType={"primary-error"}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default memo(AlertModal);

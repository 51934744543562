import React, { useEffect, useState } from "react";
import generateFileUrl from "../../utils/generateFileUrl";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { junctionService } from "../../services/junction.service";
import { Controller, useForm } from "react-hook-form";
import Selector from "../inputs/Selector";
import * as yup from "yup";
import Input from "../inputs/Input";
import HourTimeInput from "../inputs/HourTimeInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { Toast } from "../../utils/toastify/toast";
import Button from "../buttons/Button";
import SplitOrderModal from "../../pages/product/modals/SplitOrderModal";
import CustomModal from "./CustomModal";
import Loading from "../loading/Loading";

const EditJunctionModal = ({ junctionId, onClose, refetch, junctionData }) => {
  const { t } = useTranslation();
  const [isSplit, setIsSplit] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const schema = yup.object({
    workPiece: yup.number(),

    startDate: yup.string(),
    endDate: yup.string(),
    type: yup.string().required(`${t("validation:empty")}`),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleData = async () => {
    setLoading(true);
    const data = await junctionService
      .getQualityJunctionDetail(junctionId)
      .catch((err) => Toast("error", String(err)));
    setData(data);
    setLoading(false);
  };

  const junctionTypes = [
    { label: t("product:work"), value: "work" },
    { label: t("product:setup"), value: "setup" },
  ];

  const onSubmit = async (value) => {
    const send = {
      junctionId: junctionId,
      workPiece: value?.workPiece,
      startDate: moment(value?.startDate).utc().format(),
      endDate: moment(value?.endDate).utc().format(),
      type: value?.type,
    };
    const startDateUnix = moment(value?.startDate).unix();
    const endDateUnix = moment(value?.endDate).unix();
    
    // if (data?.metadata?.qualityStatus == "accepted") {
    //   await junctionService.updateJunction(junctionId, send).then((res) => {
    //     if (res.data.code === 0) {
    //       refetch();
    //       onClose();
    //       Toast("success", res?.data?.message);
    //     } else {
    //       onClose();
    //       Toast("error", res?.data?.message);
    //     }
    //   });
    // } else {
    //   if (startDateUnix > endDateUnix) {
    //     Toast("error", t("product:qualityUpdateError"));
    //     return;
    //   } else {
    //     await orderService.qualityForm(data?.metadata?.id, send).then((res) => {
    //       if (res.data.code === 0) {
    //         refetch();
    //         onClose();
    //         Toast("success", res?.data?.message);
    //       } else {
    //         onClose();
    //         Toast("error", res?.data?.message);
    //       }
    //     });
    //   }
    // }
  };

  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    setValue("workPiece", data?.metadata?.madeWorkCount);
    setValue("startDate", data?.startDate);
    setValue("endDate", data?.endDate);
    setValue("type", data?.type);
  }, [data]);

  return (
    <>
      {loading ? (
        <div className="flex items-center w-[500px] justify-center  h-[450px]">
          <Loading size={44} primary={"#B54708"} secondary={"#fff"} />
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-[500px] flex flex-col mt-6"
        >
          <div className="w-full flex-col flex gap-4 h-fit">
            <div className="w-full flex items-center h-[72px] gap-3">
              <img
                src={generateFileUrl(data?.job?.product?.image)}
                className="w-[96px] h-[72px] rounded border border-gray-300"
              />
              <div
                //   data-tooltip-id={`${data?.id}`}
                //   data-tooltip-place={"top"}
                //   data-tooltip-content={`${t("product:productName")}: ${
                //     data?.job?.product?.name
                //   } `}
                className="flex flex-col justify-between"
              >
                <p className="font-semibold text-gray-900 w-[250px] truncate">
                  {data?.job?.product?.name}
                </p>
                <p className="text-sm text-gray-600">
                  {t("product:orderNo")}: {data?.job?.orderNo}
                </p>
                <div className="flex items-center gap-3">
                  <div className="flex items-center gap-1">
                    <QtyIcon />
                    <p className="text-sm text-gray-600">
                      {data?.metadata?.madeWorkCount} / {data?.job?.piece}
                    </p>
                  </div>
                  <div className="flex items-center gap-1">
                    <ClockIcon />
                    <p className="text-sm text-gray-600">
                      {moment(data?.endDate)
                        .diff(data?.startDate, "minutes")
                        .toFixed(1) + "dk"}
                    </p>
                  </div>

                  {/* <Tooltip id={`${data?.id}`} /> */}
                </div>
                <div
                  data-tooltip-id={`${data?.id}`}
                  data-tooltip-place={"top"}
                  data-tooltip-content={"Bitiş tarihi ve saati"}
                  className="flex items-center gap-1"
                >
                  <CalendarIcon />
                  <p className="text-sm text-gray-600">
                    {moment(data?.endDate).format("DD-MM-YYYY HH:mm")}
                  </p>
                </div>
              </div>
            </div>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Selector
                  value={value}
                  items={junctionTypes}
                  theme={"product"}
                  label={t("product:junctionType")}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors?.source ? "error" : ""}
                  errorMessage={errors?.source ? errors?.source?.message : ""}
                />
              )}
              name="type"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  theme={"product"}
                  label={t("product:numberOfProducts")}
                  errorMessage={errors?.workPiece?.message}
                  validate={errors?.workPiece ? "error" : ""}
                />
              )}
              name="workPiece"
            />
            <div className="flex flex-row items-center gap-x-3 w-full">
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <HourTimeInput
                    label={t("product:jobStartTime")}
                    validate={errors.startDate ? "error" : ""}
                    onChange={(v) => {
                      setValue(
                        `startDate`,
                        moment(data?.startDate)
                          .set("hour", v.split(":")[0])
                          .set("minute", v.split(":")[1])
                          .format("YYYY-MM-DDTHH:mm:ss")
                      );
                    }}
                    // minHour={dayStartHour}
                    theme={"product"}
                    value={moment(value).format("HH:mm")}
                    errorMessage={
                      errors.startDate ? errors.startDate.message : ""
                    }
                  />
                )}
                name="startDate"
              />
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <HourTimeInput
                    theme={"product"}
                    label={t("product:jobEndTime")}
                    onChange={(v) => {
                      setValue(
                        `endDate`,
                        moment(data?.endDate)
                          .set("hour", v.split(":")[0])
                          .set("minute", v.split(":")[1])
                          .format("YYYY-MM-DDTHH:mm:ss")
                      );
                    }}
                    value={moment(value).format("HH:mm")}
                    validate={errors.endDate ? "error" : ""}
                    errorMessage={errors.endDate ? errors.endDate.message : ""}
                  />
                )}
                name="endDate"
              />
            </div>
            <div className="w-full flex items-center flex-row gap-x-3 mt-2">
              <Button
                label={t("buttons:stop")}
                colorType={"secondary-gray"}
                size={"md"}
                onClick={onClose}
              />
              <Button
                type={"submit"}
                label={t("buttons:update")}
                colorType={"primary-product"}
                size={"md"}
              />
            </div>
            <div
              onClick={(e) => {
                setIsSplit(true);
              }}
              className=" font-semibold cursor-pointer text-[#B54708] text-md flex items-center justify-center"
            >
              {t("product:splitJob")}
            </div>
          </div>
        </form>
      )}

      <CustomModal
        isOpen={isSplit}
        setIsOpen={setIsSplit}
        onClose={() => {
          setIsSplit(false);
        }}
        modalTitle={t("product:splitJob")}
        children={
          <SplitOrderModal
            handleData={refetch}
            setIsOpen={setIsSplit}
            closeModal={() => {
              setIsSplit(false);
            }}
            id={junctionId}
            dataJunction={junctionData}
          />
        }
      />
    </>
  );
};

export default EditJunctionModal;

const ClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clip-path="url(#clip0_9923_13289)">
        <path
          d="M6 3V6L8 7M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
          stroke="#475467"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9923_13289">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const CalendarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M10.5 5H1.5M8 1V3M4 1V3M3.9 11H8.1C8.94008 11 9.36012 11 9.68099 10.8365C9.96323 10.6927 10.1927 10.4632 10.3365 10.181C10.5 9.86012 10.5 9.44008 10.5 8.6V4.4C10.5 3.55992 10.5 3.13988 10.3365 2.81901C10.1927 2.53677 9.96323 2.3073 9.68099 2.16349C9.36012 2 8.94008 2 8.1 2H3.9C3.05992 2 2.63988 2 2.31901 2.16349C2.03677 2.3073 1.8073 2.53677 1.66349 2.81901C1.5 3.13988 1.5 3.55992 1.5 4.4V8.6C1.5 9.44008 1.5 9.86012 1.66349 10.181C1.8073 10.4632 2.03677 10.6927 2.31901 10.8365C2.63988 11 3.05992 11 3.9 11Z"
        stroke="#475467"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const QtyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M4.3125 1.75H2.78571C2.07563 1.75 1.5 2.32563 1.5 3.03571V8.90179C1.5 9.61187 2.07563 10.1875 2.78571 10.1875H9.21429C9.92437 10.1875 10.5 9.61187 10.5 8.90179V3.03571C10.5 2.32563 9.92437 1.75 9.21429 1.75H7.6875M4.3125 1.75V5.02098C4.3125 5.06877 4.36279 5.09985 4.40554 5.07848L6 4.28125L7.59446 5.07848C7.63721 5.09985 7.6875 5.06877 7.6875 5.02098V1.75M4.3125 1.75H7.6875"
        stroke="#475467"
      />
    </svg>
  );
};

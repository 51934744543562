import React from "react";
import { NoEquIcon } from "../../../../assets/icons/machineIcons";
import CustomImage from "../../../../components/CustomImage";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";

const InternalPlannedStationCard = ({ filledData, a }) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col items-start">
      <p className="text-sm font-semibold text-secondary-700 w-[190px] truncate">{a?.metadata?.brand + " " + a?.metadata?.model}</p>
      <div className="flex flex-row items-center gap-x-3 mt-1">
        {a?.metadata?.image ? (
          <div data-tooltip-id={`${a?.id}`} data-tooltip-place={"right"} data-tooltip-content={`${t("addProcess:equipmentName")} : ${a?.metadata?.brand + "" + a?.metadata?.model} `} className="cursor pointer">
            <CustomImage
              borderRadius={2}
              src={a?.metadata?.image}
              style={{
                width: 72,
                height: 54,
                minWidth: 72,
                minHeight: 54,
                borderRadius: 4,
                borderWidth: 1,
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                display: "flex",
                borderColor: "#0000001A",
                backgroundColor: "#fff",
              }}
            />
          </div>
        ) : (
          <div className="w-[72px] min-w-[72px] min-h-[54px] h-[54px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
            <NoEquIcon />
          </div>
        )}
        <div className="flex flex-col items-start h-[54px] min-h-[54px] px-2 py-[6px] gap-y-1 rounded border border-[#E4E7EC]">
          <p className="text-xs font-normal text-secondary-600">{t("product:totalPlannedWorks")}</p>
          <p className="text-sm font-medium text-secondary-700">{Number(a?.metadata?.plannedWorkLoad / 86400).toFixed(1)} {t("product:day")}</p>
        </div>
      </div>
      <Tooltip className="z-[120]" id={`${a?.id}`} />
    </div>
  );
};

export default InternalPlannedStationCard;

import React, { useState } from "react";
import { NoEquIcon, ProductDetailIcon } from "../../../assets/icons/machineIcons";
import CustomImage from "../../CustomImage";
import {
  AcceptedIcon,
  ClockIcon,
  DeleteIcon,
  DotsVerticalIcon,
  EndClockIcon,
  EquCalculate,
  EquipmentDetailIcon,
  EquipmentEditIcon,
  FinishJunctionIcon,
  FlexProductIcon,
  GreenAvgIcon,
  PersonnelDetailIcon,
  PieceIcon,
  RedAvgIcon,
  RejectedIcon,
  StartClockIcon,
  TargetIcon,
} from "../card-icons";
import { useTranslation } from "react-i18next";
import NoAvatar from "../../avatar/NoAvatar";
import AlertModal from "../../alert-Modal/Alert-Modal";
import JunctionUpdate from "../../modal/JunctionUpdate";
import CustomModal from "../../modal/CustomModal";
import { useNavigate, useParams } from "react-router-dom";
import { junctionService } from "../../../services/junction.service";
import { Toast } from "../../../utils/toastify/toast";
import { secondToHoursAndMinute2 } from "../../../utils/secondToHoursAndMinute";
import moment from "moment";
import { Tooltip } from "react-tooltip";
import { timeFormatter } from "../../../utils/timezoneFormatter";
import { getFormattedNavigateDate } from "../../../utils/getFomattedNavigateDate";
import { useStore } from "../../../hooks/useStores";
const OperationDetailDoneCard = ({ refetch, data, items }) => {
  let { dates } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isJunction, setIsJunction] = useState(false);
  const { auth } = useStore();

  const filteredArray = items?.filter((item) => item?.id == data?.junctionId);

  const menuOptions = [
    {
      label: t("product:personnelDetail"),
      value: "personnelDetail",
      icon: <PersonnelDetailIcon />,
    },
    {
      label: t("product:editJunction"),
      value: "editJunction",
      icon: <EquipmentEditIcon />,
    },
    {
      label: t("product:equipmentDetails"),
      value: "equipmentDetail",
      icon: <EquipmentDetailIcon />,
    },
    { label: t("buttons:delete"), value: "delete", icon: <DeleteIcon /> },
  ];

  const convertSeconds = (seconds) => {
    if (isNaN(seconds) || seconds == Infinity) return "---";

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Number(seconds % 60).toFixed(0);

    let formattedTime = "";

    if (minutes > 0) {
      formattedTime += minutes + " dk ";
    }

    if (remainingSeconds > 0) {
      formattedTime += remainingSeconds + " sn";
    }

    return formattedTime.trim();
  };

  const oneOrderTime = (start, end, count, type) => {
    const diff = moment(end).diff(moment(start), "seconds");
    const oneOrder = diff / count;
    return type === "setup" ? diff : `${oneOrder?.toFixed(0)}`;
  };
  // const piece = filteredArray[0]?.productType === "flex" ? filteredArray[0]?.flexHeader?.quantity : filteredArray[0]?.header?.piece;
  // let formattedTime =
  //   filteredArray[0]?.type === "setup"
  //     ? convertSeconds(filteredArray[0]?.productType === "flex" ? filteredArray[0]?.flexHeader?.estimatedSettingsTime * 60 : filteredArray[0]?.header?.internalOperation?.estimatedSettingsTime * 60)
  //     : convertSeconds(filteredArray[0]?.productType === "flex" ? filteredArray[0]?.flexHeader?.totalEstimatedTime : filteredArray[0]?.header?.totalEstimatedTime / filteredArray[0]?.productType === "flex" ? filteredArray[0]?.flexHeader?.quantity : filteredArray[0]?.header?.piece);
  // const totalEstimatedTime = filteredArray[0]?.productType === "flex" ? filteredArray[0]?.flexHeader?.estimatedTime : filteredArray[0]?.header?.internalOperation?.estimatedTime;
  // const setupEstimatedTime = filteredArray[0]?.productType === "flex" ? filteredArray[0]?.flexHeader?.estimatedSettingsTime * 60 : filteredArray[0]?.header?.internalOperation?.estimatedSettingsTime * 60;
  // const formattedEstimatedOpTime =
  //   filteredArray[0]?.type === "setup"
  //     ? convertSeconds(filteredArray[0]?.header?.internalOperation?.estimatedSettingsTime * 60)
  //     : convertSeconds(totalEstimatedTime);

  // const startDate = filteredArray[0]?.startDate;
  // const endDate = filteredArray[0]?.endDate;
  // const madeWorkCount = filteredArray[0]?.metadata?.madeWorkCount;
  // const type = filteredArray[0]?.type;
  // const oneOrderTimeInSeconds = oneOrderTime(startDate, endDate, madeWorkCount, type);
  // const formattedOneOrderTime = convertSeconds(oneOrderTimeInSeconds);

  // const isRed = filteredArray[0]?.type === "setup" ? setupEstimatedTime < oneOrderTimeInSeconds : totalEstimatedTime / piece < oneOrderTimeInSeconds;

  const totalEstimatedTime =
    filteredArray[0]?.productType === "flex" ? filteredArray[0]?.flexHeader?.estimatedTime : filteredArray[0]?.header?.internalOperation?.estimatedTime;
  const setupEstimatedTime =
    filteredArray[0]?.productType === "flex"
      ? filteredArray[0]?.flexHeader?.estimatedSettingsTime * 60
      : filteredArray[0]?.header?.internalOperation?.estimatedSettingsTime * 60;
  const formattedEstimatedOpTime =
    filteredArray[0]?.type === "setup"
      ? convertSeconds(
          filteredArray[0]?.productType === "flex"
            ? filteredArray[0]?.flexHeader?.estimatedSettingsTime * 60
            : filteredArray[0]?.header?.internalOperation?.estimatedSettingsTime * 60
        )
      : convertSeconds(totalEstimatedTime);

  const startDate = filteredArray[0]?.startDate;
  const endDate = filteredArray[0]?.endDate;
  const madeWorkCount = filteredArray[0]?.metadata?.madeWorkCount;
  const type = filteredArray[0]?.type;
  const oneOrderTimeInSeconds = oneOrderTime(startDate, endDate, madeWorkCount, type);
  const formattedOneOrderTime = convertSeconds(oneOrderTimeInSeconds);
  const isRed = filteredArray[0]?.type == "setup" ? setupEstimatedTime < oneOrderTimeInSeconds : totalEstimatedTime < oneOrderTimeInSeconds;

  const onDeleted = async () => {
    await junctionService.deleteJunction(data?.junctionId, deleteId).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        refetch();
        setIsAlert(false);
        setDeleteId(data?.junctionId);
      } else {
        Toast("error", res.data.message);
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };
  return (
    <>
      <div className="cursor-pointer flex flex-row items-center gap-x-3 w-full">
        <div className="flex items-center flex-col gap-y-1 justify-center border border-secondary-300 rounded-t-[4px] rounded-b-lg p-1 w-[88px] min-w-[88px] bg-[#F9FAFB]">
          <p className="text-xs w-[75px] truncate text-center font-semibold text-secondary-700">{filteredArray[0]?.station?.metadata?.orderNumber || "--"}</p>
          {filteredArray[0]?.station?.metadata?.image ? (
            <div
              onClick={() => navigate(`/app/machine/digital-twin/equipment-details/${filteredArray[0]?.deviceId}-${filteredArray[0]?.station?.id}/${dates}`)}
              data-tooltip-id={`${data?.junctionId}`}
              data-tooltip-place={"right"}
              data-tooltip-content={`
          ${filteredArray[0]?.station?.metadata?.brand} ${filteredArray[0]?.station?.metadata?.model} 
          `}
            >
              <CustomImage
                borderRadius={2}
                src={filteredArray[0]?.station?.metadata?.image}
                style={{
                  width: 80,
                  height: 60,
                  borderRadius: 4,
                  borderWidth: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  display: "flex",
                  borderColor: "#0000001A",
                  backgroundColor: "#fff",
                }}
              />
            </div>
          ) : (
            <div
              onClick={() => navigate(`/app/machine/digital-twin/equipment-details/${filteredArray[0]?.deviceId}-${filteredArray[0]?.station?.id}/${dates}`)}
              className="w-[80px] min-w-[80px] h-[60px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
            >
              <NoEquIcon />
            </div>
          )}
        </div>
        <div
          className={`flex w-full flex-col border border-t-4 cursor-pointer ${
            data?.type == "work" ? "border-t-success-500 " : data?.type === "setup" ? "border-t-[#EAAA08]" : "border-t-[#98A2B3]"
          } items-start rounded-t-[4px] rounded-b-lg border-secondary-300 bg-white relative group`}
        >
          <div className="flex flex-row items-center w-full gap-x-[6px] pt-[6px] px-2 pb-[6px]">
            <div className="flex flex-row items-center gap-x-[8px]  w-[60%] min-w-[60%]">
              {filteredArray[0]?.productType === "flex" ? (
                <>
                  {filteredArray[0]?.flexProduct?.image ? (
                    <div
                      data-tooltip-id={`${data?.junctionId}`}
                      data-tooltip-place={"right"}
                      data-tooltip-content={`
              ${filteredArray[0]?.flexProduct?.name} 
              `}
                    >
                      <CustomImage
                        borderRadius={2}
                        src={filteredArray[0]?.flexProduct?.image}
                        style={{
                          width: 64,
                          height: 48,
                          minWidth: 64,
                          minHeight: 48,
                          borderRadius: 4,
                          borderWidth: 1,
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          display: "flex",
                          borderColor: "#0000001A",
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      data-tooltip-id={`${data?.id}`}
                      data-tooltip-place={"right"}
                      data-tooltip-content={`
              ${filteredArray[0]?.job?.product?.name} 
              `}
                      className="w-[64px] min-w-[64px] h-[48px] bg-[#DC6803] flex border border-[#DC6803] items-center justify-center font-medium text-white text-xl cursor-pointer"
                    >
                      {filteredArray[0]?.flexProduct?.name?.slice(0, 2)?.toUpperCase()}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {filteredArray[0]?.job?.product?.image ? (
                    <div
                      data-tooltip-id={`${data?.junctionId}`}
                      data-tooltip-place={"right"}
                      data-tooltip-content={`
              ${filteredArray[0].job?.product?.name} 
              `}
                    >
                      <CustomImage
                        borderRadius={2}
                        src={filteredArray[0]?.job?.product?.image}
                        style={{
                          width: 64,
                          height: 48,
                          minWidth: 64,
                          minHeight: 48,
                          borderRadius: 4,
                          borderWidth: 1,
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          display: "flex",
                          borderColor: "#0000001A",
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      data-tooltip-id={`${data?.id}`}
                      data-tooltip-place={"right"}
                      data-tooltip-content={`
              ${filteredArray[0].job?.product?.name} 
              `}
                      className="w-[64px] min-w-[64px] h-[48px] bg-[#DC6803] flex border border-[#DC6803] items-center justify-center font-medium text-white text-xl cursor-pointer"
                    >
                      {filteredArray[0].job?.product?.name?.slice(0, 2)?.toUpperCase()}
                    </div>
                  )}
                </>
              )}

              <div className="flex flex-col items-start gap-y-[6px]">
                <div className="flex flex-row items-center gap-x-1 w-full">
                  {filteredArray[0]?.productType === "flex" ? (
                    <>
                      <FlexProductIcon />
                      <p className="text-sm font-semibold text-secondary-900 truncate w-[140px]">{filteredArray[0]?.flexProduct?.name || "--"}</p>
                    </>
                  ) : (
                    <p className="text-sm font-semibold text-secondary-900 truncate w-[140px]">{filteredArray[0]?.job?.product?.name || "--"}</p>
                  )}
                </div>

                <div className="flex flex-row items-center">
                  <div className="flex flex-row items-center">
                    <ClockIcon />
                    <p className="text-xs font-medium ml-1 text-secondary-600">
                      {moment(filteredArray[0]?.startDate)?.format("HH:mm")}-{moment(filteredArray[0]?.endDate)?.format("HH:mm")}
                    </p>
                  </div>
                  <p className="text-xxs font-normal mt-[1px] ml-[2px] text-secondary-600">{t("product:hours")}</p>
                </div>
                {/* <div className="flex flex-row items-center">
                    <PieceIcon />
                    <p className="text-xs font-medium ml-1 text-secondary-600">{filteredArray[0]?.madeWorkCount}</p>
                    <p className="text-xxs font-normal mt-[1px] ml-[2px] text-secondary-600">{t("product:pcs")}</p>
                  </div> */}
              </div>
            </div>
            {filteredArray[0]?.user?.avatar ? (
              <div
                data-tooltip-id={`${data?.junctionId}`}
                data-tooltip-place={"left"}
                data-tooltip-content={`
          ${filteredArray[0]?.user?.name + " " + filteredArray[0]?.user?.lastName} 
          `}
                className="cursor-pointer"
              >
                <CustomImage
                  src={filteredArray[0]?.user?.avatar}
                  style={{
                    width: 48,
                    height: 48,
                    minWidth: 48,
                    minHeight: 48,
                    flexDirection: "column",
                    borderRadius: 100,
                    display: "flex",
                    border: "1px solid #0000001A",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div
                data-tooltip-id={`${data?.junctionId}`}
                data-tooltip-place={"left"}
                data-tooltip-content={`
          ${filteredArray[0]?.user?.name + " " + filteredArray[0]?.user?.lastName} 
          `}
                className="cursor-pointer"
              >
                <NoAvatar size={48} fontSize={22} name={filteredArray[0]?.user?.name} lastName={filteredArray[0]?.user?.lastName} color={"#B54708"} />
              </div>
            )}
          </div>
          <div className="flex flex-col z-[20] items-start rounded border-[0.5px] ml-auto absolute border-[#D0D5DD] w-[86px] min-w-[86px] bg-white top-[6px] right-[6px]">
            {filteredArray[0]?.metadata?.qualityStatus === "waiting" ? (
              <div
                className={`flex flex-row items-center justify-center gap-x-1 h-5 min-h-5 px-[2px] py-[2px] ${
                  filteredArray[0]?.productType === "flex" ? "bg-[#F9F5FF]" : "bg-[#F9FAFB]"
                }  w-full rounded-t-[4px] border-b-[0.5px] border-secondary-300 ml-auto`}
              >
                <p className="text-xxs text text-secondary-600 font-semibold"> {t("product:applied")}</p>
              </div>
            ) : (
              <div
                className={`flex flex-row items-center justify-center gap-x-1 h-5 min-h-5 px-[2px] py-[2px] ${
                  filteredArray[0]?.productType === "flex" ? "bg-[#F9F5FF]" : "bg-[#F9FAFB]"
                }  w-full rounded-t-[4px] border-b-[0.5px] border-secondary-300 ml-auto`}
              >
                <p className="text-xxs text text-secondary-600 font-semibold"> {t("product:qualityNew")}</p>
                <div className="h-[14px] min-h-[14px] flex items-center justify-center bg-[#B54708] px-1 py-[1px] rounded">
                  <p className="text-xxs font-semibold text-white">
                    {" "}
                    %
                    {filteredArray[0]?.productType === "flex"
                      ? filteredArray[0]?.flexHeader?.qualityPercent
                      : filteredArray[0]?.header?.internalOperation?.qualityPercent || "--"}
                  </p>
                </div>
              </div>
            )}

            <div className="flex flex-row items-center justify-center w-full px-[6px] py-1">
              <div className="w-[14px] h-[14px] min-w-[14px] min-h-[14px]">
                {filteredArray[0]?.metadata?.qualityStatus === "waiting" ? <PieceIcon /> : <AcceptedIcon />}
              </div>
              <p className={`text-xs font-medium ml-1 ${filteredArray[0]?.metadata?.qualityStatus === "waiting" ? "text-secondary-600" : "text-[#079455]"} `}>
                {filteredArray[0]?.metadata?.qualityStatus === "waiting"
                  ? filteredArray[0]?.madeWorkCount || "0"
                  : filteredArray[0]?.metadata?.acceptedWorkCount}
              </p>
              <p className="text-[10px] font-normal ml-0.5 text-secondary-500">{t("product:pcs")}</p>
            </div>
            <div className="flex flex-row items-center justify-center w-full px-[6px] py-1">
              <div className="w-[14px] h-[14px] min-w-[14px] min-h-[14px]">
                {filteredArray[0]?.metadata?.qualityStatus === "waiting" ? <EquCalculate /> : <RejectedIcon />}
              </div>
              <p className={`text-xs font-medium ${filteredArray[0]?.metadata?.qualityStatus === "waiting" ? " text-[#6941C6]" : "text-error-600"} ml-1 `}>
                {filteredArray[0]?.metadata?.qualityStatus === "waiting"
                  ? filteredArray[0]?.metadata?.calculateWorkCount || "0"
                  : filteredArray[0]?.metadata?.rejectedWorkCount || "0"}
              </p>
              <p className="text-xxs font-normal ml-0.5 text-secondary-500">{t("product:pcs")}</p>
            </div>
          </div>
          <div
            className={`flex flex-row items-center gap-x-4 h-[26px] min-h-[26px] ${
              filteredArray[0]?.productType === "flex" ? "bg-[#F9F5FF]" : "bg-[#F9FAFB]"
            } w-full border-t-[0.5px] border-t-secondary-300 pb-[3px] pl-2 pt-[3px] pr-[3px] rounded-b-lg`}
          >
            <div className="flex flex-row items-center">
              <TargetIcon />
              <p className="text-xs font-medium ml-1 text-secondary-600">{formattedEstimatedOpTime || "--"}</p>
              <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:targetMinActualss")}</p>
            </div>
            <div className="flex flex-row items-center">
              {isRed ? <RedAvgIcon /> : <GreenAvgIcon />}
              <p className={`text-xs font-medium ml-1 ${isRed ? "text-[#D92D20]" : "text-[#079455]"}`}>{formattedOneOrderTime || "--"}</p>
              <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:average")}</p>
            </div>
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setShowDropDown(!showDropDown);
            }}
            className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[3px] z-[50] items-center justify-center cursor-pointer"
          >
            <DotsVerticalIcon />
          </div>
          {showDropDown && (
            <div
              className={`hidden flex-col absolute min-w-[200px] top-[32px] right-[3px] max-w-[200px] h-fit bg-white border z-[99] rounded-lg px-[6px] py-1 group-hover:flex`}
            >
              {menuOptions?.map((item, index) => {
                const formattedDate = getFormattedNavigateDate(data?.start, auth.user.company.dayStartHour);
                return (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();

                      if (item.value === "productDetail") {
                        navigate(`/app/product/mrp/work-in-process/detail/${filteredArray[0]?.job?.id}+${filteredArray[0]?.header?.step}`);
                      }
                      if (item?.value === "personnelDetail") {
                        navigate(`/app/machine/digital-twin/user-detail/${data?.status}/${formattedDate}`);
                      }

                      if (item.value === "equipmentDetail") {
                        navigate(
                          `/app/machine/digital-twin/equipment-details/${filteredArray[0]?.station?.deviceId}-${filteredArray[0]?.station?.id}/${formattedDate}`
                        );
                      }
                      if (item.value === "delete") {
                        setIsAlert(true);
                      }
                      if (item.value === "editJunction") {
                        // setIsJunction(true);
                        navigate(
                          `/app/machine/digital-twin/equipment-details/${`${filteredArray[0]?.station?.deviceId}-${filteredArray[0]?.station?.id}`}/${moment(filteredArray[0]?.startDate).format("YYYY-MM-DD")}/manuel-detail/edit/${moment(filteredArray[0]?.startDate)?.subtract(1, "hour")?.format("YYYY-MM-DDTHH:mm:ss")}+${moment(filteredArray[0]?.endDate)?.add(1, "hour")?.format("YYYY-MM-DDTHH:mm:ss")}/event/${filteredArray[0]?.id}`
                        );
                      }
                      setShowDropDown(false);
                    }}
                    key={index}
                    type="button"
                    className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                      item?.value === "delete" || item.value === "finishJunction" ? "hover:bg-error-50" : "hover:bg-secondary-100"
                    }`}
                  >
                    {item?.icon}
                    <p
                      className={`font-medium text-sm  ${
                        item?.value === "delete" || item.value === "finishJunction" ? "text-[#D92D20]" : "text-secondary-700"
                      }`}
                    >
                      {item.label}
                    </p>
                  </button>
                );
              })}
            </div>
          )}
        </div>
        <Tooltip className="z-[120]" id={`${data?.junctionId}`} />
      </div>
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(deleteId);
        }}
      />
      <CustomModal
        isOpen={isJunction}
        setIsOpen={setIsJunction}
        onClose={() => {
          setIsJunction(false);
        }}
        modalTitle={t("product:editJunction")}
        children={
          <JunctionUpdate
            junctionId={data?.junctionId}
            onClose={() => {
              setIsJunction(false);
            }}
            refetch={refetch}
          />
        }
      />
    </>
  );
};

export default OperationDetailDoneCard;

import React, { useEffect, useRef, useState } from 'react'
import { ArrowDownIcon } from '../../../assets/icons/commonIcons';
import { useTranslation } from 'react-i18next';
import { CloseIconSort } from '../../../assets/icons/equipmentIcon';

const RawMaterialListSort = ({ sortValue, setSortValue, ascDesc, setAscDesc }) => {
    const { t } = useTranslation();
    const menuRef = useRef();
    const [isSortDropdown, setIsSortDropdown] = useState(false);
    const [selectedSort, setSelectedSort] = useState(null);
    const handleSortCancel = (e) => {
        e.stopPropagation();
        setSelectedSort(null);
        setSortValue(null);
        setIsSortDropdown(false);
    };
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsSortDropdown(false);
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    const options = [
        {
            label: t("product:reserveStockNew"),
            value: "reserveStock",
            icon: <ReserveStockIcon />,
            number: 1,
            filterName:t("product:increasing"),
            status: "Artan",
            
        },
        {
            label: t("product:reserveStockNew"),
            value: "reserveStock",
            icon: <ReserveStockIcon />,
            number: 2,
            filterName:t("product:decreasing"),
            status: "Azalan",
        },
        {
            label: t("product:availableStock"),
            value: "freeStock",
            icon: <FreeStockIcon />,
            number: 3,
            filterName:t("product:increasing"),
            status: "Artan",
        },
        {
            label: t("product:availableStock"),
            value: "freeStock",
            icon: <FreeStockIcon />,
            number: 4,
            filterName:t("product:decreasing"),
            status: "Azalan",
        },
        {
            label: t("product:price"),
            value: "price",
            icon: <PriceIcon />,
            number: 5,
            filterName:t("product:increasing"),
            status: "Artan",
        },
        {

            label: t("product:price"),
            value: "currentPrice",
            icon: <PriceIcon />,
            number: 6,
            filterName:t("product:decreasing"),
            status: "Azalan",
        },
        {


            label: t("product:lastTransaction"),
            value: "updatedDate",
            icon: <FreeStockIcon />,
            number: 7,
            filterName:t("product:nearestDate"),
            status: "Artan",
        },
        {

            label: t("product:lastTransaction"),
            value: "updatedDate",
            icon: <FreeStockIcon />,
            number: 8,
            filterName:t("product:latestDate"),
            status: "Azalan",
        },

    ]

    return (
        <div className='flex w-full h-full relative' ref={menuRef}>
            {selectedSort ? (
                <div
                    onClick={() => setIsSortDropdown(true && !isSortDropdown)}
                    className={`flex border hover:bg-secondary-50 border-secondary-300 px-[14px] py-[10px] rounded-lg gap-x-1 justify-center items-center cursor-pointer ${isSortDropdown ? "shadow-ring-gray" : ""
                        }`}
                >

                    <span className="text-sm font-semibold text-secondary-700 whitespace-nowrap">{selectedSort}</span>
                    <span onClick={handleSortCancel} className="p-[5px] min-w-[20px] min-h-[20px] hover:bg-secondary-100 rounded">
                        <CloseIconSort />
                    </span>
                </div>
            ) : (
                <div
                    onClick={() => setIsSortDropdown(true && !isSortDropdown)}
                    className={`flex border hover:bg-secondary-50 border-secondary-300 px-[14px] py-[10px] rounded-lg gap-x-1 justify-center items-center cursor-pointer ${isSortDropdown ? "shadow-ring-gray" : ""
                        }`}
                >
                    <span className="text-sm font-semibold text-secondary-700 whitespace-nowrap">{t("product:sorted")}</span>
                    <span className="min-w-[20px] min-h-[20px]">
                        <ArrowDownIcon />
                    </span>
                </div>
            )}
            {isSortDropdown === true && (
                <div className={"flex flex-col min-w-[250px] max-w-[250px] h-fit absolute top-[48px] right-0 bg-white border z-[999999999] rounded-lg shadow-lg py-1  gap-y-[2px] border-secondary-200"}>
                    {options?.map((item, index) => (
                        <div
                            onClick={() => {
                                if (item?.status == 'Artan') {
                                    setAscDesc(false);
                                } else {
                                    setAscDesc(true);
                                }
                                setSelectedSort(item.label);
                                setSortValue(item.value);
                                setIsSortDropdown(false);
                            }}
                            key={index}
                            className={`flex items-center min-h-[38px] max-h-[38px] ${item?.number === 2 || item?.number === 4 || item?.number === 6 ? "border-b " : "border-b-0 rounded-md"}  gap-x-3 px-[12px] py-[9px] cursor-pointer hover:bg-secondary-50 ${selectedSort === item.label ? "bg-secondary-50" : ""
                                }`}
                        >
                            <div className="flex gap-x-2 items-center">
                                <div className="flex min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px] justify-end items-center">{item?.icon}</div>
                                <span className="text-sm text-secondary-700 font-medium">{item?.label}</span>
                            </div>
                            <div className='ml-auto flex items-center'>
                                <p className='text-xs text-secondary-500'>{item?.filterName}</p>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default RawMaterialListSort

const ReserveStockIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clip-path="url(#clip0_18792_22443)">
                <path d="M5.50065 1.33398H3.83398C2.45327 1.33398 1.33398 2.45327 1.33398 3.83398V12.1673C1.33398 13.548 2.45327 14.6673 3.83398 14.6673H6.51917M5.50065 1.33398V5.1197C5.50065 5.21676 5.60408 5.27882 5.68973 5.23314L8.00065 4.00065L10.3116 5.23314C10.3972 5.27882 10.5007 5.21676 10.5007 5.1197V1.33398M5.50065 1.33398H10.5007M10.5007 1.33398H12.1673C13.548 1.33398 14.6673 2.45327 14.6673 3.83398V7.2103M10.7858 11.5088H10.485C9.93274 11.5088 9.48503 11.9565 9.48503 12.5088V13.6673C9.48503 14.2196 9.93274 14.6673 10.485 14.6673H13.6653C14.2175 14.6673 14.6653 14.2196 14.6653 13.6673V12.5088C14.6653 11.9565 14.2175 11.5088 13.6653 11.5088H13.3672M10.7858 11.5088V10.0321C10.7858 9.31924 11.3637 8.74139 12.0765 8.74139V8.74139C12.7893 8.74139 13.3672 9.31924 13.3672 10.0321V11.5088M10.7858 11.5088H13.3672" stroke="#667085" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_18792_22443">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
};

const FreeStockIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clip-path="url(#clip0_18792_51838)">
                <path d="M5.50065 1.33398H3.83398C2.45327 1.33398 1.33398 2.45327 1.33398 3.83398V12.1673C1.33398 13.548 2.45327 14.6673 3.83398 14.6673H6.51917M5.50065 1.33398V5.1197C5.50065 5.21676 5.60408 5.27882 5.68973 5.23314L8.00065 4.00065L10.3116 5.23314C10.3972 5.27882 10.5007 5.21676 10.5007 5.1197V1.33398M5.50065 1.33398H10.5007M10.5007 1.33398H12.1673C13.548 1.33398 14.6673 2.45327 14.6673 3.83398V7.2103M14.668 11.9607H9.29071M14.668 11.9607L11.9875 14.6673M14.668 11.9607L11.9875 9.29905" stroke="#667085" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_18792_51838">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

const PriceIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M13.3327 3.33398C13.3327 4.43855 10.9449 5.33398 7.99935 5.33398C5.05383 5.33398 2.66602 4.43855 2.66602 3.33398M13.3327 3.33398C13.3327 2.22941 10.9449 1.33398 7.99935 1.33398C5.05383 1.33398 2.66602 2.22941 2.66602 3.33398M13.3327 3.33398V12.6673C13.3327 13.7719 10.9449 14.6673 7.99935 14.6673C5.05383 14.6673 2.66602 13.7719 2.66602 12.6673V3.33398M13.3327 6.44505C13.3327 7.54962 10.9449 8.44505 7.99935 8.44505C5.05383 8.44505 2.66602 7.54962 2.66602 6.44505M13.3327 9.55398C13.3327 10.6586 10.9449 11.554 7.99935 11.554C5.05383 11.554 2.66602 10.6586 2.66602 9.55398" stroke="#667085" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )

};

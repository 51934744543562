import React, { useRef, useState } from "react";
import JunctionUpdate from "../../../../components/modal/JunctionUpdate";
import { CustomModal } from "../../../../components";
import AlertModal from "../../../../components/alert-Modal/Alert-Modal";
import { Tooltip } from "react-tooltip";
import { ClockIcon, DotsVerticalIcon, FlexProductIcon, TargetIcon } from "../../../../components/new-cards/card-icons";
import NoAvatar from "../../../../components/avatar/NoAvatar";
import CustomImage from "../../../../components/CustomImage";
import { NoEquIcon } from "../../../../assets/icons/machineIcons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../../../utils/toastify/toast";
import { junctionService } from "../../../../services/junction.service";
import moment from "moment";
import CustomJunctionInfoModal from "../../../../components/modal/CustomJunctionInfoModal";
import JunctionInfoModal from "../../../../components/modal/JunctionInfoModal";
const InternalPlannedActiveCard = ({ refetch, operation, data, setSelectedJunction, setIsOpenFinishJunction }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isJunction, setIsJunction] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [isAlertPlanned, setIsAlertPlanned] = useState(false);
  const modalRef = useRef(null);
  const handleButtonClick = () => {
    const rect = modalRef?.current?.getBoundingClientRect();
    const x = rect?.left + window?.scrollX;
    const y = rect?.top + window?.scrollY;
    setModalPosition({ top: y, left: x });
    setShowDropDown(!showDropDown);
  };
  const onDeleted = async () => {
    await junctionService.deleteJunction(data?.id, deleteId).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        refetch();
        setIsAlert(false);
        setDeleteId(data?.id);
      } else {
        Toast("error", res.data.message);
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };
  const convertSeconds = (seconds) => {
    if (isNaN(seconds) || seconds == Infinity) return "---";
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Number(seconds % 60).toFixed(0);
    let formattedTime = "";
    if (minutes > 0) {
      formattedTime += minutes + " dk ";
    }
    if (remainingSeconds > 0) {
      formattedTime += remainingSeconds + " sn";
    }
    return formattedTime.trim();
  };
  let formattedTime =
    data?.type === "setup"
      ? convertSeconds(data?.estimatedSettingsTime * 60)
      : convertSeconds(data?.estimatedTime);
  return (
    <>
      <div
        onClick={() => {
          setIsOpenFinishJunction(true);
          setSelectedJunction(data);
        }}
        className={`flex w-full flex-col border border-t-4 cursor-pointer ${data?.type == "work" ? "border-t-success-500 " : data?.type === "setup" ? "border-t-[#EAAA08]" : "border-t-[#98A2B3]"
          } items-start rounded-t-[4px] rounded-b-lg border-secondary-300 bg-white relative group`}
      >
        <div className="flex flex-row items-center w-full gap-x-[6px] pt-[6px] px-2 pb-[6px]">
          <div className="flex flex-row items-center gap-x-[8px]  w-[80%] min-w-[80%]">
            {data?.productType === "flex" ? (
              <>
                {data?.flexProduct?.image ? (
                  <div
                    data-tooltip-id={`${data?.id}`}
                    data-tooltip-place={"right"}
                    data-tooltip-content={`${t("product:productName")} : ${data?.flexProduct?.name} `}
                    className="cursor pointer"
                  >
                    <CustomImage
                      borderRadius={2}
                      src={data?.flexProduct?.image}
                      style={{
                        width: 64,
                        height: 48,
                        minWidth: 64,
                        minHeight: 48,
                        borderRadius: 4,
                        borderWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-[64px] min-w-[64px] min-h-[48px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                    <NoEquIcon />
                  </div>
                )}
              </>
            ) : (
              <>
                {data?.job?.product?.image ? (
                  <div
                    data-tooltip-id={`${data?.id}`}
                    data-tooltip-place={"right"}
                    data-tooltip-content={`${t("product:productName")} : ${data?.job?.product?.name} `}
                    className="cursor pointer"
                  >
                    <CustomImage
                      borderRadius={2}
                      src={data?.job?.product?.image}
                      style={{
                        width: 64,
                        height: 48,
                        minWidth: 64,
                        minHeight: 48,
                        borderRadius: 4,
                        borderWidth: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        borderColor: "#0000001A",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-[64px] min-w-[64px] min-h-[48px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                    <NoEquIcon />
                  </div>
                )}
              </>
            )}

            <div className="flex flex-col items-start gap-y-[6px]">
              <div className="flex flex-row items-center gap-x-1 w-full">
                {data?.productType === "flex" ? (
                  <>
                    <FlexProductIcon />
                    <p className="text-sm font-semibold text-secondary-900 truncate w-[190px]">{data?.flexProduct?.name || "--"}</p>
                  </>
                ) : (
                  <p className="text-sm font-semibold text-secondary-900 truncate w-[190px]">{data?.job?.product?.name || "--"}</p>
                )}
              </div>

              <div className="flex flex-row items-center gap-x-2">
                <div className="flex flex-row items-center">
                  <ClockIcon />
                  <p className="text-xs font-medium ml-1 text-secondary-600">{moment(data?.startDate).format("HH:mm")}</p>
                  <p className="text-xxs font-normal ml-0.5 mt-[1px] text-secondary-600">{t("product:timeStarted")}</p>
                </div>
              </div>
            </div>
          </div>
          {data?.user?.avatar ? (
            <div
              data-tooltip-id={`${data?.id}`}
              data-tooltip-place={"left"}
              data-tooltip-content={`${data?.user?.name + " " + data?.user?.lastName} `}
              className="cursor-pointer ml-auto"
            >
              <CustomImage
                src={data?.user?.avatar}
                style={{
                  width: 48,
                  height: 48,
                  flexDirection: "column",
                  borderRadius: 100,
                  display: "flex",
                  border: "1px solid #0000001A",
                  borderColor: "#0000001A",
                  backgroundColor: "#fff",
                }}
              />
            </div>
          ) : (
            <div
              data-tooltip-id={`${data?.id}`}
              data-tooltip-place={"left"}
              data-tooltip-content={`${data?.user?.name + " " + data?.user?.lastName} `}
              className="cursor-pointer ml-auto"
            >
              <NoAvatar size={48} fontSize={22} name={data?.user?.name} lastName={data?.user?.lastName} color={"#B54708"} />
            </div>
          )}
        </div>
        <div className="flex flex-row items-center gap-x-4 h-[26px] min-h-[26px] w-full bg-[#F9FAFB]  border-t-[0.5px] border-t-secondary-300 pb-[3px] pl-2 pt-[3px] pr-[3px] rounded-b-lg">
          <div className="flex flex-row items-center">
            <TargetIcon />
            <p className="text-xs font-medium ml-1 text-secondary-600">{formattedTime || "--"}</p>
            <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:targetMin")}</p>
          </div>
          <div className="flex flex-row items-center gap-x-1 h-5 min-h-5 pl-2 pr-1 py-[2px] border-[0.5px] bg-white border-secondary-300 rounded ml-auto">
            <p className="text-xxs text text-secondary-600 font-semibold">{t("product:qualityNew")}</p>
            <div className="h-[14px] min-h-[14px] flex items-center justify-center bg-[#B54708] px-1 py-[1px] rounded-[2px]">
              <p className="text-xxs font-semibold text-white">
                %{data?.productType === "flex" ? (data?.flexHeader?.qualityPercent || '0') : (data?.qualityPercent || "0")}
              </p>
            </div>
          </div>
        </div>
        <div
          ref={modalRef}
          onClick={(e) => {
            e.stopPropagation();
            handleButtonClick();
          }}
          className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <DotsVerticalIcon />
        </div>

        <Tooltip className="z-[99999]" id={`${data?.id}`} />
      </div>
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(deleteId);
        }}
      />
      <CustomModal
        isOpen={isJunction}
        setIsOpen={setIsJunction}
        onClose={() => {
          setIsJunction(false);
        }}
        modalTitle={t("product:editJunction")}
        children={
          <JunctionUpdate
            junctionId={data?.id}
            pages={"activeAndPlanned"}
            onClose={() => {
              setIsJunction(false);
            }}
            refetch={refetch}
          />
        }
      />
      <CustomJunctionInfoModal
        isOpen={showDropDown}
        setIsOpen={setShowDropDown}
        onClose={() => {
          setShowDropDown(false);
        }}
        modalRef={modalRef}
        modalPosition={modalPosition}
        children={
          <JunctionInfoModal
            refetch={refetch}
            setIsOpen={setShowDropDown}
            onClose={() => {
              setShowDropDown(false);
            }}
            onPressNode={() => {
              navigate(
                `/app/machine/digital-twin/equipment-details/${data?.station?.deviceId}-${data?.station?.id}/${moment(data?.startDate).format("YYYY-MM-DD")}`
              );
            }}
            onPressFinish={(e) => {
              setIsOpenFinishJunction(true);
              setSelectedJunction(data);
            }}
            onPressProduct={(e) => {
              navigate(`/app/product/mrp/work-in-process/detail/${data?.job?.id}+${data?.header?.step}`);
            }}
            onPressOperation={() => {
              navigate(
                `/app/machine/digital-twin/operation-detail/${data?.internalOperation ? data?.internalOperation?.id : data?.externalOperation?.id}/${moment(
                  data?.startDate
                ).format("YYYY-MM-DD")}`
              );
            }}
            showDropDown={showDropDown}
            props={data}
            modalRef={modalRef}
            setShowDropDown={setShowDropDown}
            setIsAlert={setIsAlert}
            setIsAlertPlanned={setIsAlertPlanned}
            status={data?.status}
          />
        }
      />
    </>
  );
};

export default InternalPlannedActiveCard;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MaterialSupplyPlanModalRawCard from "../../../components/junction/MaterialSupplyPlanModalRawCard";
import { Button } from "../../../components";
import { Toast } from "../../../utils/toastify/toast";
import { purchaseService } from "../../../services/purchase.service";
import { instance } from "../../../libs/client";
import moment from "moment";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import EndDatePicker from "../../person/components/EndDatePicker";
import { CalendarIcon } from "../../../assets/icons/PersonIcons";
import TextArea from "../../../components/inputs/TextArea";
import UnitPriceInput from "../../../components/inputs/UnitPriceInput";
import PersonnelAndEquipmentSelector from "../../../components/inputs/PersonnelAndEquipmentSelector";
import QtyInput from "../../../components/inputs/QtyInput";

const MaterialSupplyPlanModal = ({ selectedJunction, setIsOpen, refetch, groupId }) => {
  const { t } = useTranslation();
  const [suppliers, setSuppliers] = useState([]);
  const [date, setDate] = useState(moment(new Date()));
  const [visible, setVisible] = useState(false);

  const handleSupplier = async () => {
    await instance.get(`purchase/list-by-group/${groupId}`).then((res) => {
      setSuppliers(
        res.data?.map((item) => {
          return { label: item?.name, value: item?.id, image: item?.image };
        })
      );
    });
  };

  const priceOptions = [
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
    { label: "GBP", value: "GBP" },
    { label: "RUB", value: "RUB" },
    { label: "UAH", value: "UAH" },
    { label: "TRY", value: "TRY" },
  ];

  const qtyOptions = [
    { label: "Kilogram", value: "kg" },
    { label: t("product:metricTon"), value: "ton" },
    { label: "Gram", value: "gr" },
    { label: "Miligram", value: "mg" },
    { label: "Microgram", value: "mcg" },
    { label: "Pound", value: "lb" },
    { label: t("product:ounce"), value: "oz" },
    { label: "Metre", value: "m" },
    { label: "Millilitre", value: "mm" },
    { label: t("product:gallon"), value: "gal" },
    { label: t("product:squareMetre"), value: "m2" },
    { label: t("product:squareMilimetre"), value: "mm2" },
    { label: t("product:squareInch"), value: "mm2" },
    { label: t("product:pieceModal"), value: "ad" },
    { label: t("product:package"), value: "pkg" },
    { label: t("product:box"), value: "box" },
  ];

  const schema = yup.object({
    price: yup.string(),
    description: yup.string(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    handleSupplier();
  }, []);

  const onSubmit = async (value) => {
    const data = {
      price: value?.price,
      plannedUnit: value?.purchaseQuantity,
      currency: value?.currency || "USD",
      requestId: selectedJunction?.id,
      estimatedDeliveryDate: moment(date).format("YYYY-MM-DD"),
      description: value?.description,
      supplierId: value?.supplier,
    };
    console.log("data", data);
    await purchaseService.createPlannedPurchaseJunction(data).then((res) => {
      if (res?.data?.code === 0) {
        Toast("success", res?.data?.message);
        setIsOpen(false);
        refetch();
      } else {
        Toast("error", res?.data?.message);
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-[766px]">
      <div className="flex flex-row items-center mt-5 border-b border-t p-3 w-full bg-[#F9FAFB]">
        <MaterialSupplyPlanModalRawCard item={selectedJunction} />
        <Button iconLeft={<ChevronRightIcon />} size={"sm"} colorType={"tertiary-gray"} />
        <div className="flex flex-row items-center ml-4 rounded-lg p-2 w-full">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <PersonnelAndEquipmentSelector
                value={value}
                items={suppliers}
                icon={"machine"}
                theme={"product"}
                label={"Tedarikçi"}
                onChange={onChange}
                onBlur={onBlur}
                validate={errors?.station ? "error" : ""}
                errorMessage={errors?.station ? errors?.station?.message : ""}
              />
            )}
            name="supplier"
          />
        </div>
      </div>
      <div className="flex flex-col items-start gap-y-4 w-full mt-3">
        <div className="flex flex-row gap-x-6 w-full">
          <div className="w-1/2">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <QtyInput
                  value={value}
                  theme={"product"}
                  items={qtyOptions}
                  qtyType={selectedJunction?.unitType === null ? t("product:pcs") : selectedJunction?.unitType} // unittype gelecek
                  label={"Satın Alım Yapılacak Miktar"}
                  onChange={onChange}
                  setValue={setValue}
                  onBlur={onBlur}
                  validate={errors?.price ? "error" : ""}
                  errorMessage={errors?.price ? errors?.price?.message : ""}
                />
              )}
              name="purchaseQuantity"
            />
          </div>
          <div className="flex w-1/2"></div>
        </div>
        <div className="flex flex-row gap-x-6 w-full">
          <div className="w-1/2">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <UnitPriceInput
                  value={value}
                  theme={"product"}
                  items={priceOptions}
                  label={t("product:unitPriceRawMaterial")}
                  onChange={onChange}
                  setValue={setValue}
                  currency={value?.currency}
                  onBlur={onBlur}
                  validate={errors?.price ? "error" : ""}
                  errorMessage={errors?.price ? errors?.price?.message : ""}
                />
              )}
              name="price"
            />
          </div>
          <div className="flex flex-col gap-2 w-1/2">
            <p className="text-sm font-medium text-secondary-700">{t("product:estimatedDeliveryDate")}</p>
            <EndDatePicker
              date={date}
              visible={visible}
              setDate={(e) => {
                setDate(e);
              }}
              setVisible={setVisible}
              buttonClassName={"flex flex-col items-center"}
              calendarClassName={"absolute top-12 rounded-lg shadow-xl z-[600]"}
            >
              <Button
                onClick={() => {
                  setVisible(!visible);
                }}
                iconLeft={<CalendarIcon />}
                size={"md"}
                colorType={"secondary-gray"}
                label={moment(date).format("DD.MM.YYYY")}
              />
            </EndDatePicker>
          </div>
        </div>
        <div className="flex flex-col gap-y-4 w-full">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextArea
                value={value}
                theme={"product"}
                height={80}
                label={t("product:description")}
                onChange={onChange}
                setValue={setValue}
                onBlur={onBlur}
                validate={errors?.description ? "error" : ""}
                errorMessage={errors?.description ? errors?.description?.message : ""}
              />
            )}
            name="description"
          />
        </div>
      </div>
      <div className="flex flex-col gap-y-4 bg-[#F9FAFB] w-full border-y mt-6 px6 py-4 min-h-[104px]">
        <div className="flex w-full justify-between items-center">
          <p className="text-[#344054] text-md font-semibold">Talep Miktarı</p>
          <p className="text-[#475467] text-md">20.000 kg</p>
        </div>
        <div className="flex w-full justify-between items-center">
          <p className="text-[#344054] text-md font-semibold">Satın Alım Yapılacak Miktar</p>
          <p className="text-[#475467] text-md">10.000 kg</p>
        </div>
      </div>
      <div className="w-[100%] flex items-center gap-x-3 mt-4">
        <Button label={"Vazgeç"} colorType={"secondary-gray"} size={"md"} type={"button"} />
        <Button type={"submit"} label={"Kaydet"} colorType={"primary-product"} size={"md"} />
      </div>
    </form>
  );
};

export default MaterialSupplyPlanModal;

export const ChevronRightIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export const UrgencyIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M3 8.5H21M3 15.5H21" stroke="#98A2B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

import React, { useState } from "react";
import {
  DeclinedQuantityIcon,
  EventsIcon,
  NewAcceptedIcon,
  NewDotsIcon,
  NewRejectedIcon,
  PlannedIcon,
  QuantityGreenIcon,
  ReadyIcon,
} from "../../../components/new-cards/card-icons";
import { ThreeDotsIcon } from "../../../assets/icons/machineIcons";
import { EditIcon } from "../../../assets/icons/departmentIcon";
import { legacyFormatSeconds } from "../../../utils/secondToHoursAndMinute";
import { useTranslation } from "react-i18next";
const MrpMultiOperationCard = ({ onClick, item }) => {
  const { t } = useTranslation();
  const operationTypes = (type) => {
    switch (type) {
      case "conversion_operation":
        return "DÖN";
      case "supporter_operation":
        return "YAR";
      case "assembly_operation":
        return "MON";
      case "decomposition_operation":
        return "AYR";
      default:
        return "DÖN";
    }
  };
  const statusColor = () => {
    if (item?.status?.includes("finished")) {
      return "#ECFDF3";
    } else if (item?.status?.includes("started")) {
      return "#FFFAEB";
    } else if (item?.status?.includes("rejected")) {
      return "#D92D20";
    } else {
      return "#F2F4F7";
    }
  };
  const operationMap = item?.operations?.map((a) => {
    if (a?.internalOperation) {
      return a?.internalOperation?.activePiece ? a?.internalOperation?.activePiece : 0;
    } else {
      return a?.externalOperation?.activePiece ? a?.externalOperation?.activePiece : 0;
    }
  });
  const orderDataItem = item?.operations?.reduce((acc, b) => acc || b?.orderData, null);
  let times = item?.times?.find((a) => a?.avgEstimatedTime > 0) || item?.times[0];
  return (
    <>
      <div
        onClick={onClick}
        className={`flex w-[312px] h-[112px] flex-row border items-start cursor-pointer rounded-lg border-secondary-300  bg-white relative group `}
      >
        {/* <div style={{ backgroundColor: statusColor() }} className="flex w-full min-h-[4px] max-h-[4px] h-1 rounded-t-[8px]"></div> */}
        <div className="flex flex-col w-[93%]">
          {item?.name?.length == 1 ? (
            <>
              {item?.name?.map((b) => {
                return (
                  <div className="flex flex-row items-center w-[100%]  justify-between pr-1 py-2 pl-3">
                    <div className="flex flex-row items-center gap-x-0.5 h-5 ">
                      {b?.processType == "external" ? (
                        <div className="px-1  border h-full w-fit rounded bg-[#F2F4F7]">
                          <p className="text-xs font-semibold text-secondary-700">{"HİZ"}</p>
                        </div>
                      ) : (
                        <div className="px-1  border h-full w-fit rounded bg-[#F2F4F7]">
                          <p className="text-xs font-semibold text-secondary-700">{item?.processType ? operationTypes(b?.processType) : "DÖN"}</p>
                        </div>
                      )}
                      <div className=" bg-white max-w-[230px] ml-1 rounded-r-[8px]">
                        <p className="font-semibold text-sm text-secondary-700  truncate">{b?.name}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="flex flex-row items-center gap-x-2 pr-1 py-1.5 pl-2 w-[270px] min-w-[270px] max-w-[270px] ">
              {item?.name?.map((a, index) => {
                return (
                  <div className={`flex border items-center ${item?.name?.length == 2 ? "w-1/2 " : "w-1/3"}  h-6 gap-x-1 flex-row rounded`}>
                    <div className="px-1 bg-[#F2F4F7] w-fit border-r h-full flex items-center">
                      {a.processType === "external" ? (
                        <p className="text-xs font-semibold text-secondary-700">{"HİZ"}</p>
                      ) : (
                        <p className="text-xs font-semibold text-secondary-700">{a?.processType ? operationTypes(a?.processType) : "--"}</p>
                      )}
                    </div>
                    <div className="bg-white truncate px-1">
                      <p className="font-semibold text-xs text-secondary-700  truncate">{a?.name}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div className="flex flex-row items-center w-full">
            <div className="flex flex-col pr-2 pl-2 w-[37%] min-w-[37%]">
              <div className="flex flex-row items-center w-full  border-b pb-0.5">
                <p className="font-normal text-secondary-600 ml-1 text-[10px]">{t("product:inStocks")}</p>
                <div className="ml-auto">
                  <p className="text-xs font-medium max-w-[45px] truncate text-secondary-700">{operationMap?.reduce((a, b) => a + b, 0) || "0"}</p>
                </div>
              </div>
              <div className="flex flex-row items-center w-full border-b pb-0.5  pt-[4px]">
                <p className="font-normal text-secondary-600 ml-1 text-[10px]">{t("product:backlog")}</p>
                <div className="ml-auto">
                  <p className="text-xs font-medium max-w-[45px] truncate text-secondary-700">{item?.ready || "0"}</p>
                </div>
              </div>
              <div className="flex flex-row items-center w-full pb-0.5 pt-[4px]">
                <p className="font-normal text-secondary-600 ml-1 text-[10px]">{t("product:planneds")}</p>
                <div className="ml-auto">
                  <p className="text-xs font-medium text-secondary-700">{item?.planned || "0"}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center w-[63%] min-w-[63%] rounded-tl-[4px] border border-r-0 border-b-0 border-[#E4E7EC]">
              <div className="flex flex-col items-start border-r w-1/2 border-r-[#E4E7EC]">
                <div className="px-1 py-[3px] flex items-center rounded-tl-[4px] justify-center border-b w-full bg-[#F9FAFB]">
                  <p className="text-xs font-medium text-secondary-700">{t("product:workOrderQty")}</p>
                </div>
                <div className="flex items-center w-full px-1.5">
                  <div className="flex py-[3px] border-b flex-row items-center w-full justify-between">
                    <p className="text-[10px] w-[50px] truncate text-secondary-500 font-normal">{t("product:remainder")}</p>
                    <p className="font-medium text-secondary-600 text-xs">{orderDataItem?.piece - item?.finishedPiece || "0"}</p>
                  </div>
                </div>
                <div className="flex px-1.5 py-[1.5px] flex-row items-center w-full justify-between">
                  <p className="text-[10px] text-secondary-500 font-normal">{t("product:applieds")}</p>
                  <p className={`font-medium text-secondary-600 text-xs`}>{item?.finishedPiece || "0"}</p>
                </div>
              </div>

              <div className="flex flex-col items-center  w-1/2">
                <div className="px-1 py-[3px] flex items-center rounded-tl-[4px] justify-center border-b w-full bg-[#F9FAFB]">
                  <p className="text-xs font-medium text-secondary-700">{t("product:cycleTime")}</p>
                </div>
                <div className="flex items-center w-full px-1.5">
                  <div className="flex py-[3px] flex-row border-b items-center w-full justify-between">
                    <p className="text-[10px] text-secondary-500 font-normal">{t("product:target")}</p>
                    <p className="font-medium text-secondary-600 ml-auto truncate text-xs">
                      {times?.type == "internal" ? legacyFormatSeconds(times?.estimatedTime) : `${times?.estimatedTime} ${t("product:day")}`}
                    </p>
                  </div>
                </div>
                <div className="flex px-1.5 py-[1.5px] flex-row items-center w-full justify-between">
                  <p className="text-[10px] text-secondary-500 font-normal">{t("product:applieds")}</p>
                  <p
                    className={`font-medium  ml-auto truncate text-xs ${
                      (times?.type == "internal" ? times?.estimatedTime < times?.avgEstimatedTime : times?.estimatedTime * 86400 < times?.avgEstimatedTime)
                        ? "text-[#F04438]"
                        : "text-[#079455] "
                    }`}
                  >
                    {times?.avgEstimatedTime
                      ? times?.type == "internal"
                        ? legacyFormatSeconds(times?.avgEstimatedTime?.toFixed(0))
                        : `${Math.ceil(times?.avgEstimatedTime / 60 / 60 / 24)} ${t("product:day")}`
                      : "--"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ backgroundColor: statusColor() }}
          className={`flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0  border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px]`}
        >
          <p
            className={`text-xxs font-semibold whitespace-nowrap ${
              item?.status?.includes("started")
                ? "text-[#CA8504]"
                : item?.status?.includes("finished")
                ? "text-[#079455]"
                : item?.status.includes("rejected")
                ? "text-white"
                : "#475467"
            } -rotate-90`}
          >
            {t("product:semiProduct")}
          </p>
        </div>
        <div
          onClick={(e) => e.stopPropagation()}
          className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <NewDotsIcon />
        </div>
      </div>
    </>
  );
};

export default MrpMultiOperationCard;

import React, { useEffect, useState } from "react";
import Selector from "../../../../components/inputs/Selector";
import { Button, Input } from "../../../../components";
import { Toggle } from "../../../machine/Twin/components";
import { useTranslation } from "react-i18next";
import TextArea from "../../../../components/inputs/TextArea";
import { processService } from "../../../../services";
import { externalOperationService } from "../../../../services/external-operation.service";
import _ from "lodash";
import ExternalOperationSide from "./ExternalOperationSide";
import InternalOperationSide from "./InternalOperationSide";
import { productService } from "../../../../services/product.service";
import { Toast } from "../../../../utils/toastify/toast";
const OperationStepOne = ({
  setItems,
  oldItems,
  stepOneData,
  setStepOneData,
  selectedStep,
  setStepTwoData,
  stepTwoData,
  setStepThreeData,
  stepThreeData,
  onClose,
  productId,
  refetch,
  groupedItemsLength
}) => {
  const { t } = useTranslation();
  const [internalProcess, setInternalProcess] = useState([]);
  const [externalProcess, setExternalProcess] = useState([]);
  const data = selectedStep === 1 ? stepOneData : selectedStep === 2 ? stepTwoData : stepThreeData;
  const setData = selectedStep === 1 ? setStepOneData : selectedStep === 2 ? setStepTwoData : setStepThreeData;
  const onSubmit = async (datas) => {
    const lastDatas = datas.map((item) => {
      return {
        id: item?.id,
        description: item?.description,
        hasQualityControl: item?.enabled,
        currency: item?.currency ? item?.currency : "USD",
        costOfMinute: item?.operationType == "internal" ? (Number(item?.costOfMinute) ? Number(item?.costOfMinute) : 0) : undefined,
        costOfPiece: item?.operationType != "internal" ? (Number(item?.costOfMinute) ? Number(item?.costOfMinute) : 0) : undefined,
        estimatedTime: item?.estimatedTime,
        estimatedSettingsTime: item?.estimatedSettingsTime,
        qualityPercent: Number(item?.qualityPercent),
        type: item?.operationType,
        step: stepOneData?.step ? stepOneData?.step : groupedItemsLength + 1,
        entityId: item?.operation,
        process: { id: item?.operation },
        processType: "conversion_operation",
        processName: item?.operations?.label,
        processId: item?.operation,
        plannableSupplier: item?.operations?.supplier?.map((supplier) => {
          return {
            id: supplier.id,
            estimatedTime: supplier.estimatedTime || data?.estimatedTime,
            costOfPiece: supplier.costOfMinute || data?.costOfMinute,
            currency: Number(supplier.currency) ? "USD" : supplier.currency || data?.currency || "USD",
          };
        }),
        plannableNodes: item?.operations?.stations?.map((station) => {
          return {
            id: station.id,
            estimatedTime: station.estimatedTime || data?.estimatedTime,
            estimatedSettingsTime: station.estimatedSettingsTime || data?.estimatedSettingsTime,
            costOfMinute: station.costOfMinute || data?.costOfMinute,
            currency: Number(station.currency) ? "USD" : station.currency || data?.currency || "USD",
          };
        }),
      };
    });
    const filtered = lastDatas?.filter((item) => item?.processId);
    const groupedOperations = _.groupBy(filtered, "step");
    const operationsNew = Object.keys(groupedOperations).map((key) => {
      return groupedOperations[key];
    });
    const newData = oldItems.concat(operationsNew);
    setItems(newData);
    filtered.map(async (item) => {
      if (item?.id) {
        await productService.updateProductOperation(item?.id, item).then((res) => { });
      } else {
        await productService.addOperation(productId, item).then((res) => { });
      }
      refetch();
      onClose();
    });
  };
  const operationType = [
    { label: t("product:serviceSupplys"), value: "external" },
    { label: t("routes:addProcess"), value: "internal" },
  ];
  const handleInternalProcessUpdate = async () => {
    let datas = [];
    await processService.activeProcessesWithStations().then((res) => {
      const newTabsData = res?.data.map((item) => ({
        stations: item.stations,
        supplier: item.supplier,
        label: item.name,
        value: item.id,
        operationType: item?.type,
      }));
      datas = newTabsData || [];
    });
    return setInternalProcess(datas);
  };
  const handleExternalProcessUpdate = async (index) => {
    let datas = [];
    await externalOperationService.listOperation().then((res) => {
      const newTabsData = res?.data.map((item) => ({
        stations: item.stations,
        supplier: item.supplier,
        label: item.name,
        value: item.id,
      }));
      datas = newTabsData || [];
    });
    return setExternalProcess(datas);
  };
  useEffect(() => {
    handleInternalProcessUpdate();
    handleExternalProcessUpdate();
  }, []);
  return (
    <div className="flex flex-col w-full h-full mt-6 gap-y-6">
      <div className="flex w-full gap-x-6 h-full">
        <div className="flex w-1/2 flex-col gap-y-3">
          <Selector
            value={selectedStep === 1 ? stepOneData?.operationType : selectedStep === 2 ? stepTwoData?.operationType : stepThreeData?.operationType}
            items={operationType}
            theme={"product"}
            label={t("product:serviceOperationType")}
            placeholder={`${t("product:serviceSupplys")}, ${t("routes:addProcess")}`}
            onChange={(value) => {
              setData({ ...data, operationType: value });
            }}
          />
          <div className="flex w-full gap-x-4 items-center">
            <Toggle
              enabled={selectedStep === 1 ? stepOneData?.enabled : selectedStep === 2 ? stepTwoData?.enabled : stepThreeData?.enabled}
              setEnabled={(enabled) => {
                setData({ ...data, enabled });
              }}
              color={"#DC6803"}
              size={"sm"}
            />
            <p className="text-[#344054] font-medium text-sm">{t("product:addQualityControl")}</p>
          </div>
          <Input
            label={t("product:qualityPercent")}
            value={!data?.enabled ? 0 : data?.qualityPercent}
            theme={"product"}
            onChange={(e) => {
              setData({ ...data, qualityPercent: e.target.value });
            }}
            isPercentage
            disabled={!data?.enabled}
            type={"number"}
          />
        </div>
        <div className="flex w-[1px] min-w-[1px] max-w-[1px] min-h-full bg-[#E4E7EC]" />
        <div className="flex w-1/2 flex-col gap-y-3 justify-between">
          <Selector
            value={data?.operation}
            items={
              data?.operationType === "internal" ? internalProcess?.filter((item) => item?.label != "") : externalProcess?.filter((item) => item?.label != "")
            }
            theme={"product"}
            label={t("product:operation")}
            disabled={!data?.operationType}
            onChange={(value) => {
              setData({
                ...data,
                operation: value,
                operations:
                  data?.operationType == "internal"
                    ? internalProcess.find((item) => item?.value == value)
                    : externalProcess.find((item) => item?.value == value),
              });
            }}
          />
          <TextArea
            label={t("product:description")}
            value={selectedStep === 1 ? stepOneData?.description : selectedStep === 2 ? stepTwoData?.description : stepThreeData?.description}
            onChange={(e) => {
              setData({ ...data, description: e?.target?.value });
            }}
            theme={"product"}
            rows={2}
          />
        </div>
      </div>
      <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
      <div className="flex flex-col w-full min-h-[350px] max-h-[350px] h-[350px] gap-y-5">
        {data?.operationType == "internal" ? (
          <InternalOperationSide
            t={t}
            stepOneData={stepOneData}
            setStepOneData={setStepOneData}
            stepTwoData={stepTwoData}
            setStepTwoData={setStepTwoData}
            stepThreeData={stepThreeData}
            setStepThreeData={setStepThreeData}
            selectedStep={selectedStep}
          />
        ) : (
          <ExternalOperationSide
            t={t}
            stepOneData={stepOneData}
            setStepOneData={setStepOneData}
            stepTwoData={stepTwoData}
            setStepTwoData={setStepTwoData}
            stepThreeData={stepThreeData}
            setStepThreeData={setStepThreeData}
            selectedStep={selectedStep}
          />
        )}
      </div>
      <div className="flex w-full gap-x-3">
        <Button colorType={"secondary-gray"} label={t("buttons:stop")} size={"lg"} onClick={onClose} />
        <Button colorType={"primary-product"} label={t("buttons:save")} size={"lg"} onClick={() => onSubmit([stepOneData, stepTwoData, stepThreeData])} />
      </div>
    </div>
  );
};
export default OperationStepOne;

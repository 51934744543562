import React, { useState } from "react";
import { Input, UploadProfile } from "../../../../components";
import { useTranslation } from "react-i18next";
import TextArea from "../../../../components/inputs/TextArea";
import { Toggle } from "../../Twin/components";

const EditManuelJunctionQualityControlForm = ({
  setQualityDescription,
  setQualityFileFirst,
  setQualityFileSecond,
  setQualityFileThird,
  qualityFileFirst,
  qualityDescription,
  qualityFileSecond,
  qualityFileThird,
  rejectedPiece,
  setRejectedPiece,
  enabled,
  setEnabled,
}) => {
  const { t } = useTranslation();
 

  const handleQualityImageChange = (value) => {
    setQualityFileFirst(value); // Eğer bu fonksiyon dışarıdan bir state güncellemesi yapıyorsa
  };

  const handleAcceptedImageChange = (value) => {
    setQualityFileSecond(value); // Eğer bu fonksiyon dışarıdan bir state güncellemesi yapıyorsa
  };

  const handleRejectedImageChange = (value) => {
    setQualityFileThird(value); // Eğer bu fonksiyon dışarıdan bir state güncellemesi yapıyorsa
  };

  const handleQualityDescriptionChange = (value) => {
    setQualityDescription(value); // Eğer bu fonksiyon dışarıdan bir state güncellemesi yapıyorsa
  };

  return (
    <div className="flex flex-col w-[30%] min-w-[30%] h-full pl-6 gap-y-4">
      <div className="flex w-full items-center justify-between">
        <p className="text-[#101828] font-semibold text-lg">{t("product:qualityControlNew")}</p>

        <Toggle enabled={enabled} setEnabled={setEnabled} />
      </div>
      <div className="flex flex-col gap-y-3">
     
          <Input
            value={rejectedPiece}
            onChange={(event) => setRejectedPiece(event.target.value)}
            theme={"machine"}
            label={t("product:quantityRejectedProduct")}
            disabled={!enabled}
           
          />
       
        <UploadProfile title={t("product:controlForm")} subTitle={"Remove Picture"} onChange={handleQualityImageChange} value={qualityFileFirst} />
        <UploadProfile title={t("product:approvalForm")} subTitle={"Remove Picture"} onChange={handleAcceptedImageChange} value={qualityFileSecond} disabled={!enabled} />
        <UploadProfile title={t("product:rejectionForm")} subTitle={"Remove Picture"} onChange={handleRejectedImageChange} value={qualityFileThird} disabled={!enabled} />
        <TextArea
          label={t("product:descriptionOptionel")}
          width={"100%"}
          onChange={({ target }) => setQualityDescription(target?.value)}
          rows={3}
          value={qualityDescription}
          disabled={!enabled}
        />
      </div>
    </div>
  );
};

export default EditManuelJunctionQualityControlForm;

import React, { useEffect, useRef } from "react";
import { authStore } from "../../../stores/auth.store";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

const EndDatePicker = ({
  date,
  toYear,
  setDate,
  visible,
  fromYear,
  children,
  setVisible,
  visibleFooter,
  captionLayout,
  footerComponent,
  buttonClassName,
  disabled,
  calendarClassName,
  onDayClick = () => {},
}) => {
  const dateString = `${date}`;
  const dates = new Date(dateString);
  const year = dates.getFullYear();
  const month = dates.getMonth();
  const menuRef = useRef();
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setVisible(false);
    }
  };
  const footer = footerComponent || (
    <button
      className="text-blue-600 active:bg-blue-25 border border-transparent active:border-blue-300 active:ring-4 active:ring-blue-50 py-1.5 px-2.5 font-medium hover:bg-blue-50 rounded-xl"
      onClick={() => {
        setVisible(false);
        setDate(new Date());
      }}
    >
      Go to Today
    </button>
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div
        ref={menuRef}
        className={
          buttonClassName
            ? `${buttonClassName} relative`
            : "flex flex-col relative select-none"
        }
      >
        {children}
        {visible && (
          <div
            style={{
              boxShadow:
                "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)",
            }}
            className={
              calendarClassName
                ? `${calendarClassName} border border-secondary-300 bg-white z-50`
                : `rounded-lg flex items-center justify-center absolute top-9 -right-[52px] border border-secondary-300 bg-white z-50`
            }
          >
            <DayPicker
              showOutsideDays
              mode={"single"}
              defaultMonth={new Date(year, month)}
              selected={date}
              onSelect={setDate}
              toYear={toYear || 2024}
              fromYear={fromYear || 571}
              footer={visibleFooter && footer}
              disabled={disabled}
              timezone={authStore?.user?.company?.timeZone}
              captionLayout={captionLayout || "dropdown-buttons"}
              modifiersClassNames={{
                selected: "bg-blue-600 text-white font-medium",
                today: "bg-blue-50 text-black font-semibold",
              }}
              onDayClick={(e) => {
                onDayClick(e);
                setVisible(false);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default EndDatePicker;

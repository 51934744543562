import React, { useState, useEffect } from "react";
import { Button, CustomModal, Loading } from "../../components";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useStore } from "../../hooks/useStores";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { junctionService } from "../../services/junction.service";
import diacritics from "diacritics";
import FinishJunctionModal from "../../components/modal/FinishJunctionModal";
import ServiceSupplyEventCard from "../../components/new-cards/junction-card/ServiceSupplyEventCard";
import ServiceSupplyActiveEventCard from "../../components/new-cards/junction-card/ServiceSupplyActiveEventCard";
import useWindowDimensions from "../../hooks/useWindowDimension";
import { QualityTab } from "./quality/components";
import NewOrderHeaderCard from "./components/order/NewOrderHeaderCard";
const ServiceSupplyJunction = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { height } = useWindowDimensions();
  const { settings, auth } = useStore();
  let navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("all");
  const [isOpenJunctionPiece, setIsOpenJunctionPiece] = useState(false);
  const [finishJunctionId, setFinishJunctionId] = useState(null);
  const [isLeftArrowHidden, setIsLeftArrowHidden] = useState(true);
  const [isRightArrowHidden, setIsRightArrowHidden] = useState(false);
  const [junctionType, setJunctionType] = useState("external");
  const headData = useQuery({
    queryKey: ["all-operation-junctions"],
    enabled: false,
    retry: 0,
    queryFn: async () => await junctionService.getExternalJunctions(),
  });
  const planView = useQuery({
    queryKey: ["external-operation-detail-view-plan"],
    enabled: false,
    queryFn: async () => await junctionService.getExtenralOperationJunctions(id),
    retry: 0,
  });
  const refetch = () => {
    planView.refetch();
    headData.refetch();
  };
  useEffect(() => {
    headData.refetch();
    if (id != ":id" && id != "undefined") {
      planView.refetch();
      setSelectedGroup(headData?.data?.operations?.find((item) => item?.id == id));
    }
  }, [id]);
  useEffect(() => {
    if (headData?.data?.operations?.length > 0 && (id == ":id" || id == "undefined")) {
      navigate(`/app/product/mrp/supply/service/${headData?.data?.operations[0]?.id}/junction`);
    }
  }, [headData?.data]);
  const cleanAndMatchSearch = (item, searchTerm) => {
    const fullName = `${item?.job?.product?.name}`;
    const cleanedFullName = diacritics.remove(fullName).toLowerCase();
    const cleanedSearchTerm = diacritics.remove(searchTerm.toLowerCase());
    return cleanedFullName.includes(cleanedSearchTerm);
  };
  const filteredOperationActiveJunction = planView?.data?.activeJunction?.filter((item) => {
    return cleanAndMatchSearch(item, searchTerm);
  });
  const filteredOperationPendingJunction = planView?.data?.pendingJunction?.filter((item) => {
    return cleanAndMatchSearch(item, searchTerm);
  });
  const filteredOperationPastJunction = planView?.data?.pastJunction?.filter((item) => {
    return cleanAndMatchSearch(item, searchTerm);
  });
  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  const handleScroll = () => {
    const container = document.getElementById("content-wrapper");
    const isAtBeginning = container.scrollLeft === 0;
    const isAtEnd = container.scrollLeft >= container.scrollWidth - container.clientWidth;
    setIsLeftArrowHidden(isAtBeginning);
    setIsRightArrowHidden(isAtEnd);
  };
  useEffect(() => {
    const container = document.getElementById("content-wrapper");
    container?.addEventListener("scroll", handleScroll);
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  useEffect(() => {
    if (auth?.user?.company?.id == "e5c06127-3c5d-44b9-8403-74c160fc7f59") {
    } else if (auth?.user?.company?.id == "ab77a50a-7b68-42a2-ba5c-0ebfb5c13325") {
    } else if (auth?.user?.company?.id == "71b0edec-e5d4-4929-bfdb-0e5d5e149592") {
    } else if (auth?.user?.company?.id == "dd1892b9-d9a3-44ed-9131-17c88a74a1b4") {
    } else if (auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706") {
    } else if (auth?.user?.company?.id == "49ee8491-35d7-4eb2-a0e9-bfc95541180f") {
    } else if (auth?.user?.company?.id == "65844198-a9e8-45fa-bc99-7d012a541b35") {
    } else if (auth?.user?.company?.id == "7c5bb5a6-21aa-4d1d-829b-2a6d360b9630") {
    } else if (auth?.user?.company?.id == "f7db6191-5842-455a-99f4-c76763090689") {
    } else if (auth?.user?.company?.id == "776c0df3-0a44-464f-9877-33bd731aef6a") {
    } else if (auth?.user?.company?.id == "065e9b57-9da2-4394-9359-59ba71610dc3") {
    } else if (auth?.user?.company?.id == "e41a9bde-203c-48f2-aeec-4f8ce6344513") {
    } else if (auth?.user?.company?.id == "262d8e02-57e8-427e-9159-6b18b236ca86") {
    } else if (auth?.user?.company?.id == "0c082d32-6d97-4ba9-a2a5-7e41fe8139d9") {
    } else {
      navigate("/app/product/verysoon");
    }
  }, []);
  const tabOptions = [
    { value: "junction", label: t("product:eventss") },
    { value: "plan", label: t("product:planner") },
  ];
  return (
    <div className="w-full h-full flex flex-col overflow-hidden scrollbar-hide overflow-y-auto">
      <>
        <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
          <span className="mr-3 md:flex sm:flex xs:flex hidden">
            <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
          </span>
          <div className="flex items-center">
            <span className="font-codecMedium text-2xl text-black">upu.</span>
            <p className="text-2xl font-codecMedium text-[#B54708]">supply</p>
            {!(headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading) && (
              <p className="text-xl font-normal ml-2 text-secondary-500">
                {selectedGroup && selectedGroup?.name ? `${t("product:serviceSupply")} / ${selectedGroup?.name}` : t("product:serviceSupply")}
              </p>
            )}
          </div>
          <span className="ml-auto">
            <Button
              colorType={"primary-product"}
              size={"md"}
              label={t("product:supplierList")}
              onClick={() => navigate("/app/product/mrp/supply/service/list")}
            />
          </span>
        </div>
        {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
          <div className="w-full bg-gray-200 rounded-xl animate-pulse h-[96px] min-h-[96px] flex gap-5" />
        ) : (
          <div className="h-[116px] min-h-[116px] w-full overflow-x-auto flex">
            {headData?.data?.operations
              ?.sort((a, b) => b?.active + b?.past + b?.pending - (a?.active + a?.past + a?.pending))
              ?.map((item, index) => {
                return (
                  <div className={`min-w-[192px] ${index !== headData?.data?.operations?.length - 1 ? "border-b" : "border-b-0"} max-h-[96px] pr-5`}>
                    <NewOrderHeaderCard
                      onClick={async () => {
                        navigate(`/app/product/mrp/supply/service/${item?.id}/junction`);
                      }}
                      icon={item?.iconKey}
                      name={item?.name}
                      planned={item?.plannedUnplanned?.planned}
                      unPlanned={item?.plannedUnplanned?.unPlanned}
                      selectedOperation={{ id: selectedGroup?.id, name: item?.name }}
                      id={item?.id}
                    />
                  </div>
                );
              })}
          </div>
        )}
        {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
          <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-gray-200 p-[6px] mt-6 mb-4 rounded-xl animate-pulse flex gap-5" />
        ) : (
          <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-[#F9FAFB] border border-[#EAECF0] p-[6px] rounded-xl flex items-center justify-center mt-6 mb-4">
            {tabOptions?.map((option, index) => (
              <div
                className={`w-1/2 h-full rounded-md px-3 py-2  flex items-center justify-center cursor-pointer ${
                  "junction" == option?.value ? "bg-white shadow-sm" : ""
                }`}
                onClick={() => {
                  if (option?.value != "junction") navigate(`/app/product/mrp/supply/service/${id}/plan-view`);
                }}
              >
                <p className={`text-md font-semibold ${"junction" == option?.value ? "text-[#344054]" : "text-[#667085]"}`}>{option?.label}</p>
              </div>
            ))}
          </div>
        )}
        {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
          <div className="w-full h-full bg-gray-200 rounded-xl animate-pulse mt-4" />
        ) : (
          <div style={{ minHeight: height - 135 }} className="flex w-full h-full rounded-xl mt-4 flex-col overflow-hidden">
            <div className="w-full h-full rounded-xl flex flex-col relative">
              <div id="content-wrapper" className="flex w-full flex-1 md:overflow-x-auto overflow-hidden">
                <QualityTab
                  length={filteredOperationActiveJunction?.length || 0}
                  type={"active"}
                  children={
                    <>
                      {filteredOperationActiveJunction?.map((item, index) => {
                        return (
                          <div className="min-h-[80px] cursor-pointer mb-3">
                            <ServiceSupplyActiveEventCard
                              data={item}
                              refetch={refetch}
                              pages={"activeAndPlanned"}
                              setJunctionType={setJunctionType}
                              setFinishJunctionId={setFinishJunctionId}
                              setIsOpenJunctionPiece={setIsOpenJunctionPiece}
                            />
                          </div>
                        );
                      })}
                    </>
                  }
                />
                <QualityTab
                  type={"waiting"}
                  length={filteredOperationPendingJunction?.length || 0}
                  children={<JunctionListController items={filteredOperationPendingJunction} refetch={refetch} navigate={navigate} completed={true} />}
                />
                <QualityTab
                  length={filteredOperationPastJunction?.length || 0}
                  type={"finished"}
                  children={<JunctionListController items={filteredOperationPastJunction} refetch={refetch} navigate={navigate} completed={true} />}
                />
                {(planView?.isFetching || planView?.isLoading) && (
                  <div className="w-full absolute bottom-0 p-2 px-3 flex items-center bg-white border-b border-secondary-200 flex-1 justify-center">
                    <Loading size={20} primary={"#B54708"} secondary={"#fff"} />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
      <CustomModal
        isOpen={isOpenJunctionPiece}
        setIsOpen={setIsOpenJunctionPiece}
        onClose={() => {
          setIsOpenJunctionPiece(false);
        }}
        modalTitle={t("product:finishJuction")}
        width="fit-content"
        children={
          <FinishJunctionModal
            t={t}
            setIsOpen={setIsOpenJunctionPiece}
            isOpen={isOpenJunctionPiece}
            handleData={refetch}
            junctionId={finishJunctionId}
            type={junctionType}
            closeModal={() => {
              setIsOpenJunctionPiece(false);
            }}
          />
        }
      />
    </div>
  );
};

export default ServiceSupplyJunction;

const JunctionListController = ({ items, refetch }) => {
  return items?.map((item, index) => {
    return (
      <div className="min-h-[80px] mb-3">
        <ServiceSupplyEventCard data={item} refetch={refetch} />
      </div>
    );
  });
};

import React, { useEffect, useState } from "react";
import Selector from "../../../components/inputs/Selector";
import { Button, Input } from "../../../components";
import { Toggle } from "../../machine/Twin/components";
import { useTranslation } from "react-i18next";
import TextArea from "../../../components/inputs/TextArea";
import { processService } from "../../../services";
import { externalOperationService } from "../../../services/external-operation.service";
import { CheckIcon } from "../../../assets/icons/machineIcons";
import generateFileUrl from "../../../utils/generateFileUrl";
import UnitPriceInput from "../../../components/inputs/UnitPriceInput";
import NoAvatar from "../../../components/avatar/NoAvatar";
import _ from "lodash";
import { DeleteIcon } from "../../../assets/icons/departmentIcon";

const CreateReceipeModal = ({ items, setItems, stepOneData, setStepOneData, stepTwoData, setStepTwoData, setStepThreeData, stepThreeData, onClose }) => {
  const { t } = useTranslation();
  const [selectedStep, setSelectedStep] = useState(1);

  const selectedTabs = (tabIndex) => {
    switch (tabIndex) {
      case 1:
        return stepOneData;

      case 2:
        return stepTwoData;

      case 3:
        return stepThreeData;

      default:
        return { processName: "Yeni Operasyon Ekle" };
    }
  };

  return (
    <div className="w-full h-full mt-6">
      <div className="flex w-full items-center">
        {[1, 2, 3].map((tabIndex) => (
          <div
            key={tabIndex}
            className={`flex px-1 pb-3 gap-x-3 items-center justify-center w-1/3 cursor-pointer ${
              selectedStep === tabIndex ? "border-b-[2px] border-[#DC6803] text-[#B54708]" : "border-b border-[#E4E7EC]"
            }`}
            onClick={() => setSelectedStep(tabIndex)}
          >
            {!selectedTabs(tabIndex)?.operation ? <PlusIcon /> : null}
            <p className="font-semibold text-md">{selectedTabs(tabIndex)?.operations?.label || t("addProcess:addNewProcess")}</p>
            {selectedTabs(tabIndex)?.operation && (
              <span
                className="ml-2 text-red-500 cursor-pointer hover:text-red-700"
                onClick={(e) => {
                  e.stopPropagation();
                  switch (tabIndex) {
                    case 1:
                      setStepOneData({});
                      break;
                    case 2:
                      setStepTwoData({});
                      break;
                    case 3:
                      setStepThreeData({});
                      break;
                  }
                }}
              >
                <DeleteIcon />
              </span>
            )}
          </div>
        ))}
      </div>
      <OperationStepOne
        oldItems={items}
        setItems={setItems}
        stepOneData={stepOneData}
        setStepOneData={setStepOneData}
        selectedStep={selectedStep}
        setStepThreeData={setStepThreeData}
        stepThreeData={stepThreeData}
        setStepTwoData={setStepTwoData}
        stepTwoData={stepTwoData}
        selectedTabs={selectedTabs}
        onClose={onClose}
      />
    </div>
  );
};

export default CreateReceipeModal;

const OperationStepOne = ({
  setItems,
  oldItems,
  selectedTabs,
  stepOneData,
  setStepOneData,
  selectedStep,
  setStepTwoData,
  stepTwoData,
  setStepThreeData,
  stepThreeData,
  onClose,
}) => {
  const { t } = useTranslation();
  const [internalProcess, setInternalProcess] = useState([]);
  const [externalProcess, setExternalProcess] = useState([]);

  const data = selectedStep === 1 ? stepOneData : selectedStep === 2 ? stepTwoData : stepThreeData;
  const setData = selectedStep === 1 ? setStepOneData : selectedStep === 2 ? setStepTwoData : setStepThreeData;
  const onSubmit = (datas) => {
    const stepNo = oldItems?.length + 1;
    const lastDatas = datas.map((item) => {
      return {
        id: item?.id,
        description: item?.description,
        hasQualityControl: item?.enabled,
        qualityControlPercent: item?.qualityPercent,
        type: item?.operationType,
        step: item?.step ? item?.step || stepOneData[0].step : stepNo,
        process: {
          id: item?.operation,
        },
        processType: item?.operations?.operationType,
        processName: item?.operations?.label,
        processId: item?.operation,
        entityId: item?.operation,
        plannableSupplier: item?.operations?.supplier?.map((supplier) => {
          return {
            id: supplier.id,
            estimatedTime: supplier.estimatedTime,
            estimatedSettingsTime: supplier.estimatedSettingsTime,
            costOfMinute: supplier.costOfMinute,
            currency: supplier.currency,
          };
        }),
        plannableNodes: item?.operations?.stations?.map((station) => {
          return {
            id: station.id,
            estimatedTime: station.estimatedTime,
            estimatedSettingsTime: station.estimatedSettingsTime,
            costOfMinute: station.costOfMinute,
            currency: station.currency,
          };
        }),
      };
    });

    const filtered = lastDatas?.filter((item) => item?.processId);
    const groupedOperations = _.groupBy(filtered, "step");
    const operationsNew = Object.keys(groupedOperations).map((key) => {
      return groupedOperations[key];
    });

    const updatedItems = oldItems.slice();
    operationsNew.forEach((operationGroup) => {
      const step = operationGroup[0].step;
      const existingIndex = updatedItems.findIndex((item) => item[0]?.step === step);

      if (existingIndex !== -1) {
        updatedItems[existingIndex] = operationGroup;
      } else {
        updatedItems.push(operationGroup);
      }
    });

    setItems(updatedItems);
    onClose();
  };

  const operationType = [
    { label: t("product:serviceSupplys"), value: "external" },
    { label: t("routes:addProcess"), value: "internal" },
  ];

  const handleInternalProcessUpdate = async () => {
    let datas = [];
    await processService.activeProcessesWithStations().then((res) => {
      const newTabsData = res?.data.map((item) => ({
        stations: item.stations,
        supplier: item.supplier,
        label: item.name,
        value: item.id,
        operationType: item?.type,
      }));
      datas = newTabsData || [];
    });

    return await setInternalProcess(datas);
  };

  const handleExternalProcessUpdate = async (index) => {
    let datas = [];

    await externalOperationService.listOperation().then((res) => {
      const newTabsData = res?.data.map((item) => ({
        stations: item.stations,
        supplier: item.supplier,
        label: item.name,
        value: item.id,
      }));
      datas = newTabsData || [];
    });

    return await setExternalProcess(datas);
  };

  useEffect(() => {
    handleInternalProcessUpdate();
    handleExternalProcessUpdate();
  }, []);

  return (
    <div className="flex flex-col w-full h-full mt-6 gap-y-6">
      <div className="flex w-full gap-x-6 h-full">
        <div className="flex w-1/2 flex-col gap-y-3">
          <Selector
            value={selectedStep === 1 ? stepOneData?.operationType : selectedStep === 2 ? stepTwoData?.operationType : stepThreeData?.operationType}
            items={operationType}
            theme={"product"}
            label={t("product:serviceOperationType")}
            placeholder={`${t("product:serviceSupplys")}, ${t("routes:addProcess")}`}
            onChange={(value) => {
              {
                switch (selectedStep) {
                  case 1:
                    setStepOneData({ ...stepOneData, operationType: value });
                    break;
                  case 2:
                    setStepTwoData({ ...stepTwoData, operationType: value });
                    break;
                  case 3:
                    setStepThreeData({ ...stepThreeData, operationType: value });
                    break;
                }
              }
            }}
          />
          <div className="flex w-full gap-x-4 items-center">
            <Toggle
              enabled={selectedStep === 1 ? stepOneData?.enabled : selectedStep === 2 ? stepTwoData?.enabled : stepThreeData?.enabled}
              setEnabled={(enabled) => {
                {
                  switch (selectedStep) {
                    case 1:
                      setStepOneData({ ...stepOneData, enabled });
                      break;
                    case 2:
                      setStepTwoData({ ...stepTwoData, enabled });
                      break;
                    case 3:
                      setStepThreeData({ ...stepThreeData, enabled });
                      break;
                  }
                }
              }}
              color={"#DC6803"}
              size={"sm"}
            />
            <p className="text-[#344054] font-medium text-sm">{t("product:addQualityControl")}</p>
          </div>
          <Input
            label={t("product:qualityPercent")}
            value={selectedStep === 1 ? stepOneData?.qualityPercent : selectedStep === 2 ? stepTwoData?.qualityPercent : stepThreeData?.qualityPercent}
            theme={"product"}
            onChange={(e) => {
              {
                switch (selectedStep) {
                  case 1:
                    setStepOneData({ ...stepOneData, qualityPercent: e?.target?.value });
                    break;
                  case 2:
                    setStepTwoData({ ...stepTwoData, qualityPercent: e?.target?.value });
                    break;
                  case 3:
                    setStepThreeData({ ...stepThreeData, qualityPercent: e?.target?.value });
                    break;
                }
              }
            }}
            isPercentage
            disabled={selectedStep === 1 ? !stepOneData?.enabled : selectedStep === 2 ? !stepTwoData?.enabled : !stepThreeData?.enabled}
          />
        </div>
        <div className="flex w-[1px] min-w-[1px] max-w-[1px] min-h-full bg-[#E4E7EC]" />
        <div className="flex w-1/2 flex-col gap-y-3 justify-between">
          <Selector
            value={selectedStep === 1 ? stepOneData?.operation : selectedStep === 2 ? stepTwoData?.operation : stepThreeData?.operation}
            items={
              selectedTabs(selectedStep)?.operationType === "internal"
                ? internalProcess?.filter((item) => item?.label != "")
                : externalProcess?.filter((item) => item?.label != "")
            }
            theme={"product"}
            label={t("product:operation")}
            onChange={(value) => {
              setData({
                ...data,
                operation: value,
                operations:
                  selectedTabs(selectedStep)?.operationType == "internal"
                    ? internalProcess.find((item) => item?.value == value)
                    : externalProcess.find((item) => item?.value == value),
              });
            }}
            disabled={selectedStep === 1 ? !stepOneData?.operationType : selectedStep === 2 ? !stepTwoData?.operationType : !stepThreeData?.operationType}
          />
          <TextArea
            label={t("product:description")}
            value={selectedStep === 1 ? stepOneData?.description : selectedStep === 2 ? stepTwoData?.description : stepThreeData?.description}
            onChange={(e) => {
              {
                switch (selectedStep) {
                  case 1:
                    setStepOneData({ ...stepOneData, description: e?.target?.value });
                    break;
                  case 2:
                    setStepTwoData({ ...stepTwoData, description: e?.target?.value });
                    break;
                  case 3:
                    setStepThreeData({ ...stepThreeData, description: e?.target?.value });
                    break;
                }
              }
            }}
            theme={"product"}
            rows={2}
          />
        </div>
      </div>
      <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />

      <div className="flex flex-col w-full min-h-[350px] max-h-[350px] h-[350px] gap-y-5">
        {selectedTabs(selectedStep)?.operationType == "internal" ? (
          <InternalOperationSide
            stepOneData={stepOneData}
            setStepOneData={setStepOneData}
            t={t}
            stepTwoData={stepTwoData}
            setStepTwoData={setStepTwoData}
            stepThreeData={stepThreeData}
            setStepThreeData={setStepThreeData}
            selectedStep={selectedStep}
          />
        ) : (
          <ExternalOperationSide
            stepOneData={stepOneData}
            setStepOneData={setStepOneData}
            stepTwoData={stepTwoData}
            setStepTwoData={setStepTwoData}
            t={t}
            stepThreeData={stepThreeData}
            setStepThreeData={setStepThreeData}
            selectedStep={selectedStep}
          />
        )}
      </div>

      <div className="flex w-full gap-x-3">
        <Button colorType={"secondary-gray"} label={t("buttons:stop")} size={"lg"} onClick={onClose} />
        <Button colorType={"primary-product"} label={t("buttons:save")} size={"lg"} onClick={() => onSubmit([stepOneData, stepTwoData, stepThreeData])} />
      </div>
    </div>
  );
};

const ExternalOperationSide = ({ stepOneData, setStepOneData, selectedStep, setStepTwoData, stepTwoData, setStepThreeData, stepThreeData, t }) => {
  const data = selectedStep === 1 ? stepOneData : selectedStep === 2 ? stepTwoData : stepThreeData;
  const setData = selectedStep === 1 ? setStepOneData : selectedStep === 2 ? setStepTwoData : setStepThreeData;

  const [firstInput, setFirstInput] = useState("");
  const [thirdInput, setThirdInput] = useState("");
  const [fourthInput, setFourthInput] = useState("");

  const externalTableHeadItems = [t("product:stations"), t("product:estimatedProductionTime"), "Dakika Maliyeti"];

  return (
    <>
      <div className="flex flex-col gap-y-1 justify-center">
        <p className="text-[#101828] font-semibold text-xl">{t("product:timeAndCost")}</p>
        <p className="text-[#475467] text-sm">{t("product:enterThePerUnit")}</p>
      </div>
      <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden scrollbar-hide border-b border-[#E4E7EC]">
        {/* Table header */}
        <div className="flex max-h-[26px] min-h-[26px] w-full sticky top-0 bg-white z-[100]">
          {externalTableHeadItems.map((item, i) => (
            <div
              key={i}
              className={`flex border-b px-2 pb-2 border-r border-[#E4E7EC] items-center ${
                i === 0
                  ? "justify-start w-[280px] min-w-[280px]"
                  : i === 1
                  ? "justify-center w-[220px] min-w-[220px]"
                  : "justify-center w-[350px] min-w-[350px]"
              } ${i === externalTableHeadItems.length - 1 ? "border-r-0" : ""}`}
            >
              <span className="text-center text-xs font-medium cursor-pointer whitespace-nowrap">{item}</span>
            </div>
          ))}
        </div>
        {data?.operations?.supplier?.length > 0 && (
          <div className="flex w-full items-center border-b h-[80px] min-h-[80px] max-h-[80px]">
            {/* Kolon 1: Tedarikçi Bilgisi */}
            <div
              onClick={() => {
                const newDatas = [...data?.operations?.supplier];
                const allChecked = newDatas.every((item) => item?.checked);
                newDatas.forEach((item) => {
                  item.checked = !allChecked;
                });
                setData({ ...data, operations: { ...data?.operations, supplier: newDatas } });
              }}
              className="flex items-center gap-x-3 py-4 pr-4 border-r h-full min-w-[280px] justify-start text-[#101828] font-semibold text-md cursor-pointer"
            >
              <div
                className={`flex min-w-[20px] max-w-[20px] w-[20px] min-h-[20px] max-h-[20px] h-[20px] border rounded-md p-[3px] items-center justify-center ${
                  data?.operations?.supplier.every((supplier) => supplier.checked) && "bg-[#DC6803] border-0"
                }`}
              >
                <CheckIcon />
              </div>
              {t("product:allOperationStandart")}
            </div>

            {/* Kolon 2: Üretim Süresi */}
            <div className="border-r flex h-full px-4 py-5 items-center justify-center w-[220px] min-w-[220px]">
              <Input
                value={firstInput || data?.estimatedTime || ""}
                onChange={(e) => {
                  setData({
                    ...data,
                    operations: {
                      ...data?.operations,
                      supplier: data?.operations?.supplier.map((item) => ({ ...item, estimatedTime: e.target.value })),
                    },
                  });
                  setFirstInput(e.target.value);
                }}
                theme={"product"}
                disabled={!data?.operations?.supplier?.every((supplier) => supplier.checked)}
              />
            </div>

            {/* Kolon 4 ve 5: Currency ve Price */}
            <div className="flex h-full px-4 py-5 justify-center w-[350px] min-w-[350px] gap-x-6 border-r ">
              <UnitPriceInput
                price={thirdInput || data?.operations?.costOfMinute || ""}
                currency={data?.currency || "USD"}
                theme={"product"}
                items={currencyItems}
                disabled={!data?.operations?.supplier?.every((supplier) => supplier.checked)}
                size="sm"
                receipe={true}
                setValue={(field, value) => {
                  setData({
                    ...data,
                    operations: {
                      ...data?.operations,
                      supplier: data?.operations?.supplier.map((item) => ({ ...item, [field]: value })),
                    },
                  });
                  field == "currency" ? setFourthInput(value) : setThirdInput(value);
                }}
              />
            </div>
          </div>
        )}

        {data?.operations?.supplier?.map((supplier, i) => (
          <div key={supplier.id} className="flex w-full items-center border-b h-[80px] min-h-[80px] max-h-[80px]">
            {/* Kolon 1: Tedarikçi Bilgisi */}
            <div
              onClick={() => {
                setData({
                  ...data,
                  operations: {
                    ...data?.operations,
                    supplier: data?.operations?.supplier.map((item) => (item.id === supplier.id ? { ...item, checked: !item.checked } : item)),
                  },
                });
              }}
              className="flex items-center gap-x-3 py-4 pr-4 border-r h-full w-[280px] justify-start cursor-pointer"
            >
              <div
                className={`flex min-w-[20px] max-w-[20px] w-[20px] min-h-[20px] max-h-[20px] h-[20px] border rounded-md p-[3px] items-center justify-center ${
                  supplier?.checked && "bg-[#DC6803] border-0"
                }`}
              >
                <CheckIcon />
              </div>
              {supplier.image ? (
                <img className="w-[64px] h-[48px] rounded border border-[#0000001A]" src={generateFileUrl(supplier.image)} alt={supplier.name} />
              ) : (
                <div className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-200 overflow-hidden">
                  <NoAvatar name={supplier?.name || "--"} rounded={4} theme={"product"} />
                </div>
              )}

              <div className="flex flex-col gap-y-1 justify-center">
                <p className="text-[#101828] font-semibold text-md w-[156px] truncate">{supplier.name || "--"}</p>
                <p className="text-[#475467] text-sm">{supplier?.processName || "--"}</p>
              </div>
            </div>

            {/* Kolon 2: Üretim Süresi */}
            <div className="border-r flex h-full px-4 py-5 items-center justify-center w-[220px] min-w-[220px]">
              <Input
                value={supplier.estimatedTime || ""}
                onChange={(e) => {
                  setData({
                    ...data,
                    operations: {
                      ...data?.operations,
                      supplier: data?.operations?.supplier?.map((item) => (item.id === supplier.id ? { ...item, estimatedTime: e.target.value } : item)),
                    },
                  });
                }}
                theme={"product"}
                disabled={!supplier.checked}
              />
            </div>

            {/* Kolon 4 ve 5: Currency ve Price */}
            <div className="flex h-full px-4 py-5 justify-center w-[350px] min-w-[350px] gap-x-6 border-r ">
              <UnitPriceInput
                price={supplier.costOfMinute || ""}
                currency={supplier.currency || "USD"}
                theme={"product"}
                receipe={true}
                items={currencyItems}
                disabled={!supplier.checked}
                size="sm"
                setValue={(field, value) => {
                  setData({
                    ...data,
                    operations: {
                      ...data?.operations,
                      supplier: data?.operations?.supplier.map((item) => (item.id === supplier.id ? { ...item, [field]: value } : item)),
                    },
                  });
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const InternalOperationSide = ({ stepOneData, setStepOneData, selectedStep, setStepTwoData, stepTwoData, setStepThreeData, stepThreeData, t }) => {
  const data = selectedStep === 1 ? stepOneData : selectedStep === 2 ? stepTwoData : stepThreeData;
  const setData = selectedStep === 1 ? setStepOneData : selectedStep === 2 ? setStepTwoData : setStepThreeData;

  const [firstInput, setFirstInput] = useState("");
  const [secondInput, setSecondInput] = useState("");
  const [thirdInput, setThirdInput] = useState("");
  const [fourthInput, setFourthInput] = useState("");

  const isAllStationsSelected = data?.operations?.stations?.every((station) => station?.checked) || false;

  const tableHeadItems = [t("product:stations"), t("product:estimatedProductionTime"), t("product:estimatedSetupTimes"), t("product:minuteCost")];

  const items = [
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
    { label: "GBP", value: "GBP" },
    { label: "RUB", value: "RUB" },
    { label: "UAH", value: "UAH" },
    { label: "TRY", value: "TRY" },
  ];

  return (
    <>
      <div className="flex flex-col gap-y-1 justify-center">
        <p className="text-[#101828] font-semibold text-xl">{t("product:timeAndCost")}</p>
        <p className="text-[#475467] text-sm">{t("product:enterThePerUnitSetup")}</p>
      </div>
      <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden scrollbar-hide border-b border-[#E4E7EC]">
        {/* Table header */}
        <div className="flex max-h-[26px] min-h-[26px] w-full sticky top-0 bg-white z-[100]">
          {tableHeadItems.map((item, i) => (
            <div
              key={i}
              className={`flex border-b px-2 pb-2 border-r border-[#E4E7EC] items-center ${
                i === 0 ? "justify-start w-[248px] min-w-[248px]" : i === 1 || i === 2 ? "justify-center w-[206px]" : "justify-center w-[222px] min-w-[222px]"
              } ${i === tableHeadItems.length - 1 ? "border-r-0" : ""}`}
            >
              <span className="text-center text-xs font-medium cursor-pointer whitespace-nowrap">{item}</span>
            </div>
          ))}
        </div>
        {data?.operations?.stations?.length > 0 && (
          <div className="flex w-full items-center border-b h-[80px] min-h-[80px] max-h-[80px]">
            {/* Kolon 1: İstasyon Bilgisi */}
            <div
              onClick={() => {
                const newDatas = [...data?.operations?.stations];
                const allChecked = newDatas.every((item) => item?.checked);
                newDatas.forEach((item) => {
                  item.checked = !allChecked;
                });
                setData({ ...data, operations: { ...data?.operations, stations: newDatas } });
              }}
              className="flex items-center gap-x-3 py-4 pr-4 border-r h-full min-w-[248px] justify-start text-[#101828] font-semibold text-md cursor-pointer"
            >
              <div
                className={`flex min-w-[20px] max-w-[20px] w-[20px] min-h-[20px] max-h-[20px] h-[20px] border rounded-md p-[3px] items-center justify-center ${
                  data?.operations?.stations?.every((station) => station.checked) && "bg-[#DC6803] border-0"
                }`}
              >
                <CheckIcon />
              </div>
              {t("product:allOperationStandart")}
            </div>

            {/* Kolon 2: Üretim Süresi */}
            <div className="border-r flex h-full px-4 py-5 items-center justify-center w-[204px] min-w-[204px]">
              <Input
                onChange={(e) => {
                  setData({
                    ...data,
                    operations: {
                      ...data?.operations,
                      stations: data?.operations?.stations?.map((item) => ({ ...item, estimatedTime: e.target.value })),
                    },
                  });
                  setFirstInput(e.target.value);
                }}
                theme={"product"}
                value={firstInput || data?.estimatedTime || ""}
                disabled={!isAllStationsSelected}
              />
            </div>

            {/* Kolon 3: Kurulum Süresi */}
            <div className="border-r flex h-full px-4 py-5 items-center justify-center w-[176px] min-w-[176px]  ">
              <Input
                onChange={(e) => {
                  setData({
                    ...data,
                    operations: {
                      ...data?.operations,
                      stations: data?.operations?.stations?.map((item) => ({ ...item, estimatedSettingsTime: e.target.value })),
                    },
                  });
                  setSecondInput(e.target.value);
                }}
                theme={"product"}
                disabled={!isAllStationsSelected}
                value={secondInput || data?.estimatedSettingsTime || ""}
              />
            </div>

            {/* Kolon 4 ve 5: Currency ve Price */}
            <div className="flex h-full px-4 py-5 justify-center w-[222px] gap-x-6 border-r  ">
              <UnitPriceInput
                setValue={(field, value) => {
                  setData({
                    ...data,
                    operations: {
                      ...data?.operations,
                      stations: data?.operations?.stations?.map((item) => ({ ...item, costOfMinute: value, currency: value })),
                    },
                  });
                  field == "currency" ? setFourthInput(value) : setThirdInput(value);
                }}
                items={items}
                price={thirdInput || data?.costOfMinute}
                theme={"product"}
                currency={data?.currency || fourthInput || "USD"}
                value={thirdInput || data?.costOfMinute || ""}
                size="sm"
                receipe={true}
                disabled={!data?.operations?.stations?.every((stations) => stations?.checked)}
              />
            </div>
          </div>
        )}

        {data?.operations?.stations?.map((station, i) => (
          <div key={station.id} className="flex w-full items-center border-b h-[80px] min-h-[80px] max-h-[80px]">
            {/* Kolon 1: İstasyon Bilgisi */}
            <div
              onClick={() => {
                setData({
                  ...data,
                  operations: {
                    ...data?.operations,
                    stations: data?.operations?.stations?.map((item) => (item.id === station.id ? { ...item, checked: !item.checked } : item)),
                  },
                });
              }}
              className="flex items-center gap-x-3 py-4 pr-4 border-r h-full min-w-[248px] max-w-[248px] justify-start cursor-pointer"
            >
              <div
                className={`flex min-w-[20px] max-w-[20px] w-[20px] min-h-[20px] max-h-[20px] h-[20px] border rounded-md p-[3px] items-center justify-center ${
                  station?.checked && "bg-[#DC6803] border-0"
                }`}
              >
                <CheckIcon />
              </div>
              <img className="w-[64px] h-[48px] rounded border border-[#0000001A]" src={generateFileUrl(station?.metadata?.image)} alt={station?.name} />
              <div className="flex flex-col gap-y-1 justify-center">
                <p className="text-[#101828] font-semibold text-md truncate w-[136px]">{station?.metadata?.brand + station?.metadata?.model || "--"}</p>
                <p className="text-[#475467] text-sm">{station?.metadata?.process?.name || "--"}</p>
              </div>
            </div>

            {/* Kolon 2: Üretim Süresi */}
            <div className="border-r flex h-full px-4 py-5 items-center justify-center w-[204px] min-w-[204px] ">
              <Input
                disabled={!station?.checked}
                value={station?.estimatedTime || ""}
                onChange={(e) => {
                  setData({
                    ...data,
                    operations: {
                      ...data?.operations,
                      stations: data?.operations?.stations?.map((item) => (item.id === station.id ? { ...item, estimatedTime: e.target.value } : item)),
                    },
                  });
                }}
                theme={"product"}
              />
            </div>

            {/* Kolon 3: Kurulum Süresi */}
            <div className="border-r flex h-full px-4 py-5 items-center justify-center w-[176px] min-w-[176px]">
              <Input
                disabled={!station?.checked}
                value={station?.estimatedSettingsTime || ""}
                onChange={(e) => {
                  setData({
                    ...data,
                    operations: {
                      ...data?.operations,
                      stations: data?.operations?.stations?.map((item) => (item.id === station.id ? { ...item, estimatedSettingsTime: e.target.value } : item)),
                    },
                  });
                }}
                theme={"product"}
              />
            </div>

            {/* Kolon 4 ve 5: Currency ve Price */}
            <div className="flex h-full px-4 py-5 justify-center w-[222px] min-w-[222px] gap-x-6 border-r">
              <UnitPriceInput
                price={station?.costOfMinute || station?.price || ""}
                currency={station?.currency || "USD"}
                theme={"product"}
                disabled={!station?.checked}
                items={currencyItems}
                size="sm"
                receipe={true}
                setValue={(field, value) => {
                  setData({
                    ...data,
                    operations: {
                      ...data?.operations,
                      stations: data?.operations?.stations?.map((item) => (item.id === station.id ? { ...item, [field]: value } : item)),
                    },
                  });
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

//icons
export const PlusIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M9.99984 4.16666V15.8333M4.1665 10H15.8332" stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export const currencyItems = [
  { label: "USD", value: "USD" },
  { label: "EUR", value: "EUR" },
  { label: "GBP", value: "GBP" },
  { label: "RUB", value: "RUB" },
  { label: "UAH", value: "UAH" },
  { label: "TRY", value: "TRY" },
];

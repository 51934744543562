import React, { useState } from "react";
import CustomImage from "../CustomImage";
import { NoEquIcon } from "../../assets/icons/machineIcons";
import { DecraceStockIcon, EquipmentEditIcon, FreeStockIcon, NewDotsIcon, PieceIcon, RezervStockIcon } from "../new-cards/card-icons";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import EditStockQuantityModal from "../../pages/product/modals/EditStockQuantityModal";
import CustomModal from "../modal/CustomModal";
import { DetailedIcon } from "../../assets/icons/commonIcons";
import { useLocation, useNavigate } from "react-router-dom";
import { CalendarIcons } from "../../assets/icons/productIcons";
import moment from "moment";
const RawMaterialListMaterialCard = ({ item }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openStockQuantityEdit, setOpenStockQuantityEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const menuOptions = [
    {
      label: t("product:editStockQuantity"),
      value: "stockQuantityEdit",
      icon: <DetailedIcon />,
    },
    {
      label: t("product:editRawMaterials"),
      value: "rawMaterialEdit",
      icon: <EquipmentEditIcon />,
    },
  ];

  return (
    <>
      <div data-tooltip-id={`${item?.id}`}
        data-tooltip-place={"right"}
        data-tooltip-content={`${item?.name} `} className="flex bg-white flex-row  w-[312px] min-w-[312px] h-[112px] min-h-[112px] relative group pl-2  pt-2 border border-[#D0D5DD] rounded-lg cursor-grab ">
        <div className="flex flex-col w-[100%]">
          <div className="flex flex-row items-center gap-x-3">
            {item?.image ? (
              <div>
                <CustomImage
                  borderRadius={2}
                  src={item?.image}
                  style={{
                    width: 64,
                    height: 48,
                    minWidth: 64,
                    minHeight: 48,
                    borderRadius: 4,
                    borderWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}

            <div className="flex flex-col items-start">
              <div className="flex flex-row items-center gap-x-1 w-full">
                <p className="font-semibold text-secondary-900 text-base w-[185px] truncate">{item?.name || "Product Name"}</p>
              </div>

              <div className="bg-[#FFFAEB] max-w-[185px] h-6 rounded px-[3px] py-1">
                <p className="text-xs font-medium max-w-[185px] truncate text-[#B54708]">
                  {t("product:stock")} : {item?.stockCode || "--"}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-2 w-[100%]">
            <div className="flex flex-col items-start">
              <div className="flex flex-row items-center mt-1.5">
                <RezervStockIcon />
                <p className="text-sm font-medium ml-1 text-secondary-700">{item?.reserveStock || "0"}</p>
                <p className="text-[10px] ml-0.5 mt-0.5 font-normal text-secondary-500">{item?.unitType || "ad"}. {t("product:reservedStockss")}</p>
              </div>
              <div className="flex flex-row items-center mt-0.5">
                <FreeStockIcon />
                <p className="text-sm font-medium ml-1 text-secondary-700">{item?.freeStock || "0"}</p>
                <p className="text-[10px] ml-0.5 mt-0.5 font-normal text-secondary-500">{item?.unitType || "ad"}. {t("product:freeStock")}</p>
              </div>
            </div>
            <div className="w-[111px] h-[42px] flex items-center mt-1.5 self-stretch border  border-[#E4E7EC] rounded ml-auto mr-2 overflow-hidden ">
              <div className="w-[42px] h-[18px] px-[1px] py-1 -ml-[11.5px] -mr-3 -rotate-90 justify-center items-center flex gap-2 border-b  border-[#E4E7EC] bg-[#F9FAFB]">
                <span className="text-xxs truncate text-[#475467] font-medium ">{t("product:lastProcess")}</span>
              </div>
              <div className="flex flex-col items-start p-1">
                <div className="flex flex-row items-center ">
                  <DecraceStockIcon />
                  <p className="text-xs font-medium ml-1 text-secondary-600">{"0" || "--"}</p>
                  <p className="text-xxs ml-0.5 font-normal text-secondary-600">{t("product:pcs")}</p>
                </div>
                <div className="flex flex-row items-center ">
                  <CalendarIcons />
                  <p className="text-xs font-medium ml-1 text-secondary-600">{moment(item?.updatedDate).format("DD.MM.YYYY") || "--"}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 bg-[#F2F4F7] border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px]`}
        >
          {i18next?.language === "tr" ? (
            <p className={`text-xxs font-semibold text-secondary-600 -rotate-90`}>{"Hammadde"}</p>
          ) : (
            <div className="flex flex-col items-center gap-y-[14px]">
              <p className={`text-[10px] font-semibold text-[#475467] -rotate-90`}>{t("product:material")}</p>
              <p className={`text-[10px] font-semibold text-[#475467] ${i18next?.language === "tr" ? "-mt-[1px]" : "mt-[4px]"} -rotate-90`}>{t("product:raw")}</p>
            </div>
          )}

        </div>
        <div
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(!isOpen);
          }}
          className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <NewDotsIcon />
        </div>
        {isOpen && (
          <div className="hidden flex-col absolute min-w-[225px] max-w-[225px] h-fit bg-white border top-[20px] right-[4px] z-[110] rounded-lg px-[6px] py-1 group-hover:flex">
            {menuOptions?.map((items, index) => {
              return (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (items.value === "stockQuantityEdit") {
                      // setIsPurchased(true);
                      setOpenStockQuantityEdit(true);
                    }
                    if (items.value === "rawMaterialEdit") {
                      navigate(`/app/product/mrp/stock/raw-material/edit/${item?.id}`);
                    }
                    setIsOpen(false);
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                    items?.value === "delete" ? "hover:bg-[#FECDCA]" : "hover:bg-secondary-100"
                  }`}
                >
                  {items?.icon}
                  <p className={`font-medium text-sm  ${items?.value === "delete" ? "text-[#D92D20]" : "text-secondary-700"}`}>{items?.label}</p>
                </button>
              );
            })}
          </div>
        )}
        {!isOpen && <Tooltip id={`${item?.id}`} />}
      </div>
      <CustomModal
        isOpen={openStockQuantityEdit}
        setIsOpen={setOpenStockQuantityEdit}
        onClose={() => {
          setOpenStockQuantityEdit(false);
        }}
        modalTitle={t("product:editStockQuantity")}
        width={400}
        children={
          <EditStockQuantityModal
            onClose={() => {
              setOpenStockQuantityEdit(false);
            }}
            item={item}
          />
        }
      />
    </>
  );
};

export default RawMaterialListMaterialCard;

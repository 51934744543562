import React, { useEffect, useState } from "react";
import { Button, CustomModal } from "../../components";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useStore } from "../../hooks/useStores";
import Badgets from "../../components/buttons/Badgets";
import { ArrowLeftIcon, ArrowRightIcon, CalendarIcon } from "../../assets/icons/PersonIcons";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import EndDatePicker from "../person/components/EndDatePicker";
import { timeFormatter } from "../../utils/timezoneFormatter";
import useWindowDimensions from "../../hooks/useWindowDimension";
import SearchInput from "../../components/inputs/SearchInput";

import { junctionService } from "../../services/junction.service";
import AllOperationsCard from "./components/AllOperationsCard";
import generateFileUrl from "../../utils/generateFileUrl";
import FinishJunctionModal from "../../components/modal/FinishJunctionModal";
import diacritics from "diacritics";
import SupplyEventCard from "../../components/new-cards/junction-card/SupplyEventCard";
import SupplyActiveEventCard from "../../components/new-cards/junction-card/SupplyActiveEventCard";
import { ChevronDownIcon, ChevronUpIcon } from "../../components/new-cards/card-icons";
import { SwitchIcon, TransformIcon } from "../../assets/icons/commonIcons";
import { QualityTab } from "./quality/components";
import { Toast } from "../../utils/toastify/toast";
import AllOperationsNewCard from "./components/AllOperationsNewCard";
import { flexJunctionService } from "../../services/flex-junction.service";
import { FilterIcon } from "../../assets/icons/productIcons";

function QualityExternal() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { height } = useWindowDimensions();
  const { settings, auth } = useStore();
  const [visible, setVisible] = useState(false);
  const [isOpenJunctionPiece, setIsOpenJunctionPiece] = useState(false);
  const [finishJunctionId, setFinishJunctionId] = useState(null);
  const [junctionType, setJunctionType] = useState("external");
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [operations, setOperations] = useState([]);
  const [isPastDay, setIsPastDay] = useState(false);
  const [hiddenPreviousPending, setHiddenPreviousPending] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selected, setSelected] = useState("all");
  const [selectOperationData, setSelectOperationData] = useState(null);
  const [draggedJunction, setDraggedJunction] = useState(null);
  const handleCardClick = (cardInfo) => {
    setSelected(cardInfo);
  };
  const { data, isLoading, refetch, isFetching } = useQuery({
    retry: 1,
    retryDelay: 3200,
    queryKey: ["quality-list"],
    queryFn: async () =>
      await flexJunctionService.getQualityJunctions({
        date: moment(timeFormatter(date, auth?.user?.company?.timeZone)?.formatted).format("YYYY-MM-DD"),
        type : "external"
      }),
  });

  const cleanAndMatchSearch = (item, searchTerm) => {
    const fullName = `${item?.job?.product?.name}`;
    const cleanedFullName = diacritics.remove(fullName).toLowerCase();
    const cleanedSearchTerm = diacritics.remove(searchTerm.toLowerCase());
    return cleanedFullName.includes(cleanedSearchTerm);
  };

  const filteredActiveJunction = data?.activeJunction?.filter((item) => {
    return cleanAndMatchSearch(item, searchTerm);
  });

  const filteredOperationActiveJunction = selectOperationData?.activeJunction?.filter((item) => {
    return cleanAndMatchSearch(item, searchTerm);
  });

  const filteredPendingJunction = data?.pendingJunction?.filter((item) => {
    return cleanAndMatchSearch(item, searchTerm);
  });

  const filteredOperationPendingJunction = selectOperationData?.pendingJunction?.filter((item) => {
    return cleanAndMatchSearch(item, searchTerm);
  });

  const filteredPreviousPendingJunction = data?.previousPending?.filter((item) => {
    return cleanAndMatchSearch(item, searchTerm);
  });

  const filteredOperationPreviousPendingJunction = selectOperationData?.previousPending?.filter((item) => {
    return cleanAndMatchSearch(item, searchTerm);
  });

  const filteredPastJunction = data?.pastJunction?.filter((item) => {
    return cleanAndMatchSearch(item, searchTerm);
  });

  const filteredOperationPastJunction = selectOperationData?.pastJunction?.filter((item) => {
    return cleanAndMatchSearch(item, searchTerm);
  });

  useEffect(() => {
    refetch();
    setSelected("all");
  }, [date]);

  useEffect(() => {
    if (auth?.user?.company?.id == "e5c06127-3c5d-44b9-8403-74c160fc7f59") {
    } else if (auth?.user?.company?.id == "ab77a50a-7b68-42a2-ba5c-0ebfb5c13325") {
    } else if (auth?.user?.company?.id == "71b0edec-e5d4-4929-bfdb-0e5d5e149592") {
    } else if (auth?.user?.company?.id == "dd1892b9-d9a3-44ed-9131-17c88a74a1b4") {
    } else if (auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706") {
    } else if (auth?.user?.company?.id == "49ee8491-35d7-4eb2-a0e9-bfc95541180f") {
    } else if (auth?.user?.company?.id == "65844198-a9e8-45fa-bc99-7d012a541b35") {
    } else if (auth?.user?.company?.id == "7c5bb5a6-21aa-4d1d-829b-2a6d360b9630") {
    } else if (auth?.user?.company?.id == "f7db6191-5842-455a-99f4-c76763090689") {
    } else if (auth?.user?.company?.id == "776c0df3-0a44-464f-9877-33bd731aef6a") {
    } else if (auth?.user?.company?.id == "065e9b57-9da2-4394-9359-59ba71610dc3") {
    } else if (auth?.user?.company?.id == "e41a9bde-203c-48f2-aeec-4f8ce6344513") {
    } else if (auth?.user?.company?.id == "262d8e02-57e8-427e-9159-6b18b236ca86") {
    } else if (auth?.user?.company?.id == "0c082d32-6d97-4ba9-a2a5-7e41fe8139d9") {
      
    } else {
      navigate("/app/product/verysoon");
    }
  }, []);

  useEffect(() => {
    setOperations(data?.operations);
  }, [data, isLoading]);

  const today = moment().format("YYYY-MM-DD");

  useEffect(() => {
    if (moment(date).format("YYYY-MM-DD") !== today) {
      setIsPastDay(true);
    } else {
      setIsPastDay(false);
    }
  }, [date]);

  const handleFinishedOnDrop = () => {
    if (draggedJunction?.id) {
      if (!(draggedJunction?.status === "completed")) {
        Toast("error", t("product:columnDragDropWarning"));
        return;
      }
      // setIsOpenQualityForm(true);
    }
  };

  const handleWaitingOnDrop = () => {
    if (draggedJunction?.id) {
      if (!(draggedJunction?.status === "active")) {
        Toast("error", t("product:columnDragDropWarning"));
        return;
      }
      setJunctionType("internal");
      setFinishJunctionId(draggedJunction?.id);
      setIsOpenJunctionPiece(true);
    }
  };

  return (
    <>
      <div className="w-full h-full flex flex-col overflow-hidden scrollbar-hide overflow-y-auto">
        <div className="flex w-full border-b h-14 min-h-[58px] mb-4 sm:justify-between items-center ">
          {isLoading || isFetching ? (
            <div className="w-full h-[48px] bg-gray-200 rounded-xl animate-pulse" />
          ) : (
            <>
              <div className=" sm:w-1/2 sm:h-full sm:flex md:flex sm:items-center">
                <span className="mr-3 md:flex sm:flex xs:flex hidden">
                  <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
                </span>
                <div className="flex items-center">
                  <span className="font-codecMedium text-2xl text-black">upu.</span>
                  <p className="text-2xl font-codecMedium text-[#B54708]">quality</p>
                  <p className="text-xl font-normal ml-2 text-secondary-500">
                    {selected && selected?.name ? `${t("product:serviceIncomingQuality")} / ${selected?.name}` : t("product:serviceIncomingQuality")}
                  </p>
                </div>
              </div>
              <div className="ml-auto flex items-center gap-3 sm:hidden xs:hidden">
                <Button
                  iconLeft={<ArrowLeftIcon />}
                  size={"md"}
                  colorType={"secondary-gray"}
                  onClick={() => setDate(moment(date).subtract(1, "days").format("YYYY-MM-DD"))}
                />
                <EndDatePicker
                  date={date}
                  visible={visible}
                  setDate={setDate}
                  setVisible={setVisible}
                  buttonClassName={"flex flex-col items-center"}
                  calendarClassName={"absolute top-12 rounded-lg shadow-xl z-[600]"}
                  visibleFooter
                >
                  <Button
                    onClick={() => setVisible(!visible)}
                    iconLeft={<CalendarIcon />}
                    size={"md"}
                    colorType={"secondary-gray"}
                    label={moment(date).format("DD.MM.YYYY")}
                  />
                </EndDatePicker>
                <Button
                  disabled={moment(new Date()).format("YYYY-MM-DD") === moment(new Date(date)).format("YYYY-MM-DD")}
                  iconLeft={<ArrowRightIcon />}
                  size={"md"}
                  colorType={"secondary-gray"}
                  onClick={() => setDate(moment(date).subtract(-1, "days").format("YYYY-MM-DD"))}
                />
              </div>
            </>
          )}
        </div>
        {isLoading || isFetching ? (
          <div className="w-full h-[134px] bg-gray-200  rounded-xl animate-pulse" />
        ) : (
          <div className={`flex w-full overflow-x-auto pb-1 ${!isPastDay ? "min-h-[116px] max-h-[116px]" : "min-h-[116px] max-h-[116px]"}`}>
            <AllOperationsNewCard
              isPastDay={isPastDay}
              pendingJunctionlength={data?.pendingJunction?.length}
              activeJunctionlength={data?.activeJunction?.length}
              pastJunctionlength={data?.pastJunction?.length}
              allCount={data?.allCount}
              onClick={handleCardClick}
              selected={selected}
            />
            {/* <AllOperationsCard
              isPastDay={isPastDay}
              pendingJunctionlength={data?.pendingJunction?.length}
              activeJunctionlength={data?.activeJunction?.length}
              pastJunctionlength={data?.pastJunction?.length}
              allCount={data?.allCount}
              onClick={handleCardClick}
              selected={selected}
            /> */}
            {operations
              ?.sort((a, b) => b?.activeJunction - a?.activeJunction)
              ?.map((item, index) => {
                return (
                  <div className={`min-w-[192px] ${index !== operations?.length - 1 ? "border-b" : "border-b-0"} max-h-[96px]`}>
                    <div
                      onClick={async () => {
                        handleCardClick(item);
                        await junctionService
                          .getQualityExternalJunctionsByOperationId(item?.id, {
                            date: moment(timeFormatter(date, auth?.user?.company?.timeZone)?.formatted).format("YYYY-MM-DD"),
                            type : "external"
                          })
                          .then((r) => setSelectOperationData(r?.data));
                      }}
                      key={index}
                      className={`min-w-[172px] max-w-[172px] rounded-t-lg ${selected?.id === item?.id ? "border-b-[2px] border-b-[#A15C07] h-[96px]" : "h-[96px]"
                        } rounded-b-none border cursor-pointer border-seconday-300  select-none flex flex-col`}
                    >
                      <div className="flex flex-row items-center justify-center w-full gap-x-1.5 p-2 border-b border-b-[#E4E7EC]">
                        {/* {icon ? <img className="w-4 h-4" src={generateFileUrl(icon)} /> : <TransformIcon className="w-4 h-4" />} */}
                        <p className={`text-base  truncate ${selected?.id === item?.id ? "text-[#B54708] font-semibold" : "text-[#667085] font-medium"} `}>
                          {item?.name || "--"}
                        </p>
                      </div>
                      <div className="flex flex-row items-center w-full h-full">
                        {!isPastDay && (
                          <div className={`flex flex-col items-center w-1/2  border-r ${selected?.id === item?.id ? "bg-[#F2F4F7]" : "bg-[#F9FAFB]"}`}>
                            <div className="w-full flex items-center justify-center border-b border-b-[#E4E7EC]">
                              <p className={`text-xs ${selected?.id === item?.id ? "text-[#344054] font-medium" : "text-[#667085] font-normal"}`}>
                                {t("product:actives")}
                              </p>
                            </div>
                            <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
                              <p className={`text-sm ${selected?.id === item?.id ? "font-semibold text-[#079455]" : "font-medium text-[#667085]"}`}>
                                {item?.activeJunction || 0}
                              </p>
                            </div>
                          </div>
                        )}

                        <div
                          className={`flex flex-col items-center w-1/2  ${selected?.id === item?.id ? "bg-[#F2F4F7]" : "bg-[#F9FAFB]"} ${isPastDay ? "border-r" : ""
                            }`}
                        >
                          <div className="w-full flex items-center justify-center border-b border-b-[#E4E7EC]">
                            <p className={`text-xs ${selected?.id === item?.id ? "text-[#344054] font-medium" : "text-[#667085] font-normal"}`}>
                              {t("product:waiting")}
                            </p>
                          </div>
                          <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
                            <p className={`text-sm ${selected?.id === item?.id ? "font-semibold text-[#A15C07]" : "font-medium text-[#667085]"}`}>
                              {item?.pendingJunction || 0}
                            </p>
                          </div>
                        </div>
                        {isPastDay && (
                          <div className={`flex flex-col items-center w-1/2  ${selected?.id === item?.id ? "bg-[#F2F4F7]" : "bg-[#F9FAFB]"}`}>
                            <div className="w-full flex items-center justify-center border-b border-b-[#E4E7EC]">
                              <p className={`text-xs ${selected?.id === item?.id ? "text-[#344054] font-medium" : "text-[#667085] font-normal"}`}>
                                {t("product:completedNew")}
                              </p>
                            </div>
                            <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
                              <p className={`text-sm ${selected?.id === item?.id ? "font-semibold text-secondary-600" : "font-medium text-[#667085]"}`}>
                                {item?.pastJunction || 0}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  // <div
                  //   onClick={async () => {
                  //     handleCardClick(item);
                  //     await junctionService
                  //       .getQualityExternalJunctionsByOperationId(item?.id, {
                  //         date: moment(timeFormatter(date, auth?.user?.company?.timeZone)?.formatted).format("YYYY-MM-DD"),
                  //       })
                  //       .then((r) => setSelectOperationData(r?.data));
                  //   }}
                  //   key={index}
                  //   className={`flex flex-col min-w-[172px] max-w-[172px] border h-[96%] shadow-sm ${
                  //     selected?.id === item?.id ? "border-[#DC6803]" : "border-[#EAECF0]"
                  //   }  px-3 py-2 rounded-lg cursor-pointer`}
                  // >
                  //   <div className="flex gap-x-2 pb-[6px] items-center justify-center">
                  //     {/* <span className="min-w-[20px] max-w-[20px] min-h-[20px] max-h-[20px]">
                  //       {item?.iconKey ? <img src={generateFileUrl(item?.iconKey)} /> : <TransformIcon />}
                  //     </span> */}
                  //     <span className="text-[#344054] text-sm font-semibold truncate">{item?.name}</span>
                  //   </div>
                  //   <div className="flex flex-col w-full h-full justify-center">
                  //     {!isPastDay && (
                  //       <div className="flex min-h-[30px] max-h-[30px] border-b border-[#EAECF0] py-1 items-center">
                  //         <span className="text-[#667085] font-normal text-xs">{t("product:actives")}</span>
                  //         <span className="ml-auto">
                  //           <Badgets colorType={"fill-success"} size={"sm"} label={item?.activeJunction || 0} />
                  //         </span>
                  //       </div>
                  //     )}
                  //     <div className="flex min-h-[30px] max-h-[30px] border-b border-[#EAECF0] py-1 items-center">
                  //       <span className="text-[#667085] font-normal text-xs">{t("product:waiting")}</span>
                  //       <span className="ml-auto">
                  //         <Badgets colorType={"fill-warning"} size={"sm"} label={item?.pendingJunction || 0} />
                  //       </span>
                  //     </div>
                  //     <div className="flex min-h-[26px] max-h-[26px] py-1 items-center">
                  //       <span className="text-[#667085] font-normal text-xs">{t("product:completedNew")}</span>
                  //       <span className="ml-auto pt-1">
                  //         <Badgets colorType={"fill-gray"} size={"sm"} label={item?.pastJunction || 0} />
                  //       </span>
                  //     </div>
                  //   </div>
                  // </div>
                );
              })}
          </div>
        )}

        {isLoading || isFetching ? (
          <div className="w-full flex-1 bg-gray-200 mt-4 rounded-xl animate-pulse" />
        ) : (
          <div style={{ minHeight: height - 50 }} className="w-full flex border border-[#D0D5DD] rounded-xl mt-4 flex-col md:overflow-y-auto overflow-hidden">
            <div className="flex min-h-[72px] max-h-[72px] p-4 border-b border-b-secondary-300 justify-between w-full items-center">
              <span className="text-[#101828] text-xl font-semibold">{selected?.name || t("product:allNew")}</span>
              <div className="flex gap-x-3">
                <span className="flex min-w-[220px] max-w-[220px] h-full items-center justify-center">
                  <SearchInput theme={"product"} setSearchVal={setSearchTerm} placeholder={t("chat:search")} />
                </span>
                <Button colorType={"secondary-gray"} size={"md"} label={t("product:sorted")} iconLeft={<SwitchIcon />} />
                <Button colorType={"secondary-gray"} size={"md"} label={t("product:filters")} iconLeft={<FilterIcon />} />
              </div>
            </div>
            <div className="flex w-full flex-1 md:overflow-x-auto overflow-hidden pb-4">
              {!isPastDay && (
                <QualityTab
                  length={selected === "all" ? filteredActiveJunction?.length : filteredOperationActiveJunction?.length || 0}
                  type={"active"}
                  onDragOver={(e) => e.preventDefault()}
                  onDragEnd={() => {
                    setDraggedJunction(null);
                  }}
                  children={
                    <div className="">
                      {selected === "all" ? (
                        <>
                          {filteredActiveJunction?.map((item, index) => {
                            const startDate = moment(item?.startDate);
                            return (
                              <div draggable onDragStart={() => setDraggedJunction(item)} className="min-h-[80px] cursor-pointer mb-3">
                                <SupplyActiveEventCard
                                  data={item}
                                  refetch={refetch}
                                  pages={"activeAndPlanned"}
                                  setJunctionType={setJunctionType}
                                  setFinishJunctionId={setFinishJunctionId}
                                  setIsOpenJunctionPiece={setIsOpenJunctionPiece}
                                />
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {filteredOperationActiveJunction?.map((item, index) => {
                            const startDate = moment(item?.startDate);
                            return (
                              <div draggable onDragStart={() => setDraggedJunction(item)} className="min-h-[80px] cursor-pointer mb-3">
                                <SupplyActiveEventCard
                                  data={item}
                                  refetch={refetch}
                                  pages={"activeAndPlanned"}
                                  setJunctionType={setJunctionType}
                                  setFinishJunctionId={setFinishJunctionId}
                                  setIsOpenJunctionPiece={setIsOpenJunctionPiece}
                                />
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  }
                />
              )}

              <QualityTab
                length={selected === "all" ? filteredPendingJunction?.length : filteredOperationPendingJunction?.length || 0}
                type={"waiting"}
                onDragEnd={() => {
                  setDraggedJunction(null);
                }}
                onDragOver={(e) => e.preventDefault()}
                onDrop={() => {
                  handleWaitingOnDrop();
                }}
                children={
                  <>
                    <JunctionListController
                      setDraggedJunction={setDraggedJunction}
                      items={selected === "all" ? filteredPendingJunction : filteredOperationPendingJunction}
                      refetch={refetch}
                      navigate={navigate}
                      completed={true}
                    />
                    {/* {!isPastDay && (
                      <>
                        <div
                          className={`flex flex-col w-full h-full pb-16 border border-b-0 border-[#EAECF0] rounded-t-xl shadow-sm overflow-y-auto overflow-x-hidden scrollbar-hide bg-[#FCFCFD] py-4 px-5 ${hiddenPreviousPending ? "h-full" : "min-h-[100px] h-[100px] max-h-[100px]"
                            }`}
                        >
                          <div className="flex w-full gap-x-2 items-center">
                            <span className="text-[#344054] font-semibold text-sm items-center">{t("product:remainingFromOtherDays")}</span>
                            <Badgets
                              label={selected === "all" ? filteredPreviousPendingJunction?.length : filteredOperationPreviousPendingJunction?.length || 0}
                              colorType={"outline-warning"}
                              size={"sm"}
                            />
                            <span className="ml-auto">
                              <Button
                                colorType={"tertiary-gray"}
                                iconLeft={hiddenPreviousPending ? <ChevronUpIcon /> : <ChevronDownIcon />}
                                size={"sm"}
                                onClick={() => setHiddenPreviousPending(!hiddenPreviousPending)}
                              />
                            </span>
                          </div>
                          {hiddenPreviousPending && (
                            <div className="flex flex-col overflow-y-auto overflow-x-hidden gap-y-8 pt-2 scrollbar-hide h-full w-full">
                              <JunctionListController
                                setDraggedJunction={setDraggedJunction}
                                items={selected === "all" ? filteredPreviousPendingJunction : filteredOperationPreviousPendingJunction}
                                refetch={refetch}
                                navigate={navigate}
                                completed={true}
                              />
                            </div>
                          )}
                        </div>
                      </>
                    )} */}
                  </>
                }
              />
              <QualityTab
                type={"finished"}
                length={selected === "all" ? filteredPastJunction?.length : filteredOperationPastJunction?.length || 0}
                onDragOver={(e) => e.preventDefault()}
                onDrop={() => {
                  handleFinishedOnDrop();
                }}
                children={
                  <JunctionListController
                    setDraggedJunction={setDraggedJunction}
                    items={selected === "all" ? filteredPastJunction : filteredOperationPastJunction}
                    refetch={refetch}
                    navigate={navigate}
                    completed={true}
                  />
                }
              />
            </div>
          </div>
        )}
      </div>
      <CustomModal
        isOpen={isOpenJunctionPiece}
        setIsOpen={setIsOpenJunctionPiece}
        onClose={() => {
          setIsOpenJunctionPiece(false);
        }}
        modalTitle={t("product:finishJuction")}
        width="fit-content"
        children={
          <FinishJunctionModal
            t={t}
            setIsOpen={setIsOpenJunctionPiece}
            isOpen={isOpenJunctionPiece}
            handleData={refetch}
            junctionId={finishJunctionId}
            type={junctionType}
            closeModal={() => {
              setIsOpenJunctionPiece(false);
            }}
          />
        }
      />
    </>
  );
}

export default QualityExternal;

const JunctionListController = ({ items, refetch, setDraggedJunction }) => {
  return items?.map((item, index) => {
    return (
      <div draggable onDragStart={() => setDraggedJunction(item)} className="min-h-[80px] mb-3">
        <SupplyEventCard data={item} refetch={refetch} />
      </div>
    );
  });
};

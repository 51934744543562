import React, { useState, useEffect } from "react";
import { Button, CustomModal } from "../../components";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useStore } from "../../hooks/useStores";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

import SearchInput from "../../components/inputs/SearchInput";
import Badgets from "../../components/buttons/Badgets";
import generateFileUrl from "../../utils/generateFileUrl";
import AllOperationsCard from "./components/AllOperationsCard";
import { purchaseService } from "../../services/purchase.service";
import PurchaseJunctionFinish from "./modals/PurchaseJunctionFinish";
import RawQualityControlForm from "./modals/RawQualityControlForm";
import diacritics from "diacritics";
import QualityMaterialEventCard from "../../components/new-cards/junction-card/QualityMaterialEventCard";
import QualityMaterialActiveEventCard from "../../components/new-cards/junction-card/QualityMaterialActiveEventCard";
import useWindowDimensions from "../../hooks/useWindowDimension";
import { SwitchIcon, TransformIcon } from "../../assets/icons/commonIcons";
import { QualityTab } from "./quality/components";
import { Toast } from "../../utils/toastify/toast";
import AllOperationsNewCard from "./components/AllOperationsNewCard";
import { FilterIcon } from "../../assets/icons/productIcons";

const MesQualitySupply = () => {
  const { t } = useTranslation();
  const { settings, auth } = useStore();
  let navigate = useNavigate();
  const { height } = useWindowDimensions();
  const [searchValue, setSearchValue] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState("all");
  const [finishPurhcaseModal, setFinishPurhcaseModal] = useState(false);
  const [finishPurhcaseModalData, setFinishPurhcaseModalData] = useState(null);
  const [purchaseQualityForm, setPurchaseQualityForm] = useState(false);
  const [purchaseQualityFormData, setPurchaseQualityFormData] = useState(null);
  const [draggedJunction, setDraggedJunction] = useState(null);

  const purchaseJunctionData = useQuery({
    queryKey: ["all-supplier-group-junctions-for-quality"],
    enabled: true,
    retry: 0,
    queryFn: async () => {
      return await purchaseService.getPurchaseJunctionsForCompany();
    },
  });
  const dataBySupplierGroup = useQuery({
    queryKey: ["supplier-group-junctions-for-quality"],
    enabled: false,
    retry: 0,
    queryFn: async () => {
      return await purchaseService.getPurchaseJunctionsForSupplierGroup(selectedGroup.id);
    },
  });
  useEffect(() => {
    if (selectedGroup == "all") {
      purchaseJunctionData?.refetch();
    } else {
      dataBySupplierGroup?.refetch();
    }
  }, [selectedGroup]);
  useEffect(() => {
    if (auth?.user?.company?.id == "e5c06127-3c5d-44b9-8403-74c160fc7f59") {
    } else if (auth?.user?.company?.id == "ab77a50a-7b68-42a2-ba5c-0ebfb5c13325") {
    } else if (auth?.user?.company?.id == "71b0edec-e5d4-4929-bfdb-0e5d5e149592") {
    } else if (auth?.user?.company?.id == "dd1892b9-d9a3-44ed-9131-17c88a74a1b4") {
    } else if (auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706") {
    } else if (auth?.user?.company?.id == "49ee8491-35d7-4eb2-a0e9-bfc95541180f") {
    } else if (auth?.user?.company?.id == "65844198-a9e8-45fa-bc99-7d012a541b35") {
    } else if (auth?.user?.company?.id == "7c5bb5a6-21aa-4d1d-829b-2a6d360b9630") {
    } else if (auth?.user?.company?.id == "f7db6191-5842-455a-99f4-c76763090689") {
    } else if (auth?.user?.company?.id == "776c0df3-0a44-464f-9877-33bd731aef6a") {
    } else if (auth?.user?.company?.id == "065e9b57-9da2-4394-9359-59ba71610dc3") {
    } else if (auth?.user?.company?.id == "e41a9bde-203c-48f2-aeec-4f8ce6344513") {
    } else if (auth?.user?.company?.id == "262d8e02-57e8-427e-9159-6b18b236ca86") {
    } else if (auth?.user?.company?.id == "0c082d32-6d97-4ba9-a2a5-7e41fe8139d9") {
      
    } else {
      navigate("/app/product/verysoon");
    }
  }, []);

  const filteredPurchaseJunctionActiveData = purchaseJunctionData?.data?.activeJunction?.filter((d) => {
    const rawMaterialName = d?.purchaseRequest?.rawMaterial?.name || "";
    const normalizedRawMaterialName = diacritics.remove(rawMaterialName).toLowerCase();
    const normalizedSearchValue = diacritics.remove(searchValue || "").toLowerCase();
    return normalizedRawMaterialName.includes(normalizedSearchValue);
  });

  const filteredPurchaseJunctionPastJunctionData = purchaseJunctionData?.data?.pastJunction?.filter((d) => {
    const rawMaterialName = d?.purchaseRequest?.rawMaterial?.name || "";
    const normalizedRawMaterialName = diacritics.remove(rawMaterialName).toLowerCase();
    const normalizedSearchValue = diacritics.remove(searchValue || "").toLowerCase();
    return normalizedRawMaterialName.includes(normalizedSearchValue);
  });

  const filteredPurchaseJunctionPendingJunctionData = purchaseJunctionData?.data?.pendingJunction?.filter((d) => {
    const rawMaterialName = d?.purchaseRequest?.rawMaterial?.name || "";
    const normalizedRawMaterialName = diacritics.remove(rawMaterialName).toLowerCase();
    const normalizedSearchValue = diacritics.remove(searchValue || "").toLowerCase();
    return normalizedRawMaterialName.includes(normalizedSearchValue);
  });

  const filteredDataBySupplierJunctionActiveData = dataBySupplierGroup?.data?.activeJunction?.filter((d) => {
    const rawMaterialName = d?.purchaseRequest?.rawMaterial?.name || "";
    const normalizedRawMaterialName = diacritics.remove(rawMaterialName).toLowerCase();
    const normalizedSearchValue = diacritics.remove(searchValue || "").toLowerCase();
    return normalizedRawMaterialName.includes(normalizedSearchValue);
  });

  const filteredDataBySupplierJunctionPendingData = dataBySupplierGroup?.data?.pendingJunction?.filter((d) => {
    const rawMaterialName = d?.purchaseRequest?.rawMaterial?.name || "";
    const normalizedRawMaterialName = diacritics.remove(rawMaterialName).toLowerCase();
    const normalizedSearchValue = diacritics.remove(searchValue || "").toLowerCase();
    return normalizedRawMaterialName.includes(normalizedSearchValue);
  });

  const filteredDataBySupplierPastJunctionData = dataBySupplierGroup?.data?.pastJunction?.filter((d) => {
    const rawMaterialName = d?.purchaseRequest?.rawMaterial?.name || "";
    const normalizedRawMaterialName = diacritics.remove(rawMaterialName).toLowerCase();
    const normalizedSearchValue = diacritics.remove(searchValue || "").toLowerCase();
    return normalizedRawMaterialName.includes(normalizedSearchValue);
  });

  const handleFinishedOnDrop = () => {
    if (draggedJunction?.id) {
      if (!(draggedJunction?.status === "completed")) {
        Toast("error", t("product:columnDragDropWarning"));
        return;
      }

      setPurchaseQualityForm(true);
      setPurchaseQualityFormData(draggedJunction);
    }
  };

  const handleWaitingOnDrop = () => {
    if (draggedJunction?.id) {
      if (!(draggedJunction?.status === "active")) {
        Toast("error", t("product:columnDragDropWarning"));
        return;
      }
      setFinishPurhcaseModal(true);
      setFinishPurhcaseModalData(draggedJunction);
    }
  };

  return (
    <div className="w-full h-full flex flex-col overflow-hidden overflow-y-auto scrollbar-hide">
      <>
        <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
          {dataBySupplierGroup?.isLoading || purchaseJunctionData?.isLoading ? (
            <div className="w-full h-[48px] bg-gray-200 rounded-xl animate-pulse" />
          ) : (
            <>
              <span className="mr-3 md:flex sm:flex xs:flex hidden">
                <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
              </span>
              <div className="flex items-center">
                <span className="font-codecMedium text-2xl text-black">upu.</span>
                <p className="text-2xl font-codecMedium text-[#B54708]">quality</p>
                <p className="text-xl font-normal ml-2 text-secondary-500">
                  {selectedGroup && selectedGroup?.name
                    ? `${t("product:materialIncomingQuality")} / ${selectedGroup?.name}`
                    : t("product:materialIncomingQuality")}
                </p>
              </div>
            </>
          )}
        </div>
        {dataBySupplierGroup?.isLoading || purchaseJunctionData?.isLoading ? (
          <div className="w-full h-[134px] bg-gray-200  rounded-xl animate-pulse" />
        ) : (
          <div className={`flex w-full  overflow-x-auto pb-1 ${!false ? "min-h-[116px] max-h-[116px]" : "min-h-[116px] max-h-[116px]"}`}>
            <AllOperationsNewCard
              isPastDay={false}
              pendingJunctionlength={purchaseJunctionData?.data?.pendingJunction?.length}
              activeJunctionlength={purchaseJunctionData?.data?.activeJunction?.length}
              pastJunctionlength={purchaseJunctionData?.data?.pastJunction?.length}
              allCount={purchaseJunctionData?.data?.allCount}
              onClick={setSelectedGroup}
              selected={selectedGroup}
            />
            {purchaseJunctionData?.data?.operations
              ?.sort((a, b) => b?.active + b?.past + b?.pending - (a?.active + a?.past + a?.pending))
              ?.map((item, index) => {
                console.log(item, "ITEMMMM");
                return (
                  <div className={`min-w-[192px] ${index !== purchaseJunctionData?.data?.operations?.length - 1 ? "border-b" : "border-b-0"} max-h-[96px]`}>
                    <div
                      onClick={async () => {
                        setSelectedGroup(item);
                      }}
                      key={index}
                      className={`min-w-[172px] max-w-[172px] rounded-t-lg ${selectedGroup?.id === item?.id ? "border-b-[2px] border-b-[#A15C07] h-[96px]" : "h-[96px]"
                        } rounded-b-none border cursor-pointer border-seconday-300  select-none flex flex-col`}
                    >
                      <div className="flex flex-row items-center justify-center w-full gap-x-1.5 p-2 border-b border-b-[#E4E7EC]">
                        {/* {icon ? <img className="w-4 h-4" src={generateFileUrl(icon)} /> : <TransformIcon className="w-4 h-4" />} */}
                        <p className={`text-base  truncate ${selectedGroup?.id === item?.id ? "text-[#B54708] font-semibold" : "text-[#667085] font-medium"} `}>
                          {item?.name || "--"}
                        </p>
                      </div>
                      <div className="flex flex-row items-center w-full h-full">
                        {!false && (
                          <div className={`flex flex-col items-center w-1/2  border-r ${selectedGroup?.id === item?.id ? "bg-[#F2F4F7]" : "bg-[#F9FAFB]"}`}>
                            <div className="w-full flex items-center justify-center border-b border-b-[#E4E7EC]">
                              <p className={`text-xs ${selectedGroup?.id === item?.id ? "text-[#344054] font-medium" : "text-[#667085] font-normal"}`}>
                                {t("product:actives")}
                              </p>
                            </div>
                            <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
                              <p className={`text-sm ${selectedGroup?.id === item?.id ? "font-semibold text-[#079455]" : "font-medium text-[#667085]"}`}>
                                {item?.active || 0}
                              </p>
                            </div>
                          </div>
                        )}

                        <div className={`flex flex-col items-center w-1/2  ${selectedGroup?.id === item?.id ? "bg-[#F2F4F7]" : "bg-[#F9FAFB]"}`}>
                          <div className="w-full flex items-center justify-center border-b border-b-[#E4E7EC]">
                            <p className={`text-xs ${selectedGroup?.id === item?.id ? "text-[#344054] font-medium" : "text-[#667085] font-normal"}`}>
                              {t("product:waiting")}
                            </p>
                          </div>
                          <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
                            <p className={`text-sm ${selectedGroup?.id === item?.id ? "font-semibold text-[#A15C07]" : "font-medium text-[#667085]"}`}>
                              {item?.pending || 0}
                            </p>
                          </div>
                        </div>
                        {false && (
                          <div className={`flex flex-col items-center w-1/2  ${selectedGroup?.id === item?.id ? "bg-[#F2F4F7]" : "bg-[#F9FAFB]"}`}>
                            <div className="w-full flex items-center justify-center border-b border-b-[#E4E7EC]">
                              <p className={`text-xs ${selectedGroup?.id === item?.id ? "text-[#344054] font-medium" : "text-[#667085] font-normal"}`}>
                                {t("product:completedNew")}
                              </p>
                            </div>
                            <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
                              <p className={`text-sm ${selectedGroup?.id === item?.id ? "font-semibold text-secondary-600" : "font-medium text-[#667085]"}`}>
                                {item?.past || 0}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}

        {dataBySupplierGroup?.isFetching || dataBySupplierGroup?.isLoading || purchaseJunctionData?.isFetching || purchaseJunctionData?.isLoading ? (
          <div className="w-full flex-1 bg-gray-200 mt-4 rounded-xl animate-pulse" />
        ) : (
          <>
            <div style={{ minHeight: height - 50 }} className="w-full flex border border-[#D0D5DD] rounded-xl mt-4 flex-col md:overflow-y-auto overflow-hidden">
              <div className="flex min-h-[72px] max-h-[72px] p-4 justify-between w-full border-b border-b-secondary-300 items-center">
                <span className="text-[#101828] text-xl font-semibold">{selectedGroup?.name || t("product:allNew")}</span>
                <div className="flex gap-x-3">
                  <span className="flex min-w-[220px] max-w-[220px] h-full items-center justify-center">
                    <SearchInput theme={"product"} setSearchVal={setSearchValue} placeholder={t("chat:search")} />
                  </span>
                  <Button colorType={"secondary-gray"} size={"md"} label={t("product:sorted")} iconLeft={<SwitchIcon />} />
                  <Button colorType={"secondary-gray"} size={"md"} label={t("product:filters")} iconLeft={<FilterIcon />} />
                </div>
              </div>
              <div className="flex w-full flex-1 md:overflow-x-auto overflow-hidden pb-4">
                <QualityTab
                  type={"active"}
                  length={selectedGroup == "all" ? purchaseJunctionData?.data?.activeJunction?.length : dataBySupplierGroup?.data?.activeJunction?.length || 0}
                  onDragOver={(e) => e.preventDefault()}
                  onDragEnd={() => {
                    setDraggedJunction(null);
                  }}
                  children={
                    <ActivePurchaseJunctionListController
                      setDraggedJunction={setDraggedJunction}
                      items={selectedGroup == "all" ? filteredPurchaseJunctionActiveData : filteredDataBySupplierJunctionActiveData}
                      navigate={navigate}
                      setFinishPurhcaseModal={setFinishPurhcaseModal}
                      setFinishPurhcaseModalData={setFinishPurhcaseModalData}
                    />
                  }
                />
                <QualityTab
                  type={"waiting"}
                  length={
                    selectedGroup == "all" ? purchaseJunctionData?.data?.pendingJunction?.length : dataBySupplierGroup?.data?.pendingJunction?.length || 0
                  }
                  onDragEnd={() => {
                    setDraggedJunction(null);
                  }}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={() => {
                    handleWaitingOnDrop();
                  }}
                  children={
                    <JunctionListController
                      setDraggedJunction={setDraggedJunction}
                      items={selectedGroup == "all" ? filteredPurchaseJunctionPendingJunctionData : filteredDataBySupplierJunctionPendingData}
                      setPurchaseQualityForm={setPurchaseQualityForm}
                      setPurchaseQualityFormData={setPurchaseQualityFormData}
                      navigate={navigate}
                      purchaseQualityForm={purchaseQualityForm}
                      purchaseQualityFormData={purchaseQualityFormData}
                    />
                  }
                />

                <QualityTab
                  length={selectedGroup == "all" ? purchaseJunctionData?.data?.pastJunction?.length : dataBySupplierGroup?.data?.pastJunction?.length || 0}
                  type={"finished"}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={() => {
                    handleFinishedOnDrop();
                  }}
                  children={
                    <JunctionListController
                      setDraggedJunction={setDraggedJunction}
                      items={selectedGroup == "all" ? filteredPurchaseJunctionPastJunctionData : filteredDataBySupplierPastJunctionData}
                      setPurchaseQualityForm={setPurchaseQualityForm}
                      setPurchaseQualityFormData={setPurchaseQualityFormData}
                      navigate={navigate}
                      purchaseQualityForm={purchaseQualityForm}
                      purchaseQualityFormData={purchaseQualityFormData}
                    />
                  }
                />
              </div>
            </div>
          </>
        )}
      </>
      <CustomModal
        isOpen={finishPurhcaseModal}
        setIsOpen={setFinishPurhcaseModal}
        modalTitle={t("product:purchaseApproval")}
        children={
          <PurchaseJunctionFinish
            refetch={() => {
              purchaseJunctionData?.refetch();
              dataBySupplierGroup?.refetch();
            }}
            close={() => setFinishPurhcaseModal(false)}
            junctionData={finishPurhcaseModalData}
            rawData={{
              ...finishPurhcaseModalData?.purchaseRequest?.rawMaterial,
              deliveryDate: finishPurhcaseModalData?.estimatedDeliveryDate,
            }}
          />
        }
      />
      <CustomModal
        isOpen={purchaseQualityForm}
        setIsOpen={setPurchaseQualityForm}
        modalTitle={t("product:productRawMaterial")}
        children={
          <RawQualityControlForm
            refetch={() => {
              if (selectedGroup == "all") {
                purchaseJunctionData?.refetch();
              } else {
                dataBySupplierGroup?.refetch();
              }
            }}
            close={() => setPurchaseQualityForm(false)}
            junctionData={purchaseQualityFormData}
            rawData={{
              ...purchaseQualityFormData?.purchaseRequest?.rawMaterial,
              deliveryDate: purchaseQualityFormData?.estimatedDeliveryDate,
            }}
          />
        }
      />
    </div>
  );
};

export default MesQualitySupply;

const JunctionListController = ({ items, setPurchaseQualityFormData, setPurchaseQualityForm, setDraggedJunction }) => {
  return items?.map((item, index) => {
    return (
      <div draggable onDragStart={() => setDraggedJunction(item)} key={index} className="min-h-[80px] mb-3">
        <QualityMaterialEventCard data={item} setPurchaseQualityForm={setPurchaseQualityForm} setPurchaseQualityFormData={setPurchaseQualityFormData} />
      </div>
    );
  });
};

const ActivePurchaseJunctionListController = ({ items, setFinishPurhcaseModal, setFinishPurhcaseModalData, setDraggedJunction }) => {
  return items?.map((item, index) => {
    return (
      <div draggable onDragStart={() => setDraggedJunction(item)} key={index} className="min-h-[80px] cursor-pointer mb-3">
        <QualityMaterialActiveEventCard data={item} setFinishPurhcaseModal={setFinishPurhcaseModal} setFinishPurhcaseModalData={setFinishPurhcaseModalData} />
      </div>
    );
  });
};

import React from 'react'
import generateFileUrl from '../../../../utils/generateFileUrl'
import { useTranslation } from 'react-i18next'
import { TransformIcon } from '../../../../assets/icons/commonIcons'
const NewOrderHeaderCard = ({ selectedOperation, id, name, planned, unPlanned, onClick, icon }) => {
  const { t } = useTranslation()
  return (
    <div
      onClick={onClick}
      className={`min-w-[172px] rounded-t-lg ${selectedOperation?.id === id ? "border-b-[2px] border-b-[#A15C07] h-[96px]" : "h-[96px]"} rounded-b-none border cursor-pointer border-seconday-300  select-none flex flex-col`}
    >
      <div className="flex flex-row items-center justify-center w-full gap-x-1.5 p-2 border-b border-b-[#E4E7EC]">
        {/* {icon ? <img className="w-4 h-4" src={generateFileUrl(icon)} /> : <TransformIcon className="w-4 h-4" />} */}
        <p className={`text-base  truncate ${selectedOperation?.id === id ? "text-[#B54708] font-semibold" : "text-[#667085] font-medium"} `}>{name}</p>
      </div>
      <div className="flex flex-row items-center w-full h-full">
        <div className={`flex flex-col items-center w-1/2  border-r ${selectedOperation?.id === id ? "bg-[#F2F4F7]" : "bg-[#F9FAFB]"}`}>
          <div className="w-full flex items-center justify-center border-b border-b-[#E4E7EC]">
            <p className={`text-xs ${selectedOperation?.id === id ? "text-[#344054] font-medium" : "text-[#667085] font-normal"}`}>{t("reports:planned")}</p>
          </div>
          <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
            <p className={`text-sm ${selectedOperation?.id === id ? "font-semibold text-[#475467]" : "font-medium text-[#667085]"}`}>{planned} {t("product:day")}</p>
          </div>
        </div>
        <div className={`flex flex-col items-center w-1/2  ${selectedOperation?.id === id ? "bg-[#F2F4F7]" : "bg-[#F9FAFB]"}`}>
          <div className="w-full flex items-center justify-center border-b border-b-[#E4E7EC]">
            <p className={`text-xs ${selectedOperation?.id === id ? "text-[#344054] font-medium" : "text-[#667085] font-normal"}`}>{t("product:unplanned")}</p>
          </div>
          <div className="flex items-center justify-center px-2 py-[6px] w-full bg-white">
            <p className={`text-sm ${selectedOperation?.id === id ? "font-semibold text-[#A15C07]" : "font-medium text-[#667085]"}`}>{unPlanned} {t("product:day")}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewOrderHeaderCard
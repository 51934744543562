import React, { useState } from "react";
import { Input } from "../../../../components";
import { CheckIcon } from "../../../../assets/icons/machineIcons";
import generateFileUrl from "../../../../utils/generateFileUrl";
import UnitPriceInput from "../../../../components/inputs/UnitPriceInput";
import NoAvatar from "../../../../components/avatar/NoAvatar";
import _ from "lodash";
const ExternalOperationSide = ({ stepOneData, setStepOneData, selectedStep, setStepTwoData, stepTwoData, setStepThreeData, stepThreeData, t }) => {
    const data = selectedStep === 1 ? stepOneData : selectedStep === 2 ? stepTwoData : stepThreeData;
    const setData = selectedStep === 1 ? setStepOneData : selectedStep === 2 ? setStepTwoData : setStepThreeData;
    const [firstInput, setFirstInput] = useState("");
    const [thirdInput, setThirdInput] = useState("");
    const [fourthInput, setFourthInput] = useState("");
    const [isAllSame, setIsAllSame] = useState(data?.isAllSame);
    const externalTableHeadItems = [t("product:stations"), `${t("product:estimatedProductionTime")} (day)`, "Dakika Maliyeti"];
    return (
        <>
            <div className="flex flex-col gap-y-1 justify-center">
                <p className="text-[#101828] font-semibold text-xl">{t("product:timeAndCost")}</p>
                <p className="text-[#475467] text-sm">{t("product:enterThePerUnit")}</p>
            </div>
            <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden scrollbar-hide border-b border-[#E4E7EC]">
                <div className="flex max-h-[26px] min-h-[26px] w-full sticky top-0 bg-white z-[100]">
                    {externalTableHeadItems.map((item, i) => (
                        <div
                            key={i}
                            className={`flex border-b px-2 pb-2 border-r border-[#E4E7EC] items-center ${i === 0 ? "justify-start w-[280px] min-w-[280px]" : i === 1 ? "justify-center w-[220px] min-w-[220px]" : "justify-center w-[350px] min-w-[350px]"
                                } ${i === externalTableHeadItems.length - 1 ? "border-r-0" : ""}`}
                        >
                            <span className="text-center text-xs font-medium cursor-pointer whitespace-nowrap">{item}</span>
                        </div>
                    ))}
                </div>
                <div className="flex w-full items-center border-b h-[80px] min-h-[80px] max-h-[80px]">
                    <div
                        onClick={() => {
                            if (!isAllSame) {
                                setIsAllSame(true);
                                setData({
                                    ...data,
                                    isAllSame: true,
                                    operations: {
                                        ...data?.operations,
                                        supplier: data?.operations?.supplier.map((item) => ({ ...item, estimatedTime: firstInput, costOfMinute: thirdInput, currency: fourthInput })),
                                    },
                                });
                            } else { setIsAllSame(false); setData({ ...data, isAllSame: false }) }
                        }}
                        className="flex items-center gap-x-3 py-4 pr-4 border-r h-full min-w-[280px] justify-start text-[#101828] font-semibold text-md cursor-pointer"
                    >
                        <div className={`flex min-w-[20px] max-w-[20px] w-[20px] min-h-[20px] max-h-[20px] h-[20px] border rounded-md p-[3px] items-center justify-center ${isAllSame && "bg-[#DC6803] border-0"}`}>
                            <CheckIcon />
                        </div>
                        {t("product:allOperationStandart")}
                    </div>
                    <div className="border-r flex h-full px-4 py-5 items-center justify-center w-[220px] min-w-[220px]">
                        <Input
                            value={data?.estimatedTime || firstInput || ""}
                            onChange={(e) => {
                                setFirstInput(e.target.value);
                                if (isAllSame) {
                                    setData({
                                        ...data,
                                        estimatedTime: e.target.value,
                                        operations: {
                                            ...data?.operations,
                                            supplier: data?.operations?.supplier.map((item) => ({ ...item, estimatedTime: e.target.value })),
                                        },
                                    });
                                } else {
                                    setData({
                                        ...data,
                                        estimatedTime: e.target.value,
                                    });
                                }
                            }}
                            theme={"product"}
                        />
                    </div>
                    <div className="flex h-full px-4 py-5 justify-center w-[350px] min-w-[350px] gap-x-6 border-r ">
                        <UnitPriceInput
                            setValue={(field, value) => {
                                field == "currency" ? setFourthInput(value) : setThirdInput(value);
                                if (isAllSame) {
                                    setData({
                                        ...data,
                                        [field]: value,
                                        operations: {
                                            ...data?.operations,
                                            supplier: data?.operations?.supplier.map((item) => ({ ...item, [field]: value })),
                                        },
                                    });
                                } else {
                                    setData({
                                        ...data,
                                        [field]: value,
                                    });
                                }
                            }}
                            items={currencyItems}
                            price={data?.costOfMinute || thirdInput}
                            theme={"product"}
                            currency={data?.currency || fourthInput || "USD"}
                            value={data?.costOfMinute || thirdInput || ""}
                            size="sm"
                            receipe={true}
                        />
                    </div>
                </div>
                {data?.operations?.supplier?.map((supplier, i) => (
                    <div key={supplier.id} className="flex w-full items-center border-b h-[80px] min-h-[80px] max-h-[80px]">
                        {/* Kolon 1: Tedarikçi Bilgisi */}
                        <div className="flex items-center gap-x-3 py-4 pr-4 border-r h-full w-[280px] justify-start cursor-pointer">
                            {supplier.image ? (
                                <img className="w-[64px] h-[48px] rounded border border-[#0000001A]" src={generateFileUrl(supplier.image)} alt={supplier.name} />
                            ) : (
                                <div className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-200 overflow-hidden">
                                    <NoAvatar name={supplier?.name || "--"} rounded={4} theme={"product"} />
                                </div>
                            )}
                            <div className="flex flex-col gap-y-1 justify-center">
                                <p className="text-[#101828] font-semibold text-md w-[156px] truncate">{supplier.name || "--"}</p>
                                <p className="text-[#475467] text-sm">{supplier?.processName || "--"}</p>
                            </div>
                        </div>
                        {/* Kolon 2: Üretim Süresi */}
                        <div className="border-r flex h-full px-4 py-5 items-center justify-center w-[220px] min-w-[220px]">
                            <Input
                                disabled={isAllSame}
                                value={supplier.estimatedTime || data?.estimatedTime || ""}
                                theme={"product"}
                                onChange={(e) => {
                                    setData({
                                        ...data,
                                        operations: {
                                            ...data?.operations,
                                            supplier: data?.operations?.supplier?.map((item) => (item.id === supplier.id ? { ...item, estimatedTime: e.target.value } : item)),
                                        },
                                    });
                                }}
                            />
                        </div>

                        {/* Kolon 4 ve 5: Currency ve Price */}
                        <div className="flex h-full px-4 py-5 justify-center w-[350px] min-w-[350px] gap-x-6 border-r ">
                            <UnitPriceInput
                                disabled={isAllSame}
                                price={supplier?.costOfMinute || supplier?.costOfPiece || data?.costOfMinute || supplier?.price || thirdInput || ""}
                                currency={supplier.currency || data?.currency || "USD"}
                                theme={"product"}
                                items={currencyItems}
                                size="sm"
                                receipe={true}
                                setValue={(field, value) => {
                                    setData({
                                        ...data,
                                        operations: {
                                            ...data?.operations,
                                            supplier: data?.operations?.supplier.map((item) => (item.id === supplier.id ? { ...item, [field]: value } : item)),
                                        },
                                    });
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div >
        </>
    );
};
export default ExternalOperationSide;

export const PlusIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M9.99984 4.16666V15.8333M4.1665 10H15.8332" stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export const currencyItems = [
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
    { label: "GBP", value: "GBP" },
    { label: "RUB", value: "RUB" },
    { label: "UAH", value: "UAH" },
    { label: "TRY", value: "TRY" },
];
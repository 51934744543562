import React, { useState, useEffect, useRef } from "react";
import useWindowDimensions from "../../hooks/useWindowDimension";
import moment from "moment";
import { timeFormatter } from "../../utils/timezoneFormatter";
import MultipleEventRect from "./components/MultipleEventRect";
import generateFileUrl from "../../utils/generateFileUrl";
import { SingleEventRect } from "./components/SingleEventRect";
import { statusToStringTR } from "../../utils/statusToString";
import { formatSeconds, secondToHoursAndMinute2 } from "../../utils/secondToHoursAndMinute";
import { Tooltip } from "react-tooltip";
import ApprovedCard from "../junction/ApprovedCard";
import MultipleEventRectMulti from "./components/MultipleEventRectMulti";
import NoData from "../../steps/step-navigation/components/NoData";
import { useTranslation } from "react-i18next";
import TimeRange from "react-video-timelines-slider";
import { getStartOfDayForCompany } from "./TimelineSlider";
import LossesCardType from "../junction/LossesCardType";
import PersonnelDetailTodayTimelineDoneCard from "../new-cards/personnel-detail-card/PersonnelDetailTodayTimelineDoneCard";
import PersonnelDetailTimelineActiveCard from "../new-cards/personnel-detail-card/PersonnelDetailTimelineActiveCard";
import PersonnelDetailTimelineDoneCard from "../new-cards/personnel-detail-card/PersonnelDetailTimelineDoneCard";
import { useNavigate } from "react-router-dom";
import PersonnelMultipleEventRectMulti from "./components/PersonnelMultipleEventRectMulti";
import PersonnelSingleEventRect from "./components/PersonnelSingleEventRect";
import i18next from "i18next";

const UpuTimelinePersonelPages = ({
  data,
  startTime,
  endTime,
  date,
  refetch,
  //kontrols
  setStartTime,
  setEndTime,
  setSelectedJunction,
  selectedJunction,
  defaultStartTime,
  defaultEndTime,

  junctionData,

  //grouped data
  groupedDatas,
}) => {
  const { width, height } = useWindowDimensions();
  const { t } = useTranslation();
  const [selectedDayType, setSelectedDayType] = useState(1);
  const [visible, setVisible] = useState(false);

  const [selectedShift, setSelectedShift] = useState(null);

  const [showTooltip, setShowTooltip] = useState(null);
  const [mouseX, setMouseX] = useState(null);

  const navigate = useNavigate();

  const totalWidth = width;

  const totalWidthh = totalWidth - 300;
  let svgWidth = width - 300;

  let svgHeight = data?.timeline?.timelineData?.length * 140;
  const handleMouseOver = (index, xPos, yPos, e) => {
    if (index.index == 0) {
      if (data?.junctionData) {
        data?.junctionData?.filter((e) => {
          if (e.id == index.junctionId) {
            setShowTooltip({ index, xPos, yPos, junction: e });
          }
        });
      } else if (data?.junctions) {
        data?.junctions?.filter((e) => {
          if (e.id == index.junctionId) {
            setShowTooltip({ index, xPos, yPos, junction: e });
          }
        });
      }
    } else {
      setShowTooltip({ index, xPos, yPos });
    }
  };

  const handleMouseMove = (e) => {
    setMouseX(e.clientX);
  };

  const handleMouseOut = () => {
    if (showTooltip) {
      setShowTooltip(null);
    }
  };

  const handleShiftClick = (index) => {
    setStartTime(new Date(timeFormatter(index.startDate).formatted).valueOf());
    setEndTime(new Date(timeFormatter(index.endDate).formatted).valueOf());
  };

  const junctionNumber = (item) => {
    const junctions = junctionData?.filter((a) => a.status != "planned");

    const sortedJunctions = junctions?.sort((a, b) => moment(a?.startDate).unix() - moment(b?.startDate).unix());

    const datas = sortedJunctions?.map((value, index) => {
      if (value?.id == item) {
        return index + 1;
      } else {
        return "";
      }
    });

    const lastData = datas?.filter((a) => a != "");

    if (lastData?.length > 0) {
      return lastData[0];
    } else {
      return 1;
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    if (!visible) {
      // Reset the start and end times when visible is false
      setStartTime(defaultStartTime);
      setEndTime(defaultEndTime);
    }
  }, [visible]);

  const convertSeconds = (seconds) => {
    if (isNaN(seconds) || seconds === Infinity) return "---";

    const minutes = Math.floor((seconds / 60) % 60);
    const hours = Math.floor(seconds / 3600);

    let formattedTime = "";

    if (i18next?.language === "tr") {
      if (hours > 0) {
        formattedTime += hours + " sa ";
      }
      if (minutes > 0) {
        formattedTime += minutes + " dk ";
      }
    } else {
      if (hours > 0) {
        formattedTime += hours + " h ";
      }
      if (minutes > 0) {
        formattedTime += minutes + " min ";
      }
    }

    return formattedTime.trim();
  };

  const sorted = junctionData?.sort((a, b) => {
    return moment(a?.startDate).unix() - moment(b?.startDate).unix();
  });
  const filter = sorted?.filter((element) => {
    return element?.endDate == null;
  });
  const availableTime =
    filter?.length == 0 ? moment(sorted[sorted?.length - 1]?.endDate).diff(sorted[0]?.startDate, "seconds") : moment().diff(sorted[0]?.startDate, "seconds");
  const availableTimeFormatter = convertSeconds(availableTime);

  return (
    <div
      className=" w-full flex flex-col  border-[2px] border-gray-300 rounded-xl  bg-white"
      style={{
        width: totalWidthh - 150,
      }}
    >
      <div className="w-full  rounded-t-lg  flex items-center pl-3 pt-2">
        <span className="isolate inline-flex rounded-lg">
          <button
            onClick={() => {
              setStartTime(defaultStartTime);
              setEndTime(defaultEndTime);
              setSelectedDayType(1);
              setSelectedJunction(null);
            }}
            type="button"
            className="relative inline-flex items-center rounded-l-lg  px-4 py-2 text-sm font-semibold text-secondary-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
            style={{
              backgroundColor: selectedDayType == 1 ? "#F9FAFB" : "white",
            }}
          >
            {t("gauge:allDay")}
          </button>
          {Object?.values(groupedDatas)?.map((shift, index) => {
            return (
              <button
                key={index}
                onClick={() => {
                  setStartTime(new Date(timeFormatter(shift[0]?.startDate)?.formatted).valueOf());
                  setEndTime(new Date(timeFormatter(shift[shift?.length - 1]?.endDate)?.formatted).valueOf());
                  setSelectedDayType(shift[0]?.shiftId);
                }}
                type="button"
                className="relative -ml-px inline-flex items-center  px-3 py-2 text-sm font-semibold text-secondary-[700] ring-1 ring-inset ring-gray-300  focus:z-10 last:rounded-r-md "
                style={{
                  backgroundColor: selectedDayType == shift[0]?.shiftId ? "#F9FAFB" : "white",
                }}
              >
                <span
                  className={`w-3 h-3 rounded-sm mr-2`}
                  style={{
                    backgroundColor: shift[0]?.color,
                  }}
                ></span>
                {shift[0]?.name}
              </button>
            );
          })}
        </span>
        <div className="ml-auto pr-4">
          <div className="px-2 py-[2px] flex items-center w-fit h-[24px] min-h-[24px] rounded-md border border-[#99F6E0] bg-[#F0FDF9]">
            <p className="text-sm font-medium text-[#107569]">
              {t("product:personnelWorkTime")} : {sorted?.length > 0 ? availableTimeFormatter || "0" : "0"}
            </p>
          </div>
        </div>
      </div>

      <div className="w-full flex mt-2 gap-x-5 pl-4">
        <div className="w-[88px] min-w-[88px] max-w-[88px] flex flex-col items-start gap-y-5">
          <div className="flex flex-col min-w-[90px] max-w-[90px] min-h-[62px] max-h-[62px] px-2 pb-2 pt-[6px] gap-y-1 rounded items-center justify-center"></div>
          {data?.timeline?.timelineData?.map((e, i) => {
            return (
              <div
                onClick={() => navigate(`/app/machine/digital-twin/equipment-details/${e?.deviceId}-${e?.nodeId}/${date}`)}
                className="flex flex-col min-w-[90px] max-w-[90px] min-h-[95px] max-h-[95px] px-2 pb-2 pt-[6px] border gap-y-1 rounded shadow-xs border-[#D0D5DD] items-center justify-center"
              >
                <span className="flex w-[60px] h-full text-[#344054] font-semibold text-xs truncate">{e?.name}</span>
                <img
                  data-tooltip-id={`${e?.image}`}
                  data-tooltip-place="right"
                  data-tooltip-content={`${e?.name}`}
                  src={generateFileUrl(e?.image)}
                  className="min-w-[78px] min-h-[58px] border border-[#0000001A] rounded"
                />
                <Tooltip className="z-[999]" id={e?.image} />
              </div>
            );
          })}
        </div>
        <div className="w-full flex flex-col  relative pr-3 gap-y-2">
          <div className="w-full max-h-[42px] min-h-[42px] overflow-hidden relative px-4">
            <TimeRange
              step={1}
              // error={error}
              ticksNumber={30}
              selectedInterval={getStartOfDayForCompany(startTime, endTime)}
              timelineInterval={getStartOfDayForCompany(defaultStartTime, defaultEndTime)}
              onUpdateCallback={(interval) => {
                "";
              }}
              onChangeCallback={(interval) => {
                setStartTime(moment(interval[0]).valueOf());
                setEndTime(moment(interval[1]).valueOf());
              }}
              disabledIntervals={[]}
            />
          </div>
          {data?.timeline?.timelineData?.length == 0 ? (
            <div className="flex w-full items-center justify-center mt-5">
              <span className="mr-[264px]">
                <NoData theme={"machine"} header={t("label:noData")} button={false} />
              </span>
            </div>
          ) : (
            <svg width={svgWidth} height={svgHeight}>
              {data?.timeline?.shiftlyTimelineData?.map((shift, index) => {
                const { startDate, endDate, color } = shift;
                const shiftStartTime = new Date(timeFormatter(startDate)?.formatted).getTime();
                const shiftEndTime = new Date(timeFormatter(endDate)?.formatted).getTime();

                if (selectedShift !== null && selectedShift !== index) {
                  return null;
                }

                const timeRange = endTime - startTime;

                const shiftX = selectedShift === index ? 0 : ((shiftStartTime - startTime) / timeRange) * svgWidth;
                const shiftWidth = selectedShift === index ? svgWidth : ((shiftEndTime - shiftStartTime) / timeRange) * svgWidth;

                const lastElement = data?.timeline?.shiftlyTimelineData[data?.timeline?.shiftlyTimelineData?.length - 1];

                let lastShift = {
                  startDate: lastElement?.endDate,
                  endDate: defaultEndTime,
                  color: "red",
                  endPosition: ((new Date(timeFormatter(defaultEndTime)?.formatted).getTime() - startTime) / timeRange) * svgWidth || 0,
                  startPostion: ((new Date(timeFormatter(lastElement?.endDate)?.formatted).getTime() - startTime) / timeRange) * svgWidth || 0,
                };

                const modifiedShift = {
                  ...shift,
                  startDate: moment(shift?.startDate).add(3, "hour").format("YYYY-MM-DD HH:mm:ss"),
                  endDate: moment(shift?.endDate).add(3, "hour").format("YYYY-MM-DD HH:mm:ss"),
                  // nodeId: nodeId,
                };

                return (
                  <>
                    <PersonnelSingleEventRect
                      key={index}
                      startPos={shiftX}
                      width={shiftWidth}
                      fill={color}
                      onClick={() => {
                        if (selectedJunction === modifiedShift?.shiftId) {
                          setSelectedJunction(!selectedJunction);
                          setStartTime(defaultStartTime);
                          setEndTime(defaultEndTime);
                          refetch();
                        } else {
                          handleShiftClick(shift);
                          setSelectedJunction(shift);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                      event={shift}
                      svgHeight={svgHeight}
                      handleMouseOver={handleMouseOver}
                      handleMouseOut={handleMouseOut}
                    />

                    {shiftWidth > 15 && (
                      <>
                        <rect
                          key={index}
                          x={shiftX}
                          y={30}
                          width={shiftWidth}
                          height={svgHeight}
                          fill={shift?.color == "#FFFFFF" ? "#F2F4F7" : shift?.color}
                          opacity={0.2}
                          onClick={() => handleShiftClick(shift)}
                          // onMouseOver={() => handleMouseOver(event, startPos)}
                          // onMouseOut={handleMouseOut}
                          style={{ cursor: "pointer" }}
                          ry={"4"}
                        ></rect>

                        <text
                          style={{
                            cursor: "pointer",
                          }}
                          x={lastShift?.endPosition}
                          y={22}
                          font-family="Comic Sans MS"
                          font-size="10"
                          fill="black"
                          // onMouseOver={() => handleMouseOver(event, startPos)}
                          // onMouseOut={handleMouseOut}
                        >
                          {moment(timeFormatter(defaultEndTime).formatted).format("HH:mm")}
                        </text>
                      </>
                    )}
                  </>
                );
              })}

              {/* Status and junctions */}
              {data?.timeline?.timelineData?.map((x, y) => {
                return x?.timelineData.map((shift, index) => {
                  const { startDate, endDate, color, timeDiff } = shift;
                  const shiftStartTime = new Date(timeFormatter(startDate)?.formatted).getTime();
                  const shiftEndTime = new Date(timeFormatter(endDate)?.formatted).getTime();

                  const timeRange = endTime - startTime;

                  if (selectedShift !== null && selectedShift !== index) {
                    // Seçili event dışındaki diğer eventleri gösterme
                    return null;
                  }

                  const shiftX = selectedShift === index ? 0 : ((shiftStartTime - startTime) / timeRange) * svgWidth;

                  const statusWidth = ((shiftEndTime - shiftStartTime) / timeRange) * svgWidth;

                  let yOffset = (y + 1) * 113;

                  const modifiedShift = {
                    ...shift,
                    startDate: moment(shift?.startDate).add(3, "hour").format("YYYY-MM-DD HH:mm:ss"),
                    endDate: moment(shift?.endDate).add(3, "hour").format("YYYY-MM-DD HH:mm:ss"),
                    // nodeId: nodeId,
                  };

                  return (
                    <>
                      <PersonnelMultipleEventRectMulti
                        key={index}
                        yOffset={yOffset}
                        startPos={shiftX}
                        width={statusWidth}
                        widthS={statusWidth}
                        event={shift}
                        onClick={() => {
                          if (selectedJunction?.id === modifiedShift?.junctionId) {
                            setSelectedJunction(!selectedJunction);
                            setStartTime(defaultStartTime);
                            setEndTime(defaultEndTime);
                          } else {
                            handleShiftClick(shift);
                            setSelectedJunction({ ...shift, id: shift?.junctionId });
                          }
                        }}
                        handleMouseOver={handleMouseOver}
                        handleMouseOut={handleMouseOut}
                        handleMouseMove={handleMouseMove}
                        junctionNumber={junctionNumber}
                        selectedJunction={selectedJunction}
                        setSelectedJunction={setSelectedJunction}
                      />
                    </>
                  );
                });
              })}
            </svg>
          )}
          {showTooltip && showTooltip?.index?.index == 0 && showTooltip?.index?.junctionId ? (
            <div
              style={{
                right: totalWidthh - mouseX < 600 ? "68%" : "4%",
                top: showTooltip.yPos - 110,
              }}
              className={`absolute z-50 bg-white p-2  rounded-lg shadow-md `}
            >
              <div className="w-[470px] min-w-[470px]">
                {moment(date).format("DD/MM/YYYY") == moment(new Date())?.format("DD/MM/YYYY") ? (
                  <>
                    {showTooltip?.junction?.status === "completed" ? (
                      <>
                        {showTooltip?.junction?.station ? (
                          <div
                            className={`flex flex-col mr-4 cursor-pointer min-w-[467px] max-w-[467px] hover:bg-secondary-50 items-start pt-2 px-3 pb-3 w-full rounded-lg `}
                          >
                            <div className="min-w-[440px] max-w-[440px] relative cursor-pointer ">
                              <div className="flex flex-col gap-y-8 mt-2">
                                <PersonnelDetailTodayTimelineDoneCard data={showTooltip} selectedJunction={selectedJunction} junctionNumber={junctionNumber} />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <div className="min-w-[465px] max-w-[465px] relative cursor-pointer ">
                        <div className="flex flex-col gap-y-8 mt-2">
                          <PersonnelDetailTimelineActiveCard data={showTooltip} junctionNumber={junctionNumber} selectedJunction={selectedJunction} />
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {showTooltip?.junction?.status === "completed" && (
                      <div
                        className={`flex flex-col mr-4 cursor-pointer min-w-[467px] max-w-[467px] hover:bg-secondary-50 items-start pt-2 px-3 pb-3 w-full border-[#E4E7EC] hover:border-secondary-300 border rounded-lg `}
                      >
                        <div className="min-w-[440px] max-w-[440px] relative cursor-pointer ">
                          <div className="flex flex-col gap-y-8 mt-2">
                            <PersonnelDetailTimelineDoneCard data={showTooltip} selectedJunction={selectedJunction} junctionNumber={junctionNumber} />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          ) : showTooltip && showTooltip?.index?.lossesId ? (
            <div
              style={{
                right: totalWidthh - mouseX < 600 ? "68%" : "4%",
                top: showTooltip.yPos - 90,
              }}
              className={`absolute z-50 bg-white p-2  rounded-lg shadow-md  `}
            >
              <LossesCardType
                title={showTooltip?.index?.lossesName || "Undefined Loss"}
                subTitle={showTooltip?.index?.reasonDescription || "Unkown"}
                startTime={moment(timeFormatter(showTooltip?.index?.startDate).formatted).format("HH:mm")}
                endTime={moment(timeFormatter(showTooltip?.index?.endDate).formatted).format("HH:mm")}
                id={showTooltip?.index?.id}
              />
            </div>
          ) : showTooltip && showTooltip?.index?.index == 1 ? (
            <div
              className={`absolute z-50 bg-white p-2  rounded-lg shadow-md`}
              style={{
                left: (showTooltip?.xPos + 30) * 2 > totalWidthh ? showTooltip?.xPos - 280 : showTooltip?.xPos + 40,
                top: showTooltip?.yPos - 140,
                borderTop: "5px solid " + showTooltip?.index?.color,
              }}
            >
              <div className="flex items-center mb-1 ">
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    backgroundColor: showTooltip?.index?.color,
                    borderRadius: 2,
                  }}
                ></div>

                <p
                  className="text-md font-semibold"
                  style={{
                    color: showTooltip?.index?.color === "#FFFFFF" ? "black" : showTooltip?.index?.color,
                    marginLeft: "6px",
                  }}
                >
                  {showTooltip?.index?.name}
                </p>
              </div>
              <div className="flex flex-col w-full items-center justify-center">
                <div className="flex w-full border-b border-[#EAECF0]">
                  <div className="flex min-w-[150px] max-w-[150px] border-r border-[#EAECF0] text-[#475467] text-sm font-normal py-1 pr-2">
                    {t("personals:startEndTime")}
                  </div>
                  <div className="flex min-w-[142px] max-w-[172px] gap-x-1 text-[#475467] font-medium text-sm py-1 pl-2">
                    <span>{moment(timeFormatter(showTooltip?.index?.startDate).formatted).format("HH:mm:ss")}</span>-
                    <span>{moment(timeFormatter(showTooltip?.index?.endDate).formatted).format("HH:mm:ss")}</span>
                  </div>
                </div>
                <div className="flex w-full">
                  <div className="flex min-w-[150px] max-w-[150px] border-r border-[#EAECF0] text-[#475467] text-sm font-normal py-1 pr-2">
                    {t("settingTab:totalTime")}
                  </div>
                  <div className="flex min-w-[142px] max-w-[172px] gap-x-1 text-[#475467] font-medium text-sm py-1 pl-2">
                    <span>
                      {formatSeconds(
                        moment(timeFormatter(showTooltip?.index?.endDate).formatted).diff(
                          moment(timeFormatter(showTooltip?.index?.startDate).formatted),
                          "seconds"
                        )
                      )}
                    </span>
                    -
                    <span>
                      {moment(timeFormatter(showTooltip?.index?.endDate).formatted).diff(
                        moment(timeFormatter(showTooltip?.index?.startDate).formatted),
                        "seconds"
                      ) +
                        " " +
                        t("product:second")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default UpuTimelinePersonelPages;

import React from "react";
import generateFileUrl from "../../../../utils/generateFileUrl";
import { useTranslation } from "react-i18next";
import { FlexProductIcon, PieceIcon } from "../../../../components/new-cards/card-icons";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";

const InternalPlannedDifferentPlannedCard = ({ item, targetCount }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const statusColor = () => {
    switch (item?.status) {
      case "finished":
        return "#12B76A";
      case "started":
        return "#FDE172";
      case "rejected":
        return "#D92D20";
      case "flexProduct":
        return "#E9D7FE";
      default:
        return "#F2F4F7";
    }
  };
  return (
    <>
      <div
        data-tooltip-id={`${item?.id}`}
        data-tooltip-content={`${t("product:productName")}: ${item?.job?.product?.name} - ${t("product:orderNo")}: ${item?.job?.orderNo}`}
        data-tooltip-place="right"
        key={item?.id}
        className="flex bg-white flex-row  w-[335px] min-w-[335px] h-[90px]  min-h-[90px] relative group pl-2  pt-2  border border-[#D0D5DD] rounded-lg cursor-grab "
      >
        <div className="flex flex-col w-[88%]">
          <div className="flex flex-row items-center gap-x-3">
            <img
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/app/product/mrp/work-in-process/detail/${item?.job?.id}+${item?.header?.step}`);
              }}
              className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-300 select-none"
              src={generateFileUrl(item?.job?.product?.image)}
            />
            <div className="flex flex-col items-start">
              <div className="flex flex-row items-center gap-x-1 w-full">
                {item?.productType === "flex" ? (
                  <>
                    <FlexProductIcon />
                    <p className="font-semibold text-secondary-900 text-base w-[170px] truncate"> {item?.job?.product?.name || "Product Name"}</p>
                  </>
                ) : (
                  <p className="font-semibold text-secondary-900 text-base w-[170px] truncate"> {item?.job?.product?.name || "Product Name"}</p>
                )}
              </div>

              <p className="text-sm font-normal w-[170px] truncate text-secondary-600">
                {t("product:stockCode")} : {item?.job?.product?.stockCode || "--"}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-[6px]">
            <div className="flex flex-row items-center mt-1.5">
              <PieceIcon />
              <p className="text-xs font-medium ml-1 text-secondary-600">{item?.header?.piece || "--"}</p>
              <p className="text-[10px] ml-0.5 font-normal text-secondary-500">{t("product:qty")}</p>
            </div>
            <div className="flex flex-row items-center mt-1.5">
              <CalendarIcon />
              <p className="text-xs font-medium ml-1 text-secondary-600">{targetCount || "--"}</p>
              <p className="text-[10px] ml-0.5 font-normal text-secondary-500">{t("product:targetDeliveryTime")}</p>
            </div>
          </div>
        </div>

        <div
          className={`flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 bg-[${statusColor()}] border-secondary-300 h-[70px] min-h-[70px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px]`}
        >
          {item?.status === "flexPoduct" ? (
            <></>
          ) : (
            <span className="w-3 min-w-3 h-3 min-h-3">
              <CheckIcon
                color={item?.status === "started" ? "#A15C07" : item?.status === "rejected" ? "#fff" : item?.status === "finished" ? "#fff" : "#475467"}
              />
            </span>
          )}

          <p
            className={`text-[10px] font-medium ${item?.status === "started"
                ? "text-[#A15C07]"
                : item?.status === "rejected"
                  ? "text-white"
                  : item?.status === "finished"
                    ? "text-white"
                    : item?.status === "flexProduct"
                      ? "text-[#6941C6]"
                      : "text-secondary-500"
              } -rotate-90`}
          >
            {item?.status === "flexProduct" ? item?.header?.step + ".Flex" : item?.header?.step + ".Op"}
          </p>
        </div>
      </div>
      {item?.id && <Tooltip className="z-[999]" id={`${item?.id}`} />}
    </>
  );
};

export default InternalPlannedDifferentPlannedCard;

const CheckIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path d="M3 2L8.5 7.5L6 10" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

const CalendarIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
      <path
        d="M13 6.66666L1 6.66666M9.66667 1.33333V3.99999M4.33333 1.33333V3.99999M4.2 14.6667L9.8 14.6667C10.9201 14.6667 11.4802 14.6667 11.908 14.4487C12.2843 14.2569 12.5903 13.951 12.782 13.5746C13 13.1468 13 12.5868 13 11.4667V5.86666C13 4.74656 13 4.1865 12.782 3.75868C12.5903 3.38236 12.2843 3.0764 11.908 2.88465C11.4802 2.66666 10.9201 2.66666 9.8 2.66666L4.2 2.66666C3.0799 2.66666 2.51984 2.66666 2.09202 2.88465C1.71569 3.0764 1.40973 3.38236 1.21799 3.75868C1 4.1865 1 4.74656 1 5.86666L1 11.4667C1 12.5868 1 13.1468 1.21799 13.5746C1.40973 13.951 1.71569 14.2569 2.09202 14.4487C2.51984 14.6667 3.0799 14.6667 4.2 14.6667Z"
        stroke="#475467"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
